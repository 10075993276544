import { useEffect, useState } from "react";
import { getProductosContrato } from "../../../pages/AdminLocal/services/contratos.productos.services";
import { IProductoContrato } from "../../../pages/AdminProducts/types/producto";

export const useProductosContrato = () => {
  const [productos, setProductos] = useState<IProductoContrato[]>([]);

  const fetchProductosContrato = async () => {
    getProductosContrato()
      .then(({ data }) => {
        setProductos(data.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProductosContrato();
  }, []);

  return { productos, fetchProductosContrato };
};
