import { useCallback, useState } from "react";

interface Props {
  pasos: string[];
  pasoInicial: string;
}

const usePasos = ({ pasos, pasoInicial }: Props) => {
  const [paso, setPaso] = useState(pasoInicial);

  const reset = useCallback(() => {
    setPaso(pasoInicial);
  }, [pasoInicial]);

  const handleNext = useCallback(
    async (callback = async () => Promise.resolve()) => {
      const currentIndex = pasos.indexOf(paso);
      if (currentIndex < pasos.length - 1) {
        setPaso(pasos[currentIndex + 1]);
        if (callback && typeof callback === "function") await callback();
      }
    },
    [paso, pasos]
  );

  const handleBack = useCallback(() => {
    const currentIndex = pasos.indexOf(paso);
    if (currentIndex > 0) {
      setPaso(pasos[currentIndex - 1]);
    }
  }, [paso, pasos]);

  return {
    paso,
    setPaso,
    reset,
    handleNext,
    handleBack,
  };
};

export default usePasos;
