import { Divider, TableCell, TableRow } from '@mui/material'
import NoResults from '../../../../components/Common/NoResults'
import { IProducto } from '../../types/producto'
import Loading from '../../../../components/Common/Loading'
import { TABLE_HEAD_ITEMS } from './TableProductos'
import Producto from '../Producto'
import { Fragment, useContext } from 'react'
import NoSearch from '../../../../components/Common/NoSearch'
import { ProductosAdminContext } from '../../context/productos.context'
import useRubros from '../../../Products/hooks/rubros.hook'

const TableData = ({ items }: { items: any }) => {
  const { searching, page, pageSize, loading: loadingProductos } = useContext(ProductosAdminContext)
  const { rubrosFlatted: rubros, loading: loadingRubros } = useRubros()

  if (loadingProductos)
    return (
      <TableRow>
        <TableCell colSpan={TABLE_HEAD_ITEMS.length}>
          <Loading />
        </TableCell>
      </TableRow>
    )
  if (items.length <= 0) {
    if (!searching)
      return (
        <TableRow>
          <TableCell colSpan={TABLE_HEAD_ITEMS.length}>
            <NoSearch />
          </TableCell>
        </TableRow>
      )
    return (
      <TableRow>
        <TableCell colSpan={TABLE_HEAD_ITEMS.length}>
          <NoResults />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {(pageSize > 0 ? items.slice(page * pageSize, page * pageSize + pageSize) : items).map(
        (producto: IProducto, i: number) => (
          <Fragment key={i}>
            <TableRow>
              <TableCell sx={{ margin: 0, padding: 0 }} colSpan={TABLE_HEAD_ITEMS.length}>
                <Divider sx={{ background: '#bbb' }} />
              </TableCell>
            </TableRow>
            <Producto producto={producto} rubros={rubros} loadingRubros={loadingRubros} />
          </Fragment>
        )
      )}
      {/* {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )
      } */}
    </>
  )
}

export default TableData
