export type EstadoLinkType = 'validando' | 'validado' | 'error'

export enum MenuTabs {
  Estado = 'ESTADO',
  Productos = 'IMPORTAR PUBLICACIONES',
  Publicaciones = 'IR A MIS PUBLICACIONES',
  Novedades = 'NOVEDADES'
}

export enum TiendaNames {
  WOO = 'Woocommerce',
  TN = 'Tienda Nube'
}

export type CodigoTienda = 'WC' | 'TN'

export const GetCodigoTienda = (tienda: TiendaNames): CodigoTienda => (tienda === 'Woocommerce' ? 'WC' : 'TN')

export interface AtributosEquivalencias {
  idIntegracionAtributo: number
  idIntegracionAtributoValor: number
  nombreAtributoValorExterno: string
  nombreValorAa1t: string
  idProductoAtributoValorNormalizado: number
  idProductoAtributo: number | null
}

export interface SaveValores {
  idIntegracionAtributo?: number
  nombreAtributoValorExterno?: string
  idProductoAtributoValorNormalizado: number
  idProductoAtributo: number
}

export interface SaveAtributos {
  nombreAtributoExterno: string
  idProductoAtributo: number
}

export interface Atributo {
  idIntegracionAtributo: number
  nombreAtributoExterno: string
  nombreAa1tSingular: string
  nombreAa1tPlural: string
  idProductoAtributo: number
  ignorarAtributo: number
}

export interface AtributoValor {
  atributo: string
  valor: string
}

export interface ValoresList {
  id: number
  nombre: string
  equivalencia?: string
  atributo?: string
}

export interface ITienda {
  name: TiendaNames
  logo: string
  color: string
}
