import { useContext, useEffect, useState } from "react";
import { getPalabrasProhibidas } from "../services/products.services";
import { TenantsContext } from "../../../context/tenants.context";

export interface IPalabrasProhibidas {
  idGrupo: number;
  nombreGrupo: string;
  accion: string;
  mensaje: string;
  palabraList: string[];
}

const usePalabrasProhibidas = () => {
  const { tenant } = useContext(TenantsContext);
  const [palabrasProhibidas, setPalabrasProhibidas] = useState<IPalabrasProhibidas[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getPalabrasProhibidas(tenant)
      .then(({ data }) => {
        setPalabrasProhibidas(data.data);
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return {
    palabrasProhibidas,
    loading,
  };
};
export default usePalabrasProhibidas;
