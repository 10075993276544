import React from "react";
import { IHistorialContratos } from "../../../dto/ContractDTO";
import { Box, Card, Typography } from "@mui/material";

const CardsHistorial: React.FC<any> = ({
  historial,
  listado,
}: {
  historial: any[];
  listado: boolean;
}) => {
  if (!historial) return;
  return (
    <>
      {historial.map((item: any, index: number) => {
        const fecha = item.fecha ? new Date(item.fecha) : null;
        const fechaInicio = item.fechaInicio
          ? new Date(item.fechaInicio)
          : null;
        const fechaFin = item.fechaFin ? new Date(item.fechaFin) : null;
        return (
          <Card
            key={index}
            variant="outlined"
            sx={{ marginBottom: "20px", padding: "10px" }}
          >
            {listado && (
              <Typography variant="h5">
                {item.nombreCliente} ({item.idLocal})
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "4px",
                gap: "4px",
              }}
            >
              {listado && (
                <Typography variant="body1">
                  <strong>Producto:</strong> {item.productoNombre}, ID{" "}
                  {item.idContratoProducto}
                </Typography>
              )}
              <Typography variant="body1">
                <strong>Fecha:</strong> {fecha.toLocaleString()}
              </Typography>
              <Typography variant="body1">
                <strong>Estado:</strong> {item.estadoNombre}
              </Typography>
              {fechaInicio && (
                <Typography variant="body1">
                  <strong>Fecha Inicio:</strong> {fechaInicio.toLocaleString()}
                </Typography>
              )}
              {fechaFin && (
                <Typography variant="body1">
                  <strong>Fecha Fin:</strong> {fechaFin.toLocaleString()}
                </Typography>
              )}
              <Typography variant="body1">
                <strong>Observaciones:</strong> {item.observaciones}
              </Typography>
            </Box>
          </Card>
        );
      })}
    </>
  );
};

export default CardsHistorial;
