import { Box, Card, CardContent, CardHeader, Collapse, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import SectionTitle from '../../common/SectionTitle'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { LocalVendedorDTO } from '../../../../dto/LocalDTO'

const SoloLecturaFaq = ({local}: {local: LocalVendedorDTO}) => {
  const [faqOpen, setFaqOpen] = useState(-1)

  return (
    <Grid container spacing={2}>
      <SectionTitle title="Preguntas frecuentes" />
      <Grid item xs={12}>
        {
            local.faq.length > 0 ? (
              local.faq.map((f, i: number) => (
                <Card
                  key={i}
                  sx={{width: '100%', mb: 1, cursor: 'pointer'}}
                  onClick={() => setFaqOpen(faqOpen === i ? -1 : i)}
                >
                  <CardHeader
                    title={
                      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <b>{f.pregunta}</b>
                        <b>{faqOpen === i ? <ExpandLess /> : <ExpandMore />}</b>
                      </Box>
                    }
                    titleTypographyProps={{variant: 'h6'}}
                  />
                  <Collapse
                      in={faqOpen === i}
                      collapsedSize={0}
                      orientation="vertical"
                    >
                    <CardContent>
                      <Typography variant="subtitle2">{f.respuesta}</Typography>
                    </CardContent>
                  </Collapse>
                </Card>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1">No se cargaron preguntas frecuentes</Typography>
              </Grid>
            )
          }
      </Grid>
    </Grid>
  )
}

export default SoloLecturaFaq
