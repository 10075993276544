import { CircularProgress, styled, Typography } from "@mui/material";
import React, { memo, useEffect } from "react";
import Observaciones from "./Observaciones";
import Loading from "../Common/Loading";
import { SingleContractProps } from "./ContractComponent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Container = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "stretch",
  width: "97%",
  border: "1px solid",
  margin: "5px",
  padding: "5px",
  borderRadius: "5px",
});

const Divider = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "stretch",
  width: "27%",
  borderRadius: "5px",
  height: "6px",
  backgroundColor: "blue",
  margin: "10px 7px",
});

const OneColumn = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "stretch",
  width: "100%",
  gap: "4px",
});

const TwoColumn = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "stretch",
  justifyContent: "space-between",
  width: "100%",
  gap: "2px",
  marginBottom: "5px",
});

const Title = styled("p")({
  fontWeight: "bold",
  marginBottom: "0.25rem",
  fontSize: "4vw",
});

const Text = styled("p")({
  marginBottom: "0.15rem",
  fontSize: "4vw",
});

const ContractMobile: React.FC<SingleContractProps> = ({
  contracts,
  contract,
  index,
  loadingAccion,
  loadingMP,
  group,
  noShow,
  setShowRenovations,
  setRenewContract,
  handleLinkMP,
  executeAction,
  handleWhpp,
  getDateDifference,
}: SingleContractProps) => {

  return (
    <>
      {!noShow &&
        group === "VencidosAntes" &&
        (index === 0 ||
          contract.fechaFinFormateada !==
            contracts[index - 1].fechaFinFormateada) && (
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
              <Divider />
              <Typography
              variant="h6"
              style={{ color: "blue" }}
            >{`Hace ${getDateDifference(
              contract.fechaFinFormateada
            )} días`}</Typography>
              <Divider />
            </div>
        )}
      <Container key={index} hidden={noShow}>
        <OneColumn>
          <Title color="007bff">{contract.idContratoClienteLocal}</Title>
          <Title>
            {" | "} {contract.clienteNombre}
            {" - "}
            Contrato #{contract.idContrato}
          </Title>
          <a
            target="_blank"
            className="btn btn-primary wsp-btn"
            role="button"
            rel="noreferrer"
            onClick={() =>
              window.open(
                `admin-local/${contract.idContratoClienteLocal}`,
                "_blank",
                "noopener,noreferrer"
              )
            }
            style={{
              padding: "2px 4px",
              height: "25px",
              fontSize: ".9rem",
              marginLeft: "auto",
            }}
          >
            Perfil
          </a>
        </OneColumn>
        <OneColumn>
          <a
            href={`https://wa.me/${contract.clienteWhatsapp}`}
            target="_blank"
            className="btn btn-success wsp-btn"
            role="button"
            rel="noreferrer"
            style={{
              padding: "3px",
              height: "30px",
              fontSize: "4vw",
              marginBottom: "2px",
            }}
          >
            {`Contactar: ${contract.clienteWhatsapp}`}
          </a>
          {group !== "Suspendidos" && group !== "Pospuestos" && (
            <a
              target="_blank"
              className="btn btn-success wsp-btn"
              role="button"
              rel="noreferrer"
              style={{
                padding: "3px",
                height: "30px",
                fontSize: "4vw",
                marginBottom: "2px",
              }}
              onClick={() => handleWhpp()}
            >
              <WhatsAppIcon
                fontSize="small"
                style={{ marginBottom: "5px", marginRight: "5px" }}
              />
              {"CON MSJ"}
            </a>
          )}
        </OneColumn>
        <OneColumn>
          <Text>Producto:</Text>{" "}
          <Title
            style={{
              backgroundColor: `#${contract.colorProducto}`,
            }}
          >
            {contract.cantidad > 1
              ? contract.productoNombre + "x" + contract.cantidad
              : contract.productoNombre}
          </Title>
        </OneColumn>
        <OneColumn>
          <Text>Estado:</Text> <Title>{contract.estadoNombre}</Title>
        </OneColumn>
        <OneColumn>
          <Text>Vto:</Text> <Title>{contract.fechaFinFormateada}</Title>
          <Text>| Precio:</Text> <Title>${contract.precioLista?.toLocaleString('es-ES')}</Title>
        </OneColumn>
        <TwoColumn>
          <button
            className="btn btn-primary premise-info-child"
            style={{ height: "37px", fontSize: ".9rem" }}
            onClick={() => {
              handleLinkMP(contract.idContrato);
            }}
          >
            {loadingMP ? <Loading /> : "Link MP"}
          </button>
          <button
            disabled={
              (!contract.renovable && contract.idContratoEstado !== 6) ||
              contract.accionesAdminList === null ||
              contract.accionesAdminList === undefined
            }
            className="btn btn-primary premise-info-child"
            style={{ height: "37px", fontSize: ".9rem" }}
            onClick={() => {
              setShowRenovations(true);
              setRenewContract(contract);
            }}
          >
            Acciones
          </button>
          {contract.accionesAdminList !== undefined &&
            contract.accionesAdminList !== null &&
            contract.accionesAdminList[0] && (
              <button
                disabled={!contract.accionesAdminList[0]}
                className="btn btn-primary premise-info-child"
                onClick={() => {
                  if (
                    contract.accionesAdminList &&
                    contract.accionesAdminList[0]
                  ) {
                    executeAction(
                      contract.accionesAdminList[0],
                      contract.idContrato
                    );
                  }
                }}
                style={{
                  color: "#212529",
                  borderColor: "#212529",
                  backgroundColor: `${contract.accionesAdminList[0].color}`,
                  pointerEvents: loadingAccion ? "none" : "auto",
                }}
              >
                {loadingAccion ? (
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                ) : (
                  contract.accionesAdminList[0].boton
                )}
              </button>
            )}
        </TwoColumn>
        <Observaciones props={contract}></Observaciones>
      </Container>
    </>
  );
};

export default memo(ContractMobile);
