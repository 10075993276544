import * as XLSX from "xlsx";

export const stringToObject = (input: string[]) => {
  const finalArray = input.map((item) => {
    const parts = item.split(" | ");
    const obj = {
      atributo: parts[0].replace("atributo: ", ""),
      valor: parts[1].replace("valor: ", ""),
    };
    return obj;
  });

  return finalArray;
};

export const downloadCSV = (csv: any) => {
  // Aquí se llama la función para descargar el CSV
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "catalogo.csv");
  document.body.appendChild(link);
  link.click();

  // Limpiar después de la descarga
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const readExcel = (file: any): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e: any) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws);

      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
