import React, { useContext, useState } from 'react'
import { Typography, Button, Box, IconButton, DialogActions } from '@mui/material'
import DesplegableRubros from './DesplegableRubros'
import CloseIcon from '@mui/icons-material/Close'
import { ITenant } from '../../AbmLocalPage/types'
import { TenantsContext } from '../../../context/tenants.context'
import { Rubro } from '../../Integraciones/types/responses-productos'

interface DialogCambiarRubrosProps {
  rubros: Rubro[]
  openDialog: (open: boolean) => void
  handleChangeRubro: (codRubro: string, tenant: ITenant) => void
}

const DialogCambiarRubros: React.FC<DialogCambiarRubrosProps> = ({ rubros, openDialog, handleChangeRubro }) => {
  const [codRubro, setCodRubro] = useState<string>(null)
  const { tenant } = useContext(TenantsContext)

  const handleSubmit = () => {
    handleChangeRubro(codRubro, tenant)
    openDialog(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 30px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
            Indique el nuevo rubro
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%'
          }}
        >
          <DesplegableRubros
            rubros={rubros}
            onChange={(e: any) => setCodRubro(e.codRubro)}
          />
        </Box>
        <Button variant="contained" onClick={() => handleSubmit()} color="success" sx={{ marginTop: '20px' }}>
          Cambiar
        </Button>
      </Box>
      <DialogActions>
        <IconButton
          edge="end"
          onClick={() => openDialog(false)}
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            paddingRight: '16px'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </>
  )
}

export default DialogCambiarRubros
