import React, { memo, useContext } from "react";
import "../../styles/contractsRenovations.scss";
import * as GrIcons from "react-icons/gr";
import { LinksDTO } from "../../hooks/admin.local.contracts.hook";
import TableDataPagination, {
  INITIAL_ROWS_PER_PAGE
} from "../../pages/Products/components/TableDataPagination";
import { PropsDataContratos } from "./ModalTablePagos";
import { DataContratosContext } from "./context/data.contratos.context";
import { format } from "date-fns";

const ModalTableLinks: React.FC<PropsDataContratos> = ({
  show,
  handleClose,
  headers,
  title,
}: PropsDataContratos) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const {
    paginatorLinks,
    setPageLinks,
    setPageSizeLinks,
    pageLinks,
    pageSizeLinks,
    selectedLinks,
  } = useContext(DataContratosContext);

  paginatorLinks.handleChangePage = (e: any, newPage: number) => {
    setPageLinks(newPage);
  };

  paginatorLinks.handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSizeLinks(parseInt(event.target.value, 10));
    setPageLinks(0);
  };

  const closeRenovations = () => {
    handleClose();
  };

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main"
        style={{
          marginTop: "20px",
          overflowY: "auto",
          maxHeight: "80vh",
          width: "90%",
        }}
      >
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">
            {title !== undefined && title}
          </h3>
          <button
            className="modal-close"
            onClick={closeRenovations}
            aria-label="Cerrar"
          >
            <GrIcons.GrClose />
          </button>
        </header>
        <div style={{ paddingRight: "7.5vh" }}>
          <table className="table table-striped table-bordered premise-table">
            <thead className="table-active">
              <tr>
                {headers &&
                  headers.map((h: any, index) => {
                    return (
                      <th key={index}>
                        <button
                          type="button"
                          // onClick={() => requestSort("idContrato")}
                          // className={getClassNamesFor("idContrato")}
                        >
                          {h}
                        </button>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            {selectedLinks.items.length > 0 && (
              <tbody>
                {selectedLinks.items.map((item: LinksDTO, index) => {
                  const fechaAlta = new Date(item.fechaAlta);
                  const fechaVencimiento = new Date(item.fechaVencimiento);
                  return (
                    <tr key={item.idContratoPagoLink}>
                      <th>{item.idContratoPagoLink}</th>
                      <th>
                        {<th>{format(fechaAlta, "dd/MM/yyyy HH:mm:ss")}</th>}
                      </th>
                      <th>{format(fechaVencimiento, "dd/MM/yyyy HH:mm:ss")}</th>
                      <th>{item.platform}</th>
                      <th>{item.source}</th>
                      <th>{item.tienePagos ? 'SI' : 'NO'}</th>
                      <th style={{ maxWidth: "400px" }}>{item.link}</th>
                    </tr>
                  );
                })}
              </tbody>
            )}
            <tfoot>
              <tr>
                <TableDataPagination
                  total={selectedLinks.totalItems || 0}
                  page={pageLinks || 0}
                  paginator={paginatorLinks}
                  items={selectedLinks.items || []}
                  rowsPerPage={pageSizeLinks || INITIAL_ROWS_PER_PAGE}
                  component={"td"}
                  label={`Links por página`}
                />
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>
  );
};

export default memo(ModalTableLinks);
