import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { memo, useEffect, useState } from 'react'
import { IAnuncio } from '../hooks/anuncios.hook'

interface DetalleAnunciosProps {
  open: boolean
  setOpen: (op: boolean) => void
  anuncio: IAnuncio
  detalle: { url: string; cta: any }[]
  isMobile: boolean
}

const DialogDetalleAnuncio = ({ open, setOpen, anuncio, detalle, isMobile }: DetalleAnunciosProps) => {
  const [index, setIndex] = useState<number>(0)
  const [texto, setTexto] = useState<string>('CONTACTARSE')

  const handleClick = () => {
    if (index !== detalle.length - 1) {
      setIndex(index + 1)
    } else {
      anuncio.detalleLink && window.open(anuncio.detalleLink)
    }
  }

  useEffect(() => {
    if (anuncio.detalleTexto) {
      setTexto(anuncio.detalleTexto)
    } else if (index !== detalle.length - 1) {
      setTexto('SIGUIENTE')
    } else {
      setTexto('CONTACTARSE')
    }
  }, [index, anuncio])

  const handleClose = () => {
    setIndex(0)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          display: 'flex',
          maxWidth: '100%',
          maxHeight: '100%'
        }
      }}
    >
      <Box sx={{ padding: 0 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 0,
            top: 0
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogContent sx={{ padding: 0 }}>
          <img
            key={index}
            src={detalle[index]?.url || ''}
            alt=""
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '80vh',
              margin: 0,
              padding: 0
            }}
          />
        </DialogContent>
        {(index !== detalle.length - 1 || anuncio.detalleLink) && (
          <DialogActions>
            <Button fullWidth variant="contained" color="success" onClick={handleClick}>
              {texto}
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}

export default memo(DialogDetalleAnuncio)
