import { useState, useEffect, useContext } from 'react'
import { TenantsContext } from '../../../context/tenants.context'
import { getLocal } from '../../AbmLocalPage/services/locales.service'

type Feature = {
  id: number
  nombre: string
  activo: boolean
}

const ACCOUNT_TYPES = {
  basic: 'Cuenta <b>BÁSICA</b>',
  premium: 'Cuenta <b>PREMIUM</b>'
}

const CONFIG = {
  maxPhotos: 'maxFotosXPublicacion',
  maxPublicaciones: 'maxPublicaciones',
  precioDto: 'precioConDto',
  ofrecerPremium: 'premium_ofrecerPremium',
  whppContratos: 'whatsappContratos',
  impo_expo: 'integraciones_impoexpo_enabled',
  integraciones: 'integraciones_tiendas_enabled'
}

const useLocal = (codLocal: any, token: string) => {
  const { tenant } = useContext(TenantsContext)
  const [localConfig, setLocalConfig] = useState<any>({
    isRetailer: false,
    isWholeSaler: false,
    isPremium: false,
    maxPhotos: 0,
    maxPublicaciones: 0,
    esMinorista: false,
    precioDto: false,
    ofrecerPremium: false,
    usaSku: false,
    shareLink: '',
    shareLinkMinorista: '',
    whppContratos: '',
    showImpoExpo: false,
    showIntegraciones: false
  })

  useEffect(() => {
    if (!codLocal) return
    getLocal(codLocal, tenant)
      .then((response) => response.data.data)
      .then((data) => {
        const maxPhotos = data.config?.find(
          (conf: any) => conf.name === CONFIG.maxPhotos
        ).value
        const maxOfertas = data.config?.find(
          (conf: any) => conf.name === CONFIG.maxPublicaciones
        ).value
        const precioAnterior = data.config?.find(
          (conf: any) => conf.name === CONFIG.precioDto
        ).value
        const showPre = data.config?.find(
          (conf: any) => conf.name === CONFIG.ofrecerPremium
        ).value
        const whatsappContratos = data.config?.find(
          (conf: any) => conf.name === CONFIG.whppContratos
        ).value
        const retailer = data.caracteristicasList?.find(
          (feat: Feature) => feat.id === 2
        )
        const wholesaler = data.caracteristicasList?.find(
          (feat: Feature) => feat.id === 1
        )
        const minorista = data.caracteristicasList?.find((feat: Feature) =>
          feat.nombre.toLowerCase().includes('minorista')
        )
        const sku = data.caracteristicasList?.find(
          (feat: Feature) => feat.id === 11
        )
        const showImpoExpo =
          data.config?.find((c) => c.name === CONFIG.impo_expo)?.value ===
          'true'

        const showIntegraciones =
          data.config?.find((c) => c.name === CONFIG.integraciones)?.value ===
          'true'

        setLocalConfig({
          ...localConfig,
          isRetailer: retailer !== undefined,
          isPremium: data.cuentaTipoNombre === ACCOUNT_TYPES.premium,
          isWholeSaler: wholesaler !== undefined,
          maxPhotos: parseInt(maxPhotos),
          maxPublicaciones: parseInt(maxOfertas),
          esMinorista: minorista !== undefined,
          precioDto: precioAnterior == 'true',
          ofrecerPremium: showPre === 'true',
          usaSku: sku,
          shareLink: data.shareLink,
          shareLinkMinorista: data.shareLinkMinorista,
          whppContratos: whatsappContratos,
          showImpoExpo,
          showIntegraciones
        })
      })
      .catch((e) => console.log(e))
  }, [codLocal])

  return {
    retail: localConfig.isRetailer,
    wholesaler: localConfig.isWholeSaler,
    premium: localConfig.isPremium,
    maxPhotos: localConfig.maxPhotos,
    maxPublicaciones: localConfig.maxPublicaciones,
    esMinorista: localConfig.esMinorista,
    precioDto: localConfig.precioDto,
    ofrecerPremium: localConfig.ofrecerPremium,
    usaSku: localConfig.usaSku,
    shareLink: localConfig.shareLink,
    shareLinkMinorista: localConfig.shareLinkMinorista,
    whppContratos: localConfig.whppContratos,
    showImpoExpo: localConfig.showImpoExpo,
    showIntegraciones: localConfig.showIntegraciones
  }
}

export default useLocal
