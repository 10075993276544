import React from "react";

import "../styles/sortableData.scss";
import "../styles/contractsList.scss";
import ContractsComponent from "../components/ContractsList/ContractsComponent";

const ContractListPage: React.FC = () => {
  return (
    <>
      <ContractsComponent></ContractsComponent>
    </>
  );
};

export default ContractListPage;
