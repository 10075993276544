import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC, memo } from 'react'
import { IProductoTienda } from '../../../../../types/responses-productos'

interface Props {
  producto: IProductoTienda
}

const ProductoBodyWoo: FC<Props> = ({ producto: { precio, nombreProducto, categorias, rubroName } }) => {
  return (
    <Box display="flex" flexDirection="column" width="auto">
      <Typography color="#1C1C1C" fontWeight={600} fontSize={16}>
        {nombreProducto}
      </Typography>
      <Typography color="#1C1C1C" fontWeight={600} fontSize={16}>
        {Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(precio)}
      </Typography>
      <Box>
        {rubroName ||
          categorias?.map((c) => (
            <Typography variant="subtitle2" color="GrayText" key={c.id}>
              {c.name}
            </Typography>
          ))}
      </Box>
    </Box>
  )
}

export default memo(ProductoBodyWoo)
