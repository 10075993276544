import { Alert } from "@mui/material"

const NoResults = () => {
  return (
    <Alert
      severity="warning"
      className="my-3 font-weight-bold"
    >
      No se encontraron resultados
    </Alert>
  )
}

export default NoResults
