import AlertDialog from '../../../components/Common/Dialog'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { aprobar, pausar, reanudar, rechazar } from '../services/productos.service'
import { IProducto } from '../types/producto'
import { useContext, useState } from 'react'
import TableProducto from './Table/TableProducto'
import CardProducto from './Card/CardProducto'
import { ProductosAdminContext } from '../context/productos.context'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { Rubro } from '../../Integraciones/types/responses-productos'
import ConfirmDialog from '../../Products/components/ConfirmDialog'

interface ProductoProps {
  producto: IProducto
  rubros: Rubro[]
  loadingRubros: boolean
}

export interface ProductoWProps {
  producto: IProducto
  isSelected: boolean
  setIsSelected: (value: boolean) => void
  changingEstado: boolean
  rubros: Rubro[]
  loadingRubros: boolean
  isDefinitivelyDeleting?: boolean
  isDefinitivelyExpiring?: boolean
  cambiarRubro: (codRubro: string, nombreAnalytics: string) => Promise<void>
  eliminarRevision: () => void
}

const Producto = ({ producto, rubros, loadingRubros }: ProductoProps) => {
  const { actions, setEditProductCode, setCodLocalEditing } = useContext(ProductosAdminContext)
  const { handleChangeEstado, vencerProducto, handleChangeRubro, borrarProducto, handleNoRevisar } = actions
  const theme = useTheme()
  const isFullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [changingEstado, setIsChangingEstado] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isDefinitivelyDeleting, setIsDefinitivelyDeleting] = useState<boolean>(false)
  const [isExpiring, setIsExpiring] = useState<boolean>(false)
  const [isDefinitivelyExpiring, setIsDefinitivelyExpiring] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [dialogContent, setDialogContent] = useState<string>('')
  const [estadoCambiandoA, setEstadoCambiandoA] = useState<string>('')
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  const cambiarEstado = async (funcion: Function) => {
    try {
      setIsChangingEstado(true)
      await handleChangeEstado(producto.codProducto, funcion, estadoCambiandoA)
    } catch (e) {
      console.log(e)
    } finally {
      setIsChangingEstado(false)
    }
  }

  const cambiarRubro = async (codRubro: string, nombreAnalytics: string) => {
    try {
      await handleChangeRubro(producto.codProducto, codRubro, nombreAnalytics)
    } catch (e) {
      console.log(e)
    } finally {
      setIsSelected(false)
    }
  }

  const eliminarRevision = () => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Desea eliminar la etiqueta de revisión?',
        body: '',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar',
        colorButtonConfirm: 'error'
      },
      action: async () => {
        await handleNoRevisar(producto.codProducto)
        clearConfirmDialog()
      },
      close: () => clearConfirmDialog()
    })
  }

  const borrar = async () => {
    try {
      await borrarProducto(producto.codProducto)
    } catch (e) {
      console.log(e)
    } finally {
      setIsDeleting(false)
    }
  }

  const vencer = async () => {
    try {
      await vencerProducto(producto.codProducto)
    } catch (e) {
      console.log(e)
    } finally {
      setIsExpiring(false)
    }
  }

  producto.aprobar = () => {
    setDialogContent('Se está por APROBAR el producto. ¿Deseas continuar?')
    setOpen(true)
    setEstadoCambiandoA('Aprobado')
  }

  producto.rechazar = () => {
    setDialogContent('Se está por RECHAZAR el producto. ¿Deseas continuar?')
    setOpen(true)
    setEstadoCambiandoA('Rechazado')
  }

  producto.pausar = () => {
    setDialogContent('Se está por PAUSAR el producto. ¿Deseas continuar?')
    setOpen(true)
    setEstadoCambiandoA('Pausado')
  }

  producto.reanudar = () => {
    setDialogContent('Se está por REANUDAR el producto. ¿Deseas continuar?')
    setOpen(true)
    setEstadoCambiandoA('Reanudado')
  }

  producto.vencer = () => {
    setDialogContent('¿Deseas vencer el producto?')
    setOpen(true)
    setIsExpiring(true)
  }

  producto.editar = () => {
    setEditProductCode(producto.codProducto)
    setCodLocalEditing(producto.codLocal)
  }

  producto.borrar = () => {
    setDialogContent('¿Deseas eliminar el producto? Esta acción no se puede deshacer')
    setOpen(true)
    setIsDeleting(true)
  }

  const onConfirm = async () => {
    if (isDeleting) {
      setIsDefinitivelyDeleting(true)
      await borrar()
      setIsDefinitivelyDeleting(false)
    } else if (isExpiring) {
      setIsDefinitivelyExpiring(true)
      await vencer()
      setIsDefinitivelyExpiring(false)
    } else {
      await cambiarEstado(
        estadoCambiandoA === 'Aprobado'
          ? aprobar
          : estadoCambiandoA === 'Rechazado'
          ? rechazar
          : estadoCambiandoA === 'Pausado'
          ? pausar
          : reanudar
      )
    }
    setOpen(false)
  }

  return (
    <>
      <AlertDialog
        title={
          isDefinitivelyDeleting
            ? 'Eliminando'
            : isDeleting
            ? 'Eliminar Producto'
            : isDefinitivelyExpiring
            ? 'Venciendo'
            : isExpiring
            ? 'Vencer Producto'
            : estadoCambiandoA !== ''
            ? 'Cambiar Estado'
            : 'Confirmar'
        }
        onConfirm={onConfirm}
        onCancel={() => setOpen(false)}
        open={open}
        content={dialogContent}
        agreeColor={isDeleting || isExpiring ? 'error' : 'success'}
        disabled={changingEstado || isDefinitivelyDeleting || isDefinitivelyExpiring}
        agreeText={changingEstado || isDefinitivelyDeleting || isDefinitivelyExpiring ? 'Espera' : 'Confirmar'}
        disagreeText="Cancelar"
      />
      {isFullScreen ? (
        <CardProducto
          rubros={rubros}
          loadingRubros={loadingRubros}
          cambiarRubro={cambiarRubro}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          producto={producto}
          changingEstado={changingEstado}
          isDefinitivelyDeleting={isDefinitivelyDeleting}
          isDefinitivelyExpiring={isDefinitivelyExpiring}
          eliminarRevision={eliminarRevision}
        />
      ) : (
        <TableProducto
          rubros={rubros}
          loadingRubros={loadingRubros}
          cambiarRubro={cambiarRubro}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          producto={producto}
          changingEstado={changingEstado}
          isDefinitivelyDeleting={isDefinitivelyDeleting}
          isDefinitivelyExpiring={isDefinitivelyExpiring}
          eliminarRevision={eliminarRevision}
        />
      )}
      <ConfirmDialog {...confirmDialog} />
    </>
  )
}

export default Producto
