/* ARQUITECTURA BASE DE UN REDUCER */
//export const defaultState = 0;

// export default (state = defaultState, { type, payload }) => {
//     switch (type) {
//         default:
//             return state;
//     }
// }

export const defaultState = {
  userLogged: {
    isLogged: false,
    token: '',
    refreshToken: '',
    accessTokenVencimiento: '',
    user: {},
    invalidToken: false,
    lastLogOut: null,
    roles: null
  }
}

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case 'setLogged':
      return {
        userLogged: {
          isLogged: true,
          token: action.payload.token,
          refreshToken: action.payload.refreshToken,
          accessTokenVencimiento: action.payload.accessTokenVencimiento,
          user: action.payload.user,
          roles: action.payload.user.roles,
          idLocal: action.payload.user.idLocal,
          codLocal: action.payload.user.codLocal
        }
      }
    case 'logOut':
      return {
        userLogged: {
          isLogged: false,
          token: action.payload.token,
          refreshToken: action.payload.refreshToken,
          accessTokenVencimiento: action.payload.accessTokenVencimiento,
          user: action.payload.user,
          lastLogOut: new Date()
        }
      }
    case 'invalidToken':
      return {
        userLogged: {
          ...state.userLogged,
          invalidToken: action.payload.invalidToken
        }
      }
    case 'refreshTokenLogged':
      return {
        userLogged: {
          ...state.userLogged,
          token: action.payload.newToken,
          refreshToken: action.payload.newRefreshToken
        }
      }
    case 'setUserLogo':
      return {
        userLogged: {
          ...state.userLogged,
          user: {
            ...state.userLogged.user,
            logo: action.payload.logo
          }
        }
      }

    default:
      return state
  }
}
