import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { AsignarAtributoProps } from './types/atributos'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useStoreImportacion } from './store/store-importacion'
import { cleanString } from '../../../utils/utils'
import { red } from '@mui/material/colors'
import { IntegracionesContext } from '../context/integraciones.context'

const AsignarAtributo = ({
  rubro,
  paso,
  handleChange,
  handleNext,
  equivalencias,
  atributosValores
}: AsignarAtributoProps) => {
  const { tiendaSeleccionada } = useContext(IntegracionesContext)
  const loading = useStoreImportacion((state) => state.loading)
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string
  }>({})

  const atributosAllValues = useMemo(() => rubro.atributosDefault.all, [rubro.atributosDefault.all])
  const atributo = useMemo(() => rubro.atributosDefault.key, [rubro.atributosDefault.key])

  const attrs = useMemo(() => [...new Set(rubro.atributosParaAsignar)], [rubro.atributosParaAsignar])
  const atributosAllValuesOrdenado = useMemo(() => {
    if (!atributosAllValues) return []
    return atributosAllValues.valoresList.sort((a, b) => a.nombre.localeCompare(b.nombre))
  }, [atributosAllValues])

  const handleAttrChange = useCallback(
    (attrName: string, value: string) => {
      if (!atributosAllValues) return
      const nombre = value.split('-')[1]
      const id = parseInt(value.split('-')[0])

      setSelectedValues((prevValues) => ({
        ...prevValues,
        [attrName]: id + '-' + nombre
      }))

      handleChange(attrName, { id, nombre, equivalencia: attrName }, atributosAllValues, rubro.rubro.codRubro)
    },
    [atributosAllValues, handleChange, rubro.rubro.codRubro]
  )

  useEffect(() => {
    console.log('AsignarAtributo', {
      rubro,
      atributosValores,
      equivalencias
    })
  }, [])

  if (!handleNext || cleanString(atributo) !== cleanString(paso)) return null

  return (
    <TableContainer>
      <Table align="center">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>Atributo en {tiendaSeleccionada.name}</TableCell>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>Atributo en Avellaneda a un Toque</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attrs.map((a: string, i: number) => {
            // Si el valor ya existe, no mostrarlo y omitirlo
            if (
              !atributosAllValues ||
              atributosAllValues.valoresList.find((v) => v.nombre.toLowerCase() === a.toLowerCase())?.nombre
            )
              return null

            if (
              atributosValores &&
              atributosValores.find((v) => v.nombreAtributoValorExterno.toLowerCase() === a.toLowerCase())
                ?.nombreAtributoValorExterno
            )
              return null

            return (
              <TableRow key={i}>
                <TableCell width={'300px'}>
                  <Typography>
                    {a} {atributosAllValues.obligatorio && <span className={red[500]}>*</span>}
                  </Typography>
                </TableCell>
                <TableCell width={'300px'}>
                  <Select
                    displayEmpty
                    value={
                      selectedValues[a] ||
                      (equivalencias &&
                        equivalencias[a as any] &&
                        equivalencias[a as any].id + '-' + equivalencias[a as any].value) ||
                      ''
                    }
                    onChange={(e) => handleAttrChange(a, e.target.value)}
                    disabled={loading}
                    sx={{ width: '200px' }}
                  >
                    <MenuItem disabled value="">
                      <em>Seleccione</em>
                    </MenuItem>
                    {atributosAllValuesOrdenado.map((v, i) => (
                      <MenuItem key={v.id} value={v.id + '-' + v.nombre}>
                        {v.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AsignarAtributo
