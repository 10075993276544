import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant, galeriasResponseType } from "../types";

export const getGalerias = (tenant: ITenant) =>
  axiosInstanceMain.get(`/${tenant.path}/galerias?deviceId=0&clave=347ebc28653419c788302d8eba55b75a`).then((result) => {
    const response: galeriasResponseType = {
      errorCode: result.data?.errorCode,
      errorMessage: result.data?.errorMessage,
      message: result.data?.message,
      data: result.data?.data || [],
    };
    return response;
  });
