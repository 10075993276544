import { createContext, useState } from 'react'

interface OfrecerPremiumContextProps {
  open: boolean
  openPre: boolean
  onClose: () => void
  onClosePre: () => void
  onOpen: () => void
  onOpenPre: () => void
}

// create context
export const OfrecerPremiumContext = createContext<OfrecerPremiumContextProps>({
  open: false,
  openPre: false,
  onClose: () => {},
  onClosePre: () => {},
  onOpen: () => {},
  onOpenPre: () => {}
})

// create provider
export const OfrecerPremiumProvider = ({ children }: any) => {
  const [open, setOpen] = useState<boolean>(false)
  const [openPre, setOpenPre] = useState<boolean>(false)

  const onClose = () => {
    setOpen(false)
  }

  const onClosePre = () => {
    setOpenPre(false)
  }

  const onOpen = () => {
    setOpenPre(false)
    setOpen(true)
  }

  const onOpenPre = () => {
    setOpenPre(true)
  }

  return (
    <OfrecerPremiumContext.Provider
      value={{
        open,
        openPre,
        onClose,
        onClosePre,
        onOpen,
        onOpenPre
      }}
    >
      {children}
    </OfrecerPremiumContext.Provider>
  )
}
