import { Collapse, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC, memo, useEffect, useState } from 'react'
import { IProductoTienda, TiendaAtributo } from '../../../../../types/responses-productos'
import { ExpandMore } from '@mui/icons-material'

interface Props {
  producto: IProductoTienda
}

const Atributo = ({ atributo }: { atributo: TiendaAtributo }) => {
  return (
    <Box display="flex" gap="8px">
      <Typography fontSize={14} color="Black" fontWeight={'bold'}>
        {atributo.name}:
      </Typography>
      <Typography fontSize={14} color="#8E8E8E">
        {atributo.options.join(', ')}
      </Typography>
    </Box>
  )
}

const ProductoAtributosWoo: FC<Props> = ({ producto: { atributos } }) => {
  const [expand, setExpand] = useState(false)
  const [atributosFirstPart, atributosSecondPart] = atributos.reduce(
    ([firstPart, secondPart], atributo) => {
      if (firstPart.length < 2) {
        firstPart.push(atributo)
      } else {
        secondPart.push(atributo)
      }
      return [firstPart, secondPart]
    },
    [[], []]
  )

  return (
    <Box display="flex" flexDirection="column" gap={0}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          {atributosFirstPart?.map((a, i) => (
            <Atributo atributo={a} key={i} />
          ))}
        </Box>

        {atributosSecondPart?.length > 0 && (
          <>
            {' '}
            <Box onClick={() => setExpand(!expand)} display="flex" flexDirection="row-reverse" alignItems="center">
              <Box
                sx={{
                  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.25s ease-in-out',
                  transformOrigin: 'center'
                }}
              >
                <ExpandMore />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Collapse in={expand} timeout="auto" unmountOnExit>
        <Box display="flex" flexDirection="column">
          {atributosSecondPart?.map((a, i) => (
            <Atributo atributo={a} key={i} />
          ))}
        </Box>
      </Collapse>
    </Box>
  )
}

export default memo(ProductoAtributosWoo)
