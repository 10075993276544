import { useContext, useEffect, useState } from 'react'
import { getAnuncios } from '../services/home.services'
import { TenantsContext } from '../../../context/tenants.context'

export interface IAnuncio {
  idNoticia: number
  idAnuncio: number
  idAnuncioTipo: number
  anuncianteNombre: string
  nombre: string
  url_imagen: string
  urlImagen: string
  urlImagenWide: string
  detalleTexto: string
  detalleImagenList: { url: string; cta: any }[]
  detalleLink: string
  orden: number
  data: any
  premium: boolean
}

export const useAnuncios = (codLocal) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [anuncios, setAnuncios] = useState<IAnuncio[]>()
  const { tenant } = useContext(TenantsContext)

  const fetchData = async () => {
    try {
      setLoading(true)
      await getAnuncios(codLocal, tenant).then((res) => setAnuncios(res.data.data))
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!codLocal) return
    fetchData()
  }, [tenant, codLocal])

  return { anuncios, fetchData, loading }
}
