import { FC } from 'react'
import { useResponsive } from '../../hooks/useResponsive.hook'
import { MobileStepper, Step, StepConnector, StepLabel, styled, Typography } from '@mui/material'
import { Stepper as StepperMUI } from '@mui/material'

interface Props {
  length: number
  values: string[]
  active: number
}

const ColorlibConnector = styled(StepConnector)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#5983EF',
  backgroundColor: '#D9D9D9',
  marginTop: '5px',
  height: 3
}))

const CustomStepper: FC<Props> = ({ length, values, active }) => {
  const { isMobile } = useResponsive()
  return isMobile ? (
    <MobileStepper
      variant="dots"
      sx={{ width: '100%', justifyContent: 'center', m: 0, p: 0 }}
      position="static"
      steps={length}
      activeStep={active}
      backButton={null}
      nextButton={null}
    />
  ) : (
    <StepperMUI activeStep={active} alternativeLabel connector={<ColorlibConnector />}>
      {values.map((paso, index) => (
        <Step key={index}>
          <StepLabel
            StepIconProps={{
              sx: {
                color: '#E3A455',
                width: '36px',
                height: '36px'
              }
            }}
          >
            <Typography fontSize={{ xs: 10, md: 14 }} color={active >= index ? '#5983EF' : '#C7C7C7'}>
              {paso}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </StepperMUI>
  )
}

export default CustomStepper
