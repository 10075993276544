import { createContext, useState, useEffect, useCallback, useContext } from 'react'
import { IProducto } from '../types/producto'
import { getProductos, noRevisar } from '../services/productos.service'
import { INITIAL_ROWS_PER_PAGE } from '../components/Table/TableFooter'
import { borrar, vencer } from '../services/productos.service'
import { handleNewRubro } from '../services/rubros.service'
import { TenantsContext } from '../../../context/tenants.context'
import SnackBarUtils from '../../../utils/SnackBarUtils'

interface Filtros {
  codLocal: string
  codRubro: string
  nombreProducto: string
  soloActivos: boolean
  soloMalCatalogados: boolean
  publicadoDesde: string | null
  publicadoHasta: string | null
}

export interface Paginator {
  handleChangePage: (e: any, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

interface Actions {
  borrarProducto: (codProducto: string) => void
  vencerProducto: (codProducto: string) => void
  handleChangeEstado: (codProducto: string, funcion: Function, estado: string) => void
  handleChangeRubro: (codProducto: string, codRubro: string, nombreRubro: string) => void
  handleNoRevisar: (codProducto: string) => void
}

const defaultFiltros: Filtros = {
  codLocal: '',
  codRubro: '',
  nombreProducto: '',
  soloActivos: false,
  soloMalCatalogados: false,
  publicadoDesde: null,
  publicadoHasta: null
}

export const defaultPaginator: Paginator = {
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {}
}

const defaultActions: Actions = {
  borrarProducto: () => {},
  vencerProducto: () => {},
  handleChangeEstado: () => {},
  handleChangeRubro: () => {},
  handleNoRevisar: () => {}
}

interface ProductosAdminContextProps {
  productos: any[]
  productosSeleccionados: any[]
  setProductosSeleccionados: (p: any[]) => void
  puedeVencerVarios: boolean
  puedePausarVarios: boolean
  puedeReanudarVarios: boolean
  accionesMasivas: boolean
  setAccionesMasivas: (accionesMasivas: boolean) => void
  confirmDialog: any
  setConfirmDialog: (confirm: any) => void
  loading: boolean
  paginator: Paginator
  actions: Actions
  searching: boolean
  setSearching: (searching: boolean) => void
  setFiltros: (filtros: Filtros) => void
  page: number
  setPage: (page: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  editProductCode: string
  setEditProductCode: (code: string) => void
  codLocalEditing: string
  setCodLocalEditing: (code: string) => void
  clearEditing: () => void
  handleLeft: () => void
  fetchData: () => Promise<void>
}

// create context
export const ProductosAdminContext = createContext<ProductosAdminContextProps>({
  productos: [],
  productosSeleccionados: [],
  setProductosSeleccionados: () => {},
  puedeVencerVarios: false,
  puedePausarVarios: false,
  puedeReanudarVarios: false,
  accionesMasivas: false,
  setAccionesMasivas: () => {},
  confirmDialog: '',
  setConfirmDialog: () => {},
  loading: false,
  paginator: defaultPaginator,
  actions: defaultActions,
  searching: false,
  setSearching: () => {},
  setFiltros: () => {},
  page: 0,
  setPage: () => {},
  pageSize: INITIAL_ROWS_PER_PAGE,
  setPageSize: () => {},
  editProductCode: '',
  setEditProductCode: () => {},
  codLocalEditing: '',
  setCodLocalEditing: () => {},
  clearEditing: () => {},
  handleLeft: () => {},
  fetchData: async () => {}
})

// create provider
export const ProductosAdminProvider = ({ children }: any) => {
  const [productos, setProductos] = useState<IProducto[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [filtros, setFiltros] = useState<Filtros>(defaultFiltros)
  const [paginator] = useState<Paginator>(defaultPaginator)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const [actions] = useState<Actions>(defaultActions)
  const [editProductCode, setEditProductCode] = useState<string>('')
  const [codLocalEditing, setCodLocalEditing] = useState<string>(null)
  const [productosSeleccionados, setProductosSeleccionados] = useState<any[]>([])
  const [puedePausarVarios, setPuedePausarVarios] = useState<boolean>(false)
  const [puedeReanudarVarios, setPuedeReanudarVarios] = useState<boolean>(false)
  const [puedeVencerVarios, setPuedeVencerVarios] = useState<boolean>(false)
  const [accionesMasivas, setAccionesMasivas] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })
  const { tenant } = useContext(TenantsContext)

  const fetchData = useCallback(async () => {
    const data = await getProductos({ ...filtros }, tenant).then((res) => res.data)
    setProductos(data.data)
  }, [filtros])

  const clearEditing = () => {
    setEditProductCode('')
    setCodLocalEditing(null)
  }

  // Paginator
  paginator.handleChangePage = (e: any, newPage: number) => setPage(newPage)

  paginator.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Actions
  actions.handleChangeEstado = async (codProducto: string, funcion: Function, estado: string) => {
    try {
      await funcion(codProducto, tenant).then(() => {
        const newProductos = productos.map((producto) => {
          if (producto.codProducto === codProducto)
            return {
              ...producto,
              aprobado: estado === 'Aprobado',
              rechazado: estado === 'Rechazado',
              pausado: estado === 'Pausado'
            }
          else return producto
        })
        SnackBarUtils.success('Estado actualizado')
        setProductos(newProductos)
      })
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Error al actualizar el estado')
    }
  }

  actions.handleChangeRubro = async (codProducto: string, codRubro: string, nombreRubro: string) => {
    try {
      const newProductos = productos.map((producto) => {
        if (producto.codProducto === codProducto)
          return {
            ...producto,
            codRubro,
            nombreRubro
          }
        else return producto
      })
      setProductos(newProductos)
      await handleNewRubro(codProducto, codRubro, tenant).then(() => SnackBarUtils.success('Rubro actualizado'))
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Error al cambiar el rubro')
    }
  }

  actions.borrarProducto = async (codProducto: string) => {
    try {
      setProductos(productos.filter((producto) => producto.codProducto !== codProducto))
      await borrar(codProducto, tenant).then(() => SnackBarUtils.success('Producto eliminado'))
    } catch (e) {
      console.log(e)
    }
  }

  actions.vencerProducto = async (codProducto: string) => {
    try {
      /* setProductos(
        productos.filter((producto) => producto.codProducto !== codProducto)
      ); */
      await vencer([codProducto], tenant).then(() => SnackBarUtils.success('Producto vencido'))
    } catch (e) {
      console.log(e)
    }
  }

  actions.handleNoRevisar = async (codProducto: string) => {
    try {
      const newProductos = productos.map((producto) => {
        if (producto.codProducto === codProducto)
          return {
            ...producto,
            adminRevisar: false
          }
        else return producto
      })
      setProductos(newProductos)
      await noRevisar(codProducto, tenant).then(() =>
        SnackBarUtils.success(`Se eliminó la etiqueta de revisión del producto ID ${codProducto}`)
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!searching) return
    setPage(0)
    setLoading(true)

    Promise.all([fetchData()])
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filtros, searching, fetchData])

  const handleLeft = useCallback(() => {
    setProductosSeleccionados([])
    setAccionesMasivas(false)
    setPuedePausarVarios(false)
    setPuedeReanudarVarios(false)
    setPuedeVencerVarios(false)
  }, [])

  useEffect(() => {
    if (productosSeleccionados.length <= 0) return setAccionesMasivas(false)

    setAccionesMasivas(true)

    const haySoloPausados = productosSeleccionados.every((p: any) => p.pausado === true)

    const haySoloUnPausados = productosSeleccionados.every((p: any) => p.pausado === false)

    const haySoloVigentes = productosSeleccionados.every((p: any) => p.vencido === false)

    setPuedePausarVarios(!haySoloPausados || haySoloUnPausados)
    setPuedeReanudarVarios(!haySoloUnPausados || haySoloPausados)
    setPuedeVencerVarios(haySoloVigentes)
  }, [productosSeleccionados])

  return (
    <ProductosAdminContext.Provider
      value={{
        productos,
        productosSeleccionados,
        setProductosSeleccionados,
        puedeVencerVarios,
        puedePausarVarios,
        puedeReanudarVarios,
        accionesMasivas,
        setAccionesMasivas,
        confirmDialog,
        setConfirmDialog,
        loading,
        setFiltros,
        paginator,
        page,
        setPage,
        pageSize,
        setPageSize,
        actions,
        searching,
        setSearching,
        editProductCode,
        setEditProductCode,
        setCodLocalEditing,
        codLocalEditing,
        clearEditing,
        handleLeft,
        fetchData
      }}
    >
      {children}
    </ProductosAdminContext.Provider>
  )
}
