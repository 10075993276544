import { styled, useMediaQuery, useTheme } from '@mui/material'
import React, { memo, useCallback, useState, useEffect } from 'react'
import { axiosInstanceContratos, axiosInstanceMain } from '../../utils/axiosGlobalUtils'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { ContractComponentProps } from './ContractList'
import ContractMobile from './ContractMobile'
import ContractDesktop from './ContractDesktop'
import { getLinkMP } from './contracts.services'
import {
  msjAyerAntes,
  msjAyerAntesPremium,
  msjHoyMañanaPremium,
  msjMañana,
  msjPrecioActualizado,
  msjPrecioActualizadoPremium
} from './msj-whpp'

export interface SingleContractProps extends ContractComponentProps {
  loadingAccion: boolean
  loadingMP: boolean
  handleLinkMP: (id: number) => void
  executeAction: (action: any, id: number) => void
  handleWhpp: () => void
  getDateDifference: (date: string) => number
}

const ContractComponent: React.FC<ContractComponentProps> = ({
  contracts,
  contract,
  index,
  group,
  noShow,
  noShowContracts,
  setNoShowContracts,
  requestSort,
  sortConfig,
  setShowRenovations,
  setRenewContract
}: ContractComponentProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loadingMP, setLoadingMP] = useState<boolean>(false)
  const [loadingAccion, setLoadingAccion] = useState<boolean>(false)

  const getDateDifference = () => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    const endDate = new Date(
      parseInt(contract.fechaFinFormateada.split('/')[2]),
      parseInt(contract.fechaFinFormateada.split('/')[1]) - 1,
      parseInt(contract.fechaFinFormateada.split('/')[0])
    )
    const differenceInMilliseconds = currentDate.getTime() - endDate.getTime()

    // Convertir la diferencia a días
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

    // Devolver la diferencia en días
    return differenceInDays
  }

  const getMsj = () => {
    if (contract.precioActualizado && group.includes('Mañana')) {
      if (contract.productoTipoNombre === 'Premium') {
        return msjPrecioActualizadoPremium
      } else {
        return msjPrecioActualizado
      }
    }
    if (group.includes('Vencidos') && contract.productoTipoNombre === 'Premium') {
      return msjAyerAntesPremium
    } else if (group.includes('VencenHoy') && contract.productoTipoNombre === 'Premium') {
      return msjHoyMañanaPremium
    } else if (group.includes('Vencidos')) {
      return msjAyerAntes
    } else if (group.includes('Vencen')) {
      return msjMañana
    }
  }

  const handleDate = () => {
    switch (group) {
      case 'VencenHoy':
        return 'Hoy'
      case 'VencenMañana':
        return 'Mañana'
      case 'Vencen2dias':
        return 'En 2 días'
      case 'Vencen3dias':
        return 'En 3 días'
      case 'VencidosAyer':
        return 'Ayer'
      case 'VencidosAntes':
        return `Hace ${getDateDifference()} días`
      default:
        return ''
    }
  }

  const handleLinkPago = async () => {
    let link = contract.linkDePago
    await getLinkMP(contract.idContrato)
      .then((res) => (link = res.data.data?.link))
      .catch((e) => {
        console.log(e)
        SnackBarUtils.error(e.response.data)
      })
    return link
  }

  const handleWhppButton = async () => {
    let msj = getMsj()
    const cuando = handleDate()
    const link = await handleLinkPago()
    const modifiedLink = link.replace('https://', '')

    msj = msj?.replace('{{nombre producto}}', contract.productoNombre)
    msj = msj?.replace('{{precio}}', contract.precioLista.toLocaleString('es-ES'))
    msj = msj?.replace('{{cuando}}', cuando)
    msj = msj?.replace('{{link}}', modifiedLink)

    if (msj) {
      const encodedMessage = encodeURIComponent(msj)
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${contract.clienteWhatsapp}&text=${encodedMessage}`
      window.open(whatsappUrl, '_blank')
    }
  }

  const handleLinkMP = async (idContrato: number) => {
    try {
      setLoadingMP(true)
      getLinkMP(idContrato).then((data) => {
        navigator.clipboard.writeText(data.data.data.link)
        SnackBarUtils.success(data.data.message)
      })
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingMP(false)
    }
  }

  const executeAction = useCallback(
    (action: any, idContrato: number) => {
      setLoadingAccion(true)
      if (action.requestMethod === 'POST')
        axiosInstanceMain
          .post(action.url, action.payload)
          .then((result) => {
            result.status === 200 ? SnackBarUtils.success(result.data) : SnackBarUtils.error(result.data)
            if (result.status === 200) {
              setNoShowContracts([...noShowContracts, idContrato])
            }
          })
          .catch((error) => {
            console.log(error)
            SnackBarUtils.error(error.response.data)
          })
          .finally(() => {
            setLoadingAccion(false)
          })
    },
    [noShowContracts, isMobile]
  )

  return (
    <>
      {isMobile ? (
        <ContractMobile
          contracts={contracts}
          contract={contract}
          index={index}
          group={group}
          loadingAccion={loadingAccion}
          loadingMP={loadingMP}
          noShow={noShow}
          noShowContracts={noShowContracts}
          setNoShowContracts={setNoShowContracts}
          requestSort={requestSort}
          sortConfig={sortConfig}
          setShowRenovations={setShowRenovations}
          setRenewContract={setRenewContract}
          handleLinkMP={handleLinkMP}
          executeAction={executeAction}
          handleWhpp={handleWhppButton}
          getDateDifference={getDateDifference}
        />
      ) : (
        <ContractDesktop
          contracts={contracts}
          contract={contract}
          index={index}
          group={group}
          loadingAccion={loadingAccion}
          loadingMP={loadingMP}
          noShow={noShow}
          noShowContracts={noShowContracts}
          setNoShowContracts={setNoShowContracts}
          requestSort={requestSort}
          sortConfig={sortConfig}
          setShowRenovations={setShowRenovations}
          setRenewContract={setRenewContract}
          handleLinkMP={handleLinkMP}
          executeAction={executeAction}
          handleWhpp={handleWhppButton}
          getDateDifference={getDateDifference}
        />
      )}
    </>
  )
}

export default memo(ContractComponent)
