import React, { useEffect, useState } from "react";
import "../styles/sortableData.scss";
import "../styles/contractsList.scss";
import AbmLocal from "../components/AbmLocal/AbmLocal";
import { connect } from "react-redux";
import setRedirectUrl from "../redux/actions/redirection/setRedirectUrl";

const AbmLocalPage: React.FC = () => {

  return (
    <>
      <AbmLocal></AbmLocal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(AbmLocalPage);
