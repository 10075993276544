import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Container from '@mui/material/Container'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import { Paper, useMediaQuery, useTheme } from '@mui/material'
import { useLocal } from './hooks/local.hook'
import FormLocales from './components/Form'
import SoloLectura from './components/SoloLectura'
import setLogged from '../../redux/actions/logged/setLogged'
import AlertDialog from '../../components/Common/Dialog'
import Navbar from '../../components/Navbar'
import Footer from '../Home/components/Footer'

const AbmLocalPage: React.FC = (props: any) => {
  const { userLogged } = props.logged
  const { codLocal } = userLogged
  const { local, fetchData, loading: loadingLocal } = useLocal(codLocal)
  const [soloLectura, setSoloLectura] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (loading || loadingLocal)
    return (
      <AlertDialog
        title="Cargando local"
        content="Espere mientras se carga su local."
        open={loading || loadingLocal}
        hasActions={false}
      />
    )

  return (
    <>
      {local && <Navbar title={'Perfil del local'} />}
      <Container maxWidth="md">
        {isMobile ? (
          local && soloLectura ? (
            <SoloLectura
              local={local}
              setSoloLectura={setSoloLectura}
              getLocal={fetchData}
            />
          ) : (
            <FormLocales
              local={local}
              setSoloLectura={setSoloLectura}
              getLocal={fetchData}
              props={props}
              setLoading={setLoading}
            />
          )
        ) : (
          <Paper variant="outlined" sx={{ px: { md: '3em', xs: '0.5em' } }}>
            {local && soloLectura ? (
              <SoloLectura
                local={local}
                setSoloLectura={setSoloLectura}
                getLocal={fetchData}
              />
            ) : (
              <FormLocales
                local={local}
                setSoloLectura={setSoloLectura}
                getLocal={fetchData}
                props={props}
                setLoading={setLoading}
              />
            )}
          </Paper>
        )}
      </Container>
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(AbmLocalPage)
