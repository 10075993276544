import { Box, Typography } from '@mui/material'

const NoProductsTienda = () => {
  return (
    <Box
      bgcolor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight="450px"
      borderRadius="8px"
      width="100%"
    >
      <Typography fontSize={24} fontWeight="bold" align="center">
        No hay productos disponibles para importar
      </Typography>
    </Box>
  )
}

export default NoProductsTienda
