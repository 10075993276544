import { Box } from "@mui/material";
import ContactosHeader from "./ContactosHeader";
import ContactosList from "./ContactosList";

const Contactos = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 80px)",
        overflow: "hidden",
        backgroundColor: "#272727",
        color: "#fff",
        padding: "10px",
        borderRight: "1px solid #111",
      }}
    >
      <ContactosHeader />
      <ContactosList />
    </Box>
  );
};

export default Contactos;
