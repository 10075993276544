import { useState, ChangeEvent, useContext, memo, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import {
  Autocomplete,
  Button,
  MenuItem,
  FormControl,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import Loading from "../../../components/Common/Loading";
import { GaleriasContext } from "../context/galerias.context";
import CreaGalerias from "../widgets/creaGalerias";
import { isMobile } from "react-device-detect";

const FiltrosGalerias = () => {
  const [modalNewGaleria, setModalNewGaleria] = useState<boolean>(false);
  const [modalEditGaleria, setModalEditGaleria] = useState<boolean>(false);
  const { paginator, setFiltro, setSearching, loading } =
    useContext(GaleriasContext);

  // Form
  const [referencia, setReferencia] = useState<string>("");

  const handleFormSubmit = () => {
    setSearching(true);
    setFiltro(referencia);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          gap: "10px",
          // display column en mobile
          "@media (max-width: 768px)": {
            flexDirection: "column",
            gap: "14px",
          },
          margin: "10px 20px 10px 20px",
        }}
      >
        <FormControl
          sx={{
            width: "78%",
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <TextField
            onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
            label="Ingrese nombre o dirección"
            variant="outlined"
            onChange={(e: any) => setReferencia(e.target.value)}
            value={referencia}
          />
        </FormControl>

        <FormControl
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <Button
            disabled={loading}
            variant="contained"
            size="large"
            onClick={handleFormSubmit}
            fullWidth
            sx={{
              height: "56px",
              "@media (max-width: 768px)": {
                display: "flex",
                gap: "10px",
                height: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "none",
                "@media (max-width: 768px)": {
                  display: "block",
                },
              }}
            >
              Filtrar
            </Box>
            <Box
              sx={{
                padding: "6px",
                display: "block",
                "@media (max-width: 768px)": {
                  display: "flex",
                },
              }}
            >
              <FaIcons.FaSearch />
            </Box>
          </Button>
        </FormControl>
        <FormControl
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <Button
            disabled={loading}
            variant="contained"
            size="large"
            fullWidth
            sx={{
              height: "56px",
              "@media (max-width: 768px)": {
                display: "flex",
                height: "100%",
              },
            }}
            onClick={() => {
              setModalNewGaleria(true);
            }}
            color="success"
          >
            Nueva Galería
          </Button>
        </FormControl>
      </Box>
      <CreaGalerias open={modalNewGaleria} setOpen={setModalNewGaleria} />
    </>
  );
};

export default memo(FiltrosGalerias);
