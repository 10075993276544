import { FC, memo } from 'react'
import { ProductoInterno } from '../../../../types/responses-novedades'

interface Props {
  producto: ProductoInterno
}

const NovedadImage: FC<Props> = ({ producto: { descripcion } }) => {
  return (
    <img
      src={`https://picsum.photos/id/${Math.floor(Math.random() * 50) + 1}/200/300`}
      alt={descripcion}
      loading="lazy"
      style={{
        width: '115px',
        height: '115px',
        objectFit: 'cover',
        aspectRatio: '16 / 9',
        borderRadius: '8px'
      }}
    />
  )
}

export default memo(NovedadImage)
