import { Check, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues
} from "react-hook-form";
import { useState } from "react";
import { postLocalType } from "../../types";
import { Asterisco, MAX_LENGTH_NOMBRE_LOCAL, MAX_LENGTH_USUARIO } from ".";
import ModifyPasswordModal from "../../widgets/ModifyPasswordModal";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

interface FormUserDataProps {
  register: UseFormRegister<postLocalType>;
  errors: FieldErrors<postLocalType>;
  setValue: UseFormSetValue<postLocalType>;
  getValues: UseFormGetValues<postLocalType>;
  local?: LocalVendedorDTO;
  usuarioEnUso: boolean;
  checkUsuario: (value: string) => Promise<boolean>;
  checkNombreLocal: (value: string) => Promise<void>;
  isSubmitting: boolean;
  nombreLocalEnUso: boolean;
}

const FormUserData = ({
  isSubmitting,
  register,
  local,
  usuarioEnUso,
  checkUsuario,
  errors,
  checkNombreLocal,
  setValue,
  getValues,
  nombreLocalEnUso,
}: FormUserDataProps) => {
  const [nombreLocal, setNombreLocal] = useState("");
  const [username, setUsername] = useState(local?.usuario || "");
  const [usernameFocused, setUsernameFocused] = useState(false);
  const [modificandoPassword, setModificandoPassword] = useState(
    local === undefined
  );
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const handleNombreLocalChange = async (e: any) => {
    const value = e.target.value;
    setValue(`nombreLocal`, value);
    setNombreLocal(value);
    await checkNombreLocal(value);
  };

  const handleUsernameChange = async (e: any) => {
    const value = e.target.value;
    setValue(`usuario`, value);
    setUsername(value);
    await checkUsuario(value);
  };

  const handleBlur = async (value: string) => {
    if (!username) {
      const v = value
        .split(" ")
        .join("")
        .toLowerCase()
        .substring(0, MAX_LENGTH_USUARIO);
      setUsername(v);
      setValue(`usuario`, v);
      await checkUsuario(v);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="filled-nombre-local-helper-text"
              color={
                errors.nombreLocal || nombreLocalEnUso ? "error" : "primary"
              }
            >
              Nombre del local <Asterisco />
            </InputLabel>
            <OutlinedInput
              id="filled-nombre-local-helper-text"
              {...register("nombreLocal")}
              onChange={handleNombreLocalChange}
              label={
                <>
                  Nombre del local <Asterisco />
                </>
              }
              fullWidth
              color={
                errors.nombreLocal || nombreLocalEnUso ? "error" : "primary"
              }
              disabled={isSubmitting}
              inputProps={{ maxLength: MAX_LENGTH_NOMBRE_LOCAL }}
              onBlur={(e) => handleBlur(e.target.value)}
              endAdornment={
                !nombreLocalEnUso &&
                !errors.nombreLocal &&
                nombreLocal.length > 0 && (
                  <InputAdornment position="end">
                    <Check color="success" />
                  </InputAdornment>
                )
              }
            />
            <FormHelperText id="filled-nombre-local-helper-text">
              <Box
                component={"span"}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography color={"#d53839"} variant="caption">
                  {(nombreLocalEnUso && "Nombre de local en uso") ||
                    (nombreLocal === "" &&
                      errors.nombreLocal &&
                      errors.nombreLocal.message)}
                </Typography>
                {nombreLocal.length || getValues("nombreLocal")?.length || 0}/
                {MAX_LENGTH_NOMBRE_LOCAL}
              </Box>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel
              shrink={usernameFocused || username.length > 0}
              htmlFor="filled-username-helper-text"
              color={
                (username.length <= 0 && errors.usuario) || usuarioEnUso
                  ? "error"
                  : "primary"
              }
            >
              Nombre de usuario <Asterisco />
            </InputLabel>
            <OutlinedInput
              id="filled-username-helper-text"
              {...register("usuario")}
              onChange={handleUsernameChange}
              label={
                <>
                  Nombre de usuario <Asterisco />
                </>
              }
              onFocus={() => setUsernameFocused(true)}
              onBlur={() => setUsernameFocused(false)}
              autoComplete="off"
              fullWidth
              inputProps={{ maxLength: MAX_LENGTH_USUARIO }}
              color={
                (username.length <= 0 && errors.usuario) || usuarioEnUso
                  ? "error"
                  : "primary"
              }
              endAdornment={
                !usuarioEnUso &&
                !errors.usuario &&
                username.length > 0 && (
                  <InputAdornment position="end">
                    <Check color="success" />
                  </InputAdornment>
                )
              }
              disabled={isSubmitting}
            />
            <FormHelperText id="filled-username-helper-text">
              <Box
                component={"span"}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography color={"#d53839"} variant="caption">
                  {errors.usuario && errors.usuario.message}
                </Typography>
                {username.length || getValues("usuario")?.length || 0}/
                {MAX_LENGTH_USUARIO}
              </Box>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="outlined-adornment-password"
              color={errors.contrasenia ? "error" : "secondary"}
            >
              Contraseña <Asterisco />
            </InputLabel>
            <OutlinedInput
              autoComplete="off"
              id="outlined-adornment-password"
              label={
                <>
                  Contraseña <Asterisco />
                </>
              }
              type={visibilityPassword ? "text" : "password"}
              color={errors.contrasenia ? "error" : "secondary"}
              value={local && "*****"}
              disabled={isSubmitting || !modificandoPassword}
              {...register("contrasenia")}
              endAdornment={
                !local && (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={isSubmitting || !modificandoPassword}
                      onClick={() => setVisibilityPassword(!visibilityPassword)}
                      onMouseDown={() =>
                        setVisibilityPassword(!visibilityPassword)
                      }
                      edge="end"
                    >
                      {visibilityPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            {errors.contrasenia && (
              <FormHelperText
                id="outlined-adornment-password"
                sx={{ color: "#d53839" }}
              >
                {errors.contrasenia.message}
              </FormHelperText>
            )}
            {local && !modificandoPassword && (
              <FormHelperText sx={{ p: 0, m: 0, alignSelf: "flex-end" }}>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => setModificandoPassword(true)}
                >
                  Modificar contraseña
                </Button>
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {local && (
          <ModifyPasswordModal
            modificandoPassword={modificandoPassword}
            setModificandoPassword={setModificandoPassword}
            isSubmitting={isSubmitting}
            idUsuario={local.idVendedor}
          />
        )}
      </Grid>
    </Box>
  );
};

export default FormUserData;
