import React, { useState, memo, useEffect } from "react";
import { connect } from "react-redux";
import setRedirectUrl from "../../../redux/actions/redirection/setRedirectUrl";
import { Toolbar, Button, Typography, Box } from "@mui/material";
import { HomeOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { useNavigate, useLocation } from "react-router";
import SnackBarUtils from "../../../utils/SnackBarUtils";
import StatisticsIcon from "./StatisticsIcon";

const BUTTONS = [
  {
    key: "inicio",
    icon: <HomeOutlined fontSize="large" />,
    label: "Inicio",
    path: "/home",
  },
  {
    key: "suscripciones",
    icon: <RocketLaunchOutlinedIcon fontSize="large" />,
    label: "Suscripciones",
    path: "/suscripciones",
  },
  // { key: "compartir", icon: <ShareOutlined />, label: "Compartir" },
  {
    key: "estadisticas",
    icon: <StatisticsIcon />,
    label: "Estadísticas",
    path: "/estadisticas",
  },
  {
    key: "comprador",
    icon: <ShoppingCartOutlined fontSize="large" />,
    label: "Comprador",
    path: "http://www.avellanedaauntoque.com/",
  }
];

const ToolbarHome: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [active, setActive] = useState<string>("inicio");
  const [hovered, setHovered] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirected = BUTTONS.find((button: any) => button.path === location.pathname);
    if (redirected) setActive(redirected.key);
  }, [location]);

  return (
    <Toolbar
      sx={{
        width: "100%",
        justifyContent: "center",
        gap: "20px",
        //marginTop: "30px",
        display: "flex",
        zIndex: 1000,
        background: "#FFFFFF",
        ...(isMobile && {
          position: "fixed",
          bottom: 0,
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
        }),
      }}
    >
      {BUTTONS.map(({ key, icon, label, path }) => (
        <Button
          key={key}
          sx={{
            width: key === "comprador" ? "auto" : "15%",
            display: "flex",
            flexDirection: "column",
            color: active === key ? "#5983EF" : "#8E8E8E",
            gap: "10px",
            ...(!isMobile &&
              active === key && {
                borderBottom: "3.28px solid #5983EF",
              }),
          }}
          onClick={() => {
            if (key === "comprador") {
              window.open(path, "_blank");
            } else {
              setActive(key);
              navigate(path);
              if (path === "/home" && key !== "inicio") {
                SnackBarUtils.info("Próximamente!");
              }
            }
          }}
          onMouseEnter={() => setHovered(key)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              borderRadius: "16px",
              padding: "5px",
              fill: active === key ? "#5983EF" : "#8E8E8E",
              ...(isMobile &&
                active === key && {
                  backgroundColor: "#5983EF",
                  color: "#FFFFFF",
                }),
            }}
          >
            {icon}
          </div>

          <Typography
            style={{
              display: "flex",
              fontFamily: "Plus Jakarta Sans",
              fontSize: isMobile ? "7px" : "20px",
              fontWeight: 700,
              lineHeight: isMobile ? "8.82px" : "25px",
              letterSpacing: "0.15px",
              textAlign: "center",
              ...(hovered === key &&
                active !== key && {
                  background: "-webkit-linear-gradient(180deg, #FF78CE 3.35%, #5983EF 93.36%)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }),
            }}
          >
            {key === "comprador" && !isMobile ? "Ver como comprador" : label}
          </Typography>
        </Button>
      ))}
    </Toolbar>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {
  setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ToolbarHome));
