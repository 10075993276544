import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  Button,
  Box
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { AlignHorizontalCenter } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SnackBarUtils from "../../../utils/SnackBarUtils";

interface NoContactoProps {
  codigoPedido: any;
}

const DialogNoContacto: React.FC<NoContactoProps> = ({ codigoPedido }) => {
  /*
  <corners android:radius="5dp"/><gradient
  android:angle="270"    android:startColor="#55CF60"
  android:endColor="#27B53F"    android:type="linear"/> */

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codigoPedido);
    setCopied(true);
  };

  const openWhatsAppWeb = () => {
    window.open("http://web.whatsapp.com/", "_blank");
  };

  useEffect(() => {
    if (copied) {
      SnackBarUtils.success("Código copiado con éxito!");
    }
  }, [copied]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 100, color: "blue" }} />
      </Box>

      <Typography
        variant="h5"
        sx={{
          marginBottom: "26px",
          textAlign: "center",
          fontWeight: "bold",
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
      >
        El comprador no nos dejó su número, pero podés buscar la conversación
        por el código de pedido.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "12px",
        }}
      >
        <Typography variant="h6">Código de pedido: {codigoPedido}</Typography>
        <ContentCopyTwoToneIcon
          sx={{ justifyContent: "center", ml: "auto", cursor: "pointer" }}
          onClick={copyToClipboard}
          color={copied ? "primary" : "action"}
          titleAccess="Copiar código"
        />
      </Box>
      <Button
        variant="contained"
        color="success"
        fullWidth
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          background: `linear-gradient(45deg, #55CF60, #27B53F)`,
          border: "none",
          borderRadius: "5px",
          color: "#fff",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
        onClick={openWhatsAppWeb}
      >
        <WhatsAppIcon sx={{ marginRight: "8px", marginBottom: "4px" }} />
        Whatsapp
      </Button>
    </Box>
  );
};

export default DialogNoContacto;
