import { memo, useState, useEffect } from 'react'

// Material UI
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  InputLabel,
  Autocomplete,
  TextField,
  MenuItem,
  Typography
} from '@mui/material'

import { Close } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

// form validation
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ICtaCte, ITipoMovimiento, MedioPago } from '../../../dto/ContractDTO'
import { editCtaCte, getTiposMovimiento } from '../services/cta.cte.services'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import SnackBarUtils from '../../../utils/SnackBarUtils'

const MAX_OBSERVACIONES_LENGTH = 100

interface EditMovimientoProps {
  open: boolean
  setOpen: (open: boolean) => void
  idMovimiento: number
  item: any
  setItem: any
  mediosDePago: MedioPago[]
  updateCtaCte: (idLocal: number) => void
}

const EditarMovimiento = ({
  open,
  setOpen,
  idMovimiento,
  item,
  setItem,
  mediosDePago,
  updateCtaCte
}: EditMovimientoProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState<boolean>(false)
  const [tiposMovimiento, setTiposMovimiento] = useState<ITipoMovimiento[]>(null)
  const [tipoSeleccionado, setTipoSeleccionado] = useState<ITipoMovimiento>(null)
  const [medioDePago, setMedioDePago] = useState<MedioPago>(null)
  const [fecha, setFecha] = useState<any>(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const getTipos = async () => {
    setLoading(true)
    await getTiposMovimiento()
      .then((res) => setTiposMovimiento(res.data.data.results))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (open && !tiposMovimiento) getTipos()
  }, [open])

  useEffect(() => {
    if (!tiposMovimiento || !mediosDePago || !item) return
    tiposMovimiento.map((t: ITipoMovimiento) => {
      if (t.nombre === item.movimientoTipo) {
        setTipoSeleccionado(t)
        setValue('tipoMovimiento', t?.idContratoCuentaCorrienteMovimientoTipo || '')
      }
    })
    mediosDePago.map((m: MedioPago) => {
      if (m.nombreRequest === item.medioDePago?.toLowerCase()) setMedioDePago(m)
    })
    if (item.fechaFormateada) {
      const fechaDayjs = dayjs(item.fechaFormateada)
      fechaDayjs && setFecha(fechaDayjs)
    }
  }, [idMovimiento, open, tiposMovimiento])

  const schema: yup.AnyObjectSchema = yup.object({
    tipoMovimiento: yup.string().required('Elija un tipo de movimiento.'),
    monto: yup
      .number()
      .required('El monto es obligatorio')
      .typeError('el monto debe ser un número')
      .nullable()
      .transform((_, value) => {
        if (value === '') return null
        if (typeof value === 'string') {
          value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
          value = parseFloat(value)
        }
        return +value
      })
      .min(0, 'El precio debe ser positivo o igual a cero'),
    comision: yup
      .number()
      .typeError('La comisión debe ser un número')
      .nullable()
      .transform((_, value) => {
        if (value === '') return null
        if (typeof value === 'string') {
          value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
          value = parseFloat(value)
        }
        return +value
      })
      .min(0, 'El precio debe ser positivo o igual a cero')
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const onSubmit = async () => {
    const fechaFormateada = new Date(fecha)
    const updatedItem: ICtaCte = {
      idContratoProducto: item.idProducto,
      movimientoTipo: tipoSeleccionado.idContratoCuentaCorrienteMovimientoTipo,
      monto: item.movimiento,
      medioDePago: tipoSeleccionado?.debeOHaber === 'D' ? null : medioDePago?.idContratoMedioDePago,
      comisionMedioDePago: tipoSeleccionado?.debeOHaber === 'D' ? null : item.comisionMedioDePago,
      observaciones: item.observaciones,
      fecha: fecha ? fechaFormateada : null
    }

    try {
      setLoading(true)
      await editCtaCte(idMovimiento, updatedItem).then(() => {
        SnackBarUtils.success('Movimiento actualizado correctamente')
        updateCtaCte(item.idClienteLocal)
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const handleClose = () => {
    reset()
    setItem(null)
    setOpen(false)
  }

  if (!tiposMovimiento || !mediosDePago || !item) return
  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Edición del Movimiento
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack sx={{ width: '100%', margin: '20px 0' }} spacing={1}>
              <Stack sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px' }}>
                <Stack sx={{ width: isMobile ? '100%' : '70%' }}>
                  <InputLabel>Tipo de movimiento</InputLabel>
                  <Autocomplete
                    disabled={loading}
                    options={tiposMovimiento}
                    getOptionLabel={(t: ITipoMovimiento) => t.nombre || ''}
                    onChange={(event, value) => setTipoSeleccionado(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={!!errors.medioDePago}
                        helperText={errors.medioDePago?.message as string}
                      />
                    )}
                    value={tipoSeleccionado}
                    renderOption={(props, tipo) => (
                      <MenuItem
                        {...props}
                        key={tipo.idContratoCuentaCorrienteMovimientoTipo}
                        value={tipo.idContratoCuentaCorrienteMovimientoTipo}
                      >
                        {tipo.nombre}
                      </MenuItem>
                    )}
                  />
                </Stack>
                <TextField
                  label="Monto"
                  {...register('monto')}
                  error={!!errors.monto}
                  helperText={errors?.monto && String(errors?.monto?.message)}
                  defaultValue={item.movimiento}
                  onChange={(e) => setItem({ ...item, movimiento: e.target.value })}
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: isMobile ? '20px' : '23px', width: isMobile ? '100%' : '30%' }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }} spacing={1}>
              <Stack sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px' }}>
                <Stack sx={{ width: isMobile ? '100%' : '50%' }}>
                  <InputLabel>Medio de Pago</InputLabel>
                  <Autocomplete
                    disabled={loading || tipoSeleccionado?.debeOHaber === 'D'}
                    options={mediosDePago}
                    getOptionLabel={(m: MedioPago) => m.nombre || ''}
                    onChange={(event, value) => setMedioDePago(value || null)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={!!errors.medioDePago}
                        helperText={errors.medioDePago?.message as string}
                      />
                    )}
                    value={tipoSeleccionado?.debeOHaber === 'D' ? null : medioDePago}
                    renderOption={(props, medio) => (
                      <MenuItem {...props} key={medio.idContratoMedioDePago} value={medio.idContratoMedioDePago}>
                        {medio.nombre}
                      </MenuItem>
                    )}
                  />
                </Stack>
                <TextField
                  label="Comisión"
                  {...register('comision')}
                  disabled={loading || tipoSeleccionado?.debeOHaber === 'D'}
                  defaultValue={tipoSeleccionado?.debeOHaber === 'D' ? null : item.comisionMedioDePago}
                  error={!!errors.comision}
                  helperText={errors?.comision && String(errors?.comision?.message)}
                  onChange={(e) => setItem({ ...item, comisionMedioDePago: e.target.value })}
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: isMobile ? '20px' : '23px', width: isMobile ? '100%' : '50%' }}
                />
              </Stack>
            </Stack>
            <Stack direction="column" sx={{ margin: '20px 0' }} spacing={1}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Observaciones
                <Typography variant="subtitle2">
                  {item.observaciones?.length || 0}/{MAX_OBSERVACIONES_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                multiline
                rows={4}
                fullWidth
                defaultValue={item.observaciones}
                onChange={(e) => setItem({ ...item, observaciones: e.target.value })}
                inputProps={{ maxLength: MAX_OBSERVACIONES_LENGTH }}
              />
            </Stack>
            <Stack
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              direction="column"
              sx={{ margin: '20px 0' }}
              spacing="20px"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={loading}
                  label="Fecha"
                  format="DD/MM/YYYY"
                  value={fecha}
                  onChange={(date: any) => {
                    date ? setFecha(date) : setFecha(null)
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      value: fecha,
                      InputProps: {
                        ...(fecha &&
                          isHovered && {
                            endAdornment: (
                              <>
                                {fecha && (
                                  <IconButton onClick={() => setFecha(null)} edge="end">
                                    <Close />
                                  </IconButton>
                                )}
                              </>
                            )
                          })
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Stack>{' '}
          </DialogContent>
          <DialogActions
            sx={{
              diplay: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-end',
              justifyContent: 'space-between',
              width: '100%',
              padding: '22px',
              paddingTop: 0,
              gap: '8px',
              position: 'relative'
            }}
          >
            <Button color="inherit" onClick={handleClose}>
              Volver
            </Button>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" /* disabled={loading} */ type="submit">
                Modificar
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default memo(EditarMovimiento)
