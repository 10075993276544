import { useState, MouseEvent, ReactNode } from 'react'
import { Button, ButtonTypeMap, Menu } from '@mui/material';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ButtonDesplegableProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  color?: ButtonTypeMap['props']['color']
  variant?: ButtonTypeMap['props']['variant']
  isLoading?: boolean
  children: ReactNode
}

const ButtonDesplegable = ({ label, color="primary", variant="contained", isLoading, className, children }: ButtonDesplegableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={variant}
        color={color}
        disabled={isLoading}
        endIcon={label ? isLoading ? false : anchorEl ? <AiFillCaretUp /> : <AiFillCaretDown /> : false}
      >
        {
          label
            ? isLoading ? "Wait" : label
            : <MoreVertIcon />
        }
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={className}
      >
        {children}
      </Menu>
    </div>
  )
}

export default ButtonDesplegable
