import { Box, Typography } from '@mui/material'
import { ImportarData, ResultadoFinal } from './types/importacion'
import { IoMdCheckmarkCircleOutline as CheckIcon } from 'react-icons/io'
import { FiAlertCircle as ErrorIcon } from 'react-icons/fi'
import { PiWarningCircle as TipIcon } from 'react-icons/pi'
import { IoIosCheckmarkCircleOutline as SuccessIcon } from 'react-icons/io'
import { memo } from 'react'

interface Props {
  resultado: ResultadoFinal | null
  dataToSend: ImportarData[]
}

enum ResultadoFinalStatus {
  'ok' = 0,
  'error' = 1,
  'tip' = 2
}

const iconStyles = (color: string, fontSize = 120) => {
  return { fontSize, color }
}

const Item = memo(({ status, text }: { status: ResultadoFinalStatus; text: string }) => {
  const icon =
    status === ResultadoFinalStatus.ok ? (
      <SuccessIcon style={iconStyles('#46A046', 35)} />
    ) : status === ResultadoFinalStatus.error ? (
      <ErrorIcon style={iconStyles('#FF9191', 35)} />
    ) : status === ResultadoFinalStatus.tip ? (
      <TipIcon style={iconStyles('#D5A000', 35)} />
    ) : null

  return (
    <Box width="100%" display="flex" gap="12px" p="30px 40px" alignItems="center" bgcolor="#ECECEC" borderRadius="12px">
      {icon}
      <Typography fontSize={{ xs: 12, md: 16 }} color="#555555" fontWeight="medium">
        {text}
      </Typography>
    </Box>
  )
})

const Resultado = ({ resultado }: Partial<Props>) => {
  if (!resultado) return null

  return (
    <>
      {resultado.lineasProcesadas > 0 && (
        <Item status={ResultadoFinalStatus.ok} text={`Se importaron ${resultado.lineasProcesadas} productos`} />
      )}
      {resultado.lineasIgnoradas > 0 && (
        <Item status={ResultadoFinalStatus.tip} text={`Se ignoraron ${resultado.lineasIgnoradas} productos`} />
      )}
      {resultado.lineasErroneas > 0 && (
        <Item
          status={ResultadoFinalStatus.error}
          text={`Hubo un error al importar ${resultado.lineasErroneas} productos, verificá los datos y volvé a intentarlo.`}
        />
      )}
    </>
  )
}

const MensajeFinal = ({ resultado, dataToSend }: Props) => {
  if (!resultado) return null

  const title = resultado.lineasErroneas === 0 ? 'Importación exitosa' : 'Importación incompleta'
  const icon =
    resultado.lineasErroneas === 0 ? (
      <CheckIcon style={iconStyles('#91B2FF')} />
    ) : (
      <ErrorIcon style={iconStyles('#ff3200')} />
    )

  if (resultado.customError) {
    return (
      <Typography variant="h5" color="red" textAlign={'center'}>
        {resultado.customError}
      </Typography>
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap="16px"
      mt={-6}
      borderBottom={'1px solid #B4B4B4'}
      pb={4}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        {icon}
        <Typography fontSize={30} color="#1C1C1C" fontWeight="bold">
          {title}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" gap="16px" width="100%">
        <Resultado resultado={resultado} />
        <Box
          hidden={resultado.productosError.length <= 0}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          gap="16px"
          width="100%"
        >
          <Typography variant="h5" alignSelf="flex-start">
            Errores identificados:
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            gap="16px"
            px="16px"
            maxHeight="300px"
            overflow="auto"
            width="100%"
          >
            {resultado.productosError.map((error, i) => (
              <Item key={i} status={ResultadoFinalStatus.error} text={`Línea ${error.fila} - ${error.mensajeError}`} />
            ))}
          </Box>
        </Box>

        {resultado.lineasErroneas <= 0 && (
          <Item
            status={ResultadoFinalStatus.tip}
            text="Recordá que ahora debés cargarle fotos a tus nuevos productos, y elegir cuáles querés que se publiquen"
          />
        )}
      </Box>
    </Box>
  )
}

export default MensajeFinal
