import { create } from 'zustand'

export interface IAppStore {
  idLocal: number
  setIdLocal: (idLocal: number) => void
  codLocal: string
  setCodLocal: (codLocal: string) => void
}

export const useAppStore = create<IAppStore>((set) => ({
  idLocal: 0,
  setIdLocal: (idLocal: number) => set({ idLocal }),
  codLocal: "",
  setCodLocal: (codLocal: string) => set({ codLocal })
}))
