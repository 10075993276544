import React, { useContext, useState } from "react";
import { Box, Button, Container, TextField, Typography, useTheme, useMediaQuery } from "@mui/material";
import { EnviarMensajeContacto } from "./services/contacto.service";
import AlertDialog from "../../components/Common/Dialog";
import { connect } from "react-redux";
import SnackBarUtils from "../../utils/SnackBarUtils";
import FormRecaptcha from "../AbmLocalPage/components/Form/FormRecaptcha";
import Navbar from "../../components/Navbar";
import Footer from "../Home/components/Footer";
import { TenantsContext } from "../../context/tenants.context";
import { useAppStore } from "../../store/app-store";

const MAX_LENGTH = 500;
const MIN_LENGTH = 5;

const Contactanos = () => {
  const codLocal = useAppStore((store) => store.codLocal)
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [, setToken] = useState("");
  const { tenant } = useContext(TenantsContext);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setMessage(value);

    if (value.length < MIN_LENGTH) return setError(`El mensaje debe tener al menos ${MIN_LENGTH} caracteres`);
    if (value.length > MAX_LENGTH) return setError(`El mensaje debe tener menos de ${MAX_LENGTH} caracteres`);

    setError("");
  };

  const handleSubmit = async () => {
    if (message.length < MIN_LENGTH || message.trim().length < MIN_LENGTH)
      return setError(`El mensaje debe tener al menos ${MIN_LENGTH} caracteres`);
    if (message.length > MAX_LENGTH) return setError(`El mensaje debe tener menos de ${MAX_LENGTH} caracteres`);

    setLoading(true);

    try {
      await EnviarMensajeContacto(codLocal, message, tenant);
      SnackBarUtils.success("Mensaje enviado con éxito");
      setMessage("");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar title="Contactanos" />
      <AlertDialog open={loading} title="Enviando mensaje" content="Espere mientras se envía su mensaje." />

      <Container maxWidth="md">
        <Box marginTop={2} marginBottom={2} display="flex" flexDirection="column" gap="20px">
          <Typography variant={isMobile ? "body2" : "h6"}>
            Por acá podes contactarte directamente con el equipo de Avellaneda a un Toque.
          </Typography>

          <TextField
            multiline
            fullWidth
            rows={isMobile ? 12 : 20}
            label="Escribe tu mensaje aquí"
            placeholder="Si se trata de un error, cuantos más detalles puedas aportar, mejor (cómo se comportó la app, qué esperabas que hiciera, etc)"
            value={message}
            onChange={handleChange}
            helperText={
              <Box component={"span"} sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography color={"#d53839"} variant="caption">
                  {error}
                </Typography>
                {message.length || 0}/{MAX_LENGTH}
              </Box>
            }
            color={error ? "error" : "primary"}
            disabled={loading}
          />

          <Button
            variant="contained"
            color="primary"
            disabled={error !== "" || loading || message === ""}
            fullWidth
            onClick={handleSubmit}
          >
            {loading ? "Enviando" : "Enviar"}
          </Button>

          <FormRecaptcha name="Mensaje_de_contacto" setToken={setToken} />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Contactanos);
