import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Box,
  IconButton,
  TablePagination
} from "@mui/material";
import { PaginatorPedidos } from "../../context/pedidos.context";
import { IPedido } from "../../types/pedido";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export const TablePaginationActions = (
  props: TablePaginationActionsProps & { hasNextPage: boolean }
) => {
  const { count, page, rowsPerPage, onPageChange, hasNextPage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!hasNextPage}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

interface TablaPedidosPaginationProps {
  component?: React.ElementType<any>;
  pedidos: IPedido[];
  paginator: PaginatorPedidos;
}

const TablaPedidosPagination = ({
  component = "div",
  pedidos,
  paginator,
}: TablaPedidosPaginationProps) => {
  const { pageNumber, pageSize, hasNextPage, handleChangePage } = paginator;
  return (
    <TablePagination
      width={"auto"}
      component={component}
      rowsPerPageOptions={[]}
      count={pedidos.length}
      rowsPerPage={pageSize}
      page={pageNumber}
      onPageChange={handleChangePage}
      ActionsComponent={(props) => (
        <TablePaginationActions {...props} hasNextPage={hasNextPage} />
      )}
      labelDisplayedRows={({ from, to, count }) =>
        `${1}-${count} - Página ${pageNumber}`
      }
      sx={{
        "& div": {
          display: "flex",
        },
        "& p": {
          alignSelf: "end",
        },
      }}
    />
  );
};

export default TablaPedidosPagination;
