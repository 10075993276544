import { useState, useEffect, useContext } from "react";
import { Box, styled } from "@mui/material";
import { ComprarHistoria, GetStatus } from "../../pages/AdminLocal/services/historias.service";
import { StatusHistorias } from "../../pages/AdminLocal/types";
import AlertDialog from "../Common/Dialog";
import { LocalAdminDTO } from "../../dto/LocalDTO";
import SnackBarUtils from "../../utils/SnackBarUtils";
import { useMediaQuery, useTheme } from "@mui/material";
import { TenantsContext } from "../../context/tenants.context";

const ContentTable = styled("div")(({ isMobile }: { isMobile: boolean }) => ({
  width: isMobile ? "95%" : "97%",
}));

interface Props {
  premise: LocalAdminDTO;
}

const BUTTONS = [
  {
    id: 1,
    name: "-1",
    value: -1,
    color: "danger",
  },
  {
    id: 2,
    name: "-5",
    value: -5,
    color: "danger",
  },
  {
    id: 3,
    name: "+1",
    value: 1,
    color: "success",
  },
  {
    id: 4,
    name: "+5",
    value: 5,
    color: "success",
  }
];

const AdminLocalHistorias = ({ premise }: Props) => {
  const [status, setStatus] = useState<StatusHistorias | null>(null);
  const [compradas, setCompradas] = useState<number>(0);
  const [numeroCompras, setNumeroCompras] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const { codLocal, nombre } = premise;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { tenant } = useContext(TenantsContext);

  const handleOpen = (cant: number) => {
    setNumeroCompras(cant);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setNumeroCompras(0);
    }, 500);
  };

  const handleCompra = async (cant: number) => {
    try {
      if (compradas + cant < 0) return;
      setCompradas((prev) => prev + cant);
      await ComprarHistoria(codLocal, cant, tenant);
      SnackBarUtils.success(
        `Se ${cant > 0 ? (cant === 1 ? "agregó" : "agregaron") : cant === -1 ? "restó" : "restaron"}
         ${Math.abs(cant)} ${Math.abs(cant) > 1 ? "historias" : "historia"}`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setTimeout(() => {
        setNumeroCompras(0);
      }, 500);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await GetStatus(codLocal, tenant);
        if (!res.data) {
          setStatus(null);
          setCompradas(0);
        } else {
          setStatus(res.data.data);
          setCompradas(res.data.data.cantTotalCompradas);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [codLocal]);
  return (
    <ContentTable isMobile={isMobile}>
      <table className="table table-striped table-bordered premise-table">
        <thead>
          <tr
            style={{
              ...(isMobile && {
                fontSize: "14px",
                whiteSpace: "normal",
              }),
            }}
          >
            <th>Cantidad Compradas</th>
            <th>Cantidad Publicadas</th>
            <th>Cantidad Publicadas Ahora</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              ...(isMobile && { fontSize: "14px" }),
            }}
          >
            <td>{compradas ? compradas : 0}</td>
            <td>{status ? status.cantTotalPublicadas : 0}</td>
            <td>{status?.historiasPublicadasAhora?.itemList?.length || 0}</td>
            <td>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {BUTTONS.map((button) => (
                  <button
                    key={button.id}
                    className={`btn btn-${button.color}`}
                    onClick={() => handleOpen(button.value)}
                    style={{
                      ...(isMobile && {
                        fontSize: "small",
                        padding: "2px",
                        width: isMobile ? "25px" : "auto",
                        height: isMobile ? "25px" : "auto",
                      }),
                    }}
                  >
                    {button.name}
                  </button>
                ))}
              </Box>
            </td>
          </tr>
        </tbody>
      </table>

      <AlertDialog
        open={open}
        title="Historias"
        content={`¿${numeroCompras < 0 ? "Restar" : "Agregar"} ${Math.abs(numeroCompras)} ${
          Math.abs(numeroCompras) > 1 ? "historias" : "historia"
        } al local ${nombre}?`}
        onConfirm={() => handleCompra(numeroCompras)}
        onClose={handleClose}
        agreeText="Confirmar"
        disagreeText="Cancelar"
        disagreeColor="error"
      />
    </ContentTable>
  );
};

export default AdminLocalHistorias;
