import { IFiltrosHistorial } from '../../dto/ContractDTO'
import { axiosInstanceContratos } from '../../utils/axiosGlobalUtils'

export const getRenovables = async () => await axiosInstanceContratos.get(`/contratos/renovables`)

export const getLinkMP = async (idContrato: number) =>
  await axiosInstanceContratos.post(`/contratos/${idContrato}/links-de-pago`, {
    platform: 'mercadopago',
    source: 'whatsapp'
  })

export const getHistorial = async ({ idContratoProducto, fechaDesde, fechaHasta, size, page }: IFiltrosHistorial) => {
  const params = new URLSearchParams({
    size: String(size),
    page: String(page),
    ...(idContratoProducto && {
      idContratoProducto: idContratoProducto.toString()
    }),
    ...(fechaDesde && { fechaDesde }),
    ...(fechaHasta && { fechaHasta })
  })

  const url = `/contratos/historial?${params.toString()}`
  return await axiosInstanceContratos.get(url)
}

export const getMediosPago = async () => await axiosInstanceContratos.get(`/contratos/medios-de-pagos?size=10&page=0`)
