import React, { useEffect, useState } from "react";
import "../../styles/contractsRenovations.scss";
import { axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import * as GrIcons from "react-icons/gr";
import { copyToClipboard } from "../../utils/utils";
import { styled } from "@mui/material/styles";

const Info = styled("div")(({ theme }) => ({
  fontSize: "calc(15px + 1vmin)",
  fontFamily: theme.typography.fontFamily,
  margin: "0 15px",
}));

const ContentPassword = styled("div")(({ theme }) => ({
  fontSize: "calc(15px + 3vmin)",
  fontFamily: theme.typography.fontFamily,
  display: "flex",
  justifyContent: "center",
}));

const ContentButton = styled("div")(({ theme }) => ({
  fontSize: "calc(10px)",
  fontFamily: theme.typography.fontFamily,
  display: "flex",
  justifyContent: "center",
  marginTop: "15px",
  [theme.breakpoints.down(425)]: {
    marginTop: "30px",
  },
}));

const Password = styled("div")({
  marginRight: "15px",
});

const ContentLine = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(425)]: {
    marginBottom: "30px",
  },
}));

interface Props {
  newPassword?: string;
  user?: string;
  getUrl?: string;
  handleClose?: any;
  headers?: string[];
  show?: string;
  title?: string;
  attributesToShow?: string[];
}

const ModalCambioClave: React.FC<any> = (props: Props) => {
  const [result, setResult] = useState<any[]>([]);

  const showHideClassName = props.show
    ? "modal display-block"
    : "modal display-none";

  useEffect(() => {
    if (
      props &&
      result.length === 0 &&
      props.getUrl &&
      !props.getUrl.includes("undefined")
    ) {
      axiosInstanceMain
        .get(props.getUrl)
        .then((result) => {
          setResult(result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props]);

  const closeRenovations = () => {
    props.handleClose();
  };

  const copyPassToClipboard = async () => {
    if (props && props.newPassword) {
      await copyToClipboard(props.newPassword.toString());
    }
  };

  const copyUserToClipboard = async () => {
    if (props && props.user) {
      await copyToClipboard(props.user.toString());
    }
  };

  const copyUserAndPassToClipboard = async () => {
    if (props && props.user && props.newPassword) {
      await copyToClipboard(
        "Su USUARIO es: " +
          props.user.toString() +
          "\n\nSu NUEVA CONTRASEÑA es: " +
          props.newPassword.toString() +
          "\n\n⚠️ Recuerde que desde modificar perfil puede modificarla para mayor seguridad." +
          "\n\n¡Saludos! Equipo de Avellaneda a un Toque"
      );
    }
  };

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main"
        style={{ height: "45%", overflow: "auto" }}
      >
        <header
          className="contract-actions-header"
          style={{ margin: "0px", height: "7vh" }}
        >
          <h3 className="contract-actions-title">
            {/* {(props.contract?.cantidad > 1
              ? props.contract?.productoNombre + "x" + props.contract?.cantidad
              : props.contract?.productoNombre) +
              ` Local ID ${props.contract?.idContratoClienteLocal}`} */}
            {props.title !== undefined && props.title}
          </h3>
          <button
            className="modal-close"
            onClick={closeRenovations}
            aria-label="Cerrar"
          >
            <GrIcons.GrClose />
          </button>
        </header>
        <div>
          <ContentLine>
            <div>
              <Info>Usuario:</Info>
            </div>
            <ContentPassword>
              <Password>{props.user}</Password>
              <button
                title="Copiar al portapapeles"
                className="btn btn-link admin-local-btn premise-info-child"
              >
                <GrIcons.GrClipboard onClick={copyUserToClipboard} />
              </button>
            </ContentPassword>
          </ContentLine>
          <ContentLine>
            <div>
              <Info>Se cambió la clave a:</Info>
            </div>
            <ContentPassword>
              <Password>{props.newPassword}</Password>
              <button
                title="Copiar al portapapeles"
                className="btn btn-link admin-local-btn premise-info-child"
              >
                <GrIcons.GrClipboard onClick={copyPassToClipboard} />
              </button>
            </ContentPassword>
          </ContentLine>
          <ContentLine>
            <ContentButton>
              <button
                title="Copiar al portapapeles"
                className="btn btn-secondary admin-local-btn"
                onClick={() => {
                  copyUserAndPassToClipboard();
                  closeRenovations();
                }}
              >
                {"Copiar usuario y contraseña "}
              </button>
            </ContentButton>
          </ContentLine>
        </div>
      </section>
    </div>
  );
};

export default ModalCambioClave;
