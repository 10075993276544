import React from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { fontSize } from '@mui/system'

interface CardProductoProps {
  producto: any
  isMobile: boolean
}

const CardProducto: React.FC<CardProductoProps> = ({ producto, isMobile }) => {
  const handleCantidad = (
    cantidad: string,
    singular: string,
    plural: string
  ) => {
    if (parseInt(cantidad) > 1) {
      return plural
    } else {
      return singular
    }
  }

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '2vh',
        width: '100%',

        height: isMobile ? '18vh' : '15vh'
      }}
    >
      <CardMedia
        sx={{
          //width: "20%",
          aspectRatio: '1',
          flexShrink: 0
        }}
      >
        <img
          src={producto.fotoUrlThumb}
          alt="imagen"
          style={{
            width: isMobile ? '18vh' : '15vh',
            height: isMobile ? '18vh' : '15vh',
            objectFit: 'cover'
          }}
        />
      </CardMedia>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'flex-start',
          padding: '2vh',
          '&:last-child': {
            paddingBottom: '2vh'
          },
          width: '80%'
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: '-4px',
            // Añade estilo condicional para la ellipsis en vista movil
            ...(isMobile && {
              whiteSpace: 'initial',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              fontSize: '18px',
              lineHeight: '18px',
              marginBottom: '4px'
            })
          }}
          title={producto.nombre}
        >
          {producto.nombre}
        </Typography>
        {producto.observaciones && (
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontSize: isMobile ? '12px' : undefined,
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {producto.observaciones}
          </Typography>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 'auto',
            marginBottom: '-8px'
          }}
        >
          <Typography
            variant="subtitle1"
            style={{ fontSize: isMobile ? '14px' : undefined }}
          >
            {producto.moneda?.simbolo}
            {new Intl.NumberFormat('es-AR').format(
              parseFloat(producto.precioUnitario)
            )}
          </Typography>
          {producto.unidadDeMedida && (
            <Typography
              variant="subtitle1"
              style={{ fontSize: isMobile ? '14px' : undefined }}
            >
              {`${producto.cantidad} ${handleCantidad(
                producto.cantidad,
                producto.unidadDeMedida.nombreSingular,
                producto.unidadDeMedida.nombrePlural
              )}`}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default CardProducto
