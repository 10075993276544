import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant } from "../../AbmLocalPage/types";

interface ChangePasswordParams {
  idUsuario: number;
  contraseniaVieja: string;
  contraseniaNueva: string;
}

export const changePassword = async (
  { idUsuario, contraseniaVieja, contraseniaNueva }: ChangePasswordParams,
  tenant: ITenant
) => {
  return await axiosInstanceMain.patch(
    `${tenant.path}/auth/usuario/${idUsuario}/cambiar-contrasenia`,
    { contraseniaVieja, contraseniaNueva }
  );
};
