import { useState, useEffect, memo, useContext } from 'react'

// Material UI
import {
  InputLabel,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from '@mui/material'

import { Close, Info } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

// form validation
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Rubro } from '../types/rubro'
import { createRubro } from '../services/rubros.operaciones'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import Loading from '../../../components/Common/Loading'
import DesplegableRubrosPadres, { flattenRubrosHierarchy } from '../components/desplegableRubrosPadres'
import { useDataABMRubros } from '../services/datos.seleccion'
import { ChromePicker, HuePicker } from 'react-color'
import KeywordsSelect from './keywordsWidget'
import { TenantsContext } from '../../../context/tenants.context'
import { getAllRubros } from '../../AdminProducts/services/rubros.service'

const MAX_NOMBRE_LENGTH = 85
const MAX_URL_LENGTH = 150
const MAX_COLOR_LENGTH = 6
const MAX_SLUG_LENGTH = 45

const CreaRubro = (props: any) => {
  const { open, setOpen } = props
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [maxOrden, setMaxOrder] = useState<number>(0)
  const [nombreRubro, setNombreRubro] = useState<string>('')
  const [nombreCompleto, setNombreCompleto] = useState<string>('')
  const [slug, setSlug] = useState<string>('')
  const [urls, setUrls] = useState<string[]>([])
  const [rubroPadre, setRubroPadre] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const [unidadesSeleccionadas, setUnidadesSeleccionadas] = useState<any>([])
  const [atributosSeleccionados, setAtributosSeleccionados] = useState<any>([])
  const [monedasSeleccionadas, setMonedasSeleccionadas] = useState<any>([])
  const [listable, setListable] = useState<boolean>(true)
  const [showHue, setShowHue] = useState<boolean>(false)
  const [isDesplegableSelected, setIsDesplegableSelected] = useState<boolean>(false)
  const [keywords, setKeywords] = useState<string>('')
  const { atributos, monedas, unidadesDeMedidaDisponibles } = useDataABMRubros()
  const { tenant } = useContext(TenantsContext)

  const handleUnidadSeleccionadaChange = (unidadId: number) => {
    if (unidadesSeleccionadas.some((unidad: any) => unidad.idUnidadDeMedida === unidadId)) {
      setUnidadesSeleccionadas(unidadesSeleccionadas.filter((unidad: any) => unidad.idUnidadDeMedida !== unidadId))
    } else {
      setUnidadesSeleccionadas([...unidadesSeleccionadas, { idUnidadDeMedida: unidadId }])
    }
  }

  const handleAtributoSeleccionado = (atributoId: number) => {
    if (atributosSeleccionados.some((atributo: any) => atributo.idProductoAtributo === atributoId)) {
      setAtributosSeleccionados(
        atributosSeleccionados.filter((atributo: any) => atributo.idProductoAtributo !== atributoId)
      )
    } else {
      setAtributosSeleccionados([...atributosSeleccionados, { idProductoAtributo: atributoId }])
    }
  }

  const handleMonedaSeleccionada = (monedaSelect: any) => {
    if (monedasSeleccionadas.some((moneda: any) => moneda.isoCode === monedaSelect.isoCode)) {
      setMonedasSeleccionadas(monedasSeleccionadas.filter((moneda: any) => moneda.isoCode !== monedaSelect.isoCode))
    } else {
      setMonedasSeleccionadas([...monedasSeleccionadas, monedaSelect])
    }
  }

  const handleMaxOrden = async (rubroPadreCod: string) => {
    try {
      const rubros = (await getAllRubros(tenant)).data.data
      const flatRubros = flattenRubrosHierarchy(rubros)
      if (!rubroPadreCod) {
        const orden = rubros.length + 1
        return orden
      } else {
        const rubrosHijos = flatRubros.filter((rubro: any) => rubro.codRubroPadre === parseInt(rubroPadreCod))
        const orden = rubrosHijos.length + 1
        return orden
      }
    } catch (e) {
      console.error('Error:', e)
    }
  }

  useEffect(() => {
    const fetchMaxOrder = async () => {
      const orden = await handleMaxOrden(rubroPadre)
      setMaxOrder(orden)
    }
    fetchMaxOrder()
  }, [rubroPadre])

  const schema: yup.AnyObjectSchema = yup.object({
    rubroName: yup.string().required('El Nombre es obligatorio'),
    minPrice: yup
      .number()
      .test('minPrice', 'El precio debe ser positivo y mayor a cero', function (value) {
        if (!value) {
          return true // No hay error si el campo está vacío
        }
        return yup.number().positive().isValidSync(value)
      })
      .transform((_, value) => {
        if (value.includes('.')) {
          return null
        }
        return +value.replace(/,/, '.')
      }),
    orden: yup
      .number()
      .required('Es obligatorio declarar el orden del rubro')
      .min(1, 'El orden debe ser mayor o igual a 1')
      .max(maxOrden, `El orden debe ser menor o igual a ${maxOrden}`)
      .typeError('Es obligatorio declarar el orden del rubro'),
    colorRubro: yup.string().max(MAX_COLOR_LENGTH),
    nombreCompleto: yup.string().max(MAX_URL_LENGTH),
    slug: yup.string().max(MAX_SLUG_LENGTH),
    urlImg: yup.string().max(MAX_URL_LENGTH),
    urlFoto: yup.string().max(MAX_URL_LENGTH),
    urlIcono: yup.string().max(MAX_URL_LENGTH),
    urlPortada: yup.string().max(MAX_URL_LENGTH)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const onSubmit = async (data: any) => {
    const rubro: Rubro = {
      activo: true,
      listable: listable,
      codRubroPadre: rubroPadre,
      monedaList: monedasSeleccionadas,
      nombre: data.rubroName,
      nombreCompleto: data.nombreCompleto,
      orden: data.orden,
      precioMinimo: data.minPrice,
      productoAtributoList: atributosSeleccionados,
      unidadDeMedidaList: unidadesSeleccionadas,
      color: color,
      keywords: keywords,
      slug: data.slug,
      urlFoto: data.urlFoto,
      urlImagen: data.urlImg,
      urlIcono: data.urlIcono,
      urlPortada: data.urlPortada
    }

    if (rubro.monedaList.length === 0) {
      return SnackBarUtils.error('Debes elegir por lo menos un valor de moneda')
    }

    if (rubro.unidadDeMedidaList.length === 0) {
      return SnackBarUtils.error('Debes elegir por lo menos una unidad de medida')
    }
    if (!isDesplegableSelected) {
      return SnackBarUtils.error('Debes elegir un rubro padre')
    }

    try {
      setLoading(true)
      await createRubro(rubro, tenant)
      props.updateRubros()
      SnackBarUtils.success('Rubro creado con éxito')
    } catch (e) {
      console.log('Error: ', e)
      SnackBarUtils.error('Ha ocurrido un error al crear el rubro')
    } finally {
      handleClose()
      setLoading(false)
    }
  }

  const handleClose = async () => {
    setOpen(false)
    setNombreRubro('')
    setSlug('')
    setUrls([])
    setUnidadesSeleccionadas([])
    setAtributosSeleccionados([])
    setMonedasSeleccionadas([])
    setColor('')
    setShowHue(false)
    reset()
  }

  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(0,0,0,0.75)',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5">Grabando Rubro</Typography>
        <Loading color="inherit" />
      </Box>
    )
  }

  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Agregar un nuevo rubro
            {open ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Título del rubro
                  <Typography variant="subtitle2">
                    {nombreRubro.length || 0}/{MAX_NOMBRE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  disabled={loading}
                  sx={{ width: '100%' }}
                  error={!!errors.rubroName}
                  helperText={errors?.rubroName && String(errors?.rubroName?.message)}
                  variant="outlined"
                  {...register('rubroName')}
                  onChange={(e) => setNombreRubro(e.target.value)}
                  inputProps={{ maxLength: MAX_NOMBRE_LENGTH }}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Nombre completo
                  <Typography variant="subtitle2">
                    {nombreCompleto.length || 0}/{MAX_URL_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  disabled={loading}
                  sx={{ width: '100%' }}
                  error={!!errors.nombreCompleto}
                  helperText={errors?.nombreCompleto && String(errors?.nombreCompleto?.message)}
                  variant="outlined"
                  {...register('nombreCompleto')}
                  onChange={(e) => setNombreCompleto(e.target.value)}
                  inputProps={{ maxLength: MAX_URL_LENGTH }}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Rubro padre</InputLabel>

                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DesplegableRubrosPadres
                    onChange={(e: any) => {
                      setRubroPadre(e.target.value || null)
                      setIsDesplegableSelected(true)
                    }}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Precio mínimo</InputLabel>
                <TextField
                  prefix={'$'}
                  variant="outlined"
                  error={!!errors.minPrice}
                  helperText={errors?.minPrice && String(errors?.minPrice?.message)}
                  disabled={loading}
                  {...register('minPrice')}
                  type="number"
                  placeholder="Seleccione"
                  InputLabelProps={{ shrink: false }}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Orden</InputLabel>
                <TextField
                  variant="outlined"
                  error={!!errors.orden}
                  helperText={errors?.orden && String(errors?.orden?.message)}
                  disabled={loading}
                  {...register('orden')}
                  type="number"
                  placeholder="Seleccione"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{ min: 1, max: maxOrden }}
                />
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => setListable(e.target.checked)} checked={listable} disabled={loading} />
                  }
                  style={{ marginTop: '40px' }}
                  label={'Listable'}
                  disabled={loading}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ marginBottom: '20px ', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Keywords</InputLabel>
                <KeywordsSelect setKeywords={setKeywords} />
              </Stack>
            </Stack>
            <InputLabel
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '35%'
              }}
            >
              Color
              <Typography variant="subtitle2">
                {color.length || 0}/{MAX_COLOR_LENGTH}
              </Typography>
            </InputLabel>
            <Stack sx={{ width: '100%', marginBottom: '10px' }} direction={isMobile ? 'column' : 'row'} gap="10px">
              <Stack direction="row" gap="10px" sx={{ width: '40%', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  multiline
                  {...register('colorRubro')}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  inputProps={{
                    maxLength: MAX_COLOR_LENGTH
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">#</InputAdornment>
                  }}
                  style={{ width: '100%' }}
                />
                <div
                  onClick={() => setShowHue(true)}
                  style={{
                    display: 'block',
                    cursor: 'pointer',
                    width: '18px',
                    height: '18px',
                    border: `1px solid black`,
                    backgroundColor: `#${color}`,
                    aspectRatio: '1/1'
                  }}
                ></div>
              </Stack>

              <Stack sx={{ width: '60%', justifyContent: 'center' }}>
                {showHue && (
                  <HuePicker
                    color={`#${color}` || '#000000'}
                    onChange={(selectedColor) => setColor(selectedColor.hex.substring(1))}
                    height="18px"
                    width="100%"
                  />
                )}
              </Stack>
            </Stack>

            <Stack sx={{ width: '100%' }}>
              <InputLabel>Unidad de medida</InputLabel>
              {unidadesDeMedidaDisponibles.map((units: any, i: number) => (
                <FormControlLabel
                  key={units.idUnidadDeMedida + i}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={unidadesSeleccionadas.some(
                        (unidad: any) => unidad.idUnidadDeMedida === units.idUnidadDeMedida
                      )}
                      onChange={() => handleUnidadSeleccionadaChange(units.idUnidadDeMedida)}
                    />
                  }
                  label={units.nombreSingular + '/' + units.nombrePlural}
                  sx={{ marginBottom: '1px' }}
                />
              ))}
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <InputLabel>Atributos</InputLabel>
              {atributos.map((units: any, i: number) => (
                <FormControlLabel
                  key={units.idProductoAtributo + i}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={atributosSeleccionados.some(
                        (atributo: any) => atributo.idProductoAtributo === units.idProductoAtributo
                      )}
                      onChange={() => handleAtributoSeleccionado(units.idProductoAtributo)}
                    />
                  }
                  label={units.nombreSingular + '/' + units.nombrePlural}
                  sx={{ marginBottom: '1px' }}
                />
              ))}
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <InputLabel>Monedas</InputLabel>
              {monedas.map((units: any) => (
                <FormControlLabel
                  key={units.isoCode}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={monedasSeleccionadas.some((moneda: any) => moneda.isoCode === units.isoCode)}
                      onChange={() => handleMonedaSeleccionada(units)}
                    />
                  }
                  label={`${units.nombre} (${units.isoCode}, ${units.simbolo})`}
                  sx={{ marginBottom: '1px' }}
                />
              ))}
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Slug
                <Typography variant="subtitle2">
                  {slug.length || 0}/{MAX_SLUG_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                disabled={loading}
                sx={{ width: '100%' }}
                error={!!errors.slug}
                helperText={errors?.slug && String(errors?.slug?.message)}
                variant="outlined"
                {...register('slug')}
                onChange={(e) => setSlug(e.target.value)}
                inputProps={{ maxLength: MAX_SLUG_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url imagen
                <Typography variant="subtitle2">
                  {urls[0]?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlImg')}
                disabled={loading}
                onChange={(e) => {
                  const urlsRubro = [...urls]
                  urlsRubro[0] = e.target.value
                  setUrls(urlsRubro)
                }}
                inputProps={{ maxLength: MAX_URL_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url Foto
                <Typography variant="subtitle2">
                  {urls[1]?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlFoto')}
                disabled={loading}
                onChange={(e) => {
                  const urlsRubro = [...urls]
                  urlsRubro[1] = e.target.value
                  setUrls(urlsRubro)
                }}
                inputProps={{ maxLength: MAX_URL_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url Icono
                <Typography variant="subtitle2">
                  {urls[2]?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlIcono')}
                disabled={loading}
                onChange={(e) => {
                  const urlsRubro = [...urls]
                  urlsRubro[2] = e.target.value
                  setUrls(urlsRubro)
                }}
                inputProps={{ maxLength: MAX_URL_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url Portada
                <Typography variant="subtitle2">
                  {urls[3]?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlPortada')}
                disabled={loading}
                onChange={(e) => {
                  const urlsRubro = [...urls]
                  urlsRubro[3] = e.target.value
                  setUrls(urlsRubro)
                }}
                inputProps={{ maxLength: MAX_URL_LENGTH }}
              />
            </Stack>
            <DialogActions
              sx={{
                diplay: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-end',
                width: '100%',
                padding: '22px',
                paddingTop: 0,
                gap: '8px',
                position: 'relative'
              }}
            >
              {isMobile && (
                <Button color="inherit" onClick={handleClose}>
                  Volver
                </Button>
              )}
              <Stack direction="row" spacing={2}>
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? 'Grabando' : 'Grabar'}
                </Button>
              </Stack>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

export default memo(CreaRubro)
