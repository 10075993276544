import { Autocomplete, MenuItem, SxProps, TextField } from '@mui/material'
import { Rubro } from '../../Integraciones/types/responses-productos'
import { useEffect, useState } from 'react'
import { CustomAutocomplete } from '../../../components/Common/utils/input/CustomInput'

// extends Autocomplete props
interface DesplegableRubrosProps {
  onChange?: (rubro: Rubro) => void
  handleFormSubmit?: () => void
  hideLabel?: boolean
  label?: any
  placeHolder?: string
  disabled?: boolean
  fullWidth?: boolean
  sx?: SxProps
  rubros: Rubro[]
  defaultValue?: any
}

const DesplegableRubros = ({
  onChange,
  handleFormSubmit,
  disabled,
  fullWidth,
  label,
  hideLabel,
  placeHolder,
  rubros,
  sx,
  defaultValue
}: DesplegableRubrosProps) => {
  const [selectedRubro, setSelectedRubro] = useState<Rubro | null>(
    rubros.find((rubro) => rubro.codRubro === defaultValue) || null
  )

  useEffect(() => {
    if (defaultValue) setSelectedRubro(rubros.find((rubro) => rubro.codRubro === defaultValue) || null)
  }, [defaultValue, rubros])

  return (
    <CustomAutocomplete
      options={rubros}
      getOptionLabel={(rubro: Rubro) => rubro.nombreAnalytics || ''}
      onChange={(e: any, rubro: Rubro) => {
        if (onChange) onChange(rubro)

        setSelectedRubro(rubro as unknown as React.SetStateAction<Rubro>)
      }}
      fullWidth={fullWidth}
      disabled={disabled}
      value={selectedRubro}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyDown={(e) => e.key === 'Enter' && handleFormSubmit && handleFormSubmit()}
          label={label ? label : ''}
          placeholder={hideLabel || !label ? (placeHolder ? placeHolder : 'Todos') : ''}
          variant="outlined"
        />
      )}
      renderOption={(props, rubro: Rubro) => (
        <MenuItem {...props} value={rubro.codRubro} key={rubro.codRubro}>
          {rubro.nombreAnalytics}
        </MenuItem>
      )}
    />
  )
}

export default DesplegableRubros
