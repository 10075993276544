export interface Product {
  aclaracionPrecio: string;
  detalle: string;
  idLocal?: number;
  codLocal: string;
  imei: string;
  listaImagenes: Image[];
  modeloDisp: string;
  nombre: string;
  sku: string;
  precio: number;
  precioMinorista: number;
  precioSinDto: number;
  codRubro: string;
  talles: string;
  isoCodeMoneda: number;
  versionAndroid: string;
  versionApp: string;
  idUnidadDeMedida: number;
}

export interface Image {
  desc: string;
  idfotoProducto: number;
  imagenBase64: string;
}

export const ProductStatusValues = {
  Todos: 0,
  SoloActivos: 1,
  SoloVencidos: 2,
};
