import { AxiosResponse } from "axios";
import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { StatusHistoriasResponse } from "../types";
import { ITenant } from "../../AbmLocalPage/types";

export const GetStatus = async (codLocal: string, tenant: ITenant) =>
  (await axiosInstanceMain.get(
    `/${tenant.path}/historias/status-local/${codLocal}`
  )) as AxiosResponse<StatusHistoriasResponse>;

export const ComprarHistoria = async (codLocal: string, cant: number, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/historias/local-compra-paquete/${codLocal}?cant=${cant}`);
