import { useState, useEffect } from "react";
import { axiosInstanceMain } from "../utils/axiosGlobalUtils";
import AdminAbmConfigurations from "../components/AdminAbmConfig/AdminAbmConfigurations";
import ButtonCache from "../components/Common/ButtonCache";
import Buscador from "../components/Common/Buscador";
import { CircularProgress, useTheme, useMediaQuery, styled } from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "./Home/components/Footer";

const Title = styled("span")(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  [theme.breakpoints.down(425)]: {
    fontSize: "1.2rem",
    textAlign: "center",
  },
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    "& > *": {
      margin: "10px 0px 0px 0px",
    },
  },
}));

const ConfiguracionesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0px",
});

export interface IConfig {
  name: string;
  value: string;
  isEditing: boolean;
  newValue: string;
}

const AdminAbmConfigPage = () => {
  const [configs, setConfigs] = useState<IConfig[]>([]);
  const [configsSearched, setConfigsSearched] = useState<IConfig[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getConfigs();
  }, []);

  const getConfigs = async () => {
    await axiosInstanceMain
      .get("/admin/config")
      .then((result) => {
        setConfigs([...result.data.data, { ...result.data.data, isEditing: false }]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Navbar title="Admin ABM Configuración" />

      <div className="p-4">
        <Header>
          {/* <AdminAbmConfigSearch configs={configs} setConfigsSearched={setConfigsSearched} /> */}
          <Buscador
            elements={configs}
            setElementsSearched={setConfigsSearched}
            searchBy={["name", "value"]}
            setIsSearching={setIsSearching}
          />
          <ButtonCache />
        </Header>

        {/* Configuraciones */}
        <ConfiguracionesContainer>
          <Title>Lista de Configuraciones</Title>
          {loading ? (
            <CircularProgress />
          ) : (
            <AdminAbmConfigurations
              isSearching={isSearching}
              configs={configs}
              setConfigs={setConfigs}
              configsSearched={configsSearched}
            />
          )}
        </ConfiguracionesContainer>
      </div>
      {!loading && <Footer />}
    </>
  );
};

export default AdminAbmConfigPage;
