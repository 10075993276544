import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getContratosList } from './components/contratos.services'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import Navbar from '../../components/Navbar'
import DisplayContractsDesktop from './components/Desktop/renderContractsDesktop'
import DisplayContractsMobile from './components/Mobile/renderContractsMobile'
import { useTheme, useMediaQuery } from '@mui/material'
import NoContractsLabel from './components/noContractsLabel'
import Loading from '../../components/Common/Loading'
import Footer from '../Home/components/Footer'
import useLocal from '../Products/hooks/locals.hook'
import { useAppStore } from '../../store/app-store'

const ContratosVendedor: React.FC = (props: any) => {
  const { codLocal, token } = props.logged.userLogged
  const idLocal = useAppStore((state) => state.idLocal)
  const { whppContratos } = useLocal(codLocal, token)
  const [contratosList, setContratosList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const fetchContratos = async () => {
      try {
        setLoading(true)
        const contratosData = await getContratosList(idLocal)
        setContratosList(contratosData)
      } catch (error) {
        console.error('Error fetching contratos:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchContratos()
  }, [idLocal])

  if (loading) return <Loading />
  return (
    <>
      <Navbar title="Suscripciones" />
      {contratosList.length > 0 ? (
        isMobile ? (
          <DisplayContractsMobile contratosList={contratosList} contactoLocal={whppContratos} />
        ) : (
          <DisplayContractsDesktop contratosList={contratosList} contactoLocal={whppContratos} />
        )
      ) : (
        <NoContractsLabel message="No tienes suscripciones"></NoContractsLabel>
      )}
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(ContratosVendedor)
