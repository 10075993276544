import { GetAtributosEquivalencias } from '../services/atributos.service'
import { useAppStore } from '../../../store/app-store'

const useAtributos = () => {
  const codLocal = useAppStore((state) => state.codLocal)

  const getAtributos = async () => {
    try {
      const { data: atributos } = await GetAtributosEquivalencias(codLocal)
      return atributos
    } catch (error) {
      throw new Error('Error al obtener los atributos')
    }
  }

  return { getAtributos }
}

export default useAtributos
