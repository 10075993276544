import { create } from "zustand";

type State = {
  isValid: boolean;
  loading: boolean;
  percentage: number | null;
  open: boolean;
  dataExcel: any[];
  file: any;
};

type Action = {
  setIsValid: (isValid: State["isValid"]) => void;
  setLoading: (loading: State["loading"]) => void;
  setPercentage: (percentage: State["percentage"]) => void;
  setOpen: (open: State["open"]) => void;
  setDataExcel: (dataExcel: State["dataExcel"]) => void;
  setFile: (file: State["file"]) => void;
};

export const useStoreImportacion = create<State & Action>((set) => ({
  isValid: false,
  loading: false,
  percentage: null,
  open: false,
  dataExcel: [],
  file: null,
  setIsValid: (isValid: boolean) => set({ isValid }),
  setLoading: (loading: boolean) => set({ loading }),
  setPercentage: (percentage: number | null) => set({ percentage }),
  setOpen: (open: boolean) => set({ open }),
  setDataExcel: (dataExcel: any) => set({ dataExcel }),
  setFile: (file: any) => set({ file }),
}));
