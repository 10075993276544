import { memo, useContext, useState, useEffect } from "react";

// Material UI
import {
  InputLabel,
  Select,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  Tooltip,
  Typography,
  Box
} from "@mui/material";

import { Close, Info } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { updateGalerias, getGalerias } from "../services/galerias.services";
import ValidarDireccion from "../services/validacionesDir";

// form validation
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { GaleriasContext, IGalerias } from "../context/galerias.context";
import SnackBarUtils from "../../../utils/SnackBarUtils";
import moment from "moment";
import { validarDireccion } from "../../AbmLocalPage/services/validar.service";

import Loading from "../../../components/Common/Loading";
import { TenantsContext } from "../../../context/tenants.context";

const MAX_NOMBRE_LENGTH = 75;
const MAX_DIR_LENGTH = 150;

interface EditGaleriaProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  editGaleriaCode: any;
  setEliminaGaleria: (elimina: boolean) => void;
}

const EditGaleria = ({ open, setOpen, editGaleriaCode, setEliminaGaleria }: EditGaleriaProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [center, setCenter] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [latitud, setLatitud] = useState<number>(0);
  const [longitud, setLongitud] = useState<number>(0);
  const [direccionValidada, setDireccionValidada] = useState<boolean>(true);
  const { galerias, fetchData } = useContext(GaleriasContext);
  const { tenant } = useContext(TenantsContext);

  // PRODUCT SAVE
  const [editGaleria, setEditGaleria] = useState<any>(null);

  const getEditGaleria = async () => {
    if (editGaleriaCode) {
      const galeriaEditada = galerias.find((galeria) => galeria.codGaleria === editGaleriaCode);
      setEditGaleria(galeriaEditada);
    }
  };

  useEffect(() => {
    if (editGaleriaCode && open) {
      setLoading(true);
      getEditGaleria();
    }
  }, [editGaleriaCode, open]);

  const schema: yup.AnyObjectSchema = yup.object({
    nombreGaleria: yup.string().required("El nombre es obligatorio"),
    direcGaleria: yup.string().required("La direccion es obligatoria"),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    setError,
    //setFocus,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onBlur",
  });
  /* const handleChange = (newValue: any) => setValue(newValue); */
  const onSubmit = async (data: any) => {
    if (!direccionValidada) {
      setError("direcGaleria", {
        message: "Debes validar la dirección",
        type: "manual",
      });
      return SnackBarUtils.error("Debes validar la dirección ingresada");
    }

    const updatedGaleria: IGalerias = {
      codGaleria: editGaleria.codGaleria,
      nombre: editGaleria.nombre,
      direccion: editGaleria.direccion,
      latitud: editGaleria.latitud,
      longitud: editGaleria.longitud,
    };

    try {
      setLoading(true);
      await updateGalerias(updatedGaleria, tenant);
      await fetchData();
      /* if (onConfirm) await onConfirm();
      if (onClose) onClose(); */
      SnackBarUtils.success("Galería actualizada correctamente");
    } catch (e) {
      console.log(e);
      SnackBarUtils.error("Error al actualizar la galería");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleClose = async () => {
    // if (onClose) onClose();

    setOpen(false);
    reset();
  };

  //if (loading) return <></>;
  return (
    <>
      <Dialog maxWidth={isMobile ? "lg" : "sm"} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        {editGaleria && (
          <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: "hidden" }}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Edición de la Galería
              {open ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <Stack direction={"column"} sx={{ margin: "20px 0" }} spacing="20px">
                <Stack sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    Nombre de la Galería
                    <Typography variant="subtitle2">
                      {editGaleria.nombre.length || 0}/{MAX_NOMBRE_LENGTH}
                    </Typography>
                  </InputLabel>
                  <TextField
                    /* disabled={loading} */
                    sx={{ width: "100%" }}
                    error={!!errors.nombreGaleria}
                    helperText={errors?.nombreGaleria && String(errors?.nombreGaleria?.message)}
                    variant="outlined"
                    {...register("nombreGaleria")}
                    onChange={(e) => {
                      setEditGaleria({
                        ...editGaleria,
                        nombre: e.target.value,
                      });
                    }}
                    inputProps={{
                      maxLength: MAX_NOMBRE_LENGTH,
                    }}
                    value={editGaleria.nombre}
                  />
                </Stack>
                <ValidarDireccion
                  loading={loading}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  center={center}
                  setCenter={setCenter}
                  setError={setError}
                  clearErrors={clearErrors}
                  setLongitud={setLongitud}
                  setLatitud={setLatitud}
                  editGaleria={editGaleria}
                  setEditGaleria={setEditGaleria}
                  setDireccionValidada={setDireccionValidada}
                />
              </Stack>
            </DialogContent>

            <DialogActions
              sx={{
                diplay: "flex",
                alignItems: "center",
                alignSelf: "flex-end",
                justifyContent: "space-between",
                width: "100%",
                padding: "22px",
                paddingTop: 0,
                gap: "8px",
                position: "relative",
              }}
            >
              <Button color="inherit" onClick={handleClose}>
                Volver
              </Button>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  /* disabled={loading} */
                  color="error"
                  onClick={() => setEliminaGaleria(true)}
                >
                  Eliminar
                </Button>
                <Button variant="contained" /* disabled={loading} */ type="submit">
                  Modificar
                </Button>
              </Stack>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default memo(EditGaleria);
