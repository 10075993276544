import { Card, Collapse } from '@mui/material'
import { memo, useContext, useMemo, useState } from 'react'
import CardActionsArea from './Card/CardActionsArea'
import CardContent from './Card/CardContent'
import CardActions from './Card/CardActions'
import CardHeader from './Card/CardHeader'
import { ProductListProps } from '../..'
import { ProductosVendedorContext } from '../../context/productos.vendedor.context'
import { TenantsContext } from '../../../../context/tenants.context'

interface MobileProductProps extends ProductListProps {
  product: any
}

export const getColor = (product: any) => {
  return product.aprobado && !product.pausado && !product.vencido
    ? '#b3faa8'
    : product.rechazado
    ? '#ffaeba'
    : product.pausado || product.vencido
    ? '#ddd'
    : '#fff'
}

const MobileProduct = ({
  product,
  isAdmin,
  handleDeleteProduct,
  handleEditProduct,
  handlePauseProduct,
  handleResumeProduct
}: MobileProductProps) => {
  const { productosSeleccionados, setProductosSeleccionados, setAccionesMasivas, accionesMasivas } =
    useContext(ProductosVendedorContext)
  const [expanded, setExpanded] = useState(false)
  // const [pressStartTime, setPressStartTime] = useState(0);
  const [pressTimer, setPressTimer] = useState<any>(null)
  const estaSeleccionado = useMemo(() => {
    return productosSeleccionados.some((p: any) => p.codProducto === product.codProducto)
  }, [productosSeleccionados, product])

  const handleTouchStart = () => {
    // const startTime = Date.now();
    // setPressStartTime(startTime);

    const timer = setTimeout(() => {
      setAccionesMasivas && setAccionesMasivas(true)
      if (!estaSeleccionado) {
        setProductosSeleccionados([...productosSeleccionados, product])
      }
    }, 500)

    setPressTimer(timer)
  }

  const handleTouchEnd = () => {
    clearTimeout(pressTimer)
  }

  return (
    <Card
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onContextMenu={(e) => e.preventDefault()}
      sx={{
        width: 350,
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 5,
        userSelect: 'none'
      }}
    >
      <CardActionsArea
        product={product}
        handleEditProduct={handleEditProduct}
        accionesMasivas={accionesMasivas}
        setAccionesMasivas={setAccionesMasivas}
        productosSeleccionados={productosSeleccionados}
        setProductosSeleccionados={setProductosSeleccionados}
        estaSeleccionado={estaSeleccionado}
      />

      <CardHeader expanded={expanded} product={product} setExpanded={setExpanded} />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent product={product} />
      </Collapse>

      <CardActions
        handleDeleteProduct={handleDeleteProduct}
        handlePauseProduct={handlePauseProduct}
        handleResumeProduct={handleResumeProduct}
        isAdmin={isAdmin}
        product={product}
      />
    </Card>
  )
}

export default memo(MobileProduct)
