import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  Box,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { actualizaFechaStickers } from "../services/stickers.services";
import { StickersFechaMasivas } from "../types/sticker";
import SnackBarUtils from "../../../utils/SnackBarUtils";
import { TenantsContext } from "../../../context/tenants.context";

interface FechaStickersProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
const CheckBox = styled("div")({
  display: "flex",
  gap: "10px",
  alignItems: "center",
});

const FechaStickers: React.FC<FechaStickersProps> = ({ open, setOpen }) => {
  const [fechasMasivas, setFechasMasivas] = useState<StickersFechaMasivas>();
  const [codigosLocales, setCodigosLocales] = useState<any[]>([]);
  const [fecha, setFecha] = useState<Dayjs>(dayjs());
  const [blanquearFecha, setBlanquearFecha] = useState<boolean>(false);
  const [noQuisoPegar, setNoQuisoPegar] = useState<boolean>(false);
  const [invalidCod, setInvalidCod] = useState<boolean>(false);
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const { tenant } = useContext(TenantsContext);

  const handleCodigosLocales = (textareaValue: string) => {
    const fechaFormateada = fecha.format("YYYY-MM-DD");
    const codigosLocalesArray = textareaValue.split(/\s+|\n+/).filter((code) => code.trim() !== "");

    const invalidCod = codigosLocalesArray.some((code) => {
      const parsedCode = parseInt(code, 10);
      return isNaN(parsedCode) || parsedCode.toString() !== code;
    });

    setInvalidCod(invalidCod);

    setCodigosLocales(codigosLocalesArray);
    setFechasMasivas({
      fecha: blanquearFecha ? null : fechaFormateada,
      codLocalList: codigosLocalesArray,
    });
  };

  useEffect(() => {
    const fechaFormateada = fecha.format("YYYY-MM-DD");
    setFechasMasivas({
      fecha: blanquearFecha ? null : fechaFormateada,
      codLocalList: codigosLocales,
    });
  }, [fecha, blanquearFecha]);

  const handleActualizar = async () => {
    if (codigosLocales.length == 0) {
      setInvalidCod(true);
      SnackBarUtils.error("Es obligatorio ingresar por lo menos un código");
      return;
    }
    if (invalidCod) {
      return SnackBarUtils.error("Ingresar códigos válidos");
    }
    if (invalidDate) {
      return SnackBarUtils.error("Ingresar una fecha válida");
    }
    try {
      handleClose();
      const res = await actualizaFechaStickers(fechasMasivas, tenant);
      if (res.data.errorCode !== 0) {
        return SnackBarUtils.error(res.data.message);
      }
      SnackBarUtils.success("Fecha actualizada correctamente");
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return SnackBarUtils.error("Falló la actualización de la fecha de puso sticker");
      }
    } finally {
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBlanquearFecha(false);
    setNoQuisoPegar(false);
    setFecha(dayjs());
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ flexGrow: 1, marginTop: "2px", gap: "6px" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              flexGrow: 1,
              marginBottom: "6px",
            }}
          >
            Seteo masivo de fecha de puesta de stickers
          </Typography>
          <Typography
            style={{
              marginTop: "6px",
              marginBottom: "4px",
            }}
          >
            Ingresá los códigos de los locales a los cuales se les debe setear la fecha de puesta de sticker
          </Typography>
          <textarea
            id="codigosLocales"
            className={`form-control ${invalidCod ? "is-invalid" : ""}`}
            style={{ color: "#53292a" }}
            rows={10}
            onBlur={(event) => {
              handleCodigosLocales(event.target.value);
            }}
            placeholder="Separar los códigos con espacios. Por ejemplo '11 22 33'"
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography>Fecha a setear</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={blanquearFecha || noQuisoPegar}
                  format="DD/MM/YYYY"
                  value={fecha}
                  onChange={(date: any) => {
                    date && setFecha(date);
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      value: fecha,
                    },
                  }}
                  onError={() => setInvalidDate(true)}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginLeft: "20px", marginTop: "18px" }}>
              <CheckBox>
                <input
                  type="checkbox"
                  value="blanquear fecha"
                  onChange={(e) => {
                    setNoQuisoPegar(e.target.checked);
                    if (e.target.checked) {
                      setFecha(dayjs("1900-01-01", "YYYY-MM-DD"));
                    }
                  }}
                  checked={noQuisoPegar}
                />
                No quiso pegar
              </CheckBox>
              <CheckBox>
                <input
                  type="checkbox"
                  value="blanquear fecha"
                  onChange={(e) => setBlanquearFecha(e.target.checked)}
                  checked={blanquearFecha}
                />
                Blanquear la fecha
              </CheckBox>
            </Box>
          </div>
          <Button
            sx={{ marginTop: "10px", marginBottom: "-10px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              handleActualizar();
            }}
          >
            Actualizar fechas
          </Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="end"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              margin: "6px",
              paddingTop: "16px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FechaStickers;
