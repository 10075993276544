import React, { useEffect } from 'react'
import { Card, Typography, Box, Button } from '@mui/material'
import TableDataPagination from '../../pages/Products/components/TableDataPagination'
import { INotificaciones } from './types/Notificaciones'
import { format } from 'date-fns'
import { useSortableData } from '../../utils/UseSorteableDataHook'
import { Paginator } from '../../pages/AdminProducts/context/productos.context'
import { es } from 'date-fns/locale'

export interface NotificacionesProps {
  listado: INotificaciones[]
  paginator: Paginator
  page: number
  pageSize: number
  totalRecord: number
  calcularEstado: (notif: INotificaciones) => { nombre: string; icono: any }
  setNotificacion: (notif: INotificaciones) => void
  setIsEditing: (editing: boolean) => void
  setOpenForm: (open: boolean) => void
}

const NotificacionesMobile: React.FC<NotificacionesProps> = ({
  listado,
  paginator,
  page,
  pageSize,
  totalRecord,
  calcularEstado,
  setIsEditing,
  setNotificacion,
  setOpenForm
}) => {
  return (
    <>
      {listado.map((item: INotificaciones, index: number) => {
        const estado: { nombre: string; icono: any } = calcularEstado(item)
        const fechaInico = new Date(item.fechaInicio)
        const fechaFin = new Date(item.fechaFin)
        return (
          <Card key={index} variant="outlined" sx={{ margin: '20px', padding: '10px' }}>
            <Typography style={{ marginBottom: '8px', fontWeight: 'bold' }} variant="h5">
              {item.nombre}
            </Typography>
            <Typography variant="h6">{item.titulo}</Typography>
            <Typography style={{ marginBottom: '8px', fontStyle: 'italic' }} variant="body1">
              {item.mensaje}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '4px',
                gap: '4px'
              }}
            >
              <Typography variant="body1">
                <strong>Tipo:</strong> {item.notificacionOrigenTipo}
              </Typography>
              <Typography variant="body1">
                <strong>Mensajes recibidos:</strong> {item.cantMensajesEnviados.toLocaleString('es-ES')}
              </Typography>
              <Typography variant="body1">
                <strong>Mensajes exitosos:</strong> {item.cantMensajesExitosos.toLocaleString('es-ES')}
              </Typography>
              {item.fechaInicio && (
                <Typography variant="body1">
                  <strong>Fecha Inicio:</strong> {format(fechaInico, 'dd/MM/yyyy, HH:mm', { locale: es })}
                </Typography>
              )}
              {item.fechaFin && (
                <Typography variant="body1">
                  <strong>Fecha Fin:</strong> {format(fechaFin, 'dd/MM/yyyy, HH:mm', { locale: es })}
                </Typography>
              )}
              <Typography variant="body1">
                <strong>Estado:</strong>
                {` ${estado.nombre} `}
                {estado.icono}
              </Typography>
            </Box>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'row',
                gap: '20px'
              }}
            >
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setIsEditing(true)
                  setNotificacion(item)
                  setOpenForm(true)
                }}
              >
                Editar
              </Button>{' '}
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setNotificacion(item)
                  setOpenForm(true)
                }}
              >
                Duplicar
              </Button>
            </div>
          </Card>
        )
      })}
      <TableDataPagination
        total={totalRecord || 0}
        page={page || 0}
        paginator={paginator}
        items={listado || []}
        rowsPerPage={pageSize || 100}
        component={'p'}
        label={`Notificaciones por página`}
      />
    </>
  )
}

export default NotificacionesMobile
