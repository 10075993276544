import { useState, useEffect, useCallback, useContext } from "react";
import { INITIAL_ROWS_PER_PAGE } from "../pages/Products/components/TableDataPagination";
import { hash } from "../utils/hashFunction";
import { axiosInstanceContratos } from "../utils/axiosGlobalUtils";
import { ContractDTO } from "../dto/ContractDTO";
import { DataContratosContext } from "../components/AdminLocal/context/data.contratos.context";

export interface LinksDTO {
  idContratoPagoLink: number;
  idContrato: number;
  platform: string;
  idExterno: string;
  link: string;
  tienePagos: boolean;
  source: string;
  fechaAlta: string;
  fechaVencimiento: string;
}

export interface PagosDTO {
  idContratoPago: number;
  idContrato: number;
  contratoPagoEstado: string;
  contratoPagoEstadoLogica: string;
  monto: number;
  montoDevuelto: number;
  saldoAplicado: any;
  pagoNroOperacion: string;
  fechaPago: string;
  fechaAlta: string;
  fechaBaja: string;
  idContratoPagoLink: number;
  mensajeResultado: string;
  nuevoContrato: boolean;
  historialCambios: any[];
}

export interface LinksLocal {
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  items: LinksDTO[];
}

export interface PagosLocal {
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  items: PagosDTO[];
}

const defaultData: any = {
  page: 0,
  pageSize: INITIAL_ROWS_PER_PAGE,
  totalPages: 0,
  totalItems: 0,
  items: [],
};

export const useLocalContracts = (idLocal: string | undefined) => {
  const [contracts, setContracts] = useState<ContractDTO[]>([]);
  const [forzarRenovables, setForzarRenovables] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchDataContratos } = useContext(DataContratosContext);

  const fetchLocalContracts = useCallback(
    async (idLocal: any, forzarRenovables: boolean) => {
      setLoading(true);
      const clave = await hash(idLocal.toString() + "lugares2017");
      const url = forzarRenovables
        ? `/contratos/local/${idLocal}?clave=${clave}&forzar-renovable=true`
        : `/contratos/local/${idLocal}?clave=${clave}`;
      axiosInstanceContratos
        .get(url)
        .then((result) => {
          result.data.sort(
            (a: any, b: any) => a.fechaFinFormateada - b.fechaFinFormateada
          );
          setContracts(result.data);
          fetchDataContratos(result.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    },
    [idLocal, forzarRenovables]
  );

  useEffect(() => {
    if (idLocal && idLocal !== "0") {
      fetchLocalContracts(idLocal, forzarRenovables);
    }
  }, [idLocal, forzarRenovables, fetchLocalContracts]);

  return {
    contracts,
    forzarRenovables,
    setForzarRenovables,
    fetchLocalContracts,
    loading,
  };
};
