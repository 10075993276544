import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { registrarToken } from "./services/firebase.service";
import * as uuid from "uuid";
import { ITenant } from "./pages/AbmLocalPage/types";

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generarToken = async (
  userLogged: any,
  tenant: ITenant
): Promise<any> => {
  try {
    const token = await getToken(messaging, {
      vapidKey: FIREBASE_VAPID_KEY,
    });
    if (token) {
      let imei = localStorage.getItem("imei");
      if (!imei) {
        imei = uuid.v4();
        localStorage.setItem("imei", imei);
      }

      await registrarToken(userLogged, token, imei,tenant);
      return { token, imei };
    } else {
      console.log("No registration token available. Request permission to generate one.");
      return { token: "" };
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    return { token: "" };
  }
};

export const requestPermission = async () => {
  const permission = await Notification.requestPermission();
  return permission === "granted";
};
