import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useContext } from 'react'
import { IntegracionesContext } from '../context/integraciones.context'

const TiendaSeleccionada = () => {
  const { tiendaSeleccionada, irASeleccionarTienda } = useContext(IntegracionesContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!tiendaSeleccionada) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignSelf: isMobile ? 'center' : 'flex-end'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          width={'200px'}
          src={tiendaSeleccionada.logo}
          alt={`tienda ${tiendaSeleccionada.name}`}
          style={{
            cursor: 'pointer',
            borderRadius: '6px'
          }}
          onClick={irASeleccionarTienda}
        />
      </Box>
    </Box>
  )
}

export default TiendaSeleccionada
