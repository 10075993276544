import { Box, Grid, TextField, Typography } from "@mui/material";
import { postLocalType } from "../../types";
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues
} from "react-hook-form";
import { MAX_LENGTH_DESC } from ".";
import { useState } from "react";
import SectionTitle from "../../common/SectionTitle";

interface FormDescripcionProps {
  register: UseFormRegister<postLocalType>;
  errors: FieldErrors<postLocalType>;
  setValue: UseFormSetValue<postLocalType>;
  getValues: UseFormGetValues<postLocalType>;
  isSubmitting: boolean;
}

const FormDescripcion = ({
  isSubmitting,
  register,
  getValues,
  setValue,
  errors,
}: FormDescripcionProps) => {
  const [v, setV] = useState("");

  const handleDescChange = (e: any) => {
    setValue(`desc`, e.target.value);
    setV(e.target.value);
  };

  return (
    <Box>
      <SectionTitle title="Descripción" />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item md={12} xs={12}>
          <TextField
            {...register("desc")}
            rows={4}
            onChange={handleDescChange}
            multiline
            fullWidth
            disabled={isSubmitting}
            inputProps={{ maxLength: MAX_LENGTH_DESC }}
            helperText={
              <Box
                component={"span"}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography color={"#d53839"} variant="caption">
                  {errors.desc && errors.desc.message}
                </Typography>
                {v.length || getValues("desc")?.length || 0}/{MAX_LENGTH_DESC}
              </Box>
            }
            label="Escribe una descripción sobre tu local, tus productos y más!"
            color={errors.desc ? "error" : "secondary"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormDescripcion;
