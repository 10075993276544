import { Box, Typography } from "@mui/material";

const Rubro = ({ product }: { product: any }) => {
  return (
    <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
      {product.rubro.urlIcono && (
        <img src={product.rubro.urlIcono} alt={product.nombre} width={"30px"} />
      )}
      <Typography variant="body2" color="gray">
        {product.rubro.nombreAnalytics}
      </Typography>
    </Box>
  );
};

export default Rubro;
