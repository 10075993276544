/* eslint-disable react/prop-types */
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { memo, useContext, useState } from 'react'
import { connect } from 'react-redux'
import { responderCalificaciones } from './services/calificaciones.services'
import { useCalificaciones } from './calificaciones.hook'
import { TenantsContext } from '../../context/tenants.context'

interface DialogResponderProps {
  open: boolean
  setOpen: (open: boolean) => void
  codLocal: string
  idCalificacion: number
}

const DialogResponder: React.FC<DialogResponderProps> = ({
  open,
  setOpen,
  idCalificacion,
  codLocal
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [respuesta, setRespuesta] = useState<string>('')
  const [publicarRespuesta, setPublicarRespuesta] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { fetchData } = useCalificaciones(codLocal)
  const { tenant } = useContext(TenantsContext)

  const responder = async (id: number) => {
    setLoading(true)
    await responderCalificaciones(id, respuesta, publicarRespuesta, tenant)
      .catch(console.log)
      .finally(() => {
        setLoading(false)
        handleClose()
      })
    fetchData(codLocal)
  }

  const handleClose = () => {
    setOpen(false)
    setRespuesta('')
    setPublicarRespuesta(false)
  }
  return (
    <>
      <Dialog open={open}>
        <DialogContent sx={{ padding: '30px 40px' }}>
          <Stack
            spacing={2}
            alignItems="center"
            style={{ marginBottom: '10px' }}
          >
            <Typography fontWeight="bold" style={{ fontSize: '24px' }}>
              Ingresá el mensaje que querés enviarle al comprador.
              <br /> <br />
              Tené en cuenta que sólo le podrás escribir una vez, hasta tanto
              modifique su calificacón.
            </Typography>
            <Box width={'100%'}>
              <Typography
                variant="body2"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                {respuesta.length}/500
              </Typography>
              <TextField
                value={respuesta}
                onChange={(e) => {
                  setRespuesta(e.target.value)
                }}
                disabled={loading}
                id="standard-multiline-static"
                fullWidth
                multiline
                rows={4}
                variant="standard"
                inputProps={{ maxLength: 500 }}
              />
            </Box>

            <Stack direction="row" alignItems="center">
              <Checkbox
                checked={publicarRespuesta}
                onChange={(e) => setPublicarRespuesta(e.target.checked)}
              />
              <Typography>
                Publicar la respuesta para que todos los compradores la vean
              </Typography>
            </Stack>
          </Stack>
          <Button
            onClick={() => responder(idCalificacion)}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: '#888e8e',
              color: 'white',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#6e6e6e'
              }
            }}
          >
            Enviar
          </Button>
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="end"
            onClick={() => handleClose()}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              margin: '6px',
              paddingTop: '16px'
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

export default connect(mapStateToProps)(memo(DialogResponder))
