import React, { useCallback, useState, useEffect } from 'react'
import {
  Typography,
  Button,
  Box,
  IconButton,
  DialogActions,
  MenuItem,
  TextField,
  Dialog,
  Select,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormHelperText
} from '@mui/material'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import ConfirmDialog from '../../pages/Products/components/ConfirmDialog'
import { IProductoContrato } from '../../pages/AdminProducts/types/producto'
import { cambiarProductoContrato } from '../../pages/AdminLocal/services/contratos.productos.services'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { useSortableData } from '../../utils/UseSorteableDataHook'
import { ICambioProducto, MedioPago } from '../../dto/ContractDTO'

interface DialogCambiarProductosProps {
  open: boolean
  productos: IProductoContrato[]
  idProductoActual: number
  openDialog: (open: boolean) => void
  idContrato: number
  idLocal: number
  updateContractsData: (idLocal: number) => void
  mediosDePago: MedioPago[]
}

const DialogCambiarProductos: React.FC<DialogCambiarProductosProps> = ({
  open,
  productos,
  idProductoActual,
  openDialog,
  idContrato,
  idLocal,
  updateContractsData,
  mediosDePago
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState<boolean>(false)
  const [activacion, setActivacion] = useState<boolean>(true)
  const [observaciones, setObservaciones] = useState<string | null>(null)
  /* const [medioPagoSeleccionado, setMedioPagoSeleccionado] =
    useState<MedioPago | null>(null); */
  const { items, requestSort } = useSortableData(productos)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  const schema: yup.AnyObjectSchema = yup.object({
    nuevoNombre: yup.string().required('Elija un nuevo nombre.'),
    medioDePago: yup.string().required('Elija un medio de pago.'),
    cargoAdicional: yup
      .string()
      .nullable()
      .test(
        'is-decimal',
        'El cargo adicional debe ser un número válido.',
        (value) => {
          if (!value) return true
          const decimalValue = value.replace(',', '.')
          return !isNaN(parseFloat(decimalValue))
        }
      )
  })

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nuevoNombre: '',
      medioDePago: ''
    }
  })

  const handleChangeProducto = useCallback(
    (data: any) => {
      const newProducto: ICambioProducto = {
        producto: data.nuevoNombre,
        medioDePago: data.medioPago,
        cargoAdicional: data.cargoAdicional
          ? parseFloat(data.cargoAdicional.replace(',', '.'))
          : null,
        observaciones
      }
      setConfirmDialog({
        open: true,
        text: {
          title: '¿Deseás cambiar este producto?',
          body: '',
          leftButton: 'Cancelar',
          rightButton: 'Confirmar',
          colorButtonConfirm: 'error'
        },
        action: async () => {
          setLoading(true)
          await cambiarProductoContrato(idContrato, newProducto)
            .then((data) => {
              idLocal && updateContractsData(idLocal)
              SnackBarUtils.success(data.data)
            })
            .catch((e) => {
              console.log(e)
            })
            .finally(() => {
              setLoading(false)
              clearConfirmDialog()
              handleClose()
            })
        },
        close: () => clearConfirmDialog()
      })
    },
    [idContrato, activacion, observaciones]
  )

  useEffect(() => {
    requestSort('nombre', 'descending')
  }, [productos])

  const handleClose = () => {
    reset({
      nuevoNombre: '',
      medioDePago: '',
      cargoAdicional: ''
    })
    openDialog(false)
    setObservaciones('')
  }

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          style: { overflowX: 'hidden', ...(!isMobile && { width: '500px' }) }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 30px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: "space-between",
              alignItems: 'center',
              marginTop: '20px',
              marginBottom: '20px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            >
              Indicá el nuevo producto
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: 'center'
              }}
            >
              No cambiarán las fechas del contrato
            </Typography>
          </Box>
          <FormControl fullWidth error={!!errors.nuevoNombre}>
            <InputLabel id="select-producto-label">Producto</InputLabel>
            <Select
              labelId="select-producto-label"
              {...register('nuevoNombre')}
              defaultValue=""
              label="Producto"
              error={!!errors.nuevoNombre}
            >
              {items
                .filter(
                  (item: any) => item.idContratoProducto !== idProductoActual
                )
                .map((producto) => (
                  <MenuItem
                    key={producto.idContratoProducto}
                    value={producto.nombreRequest}
                  >
                    {producto.nombre}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {errors?.nuevoNombre && String(errors?.nuevoNombre?.message)}
            </FormHelperText>
          </FormControl>

          <TextField
            label="Cargo adicional"
            {...register('cargoAdicional')}
            error={!!errors.cargoAdicional}
            helperText={
              errors?.cargoAdicional && String(errors?.cargoAdicional?.message)
            }
            variant="outlined"
            fullWidth
            sx={{ marginTop: '20px' }}
          />

          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isMobile ? 'column' : 'row'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                disabled={true}
                checked={activacion}
                onChange={(e) => {
                  //setMedioPagoSeleccionado(null);
                  setActivacion(e.target.checked)
                }}
              />
              <Typography
                style={{
                  marginTop: '0.2rem'
                }}
              >
                Activar automáticamente
              </Typography>
            </Box>

            {activacion && (
              <FormControl
                error={!!errors.medioDePago}
                sx={{
                  width: isMobile ? '100%' : '50%',
                  ...(isMobile && { marginTop: '10px' })
                }}
              >
                <InputLabel id="select-medio-pago-label">
                  Medio de Pago
                </InputLabel>
                <Select
                  labelId="select-medio-pago-label"
                  label="Medio de Pago"
                  {...register('medioDePago')}
                  defaultValue=""
                  error={!!errors.medioDePago}
                >
                  {mediosDePago.map((medio: MedioPago) => (
                    <MenuItem
                      key={medio.idContratoMedioDePago}
                      value={medio.nombreRequest}
                    >
                      {medio.nombre}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors?.medioDePago && String(errors?.medioDePago?.message)}
                </FormHelperText>
              </FormControl>
            )}
          </Box>

          <TextField
            label="Observaciones"
            multiline
            rows={4}
            fullWidth
            value={observaciones || ''}
            onChange={(e) => setObservaciones(e.target.value)}
            sx={{ marginTop: '20px' }}
          />

          <Button
            variant="contained"
            onClick={handleSubmit(handleChangeProducto)}
            color="success"
            sx={{ marginTop: '20px', width: '100%' }}
          >
            Cambiar
          </Button>
        </Box>

        <DialogActions>
          <IconButton
            edge="end"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              paddingRight: '16px'
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog {...confirmDialog} disabled={loading} />
    </>
  )
}

export default DialogCambiarProductos
