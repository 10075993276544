import { memo, useContext, useState } from 'react'

// Material UI
import {
  InputLabel,
  Select,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  Tooltip,
  Typography,
  Box
} from '@mui/material'

import { Close, Info } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createGalerias, getGalerias } from '../services/galerias.services'

// form validation
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { GaleriasContext, IGalerias } from '../context/galerias.context'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { validarDireccion } from '../../AbmLocalPage/services/validar.service'
import ValidarDireccion from '../services/validacionesDir'

import Loading from '../../../components/Common/Loading'
import { TenantsContext } from '../../../context/tenants.context'

const MAX_NOMBRE_LENGTH = 75
const MAX_DIR_LENGTH = 150

const CreaGalerias = (props: any) => {
  const { open, setOpen } = props

  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // temporal data
  const [nombre, setNombre] = useState<string>('')
  const [center, setCenter] = useState<string>('')
  const [direccionValidada, setDireccionValidada] = useState<boolean>(false)
  const [latitud, setLatitud] = useState<number>(0)
  const [longitud, setLongitud] = useState<number>(0)
  const { fetchData } = useContext(GaleriasContext)
  const { tenant } = useContext(TenantsContext)

  const schema: yup.AnyObjectSchema = yup.object({
    nombreGaleria: yup
      .string()
      .required('El nombre es obligatorio')
      .typeError('El nombre es requerido')
      .max(MAX_NOMBRE_LENGTH, `El nombre no puede superar los ${MAX_NOMBRE_LENGTH} caracteres`),
    direcGaleria: yup
      .string()
      .required('La direccion es obligatoria')
      .typeError('La dirección es requerida')
      .max(MAX_DIR_LENGTH, `La dirección no puede superar los ${MAX_DIR_LENGTH} caracteres`)
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    setError,
    //setFocus,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const onSubmit = async (data: any) => {
    if (!direccionValidada) {
      setError('direcGaleria', {
        message: 'Debes validar la dirección',
        type: 'manual'
      })
      return SnackBarUtils.error('Debes validar la dirección ingresada')
    }

    const galeria: IGalerias = {
      nombre: data.nombreGaleria,
      direccion: data.direcGaleria,
      latitud: latitud,
      longitud: longitud
    }

    try {
      setLoading(true)
      await createGalerias(galeria, tenant)
      fetchData()

      SnackBarUtils.success('Galería creada con éxito')
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Ha ocurrido un error al crear la galería')
    } finally {
      handleClose()
      setLoading(false)
    }
  }

  const handleClose = async () => {
    setOpen(false)
    setNombre('')
    reset()
  }

  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(0,0,0,0.75)',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5">Publicando Galería</Typography>
        <Loading color="inherit" />
      </Box>
    )
  }

  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Agregar una nueva Galería
            {open ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" sx={{ margin: '20px 0' }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Nombre de la Galería
                  <Typography variant="subtitle2">
                    {nombre.length || 0}/{MAX_NOMBRE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  disabled={loading}
                  sx={{ width: '100%' }}
                  error={!!errors.nombreGaleria}
                  helperText={errors?.nombreGaleria && String(errors?.nombreGaleria?.message)}
                  variant="outlined"
                  {...register('nombreGaleria')}
                  onChange={(e) => setNombre(e.target.value)}
                  inputProps={{
                    maxLength: MAX_NOMBRE_LENGTH
                  }}
                />
              </Stack>
              <ValidarDireccion
                loading={loading}
                register={register}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                center={center}
                setCenter={setCenter}
                setError={setError}
                clearErrors={clearErrors}
                setLongitud={setLongitud}
                setLatitud={setLatitud}
                setDireccionValidada={setDireccionValidada}
              />
            </Stack>
          </DialogContent>

          <DialogActions
            sx={{
              diplay: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-end',
              justifyContent: 'space-between',
              width: '100%',
              padding: '22px',
              paddingTop: 0,
              gap: '8px',
              position: 'relative'
            }}
          >
            <Button color="inherit" onClick={handleClose}>
              Volver
            </Button>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" type="submit" disabled={loading}>
                {loading ? 'Grabando' : 'Grabar'}
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default memo(CreaGalerias)
