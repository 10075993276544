import { useContext, useEffect, useState } from "react";
import { getCaracteristicas } from "../services/caracteristicas.service";
import { caracteristicasType } from "../types";
import { TenantsContext } from "../../../context/tenants.context";

export const useCaracteristicas = () => {
  const [caracteristicas, setCaracteristicas] = useState<caracteristicasType[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const { tenant } = useContext(TenantsContext);
  useEffect(() => {
    setLoading(true);
    getCaracteristicas(tenant).then((res) => {
      const carac = res.data;
      const index = carac.findIndex((carac) => carac.nombre.includes("envíos"));
      carac.push(carac.splice(index, 1)[0]);
      setCaracteristicas(carac);
      setLoading(false);
    });
  }, []);

  return { caracteristicas, loading };
};
