import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { postLocalType } from "../../types";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Fragment, useState } from "react";
import SectionTitle from "../../common/SectionTitle";

interface FormCaracteristicasProps {
  caracteristicasStringChecked: string[];
  handleCaracteristicas: (e: React.ChangeEvent<HTMLInputElement>) => void;
  register: UseFormRegister<postLocalType>;
  errors: FieldErrors<postLocalType>;
  enableDelivery: boolean;
  isSubmitting: boolean;
  caracteristicas: any[];
  refCaracteristicas: any;
  clearErrors: any;
}

const FormCaracteristicas = ({
  refCaracteristicas,
  caracteristicas,
  isSubmitting,
  caracteristicasStringChecked,
  handleCaracteristicas,
  errors,
  register,
  enableDelivery,
  clearErrors,
}: FormCaracteristicasProps) => {
  const [minoristaOMayorista, setMinoristaOMayorista] = useState(true);

  const handleCaracteristicaChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const mayOMin =
      e.target.name === "Minorista" ||
      e.target.name === "Mayorista" ||
      caracteristicasStringChecked.includes("Minorista") ||
      caracteristicasStringChecked.includes("Mayorista");
    setMinoristaOMayorista(mayOMin);
    if (mayOMin) {
      clearErrors("caracteristicasId");
    }
    handleCaracteristicas(e);
  };

  return (
    <Box ref={refCaracteristicas}>
      <SectionTitle title="Características" />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        {caracteristicas &&
          caracteristicas.map((caracteristica, i) => {
            const checked: boolean =
              caracteristicasStringChecked?.find((c) =>
                c.includes(caracteristica.nombre)
              ) !== undefined;
            return (
              <Fragment key={i}>
                {!caracteristica.nombre.includes("envíos") ? (
                  <Grid item md={4} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox onChange={handleCaracteristicaChange} />
                      }
                      label={caracteristica.nombre}
                      name={caracteristica.nombre}
                      checked={checked}
                      disabled={isSubmitting}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleCaracteristicaChange}
                            checked={checked}
                          />
                        }
                        label={caracteristica.nombre}
                        name={caracteristica.nombre}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid
                      item
                      md={5}
                      xs={12}
                      sx={{
                        display: enableDelivery ? "block" : "none",
                        "& p": {
                          position: "absolute",
                          top: "100%",
                        },
                      }}
                    >
                      {enableDelivery && (
                        <TextField
                          {...register("compraMinimaParaEnvio")}
                          label="Minimo de compra"
                          fullWidth
                          size="small"
                          placeholder="Ej: 5 prendas, $1000"
                          disabled={isSubmitting}
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors.compraMinimaParaEnvio &&
                            String(errors?.compraMinimaParaEnvio?.message)
                          }
                          FormHelperTextProps={{ error: true }}
                          color={errors.compraMinimaParaEnvio ? "error" : "secondary"}
                        />
                      )}
                    </Grid>
                  </>
                )}
              </Fragment>
            );
          })}
        {(!minoristaOMayorista || errors.caracteristicasId) && (
          <Grid item md={12}>
            <Typography variant="caption" color="#d53839">
              Indicá si tu local vende mayorista, minorista, o los dos
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FormCaracteristicas;
