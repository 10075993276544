import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import ProtectedRoute from './components/Common/ProtectedRoute'
import setRedirectUrl from './redux/actions/redirection/setRedirectUrl'
import AbmLocalesPage from './pages/AbmLocalesPage'
import AbmLocalPage from './pages/AbmLocalPage'
import AbmLocalPageLegacy from './pages/AbmLocalPageLegacy'
import AdminLocalPage from './pages/AdminLocalPage'
import AbmProducts from './pages/Products'
import './styles/main.scss'
import ContractListPage from './pages/ContractsListPage'
import AdminAbmConfigPage from './pages/AdminAbmConfigPage'
import AdminAbmProductosPage from './pages/AdminProducts'
import { initGA, logPageView } from './analytics'
import AdminWspBotPage from './pages/WhatsappBot'
import Contactanos from './pages/Contactanos'
import LoginPage from './pages/Login'
import AbmRubrosPage from './pages/AbmRubros'
import Notificaciones from './components/Notificaciones/Notificaciones'
import Pedidos from './pages/Pedidos'
import ContratosVendedor from './pages/ContratosVendedor'
import AbmGalerias from './pages/Galerias'
import Stickers from './pages/Stickers'
import Reportes from './pages/Reportes'
import Home from './pages/Home'
import AdminHistorias from './pages/AdminHistorias'
import Estadisticas from './pages/Estadisticas'
import Calificaciones from './pages/Calificaciones'
import Integraciones from './pages/Integraciones'
import { useAppStore } from './store/app-store'
import AbmNotificaciones from "./components/AbmNotificaciones";
import OfrecerPremiumDialog from './context/OfrecerPremium/OfrecerPremiumDialog'

const App: React.FC = (props: any) => {
  const { userLogged } = props.logged
  const setCodLocal = useAppStore((state) => state.setCodLocal)

  useEffect(() => {
    initGA('G-FYPXL88XHF')
    logPageView()

    if (userLogged.isLogged) {
      setCodLocal(userLogged.user?.codLocal)
    }
  }, [userLogged, setCodLocal])

  return (
    <Router>
      <Notificaciones />
      <OfrecerPremiumDialog />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/abm-local" element={<AbmLocalPage />} />
        <Route path="/abm-local-legacy" element={<AbmLocalPageLegacy />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute
              element={<Home />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/abm-products"
          element={
            <ProtectedRoute
              element={<AbmProducts />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/integrations"
          element={
            <ProtectedRoute
              element={<Integraciones />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/suscripciones"
          element={
            <ProtectedRoute
              element={<ContratosVendedor />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/stickers"
          element={
            <ProtectedRoute
              element={<Stickers />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/abm-locales"
          element={
            <ProtectedRoute
              element={<AbmLocalesPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/historias"
          element={
            <ProtectedRoute
              element={<AdminHistorias />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/calificaciones"
          element={
            <ProtectedRoute
              element={<Calificaciones />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/contactanos"
          element={
            <ProtectedRoute
              element={<Contactanos />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/integrations"
          element={
            <ProtectedRoute
              element={<Integraciones />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />

        <Route
          path="/admin-local/:code"
          element={
            <ProtectedRoute
              element={<AdminLocalPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/abm-config"
          element={
            <ProtectedRoute
              element={<AdminAbmConfigPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/abm-rubros"
          element={
            <ProtectedRoute
              element={<AbmRubrosPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/pedidos"
          element={
            <ProtectedRoute
              element={<Pedidos />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/reportes"
          element={
            <ProtectedRoute
              element={<Reportes />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/admin-abm-products"
          element={
            <ProtectedRoute
              element={<AdminAbmProductosPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/contracts"
          element={
            <ProtectedRoute
              element={<ContractListPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/admin-wsp-bot"
          element={
            <ProtectedRoute
              element={<AdminWspBotPage />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/abm-galerias"
          element={
            <ProtectedRoute
              element={<AbmGalerias />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/estadisticas"
          element={
            <ProtectedRoute
              element={<Estadisticas />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
        <Route
          path="/notificaciones"
          element={
            <ProtectedRoute
              element={<AbmNotificaciones />}
              isAuthenticated={userLogged.isLogged}
              redirectURL="/login"
            />
          }
        />
      </Routes>
    </Router>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
