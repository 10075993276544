import { useCallback, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface FormRecaptchaProps {
  setToken: (token: string) => void
  name: string
}

const FormRecaptcha = ({name, setToken}: FormRecaptchaProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha(name);
    setToken(token)
    // Do whatever you want with the token
  }, [executeRecaptcha, name, setToken]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return <></>
};

export default FormRecaptcha
