import React from "react";
import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant } from "../../AbmLocalPage/types";

export const getGalerias = async (tenant: ITenant, filtro?: string) => {
  let url = `/admin/${tenant.path}/galerias`;

  if (filtro) url += `?q=${filtro}`;

  return await axiosInstanceMain.get(url);
};

export const createGalerias = async (galeria: any, tenant: ITenant) => {
  return await axiosInstanceMain.post(`admin/${tenant.path}/galerias`, galeria);
};

export const updateGalerias = async (galeria: any, tenant: ITenant) => {
  return await axiosInstanceMain.put(`admin/${tenant.path}/galerias/${galeria.codGaleria}`, galeria);
};

export const deleteGaleria = async (codGaleria: any, tenant: ITenant) => {
  return await axiosInstanceMain.delete(`admin/${tenant.path}/galerias/${codGaleria}`);
};
