import React, { useEffect, useState, useReducer, useRef } from "react";
import {
  ContractDTO,
  IFiltrosHistorial,
  IHistorialResponse
} from "../../dto/ContractDTO";
import AdminLocalContractsRenovations from "../../components/AdminLocal/AdminLocalContractsRenovations";
import ContractList from "./ContractList";
import * as FaIcons from "react-icons/fa";
import Footer from "../../pages/Home/components/Footer";
import Navbar from "../Navbar";
import { useTheme, useMediaQuery, styled } from "@mui/material";
import { AllInclusiveRounded } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { getHistorial, getRenovables } from "./contracts.services";
import HistorialContratos from "./historial/DialogHistorial";
import SnackBarUtils from "../../utils/SnackBarUtils";

const ContentForm = styled("div")({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "stretch",
  width: "100%",
  gap: "5px",
  marginLeft: "10px",
  top: "0px",
});

const Sticky = styled("div")({
  position: "sticky",
  top: "20px",
});

const Loading = styled("div")({
  margin: "20px 10px",
  fontSize: "1rem",
});

const ContractsComponent: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [originalContracts, setOriginalContracts] = useState<ContractDTO[]>([]);
  const [markedContracts, setMarkedContracts] = useState<ContractDTO[]>([]);
  const [suspendidosContracts, setSuspendidosContracts] = useState<ContractDTO[]>([]);
  const [outdatedPreviouslyContracts, setOutdatedPreviouslyContracts] = useState<ContractDTO[]>([]);
  const [outdatedYesterdayContracts, setOutdatedYesterdayContracts] = useState<ContractDTO[]>([]);
  const [outdateTodayContracts, setOutdateTodayContracts] = useState<ContractDTO[]>([]);
  const [outdatedTomorrowContracts, setOutdatedTomorrowContracts] = useState<ContractDTO[]>([]);
  const [outdateIn2DaysContracts, setOutdateIn2DaysContracts] = useState<ContractDTO[]>([]);
  const [outdateIn3DaysContracts, setOutdateIn3DaysContracts] = useState<ContractDTO[]>([]);
  const [showRenovations, setShowRenovations] = useState<boolean>(false);
  const [renewContract, setRenewContract] = useState<ContractDTO | null>();
  const [noShowContractsIds, setNoShowContractsIds] = useState<number[]>([]);
  // const [query, setQuery] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(true); //
  const [loadingHistorial, setLoadingHistorial] = useState<boolean>(false);

  ////////
  const [collapseMarkedContracts, setCollapseMarkedContracts] = useState<boolean>(true);
  const [collapseSuspendidosContracts, setCollapseSuspendidosContracts] = useState<boolean>(true);
  const [collapseOutdatedPreviouslyContracts, setCollapseOutdatedPreviouslyContracts] = useState<boolean>(true);
  const [collapseOutdatedYesterdayContracts, setCollapseOutdatedYesterdayContracts] = useState<boolean>(true);
  const [collapseOutdateTodayContracts, setCollapseOutdateTodayContracts] = useState<boolean>(true);
  const [collapseOutdateTomorrowContracts, setCollapseOutdateTomorrowContracts] = useState<boolean>(true);
  const [collapseOutdateIn2DaysContracts, setCollapseOutdateIn2DaysContracts] = useState<boolean>(true);
  const [collapseOutdateIn3DaysContracts, setCollapseOutdateIn3DaysContracts] = useState<boolean>(true);

  const [locationKeys, setLocationKeys] = useState<Array<string | undefined>>([]);
  const [tmpCollapsed, setTmpCollapsed] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [actualRefId, setActualRefId] = useState<number | null>(null);
  const [clearedFiltro, setClearedFiltro] = useState<boolean>(false);
  const [historial, setHistorial] = useState<IHistorialResponse>();
  const [showHistorial, setShowHistorial] = useState<boolean>(false);
  const [filtrosHistorial, setFiltrosHistorial] = useState<IFiltrosHistorial>({
    idContratoProducto: null,
    fechaDesde: null,
    fechaHasta: null,
    page: 0,
    size: 100,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const input1 = useRef<HTMLInputElement | null>(null);
  const refs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const getYesterdayDate = () => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    d.setHours(0, 0, 0, 0);
    return d;
  };
  const getCurrentDate = () => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  };
  const getTomorrowDate = () => {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(0, 0, 0, 0);
    return d;
  };
  const getDateIn2Days = () => {
    const d = new Date();
    d.setDate(d.getDate() + 2);
    d.setHours(0, 0, 0, 0);
    return d;
  };
  const getDateIn3Days = () => {
    const d = new Date();
    d.setDate(d.getDate() + 3);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  useEffect(() => {
    navigate(location.pathname);

    const fetchData = async () => {
      setIsLoading(true);
      await getRenovables()
        .then((result) => {
          setOriginalContracts(result.data);
          setAllContracts(result.data);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(true);
        })
        .finally(() => setIsLoading(false));
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fechHistorial = async () => {
      setLoadingHistorial(true);
      await getHistorial(filtrosHistorial)
        .then((result) => {
          setHistorial(result.data.data);
        })
        .catch((error) => {
          console.log(error);
          SnackBarUtils.error("Error buscando contratos");
        })
        .finally(() => setLoadingHistorial(false));
    };

    fechHistorial();
  }, [filtrosHistorial]);

  const saveActualState = () => {
    setClearedFiltro(false);
    handleScroll();
    const collapsed: string[] = [];
    if (collapseMarkedContracts) collapsed.push("marked");
    if (collapseSuspendidosContracts) collapsed.push("suspendidos");
    if (collapseOutdatedPreviouslyContracts) collapsed.push("previously");
    if (collapseOutdatedYesterdayContracts) collapsed.push("yesterday");
    if (collapseOutdateTodayContracts) collapsed.push("today");
    if (collapseOutdateTomorrowContracts) collapsed.push("tomorrow");
    if (collapseOutdateIn2DaysContracts) collapsed.push("2Days");
    if (collapseOutdateIn3DaysContracts) collapsed.push("3Days");
    setTmpCollapsed(collapsed);
  };

  useEffect(() => {
    navigate(location.pathname);
    if (location.state && location.state.action === "PUSH") {
      setLocationKeys([location.key]);
    }
    if (location.state && location.state.action === "POP") {
      closeModal();
      if (locationKeys[1] === location.key) {
        setLocationKeys(([_, ...keys]) => keys);
      } else {
        setLocationKeys((keys) => [location.key, ...keys]);
      }
    }
  }, [locationKeys]);

  const setAllContracts = (data: Array<any>) => {
    if (data.length === 0) setNoResults(true);
    const contratosPospuestos: ContractDTO[] = data.filter((c: any) => c.pospuesto);
    setMarkedContracts(contratosPospuestos);
    setOutdatedPreviouslyContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate < getYesterdayDate() && !c.pospuesto && c.estadoNombre === "Vencido";
      })
    );
    setOutdatedYesterdayContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate.toLocaleDateString() === getYesterdayDate().toLocaleDateString() && !c.pospuesto;
      })
    );
    setSuspendidosContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate < getCurrentDate() && !c.pospuesto && c.estadoNombre !== "Vencido";
      })
    );
    setOutdateTodayContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate.toLocaleDateString() === getCurrentDate().toLocaleDateString() && !c.pospuesto;
      })
    );
    setOutdatedTomorrowContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate.toLocaleDateString() === getTomorrowDate().toLocaleDateString() && !c.pospuesto;
      })
    );
    setOutdateIn2DaysContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate.toLocaleDateString() === getDateIn2Days().toLocaleDateString() && !c.pospuesto;
      })
    );
    setOutdateIn3DaysContracts(
      data.filter((c: any) => {
        const endDate = new Date(
          parseInt(c.fechaFinFormateada.split("/")[2]),
          parseInt(c.fechaFinFormateada.split("/")[1]) - 1,
          parseInt(c.fechaFinFormateada.split("/")[0])
        );
        return endDate.toLocaleDateString() === getDateIn3Days().toLocaleDateString() && !c.pospuesto;
      })
    );
  };

  const closeModal = () => {
    setShowRenovations(false);
    setRenewContract(null);
    forceUpdate();
  };

  const handleScroll = () => {
    const visibleContracts = Object.entries(refs.current).find(([id, ref]) => {
      if (ref) {
        const rect = ref.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      }
      return false;
    });

    if (visibleContracts) {
      const [id, ref] = visibleContracts;
      setActualRefId(parseInt(id));
    }
  };

  const searchPremises = () => {
    saveActualState();
    const value = input1.current ? input1.current.value : "";
    if (!value) {
      getRenovables()
        .then((result) => {
          setAllContracts(result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setAllContracts(
        originalContracts?.filter(
          (c) =>
            c.idContrato?.toString().includes(value) ||
            c.productoNombre?.toLowerCase().includes(value) ||
            c.estadoNombre?.toLowerCase().includes(value) ||
            c.clienteNombre?.toLowerCase().includes(value) ||
            c.idContratoClienteLocal?.toString().includes(value) ||
            c.clienteWhatsapp?.toString().includes(value)
        )
      );
      window.scrollTo(0, 0);
      handleExpandAll();
    }
  };

  const handlePreviousState = () => {
    setClearedFiltro(true);
    setCollapseMarkedContracts(tmpCollapsed.includes("marked"));
    setCollapseSuspendidosContracts(tmpCollapsed.includes("suspendidos"));
    setCollapseOutdatedPreviouslyContracts(tmpCollapsed.includes("previously"));
    setCollapseOutdatedYesterdayContracts(tmpCollapsed.includes("yesterday"));
    setCollapseOutdateTodayContracts(tmpCollapsed.includes("today"));
    setCollapseOutdateTomorrowContracts(tmpCollapsed.includes("tomorrow"));
    setCollapseOutdateIn2DaysContracts(tmpCollapsed.includes("2Days"));
    setCollapseOutdateIn3DaysContracts(tmpCollapsed.includes("3Days"));
  };

  const clearFiltro = () => {
    setAllContracts(originalContracts);
    setNoResults(false);

    if (input1.current) input1.current.value = "";
    handlePreviousState();
  };

  const handleExpandAll = () => {
    setCollapsed(false);
    setCollapseMarkedContracts(false);
    setCollapseOutdatedPreviouslyContracts(false);
    setCollapseOutdatedYesterdayContracts(false);
    setCollapseSuspendidosContracts(false);
    setCollapseOutdateTodayContracts(false);
    setCollapseOutdateTomorrowContracts(false);
    setCollapseOutdateIn2DaysContracts(false);
    setCollapseOutdateIn3DaysContracts(false);
  };

  const handleCollapseAll = () => {
    setCollapsed(true);
    setCollapseMarkedContracts(true);
    setCollapseOutdatedPreviouslyContracts(true);
    setCollapseOutdatedYesterdayContracts(true);
    setCollapseSuspendidosContracts(true);
    setCollapseOutdateTodayContracts(true);
    setCollapseOutdateTomorrowContracts(true);
    setCollapseOutdateIn2DaysContracts(true);
    setCollapseOutdateIn3DaysContracts(true);
  };

  const updateContractList = (id: number) => {
    const updatedContracts = originalContracts.filter((c: ContractDTO) => c.idContrato !== id);
    setOriginalContracts(updatedContracts);
    setAllContracts(updatedContracts);
  };

  return (
    <>
      <AdminLocalContractsRenovations
        contract={renewContract}
        show={showRenovations}
        handleClose={closeModal}
        noShowContracts={noShowContractsIds}
        setNoShowContracts={setNoShowContractsIds}
        updateContractList={updateContractList}
      ></AdminLocalContractsRenovations>
      <Navbar title="Contratos" />
      <Sticky>
        <ContentForm>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="input-group">
              <div>
                <input
                  type="search"
                  ref={input1}
                  onBlur={() => input1.current && input1.current.blur()}
                  placeholder="Buscar"
                  className="form-outline form-control-lg"
                  style={{ marginTop: "5px" }}
                />
              </div>
              <button
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="submit"
                onClick={searchPremises}
                className="btn btn-primary btn-lg"
              >
                <FaIcons.FaSearch />
              </button>
              <button
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="submit"
                onClick={handleExpandAll}
                className="btn btn-primary btn-lg"
              >
                Expandir todo
              </button>
              <button
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="submit"
                onClick={handleCollapseAll}
                className="btn btn-primary btn-lg"
              >
                Colapsar todo
              </button>
              <button
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="submit"
                onClick={() => setShowHistorial(true)}
                className="btn btn-primary btn-lg"
              >
                Historial
              </button>
            </div>
          </form>
          {input1.current && input1.current.value !== "" && (
            <button style={{ marginTop: "5px" }} type="submit" onClick={clearFiltro} className="btn btn-primary btn-lg">
              Limpiar búsqueda
            </button>
          )}
        </ContentForm>
      </Sticky>
      {isLoading ? (
        <Loading>Cargando contratos...</Loading>
      ) : (
        <>
          <ContractList
            refs={refs}
            group={"Pospuestos"}
            title={`Pospuestos (${markedContracts.length})`}
            textColor={"lightblue"}
            hidden={markedContracts.length < 1}
            contracts={markedContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseMarkedContracts}
            setnoShow={setCollapseMarkedContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"Suspendidos"}
            title={`Suspendidos (${suspendidosContracts.length})`}
            textColor={"yellow"}
            hidden={suspendidosContracts.length < 1}
            contracts={suspendidosContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseSuspendidosContracts}
            setnoShow={setCollapseSuspendidosContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"VencidosAntes"}
            title={`Vencidos Anteriormente (${outdatedPreviouslyContracts.length})`}
            textColor={"lightcoral"}
            hidden={outdatedPreviouslyContracts.length < 1}
            contracts={outdatedPreviouslyContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseOutdatedPreviouslyContracts}
            setnoShow={setCollapseOutdatedPreviouslyContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"VencidosAyer"}
            title={`Vencidos ayer (${outdatedYesterdayContracts.length})`}
            textColor={"lightcoral"}
            hidden={outdatedYesterdayContracts.length < 1}
            contracts={outdatedYesterdayContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseOutdatedYesterdayContracts}
            setnoShow={setCollapseOutdatedYesterdayContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"VencenHoy"}
            title={`Vencen hoy (${outdateTodayContracts.length})`}
            textColor={"lightblue"}
            hidden={outdateTodayContracts.length < 1}
            contracts={outdateTodayContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseOutdateTodayContracts}
            setnoShow={setCollapseOutdateTodayContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"VencenMañana"}
            title={`Vencen mañana (${outdatedTomorrowContracts.length})`}
            textColor={"lightblue"}
            hidden={outdatedTomorrowContracts.length < 1}
            contracts={outdatedTomorrowContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseOutdateTomorrowContracts}
            setnoShow={setCollapseOutdateTomorrowContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"Vencen2dias"}
            title={`Vencen en 2 días (${outdateIn2DaysContracts.length})`}
            textColor={"lightblue"}
            hidden={outdateIn2DaysContracts.length < 1}
            contracts={outdateIn2DaysContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseOutdateIn2DaysContracts}
            setnoShow={setCollapseOutdateIn2DaysContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <ContractList
            refs={refs}
            group={"Vencen3dias"}
            title={`Vencen en 3 días (${outdateIn3DaysContracts.length})`}
            textColor={"lightblue"}
            hidden={outdateIn3DaysContracts.length < 1}
            contracts={outdateIn3DaysContracts}
            setShowRenovations={setShowRenovations}
            setRenewContract={setRenewContract}
            noShowContracts={noShowContractsIds}
            setNoShowContracts={setNoShowContractsIds}
            noshow={collapseOutdateIn3DaysContracts}
            setnoShow={setCollapseOutdateIn3DaysContracts}
            setAllCollapsed={setCollapsed}
            updateContractList={updateContractList}
            clearedFiltro={clearedFiltro}
            refId={actualRefId}
          />
          <HistorialContratos
            historial={historial}
            filtros={filtrosHistorial}
            setFiltros={setFiltrosHistorial}
            show={showHistorial}
            setShow={setShowHistorial}
            loading={loadingHistorial}
            setLoading={setLoadingHistorial}
            headers={[
              "Fecha",
              "Id Contrato",
              "Id Local",
              "Nombre Cliente",
              "Nombre Producto",
              "Estado",
              "Fecha inicio",
              "Fecha fin",
              "Obs"
            ]}
          />
        </>
      )}
      <Footer />
    </>
  );
};

export default ContractsComponent;
