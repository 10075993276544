export const type = "setLogged";

const setLogged = (data: any) => {
  return {
    type,
    payload: {
      token: data.accessToken,
      refreshToken: data.refreshToken,
      accessTokenVencimiento: data.accessTokenVencimiento,
      user: {
        userId: data.idUsuario,
        nickname: data.nickname,
        idVendedor: data.idVendedor,
        idLocal: data.idLocal,
        codLocal: data.codLocal,
        roles: data.roles,
      },
    },
  };
};
export default setLogged;
