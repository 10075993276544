import { ITienda, TiendaNames } from '../types'
import LogoWoo from '../../../media/logo_woocommerce.png'
import LogoTN from '../../../media/logo_tiendanube.png'

export const Tiendas: ITienda[] = [
  {
    name: TiendaNames.WOO,
    logo: LogoWoo,
    color: '#7f54b3'
  },
  {
    name: TiendaNames.TN,
    logo: LogoTN,
    color: '#E3E4E5'
  }
]
