import { CustomDataGridProps } from './CustomDataGrid'
import { Box, Typography } from '@mui/material'
import { mostrarFechaCompleta } from '../../utils/DateFunctions'
import Loading from '../Common/Loading'

const HistorialCards = ({data, loadingHistorial}: CustomDataGridProps) => {
  if (loadingHistorial) return <Loading />

  return (
    <Box>
      {
        data.map((item, index) => {
          return (
            <Box
              sx={{p: 1, m: 0.5, boxShadow: 1, width: 'auto'}}
              key={index}
            >
              <Box>
                <Typography variant="body1">Fecha: <b>{mostrarFechaCompleta(item.fecha)}</b></Typography>
                <Typography variant="body1">Estado: <b>{item.aprobado ? 'Aprobado' : item.rechazado ? 'Rechazado' : 'Pausado'}</b></Typography>
                <Typography variant="body2">Cuenta tipo: {item.cuentaTipo}</Typography>
                <Typography variant="body2">Local tipo: {item.localTipo}</Typography>
                <Typography variant="body2">Nombre: {item.nombre}</Typography>
                <Typography variant="body2">Direccion: {item.direccion}</Typography>
                <Typography variant="body2">Fecha baja: {item.fechaBaja ? mostrarFechaCompleta(item.fechaBaja) : '-'}</Typography>
              </Box>
            </Box>
          )}
        )
      }
    </Box>
  )
}

export default HistorialCards
