export const mostrarHora = (date: Date | string) => {
  const d = new Date(date);
  const hour = d.getHours();
  const minute = d.getMinutes();
  const seconds = d.getSeconds();

  const hourString = hour < 10 ? `0${hour}` : hour;
  const minuteString = minute < 10 ? `0${minute}` : minute;
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;

  return `${hourString}:${minuteString}:${secondsString}`;
};

export const mostrarFecha = (date: Date | string) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  const dayString = day < 10 ? `0${day}` : day;
  const monthString = month < 10 ? `0${month}` : month;

  return `${dayString}/${monthString}/${year}`;
};

export const mostrarFechaCompleta = (date: Date | string, conSeparador = false, conHorario=false) => {
  const d = new Date(date);
  const fecha = mostrarFecha(d);
  const hora = mostrarHora(d);
  const separador = conSeparador ? ' - ' : ' ';

  const horario = conHorario ? ` ${hora.includes('PM') ? 'PM' : 'AM'}` : '';
  return `${fecha}${separador} ${hora}${horario}`;
};

export const checkSiPasoUnDia = (date: Date | string) => {
  const d = new Date(date);
  const hoy = new Date();
  const diferencia = hoy.getTime() - d.getTime();
  const diferenciaEnDias = diferencia / (1000 * 3600 * 24);
  return diferenciaEnDias >= 1;
};

export const checkSiEsHoy = (date: Date | string) => {
  const d = new Date(date);
  const hoy = new Date();
  return (
    d.getDate() === hoy.getDate() &&
    d.getMonth() === hoy.getMonth() &&
    d.getFullYear() === hoy.getFullYear()
  );
};

export const checkSiEsAyer = (date: Date | string) => {
  const d = new Date(date);
  const ayer = new Date();
  ayer.setDate(ayer.getDate() - 1);
  return (
    d.getDate() === ayer.getDate() &&
    d.getMonth() === ayer.getMonth() &&
    d.getFullYear() === ayer.getFullYear()
  );
};
