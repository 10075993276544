import { useCallback, useContext, useEffect, useState } from 'react'
import { TenantsContext } from '../../../context/tenants.context'
import { Rubro } from '../../Integraciones/types/responses-productos'
import { getAllRubros } from '../../AdminProducts/services/rubros.service'

export function flatRubros(rubros: Rubro[]): Rubro[] {
  return rubros
    .map((rubro) => {
      if (rubro.rubros?.length > 0)
        return rubro.rubros.map((subrubros) => (subrubros.rubros ? subrubros.rubros : subrubros))

      return rubro
    })
    .flat(3)
}

const useRubros = () => {
  const [rubros, setRubros] = useState<Rubro[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { tenant } = useContext(TenantsContext)

  const fetchRubrosLocal = useCallback(() => {
    setLoading(true)
    getAllRubros(tenant)
      .then(({ data }) => {
        setRubros(data.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [tenant])

  useEffect(() => {
    fetchRubrosLocal()
  }, [tenant])

  return {
    rubros,
    setRubros,
    rubrosFlatted: flatRubros(rubros),
    updateRubros: fetchRubrosLocal,
    loading
  }
}
export default useRubros
