import { Box, Button, FormControlLabel, Paper, Switch, Typography } from '@mui/material'
import AccionesMasivas from './AccionesMasivas'
import { useContext, useCallback, useState } from 'react'
import { IntegracionesContext } from '../../../context/integraciones.context'
import { Novedad } from '../../../types/responses-novedades'
import { RejectNovedad, UpdateNovedades } from '../../../services/novedades.service'
import SearchNovedades from './SearchNovedades'
import { useAppStore } from '../../../../../store/app-store'
import ListadoNovedades from './ListadoNovedades'
import { TabsContent } from '@radix-ui/react-tabs'
import { MenuTabs } from '../../../types'
import { LuSearch } from 'react-icons/lu'
import ButtonRounded from '../../../../../components/Common/utils/button/ButtonRounded'

const IntegracionesNovedades = () => {
  const codLocal = useAppStore((state) => state.codLocal)
  const { tiendaSeleccionada, getNovedades } = useContext(IntegracionesContext)
  const [searching, setSearching] = useState(false)

  const filterNovedades = useCallback(
    async (search: string, category: string) => {
      if (!tiendaSeleccionada) return
      setSearching(true)
      await getNovedades(codLocal, category === 'todos' ? '' : category, search)
      setSearching(false)
    },
    [tiendaSeleccionada, codLocal, getNovedades]
  )

  const update = useCallback(
    async (novedad: Partial<Novedad>[]) => {
      if (!tiendaSeleccionada) return
      const novedadesUnificadas: Partial<Novedad>[] = []
      novedad.forEach((n) => {
        const index = novedadesUnificadas.findIndex(
          (nu) => nu.productoInterno.idproducto === n.productoInterno.idproducto
        )
        if (index === -1) {
          novedadesUnificadas.push(n)
        } else {
          novedadesUnificadas[index] = { ...novedadesUnificadas[index], ...n }
        }
      })

      console.log('novedadesUnificadas', novedadesUnificadas)

      await UpdateNovedades(codLocal, novedadesUnificadas)
      await getNovedades(codLocal, '', '')
    },
    [tiendaSeleccionada, codLocal, getNovedades]
  )

  const reject = useCallback(
    async (id: number) => {
      if (!tiendaSeleccionada) return
      await RejectNovedad(codLocal, id)
      await getNovedades(codLocal, '', '')
    },
    [tiendaSeleccionada, codLocal, getNovedades]
  )

  return (
    <TabsContent
      value={MenuTabs.Novedades}
      className="w-100"
      style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
    >
      <Typography fontSize={{ xs: 20, md: 24 }} fontWeight="bold">
        Novedades de los productos que agregaste desde Woocommerce
      </Typography>
      <Box display="flex" justifyContent="space-between" width="100%">
        {/* <AccionesMasivas /> */}
        {/* <SearchNovedades filterNovedades={filterNovedades} searching={searching} /> */}
        <Box display="flex" gap="8px" alignItems="center">
          <Typography color="#8E8E8E" fontWeight="medium" fontSize={{ xs: 12, sm: 14, md: 16 }}>
            Aplicar automáticamente todos los cambios
          </Typography>
          <FormControlLabel control={<Switch />} label="" />
        </Box>
        <ButtonRounded startIcon={<LuSearch />}>Buscar Productos</ButtonRounded>
      </Box>

      {/* Listado Novedades */}
      <ListadoNovedades reject={reject} />
    </TabsContent>
  )
}

export default IntegracionesNovedades
