import { memo, useCallback, useState } from 'react'
import SubirArchivo, { CasoRepiteID } from './SubirArchivo'
import AsignarColumnas from './AsignarColumnas'
import AsignarCategorias from './AsignarCategorias'
import { Box, Typography } from '@mui/material'
import {
  AtributoConValor,
  Atributos,
  Columna,
  CsvEquivalencia,
  Equivalencia,
  ImportarData,
  ResultadoFinal,
  RubroEquivalencia
} from './types/importacion'
import CloseButton, { CloseButtonProps } from '../../../components/CloseButton'
import { useStoreImportacion } from './store/store-importacion'
import AsignarAtributos from './AsignarAtributos'
import { ImportarCSV, SetCSVEquivalencias, SetRubrosEquivalencias } from '../services/impo-expo.service'
import { attributes, recorrerDataExcelYReemplazar } from './utils/helpers'
import MensajeFinal from './MensajeFinal'
import { useAppStore } from '../../../store/app-store'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import usePasos from './hooks/usePasos'
import { ProductoAtributoList } from '../types/responses'
import { Rubro } from '../types/responses-productos'
import { useResponsive } from '../../../hooks/useResponsive.hook'
import BotonesAtributos from './components/BotonesAtributos'
import { Atributo, AtributosEquivalencias, SaveValores } from '../types'
import {
  GetAtributosEquivalencias,
  GetAtributosEquivalenciasValores,
  SaveValoresAtributos
} from '../services/atributos.service'
import { RubroYValores } from './types/atributos'
import ButtonRounded from '../../../components/Common/utils/button/ButtonRounded'
import CustomStepper from '../../../components/Common/CustomStepper'
import useRubros from '../../Products/hooks/rubros.hook'

const Headers = [
  'SKU',
  'Nombre',
  'Categoría',
  'Moneda',
  'Precio',
  'Compra_mínima_por_mayor',
  'Precio_anterior_sin_descuento',
  'Precio_minorista',
  'Talles',
  // "Colores",
  // "Composición",
  // "Medida",
  // "Tamaño",
  'Unidad_de_medida',
  'Descripción'
  // 'ID'
]

export enum Pasos {
  SubirArchivo = 'Subir Archivo',
  AsignarColumnas = 'Asignar Columnas',
  AsignarCategorias = 'Asignar Categorias',
  AsignarAtributos = 'Asignar Atributos',
  ResultadoFinal = 'Resultado'
}

export enum PasosAtributos {
  Colores = 'Colores',
  Talles = 'Talles',
  Composiciones = 'Composicion',
  Medidas = 'Medida',
  Tamanos = 'Tamano'
}

export const CATEGORIA_NAME = 'codRubro'
export const TALLES_NAME = 'talles'

interface Props extends CloseButtonProps {
  getProducts: () => Promise<any[]>
}

const ImportarCatalogo = ({ closeButton, getProducts }: Props) => {
  const codLocal = useAppStore((state) => state.codLocal)
  const { isValid, loading, dataExcel, setIsValid, setLoading, setFile, setPercentage } = useStoreImportacion(
    (state) => state
  )
  const {
    paso,
    setPaso,
    handleBack,
    handleNext,
    reset: resetPaso
  } = usePasos({
    pasos: Object.values(Pasos),
    pasoInicial: Pasos.SubirArchivo
  })
  const {
    paso: pasoAtributos,
    setPaso: setPasoAtributos,
    handleBack: handleBackAttr,
    handleNext: handleNextAttr
  } = usePasos({
    pasos: Object.values(PasosAtributos),
    pasoInicial: PasosAtributos.Colores
  })
  const [dataToSend, setDataToSend] = useState<ImportarData[]>([])
  // Subir archivo
  const [casoRepiteIDs, setCasoRepiteIDs] = useState(CasoRepiteID.ReemplazaExistente)
  // Columnas
  const [columnas, setColumnas] = useState<Columna[]>([])
  const [columnasEquivalencias, setColumnasEquivalencias] = useState<Equivalencia[]>([])
  const [csvEquivalencias, setCsvEquivalencias] = useState<CsvEquivalencia[]>([])
  // Categorias
  const [categorias, setCategorias] = useState<string[]>([])
  const [categoriasEquivalencias, setCategoriasEquivalencias] = useState<Equivalencia[]>([])
  const [rubrosEquivalencias, setRubrosEquivalencias] = useState<RubroEquivalencia[]>([])
  // Atributos
  const [atributos, setAtributos] = useState<any>({})
  const [atributosEquivalencias, setAtributosEquivalencias] = useState<any[]>([])
  const [valoresList, setValoresList] = useState<AtributoConValor>({
    colores: [],
    talles: [],
    composicion: [],
    medida: [],
    tamano: []
  })
  const [valoresASincronizar, setValoresASincronizar] = useState<SaveValores[]>([])
  // Mensaje Final
  const [resultado, setResultado] = useState<ResultadoFinal | null>(null)
  const { rubrosFlatted } = useRubros()
  const { isMobile } = useResponsive()
  const [atributosTemplate, setAtributosTemplate] = useState<Atributo[]>([])
  const [atributosValores, setAtributosValores] = useState<AtributosEquivalencias[]>([])
  const [RubroYValores, setRubroYValores] = useState<RubroYValores[]>([])

  const updateProgress = useCallback(
    (percentage: number | null) => {
      setPercentage(percentage)
    },
    [setPercentage]
  )

  const importar = useCallback(async () => {
    if (!rubrosFlatted) return

    const lineasAProcesar = dataExcel.length
    if (!window.confirm(`¿Estás seguro que deseás importar los datos? Se van a procesar ${lineasAProcesar} líneas.`))
      return

    const newData: ImportarData[] = []

    // Set atributos
    Object.entries(valoresList).forEach(([key, value]) => {
      const talles =
        value
          .filter((v) => v.atributo === 'talles')
          .map((v) => {
            return { nombre: v.nombre, id: v.id }
          }) || null
      const colores =
        value
          .filter((v) => v.atributo === 'colores')
          .map((v) => {
            return { nombre: v.nombre, id: v.id }
          }) || null
      const composicion =
        value
          .filter((v) => v.atributo === 'composicion')
          .map((v) => {
            return { nombre: v.nombre, id: v.id }
          }) || null
      const medida =
        value
          .filter((v) => v.atributo === 'medida')
          .map((v) => {
            return { nombre: v.nombre, id: v.id }
          }) || null
      const tamano =
        value
          .filter((v) => v.atributo === 'tamano')
          .map((v) => {
            return { nombre: v.nombre, id: v.id }
          }) || null

      // Set Data
      const data = dataToSend.filter((d) => d[CATEGORIA_NAME] === key)
      const rubro = rubrosFlatted.find((r) => r.codRubro === key) as Rubro

      data.forEach((d) => {
        if (d.talles) {
          d.talles = [
            rubro.productoAtributoList.find((a) => a.nombrePlural.toLowerCase() === 'talles') as ProductoAtributoList
          ]
          if (d.talles[0]) d.talles[0].valoresList = [...new Set(talles)]
          else d.talles = null
        } else d.talles = null
        if (d.colores) {
          d.colores = [
            rubro.productoAtributoList.find((a) => a.nombrePlural.toLowerCase() === 'colores') as ProductoAtributoList
          ]
          if (d.colores[0]) d.colores[0].valoresList = [...new Set(colores)]
          else d.colores = null
        } else d.colores = null
        if (d.composicion) {
          d.composicion = [
            rubro.productoAtributoList.find(
              (a) => a.nombreSingular.toLowerCase() === 'composicion'
            ) as ProductoAtributoList
          ]
          if (d.composicion[0]) d.composicion[0].valoresList = [...new Set(composicion)]
          else d.composicion = null
        } else d.composicion = null
        if (d.medida) {
          d.medida = [
            rubro.productoAtributoList.find((a) => a.nombreSingular.toLowerCase() === 'medida') as ProductoAtributoList
          ]
          if (d.medida[0]) d.medida[0].valoresList = [...new Set(medida)]
          else d.medida = null
        } else d.medida = null
        if (d.tamano) {
          d.tamano = [
            rubro.productoAtributoList.find((a) => a.nombreSingular.toLowerCase() === 'tamano') as ProductoAtributoList
          ]
          if (d.tamano[0]) d.tamano[0].valoresList = [...new Set(tamano)]
          else d.tamano = null
        } else d.tamano = null

        newData.push(d)
      })
    })

    // Importar
    updateProgress(0)
    setLoading(true)
    try {
      let requestsCount = 1
      if (newData.length > 1 && newData.length <= 4) {
        requestsCount = 2
      } else if (newData.length > 4) {
        requestsCount = 4
      }

      const chunkSize = Math.ceil(newData.length / requestsCount)

      for (let i = 0; i < requestsCount; i++) {
        const start = i * chunkSize
        const end = start + chunkSize
        const newDataPart = newData.slice(start, end)

        // Realizar llamada a la API con la parte correspondiente de newData
        const res = await ImportarCSV(codLocal, newDataPart, casoRepiteIDs === CasoRepiteID.ReemplazaExistente)

        // Actualizar el estado con los datos de la llamada
        setResultado((prev: any) => {
          return {
            lineasErroneas: (prev?.lineasErroneas || 0) + res.data.data.lineasErroneas,
            lineasProcesadas: (prev?.lineasProcesadas || 0) + res.data.data.lineasProcesadas,
            lineasIgnoradas: (prev?.lineasIgnoradas || 0) + res.data.data.lineasIgnoradas,
            productosError: prev?.productosError
              ? prev.productosError.concat(res.data.data.productosError)
              : res.data.data.productosError
          }
        })

        // Actualizar el estado de la barra de progreso
        const progress = ((i + 1) / requestsCount) * 100
        updateProgress(progress)
        if (progress === 100) await new Promise((resolve) => setTimeout(resolve, 1500))
      }

      // Actualizar el estado de isValid y paso
      setIsValid(true)
      setPaso(Pasos.ResultadoFinal)

      // Actualizar el estado de loading a falso
      setLoading(false)

      // Actualizar los productos
      await getProducts()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      updateProgress(null)
    }
  }, [
    casoRepiteIDs,
    dataToSend,
    codLocal,
    valoresList,
    rubrosFlatted,
    getProducts,
    setPaso,
    setLoading,
    setIsValid,
    updateProgress,
    dataExcel.length
  ])

  const setTemplateAtributosValores = useCallback(async () => {
    setLoading(true)

    try {
      const { data: dataAttrs } = await GetAtributosEquivalencias(codLocal)
      setAtributosTemplate(dataAttrs || null)

      if (dataAttrs) {
        const resAttrsValores: AtributosEquivalencias[] = await Promise.all(
          dataAttrs.map(async (a) => {
            if (a.idIntegracionAtributo === 0) return null
            const { data: dataAttrsValores } = await GetAtributosEquivalenciasValores(a.idIntegracionAtributo, codLocal)
            return dataAttrsValores as any
          })
        )

        const filteredResAttrsValores = [
          ...new Set(resAttrsValores.filter(Boolean))
        ].flat() as unknown as AtributosEquivalencias[] // Filtra valores nulos
        setAtributosValores(filteredResAttrsValores)
      }
    } catch (error) {
      SnackBarUtils.error('Error al obtener el template')
    } finally {
      setLoading(false)
    }
  }, [codLocal])

  const sendCsvEquivalencias = useCallback(async () => {
    if (csvEquivalencias.length <= 0) return

    setLoading(true)
    try {
      const { data } = await SetCSVEquivalencias(codLocal, csvEquivalencias)
      setCsvEquivalencias([])
      SnackBarUtils.success(data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [codLocal, csvEquivalencias, setLoading])

  const sendRubrosEquivalencias = useCallback(async () => {
    if (rubrosEquivalencias.length <= 0) return

    setLoading(true)
    try {
      const { data } = await SetRubrosEquivalencias(codLocal, rubrosEquivalencias)
      setRubrosEquivalencias([])
      SnackBarUtils.success(data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [codLocal, rubrosEquivalencias, setLoading])

  const reset = useCallback(() => {
    setFile(null)
    setCategorias([])
    setColumnas([])
    setColumnasEquivalencias([])
    setCategoriasEquivalencias([])
    setCsvEquivalencias([])
    setRubrosEquivalencias([])
    setDataToSend([])
    setAtributosEquivalencias([])
    setAtributos({})
    setValoresList({
      colores: [],
      talles: [],
      composicion: [],
      medida: [],
      tamano: []
    })
    setResultado(null)
    setPasoAtributos(PasosAtributos.Colores)
    resetPaso()
  }, [setFile, resetPaso, setPasoAtributos])

  const checkContentDataIsValid = useCallback((dataExcel: any[]) => {
    let isValid = true
    if (dataExcel.length <= 0) {
      isValid = false
      return handleError({ error: 'Error al procesar el archivo' })
    }

    const headersError: string[] = []

    for (const row of dataExcel) {
      // Chequear Unidad De Medida
      Headers.forEach((header) => {
        if (!row[header]) {
          isValid = false
          headersError.push(header)
        }
      })

      // Si tiene todos los headers, entonces es valido
      if (isValid) {
        // Chequear que todos los productos tengan bien asignados el valor de Publicar
        if (!row.Publicar) {
          row.Publicar = false
        } else if (row.Publicar) {
          row.Publicar = true
        } else if (
          row.Publicar?.toUpperCase() === 'FALSO' ||
          row.Publicar?.toUpperCase() === 'FALSE' ||
          row.Publicar?.toUpperCase() === 'NO'
        ) {
          row.Publicar = false
        } else if (
          row.Publicar?.toUpperCase() === 'VERDADERO' ||
          row.Publicar?.toUpperCase() === 'TRUE' ||
          row.Publicar?.toUpperCase() === 'SI'
        ) {
          row.Publicar = true
        } else {
          row.Publicar = false
        }
      }
    }

    if (headersError.length > 0) {
      return handleError({
        error: `Revisa que todos los productos tengan ${[...new Set(headersError)]}`
      })
    }

    if (headersError.length > 0) {
      return handleError({
        error: `Revisa que todos los productos tengan ${[...new Set(headersError)]}`
      })
    }

    return isValid
  }, [])

  const handleAsignarColumnas = useCallback(() => {
    // Chequear contenido del archivo
    const dataValida = checkContentDataIsValid(dataExcel)
    if (!dataValida) return

    // Asignar columnas
    const columnas = Object.keys(dataExcel[0]).map((nombre) => {
      return {
        nombre,
        valor: dataExcel[0][nombre].toString()
      }
    })

    setColumnas(columnas)
  }, [dataExcel, reset])

  const handleAsignarCategorias = useCallback((): Promise<void> => {
    console.log({ columnasEquivalencias })

    return new Promise((resolve) => {
      const categoria = columnasEquivalencias.find((eq) => Object.keys(eq)[0] === CATEGORIA_NAME)
      if (!categoria) {
        resolve()
        return
      }

      const nombreCategoria = categoria[CATEGORIA_NAME].value
      const indexedData = dataExcel.reduce((acc, row) => {
        acc[row[nombreCategoria]] = row
        return acc
      }, {} as Record<string, (typeof dataExcel)[number]>)

      const valoresCategoria = Object.keys(indexedData)

      setCategorias(valoresCategoria)
      resolve()
    })
  }, [columnasEquivalencias, dataExcel, setCategorias])

  const handleAsignarAtributos = useCallback(async () => {
    setLoading(true)

    try {
      const data = await recorrerDataExcelYReemplazar(dataExcel, columnasEquivalencias, categoriasEquivalencias)
      setDataToSend(data)

      const separador = /\s*[|,;\-]\s*/

      const atributosPromises = categoriasEquivalencias.map(async (eq) => {
        const categoria = Object.keys(eq)[0]
        const valor = eq[categoria].value

        const atributos = data
          .filter((row) => row[CATEGORIA_NAME] === valor)
          .reduce(
            (acc: { [key: string]: string[] }, row) => {
              const agregarAtributo = (campo: keyof typeof row, target: keyof typeof acc) => {
                if (row[campo]) {
                  const valores = (row[campo] as unknown as string).split(separador).map((t: string) => t.trim())
                  acc[target] = [...new Set([...acc[target], ...valores])]
                }
              }

              agregarAtributo('colores', 'colores')
              agregarAtributo('talles', 'talles')
              agregarAtributo('composicion', 'composicion')
              agregarAtributo('medida', 'medida')
              agregarAtributo('tamano', 'tamano')

              return acc
            },
            { colores: [], talles: [], composicion: [], medida: [], tamano: [] }
          )

        return { [valor]: atributos }
      })

      const atributosPorCategoria = await Promise.all(atributosPromises)

      await setTemplateAtributosValores()

      setAtributos(atributosPorCategoria)
      return atributosPorCategoria
    } catch (error) {
      console.error('Error al asignar atributos:', error)
    } finally {
      setLoading(false)
    }
  }, [categoriasEquivalencias, columnasEquivalencias, dataExcel, setLoading])

  const sincronizarValoresAtributos = useCallback(async () => {
    if (valoresASincronizar.length <= 0) return
    setLoading(true)
    try {
      await SaveValoresAtributos(valoresASincronizar, codLocal)
      SnackBarUtils.success('Valores sincronizados correctamente')
    } catch (error) {
      console.error('error', error)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleNextImportacion = useCallback(
    async (paso) => {
      let callback = null
      switch (paso) {
        case Pasos.SubirArchivo:
          callback = handleAsignarColumnas
          break
        case Pasos.AsignarColumnas:
          await sendCsvEquivalencias()
          callback = await handleAsignarCategorias()
          break
        case Pasos.AsignarCategorias:
          callback = await handleAsignarAtributos()
          _setRubroYValores(callback)
          await sendRubrosEquivalencias()
          break
        case Pasos.ResultadoFinal:
          callback = reset()
          break
        default:
          break
      }
      if (paso !== Pasos.ResultadoFinal) {
        handleNext(callback)
        setIsValid(false)
      }
    },
    [
      handleAsignarAtributos,
      handleAsignarCategorias,
      handleAsignarColumnas,
      handleNext,
      reset,
      setIsValid,
      sendCsvEquivalencias,
      sendRubrosEquivalencias
    ]
  )

  const handleError = useCallback(
    ({ error }: { error: string }) => {
      reset()
      SnackBarUtils.error(`Ocurrió un error al importar los datos${error ? ': ' + error : ''}`)
    },
    [setPaso]
  )

  const _setRubroYValores = useCallback(
    (atributos: Atributos[]) => {
      if (RubroYValores.length) return

      atributos.forEach((a) => {
        for (const key in attributes) {
          const { singular, plural } = attributes[key]
          const rubro = rubrosFlatted.find((r) => Object.keys(a)[0] === r.codRubro.toString()) as Rubro
          const attribute = rubro?.productoAtributoList.find(
            (a) =>
              a.nombreSingular.toLowerCase() === singular.toLowerCase() ||
              a.nombrePlural.toLowerCase() === plural.toLowerCase()
          )

          if (attribute) {
            const attrs = Object.values(a)[0][key]
            const valores = attribute.valoresList.filter((v) => attrs.includes(v.nombre))

            if (
              !RubroYValores.some((r) => {
                return (
                  r.rubro.codRubro === rubro.codRubro &&
                  JSON.stringify(r.atributosDefault.all) === JSON.stringify(attribute) &&
                  JSON.stringify(r.atributosParaAsignar) === JSON.stringify(attrs)
                )
              })
            ) {
              RubroYValores.push({
                rubro: {
                  codRubro: rubro.codRubro,
                  nombre: rubro.nombreAnalytics
                },
                atributosDefault: {
                  key,
                  all: attribute,
                  valores: valores
                },
                atributosParaAsignar: attrs
              })
            }
          }
        }
      })

      const r = RubroYValores.filter((r) => r.atributosParaAsignar.length > 0)
      setRubroYValores(r)
      return r
    },
    [RubroYValores, rubrosFlatted]
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px" width="100%">
      <Box
        width="100%"
        display="flex"
        justifyContent={paso === Pasos.ResultadoFinal ? 'flex-end' : 'space-between'}
        alignItems="center"
      >
        <Typography
          hidden={paso === Pasos.ResultadoFinal}
          fontSize={{ xs: 20, md: 24 }}
          fontWeight={'bold'}
          color="#1C1C1C"
        >
          Importar Catálogo
        </Typography>
        {closeButton && <CloseButton onClick={() => closeButton(paso, reset)} disabled={loading} />}
      </Box>

      {/* Stepper Desktop */}
      {!isMobile && paso !== Pasos.ResultadoFinal && (
        <CustomStepper
          length={Object.values(Pasos).length}
          values={Object.values(Pasos)}
          active={Object.values(Pasos).indexOf(paso as Pasos)}
        />
      )}

      {paso === Pasos.SubirArchivo && <SubirArchivo setCasoRepiteIDs={setCasoRepiteIDs} />}

      {paso === Pasos.AsignarColumnas && (
        <AsignarColumnas
          columnas={columnas}
          equivalencias={columnasEquivalencias}
          setEquivalencias={setColumnasEquivalencias}
          csvEquivalencias={csvEquivalencias}
          setCsvEquivalencias={setCsvEquivalencias}
        />
      )}

      {paso === Pasos.AsignarCategorias && (
        <AsignarCategorias
          categorias={categorias}
          equivalencias={categoriasEquivalencias}
          setEquivalencias={setCategoriasEquivalencias}
          rubrosEquivalencias={rubrosEquivalencias}
          setRubrosEquivalencias={setRubrosEquivalencias}
          handleNextImportacion={handleNextImportacion}
          paso={paso}
        />
      )}

      {paso === Pasos.AsignarAtributos && (
        <AsignarAtributos
          paso={pasoAtributos}
          atributos={atributos}
          equivalencias={atributosEquivalencias}
          setEquivalencias={setAtributosEquivalencias}
          handleNext={handleNextAttr}
          valoresList={valoresList}
          setValoresList={setValoresList}
          setValoresASincronizar={setValoresASincronizar}
          atributosValores={atributosValores}
        />
      )}

      {paso === Pasos.ResultadoFinal && <MensajeFinal resultado={resultado} dataToSend={dataToSend} />}

      {isMobile && paso !== Pasos.ResultadoFinal && (
        <CustomStepper
          length={Object.values(Pasos).length}
          values={Object.values(Pasos)}
          active={Object.values(Pasos).indexOf(paso as Pasos)}
        />
      )}

      <Box display="flex" justifyContent="space-between" width="100%" gap={'12px'}>
        {/* Botones de navegación IMPORTACION */}
        <Box
          display="flex"
          flexDirection={
            paso === Pasos.ResultadoFinal && resultado && resultado.lineasErroneas <= 0 ? 'row-reverse' : 'row'
          }
          gap={isMobile ? '10px' : '24px'}
          justifyContent="space-between"
          alignItems="center"
          width={paso !== Pasos.AsignarAtributos ? '100%' : 'auto'}
          hidden={paso === Pasos.AsignarAtributos && pasoAtributos !== PasosAtributos.Colores}
        >
          <ButtonRounded
            hidden={
              paso === Pasos.SubirArchivo ||
              (paso === Pasos.AsignarAtributos && pasoAtributos !== PasosAtributos.Colores)
            }
            disabled={loading}
            bgcolor={paso === Pasos.ResultadoFinal && resultado.lineasErroneas === 0 ? '#5983EF' : 'transparent'}
            textColor={paso === Pasos.ResultadoFinal && resultado.lineasErroneas === 0 ? 'white' : '#5983EF'}
            onClick={() => {
              if (paso === Pasos.ResultadoFinal) closeButton && closeButton(paso, reset)
              else handleBack()
            }}
            sx={{ minWidth: '160px' }}
            fullWidth={isMobile || (paso === Pasos.ResultadoFinal && resultado.lineasErroneas === 0)}
          >
            {paso === Pasos.ResultadoFinal ? 'Cerrar' : 'Volver'}
          </ButtonRounded>
          <ButtonRounded
            hidden={paso === Pasos.AsignarAtributos}
            bgcolor={paso === Pasos.ResultadoFinal && resultado.lineasErroneas === 0 ? 'transparent' : '#5983EF'}
            textColor={paso === Pasos.ResultadoFinal && resultado.lineasErroneas === 0 ? '#5983EF' : 'white'}
            disabled={!isValid || loading}
            onClick={() => handleNextImportacion(paso)}
            fullWidth={
              isMobile ||
              paso !== Pasos.ResultadoFinal ||
              (paso === Pasos.ResultadoFinal && resultado.lineasErroneas > 0)
            }
            sx={{ minWidth: '250px' }}
          >
            {paso === Pasos.ResultadoFinal ? 'Volver a importar' : loading ? 'Cargando...' : 'Continuar'}
          </ButtonRounded>
        </Box>
        {/* Botones de navegación ATRIBUTOS */}
        {paso === Pasos.AsignarAtributos && (
          <BotonesAtributos
            setPaso={setPasoAtributos}
            callback={importar}
            sincronizar={sincronizarValoresAtributos}
            loading={loading}
            paso={paso}
            pasoAtributos={pasoAtributos}
            atributos={atributos}
            loadingTextButton="Importando"
            textButton="Finalizar e Importar"
            RubroYValores={RubroYValores}
            handleBack={handleBack}
          />
        )}
      </Box>
    </Box>
  )
}

export default memo(ImportarCatalogo)
