import {
  AccountCircle,
  Category,
  Gavel,
  GradeSharp,
  Label,
  LocalOffer,
  LocationOn,
  Message,
  Settings,
  ShoppingCart,
  WhatsApp
} from '@mui/icons-material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import StoreIcon from '@mui/icons-material/Store'
import LabelIcon from '@mui/icons-material/Label'
import CategoryIcon from '@mui/icons-material/Category'
import HomeIcon from '@mui/icons-material/Home'
import FlagIcon from '@mui/icons-material/Flag'
import WebStoriesIcon from '@mui/icons-material/WebStories'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import ReviewsIcon from '@mui/icons-material/Reviews'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

export const RolType = Object.freeze({
  ADMIN: 'admin',
  MOD: 'operador',
  USER: 'vendedor'
})

interface SidebarItemsProps {
  title: string
  path: string
  icon: JSX.Element
  rol: any[]
}

export const SidebarItems: SidebarItemsProps[] = [
  // Admin // Mod
  {
    title: 'Inicio',
    path: '/home',
    icon: <HomeIcon />,
    rol: [RolType.USER]
  },
  {
    title: 'Locales',
    path: '/abm-locales',
    icon: <LocationOn />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Productos',
    path: '/admin-abm-products',
    icon: <Category />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Contratos',
    path: '/contracts',
    icon: <Gavel />,
    rol: [RolType.ADMIN]
  },
  // Local
  {
    title: 'Perfil del local',
    path: '/abm-local',
    icon: <AccountCircle />,
    rol: [RolType.USER]
  },
  {
    title: 'Publicaciones',
    path: '/abm-products',
    icon: <LocalOffer />,
    rol: [RolType.USER]
  },
  {
    title: 'Integraciones',
    path: '/integrations',
    icon: <ShoppingCart />,
    rol: [RolType.USER]
  },
  {
    title: 'Galerias',
    path: '/abm-galerias',
    icon: <StoreIcon />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Rubros',
    path: '/abm-rubros',
    icon: <CategoryIcon />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Pedidos',
    path: '/pedidos',
    icon: <ShoppingCartIcon />,
    rol: [RolType.ADMIN, RolType.MOD, RolType.USER]
  },
  {
    title: 'Stickers',
    path: '/stickers',
    icon: <LabelIcon />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Historias',
    path: '/historias',
    icon: <WebStoriesIcon />,
    rol: [RolType.ADMIN]
  },
  {
    title: 'Configuración',
    path: '/abm-config',
    icon: <Settings />,
    rol: [RolType.ADMIN]
  },
  {
    title: 'Suscripciones',
    path: '/suscripciones',
    icon: <GradeSharp />,
    rol: [RolType.USER]
  },
  {
    title: 'Reportes',
    path: '/reportes',
    icon: <FlagIcon />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Estadísticas',
    path: '/estadisticas',
    icon: <AutoGraphIcon />,
    rol: [RolType.USER]
  },
  {
    title: 'Calificaciones',
    path: '/calificaciones',
    icon: <ReviewsIcon />,
    rol: [RolType.USER]
  },
  {
    title: 'Notificaciones',
    path: '/notificaciones',
    icon: <NotificationsActiveIcon />,
    rol: [RolType.ADMIN, RolType.MOD]
  },
  {
    title: 'Whatsapp',
    path: '/admin-wsp-bot',
    icon: <WhatsApp />,
    rol: [RolType.ADMIN]
  },
  {
    title: 'Contactanos',
    path: '/contactanos',
    icon: <Message />,
    rol: [RolType.USER]
  }
]
