import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import Navbar from '../../components/Navbar'
import Footer from '../../pages/Home/components/Footer'
import * as FaIcons from 'react-icons/fa'
import { Autocomplete, Box, Button, FormControl, MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material'
import { getNotificaciones, getTiposNotificaciones, getTiposOrigenNotificaciones } from './notificaciones.services'
import { TenantsContext } from '../../context/tenants.context'
import { INotificaciones, TipoNotificacion } from './types/Notificaciones'
import { defaultPaginator, Paginator } from '../../pages/AdminProducts/context/productos.context'
import { Header } from '../../pages/Galerias'
import NotificacionesDesktop from './notificacionesDesktop'
import NotificacionesMobile from './notificacionesMobile'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CrearNotificacion from './widgets/crearNotificacion'
import Loading from '../Common/Loading'
import { useSortableData } from '../../utils/UseSorteableDataHook'
import dayjs from 'dayjs'

const ESTADOS = [
  { estado: 'Todos', key: 1 },
  { estado: 'Programados', key: 2 },
  { estado: 'Corriendo', key: 3 },
  { estado: 'Finalizados', key: 4 }
]

const AbmNotificaciones: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState<boolean>(false)
  const [notificaciones, setNotificaciones] = useState<INotificaciones[]>([])
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecord, setTotalRecord] = useState<number>(0)
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [paginatorNotificaciones] = useState<Paginator>(defaultPaginator)
  const [filtros, setFiltros] = useState<{ texto: string; tipo: string }>(null)
  const [listado, setListado] = useState<INotificaciones[]>([])
  const [notificacion, setNotificacion] = useState<INotificaciones | null>(null)
  const [tiposOrigenNotificacion, setTiposOrigenNotificacion] = useState<TipoNotificacion[]>([])
  const [tiposNotificacion, setTiposNotificacion] = useState<TipoNotificacion[]>([])
  const [estadoSeleccionado, setEstadoSeleccionado] = useState<{ estado: string; key: number }>(ESTADOS[0])
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { items, requestSort, sortConfig } = useSortableData(notificaciones || [])
  const { tenant } = useContext(TenantsContext)

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig['key'] === name ? sortConfig['direction'] : undefined
  }

  paginatorNotificaciones.handleChangePage = (e: any, newPage: number) => {
    setPage(newPage)
    setListado(items.slice(newPage * pageSize, newPage * pageSize + pageSize))
  }

  paginatorNotificaciones.handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageSize = parseInt(event.target.value, 10)
    setPageSize(newPageSize)
    setPage(0)
    setListado(items.slice(0, newPageSize))
  }

  const fetchNotificaciones = useCallback(async () => {
    setLoading(true)
    try {
      await getNotificaciones(tenant).then((data) => {
        setNotificaciones(data.data.data)
        setTotalRecord(data.data.totalRecord)
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [tenant])

  useEffect(() => {
    requestSort('fechaInicio', 'ascending')
    if (filtros) {
      handleFormSubmit(items)
    } else {
      setListado(items?.slice(0, 10))
    }
  }, [notificaciones])

  const updateData = () => {
    fetchNotificaciones()
  }

  useEffect(() => {
    const fetchTipos = async () => {
      setLoading(true)
      try {
        const tiposOrigenData = await getTiposOrigenNotificaciones(tenant)
        setTiposOrigenNotificacion(tiposOrigenData.data?.data)
        const tiposNotificacionesData = await getTiposNotificaciones(tenant)
        setTiposNotificacion(tiposNotificacionesData.data?.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchNotificaciones()
    fetchTipos()
  }, [tenant])

  const handleFormSubmit = (notificaciones: INotificaciones[]) => {
    if (!notificaciones) return

    const items = filtroEstado(estadoSeleccionado?.estado, notificaciones)
    if (filtros) {
      const { texto, tipo } = filtros
      const textoFiltro = texto?.toLowerCase()
      const tipoFiltro = tipo?.toLowerCase()

      const filtrado = items.filter(({ nombre, titulo, mensaje, notificacionOrigenTipo }) => {
        const coincideTexto =
          textoFiltro && [nombre, titulo, mensaje].some((campo) => campo?.toLowerCase().includes(textoFiltro))

        const coincideTipo = tipoFiltro && notificacionOrigenTipo.toLowerCase().includes(tipoFiltro)

        return textoFiltro && tipoFiltro ? coincideTexto && coincideTipo : coincideTexto || coincideTipo
      })

      setListado(filtrado)
      setTotalRecord(filtrado.length)
    }
  }

  const Corriendo = (fechaInicio: Date, fechaFin: Date, hoy: any) => {
    const finalizado = fechaFin && dayjs(fechaFin) < hoy
    const programado = fechaInicio && dayjs(fechaInicio) > hoy
    const req1 = !fechaInicio && !finalizado
    const req2 = !fechaFin && !programado
    const req3 = fechaFin && fechaInicio && dayjs(fechaFin) > hoy && dayjs(fechaInicio) <= hoy
    return req1 || req2 || req3
  }

  const filtroEstado = (estado: string, items: INotificaciones[]) => {
    const hoy = dayjs()
    let newListado: INotificaciones[] = []
    if (estado === 'Finalizados') {
      items.map((n: INotificaciones) => {
        if (n.fechaFin && dayjs(n.fechaFin) < hoy) newListado.push(n)
      })
    } else if (estado === 'Programados') {
      items.map((n: INotificaciones) => {
        if (n.fechaInicio && dayjs(n.fechaInicio) > hoy) newListado.push(n)
      })
    } else if (estado === 'Corriendo') {
      items.map((n: INotificaciones) => {
        if (Corriendo(n.fechaInicio, n.fechaFin, hoy)) newListado.push(n)
      })
    } else {
      newListado = [...items]
    }
    return newListado
  }

  const calcularEstado = (notificacion: INotificaciones) => {
    const hoy = new Date()
    const fechaInicio = notificacion.fechaInicio ? new Date(notificacion.fechaInicio) : null
    const fechaFin = notificacion.fechaFin ? new Date(notificacion.fechaFin) : null
    if (fechaFin && fechaFin < hoy) {
      return { nombre: 'Finalizado', icono: <CheckCircleOutlineIcon /> }
    } else {
      if (fechaInicio && fechaInicio > hoy) {
        return { nombre: 'Programada', icono: <AccessTimeIcon /> }
      } else {
        return { nombre: 'Corriendo', icono: <PlayCircleOutlineIcon /> }
      }
    }
  }

  if (!notificaciones || !listado) return <Loading />

  return (
    <>
      <Navbar title={'Notificaciones'} />
      <Header>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            width: '100%',
            padding: '10px',
            backgroundColor: '#f5f5f5',
            borderRadius: '5px',
            marginBottom: '10px',
            gap: '10px',
            marginTop: '10px',

            // display column en mobile
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              gap: '14px'
            }
          }}
        >
          <FormControl
            sx={{
              width: '40%',
              '@media (max-width: 768px)': { width: '100%' }
            }}
          >
            <TextField
              label="Nombre, título o mensaje"
              disabled={loading}
              onChange={(e) => setFiltros(e.target.value.trim() ? { ...filtros, texto: e.target.value.trim() } : null)}
              onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit(items)}
            />
          </FormControl>
          <FormControl
            sx={{
              width: '40%',
              '@media (max-width: 768px)': { width: '100%' }
            }}
          >
            <Autocomplete
              disabled={loading}
              options={tiposOrigenNotificacion}
              getOptionLabel={(tipo: any) => tipo.nombre || ''}
              onChange={(event, value) => setFiltros(value ? { ...filtros, tipo: value.nombre } : null)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Destinatario" />}
              renderOption={(props, tipo) => (
                <MenuItem {...props} key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </MenuItem>
              )}
              onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit(items)}
            />
          </FormControl>

          <FormControl
            sx={{
              width: '40%',
              '@media (max-width: 768px)': { width: '100%' }
            }}
          >
            <Autocomplete
              disabled={loading}
              options={ESTADOS}
              getOptionLabel={(estado: any) => estado.estado || ''}
              value={estadoSeleccionado}
              onChange={(event, value) => setEstadoSeleccionado(value)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Estado" />}
              renderOption={(props, estado) => (
                <MenuItem {...props} key={estado.key} value={estado.estado}>
                  {estado.estado}
                </MenuItem>
              )}
              onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit(items)}
            />
          </FormControl>
          <Button
            disabled={loading}
            variant="contained"
            size="large"
            onClick={() => updateData()}
            fullWidth={isMobile}
            sx={{
              '@media (max-width: 768px)': {
                display: 'flex',
                gap: '10px'
              }
            }}
          >
            <Box
              sx={{
                display: 'none',
                '@media (max-width: 768px)': {
                  display: 'block'
                }
              }}
            >
              Filtrar
            </Box>
            <Box
              sx={{
                padding: '6px',
                display: 'block',
                '@media (max-width: 768px)': {
                  display: 'flex'
                }
              }}
            >
              <FaIcons.FaSearch />
            </Box>
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="success"
            fullWidth={isMobile}
            sx={{ height: '90%' }}
            onClick={() => setOpenForm(true)}
          >
            Crear Nueva
          </Button>
        </Box>
      </Header>
      {loading ? (
        <Loading />
      ) : isMobile ? (
        <NotificacionesMobile
          listado={listado}
          paginator={paginatorNotificaciones}
          page={page}
          pageSize={pageSize}
          totalRecord={totalRecord}
          calcularEstado={calcularEstado}
          setNotificacion={setNotificacion}
          setIsEditing={setIsEditing}
          setOpenForm={setOpenForm}
        />
      ) : (
        <NotificacionesDesktop
          listado={listado}
          paginator={paginatorNotificaciones}
          page={page}
          pageSize={pageSize}
          totalRecord={totalRecord}
          calcularEstado={calcularEstado}
          setNotificacion={setNotificacion}
          setIsEditing={setIsEditing}
          setOpenForm={setOpenForm}
          getClassNamesFor={getClassNamesFor}
          requestSort={requestSort}
        />
      )}
      <Footer />

      {tiposNotificacion && tiposOrigenNotificacion && (
        <>
          <CrearNotificacion
            open={openForm}
            setOpen={setOpenForm}
            tiposDeNotificacion={tiposNotificacion}
            tiposOrigenNotificacion={tiposOrigenNotificacion}
            notificacion={notificacion}
            setNotificacion={setNotificacion}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            updateData={updateData}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(AbmNotificaciones))
