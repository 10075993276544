import { axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import { hash } from '../../../utils/hashFunction'
import { ITenant, postLocalType } from '../types'

export const getLocal = async (codLocal: string, tenant: ITenant) =>
  await axiosInstanceMain.get(`/${tenant.path}/locales/${codLocal}`).then((result) => result)
export const postLocal = async (data: postLocalType, tenant: ITenant) =>
  await axiosInstanceMain.post(`/${tenant.path}/locales`, data)
export const updateLocal = async (codLocal: string, data: postLocalType, tenant: ITenant) =>
  await axiosInstanceMain.put(`/${tenant.path}/locales/${codLocal}`, {
    ...data,
    codLocal
  })

export const uploadFoto = async (codLocal: string, imagenBase64: any, tenant: ITenant) => {
  const clave = await hash(codLocal + 'lugares2017')
  return await axiosInstanceMain.post(`/${tenant.path}/locales/${codLocal}/logo`, {
    imagenBase64,
    clave,
    codLocal
  })
}

export const notificarSobreEstafador = async (codLocal: string, tenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/compradores/notificar-local-estafador?codLocal=${codLocal}`)
