import { Button, ButtonProps } from '@mui/material'
import { StandardCSSProperties } from '@mui/system'
import { FC } from 'react'

interface Props extends ButtonProps {
  bgcolor?: StandardCSSProperties['backgroundColor']
  textColor?: StandardCSSProperties['color']
  textColorDisabled?: StandardCSSProperties['color']
  borderColor?: StandardCSSProperties['borderColor']
  borderSize?: string
}

const ButtonRounded: FC<Props> = ({
  children,
  bgcolor = 'transparent',
  borderSize = '2px',
  borderColor = '#5983EF',
  textColor = '#5983EF',
  textColorDisabled = 'white',
  sx,
  ...props
}) => {
  const border = `${borderSize} solid ${borderColor}`

  return (
    <Button
      sx={{
        borderRadius: '40px',
        color: textColor,
        bgcolor,
        border,
        p: '8px 40px',
        fontWeight: 600,
        outline: 'none',
        '&:hover': {
          bgcolor: bgcolor + 'd5',
          border,
          boxShadow: 'none'
        },
        '&:disabled': {
          bgcolor: bgcolor + '96',
          color: textColorDisabled,
          border: border + '2f'
        },
        ...sx
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonRounded
