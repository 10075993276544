import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Divider,
  Button,
  Box,
  styled
} from "@mui/material";
import TablaHistoriasPagination from "./HistoriasPagination";
import { useContext, Fragment, useState, useEffect } from "react";
import { HistoriasAdminContext } from "../../context/historias.context";
import { IHistorias } from "../../types/historias.types";
import { format } from "date-fns";
import Loading from "../../../../components/Common/Loading";
import SnackBarUtils from "../../../../utils/SnackBarUtils";

export const TableTitle = styled("h5")({
  margin: 0,
  fontWeight: "bold",
});

export const HEAD_ITEMS_HISTORIAS = ["Foto", "Local", "Id historia", "Estado", "Fecha de creación", "Acciones"];

const TablaHistorias = ({ copyShareLink }: { copyShareLink: (link: string) => void }) => {
  const {
    historias,
    cantLocales,
    searching,
    page,
    rowsPerPage,
    loading: loadingHistorias,
    EliminarHistoria,
  } = useContext(HistoriasAdminContext);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  useEffect(() => {
    if (historias.length > 0 && cantLocales > 0) {
      SnackBarUtils.info(
        `${historias.length} ${historias.length > 1 ? "historias" : "historia"} de ${cantLocales} ${
          cantLocales > 1 ? "locales" : "local"
        }`
      );
    }
  }, [historias, cantLocales]);

  if (loadingHistorias) return <Loading />;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {HEAD_ITEMS_HISTORIAS.map((item, i) => (
              <TableCell key={i}>
                <TableTitle>{item}</TableTitle>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {historias
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((historia: IHistorias, i: number) => (
              <Fragment key={i}>
                <TableRow>
                  <TableCell sx={{ margin: 0, padding: 0 }} colSpan={HEAD_ITEMS_HISTORIAS.length}>
                    <Divider sx={{ background: "#bbb" }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ cursor: "pointer" }}>
                    <img
                      src={historia.imageUrl}
                      alt={historia.imageUrl}
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>{`${historia.nombreLocal} (${historia.codLocal})`}</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>{historia.idHistoria}</TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {historia.publicadaAhora ? "Publicada ahora" : "No publicada"}
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {historia.fechaDesde && formatDate(historia.fechaDesde)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        EliminarHistoria(historia.idHistoria);
                      }}
                    >
                      Eliminar
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        copyShareLink(historia.shareLink);
                      }}
                    >
                      Copiar link para compartir
                    </Button>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablaHistoriasPagination component={"td"} />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TablaHistorias;
