import React, { memo } from 'react'

// components
import { Box, Button, Divider, useTheme, useMediaQuery, Grid } from '@mui/material'

// redux
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import Navbar from '../../components/Navbar'
import Footer from '../Home/components/Footer'
import Calificaciones from '../../components/AdminLocal/AdminLocalCalificaciones'
import ModalCalificaciones from '../../components/AdminLocal/ModalCalificaciones'
import { useCalificaciones } from './calificaciones.hook'
import { useAppStore } from '../../store/app-store'

const CalificacionesVendedor: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codLocal = useAppStore((store) => store.codLocal)
  const { calificacionesList, calificacionXatributoList } = useCalificaciones(codLocal)

  return (
    <>
      <Navbar title={`Mis Calificaciones`} />
      <Grid container direction={isMobile ? 'column' : 'row'} id="calificaciones-grid">
        <Grid item xs={12} md={6}>
          <Calificaciones codLocal={codLocal} isAdmin={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ModalCalificaciones
            codLocal={codLocal}
            options={calificacionXatributoList}
            data={calificacionesList}
            isAdmin={false}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(CalificacionesVendedor))
