import React, { useContext, useEffect, useState } from 'react'
import '../styles/sortableData.scss'
import GaleriaDTO from '../dto/GaleriaDTO'
import { useSortableData } from '../utils/UseSorteableDataHook'
import { useNavigate } from 'react-router-dom'
import { axiosInstanceMain } from '../utils/axiosGlobalUtils'
import * as FaIcons from 'react-icons/fa'
import { MaximizeIcon } from '../icons/maximize-icon/maximize-icon.component'
import Select from 'react-select'
import { useLocales, IFiltros } from '../hooks/locales.hook'
import Navbar from '../components/Navbar'
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material'
import Footer from './Home/components/Footer'
import { TenantsContext } from '../context/tenants.context'
import { styled } from '@mui/material/styles'
import { getGalerias } from './Galerias/services/galerias.services'
import { LocalAdminDTO } from '../dto/LocalDTO'
import { height } from '@mui/system'
import { Button } from '../components/Common/utils/button/button.component'

const ContentInputSearch = styled('div')({
  display: 'flex'
})

const FirstRow = styled('div')({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  margin: '10px',
  gap: '10px'
})

const QueryTexto = styled('div')({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  width: '25%',
  '@media (max-width: 425px)': {
    width: '100%'
  }
})

const ContentSelect = styled('div')({
  width: '30%',
  fontSize: '1rem',
  lineHeight: '1.6',
  borderRadius: '0.3rem',
  '@media (max-width: 425px)': {
    width: '100%'
  }
})

const ContentButton = styled('div')({})

const ContentSoloActivos = styled('div')({
  width: '10%',
  marginTop: '10px',
  '@media (max-width: 425px)': {
    width: '80%'
  }
})

const ButtonCustom = styled(Button)({
  width: '95%',
  fontSize: '0.75rem'
})

const InputCustom = styled('input')({
  width: '100%',
  padding: '0.5rem 0.5rem',
  fontSize: '1rem',
  border: '1px solid #999'
})

const InputIdCustom = styled('input')({
  height: '50%',
  width: '17%',
  fontSize: '1rem',
  padding: '0.5rem 0.5rem',
  border: '1px solid #999',
  '@media (max-width: 425px)': {
    width: '100%',
    height: 'auto'
  }
})

const CheckBox = styled('div')({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: 'grey'
})

// Mobile
const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  width: '97%',
  border: '1px solid',
  margin: '5px',
  padding: '5px',
  borderRadius: '5px'
})

const OneColumn = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  width: '100%',
  gap: '4px'
})

const Title = styled('p')({
  fontWeight: 'bold',
  marginBottom: '0.25rem',
  fontSize: '4vw',
  color: `${(props: any) => (props.color ? '#' + props.color : '#212529')}`
})

const TwoColumn = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  width: '100%',
  gap: '2px',
  marginBottom: '5px'
})

const AbmLocalesPage: React.FC = () => {
  const { locales, updateLocales, filtros, updateFiltros } = useLocales()
  const [galerias, setGalerias] = useState<GaleriaDTO[]>()
  const [queryLocal, setQueryLocal] = useState<any>()
  const [queryFiltrosLocal, setQueryFiltrosLocal] = useState<string>('')
  const [queryPalabraCompleta, setQueryPalabraCompleta] = useState<boolean>(false)
  const [queryCodesGaleria, setQueryCodesGaleria] = useState<string[]>()
  const [galeriasSeleccionadas, setGaleriasSeleccionadas] = useState<GaleriaDTO[]>()
  const { items, requestSort, sortConfig } = useSortableData(locales ? locales : [])
  const { tenant } = useContext(TenantsContext)

  const navigate = useNavigate()
  const [soloActivos, setSoloActivos] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const customStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      borderWidth: '1px',
      borderColor: '#999',
      borderStyle: 'inset',
      '&:hover': {},
      '&:focus': {
        borderWidth: '1px',
        borderColor: '#999',
        borderStyle: 'inset'
      }
    })
  }

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig['key'] === name ? sortConfig['direction'] : undefined
  }

  const searchPremises = () => {
    let filtros = `/admin/${tenant.path}/locales?`
    if (queryLocal) {
      const q = isNaN(queryLocal) ? 'codLocal' : 'idLocal'
      filtros = filtros + `${q}=${queryLocal}&`
    }
    if (queryCodesGaleria) filtros = filtros + `codGaleria=${queryCodesGaleria}&`
    const cleanedQueryFiltrosLocal = queryFiltrosLocal ? queryFiltrosLocal.replace(/^549/g, '').trim() : null
    if (cleanedQueryFiltrosLocal)
      filtros =
        filtros + `q=${encodeURIComponent(cleanedQueryFiltrosLocal)}&solo-palabra-completa=${queryPalabraCompleta}`
    if (soloActivos) filtros = filtros + '&solo-activos=true'
    setLoading(true)
    axiosInstanceMain
      .get(filtros)
      .then((result) => {
        updateLocales(result.data.data)
        const filtros: IFiltros = {
          codLocal: queryLocal && isNaN(queryLocal) && queryLocal,
          idLocal: queryLocal && !isNaN(queryLocal) && queryLocal,
          queryTexto: queryFiltrosLocal && queryFiltrosLocal,
          palabraCompleta: queryPalabraCompleta && queryPalabraCompleta,
          codesGalerias: queryCodesGaleria && queryCodesGaleria,
          soloActivos: soloActivos && soloActivos
        }
        updateFiltros(filtros)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  const getListadoGalerias = () => {
    setLoading(true)
    getGalerias(tenant)
      .then((result) => {
        const galeriasResult: GaleriaDTO[] = []
        if (result.data.data) {
          result.data.data.map((g: GaleriaDTO) => {
            const galeriaResult: GaleriaDTO = {
              value: g.codGaleria,
              label: `${g.nombre} (${g.direccion})`
            }
            galeriasResult.push(galeriaResult)
          })
        }
        setGalerias(galeriasResult)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  const handleClickInLine = (codLocal: string) => {
    if (isMobile) redirectToLocalDetail(codLocal)
  }

  const redirectToLocalDetail = (codLocal: string) => {
    navigate('/admin-local/' + codLocal)
  }

  useEffect(() => {
    getListadoGalerias()
  }, [])

  useEffect(() => {
    if (filtros && galerias) {
      if (filtros.idLocal) setQueryLocal(filtros.idLocal)
      if (filtros.codLocal) setQueryLocal(filtros.codLocal)
      if (filtros.queryTexto) setQueryFiltrosLocal(filtros.queryTexto)
      if (filtros.palabraCompleta) setQueryPalabraCompleta(filtros.palabraCompleta)
      if (filtros.codesGalerias) {
        setQueryCodesGaleria(filtros.codesGalerias)
        const newGaleriasFormatter: GaleriaDTO[] = []
        filtros.codesGalerias.map((i) => {
          const gal = galerias.find((g) => {
            return g.value === i
          })

          if (gal) newGaleriasFormatter.push(gal)
        })

        setGaleriasSeleccionadas(newGaleriasFormatter)
      }
      if (filtros.soloActivos) setSoloActivos(filtros.soloActivos)
    }
  }, [filtros, galerias])

  // console.log(galerias?.find(g => queryCodesGaleria?.includes(g.value.toString())));

  useEffect(() => {
    if (queryCodesGaleria)
      setGaleriasSeleccionadas(galerias?.filter((g: GaleriaDTO) => queryCodesGaleria.map((q) => q)?.includes(g.value)))
  }, [queryCodesGaleria])

  // console.log(galerias?.find(g));
  return (
    <>
      <div className="principal">
        <Navbar title="ABM de Locales" searchBar={{ searchBar: true }} />
        <ContentInputSearch>
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <div className="input-group">
              <FirstRow>
                <InputIdCustom
                  type="search"
                  onChange={(e) => setQueryLocal(e.target.value)}
                  aria-label="Busca por ID o código del local"
                  placeholder="Busca por ID o código del local"
                  className="form-outline form-control-lg"
                  value={queryLocal}
                />
                <QueryTexto>
                  <InputCustom
                    type="search"
                    onChange={(e) =>
                      // !e.target.value.startsWith("&") &&
                      setQueryFiltrosLocal(e.target.value.toLowerCase())
                    }
                    placeholder="Busca por nombre, dirección, wsp y usuario"
                    className="form-outline form-control-lg"
                    value={queryFiltrosLocal}
                  />
                  <CheckBox>
                    Buscar por palabra completa
                    <input
                      type="checkbox"
                      value="Buscar por palabra completa"
                      onChange={(e) => setQueryPalabraCompleta(e.target.checked)}
                      checked={queryPalabraCompleta}
                    />
                  </CheckBox>
                </QueryTexto>
                <ContentSelect>
                  <Select
                    styles={customStyles}
                    options={galerias as GaleriaDTO[]}
                    value={galeriasSeleccionadas}
                    isMulti
                    placeholder="Busca por galerias"
                    onChange={(e) => {
                      const galeriasSelected: string[] = []
                      e.map((f): void => {
                        if (f) galeriasSelected.push(f.value)
                      })
                      setQueryCodesGaleria(galeriasSelected)
                      setGaleriasSeleccionadas(e as GaleriaDTO[])
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </ContentSelect>
                <ContentSoloActivos>
                  <CheckBox>
                    <input
                      type="checkbox"
                      value="Solo activos"
                      onChange={(e) => setSoloActivos(e.target.checked)}
                      checked={soloActivos}
                    />
                    Solo activos
                  </CheckBox>
                </ContentSoloActivos>
                <ContentButton>
                  <button type="submit" onClick={searchPremises} className="btn btn-primary btn-lg">
                    <FaIcons.FaSearch />
                  </button>
                </ContentButton>
              </FirstRow>
            </div>
          </form>
        </ContentInputSearch>

        {loading && <CircularProgress />}

        {isMobile ? (
          <>
            {items?.map((item: LocalAdminDTO, index) => {
              const whpp: any = item.medioDeContactoList?.find((x: any) => x.tipo === 1)
              return (
                <div key={index}>
                  <Container
                    key={index}
                    className={
                      item.statusString === 'Aprobado'
                        ? 'approved'
                        : item.statusString === 'Rechazado'
                        ? 'rejected'
                        : item.statusString.toLowerCase().includes('borrado') ||
                          item.statusString.toLowerCase().includes('error')
                        ? 'rejected'
                        : 'not-approved'
                    }
                  >
                    <OneColumn>
                      <Title>
                        {`${item.idLocal}`}
                        {item.codLocal && ` | ${item.codLocal}`}
                      </Title>
                      <Title>
                        {' | Nombre: '} {item.nombre}
                      </Title>
                    </OneColumn>
                    <OneColumn>
                      <Title>{item.direccion}</Title>
                    </OneColumn>
                    <TwoColumn>
                      <Title>
                        {'Activos: '}
                        {item.cantPublicacionesActivas}
                        {' | Cuenta: '}
                        {item.flags ? item.flags : '-'}
                      </Title>
                    </TwoColumn>
                    <TwoColumn>
                      <ButtonCustom variant="secondary" size="small">
                        <a href={`https://wa.me/${whpp?.prefijo}${whpp?.valor}`}>
                          {whpp?.prefijo} {whpp?.valor}
                        </a>
                      </ButtonCustom>
                      <ButtonCustom
                        variant="secondary"
                        size="small"
                        onClick={() => redirectToLocalDetail(item.codLocal)}
                      >
                        <MaximizeIcon />
                      </ButtonCustom>
                    </TwoColumn>
                  </Container>
                </div>
              )
            })}
          </>
        ) : (
          <div className="table-container">
            <table className="table table-bordered">
              <thead className="table-active">
                <tr>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('idLocal')}
                      className={getClassNamesFor('idLocal')}
                    >
                      ID
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('codLocal')}
                      className={getClassNamesFor('codLocal')}
                    >
                      Código
                    </button>
                  </th>
                  <th>
                    <button type="button" onClick={() => requestSort('nombre')} className={getClassNamesFor('nombre')}>
                      Nombre
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('direccion')}
                      className={getClassNamesFor('direccion')}
                    >
                      Dirección
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort('cantPublicacionesActivas')}
                      className={getClassNamesFor('cantPublicacionesActivas')}
                    >
                      Activos
                    </button>
                  </th>
                  <th>
                    <button type="button">Whatsapp</button>
                  </th>
                  <th>
                    <button type="button" onClick={() => requestSort('flags')} className={getClassNamesFor('flags')}>
                      Cuenta
                    </button>
                  </th>
                  {!isMobile && <th style={{ width: '15vmin' }}>Administrar</th>}
                </tr>
              </thead>
              <tbody>
                {items?.map((item: LocalAdminDTO, index) => {
                  const whpp: any = item.medioDeContactoList?.find((x: any) => x.tipo === 1)
                  return (
                    <tr
                      key={index}
                      className={
                        item.statusString === 'Aprobado'
                          ? 'approved'
                          : item.statusString === 'Rechazado'
                          ? 'rejected'
                          : item.statusString.toLowerCase().includes('borrado') ||
                            item.statusString.toLowerCase().includes('error')
                          ? 'rejected'
                          : 'not-approved'
                      }
                    >
                      <th onClick={() => handleClickInLine(item.codLocal)}>{item.idLocal}</th>
                      <th onClick={() => handleClickInLine(item.codLocal)}>{item.codLocal}</th>
                      <th onClick={() => handleClickInLine(item.codLocal)}>{item.nombre}</th>
                      <th onClick={() => handleClickInLine(item.codLocal)}>{item.direccion}</th>
                      <th onClick={() => handleClickInLine(item.codLocal)}>{item.cantPublicacionesActivas}</th>
                      <th className="columWithButton">
                        {whpp && (
                          <ButtonCustom variant="secondary" size="small">
                            <a href={`https://wa.me/${whpp?.prefijo}${whpp?.valor}`}>
                              {whpp?.prefijo} {whpp?.valor}
                            </a>
                          </ButtonCustom>
                        )}
                      </th>
                      <th onClick={() => handleClickInLine(item.codLocal)}>{item.flags}</th>
                      {!isMobile && (
                        <th className="columWithButton">
                          <ButtonCustom
                            variant="secondary"
                            size="xsmall"
                            onClick={() => redirectToLocalDetail(item.codLocal)}
                          >
                            <MaximizeIcon />
                          </ButtonCustom>
                        </th>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}

export default AbmLocalesPage
