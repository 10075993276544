import React, { useState, memo } from 'react'
import Navbar from '../../components/Navbar'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'

import { Button, Container, Box, TextField, useMediaQuery, useTheme, styled } from '@mui/material'
import MultiSelectTreeView from './components/treeView/treeView'

import useFilters from './hooks/filters'
import CreaRubro from './widgets/CreaRubro'
import Footer from '../Home/components/Footer'
import useRubros from '../Products/hooks/rubros.hook'

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

const AbmRubrosPage = () => {
  const { rubros, updateRubros } = useRubros()
  const { setSearchFilter } = useFilters(rubros)

  const [tmpSearchFilter, setTmpSearchFilter] = useState('')
  const [modalNewRubro, setModalNewRubro] = useState<boolean>(false)
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const executeSearch = () => {
    setSearchFilter(tmpSearchFilter)
  }

  return (
    <>
      <Navbar title="ABM de Rubros" />
      <Container>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            style={{
              marginTop: '2vh',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              flexDirection: isMobile ? 'column' : 'row'
            }}
            sx={{ '@media (max-width:600px)': { flexDirection: 'column' } }}
          >
            <TextField
              id="outlined-basic"
              style={{ flexGrow: '2' }}
              label="Buscar por nombre o descripción"
              onChange={(e: any) => setTmpSearchFilter(e.target.value)}
              onKeyPress={(e: any) => e.key === 'Enter' && executeSearch()}
              variant="outlined"
              fullWidth={isMobile}
            />

            <Button variant="contained" onClick={executeSearch}>
              Buscar
            </Button>
            <Button
              variant="contained"
              fullWidth={isMobile}
              color="success"
              onClick={() => {
                setModalNewRubro(true)
              }}
            >
              Agregar nuevo rubro
            </Button>
          </Box>
        </Box>
        <div className="p-4">
          <MultiSelectTreeView
            rubros={rubros}
            searchedRubro={tmpSearchFilter}
            updateRubros={updateRubros}
            expandedNodes={expandedNodes}
            setExpandedNodes={setExpandedNodes}
          />
        </div>
        <CreaRubro open={modalNewRubro} setOpen={setModalNewRubro} updateRubros={updateRubros} />
      </Container>
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(AbmRubrosPage))
