import { styled } from "@mui/material";
import React, { ReactElement } from "react";

const ButtonCustom = styled("button")({
  boxSizing: "border-box",
  borderRadius: "999px",
  width: "100%",
  height: "5rem",
  border: "none",
  cursor: "pointer",
  fontSize: "2rem",
  "&.large": {
    fontSize: "2rem", // 32px
    height: "5rem", // 80px
  },
  "&.small": {
    fontSize: "1rem",
    height: "3rem", // 48px
  },
  "&.xsmall": {
    fontSize: "0.5rem",
    height: "2rem", // 32px
  },
});

const ButtonContent = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: "0.625rem", // 10px
  color: "inherit",
});

type ButtonParams = {
  children: ReactElement | ReactElement[] | string;
  onClick?: () => void;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "large" | "small" | "xsmall";
  type?: "button" | "submit";
  className?: string;
};

export const Button = ({ children, onClick, disabled, variant, size, type, className }: ButtonParams): ReactElement => {
  return (
    <ButtonCustom
      onClick={onClick}
      disabled={disabled}
      type={type ? type : "button"}
      className={`
      ${variant ? variant : "primary"}
      ${size ? size : "large"}
      ${className}
      `}
    >
      <ButtonContent>{children}</ButtonContent>
    </ButtonCustom>
  );
};
