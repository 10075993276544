import { TableCell, TableRow, Checkbox, Box, IconButton, Typography } from '@mui/material'
import ButtonDesplegable from '../../../../components/Common/ButtonDesplegable'
import DesplegableRubrosProducto from '../DesplegableRubrosProducto'
import { ProductoWProps } from '../Producto'
import ProductoActions from '../ProductoActions'
import { ProductosAdminContext } from '../../context/productos.context'
import { useContext, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { IProducto } from '../../types/producto'
import CloseIcon from '@mui/icons-material/Close'

const TableProducto = ({
  producto,
  rubros,
  cambiarRubro,
  changingEstado,
  isDefinitivelyDeleting,
  isDefinitivelyExpiring,
  isSelected,
  setIsSelected,
  loadingRubros,
  eliminarRevision
}: ProductoWProps) => {
  const { productosSeleccionados, setProductosSeleccionados } = useContext(ProductosAdminContext)

  const estaSeleccionado = useMemo(() => {
    return productosSeleccionados.some((p: IProducto) => p.codProducto === producto.codProducto)
  }, [productosSeleccionados, producto])

  const handleCheckboxChange = () => {
    if (estaSeleccionado) {
      setProductosSeleccionados(productosSeleccionados.filter((p: IProducto) => p.codProducto !== producto.codProducto))
    } else {
      setProductosSeleccionados([...productosSeleccionados, producto])
    }
  }
  return (
    <>
      <TableRow
        style={{
          backgroundColor:
            producto.aprobado && !producto.pausado && !producto.vencido
              ? '#b3faa8'
              : producto.rechazado
              ? '#ffaeba'
              : producto.pausado || producto.vencido
              ? '#ddd'
              : '#fff'
        }}
      >
        <TableCell align="center">
          <Checkbox
            checked={estaSeleccionado}
            onChange={handleCheckboxChange}
            sx={{
              p: 0,
              m: 0,
              width: 'fit-content',
              alignSelf: 'center'
            }}
          />
        </TableCell>
        <TableCell
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(producto.sharelink, '_blank')
          }}
        >
          <img
            src={producto.urlFoto}
            // src={"https://http2.mlstatic.com/D_NQ_NP_601375-MLA51645718227_092022-W.jpg"}
            alt={producto.nombre}
            loading="lazy"
            style={{
              width: '100px',
              height: '100px'
            }}
          />
        </TableCell>
        <TableCell sx={{ fontSize: '15px' }}>
          {producto.nombreLocal} - {`(${producto.codLocal})`}
        </TableCell>
        <TableCell sx={{ fontSize: '15px' }}>
          {producto.nombre} - {`(${producto.codProducto})`}
        </TableCell>
        <TableCell width={'350px'}>
          <DesplegableRubrosProducto
            loading={loadingRubros}
            onChange={cambiarRubro}
            nombreRubro={producto.nombreRubro}
            rubros={rubros}
            codActual={producto.codRubro}
            setIsSelected={setIsSelected}
            isSelected={isSelected}
          />
        </TableCell>
        <TableCell sx={{ fontSize: '15px' }}>
          {producto.precioFormateado.split('<br>').map(
            (linea, i) =>
              linea.length > 0 && (
                <span key={i}>
                  {linea}
                  <br />
                </span>
              )
          )}
        </TableCell>
        <TableCell sx={{ fontSize: '15px', whiteSpace: 'break-spaces' }}>
          {`Pub: ${format(new Date(producto.fechaPublicacion), 'dd/MM/yyyy HH:mm')}\nVto: ${format(
            new Date(producto.fechaVencimiento),
            'dd/MM/yyyy'
          )}`}
        </TableCell>
        <TableCell sx={{ fontSize: '15px' }}>{producto.flags}</TableCell>
        <TableCell
          sx={{
            ...(producto.adminRevisar && {
              display: 'flex',
              verticalAlign: 'center',
              flexDirection: 'column',
              gap: '10px'
            })
          }}
        >
          {producto.adminRevisar && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid red',
                color: 'red',
                borderRadius: '4px'
              }}
            >
              <Typography style={{ paddingLeft: '14px', fontSize: '14px' }}>REVISAR</Typography>
              <IconButton size="small" color="error" onClick={eliminarRevision}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <ButtonDesplegable label="Acciones" className="d-flex flex-column">
            <ProductoActions
              changingEstado={changingEstado}
              isDefinitivelyDeleting={isDefinitivelyDeleting}
              isDefinitivelyExpiring={isDefinitivelyExpiring}
              producto={producto}
            />
          </ButtonDesplegable>
        </TableCell>
      </TableRow>
    </>
  )
}

export default TableProducto
