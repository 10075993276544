import { Box, Typography } from '@mui/material'

const SectionTitle = ({title}: {title: string}) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '50px',
      background: '#88bae9',
      my: 3
    }}>
      <Typography variant="h5">
        {title}
      </Typography>
    </Box>
  )
}

export default SectionTitle
