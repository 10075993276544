import { useContext, useEffect, useState } from "react";
import { getMetricas } from "../../Products/services/locals.services";
import { IMetricas } from "../../AdminLocal/types";
import { TenantsContext } from "../../../context/tenants.context";

export const useMetricas = (codLocal: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [metricas, setMetricas] = useState<IMetricas>();
  const [detallado, setDetallado] = useState<boolean>();
  const { tenant } = useContext(TenantsContext);

  const fetchData = async (codLocal: string) => {
    try {
      setLoading(true);
      await getMetricas(codLocal, tenant, detallado).then((res) => setMetricas(res.data));
    } catch {
      console.log();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(codLocal);
  }, [codLocal, detallado]);

  return { metricas, setDetallado, fetchData, loading };
};
