// Basic Theme (Black)
// export const themePalette = {
//   bgcolor: '#000',
//   color: '#fff',
//   defaultHover: '#3a3a3a',
//   alternativeHover: '#4b4b4b',
//   logout: '#ff6c76',
// }

// Basic Theme (Red)
// export const themePalette = {
//   bgcolor: '#160000',
//   color: '#fff',
//   defaultHover: '#3a3a3a',
//   alternativeHover: '#4b4b4b',
//   logout: '#ff6c76',
// }

//Basic Theme (Blue)
// export const themePalette = {
//   bgcolor: '#040020',
//   color: '#fff',
//   defaultHover: '#3a3a3a',
//   alternativeHover: '#4b4b4b',
//   logout: '#ff6c76',
// }

// Green Theme
// export const themePalette = {
//   bgcolor: '#002519',
//   color: '#fff',
//   defaultHover: '#285244',
//   alternativeHover: '#356354',
//   logout: '#ff6c76',
// }

// White Theme
export const themePalette = {
  bgcolor: '#3b3c3c',
  bgcolorsidebar: '#f3f3f3',
  color: '#f7f7f7',
  colorsidebar: '#505050',
  defaultHover: '#303131',
  defaultHoverSidebar: '#ededed',
  alternativeHover: '#dddddd',
  logout: '#ff525e',
}

// Yellow Theme
// export const themePalette = {
//   bgcolor: '#2a1f00',
//   color: '#fff',
//   defaultHover: '#4e3f00',
//   alternativeHover: '#5f4b00',
//   logout: '#ff6c76',
// }

// Blue Theme
// export const themePalette = {
//   bgcolor: '#070229',
//   color: '#fff',
//   defaultHover: '#352f5a',
//   alternativeHover: '#3c3664',
//   logout: '#ff6c76',
// }

// Purple Theme
// export const themePalette = {
//   bgcolor: '#0d001a',
//   color: '#fff',
//   defaultHover: '#38214e',
//   alternativeHover: '#47335a',
//   logout: '#ff6c76',
// }
