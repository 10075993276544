import { memo, useContext, useEffect, useState } from 'react'

// Material UI
import {
  InputLabel,
  Select,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  Tooltip,
  Typography,
  Box,
  InputAdornment
} from '@mui/material'
import { Close, Info } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

// component for d&d images
import Dropzone from '../components/Dropzone'
import DisableRetailPrice from './DisableRetailPrice'

// form validation
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Image, Product } from '../types/product'
import { skuEnUso, createProduct } from '../services/products.services'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { findRubro, findUnidadDeMedida } from '../../AdminProducts/services/rubros.service'
import Loading from '../../../components/Common/Loading'
import { useLocal } from '../../AbmLocalPage/hooks/local.hook'
import { OfrecerPremiumContext } from '../../../context/OfrecerPremium/ofrecerPremium.context'
import InfoProductosAgotados from '../components/PopupSubmit'
import { TenantsContext } from '../../../context/tenants.context'
import { IPalabrasProhibidas } from '../hooks/palabras.prohibidas.hook'
import { NewProductProps } from './EditProduct'
import { ProductosVendedorContext } from '../context/productos.vendedor.context'
import { Rubro } from '../../Integraciones/types/responses-productos'

export interface ErrorPP {
  mensaje: string
  error: boolean
  warning: boolean
}

export const defaultErrorTitulo = {
  mensaje: '',
  error: false,
  warning: false
}

const MAX_NOMBRE_LENGTH = 85
const MAX_DESC_LENGTH = 1000
const MAX_SKU_LENGTH = 45
const MAX_MIN_WHOLESALE_LENGTH = 75

const EditProduct = (props: NewProductProps) => {
  const {
    getProducts,
    open,
    setOpen,
    retailer,
    rubros,
    maxPhotos,
    codLocal,
    esMinorista,
    cantOfertasDisp,
    cantProducts,
    usaSku,
    precioDto,
    ofrecerPremium,
    palabrasProhibidas
  } = props

  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // temporal data
  const [rubro, setRubro] = useState<string>('')
  const [images, setImages] = useState<any[]>([])
  const [canRetailSell, setCanRetailSell] = useState(true)
  const [disableRetailPrice, setDisableRetailPrice] = useState(false)
  const [unitsPerRubro, setUnitsPerRubro] = useState<any>([])
  const [unidadSeleccionada, setUnidadSeleccionada] = useState<any>('')
  const [monedasPorRubro, setMonedasPorRubro] = useState<any>([])
  const [monedaSeleccionada, setMonedaSeleccionada] = useState<any>()
  const [minWholeCount, setMinWholeCount] = useState('')
  const [talles, setTalles] = useState<string>('')
  const [nombreProducto, setNombreProducto] = useState('')
  const [codigoSKU, setCodigoSKU] = useState<string>('')
  const [descripcion, setDescripcion] = useState('')
  const [showTooltipPM, setShowTooltipPM] = useState(false)
  const [showTooltipPA, setShowTooltipPA] = useState(false)
  const [unidadDeMedida, setUnidadDeMedida] = useState<any>('')
  const [precioMinimo, setPrecioMinimo] = useState<number>(0)
  const { onOpenPre } = useContext(OfrecerPremiumContext)
  const { local } = useLocal(codLocal)
  const [openPopupInfo, setOpenPopupInfo] = useState<boolean>(false)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [tituloAgotado, setTituloAgotado] = useState<boolean>(false)
  const [codigoSkuEnUso, setCodigoSkuEnUso] = useState<boolean>(false)
  const [tituloProhibido, setTituloProhibido] = useState<ErrorPP>(defaultErrorTitulo)
  const { estado } = useContext(ProductosVendedorContext)
  const { tenant } = useContext(TenantsContext)

  const schema: yup.AnyObjectSchema = yup.object({
    productName: yup
      .string()
      .test('isNotProhibited', tituloProhibido.mensaje, function () {
        return !tituloProhibido.error
      })
      .required('El título es obligatorio'),
    skuCode: yup.string().max(MAX_SKU_LENGTH, `El código SKU no puede tener más de ${MAX_SKU_LENGTH} caracteres`),
    price: yup
      .number()
      .typeError('el monto debe ser un número')
      .transform((_, value) => {
        if (value === '') return null
        if (typeof value === 'string') {
          // Elimina los puntos solo si son separadores de miles
          value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
          value = parseFloat(value)
        }
        return +value
      })
      .positive('El precio debe ser positivo y mayor a cero')
      .required('El precio es un atributo necesario')
      .min(precioMinimo, 'El precio debe ser el verdadero o no se aprobará la publicación'),
    previusPrice:
      precioDto &&
      yup
        .number()
        .nullable()
        .typeError('el monto debe ser un número')
        .transform((_, value) => {
          if (value === '') return null
          if (typeof value === 'string') {
            value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
            value = parseFloat(value)
          }
          return +value
        })
        .min(0, 'El precio debe ser positivo o igual a cero'),
    description: yup.string(),
    minWholesale: yup
      .string()
      .required('Debes indicar la cantidad minima por mayor')
      .max(MAX_MIN_WHOLESALE_LENGTH, `Este campo no puede tener más de ${MAX_MIN_WHOLESALE_LENGTH} caracteres`),
    retailPrice:
      retailer &&
      canRetailSell &&
      yup
        .number()
        .nullable()
        .typeError('el monto debe ser un número')
        .nullable()
        .transform((_, value) => {
          if (value === '') return null
          if (typeof value === 'string') {
            value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
            value = parseFloat(value)
          }
          return +value
        })
        .min(0, 'El precio debe ser positivo o igual a cero'),
    waist: yup
      .string()
      .max(MAX_MIN_WHOLESALE_LENGTH, `Este campo no puede tener más de ${MAX_MIN_WHOLESALE_LENGTH} caracteres`)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const handlePalabrasProhibidas = (input: string) => {
    const titulo = input
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    palabrasProhibidas.map((group: IPalabrasProhibidas) => {
      const accion = group.accion
      const msj = group.mensaje
      group.palabraList.map((p: string) => {
        const palabra = p
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
        if (titulo.includes(` ${palabra} `) || titulo.startsWith(`${palabra} `) || titulo.endsWith(` ${palabra}`)) {
          const error: ErrorPP = {
            mensaje: msj.replace('{palabra}', p),
            error: accion === 'bloquear',
            warning: accion === 'advertir'
          }
          setTituloProhibido(error)
        }
      })
    })
  }

  const handleTitulo = (titulo: string) => {
    const agotado = titulo.toLowerCase().includes('agotad')
    setTituloAgotado(agotado)
  }

  const onSubmit = async (data: any) => {
    if (cantOfertasDisp && cantOfertasDisp != -1 && cantOfertasDisp <= 0) {
      return SnackBarUtils.error('No tenés ofertas disponibles para publicar productos')
    }

    const photos: Image[] = []

    images.forEach((img: any, i: number) => {
      const CHUNK_SIZE = 65536 // Tamaño de cada fragmento
      const uint8Array = new Uint8Array(img.bin)
      const chunks = []
      for (let i = 0; i < uint8Array.length; i += CHUNK_SIZE) {
        chunks.push(uint8Array.subarray(i, i + CHUNK_SIZE))
      }
      const str = chunks.map((chunk) => String.fromCharCode(...chunk)).join('')
      const b64 = btoa(str)

      photos.push({
        desc: encodeURIComponent(img.desc),
        idfotoProducto: 0,
        imagenBase64: b64
      })
    })

    const product: Product = {
      aclaracionPrecio: minWholeCount,
      detalle: data.description,
      codLocal: codLocal,
      imei: '0',
      listaImagenes: photos,
      modeloDisp: '',
      nombre: data.productName,
      sku: codigoSKU,
      precio: data.price,
      precioMinorista: canRetailSell ? data.retailPrice : 0,
      precioSinDto: data.previusPrice ?? 0,
      codRubro: rubro,
      idUnidadDeMedida: unidadSeleccionada,
      talles: data.waist,
      isoCodeMoneda: monedaSeleccionada,
      versionAndroid: '0',
      versionApp: '0'
    }

    if (images.length > maxPhotos) {
      return SnackBarUtils.error(`Solo podés subir ${maxPhotos} fotos`)
    }

    if (images.length === 0) {
      return SnackBarUtils.error('No podés publicar un producto sin imágenes')
    }

    if (!rubro) {
      return SnackBarUtils.error('Debes seleccionar un rubro')
    }

    if (openPopupInfo) {
      return
    }

    if (openPopupInfo) {
      return
    }

    try {
      !codigoSkuEnUso && setLoading(true)
      const response = await createProduct(product, tenant)
      if (response.data.errorCode !== 0) {
        return SnackBarUtils.error(response.data.message)
      } else {
        codigoSkuEnUso && setLoading(true)
        SnackBarUtils.success('Producto creado con éxito')
      }
      getProducts(2)

      const showPre = local?.config.find((c: any) => c.name === 'premium_ofrecerPremium')?.value

      if (local && showPre === 'true') {
        let sePidio = false
        const lastDiaPedido = localStorage.getItem('lastDiaPedido') as string
        if (lastDiaPedido) {
          sePidio = true
        }

        const postFrecuency = parseInt(
          local.config.find((c) => c.name === 'premium_ABMproductos_postFrequency')?.value as string
        )

        // Calcular diferencia de dias hay entre ultimo dia pedido y hoy
        const diff = Math.floor(
          (Date.parse(new Date().toString()) - Date.parse(new Date(lastDiaPedido).toString())) / 86400000
        )

        const cantPublicadosMin = parseInt(
          local.config.find((c) => c.name === 'premium_minPublicacionesOfrecerPremium')?.value as string
        )

        if (cantProducts && (!sePidio || postFrecuency >= diff) && cantPublicadosMin >= cantProducts) {
          localStorage.setItem('lastDiaPedido', new Date().toString())
          onOpenPre()
        }
      }
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Ha ocurrido un error al crear el producto')
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const handleClose = async () => {
    setOpen(false)
    setCanRetailSell(true)
    setNombreProducto('')
    setDescripcion('')
    setRubro('')
    setCodigoSKU('')
    setMinWholeCount('')
    setTalles('')
    setImages([])
    reset()
    setUnitsPerRubro('')
    setMonedasPorRubro('')
    setTituloProhibido(defaultErrorTitulo)
  }

  const handleUnidadSeleccionadaChange = async (e: any) => {
    // setUnidadSeleccionada(e.target.value);

    setUnidadSeleccionada(e.target.value)
    if (unidadDeMedida) {
      setUnidadDeMedida(unidadDeMedida.nombreSingular.toLowerCase())
    }
    //setUnidadDeMedida(unDeMedida.nombreSingular.toLowerCase());
  }

  const handleChangeRubro = async (e: any) => {
    setRubro(e.target.value)

    const rubroEncontrado = findRubro(e.target.value, rubros)
    setUnitsPerRubro(rubroEncontrado.unidadDeMedidaList)
    setMonedasPorRubro(rubroEncontrado.monedaList)
    setMonedaSeleccionada(rubroEncontrado.monedaList[0]?.isoCode)
    setPrecioMinimo(rubroEncontrado?.precioMinimo)

    if (rubroEncontrado.unidadDeMedidaList.length > 1) {
      setUnidadDeMedida(rubroEncontrado.unidadDeMedidaList[0].nombreSingular.toLowerCase())
      setUnidadSeleccionada(rubroEncontrado.unidadDeMedidaList[0].idUnidadDeMedida)
    }

    if (rubroEncontrado && rubroEncontrado[0]) {
      setMonedaSeleccionada(rubroEncontrado[0].monedaList[0].isoCode)
    }
  }

  const validarSku = async () => {
    const codigoEnUso = await skuEnUso(codLocal, codigoSKU, tenant)
    if (codigoEnUso?.data) {
      setCodigoSkuEnUso(true)
      SnackBarUtils.warning('El código/SKU está en uso')
    } else {
      setCodigoSkuEnUso(false)
    }
  }

  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(0,0,0,0.75)',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5">Publicando Producto</Typography>
        <Loading color="inherit" />
      </Box>
    )
  }

  if (!rubros || rubros.length <= 0) return <></>

  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Agregar un nuevo producto
            {open ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
              <Stack sx={{ width: '100%' }} onBlur={() => handlePalabrasProhibidas(nombreProducto)}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Título del producto
                  <Typography variant="subtitle2">
                    {nombreProducto.length || 0}/{MAX_NOMBRE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  {...register('productName')}
                  disabled={loading}
                  sx={{ width: '100%' }}
                  error={!!errors.productName}
                  helperText={
                    (errors?.productName && String(errors.productName.message)) ||
                    (!tituloProhibido.error && tituloProhibido.warning && (
                      <span style={{ color: 'blue' }}>{tituloProhibido.mensaje}</span>
                    ))
                  }
                  variant="outlined"
                  onChange={(e) => {
                    setNombreProducto(e.target.value)
                    handleTitulo(e.target.value)
                  }}
                  inputProps={{ maxLength: MAX_NOMBRE_LENGTH }}
                />
              </Stack>
            </Stack>
            {usaSku && (
              <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
                <Stack sx={{ width: '100%' }}>
                  <InputLabel
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    Código del Producto (SKU)
                    <Typography variant="subtitle2">
                      {codigoSKU.length || 0}/{MAX_SKU_LENGTH}
                    </Typography>
                  </InputLabel>
                  <TextField
                    disabled={loading}
                    sx={{ width: '100%' }}
                    error={!!errors.skuCode}
                    helperText={errors?.skuCode && String(errors?.skuCode?.message)}
                    variant="outlined"
                    {...register('skuCode')}
                    onChange={(e) => {
                      setCodigoSKU(e.target.value)
                    }}
                    onBlur={() => validarSku()}
                    inputProps={{ maxLength: MAX_SKU_LENGTH }}
                  />
                </Stack>
              </Stack>
            )}
            {/* Rubros */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                margin: '20px 0px',
                gap: '20px'
              }}
            >
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Rubro</InputLabel>
                <Select
                  native
                  defaultValue={rubro}
                  id="grouped-native-select"
                  onChange={handleChangeRubro}
                  disabled={loading}
                >
                  <option aria-label="None" disabled value="" label="Seleccione" />
                  {rubros &&
                    rubros?.map((group: any, i: number) => (
                      <optgroup key={i} label={group.nombre.toUpperCase()}>
                        {group?.rubros?.map((subrubro: any, j: number) => {
                          return subrubro.rubros?.length > 0 ? (
                            subrubro.rubros.map((subrubro2: any, h: number) => (
                              <option key={h} value={subrubro2.codRubro}>
                                {subrubro.nombre + ' - ' + subrubro2.nombre}
                              </option>
                            ))
                          ) : (
                            <option key={j} value={subrubro.codRubro}>
                              {subrubro.nombre}
                            </option>
                          )
                        })}
                      </optgroup>
                    ))}
                </Select>
              </Stack>
              {unitsPerRubro.length > 1 && (
                <Stack sx={{ width: '100%' }}>
                  <InputLabel>Unidad de medida</InputLabel>
                  <Select native disabled={loading} onChange={handleUnidadSeleccionadaChange}>
                    <option disabled value="" label="Seleccione">
                      Seleccione
                    </option>
                    {unitsPerRubro.map((units: any, i: number) => (
                      <option
                        key={units.idUnidadDeMedida + i}
                        value={units.idUnidadDeMedida}
                        label={units.nombreSingular + '/' + units.nombrePlural}
                      >
                        {units.nombreSingular + '/' + units.nombrePlural}
                      </option>
                    ))}
                  </Select>
                </Stack>
              )}
            </Box>
            {monedasPorRubro.length > 1 && (
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Moneda</InputLabel>
                <Select
                  native
                  disabled={loading}
                  onChange={(e: any) => {
                    setMonedaSeleccionada(e.target.value)
                  }}
                >
                  <option disabled value="" label="Seleccione">
                    Seleccione
                  </option>

                  {monedasPorRubro.map((units: any) => (
                    <option key={units.isoCode} value={units.isoCode} label={`${units.nombre} (${units.simbolo})`}>
                      {`${units.nombre} (${units.simbolo})`}
                    </option>
                  ))}
                </Select>
              </Stack>
            )}
            <Stack
              direction={isMobile ? 'column' : precioDto ? 'column' : 'row'}
              spacing="20px"
              sx={{ width: '100%', margin: '20px 0' }}
            >
              <Stack direction={'row'} spacing="20px">
                <Stack>
                  <InputLabel>
                    Precio mayorista (
                    {unitsPerRubro.length > 1
                      ? 'por ' + unidadDeMedida
                      : unitsPerRubro.length > 0
                      ? 'por ' + unitsPerRubro[0].nombreSingular.toLowerCase()
                      : 'por unidad'}
                    )
                  </InputLabel>
                  <TextField
                    prefix={'$'}
                    variant="outlined"
                    error={!!errors.price}
                    helperText={errors?.price && String(errors?.price?.message)}
                    disabled={loading}
                    {...register('price')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {monedasPorRubro.length > 0 &&
                            monedasPorRubro.find((moneda: any) => moneda.isoCode === monedaSeleccionada)?.simbolo}
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

                {precioDto && (
                  <Stack sx={{ width: isMobile ? '100%' : '50%' }}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        gap: '6px'
                      }}
                    >
                      Precio anterior
                      <Tooltip
                        sx={{ padding: '0px' }}
                        open={showTooltipPA}
                        onClose={() => setShowTooltipPA(false)}
                        onOpen={() => setShowTooltipPA(true)}
                        disableFocusListener
                        title={
                          <span>
                            El precio que cargues en el campo 'precio anterior' se mostrará tachado.
                            <br />
                            De esa forma podés mostrarle a los compradores que tu precio está rebajado
                          </span>
                        }
                      >
                        <IconButton onClick={() => setShowTooltipPA(true)}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <TextField
                      prefix={'$'}
                      variant="outlined"
                      error={!!errors.previusPrice}
                      disabled={loading}
                      helperText={errors?.previusPrice && String(errors?.previusPrice?.message)}
                      {...register('previusPrice')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {monedasPorRubro.length > 0 &&
                              monedasPorRubro.find((moneda: any) => moneda.isoCode === monedaSeleccionada)?.simbolo}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              <Stack sx={{ width: isMobile ? '100%' : precioDto ? '100%' : '50%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Compra mínima por mayor{' '}
                  <Typography variant="subtitle2">
                    {minWholeCount.length || 0}/{MAX_MIN_WHOLESALE_LENGTH}
                  </Typography>
                </InputLabel>

                <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    variant="outlined"
                    error={!!errors.minWholesale}
                    helperText={errors?.minWholeSale && String(errors?.minWholeSale?.message)}
                    placeholder="Mínimo 3 unidades"
                    value={minWholeCount}
                    disabled={loading}
                    fullWidth
                    {...register('minWholesale')}
                    onChange={(e) => setMinWholeCount(e.target.value)}
                    inputProps={{ maxLength: MAX_MIN_WHOLESALE_LENGTH }}
                  />
                </Stack>
              </Stack>
            </Stack>

            {/* Precio Minorista */}
            {esMinorista && (
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  gap: '8px',
                  width: '100%',
                  margin: '20px 0'
                }}
              >
                <Stack sx={{ width: '100%' }}>
                  <InputLabel
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}
                  >
                    Precio minorista (
                    {unitsPerRubro.length > 1
                      ? 'por ' + unidadDeMedida
                      : unitsPerRubro.length > 0
                      ? 'por ' + unitsPerRubro[0].nombreSingular.toLowerCase()
                      : 'por unidad'}
                    )
                    <Tooltip
                      open={showTooltipPM}
                      onClose={() => setShowTooltipPM(false)}
                      onOpen={() => setShowTooltipPM(true)}
                      disableFocusListener
                      title={`Si el comprador indicó que compra solo por menor, le mostraremos este precio. Si no vendés por menor, tocá ${
                        !retailer ? 'en la X' : 'en el botón'
                      } a la derecha del cuadro de texto`}
                    >
                      <IconButton onClick={() => setShowTooltipPM(true)}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    error={!!errors.retailPrice}
                    helperText={errors?.retailPrice && String(errors?.retailPrice?.message)}
                    disabled={!canRetailSell || loading}
                    sx={
                      !canRetailSell
                        ? {
                            backgroundColor: '#8d8d8d'
                          }
                        : {}
                    }
                    {...register('retailPrice')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {monedasPorRubro.length > 0 &&
                            monedasPorRubro.find((moneda: any) => moneda.isoCode === monedaSeleccionada)?.simbolo}
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    if (canRetailSell === false) {
                      setCanRetailSell(true)
                    } else {
                      setDisableRetailPrice(true)
                    }
                  }}
                  sx={{ alignSelf: 'flex-end', width: '75%' }}
                >
                  {canRetailSell ? 'No vender por menor' : 'Vender por menor'}
                </Button>
              </Stack>
            )}
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Talles
                  <Typography variant="subtitle2">
                    {talles.length || 0}/{MAX_MIN_WHOLESALE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  variant="outlined"
                  error={!!errors.waist}
                  helperText={errors?.waist && String(errors?.waist?.message)}
                  disabled={loading}
                  {...register('waist')}
                  onChange={(e) => setTalles(e.target.value)}
                  inputProps={{ maxLength: MAX_MIN_WHOLESALE_LENGTH }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Descripción del producto
                <Typography variant="subtitle2">
                  {descripcion.length || 0}/{MAX_DESC_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                placeholder="No olvides ninguna información! Ej colores, material, etc"
                variant="outlined"
                multiline
                rows={3}
                disabled={loading}
                error={!!errors.description}
                helperText={errors?.description && String(errors?.description?.message)}
                {...register('description')}
                onChange={(e) => setDescripcion(e.target.value)}
                inputProps={{ maxLength: MAX_DESC_LENGTH }}
              />
            </Stack>
            <Stack sx={{ mt: 3 }}>
              <InputLabel>
                Fotos{' '}
                {images.length > 0 && (
                  <span
                    style={{
                      color: images.length >= maxPhotos ? 'red' : undefined
                    }}
                  >
                    ({images.length} de {maxPhotos})
                  </span>
                )}
              </InputLabel>
              {/* <TextField
                                variant="outlined"
                                error={!!errors.waist}
                                helperText={errors?.waist?.message}
                                {...register("waist")}
                            /> */}
              <Dropzone
                name="images"
                maxPhotos={maxPhotos}
                images={images}
                setImages={setImages}
                ofrecerPremium={ofrecerPremium}
              />
            </Stack>
          </DialogContent>

          <DialogActions
            sx={{
              diplay: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-end',
              justifyContent: 'space-between',
              width: '100%',
              padding: '22px',
              paddingTop: 0,
              gap: '8px',
              position: 'relative'
            }}
          >
            <Button color="inherit" onClick={handleClose}>
              Volver
            </Button>

            <Stack direction="row" spacing={2}>
              {canSubmit ? (
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? 'Publicando' : 'Publicar'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  //type="submit"
                  disabled={loading}
                  onClick={() => (tituloAgotado ? setOpenPopupInfo(true) : setCanSubmit(true))}
                >
                  {loading ? 'Publicando' : 'Publicar'}
                </Button>
              )}
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <DisableRetailPrice
        open={disableRetailPrice}
        openStatus={setDisableRetailPrice}
        setCanRetailSell={setCanRetailSell}
      />

      <Dialog open={openPopupInfo} onClose={() => setOpenPopupInfo(false)}>
        <DialogContent>
          <InfoProductosAgotados isMobile={isMobile} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenPopupInfo(false)
            }}
            color="primary"
            variant="outlined"
            sx={{ margin: '4px' }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default memo(EditProduct)
