import { Box, Grid, Skeleton } from '@mui/material'

const ProductsSkeleton = () => {
  const base = Array.from({ length: 4 }).map((_, index) => (
    <Grid item key={index} xs={12} lg={6} mt="8px" width="100%">
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" gap="16px">
          <Skeleton width={100} height={130} variant="rectangular" style={{ borderRadius: '8px' }} />
          <Box display="flex" flexDirection="column" gap="16px">
            <Skeleton width={120} height={20} variant="rectangular" />
            <Skeleton width={90} height={20} variant="rectangular" />
            <Skeleton width={80} height={20} variant="rectangular" />
            <Skeleton width={210} height={20} variant="rectangular" />
          </Box>
        </Box>
        <Skeleton width={120} height={40} variant="rectangular" style={{ borderRadius: '20px' }} />
      </Box>
    </Grid>
  ))

  return (
    <Grid container spacing="16px">
      {base}
    </Grid>
  )
}

export default ProductsSkeleton
