import { axiosInstanceIntegraciones } from '../../../utils/axiosGlobalUtils'
import { CsvEquivalencia, ImportarData } from '../Importacion/types/importacion'
import { SaveAtributos } from '../types'

// Impo
export const GetCSVTemplate = async (codLocal: string) =>
  await axiosInstanceIntegraciones.get(`/${codLocal}/csvtemplate`)

export const ImportarCSV = async (codLocal: string, data: ImportarData[], reemplazarIds: boolean) =>
  await axiosInstanceIntegraciones.post(`/avellaneda/${codLocal}/importar?reemplazarIDs=${reemplazarIds}`, data)

export const SetCSVEquivalencias = async (codLocal: string, equivalencias: CsvEquivalencia[]) =>
  await axiosInstanceIntegraciones.post(`/${codLocal}/csvequivalencia`, equivalencias)

export const SetRubrosEquivalencias = async (codLocal: string, equivalencias: any[]) =>
  await axiosInstanceIntegraciones.post(`/${codLocal}/rubros`, equivalencias)

export const GetRubrosEquivalencias = async (codLocal: string) =>
  await axiosInstanceIntegraciones.get(`/${codLocal}/rubros`)

export const SetAtributosEquivalencias = async (codLocal: string, equivalencias: SaveAtributos[]) =>
  await axiosInstanceIntegraciones.post(`/${codLocal}/atributos`, equivalencias)
