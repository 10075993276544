import { GOOGLE_API_KEY } from "../../../utils/config"

interface MapaProps {
  center: string
}

const Mapa = ({center}: MapaProps) => {
  return (
    <img src={`
      https://maps.googleapis.com/maps/api/staticmap?center=${center}
      &markers=color:red%7Clabel:L%7C${center}
      &zoom=16&size=720x300&maptype=roadmap
      &key=${GOOGLE_API_KEY}
    `}
      alt="Mapa"
      width={'100%'}
    />
  )
}

export default Mapa
