import { FaPaperclip } from "react-icons/fa";
import { mostrarFecha, mostrarHora } from "../../../../utils/DateFunctions";
import { IConversacion, WhatsappContext } from "../../context/whatsapp.context";
import { Close, Done, DoneAll } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useContext, useEffect } from "react";
import ConversacionFecha from "./ConversacionFecha";
import { styled } from "@mui/material/styles";

const UserMessage = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#e6e6e6",
  borderRadius: "10px",
  padding: "10px",
  margin: "10px",
  maxWidth: "60%",
  alignSelf: "flex-start",
  overflowWrap: "break-word",
  fontFamily: "Roboto, sans-serif",

  "@media (max-width: 768px)": {
    maxWidth: "80%",
  },
});

const BotMessage = styled("div")({
  backgroundColor: "#035d4d",
  color: "#cddfdb",
  borderRadius: "10px",
  padding: "10px",
  margin: "10px",
  maxWidth: "60%",
  alignSelf: "flex-end",
  overflowWrap: "break-word",
  fontFamily: "Roboto, sans-serif",

  "@media (max-width: 768px)": {
    maxWidth: "80%",
  },
});

const Clip = styled("span")({
  cursor: "pointer",
  color: "#012509",
  fontWeight: "bold",
  marginLeft: "12px",

  "@media (max-width: 768px)": {
    margin: "0px",
  },
});

const Fecha = styled("span")({
  fontSize: "12px",
  color: "#828282",
  marginLeft: "30px",
  alignSelf: "flex-end",
});

const Mensaje = ({ message }: { message: IConversacion }) => {
  return (
    <>
      {message.outbound ? (
        <BotMessage>
          <span className="d-flex flex-column">
            {message.message}
            <Box
              sx={{
                display: "flex",
                alignSelf: "flex-end",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Fecha style={{ color: "#b6b6b6" }}>
                {mostrarHora(message.date)}
              </Fecha>
              {message.status === "ENVIADO" ? (
                <Done fontSize="small" style={{ color: "#acacac" }} />
              ) : message.status === "ENTREGADO" ? (
                <DoneAll fontSize="small" style={{ color: "#acacac" }} />
              ) : message.status === "LEIDO" ? (
                <DoneAll fontSize="small" style={{ color: "#6bd8e6" }} />
              ) : message.status === "ERROR" || message.status === "FALLO" ? (
                <Close fontSize="small" style={{ color: "#ff2d2d" }} />
              ) : null}
            </Box>
          </span>
        </BotMessage>
      ) : (
        <UserMessage>
          <span className="m-0 p-0">
            {message.read && message.message}
            {!message.read && <b>{message.message}</b>}
            {message.hasAttachment && (
              <Clip>
                <FaPaperclip />
              </Clip>
            )}
          </span>
          <Fecha>{mostrarHora(message.date)}</Fecha>
        </UserMessage>
      )}
    </>
  );
};

export default Mensaje;
