import React, { useContext, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Box, useMediaQuery, useTheme, Button } from "@mui/material";
import { Filtros, PedidosContext } from "../context/pedidos.context";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import InboxIcon from "@mui/icons-material/Inbox";

const StyledButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "6px 12px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(1),
  },
  color: "inherit",
  cursor: "pointer",
}));

const PedidosArchivados = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { setFiltros, filtros, setArchivados, archivados } =
    useContext(PedidosContext);

  const handleVerPedidosArchivados = () => {
    setFiltros({ ...filtros, archivados: true });
    setArchivados(true);
  };

  const handleVerPedidosVigentes = () => {
    setFiltros({ ...filtros, archivados: false });
    setArchivados(false);
  };

  return (
    <Box>
      <StyledButton
        onClick={
          archivados ? handleVerPedidosVigentes : handleVerPedidosArchivados
        }
      >
        {archivados ? (
          <InboxIcon
            sx={{
              marginRight: isMobile ? "0" : "8px",
              fontSize: "20px",
            }}
          />
        ) : (
          <MoveToInboxIcon
            sx={{
              marginRight: isMobile ? "0" : "8px",
              fontSize: "20px",
            }}
          />
        )}
        {!isMobile &&
          (archivados ? "VER PEDIDOS VIGENTES" : "VER PEDIDOS ARCHIVADOS")}
      </StyledButton>
    </Box>
  );
};

export default PedidosArchivados;
