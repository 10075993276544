import { axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import { ITenant } from '../../AbmLocalPage/types'
import { flatRubros } from '../../Products/hooks/rubros.hook'

export const getAllRubros = async (tenant: ITenant) =>
  await axiosInstanceMain.get(
    `/${tenant.path}/rubros?incluir-atributos=true&deviceId=0&clave=347ebc28653419c788302d8eba55b75a`
  )
export const handleNewRubro = async (codProducto: string, codRubro: string, tenant: ITenant) =>
  await axiosInstanceMain.patch(`/admin/${tenant.path}/productos/${codProducto}/rubro`, {
    valor: codRubro
  })

export const findRubro = (codRubro: string | number, rubros: any) => {
  const flatten = flatRubros(rubros)
  let rubroEncontrado: any
  flatten.forEach((r: any) => {
    if (r.rubros) {
      r.rubros.forEach((j: any) => {
        if (j.rubros) {
          j.rubros.forEach((k: any) => {
            if (k.codRubro === codRubro) {
              rubroEncontrado = k
              return k
            }
          })
        } else {
          if (j.codRubro === codRubro) {
            rubroEncontrado = j
            return j
          }
        }
      })
    } else {
      if (r.codRubro === codRubro) {
        rubroEncontrado = r
        return r
      }
    }
  })

  return rubroEncontrado
}

export const findUnidadDeMedida = (idUnidadDeMedida: number | string, codRubro: string | number, rubros: any) => {
  if (!rubros) return null

  const rubro = findRubro(codRubro, rubros)

  let unidadDeMedidaEncontrada = null

  if (rubro?.unidadDeMedidaList?.length > 1) {
    unidadDeMedidaEncontrada = rubro.unidadDeMedidaList.find((u: any) => {
      return u.idUnidadDeMedida === idUnidadDeMedida
    })
  } else {
    unidadDeMedidaEncontrada = rubro?.unidadDeMedidaList[0]
  }

  return unidadDeMedidaEncontrada
}
