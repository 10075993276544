import React, { memo, useEffect, useReducer, useRef, useState } from "react";
import { useSortableData } from "../../utils/UseSorteableDataHook";
import { ContractDTO, ContractListProps } from "../../dto/ContractDTO";
import ContractComponent from "./ContractComponent";
import { SortConfig } from "../../pages/Products/utils/ordenamientos";

export interface ContractComponentProps {
  contracts: ContractDTO[];
  contract: ContractDTO;
  index: number;
  group: string;
  noShow: boolean;
  noShowContracts: number[];
  setNoShowContracts: (ids: number[]) => void;
  requestSort: (key: string, direction?: string | undefined) => void;
  sortConfig: SortConfig | null;
  setShowRenovations: (show: boolean) => void;
  setRenewContract: (c: ContractDTO | null) => void;
}

const ContractList: React.FC<ContractListProps> = (props: ContractListProps) => {
  const { items, requestSort, sortConfig } = useSortableData(props.contracts ? props.contracts : []);

  const handleHidden = () => {
    if (props && props.noshow !== undefined) {
      props.noshow ? props.setnoShow(false) : props.setnoShow(true);
    }
  };

  useEffect(() => {
    props.setAllCollapsed(props.noshow);
  }, [props.noshow]);

  useEffect(() => {
    props.updateContractList(props.noShowContracts[props.noShowContracts.length - 1]);
  }, [props.noShowContracts]);

  useEffect(() => {
    if (props.clearedFiltro && props.refId && props.refs.current[props.refId]) {
      props.refs.current[props.refId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.clearedFiltro]);

  return (
    <>
      <div hidden={props.hidden} key={props.group}>
        <h1 style={{ textAlign: "center" }}>
          <button
            type="button"
            onClick={() => handleHidden()}
            className={"btn btn-primary-outline"}
            style={{
              fontSize: "xx-large",
              backgroundColor: props.textColor,
              width: "98%",
              marginTop: "10px",
            }}
          >
            {props.title}
          </button>
        </h1>
        {!props.noshow && (
          <div className="table-container">
            {items?.map((item: ContractDTO, index: any) => {
              /* if (!props.noShowContracts.includes(item.idContrato)) */
              return (
                <div
                  key={index}
                  ref={(el) => {
                    props.refs.current[item.idContrato] = el;
                  }}
                >
                  <ContractComponent
                    contracts={items}
                    contract={item}
                    index={index}
                    group={props.group}
                    noShow={props.noshow}
                    noShowContracts={props.noShowContracts}
                    setNoShowContracts={props.setNoShowContracts}
                    requestSort={requestSort}
                    sortConfig={sortConfig}
                    setShowRenovations={props.setShowRenovations}
                    setRenewContract={props.setRenewContract}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(ContractList);
