import { Box, Drawer, Typography } from '@mui/material'
import { FC, memo } from 'react'
import ProductosAA1TListado from './ProductosAA1TListado'
import { IProductoTienda } from '../../../../types/responses-productos'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  openDrawer: boolean
  cantProductosAA1T: number
  cantOfertasDisp: number
  loading: boolean
  productosAConfirmar: IProductoTienda[]
  handleConfirmProducts: (some: any) => Promise<void>
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const CarritoPublicaciones: FC<Props> = ({
  cantProductosAA1T,
  openDrawer,
  setOpenDrawer,
  cantOfertasDisp,
  handleConfirmProducts,
  loading,
  productosAConfirmar
}) => {
  return (
    <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor="right">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        maxWidth="650px"
        height="100%"
        p="24px"
      >
        {/* Sidebar */}
        <Box
          alignSelf="flex-start"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyItems="center"
          justifyContent="center"
          alignContent="center"
          gap="12px"
        >
          {/* Titulo y listado de productos */}
          <Typography fontSize={24} fontWeight={800} color="#212121" align="center">
            Publicaciones a importar en Avellaneda a un toque
          </Typography>

          {/* Publicaciones Disponibles */}
          <Typography color="#D5A000" fontSize={16} fontWeight={600}>
            Seleccionaste {cantProductosAA1T} {cantProductosAA1T > 1 ? 'publicaciones' : 'publicación'} - Podés importar{' '}
            {cantOfertasDisp} más
          </Typography>

          {/* Listado de productos */}
          <ProductosAA1TListado />
        </Box>

        <Box display="flex" flexDirection="column" gap="16px">
          {/* Boton añadir productos */}
          {productosAConfirmar.length > 0 && (
            <ButtonRounded
              fullWidth
              disabled={loading}
              bgcolor="#5983EF"
              textColor="white"
              variant="contained"
              onClick={async () => await handleConfirmProducts({})}
            >
              {loading
                ? 'Añadiendo...'
                : `IMPORTAR ${cantProductosAA1T} ${cantProductosAA1T > 1 ? 'PUBLICACIONES' : 'PUBLICACIÓN'}`}
            </ButtonRounded>
          )}

          <ButtonRounded fullWidth onClick={() => setOpenDrawer(false)}>
            Cerrar
          </ButtonRounded>
        </Box>
      </Box>
    </Drawer>
  )
}

export default memo(CarritoPublicaciones)
