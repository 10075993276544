import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography
} from '@mui/material'
import { ProductoWProps } from '../Producto'
import ProductoActions from '../ProductoActions'
import ButtonDesplegable from '../../../../components/Common/ButtonDesplegable'
import DesplegableRubrosProducto from '../DesplegableRubrosProducto'
import { format } from 'date-fns'
import CloseIcon from '@mui/icons-material/Close'

const CardProducto = ({
  producto,
  rubros,
  changingEstado,
  loadingRubros,
  isDefinitivelyDeleting,
  isDefinitivelyExpiring,
  cambiarRubro,
  isSelected,
  setIsSelected,
  eliminarRevision
}: ProductoWProps) => {
  return (
    <Card
      sx={{
        width: 350,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid'
      }}
    >
      <CardActionArea
        onClick={() => {
          window.open(producto.sharelink, '_blank')
        }}
      >
        <CardMedia
          component={'img'}
          loading="lazy"
          sx={{ height: 200, objectFit: 'scale-down' }}
          image={producto.urlFoto}
          title={producto.nombre}
        />
      </CardActionArea>
      <CardHeader
        sx={{
          backgroundColor:
            producto.aprobado && !producto.pausado && !producto.vencido
              ? '#b3faa8'
              : producto.rechazado
              ? '#ffaeba'
              : producto.pausado || producto.vencido
              ? '#ddd'
              : '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        action={
          <Box sx={{ color: 'text.secondary' }}>
            <ButtonDesplegable color="inherit" variant="text">
              <ProductoActions
                changingEstado={changingEstado}
                isDefinitivelyDeleting={isDefinitivelyDeleting}
                isDefinitivelyExpiring={isDefinitivelyExpiring}
                producto={producto}
              />
            </ButtonDesplegable>
          </Box>
        }
        title={producto.nombre}
        titleTypographyProps={{ variant: 'body1' }}
        subheader={`${producto.nombreLocal} - (${producto.codLocal})`}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <Typography variant="body2" color="darkblue">
            {producto.precioFormateado.split('<br>').map(
              (linea, i) =>
                linea.length > 0 && (
                  <span key={i}>
                    {linea}
                    <br />
                  </span>
                )
            )}
          </Typography>
          <Typography variant="body2" color="darkblue" style={{ whiteSpace: 'break-spaces' }}>
            {`Pub: ${format(new Date(producto.fechaPublicacion), 'dd/MM/yyyy HH:mm')}\nVto: ${format(
              new Date(producto.fechaVencimiento),
              'dd/MM/yyyy'
            )}`}
          </Typography>
          <Typography variant="body2" color="gray" sx={{ marginTop: '10px' }}>
            {producto.flags}
          </Typography>
        </div>
        {producto.adminRevisar && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px solid red',
              color: 'red',
              borderRadius: '4px',
              height: '40px'
            }}
          >
            <Typography style={{ paddingLeft: '14px', fontSize: '14px' }}>REVISAR</Typography>
            <IconButton size="small" color="error" onClick={eliminarRevision}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </CardContent>
      {/* Se alinea verticalmente al final del rubro */}
      <Box sx={{ flexGrow: 1 }} />
      <CardActions>
        <DesplegableRubrosProducto
          rubros={rubros}
          loading={loadingRubros}
          onChange={cambiarRubro}
          nombreRubro={producto.nombreRubro}
          codActual={producto.codRubro}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
        />
      </CardActions>
    </Card>
  )
}

export default CardProducto
