import { create } from "zustand";

type State = {
  open: boolean;
};

type Action = {
  setOpen: (open: State["open"]) => void;
};

export const useStoreExportacion = create<State & Action>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
}));
