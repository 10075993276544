import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { memo } from "react";

interface TextType {
  title: string;
  body: string;
  leftButton: string;
  rightButton: string;
  colorButtonConfirm:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "success"
    | "warning";
}

interface DialogProps {
  open: boolean;
  text: TextType;
  action: () => Promise<void>;
  close: any;
  disabled: boolean;
}

const ConfirmDialog = ({
  open,
  close,
  text,
  action,
  disabled,
}: DialogProps) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{text.title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ justifyContent: "center" }}>
          {text.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={close}>
          {text.leftButton}
        </Button>
        <Button
          disabled={disabled}
          onClick={action}
          autoFocus
          color={text.colorButtonConfirm}
        >
          {text.rightButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(ConfirmDialog);
