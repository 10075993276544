import React, {
  useState,
  FunctionComponent,
  createContext,
  useContext
} from "react";
import { LocalAdminDTO } from "../dto/LocalDTO";

export interface IFiltros {
  idLocal: string | undefined;
  codLocal: string;
  queryTexto: string | undefined;
  palabraCompleta: boolean | undefined;
  codesGalerias: string[] | undefined;
  soloActivos: boolean | undefined;
}

interface ILocalesContext {
  locales: LocalAdminDTO[] | undefined;
  updateLocales: (arg0: LocalAdminDTO[]) => void;
  filtros: IFiltros | undefined;
  updateFiltros: (arg0: IFiltros) => void;
}

const LocalesContext = createContext<ILocalesContext>({
  locales: undefined,
  updateLocales: () => {
    return null;
  },
  filtros: undefined,
  updateFiltros: () => {
    return null;
  },
});

const LocalesProvider: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [locales, setLocales] = useState<LocalAdminDTO[]>([]);
  const [filtros, setFiltros] = useState<IFiltros>();

  const updateLocales = (locales: LocalAdminDTO[]): void => {
    setLocales(locales);
  };

  const updateFiltros = (filtros: IFiltros): void => {
    setFiltros(filtros);
  };

  return (
    <LocalesContext.Provider
      value={{
        locales,
        updateLocales,
        filtros,
        updateFiltros,
      }}
    >
      {children}
    </LocalesContext.Provider>
  );
};
const useLocales = (): ILocalesContext => {
  const { locales, updateLocales, filtros, updateFiltros } =
    useContext(LocalesContext);
  return {
    locales,
    updateLocales,
    filtros,
    updateFiltros,
  };
};

export { useLocales, LocalesProvider };
