import { memo, useContext } from 'react'
import { IntegracionesContext } from '../../../../context/integraciones.context'
import { Grid } from '@mui/material'
import SingleProductoWoo from './SingleProductoWoo'
import { IProductoTienda } from '../../../../types/responses-productos'
import ProductsSkeleton from '../ProductsSkeleton'
import NoProductsTienda from './NoProductsTienda'

const ProductosWoo = () => {
  const { productosTienda, loadingProductosTienda } = useContext(IntegracionesContext)

  if (loadingProductosTienda) return <ProductsSkeleton />
  if (!productosTienda || productosTienda.length === 0) return <NoProductsTienda />

  return (
    <Grid container spacing="16px">
      {productosTienda.map((producto: IProductoTienda) => (
        <Grid item key={producto.externalId} xs={12} lg={6}>
          <SingleProductoWoo key={producto.externalId} producto={producto} action="agregar" />
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(ProductosWoo)
