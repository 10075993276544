import { Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import Loading from "../../../components/Common/Loading";
import SoloLecturaHorarios from "./SoloLectura/SoloLecturaHorarios";
import SoloLecturaFaq from "./SoloLectura/SoloLecturaFaq";
import SoloLecturaDescripcion from "./SoloLectura/SoloLecturaDescripcion";
import SoloLecturaCaracteristicas from "./SoloLectura/SoloLecturaCaracteristicas";
import SoloLecturaMediosDeContacto from "./SoloLectura/SoloLecturaMediosDeContacto";
import SoloLecturaLocal from "./SoloLectura/SoloLecturaLocal";
import { useCaracteristicas } from "../hooks/caracteristicas.hook";
import { LocalVendedorDTO } from "../../../dto/LocalDTO";

interface SoloLecturaProps {
  local: LocalVendedorDTO;
  setSoloLectura: (value: boolean) => void;
  getLocal: (codLocal: string) => Promise<any>;
}

const SoloLectura = ({ local, setSoloLectura, getLocal }: SoloLecturaProps) => {
  const [center, setCenter] = useState("");
  const [loading, setLoading] = useState(false);
  const { caracteristicas, loading: loadingCaracteristicas } = useCaracteristicas();

  useEffect(() => {
    setLoading(true);
    if (local.latitud) {
      setCenter(`${local.latitud},${local.longitud}`);
      setLoading(false);
    } else {
      if (local.direccion === "") return;
      // validarDireccion(local.dirLocal).then(res => {
      //   const local = res.data.data as Direccion
      //   setDirLocal(local?.direccionNormalizada)
      //   setCenter(`${local?.latitud},${local?.longitud}`)
      setLoading(false);
      // })
    }
  }, [local]);

  if (loading || loadingCaracteristicas)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loading />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5em",
        p: "1.5em",
        width: "100%",
        height: "100%",
        marginBottom: "70px",
      }}
    >
      {/* Local */}
      <SoloLecturaLocal local={local} center={center} getLocal={getLocal} />

      {/* Horarios */}
      <SoloLecturaHorarios local={local} />

      {/* Medios de contacto */}
      <SoloLecturaMediosDeContacto local={local} />

      {/* Caracteristicas */}
      <SoloLecturaCaracteristicas local={local} caracteristicas={caracteristicas} />

      {/* FAQ */}
      <SoloLecturaFaq local={local} />

      {/* Descripción */}
      <SoloLecturaDescripcion local={local} />

      <Button onClick={() => setSoloLectura(false)} fullWidth variant="contained">
        Modificar Perfil
      </Button>
    </Box>
  );
};

export default SoloLectura;
