import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { HistorialDTO } from "./AdminLocalState";
import { mostrarFechaCompleta } from "../../utils/DateFunctions";
import { Box } from "@mui/material";

export interface CustomDataGridProps {
  data: HistorialDTO[];
  loadingHistorial: boolean;
}

const CustomDataGrid = ({ data, loadingHistorial }: CustomDataGridProps) => {
  const columns: GridColDef<HistorialDTO>[] = [
    {
      field: "fecha",
      headerName: "Fecha",
      width: 150,
      valueGetter: (params: any) =>
        params ? mostrarFechaCompleta(params) : "-",
    },
    {
      field: "estado",
      headerName: "Estado",
      width: 120,
      renderCell: (data: any) =>
        data.row.aprobado
          ? "Aprobado"
          : data.row.rechazado
          ? "Rechazado"
          : "Pausado",
    },
    { field: "cuentaTipo", headerName: "Cuenta Tipo", width: 200 },
    { field: "localTipo", headerName: "Local Tipo", width: 100 },
    { field: "nombre", headerName: "Nombre", width: 200 },
    { field: "direccion", headerName: "Direccion", width: 250 },
    {
      field: "fechaBaja",
      headerName: "Fecha Baja",
      width: 120,
      editable: false,
      valueGetter: (params: any) =>
        params ? mostrarFechaCompleta(params) : "-",
    }
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: 400,
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.idLocalHistorial}
        disableRowSelectionOnClick
        disableColumnSelector
        hideFooter
        loading={loadingHistorial}
      />
    </Box>
  );
};

export default CustomDataGrid;
