import { axiosInstanceIntegraciones, axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import { ITenant } from '../../AbmLocalPage/types'
import { GetCodigoTienda, TiendaNames } from '../types'
import { CategoryWooResponse } from '../types/responses'
import { IProductoTienda, ProductosAA1TResponse, ProductosWooResponse } from '../types/responses-productos'

export const GetProductosAA1T = async (codLocal: string) =>
  (await axiosInstanceMain.get(`/avellaneda/productos/local/${codLocal}`)) as ProductosAA1TResponse

export const GetProductosTienda = async (
  tienda: TiendaNames,
  codLocal: string,
  idCategoria: string | number,
  searchText: string,
  sku: string
) => {
  let url = `/${codLocal}/productos?codigoTienda=${GetCodigoTienda(tienda)}`
  if (idCategoria) url += `&idCategoria=${idCategoria}`
  if (searchText) url += `&searchText=${searchText}`
  if (sku) url += `&sku=${sku}`
  return (await axiosInstanceIntegraciones.get(url)) as ProductosWooResponse
}

export const GetCategoriasTienda = async (tienda: TiendaNames, codLocal: string) =>
  (await axiosInstanceIntegraciones.get(
    `/${codLocal}/categorias?codigoTienda=${GetCodigoTienda(tienda)}`
  )) as CategoryWooResponse

export const SaveProductosAA1T = async (
  tienda: TiendaNames,
  codLocal: string,
  productos: IProductoTienda[],
  tenant: ITenant
) =>
  await axiosInstanceIntegraciones.post(
    `/${tenant.path}/${codLocal}/productos?codigoTienda=${GetCodigoTienda(tienda)}`,
    productos
  )
