import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { memo } from 'react'

interface Props {
  onClick: () => void
  disabled?: boolean
}

export interface CloseButtonProps {
  closeButton?: (paso?: string, reset?: () => void) => void
}

const CloseButton = ({ onClick, disabled }: Props) => {
  return (
    <IconButton aria-label="close" onClick={onClick} disabled={disabled} sx={{ color: 'black' }}>
      <Close />
    </IconButton>
  )
}

export default memo(CloseButton)
