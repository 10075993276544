import { FaArrowLeft, FaRedo } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { Button } from "@mui/material";
import { useContext } from "react";
import {
  WhatsappContext,
  initialStateContacto
} from "../../context/whatsapp.context";
import { styled } from "@mui/material/styles";

const ConversacionesHeader = styled("div")<{ hayContacto: boolean }>(
  ({ hayContacto }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderBottom: "1px solid #e0e0e0",
    gap: "10px",
    "& > button": {
      "@media (min-width: 768px)": {
        display: "none",
      },
      "@media (max-width: 768px)": {
        alignSelf: "center",
      },
    },
    "& > div": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: hayContacto ? "space-between" : "start",
      gap: "10px",
      "@media (max-width: 768px)": {
        width: "auto",
        flexDirection: "column",
        "& > h3": {
          fontSize: "1.2rem",
        },
      },
    },
  })
);

const NumberSelected = styled("h3")({
  fontSize: "1.5rem",
  fontWeight: "500",
  margin: "0",
  alignSelf: "center",
});

const ConversacionHeader = () => {
  const { contactoSeleccionado, setContactoSeleccionado, getConversacion } =
    useContext(WhatsappContext);
  const hayContacto = contactoSeleccionado.number !== 0;

  return (
    <ConversacionesHeader hayContacto={hayContacto}>
      {/* Volver ¡Solo Mobile! */}
      <Button
        variant="contained"
        color="error"
        onClick={() => setContactoSeleccionado(initialStateContacto)}
      >
        <FaArrowLeft />
      </Button>

      <div>
        {/* Número Seleccionado */}
        <NumberSelected>
          {hayContacto
            ? ` ${isMobile ? "" : "Chat: "} +${contactoSeleccionado.number}`
            : "Conversación"}
        </NumberSelected>

        {/* Refresh Button */}
        {hayContacto && (
          <Button onClick={getConversacion} variant="contained" color="info">
            <FaRedo />
          </Button>
        )}

        {/* Info */}
        {!hayContacto && (
          <span className="align-self-center">
            Selecciona un contacto para ver la conversacion
          </span>
        )}
      </div>
    </ConversacionesHeader>
  );
};

export default ConversacionHeader;
