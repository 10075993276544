import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Box, IconButton, TablePagination, useMediaQuery, useTheme } from "@mui/material";
import { Paginator, ProductosAdminContext } from "../../AdminProducts/context/productos.context";
import { IProducto } from "../../AdminProducts/types/producto";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export const INITIAL_ROWS_PER_PAGE = 10;
const rowsPerPageOptions = [INITIAL_ROWS_PER_PAGE, 25, 50, 100];

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

interface TableDataPaginationProps {
  component?: React.ElementType<any>;
  label: string;
  items: any[];
  total: number;
  paginator: Paginator;
  page: number;
  rowsPerPage: number;
}

const TableDataPagination = ({
  component = "div",
  label,
  total,
  paginator,
  page,
  rowsPerPage,
}: TableDataPaginationProps) => {
  const { handleChangePage, handleChangeRowsPerPage } = paginator;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <TablePagination
      width={"auto"}
      component={component}
      rowsPerPageOptions={rowsPerPageOptions}
      count={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage={!isMobile && label}
      labelDisplayedRows={({ from, to, count }) => {
        return count > 0 ? `${from}-${to} de ${count}` : "";
      }}
      sx={{
        "& div": {
          display: "flex",
        },
        "& p": {
          alignSelf: "end",
        },
        ...(isMobile && { display: "flex", alignSelf: "start" }),
      }}
    />
  );
};

export default TableDataPagination;
