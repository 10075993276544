import React from "react";

const StatisticsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: any
) => (
  <svg
    width="31"
    height="32"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.98828 16.1549L29.3029 4.1875"
      stroke="#8E8E8E"
      strokeWidth="3.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3867 2.32812L29.3064 4.18159L27.4758 9.10127"
      stroke="#8E8E8E"
      strokeWidth="3.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.4688 32.0742H24.7482V16.0566C24.7482 15.7532 24.8687 15.4622 25.0833 15.2476C25.2979 15.0331 25.5889 14.9125 25.8923 14.9125H29.3246C29.6281 14.9125 29.9191 15.0331 30.1336 15.2476C30.3482 15.4622 30.4688 15.7532 30.4688 16.0566L30.4688 32.0742Z"
      stroke="#8E8E8E"
      strokeWidth="3.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0312 32.0742H13.3107L13.3107 19.489C13.3107 19.1855 13.4312 18.8945 13.6458 18.68C13.8604 18.4654 14.1514 18.3449 14.4548 18.3449H17.8871C18.1906 18.3449 18.4816 18.4654 18.6961 18.68C18.9107 18.8945 19.0312 19.1855 19.0312 19.489V32.0742Z"
      stroke="#8E8E8E"
      strokeWidth="3.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.58203 32.0742H1.86147L1.86147 22.9213C1.86147 22.6179 1.98201 22.3269 2.19657 22.1123C2.41113 21.8977 2.70214 21.7772 3.00558 21.7772H6.43792C6.74136 21.7772 7.03237 21.8977 7.24693 22.1123C7.46149 22.3269 7.58203 22.6179 7.58203 22.9213L7.58203 32.0742Z"
      stroke="#8E8E8E"
      strokeWidth="3.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StatisticsIcon;
