import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | undefined

interface AlertDialogProps extends DialogProps {
  onCancel?: () => void
  onConfirm?: () => void
  onClose?: () => void
  title: string
  // content?: JSX.Element;
  agreeText?: string
  agreeColor?: color
  disagreeColor?: color
  disagreeText?: string
  disabled?: boolean
  hasActions?: boolean
  children?: any
  open: boolean
}

const AlertDialog = ({
  agreeText,
  agreeColor = 'primary',
  disagreeColor = 'error',
  content,
  disagreeText,
  onCancel,
  onClose,
  onConfirm,
  open,
  title,
  disabled = false,
  hasActions = true,
  children,
  ...props
}: AlertDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose || onCancel}
      onBlur={onCancel}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content && <DialogContentText>{content}</DialogContentText>}
        <Box sx={{ mt: 3 }}>{children}</Box>
      </DialogContent>
      {hasActions && (
        <DialogActions>
          {disagreeText && (
            <Button
              disabled={disabled}
              onClick={onCancel || onClose}
              color={disagreeColor}
            >
              {disagreeText}
            </Button>
          )}
          {agreeText && (
            <Button disabled={disabled} onClick={onConfirm} color={agreeColor}>
              {agreeText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default AlertDialog
