import React, { useState, useContext } from "react";
import { Grid, Paper, Typography, Card, Box, Divider } from "@mui/material";
import { GaleriasContext, IGalerias } from "../../context/galerias.context";
import GaleriasPagination from "../desktop/paginadoTablaGalerias";
import Loading from "../../../../components/Common/Loading";

export interface GaleriasProps {
  setSelectedGaleria: (code: string) => void;
  setEditGaleria: (edit: boolean) => void;
}

const GaleriasMobile: React.FC<GaleriasProps> = ({
  setEditGaleria,
  setSelectedGaleria,
}) => {
  const { galerias, /* searching */ page, rowsPerPage, loading } =
    useContext(GaleriasContext);
  const keyNames: Record<string, string> = {
    nombre: "Nombre",
    direccion: "Dirección",
  };
  if (loading) return <Loading />;

  return (
    <>
      <div style={{ margin: "20px" }}>
        {(rowsPerPage > 0
          ? galerias.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : galerias
        ).map((galeria: any) => (
          <Card
            key={galeria.codGaleria}
            variant="outlined"
            sx={{
              px: { md: "3em", xs: "0.5em" },
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "20px",
              alignItems: "flex-start",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => {
              setSelectedGaleria(galeria.codGaleria);
              setEditGaleria(true);
            }}
            style={{ cursor: "pointer" }}
            //title= "Ver detalle"
          >
            <Grid container style={{ marginLeft: "6px" }}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Nombre:
                  </Typography>
                  <Typography variant="body1">{galeria.nombre}</Typography>
                </Box>
              </Grid>
              {/* <Divider sx={{ width: "100%" }}  */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Dirección:
                  </Typography>
                  <Typography variant="body1">{galeria.direccion}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        ))}
        <GaleriasPagination />
      </div>
    </>
  );
};

export default GaleriasMobile;
