import { useEffect, useState } from 'react'

const useFilters = (rubros: any) => {
  const [filtersRubros, setFiltersRubros] = useState<any>([])
  const [searchFilter, setSearchFilter] = useState<string>('')

  const cumpleRubro = (codRubro: string, filterValue: string) => {
    if (filterValue === '0') return true
    return codRubro === filterValue
  }

  const filterByWord = (rubros: any[], filterValue: string): any[] => {
    return rubros.filter(
      (r) =>
        r.nombre.toLowerCase().includes(filterValue.toLowerCase()) && // Utilizamos filterValue en lugar de searchFilter
        cumpleRubro(r.codRubro, filterValue)
    )
  }

  useEffect(() => {
    if (rubros) {
      const filteredRubros = filterByWord(rubros, searchFilter)
      setFiltersRubros(filteredRubros)
    }
  }, [rubros, searchFilter])

  return {
    data: filtersRubros,
    setSearchFilter
  }
}

export default useFilters
