import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Typography,
  Container,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Grid
} from "@mui/material";
import { format } from "date-fns";
import DetallesMobile from "./detalleMobile";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { PaginatorPedidos } from "../../context/pedidos.context";
import { formatDate } from "../desktop/renderPedidosDesktop";

interface PedidosProps {
  pedidos: any;
  isAdmin: boolean;
  paginator: PaginatorPedidos;
  handleArchivarPedidos: (idPedido: number, archivar: boolean) => void;
}

const RenderPedidosMobile: React.FC<PedidosProps> = ({
  pedidos,
  isAdmin,
  paginator,
  handleArchivarPedidos,
}) => {
  const [selectedPedido, setSelectedPedido] = useState<any | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleVerDetalleClick = (pedido: any) => {
    setSelectedPedido(pedido);
    setModalOpen(true);
  };

  return (
    <>
      <div style={{ margin: "12px" }}>
        {pedidos.map((pedido: any, index: number) => (
          <Card
            key={index}
            variant="outlined"
            sx={{
              px: { md: "3em", xs: "0.5em" },
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "20px",
              alignItems: "flex-start",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => handleVerDetalleClick(pedido)}
            style={{ cursor: "pointer" }}
            //title= "Ver detalle"
          >
            {isAdmin && (
              <Typography variant="h5">Local: {pedido.localNombre}</Typography>
            )}
            {isAdmin && <Divider sx={{ width: "100%" }} />}
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="body1">
                    Pedido: {pedido.codigo}
                  </Typography>
                  <Typography variant="body1" sx={{ marginLeft: "auto" }}>
                    {formatDate(pedido.fechaEnvio)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="body1">
                    {pedido.nombre} {pedido.apellido}
                  </Typography>
                  <Typography variant="body1" sx={{ marginLeft: "auto" }}>
                    {pedido.compradorWhatsapp &&
                      `(Tel termina en ${pedido.compradorWhatsapp.slice(-4)})`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="body1">
                    Retira: {pedido.pedidoTipoRetiro}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: "auto", textAlign: "end" }}
                  >
                    Total:{" "}
                    {pedido.totales.map((total: any, index: number) => (
                      <span key={index}>
                        {`${total.moneda.simbolo} ${new Intl.NumberFormat(
                          "es-AR"
                        ).format(parseFloat(total.total))}`}
                        <br />
                      </span>
                    ))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        ))}

        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              marginRight: "10px",
              gap: "6px",
            }}
          >
            <IconButton
              edge="end"
              onClick={() => setModalOpen(false)}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                margin: "6px",
                paddingTop: "10px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent
            style={{ padding: "6px", margin: "6px", display: "flex" }}
          >
            <DetallesMobile
              pedidos={[selectedPedido]}
              isAdmin={isAdmin}
              handleArchivarPedidos={handleArchivarPedidos}
            />
          </DialogContent>
        </Dialog>
        {pedidos.length > 0 && (
          <Box
            display="flex"
            sx={{
              justifyContent: "end",
              width: "100%",
              marginBottom: "8px",
              paddingRight: "26px",
            }}
          >
            {paginator.pageNumber - 1 > 0 && (
              <IconButton
                onClick={(e: any) => {
                  paginator.handleChangePage(e, paginator.pageNumber - 1);
                }}
              >
                <WestIcon />
              </IconButton>
            )}
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "8px",
              }}
            >
              {paginator.pageNumber}
            </Typography>
            {paginator.hasNextPage && (
              <IconButton
                onClick={(e: any) => {
                  paginator.handleChangePage(e, paginator.pageNumber + 1);
                }}
              >
                <EastIcon />
              </IconButton>
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default RenderPedidosMobile;
