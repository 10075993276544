import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import Navbar from '../../components/Navbar'
import { useTheme, useMediaQuery } from '@mui/material'
import RenderPedidosDesktop from './components/desktop/renderPedidosDesktop'
import RenderPedidosMobile from './components/mobile/renderPedidosMobile'
import PedidosFiltros from './filtrosPedidos'
import { PedidosContext } from './context/pedidos.context'
import { RolType } from '../../components/Navbar/Sidebar/SidebarItems'
import NoContractsLabel from '../ContratosVendedor/components/Label'
import { archivarPedidos, desarchivarPedidos } from './components/services/archivados.services'
import Loading from '../../components/Common/Loading'
import SnackBarUtils from '../../utils/SnackBarUtils'
import ConfirmDialog from '../Products/components/ConfirmDialog'
import Footer from '../Home/components/Footer'
import { Header } from '../Galerias'
import { TenantsContext } from '../../context/tenants.context'
import { useAppStore } from '../../store/app-store'

const Pedidos: React.FC = (props: any) => {
  const codLocal = useAppStore((store) => store.codLocal)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  const { pedidos, loading, setLoading, archivados, filtros, setFiltros, paginatorPedidos } = useContext(PedidosContext)
  const { tenant } = useContext(TenantsContext)

  const isAdmin = props.logged.userLogged.roles.includes(RolType.ADMIN)

  const handleArchivarPedidos = (idPedido: number, archivar: boolean) => {
    const title = archivar ? '¿Desea archivar este pedido?' : '¿Desea desarchivar este pedido?'

    setConfirmDialog({
      open: true,
      text: {
        title: title,
        body: '',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar',
        colorButtonConfirm: 'primary'
      },
      action: async () => {
        try {
          setLoading(true)
          archivar ? await archivarPedidos(idPedido, tenant) : await desarchivarPedidos(idPedido, tenant)
          clearConfirmDialog()
          setFiltros({ ...filtros })
        } catch {
          console.log()
        } finally {
          SnackBarUtils.success(archivar ? 'Pedido archivado correctamente' : 'Pedido desarchivado correctamente')
          setLoading(false)
        }
      },
      close: () => {
        clearConfirmDialog()
      }
    })
  }

  return (
    <>
      <Navbar title={archivados ? 'Pedidos Archivados' : 'Pedidos'} pedidosArchivados={{ pedidosArchivados: true }} />
      <Header>
        <PedidosFiltros isAdmin={isAdmin} userLocal={codLocal} pedidos={pedidos} />
      </Header>
      {loading ? (
        <Loading />
      ) : (
        <>
          {pedidos.length > 0 ? (
            isMobile ? (
              <RenderPedidosMobile
                handleArchivarPedidos={handleArchivarPedidos}
                paginator={paginatorPedidos}
                pedidos={pedidos}
                isAdmin={isAdmin}
              />
            ) : (
              <RenderPedidosDesktop
                handleArchivarPedidos={handleArchivarPedidos}
                paginator={paginatorPedidos}
                pedidos={pedidos}
                isAdmin={isAdmin}
              />
            )
          ) : (
            <NoContractsLabel message="No se han encontrado pedidos"></NoContractsLabel>
          )}
          <ConfirmDialog {...confirmDialog} disabled={loading} />
        </>
      )}
      {!loading && <Footer />}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(Pedidos)
