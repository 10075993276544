import React, { useContext, useState, useEffect, useCallback } from "react";
import { IPedido } from "../types/pedido";
import { getPedidos, getTiposRetiro } from "../components/services/pedidos.services";
import { TenantsContext } from "../../../context/tenants.context";

export interface Filtros {
  pagina: number;
  tipo: string;
  codLocal: string;
  envioDesde: any;
  envioHasta: any;
  idTipoRetiro: number;
  codigoPedido: string;
  archivados: boolean;
}

const defaultFiltros: Filtros = {
  pagina: 0,
  tipo: "",
  codLocal: null,
  envioDesde: "",
  envioHasta: "",
  idTipoRetiro: 0,
  codigoPedido: "",
  archivados: false,
};

export interface PaginatorPedidos {
  pageNumber: number;
  pageSize: number;
  hasNextPage: boolean;
  handleChangePage: (e: any, newPage: number) => void;
}

const defaultPaginator: PaginatorPedidos = {
  pageNumber: 0,
  pageSize: 0,
  hasNextPage: false,
  handleChangePage: () => {},
};

interface PedidosContextProps {
  pedidos: IPedido[];
  setLoading: (loading: boolean) => void;
  loading: boolean;
  searching: boolean;
  archivados: boolean;
  setArchivados: (archivados: boolean) => void;
  tiposDeRetiro: any;
  setSearching: (searching: boolean) => void;
  setFiltros: (filtros: Filtros) => void;
  paginatorPedidos: PaginatorPedidos;
  filtros: Filtros;
}

// create context
export const PedidosContext = React.createContext<PedidosContextProps>({
  pedidos: [],
  setLoading: () => {},
  loading: false,
  searching: false,
  archivados: false,
  setArchivados: () => {},
  tiposDeRetiro: [],
  setSearching: () => {},
  setFiltros: () => {},
  paginatorPedidos: Object.assign({}, defaultPaginator),
  filtros: defaultFiltros,
});

// create provider
export const PedidosProvider = ({ children }: any) => {
  const { tenant } = useContext(TenantsContext);
  const [pedidos, setPedidos] = useState<IPedido[]>([]);
  const [tiposDeRetiro, setTiposDeRetiro] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [archivados, setArchivados] = useState<boolean>(false);
  const [filtros, setFiltros] = useState<Filtros>(defaultFiltros);
  const [paginatorPedidos, setPaginatorPedidos] = useState<PaginatorPedidos>(defaultPaginator);

  useEffect(() => {
    async function fetchTiposDeRetiro() {
      if (tenant.path) {
        const tiposRetiro = await getTiposRetiro(tenant);
        setTiposDeRetiro(tiposRetiro);
      }
    }

    fetchTiposDeRetiro();
  }, []);

  const fetchData = useCallback(async () => {
    if (tenant.path) {
      const response = await getPedidos(filtros, tenant).then((res) => res.data);

      setPaginatorPedidos({
        pageNumber: response.pageNumber,
        pageSize: response.pageSize,
        hasNextPage: response.hasNextPage,
        handleChangePage: paginatorPedidos.handleChangePage,
      });
      setPedidos(response.data);
    }
  }, [filtros]);

  paginatorPedidos.handleChangePage = (e: any, newPage: number) => {
    setFiltros((filtros) => ({
      ...filtros,
      pagina: newPage - 1,
    }));
  };

  useEffect(() => {
    if (!searching) return;

    setLoading(true);
    Promise.all([fetchData()])
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }, [filtros, searching]);

  if (!pedidos || !tiposDeRetiro) return null;

  return (
    <PedidosContext.Provider
      value={{
        pedidos,
        setLoading,
        loading,
        archivados,
        setArchivados,
        setFiltros,
        filtros,
        paginatorPedidos,
        searching,
        tiposDeRetiro,
        setSearching,
      }}
    >
      {children}
    </PedidosContext.Provider>
  );
};
