import { useContext, useEffect, useState } from 'react'
import { getGalerias } from '../services/galerias.service'
import { galeriasType } from '../types'
import { TenantsContext } from '../../../context/tenants.context'

export const useGalerias = () => {
  const [galerias, setGalerias] = useState<galeriasType[]>([])
  const [loading, setLoading] = useState(true)
  const { tenant } = useContext(TenantsContext)

  useEffect(() => {
    setLoading(true)
    getGalerias(tenant)
      .then((res) => {
        setGalerias(res.data)
      })
      .catch(console.log)
      .finally(() => setLoading(false))
  }, [])

  return { galerias, loading }
}
