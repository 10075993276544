import { Grid, Typography } from "@mui/material";
import Mapa from "../Mapa";
import SectionTitle from "../../common/SectionTitle";
import SoloLecturaLogo from "./SoloLecturaLogo";
import { useState } from "react";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

interface SoloLecturaLocalProps {
  local: LocalVendedorDTO;
  center: string;
  getLocal: (codLocal: string) => Promise<any>;
}

const SoloLecturaLocal = ({
  local,
  center,
  getLocal,
}: SoloLecturaLocalProps) => {
  const puedeTenerLogo =
    local.config.find((config) => config.name === "puedeTenerLogo")?.value ===
    "true";
  const [openModal, setOpenModal] = useState(false);

  return (
    <Grid container spacing={2}>
      {puedeTenerLogo && (
        <SoloLecturaLogo
          open={openModal}
          setOpen={setOpenModal}
          local={local}
          getLocal={getLocal}
        />
      )}

      <SectionTitle title="Información del local" />

      <Grid item xs={12}>
        <Typography variant="body1">
          Nombre del local: <b>{local.nombre}</b>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          Dirección:
          <b> {local.direccion}</b>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          Usuario: <b>{local.usuario}</b>
        </Typography>
      </Grid>

      {/* Mapa */}
      {center !== "" && (
        <Grid item xs={12}>
          <Mapa center={center} />
        </Grid>
      )}
    </Grid>
  );
};

export default SoloLecturaLocal;
