import { TextField, Typography } from '@mui/material'
import * as yup from 'yup'
import { PasosProps } from './RecuperarPassword'

export const validationEmail = yup.string().email("El mail no es válido").typeError("El mail es requerido")
export const validationTelefono = yup.string().matches(/^(?!15|0|54)\d{10}$/, {message: "Número inválido", excludeEmptyString: true})

const Paso1 = ({isSubmitting, isValid, setIsValid, setValue, value, defaultValue, handleConfirm}: PasosProps) => {
  const handleChange = (e: any) => {
    const v = e.target.value
    setValue(v)
    setIsValid(validationEmail.isValidSync(v) || validationTelefono.isValidSync(v))
  }

  return (
    <>
      <Typography marginBottom={2} variant="body1" color={"GrayText"}>
        Para ayudarte a recuperar tu contraseña lo más rápido posible, por favor ingresá los datos de contacto con los que registraste tu local
      </Typography>
      <TextField
        label="E-mail o whatsapp"
        value={value}
        autoFocus
        fullWidth
        disabled={isSubmitting}
        onChange={handleChange}
        onBlur={() => setIsValid(!(!validationEmail.isValidSync(value) && !validationTelefono.isValidSync(value)))}
        onKeyPress={e => e.key === "Enter" && handleConfirm()}
        helperText={isValid ? "" : "E-mail o whatsapp inválido"}
        error={!isValid}
        color={isValid ? "primary" : "error"}
      />
    </>
  )
}

export default Paso1
