import { createContext, useState, useEffect } from "react";
import { IPais, ITenant } from "../pages/AbmLocalPage/types";
import Loading from "../components/Common/Loading";
import { getTenant } from "../pages/Products/services/admin.services";

interface TenantsContextProps {
  tenant: ITenant;
  setTenant: (tenant: ITenant) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const defaultTenant: ITenant = {
  idTenant: 1,
  idPais: 1,
  nombre: "",
  path: "",
  latitud: 0,
  longitud: 0,
  radioMetros: 0,
  logo: null,
  dominio: "avellanedaauntoque.com",
  pais: {} as IPais,
  config: [],
};

export const TenantsContext = createContext<TenantsContextProps>({
  tenant: defaultTenant,
  setTenant: () => {},
  loading: false,
  setLoading: () => {},
});

const getDomain = () => {
  const hostname = window.location.hostname;
  if (hostname?.includes("localhost")) {
    const params = new URL(window.location as any).searchParams;
    const tenant = params.get("dominio");
    return tenant || defaultTenant.dominio;
  } else {
    const parts = hostname.split(".");
    const domain = parts.slice(-2).join(".");
    return domain;
  }
};

export const TenantsProvider = ({ children }: any) => {
  const domain: string = getDomain();
  const [tenant, setTenant] = useState<ITenant>(defaultTenant);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchDataTenancy = () => {
    setLoading(true);
    getTenant(domain)
      .then(({ data }) => {
        setTenant(data.data);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDataTenancy();
  }, [domain]);

  if (!tenant?.path || tenant?.path === "") return null;

  if (!tenant || loading) return <Loading />;
  return (
    <TenantsContext.Provider
      value={{
        setTenant,
        tenant,
        loading,
        setLoading,
      }}
    >
      {children}
    </TenantsContext.Provider>
  );
};
