import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Typography,
  Container,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Grid
} from "@mui/material";
import { format } from "date-fns";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import ReportesPagination from "../desktop/paginadoReportes";
import { DataReporte } from "../../types/reporte";
import Loading from "../../../../components/Common/Loading";

interface ReporteProps {
  reporte: DataReporte[];
  loading: boolean;
}

const INITIAL_ROWS_PER_PAGE = 10;

const CardReporte: React.FC<ReporteProps> = ({ reporte, loading }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedReportes = reporte.slice(startIndex, endIndex);

  const formatValue = (data: any) => {
    if (typeof data === "string") {
      const dateObject = new Date(data);

      if (!isNaN(dateObject.getDate())) {
        return format(dateObject, "dd/MM/yyyy HH:mm");
      }
    }

    return data;
  };
  if (loading) return <Loading />;
  const renderData = (name: string, value: any) => {
    return (
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {name}:
        </Typography>
        <Typography variant="body1" sx={{ marginLeft: "6px" }}>
          {value}
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <div style={{ margin: "12px" }}>
        {slicedReportes.map((r: DataReporte, i: number) => (
          <Card
            key={i}
            variant="outlined"
            sx={{
              px: { md: "3em", xs: "0.5em" },
              display: "flex",
              flexDirection: "column",

              marginBottom: "20px",
              alignItems: "flex-start",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container sx={{ padding: "10px" }}>
              <Grid item xs={12} md={6}>
                {renderData("Cod. Local", r.codLocal)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderData("Nombre", r.nombre)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderData("Dirección", r.direccion)}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="body1">
                    {r.metadata.length > 0 &&
                      r.metadata.map((data: any, index: number) => (
                        <React.Fragment key={index}>
                          {data.name && (
                            <>
                              <span style={{ fontWeight: "bold" }}>
                                {data.name}:
                              </span>{" "}
                              {formatValue(data.value)}
                              <br />
                            </>
                          )}
                        </React.Fragment>
                      ))}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {r.whatsapp.map((whpp, i: number) => (
                    <React.Fragment key={i}>
                      <a
                        href={whpp}
                        target="_blank"
                        className="btn btn-success wsp-btn"
                        role="button"
                        rel="noreferrer"
                        style={{
                          marginRight: i < r.whatsapp.length - 1 ? "10px" : "0",
                        }}
                      >
                        WSP
                      </a>
                    </React.Fragment>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Card>
        ))}
        <Box>
          <ReportesPagination
            component={"div"}
            reportes={reporte}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      </div>
    </>
  );
};

export default CardReporte;
