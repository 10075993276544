import { FC } from 'react'
import { ProductoInterno } from '../../../../types/responses-novedades'
import { Box, Typography } from '@mui/material'
import NovedadImage from './NovedadImage'
import { mostrarFechaCompleta } from '../../../../../../utils/DateFunctions'
import { LuCalendarDays, LuScanBarcode } from 'react-icons/lu'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  producto: ProductoInterno
}

const NovedadHeader: FC<Props> = ({ producto }) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      gap={{ xs: '20px', md: '0px' }}
      alignItems="center"
      width="100%"
    >
      <Box display="flex" gap="19px">
        <Box>
          <NovedadImage producto={producto} />
        </Box>
        <Box flexDirection="column" gap="24px">
          <Typography fontSize={{ xs: 20, md: 24 }} fontWeight="bold">
            {producto.nombre}
          </Typography>
          <Box display="flex" gap="8px" alignItems="center">
            <Box display="flex" gap="2px">
              <Typography
                fontSize={{ xs: 12, md: 14 }}
                display="flex"
                alignItems="center"
                gap="4px"
                fontWeight="medium"
                color="#555555"
              >
                <LuCalendarDays fontWeight={900} size={20} color="black" /> Fecha de última publicación:
              </Typography>
              <Typography fontSize={{ xs: 12, md: 14 }} fontWeight="medium" color="#1C1C1C">
                {mostrarFechaCompleta(producto.fechaPublicacionString, true, true)}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap="8px" alignItems="center">
            <Typography
              fontSize={{ xs: 12, md: 14 }}
              display="flex"
              alignItems="center"
              gap="4px"
              fontWeight="medium"
              color="#555555"
            >
              <LuScanBarcode fontWeight={900} size={20} color="black" /> SKU:
            </Typography>
            <Typography fontSize={{ xs: 12, md: 14 }} fontWeight="medium" color="#1C1C1C">
              {producto.sku}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box alignSelf={{ xs: 'flex-end', md: 'center' }}>
        <ButtonRounded fullWidth variant="outlined">
          APLICAR TODOS LOS CAMBIOS
        </ButtonRounded>
      </Box>
    </Box>
  )
}

export default NovedadHeader
