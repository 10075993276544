import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Container from '@mui/material/Container'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import { Paper, useMediaQuery, useTheme, Typography } from '@mui/material'
import AlertDialog from '../../components/Common/Dialog'
import Navbar from '../../components/Navbar'
import Footer from '../Home/components/Footer'
import CardEstadistica from './CardEstadistica'
import { useMetricas } from '../Home/hooks/metricas.hook'
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import LabelIcon from '@mui/icons-material/Label'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import Loading from '../../components/Common/Loading'
import { useAppStore } from '../../store/app-store'

const Estadisticas: React.FC = () => {
  const codLocal = useAppStore((store) => store.codLocal)
  const { setDetallado, metricas, loading } = useMetricas(codLocal)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setDetallado(true)
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <Navbar title={'Estadísticas'} />
      <Paper
        elevation={0}
        sx={{
          marginTop: '10px',
          ...(isMobile && { marginBottom: '80px' })
        }}
      >
        <>
          <CardEstadistica
            logo={<AddBusinessOutlinedIcon fontSize="large" />}
            descripcion={
              <Typography variant={isMobile ? 'body1' : 'h6'}>
                Tenés <b>{metricas?.cantSeguidores}</b> seguidores
              </Typography>
            }
            divider={true}
          />
          <CardEstadistica
            logo={<StarBorderOutlinedIcon fontSize="large" />}
            descripcion={
              <Typography variant={isMobile ? 'body1' : 'h6'}>
                <b>{metricas?.cantFavoritosUsuarios}</b> veces marcaron tus
                productos como favoritos
              </Typography>
            }
            divider={true}
          />
          <CardEstadistica
            logo={<RemoveRedEyeIcon fontSize="large" />}
            descripcion={
              <Typography variant={isMobile ? 'body1' : 'h6'}>
                <b>{metricas?.cantVistasTotales}</b> veces entraron a tus
                publicaciones
              </Typography>
            }
            divider={true}
          />
          <CardEstadistica
            logo={<LabelIcon fontSize="large" />}
            descripcion={
              <Typography variant={isMobile ? 'body1' : 'h6'}>
                <b>{metricas?.cantOfertas}</b> publicaciones,{' '}
                {metricas?.cantOfertasVencidas === 0
                  ? 'ninguna vencida'
                  : `${metricas?.cantOfertasVencidas} vencidas`}{' '}
                <b>
                  (
                  {metricas?.cantOfertasDisp === 0
                    ? 'no podés publicar ninguna oferta más'
                    : `podés publicar ${metricas?.cantOfertasDisp} más`}
                  )
                </b>
              </Typography>
            }
            divider={true}
          />
          <CardEstadistica
            logo={<ShoppingCartOutlinedIcon fontSize="large" />}
            descripcion={
              <Typography variant={isMobile ? 'body1' : 'h6'}>
                <b>{metricas?.cantPedidos}</b> pedidos recibidos
              </Typography>
            }
            divider={false}
            botonVer="Ver pedidos"
            botonPath="pedidos"
          />
        </>
      </Paper>
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas)
