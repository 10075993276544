import axios from 'axios'
import store from '../redux/store'
import SnackBarUtils from './SnackBarUtils'
import { axiosInstanceMain } from './axiosGlobalUtils'
import { HorarioDTO } from '../dto/LocalDTO'

const unsecuredCopyToClipboard = (text: any) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Unable to copy to clipboard', err)
  }
  document.body.removeChild(textArea)
}

export const copyToClipboard = async (text: string) => {
  if (window.isSecureContext && navigator.clipboard) {
    await navigator.clipboard.writeText(text)
  } else {
    unsecuredCopyToClipboard(text)
  }

  SnackBarUtils.info('Texto copiado al portapapeles')
}

export const esPar = (value: number) => value % 2 === 0

export const generarNuevoToken = async () => {
  try {
    const result = await axiosInstanceMain.get(
      `/avellaneda/auth/refreshToken/${store.getState().logged['userLogged']['refreshToken']}`
    )
    const newToken = result.data.accessToken
    const newRefreshToken = result.data.refreshToken
    store.dispatch({
      type: 'refreshTokenLogged',
      payload: { newToken, newRefreshToken }
    })
    return newToken
  } catch (error) {
    console.log('Error al generar nuevo token: ' + error)
  }
}

export const consultarConNuevoToken = async (config: any) => {
  const newToken = await generarNuevoToken()

  const newPromise = new Promise((resolve, reject) => {
    axios({
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${newToken}` }
    })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        store.dispatch({ type: 'logOut', payload: '' })
        SnackBarUtils.error('Tu sesión fue cerrada por motivos de seguridad')
        reject(error)
      })
  })

  return newPromise
}

export const formatDate = (d: any) => {
  const date = new Date(d)
  const day = date.getDate()
  const month = date.getMonth() + 1
  let year = date.getFullYear().toString()

  year = year.substring(2, 4)

  return `${day}/${month}/${year}`
}

export const formatTime = (d: any) => {
  const date = new Date(d)
  const hours = date.getHours() as any
  let minutes = date.getMinutes() as any

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  return `${hours}:${minutes}`
}

export const formatDateTime = (d: any) => {
  return formatDate(d) + '  ' + formatTime(d)
}

export const getFecha = (horario: HorarioDTO) => {
  switch (horario.idDia) {
    case 1:
      return 'Lunes'
    case 2:
      return 'Martes'
    case 3:
      return 'Miércoles'
    case 4:
      return 'Jueves'
    case 5:
      return 'Viernes'
    case 6:
      return 'Sábado'
    case 7:
      return 'Domingo'
    default:
      return ''
  }
}

export function obtenerValoresNumericos(cadena: string) {
  const regex = /\d+/g
  const matches = cadena.match(regex)

  if (matches) {
    return matches.map(Number)
  } else {
    return []
  }
}

export const firstLetterUpperCase = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const cleanString = (text: string) =>
  text
    .replace(/á/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ú/gi, 'u')
    .replace(/ñ/gi, 'n')
    .replace(/ /g, '_')
    .toLowerCase()

export function eliminarDuplicados(array) {
  const seen = new Set()
  return array.filter((obj) => {
    const stringified = JSON.stringify(obj)
    if (seen.has(stringified)) {
      return false // Ya existe, no incluirlo
    }
    seen.add(stringified)
    return true // Es único, incluirlo
  })
}

export function eliminarTildes(texto: string): string {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
