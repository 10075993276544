import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { useContext, useEffect, useState } from "react";
import { IPais } from "../types";
import { TenantsContext } from "../../../context/tenants.context";

export const useDataPais = () => {
  const [infoPais, setInfoPais] = useState<IPais | undefined>();
  const [loadingDataPais, setLoadingDataPais] = useState<boolean>(true);
  const { tenant } = useContext(TenantsContext);

  const fetchData = async () => {
    if (tenant.path) {
      try {
        setLoadingDataPais(true);
        const data = (await axiosInstanceMain.get(`tenants/${tenant.path}`)).data.data;
        setInfoPais(data.pais);
      } catch {
        console.log();
      } finally {
        setLoadingDataPais(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { infoPais, fetchData, loadingDataPais };
};
