import { CircularProgress } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import Observaciones from "./Observaciones";
import Loading from "../Common/Loading";
import { SingleContractProps } from "./ContractComponent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ContractDesktop: React.FC<SingleContractProps> = ({
  group,
  contract,
  index,
  loadingAccion,
  loadingMP,
  noShow,
  requestSort,
  sortConfig,
  setShowRenovations,
  setRenewContract,
  handleLinkMP,
  executeAction,
  handleWhpp,
}: SingleContractProps) => {
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig["key"] === name ? sortConfig["direction"] : undefined;
  };

  return (
    <>
      <table
        key={index}
        className="table table-bordered table-border-button"
        hidden={noShow}
      >
        <thead>
          <tr>
            <th style={{ width: "15vmin" }}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <a
                  href={`https://wa.me/${contract.clienteWhatsapp}`}
                  target="_blank"
                  className="btn btn-success wsp-btn"
                  role="button"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                  }}
                >
                  {group !== "Suspendidos" && group !== "Pospuestos" ? (
                    "VACÍO"
                  ) : (
                    <>
                      <WhatsAppIcon
                        fontSize="small"
                        style={{ marginRight: "2px" }}
                      />
                      {contract.clienteWhatsapp}
                    </>
                  )}
                </a>
                {group !== "Suspendidos" && group !== "Pospuestos" && (
                  <a
                    target="_blank"
                    className="btn btn-success wsp-btn"
                    role="button"
                    rel="noreferrer"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => handleWhpp()}
                  >
                    {"CON MSJ"}
                  </a>
                )}
              </div>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("clienteNombre")}
                className={
                  "btn btn-primary-outline " + getClassNamesFor("clienteNombre")
                }
              >
                Cliente
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("productoNombre")}
                className={
                  "btn btn-primary-outline " +
                  getClassNamesFor("productoNombre")
                }
              >
                Producto
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("fechaFinFormateada")}
                className={
                  "btn btn-primary-outline " +
                  getClassNamesFor("fechaFinFormateada")
                }
              >
                Vencimiento
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("estadoNombre")}
                className={
                  "btn btn-primary-outline " + getClassNamesFor("estadoNombre")
                }
              >
                Estado
              </button>
            </th>
            <th>
              <button
                type="button"
                onClick={() => requestSort("precioLista")}
                className={
                  "btn btn-primary-outline " + getClassNamesFor("precioLista")
                }
              >
                Precio
              </button>
            </th>
            {/* <th>
      <button
        type="button"
        onClick={() => requestSort("idContrato")}
        className={
          "btn btn-primary-outline " +
          getClassNamesFor("idContrato")
        }
      >
        Id Contrato
      </button>
    </th> */}

            <th style={{ display: "flex", flexDirection: "row" }}>
              <button
                disabled={
                  (!contract.renovable && contract.idContratoEstado !== 6) ||
                  contract.accionesAdminList === null ||
                  contract.accionesAdminList === undefined
                }
                className="btn btn-primary premise-info-child"
                onClick={() => {
                  setShowRenovations(true);
                  setRenewContract(contract);
                }}
              >
                Acciones
              </button>

              <button
                className="btn btn-primary premise-info-child"
                onClick={() => {
                  handleLinkMP(contract.idContrato);
                }}
              >
                {loadingMP ? <Loading /> : "Link MP"}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key={index}>
            <th>{contract.idContrato}</th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `admin-local/${contract.idContratoClienteLocal}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <u>
                {contract.clienteNombre} <br />{" "}
                {contract.idContratoClienteLocal}
                {group !== "Suspendidos" && group !== "Pospuestos" && (
                  <>
                    <br />
                    {`Wp: ${contract.clienteWhatsapp}`}
                  </>
                )}
              </u>
            </th>
            <th
              style={{
                backgroundColor: `#${contract.colorProducto}`,
              }}
            >
              {contract.cantidad > 1
                ? contract.productoNombre + "x" + contract.cantidad
                : contract.productoNombre}
            </th>
            <th>{contract.fechaFinFormateada}</th>
            <th>{contract.estadoNombre}</th>
            <th>{`$${contract.precioLista?.toLocaleString('es-ES')}`}</th>
            <th>
              {contract.accionesAdminList !== undefined &&
                contract.accionesAdminList !== null &&
                contract.accionesAdminList[0] && (
                  <button
                    disabled={!contract.accionesAdminList[0]}
                    className="btn btn-primary premise-info-child"
                    onClick={() => {
                      if (
                        contract.accionesAdminList &&
                        contract.accionesAdminList[0]
                      ) {
                        executeAction(
                          contract.accionesAdminList[0],
                          contract.idContrato
                        );
                      }
                    }}
                    style={{
                      color: "#212529",
                      borderColor: "#212529",
                      backgroundColor: `${contract.accionesAdminList[0].color}`,
                      pointerEvents: loadingAccion ? "none" : "auto",
                    }}
                  >
                    {loadingAccion ? (
                      <CircularProgress
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    ) : (
                      contract.accionesAdminList[0].boton
                    )}
                  </button>
                )}
            </th>
          </tr>
          <Observaciones props={contract}></Observaciones>
        </tbody>
      </table>
    </>
  );
};

export default memo(ContractDesktop);
