export interface Ordenamiento {
  id: number
  criterio: string
  keyName: string
  ascendente: boolean
}

export interface SortConfig {
  key: string
  direction: string
}

export const ordenamientos: Ordenamiento[] = [
  {
    id: 4,
    criterio: 'Más nuevo',
    keyName: 'fechaPublicacion',
    ascendente: true
  },
  {
    id: 3,
    criterio: 'Más antiguo',
    keyName: 'fechaPublicacion',
    ascendente: false
  },
  { id: 1, criterio: "Menos popular", keyName: "", ascendente: true },
  { id: 2, criterio: "Más popular", keyName: "", ascendente: false },
  {
    id: 5,
    criterio: 'Menor precio',
    keyName: 'precioFormateado',
    ascendente: false
  },
  {
    id: 6,
    criterio: 'Mayor precio',
    keyName: 'precioFormateado',
    ascendente: true
  },
  { id: 9, criterio: 'Vencimiento', keyName: '', ascendente: false }
]
