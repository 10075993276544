import React, { useCallback, useContext, useEffect, useState } from 'react'
import '../../styles/sortableData.scss'
import { useSortableData } from '../../utils/UseSorteableDataHook'
import { ContractDTO, MedioPago } from '../../dto/ContractDTO'
import { axiosInstanceContratos, axiosInstanceMain } from '../../utils/axiosGlobalUtils'
import AdminLocalContractsRenovations from './AdminLocalContractsRenovations'
import { hash } from '../../utils/hashFunction'
import ModalTableHistorial from './ModalTableHistorial'
import ModalTableCtaCte from './CtaCte/ModalTableCtaCte'
import { Button } from '../Common/utils/button/button.component'
import { RolType } from '../Navbar/Sidebar/SidebarItems'
import { Box, FormControlLabel, Switch, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material'
import { getProductosContrato } from '../../pages/AdminLocal/services/contratos.productos.services'
import { DataContratosContext } from './context/data.contratos.context'
import { useLocalContracts } from '../../hooks/admin.local.contracts.hook'
import { IProductoContrato } from '../../pages/AdminProducts/types/producto'
import { INITIAL_ROWS_PER_PAGE } from '../../pages/Products/components/TableDataPagination'
import ModalTableLinks from './ModalTableLinks'
import ModalTablePagos from './ModalTablePagos'
import CrearContrato from './DialogCrearContrato'
import DialogCambiarProductos from './DialogCambiarProducto'
import { useProductosContrato } from '../ContractsList/hooks/productos.contrato.hook'
import { getMediosPago } from '../ContractsList/contracts.services'
import { getCtaCte } from './services/cta.cte.services'

const ContentTable = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  width: isMobile ? '100%' : '97%',
  overflowX: isMobile ? 'auto' : 'visible'
}))

const ButtonChangeProduct = styled(Button)(({ theme }) => ({
  fontSize: '0.70rem',
  margin: '1px',
  width: '100%',
  padding: '6px 2px',
  lineHeight: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down(425)]: {
    width: '100%'
  }
}))

const ButtonTable = styled(Button)(({ theme }) => ({
  fontSize: '0.75rem',
  margin: '1px',
  width: '85%',
  [theme.breakpoints.down(425)]: {
    width: '100%'
  }
}))

const AdminLocalContracts: React.FC<any> = (props: any) => {
  const [showRenovations, setShowRenovations] = useState<boolean>(false)
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const [contractIdChecked, setContractIdChecked] = useState<number>()
  const [showCtaCte, setShowCtaCte] = useState<boolean>(false)
  const [renewContract, setRenewContract] = useState<ContractDTO>()
  //const [idLocal, setIdLocal] = useState<string>();
  const [showPagos, setShowPagos] = useState<boolean>(false)
  const [showLinks, setShowLinks] = useState<boolean>(false)
  const [resultCtaCte, setResultCtaCte] = useState<any[]>([])
  const [openCambiarProductos, setOpenCambiarProductos] = useState<boolean>(false)
  const [openCrearContrato, setOpenCrearContrato] = useState<boolean>(false)
  const [productoACambiar, setProductoACambiar] = useState<ContractDTO>()
  const [mediosDePago, setMediosDePago] = useState<MedioPago[]>([])
  const isAdmin = props?.props?.logged?.userLogged?.roles.includes(RolType.ADMIN)
  const isOperador = props?.props?.logged?.userLogged?.roles.includes(RolType.MOD)
  const { setIdContrato, idLocal, setIdLocal, setPageLinks, setPageSizeLinks, setPagePagos, setPageSizePagos } =
    useContext(DataContratosContext)
  const { contracts, forzarRenovables, setForzarRenovables, fetchLocalContracts } = useLocalContracts(idLocal)
  const { items, requestSort, sortConfig } = useSortableData(contracts)
  const { productos, fetchProductosContrato } = useProductosContrato()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig['key'] === name ? sortConfig['direction'] : undefined
  }

  const closeModalRenovation = () => {
    setShowRenovations(false)
  }

  const closeModalHistory = () => {
    setShowHistory(false)
  }

  const closeModalCtaCte = () => {
    setShowCtaCte(false)
  }

  const closeModalLinks = () => {
    setShowLinks(false)
    setIdContrato(0)
    setPageLinks(0)
    setPageSizeLinks(INITIAL_ROWS_PER_PAGE)
  }

  const closeModalPagos = () => {
    setShowPagos(false)
    setIdContrato(0)
    setPagePagos(0)
    setPageSizePagos(INITIAL_ROWS_PER_PAGE)
  }

  const handleOpenLinks = (idContrato: number) => {
    setIdContrato(idContrato)
    setShowLinks(true)
  }

  const handleOpenPagos = (idContrato: number) => {
    setIdContrato(idContrato)
    setShowPagos(true)
  }

  const getMediosDePago = async () => {
    getMediosPago()
      .then((result) => {
        setMediosDePago(result.data.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateCtaCte = useCallback(async (idLocal: any) => {
    await getCtaCte(idLocal)
      .then((result) => {
        setResultCtaCte(
          result.data.sort((a: any, b: any) => {
            const dateA = new Date(a.fecha)
            const dateB = new Date(b.fecha)
            return dateB.getTime() - dateA.getTime()
          })
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }, [idLocal])

  useEffect(() => {
    if (props.premise) {
      setIdLocal(props.premise?.idLocal)
      fetchProductosContrato()
      getMediosDePago()
    }
  }, [props.premise, forzarRenovables])

  useEffect(() => {
    if (idLocal) {
      updateCtaCte(idLocal)
    }
  }, [idLocal])

  const updateDataContratos = (id: any) => {
    props.updateLocal(id)
    fetchLocalContracts(id, forzarRenovables)
    updateCtaCte(id)
  }

  return (
    <>
      {!isOperador && (
        <AdminLocalContractsRenovations
          contract={renewContract}
          show={showRenovations}
          handleClose={closeModalRenovation}
          getLocal={fetchLocalContracts}
          idLocal={props.premise?.idLocal}
          forzarRenovables={forzarRenovables}
          updateLocal={props.updateLocal}
        />
      )}
      <ModalTableHistorial
        show={showHistory}
        handleClose={closeModalHistory}
        headers={['Fecha', 'Estado', 'Fecha inicio', 'Fecha fin', 'Obs']}
        attributesToShow={['fecha', 'estadoNombre', 'fechaInicio', 'fechaFin', 'observaciones']}
        getUrl={`/contratos/${contractIdChecked}/historial`}
        title={'Historial'}
      />
      <ModalTableCtaCte
        show={showCtaCte}
        handleClose={closeModalCtaCte}
        headers={['ID Contrato', 'Fecha', 'Producto', 'Movimiento', 'Medio de pago', 'Comisión', 'Obs', 'Acciones']}
        idLocal={idLocal}
        result={resultCtaCte}
        title={'Cuenta Corriente'}
        updateCtaCte={updateCtaCte}
        mediosDePago={mediosDePago}
      ></ModalTableCtaCte>
      <ModalTableLinks
        show={showLinks}
        handleClose={closeModalLinks}
        headers={['ID Link', 'Fecha Alta', 'Fecha Vencimiento', 'Medio de pago', 'Fuente', 'Tiene Pagos', 'Link']}
        title={'Links'}
      ></ModalTableLinks>
      <ModalTablePagos
        show={showPagos}
        handleClose={closeModalPagos}
        headers={['Estado', 'Monto', 'Nro operación', 'Fecha', 'Mensaje Resultado', 'Historial de cambios']}
        title={'Pagos'}
      ></ModalTablePagos>

      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          alignItems: isMobile ? 'flex-start' : 'flex-end',
          justifyContent: isMobile ? 'flex-start' : 'flex-end',
          width: '98%',
          ...(isMobile && { marginLeft: '20px' })
        }}
      >
        <Box sx={{ alignSelf: 'flex-start' }}>
          {isAdmin && (
            <button
              className="btn btn-primary"
              style={{ marginRight: '5px' }}
              onClick={() => setOpenCrearContrato(true)}
            >
              Crear
            </button>
          )}
          <button
            className="btn btn-secondary premise-info-child"
            style={{ fontSize: '1rem' }}
            onClick={() => {
              setShowCtaCte(true)
            }}
          >
            Cta Cte
          </button>
        </Box>
        <Box>
          <FormControlLabel
            label="Forzar renovables"
            control={<Switch onChange={(e) => setForzarRenovables(e.target.checked)} checked={forzarRenovables} />}
          />
        </Box>
      </Box>

      <ContentTable isMobile={isMobile}>
        <table className="table table-striped table-bordered premise-table">
          <thead className="table-active">
            <tr
              style={{
                ...(isMobile && { fontSize: '14px' })
              }}
            >
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('idContrato')}
                  className={getClassNamesFor('idContrato')}
                >
                  ID
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('productoNombre')}
                  className={getClassNamesFor('productoNombre')}
                >
                  Producto
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('fechaFinFormateada')}
                  className={getClassNamesFor('fechaFinFormateada')}
                >
                  Vencimiento
                </button>
              </th>
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('estadoNombre')}
                  className={getClassNamesFor('estadoNombre')}
                >
                  Estado
                </button>
              </th>
              {!isOperador && (
                <th
                  style={{
                    ...(isMobile && { fontSize: '14px' })
                  }}
                >
                  <button
                    type="button"
                    onClick={() => requestSort('precioLista')}
                    className={getClassNamesFor('precioLista')}
                  >
                    Precio
                  </button>
                </th>
              )}
              {!isOperador && (
                <th
                  style={{
                    ...(isMobile && { fontSize: '14px' })
                  }}
                >
                  <button type="button" onClick={() => requestSort('saldo')} className={getClassNamesFor('saldo')}>
                    Saldo Pendiente
                  </button>
                </th>
              )}
              <th>
                <button
                  type="button"
                  onClick={() => requestSort('observaciones')}
                  className={getClassNamesFor('observaciones')}
                >
                  Observaciones
                </button>
              </th>
              {!isOperador && (
                <>
                  <th style={{ width: '13vmin' }}></th>
                  <th style={{ width: '13vmin' }}></th>
                  {items.some((i: ContractDTO) => {
                    return i.accionesAdminList && i.accionesAdminList.length > 0
                  }) && <th style={{ width: '13vmin' }}></th>}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {items?.map((item: ContractDTO, index) => {
              return (
                <React.Fragment key={index}>
                  <tr
                    key={index}
                    style={{
                      ...(isMobile && { fontSize: '14px' })
                    }}
                  >
                    <th>{item.idContrato}</th>
                    <th style={{ backgroundColor: `#${item.colorProducto}` }}>
                      {item.cantidad > 1 ? item.productoNombre + 'x' + item.cantidad : item.productoNombre}
                    </th>
                    <th>{item.fechaFinFormateada}</th>
                    <th style={{ backgroundColor: `#${item.colorEstado}` }}>{item.estadoNombre}</th>
                    {!isOperador && <th>{item.precioLista?.toLocaleString('es-ES')}</th>}
                    {!isOperador && <th>{item.saldo}</th>}
                    <th>{item.observaciones}</th>

                    <th style={{ padding: '4px' }}>
                      <div
                        style={{
                          marginTop: '4px',
                          display: 'flex',
                          gap: '5px'
                        }}
                      >
                        <ButtonTable
                          // disabled={!item.renovable}
                          size="small"
                          className="btn btn-secondary premise-info-child"
                          onClick={() => {
                            setContractIdChecked(item.idContrato)
                            setShowHistory(true)
                          }}
                        >
                          Historial
                        </ButtonTable>
                        <ButtonTable
                          size="small"
                          className="btn btn-secondary premise-info-child"
                          onClick={() => handleOpenLinks(item.idContrato)}
                        >
                          Links
                        </ButtonTable>
                        <ButtonTable
                          size="small"
                          className="btn btn-secondary premise-info-child"
                          onClick={() => handleOpenPagos(item.idContrato)}
                        >
                          Pagos
                        </ButtonTable>
                      </div>
                    </th>
                    <th style={{ padding: '4px' }}>
                      <ButtonChangeProduct
                        size="small"
                        className="btn btn-secondary premise-info-child"
                        onClick={() => {
                          setProductoACambiar(item)
                          setOpenCambiarProductos(true)
                        }}
                        sx={{ marginTop: '4px' }}
                      >
                        Cambiar Producto
                      </ButtonChangeProduct>
                    </th>
                    {item.accionesAdminList !== null &&
                      item.accionesAdminList !== undefined &&
                      item.accionesAdminList &&
                      item.accionesAdminList.length > 0 &&
                      !isOperador && (
                        <th style={{ padding: '4px' }}>
                          <ButtonTable
                            // disabled={
                            //   item.accionesAdminList === null ||
                            //   item.accionesAdminList === undefined ||
                            //   (item.accionesAdminList &&
                            //     item.accionesAdminList.length === 0)
                            // }
                            size="small"
                            className="btn btn-primary premise-info-child"
                            onClick={() => {
                              setShowRenovations(true)
                              setRenewContract(item)
                            }}
                          >
                            Acciones
                          </ButtonTable>
                        </th>
                      )}
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </ContentTable>
      <CrearContrato
        open={openCrearContrato}
        idLocal={props.premise?.idLocal}
        productos={productos}
        openDialog={setOpenCrearContrato}
        updateContractsData={updateDataContratos}
        mediosDePago={mediosDePago}
      />
      {productoACambiar && (
        <DialogCambiarProductos
          open={openCambiarProductos}
          productos={productos}
          idProductoActual={productoACambiar.idContratoProducto}
          openDialog={setOpenCambiarProductos}
          idContrato={productoACambiar.idContrato}
          idLocal={props.premise?.idLocal}
          updateContractsData={updateDataContratos}
          mediosDePago={mediosDePago}
        />
      )}
    </>
  )
}

export default AdminLocalContracts
