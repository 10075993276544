import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant } from "../../AbmLocalPage/types";

// Only for admin users
export const approveProduct = async (productCode: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/${productCode}/aprobar`);
export const deniedProduct = async (productCode: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/${productCode}/rechazar`);

export const getTenant = async (dominio: string) => await axiosInstanceMain.get(`/tenants?dominio=${dominio}`);
