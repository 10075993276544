import React from 'react'
import { Table, TableHead, TableBody, TableCell, TableRow, TableFooter, Button } from '@mui/material'
import { INotificaciones } from './types/Notificaciones'
import TableDataPagination from '../../pages/Products/components/TableDataPagination'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { NotificacionesProps } from './notificacionesMobile'

interface NotifDesktopProps extends NotificacionesProps {
  getClassNamesFor: (name: string) => any
  requestSort: (key: string, direction?: string) => void
}

const NotificacionesDesktop: React.FC<NotifDesktopProps> = ({
  listado,
  paginator,
  page,
  pageSize,
  totalRecord,
  calcularEstado,
  setNotificacion,
  setIsEditing,
  setOpenForm,
  getClassNamesFor,
  requestSort
}) => {
  const HEADERS = [
    { name: 'Nombre', key: 'nombre' },
    { name: 'Tipo Origen', key: 'notificacionOrigenTipo' },
    { name: 'Título', key: 'titulo' },
    { name: 'Mensaje', key: 'mensaje' },
    { name: 'Mensajes Enviados', key: 'cantMensajesEnviados' },
    { name: 'Mensajes Exitosos', key: 'cantMensajesExitosos' },
    { name: 'Fecha Inicio', key: 'fechaInicio' },
    { name: 'Fecha Fin', key: 'fechaFin' },
    { name: 'Estado', key: 'estado' },
    { name: 'Acciones', key: 'acciones' }
  ]

  return (
    <Table style={{ tableLayout: 'initial' }}>
      <TableHead
        aria-label="Notificaciones"
        sx={{
          fontWeight: 'bold',
          backgroundColor: '#e0e0e0',
          color: 'white'
        }}
      >
        <TableRow>
          {HEADERS.map((header: { name: string; key: string }) => (
            <TableCell
              key={header.key}
              sx={{
                fontWeight: 'bold',
                fontSize: '18px',
                color: 'black',
                width: '180px'
              }}
            >
              {header.key === 'estado' || header.key === 'acciones' ? (
                header.name
              ) : (
                <button type="button" onClick={() => requestSort(header.key)} className={getClassNamesFor(header.key)}>
                  {header.name}
                </button>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {listado.map((notificacion: INotificaciones, index: number) => {
          const estado = calcularEstado(notificacion)
          const fechaInico = new Date(notificacion.fechaInicio)
          const fechaFin = new Date(notificacion.fechaFin)
          return (
            <TableRow key={index}>
              <TableCell sx={{ width: '180px' }}>{notificacion.nombre}</TableCell>
              <TableCell sx={{ width: '180px' }}>{notificacion.notificacionOrigenTipo}</TableCell>
              <TableCell sx={{ width: '180px' }}>{notificacion.titulo}</TableCell>
              <TableCell sx={{ width: '180px' }}>{notificacion.mensaje}</TableCell>
              <TableCell sx={{ width: '180px', textAlign: 'center' }}>
                {notificacion.cantMensajesEnviados.toLocaleString('es-ES')}
              </TableCell>
              <TableCell sx={{ width: '180px', textAlign: 'center' }}>
                {notificacion.cantMensajesExitosos.toLocaleString('es-ES')}
              </TableCell>
              <TableCell sx={{ width: '180px' }}>
                {notificacion.fechaInicio && format(fechaInico, 'dd/MM/yyyy, HH:mm', { locale: es })}
              </TableCell>
              <TableCell sx={{ width: '180px' }}>
                {notificacion.fechaFin && format(fechaFin, 'dd/MM/yyyy, HH:mm', { locale: es })}
              </TableCell>
              <TableCell sx={{ width: '180px' }}>
                {`${estado.nombre} `}
                {estado.icono}
              </TableCell>
              <TableCell sx={{ width: '180px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsEditing(true)
                      setNotificacion(notificacion)
                      setOpenForm(true)
                    }}
                  >
                    Editar
                  </Button>{' '}
                  <Button
                    variant="contained"
                    onClick={() => {
                      setNotificacion(notificacion)
                      setOpenForm(true)
                    }}
                  >
                    Duplicar
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableDataPagination
            total={totalRecord || 0}
            page={page || 0}
            paginator={paginator}
            items={listado || []}
            rowsPerPage={pageSize || 100}
            component={'td'}
            label={`Notificaciones por página`}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default NotificacionesDesktop
