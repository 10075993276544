import { axiosInstanceIntegraciones } from '../../../utils/axiosGlobalUtils'
import { AxiosResponse } from 'axios'
import { Novedad, NovedadesResponse } from '../types/responses-novedades'

export const GetNovedades = async (codLocal: string, idCategoria: number | string = '', searchText = '') =>
  (await axiosInstanceIntegraciones.get(
    `/${codLocal}/productos/novedades?idCategoria=${idCategoria}&searchText=${searchText}`
  )) as AxiosResponse<NovedadesResponse>

export const UpdateNovedad = async (codLocal: string, novedad: Partial<Novedad>) =>
  (await axiosInstanceIntegraciones.put(`/${codLocal}/productos/novedades`, novedad)) as AxiosResponse<any>

export const RejectNovedad = async (codLocal: string, int_externalId: number) =>
  (await axiosInstanceIntegraciones.delete(`/${codLocal}/productos/novedades`, {
    data: [{ int_externalId }]
  })) as AxiosResponse<any>

export const UpdateNovedades = async (codLocal: string, novedad: Partial<Novedad>[]) =>
  (await axiosInstanceIntegraciones.put(`/${codLocal}/productos/novedades`, novedad)) as AxiosResponse<any>

export const RejectNovedades = async (codLocal: string, int_externalId: number[]) =>
  (await axiosInstanceIntegraciones.delete(`/${codLocal}/productos/novedades`, {
    data: int_externalId
  })) as AxiosResponse<any>
