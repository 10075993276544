import { Box, Divider, Grid, Typography } from "@mui/material";
import { caracteristicasType } from "../../types";
import { Check, Close } from "@mui/icons-material";
import SectionTitle from "../../common/SectionTitle";
import { useEffect, useState } from "react";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

interface SoloLecturaCaracteristicasProps {
  local: LocalVendedorDTO;
  caracteristicas: caracteristicasType[];
}

const SoloLecturaCaracteristicas = ({
  local,
  caracteristicas,
}: SoloLecturaCaracteristicasProps) => {
  const [checked, setChecked] = useState<caracteristicasType[]>([]);
  const [unchecked, setUnchecked] = useState<caracteristicasType[]>([]);

  const getCheckedOrUnchecked = () => {
    const checked: caracteristicasType[] = [];
    const unchecked: caracteristicasType[] = [];

    caracteristicas.forEach((c) => {
      if (
        local.caracteristicasList.some(
          (car) => car.id === c.caracteristicaLocalId && car.activo
        )
      ) {
        checked.push(c);
      } else {
        unchecked.push(c);
      }
    });

    setChecked(checked);
    setUnchecked(unchecked);
  };

  useEffect(() => {
    if (local) getCheckedOrUnchecked();
  }, [local]);

  return (
    <Grid container spacing={2}>
      <SectionTitle title="Características" />
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            textAlign: "center",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              gap: "20px",
            },
          }}
        >
          {/* Checked - (Siempre va a haber al menos una chequeada [Mayorista, Minorista]) */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Check sx={{ color: "green" }} fontSize="large" />
            {checked.map((c, i) => (
              <Typography
                key={i}
                variant="body1"
                sx={{ whiteSpace: "pre-line" }}
              >
                {c.nombre}
              </Typography>
            ))}
            {local.compraMinimaParaEnvio && (
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "180px",
                  "@media (max-width: 768px)": { maxWidth: "100%" },
                }}
              >
                {`(Mínimo de compra para envío: ${local.compraMinimaParaEnvio})`}
              </Typography>
            )}
          </Box>

          {/* Unchecked */}
          {unchecked.length > 0 && (
            <Box>
              <Close sx={{ color: "red" }} fontSize="large" />
              {unchecked.map((c, i) => (
                <Typography
                  key={i}
                  variant="body1"
                  sx={{ whiteSpace: "pre-line" }}
                >
                  {c.nombre}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SoloLecturaCaracteristicas;
