import { useState, ChangeEvent, useContext } from 'react'
import * as FaIcons from 'react-icons/fa'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { Box } from '@mui/system'
import { axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import DesplegableRubros from './DesplegableRubros'
import { ProductosAdminContext } from '../context/productos.context'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TenantsContext } from '../../../context/tenants.context'
import { Dayjs } from 'dayjs'
import { Rubro } from '../../Integraciones/types/responses-productos'

const AdminAbmProductosFiltros = ({ rubros }: { rubros: Rubro[] }) => {
  const { setFiltros, setSearching, loading: loadingProductos } = useContext(ProductosAdminContext)
  const [autoCompletado, setAutoCompletado] = useState<string[]>([])

  // Form
  const [codLocal, setCodLocal] = useState<string>(null)
  const [codRubro, setCodRubro] = useState<string>(null)
  const [nombreProducto, setNombreProducto] = useState<string>('')
  const [soloActivos, setSoloActivos] = useState<boolean>(true)
  const [soloMalCatalogados, setSoloMalCatalogados] = useState<boolean>(false)
  const [publicadoDesde, setPublicadoDesde] = useState<Dayjs | null>(null)
  const [publicadoHasta, setPublicadoHasta] = useState<Dayjs | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { tenant } = useContext(TenantsContext)

  const handleAutoCompletado = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value.length < 3) return

    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/autocompletado`, { valor: value })
      .then((result) => {
        setAutoCompletado(result.data.data)
      })
      .catch((error) => {
        console.log('error: ', error)
      })
  }

  const handleFormSubmit = () => {
    setSearching(true)
    const fechaDesde = publicadoDesde?.format('YYYY-MM-DD')
    const fechaHasta = publicadoHasta?.format('YYYY-MM-DD')
    setFiltros({
      codLocal,
      codRubro,
      nombreProducto,
      soloActivos,
      soloMalCatalogados,
      publicadoDesde: fechaDesde,
      publicadoHasta: fechaHasta
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        padding: '10px',
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
        marginBottom: '10px',
        gap: '8px',

        // display column en mobile
        '@media (max-width: 768px)': {
          flexDirection: 'column',
          gap: '5px'
        }
      }}
    >
      <FormControl sx={{ width: '300px', '@media (max-width: 768px)': { width: '100%' } }}>
        <DesplegableRubros
          rubros={rubros}
          onChange={(r: any) => {
            setCodRubro(r?.codRubro || null)
          }}
          handleFormSubmit={handleFormSubmit}
        />
      </FormControl>

      <FormControl sx={{ width: '300px', '@media (max-width: 768px)': { width: '100%' } }}>
        <Autocomplete
          options={autoCompletado}
          disabled={loadingProductos}
          getOptionLabel={(option: any) => option.nombre}
          onChange={(e, value: any) => setCodLocal(value?.data || null)}
          renderInput={(p) => (
            <TextField
              {...p}
              onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit()}
              label="Local"
              variant="outlined"
              onChange={handleAutoCompletado}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ width: '250px', '@media (max-width: 768px)': { width: '100%' } }}>
        <TextField
          disabled={loadingProductos}
          label="Producto"
          variant="outlined"
          onKeyPress={(e) => e.key === 'Enter' && handleFormSubmit()}
          onChange={(e) => setNombreProducto(e.target.value)}
        />
      </FormControl>

      <FormControl
        sx={{
          width: '220px',
          '@media (max-width: 768px)': { width: '100%' }
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={loadingProductos}
            label={isMobile ? 'Publicado Desde' : 'Public. Desde'}
            format="DD/MM/YYYY"
            value={publicadoDesde}
            onChange={(date: any) => {
              date ? setPublicadoDesde(date) : setPublicadoDesde(null)
            }}
            slotProps={{
              textField: {
                variant: 'outlined',
                onKeyPress: (e) => e.key === 'Enter' && handleFormSubmit(),
                value: publicadoDesde
              }
            }}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        sx={{
          width: '220px',
          '@media (max-width: 768px)': { width: '100%' }
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={loadingProductos}
            label={isMobile ? 'Publicado Hasta' : 'Public. Hasta'}
            format="DD/MM/YYYY"
            value={publicadoHasta}
            onChange={(date: any) => (date ? setPublicadoHasta(date) : setPublicadoHasta(null))}
            slotProps={{
              textField: {
                variant: 'outlined',
                onKeyPress: (e) => e.key === 'Enter' && handleFormSubmit(),
                value: publicadoHasta
              }
            }}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        sx={{
          width: '180px',
          '@media (max-width: 768px)': { width: '100%', m: 0 }
        }}
      >
        <FormControlLabel
          className="m-auto"
          label="Solo Mal Catalogados"
          control={<Checkbox sx={{ paddingLeft: 0 }} />}
          disabled={loadingProductos}
          onChange={(e: any) => setSoloMalCatalogados(e.target.checked)}
        />
      </FormControl>
      <FormControl
        sx={{
          '@media (max-width: 768px)': { width: '100%', m: 0 }
        }}
      >
        <FormControlLabel
          className="m-auto"
          label="Solo Activos"
          control={<Checkbox defaultChecked sx={{ paddingLeft: 0 }} />}
          disabled={loadingProductos}
          onChange={(e: any) => setSoloActivos(e.target.checked)}
        />
      </FormControl>

      <FormControl
        sx={{
          width: '80px',
          paddingRight: '10px',
          '@media (max-width: 768px)': {
            width: '100%'
          }
        }}
      >
        <Button
          disabled={loadingProductos}
          variant="contained"
          onClick={handleFormSubmit}
          sx={{
            height: '50px',
            '@media (max-width: 768px)': {
              display: 'flex',
              gap: '10px'
            }
          }}
        >
          <Box
            sx={{
              display: 'none',
              '@media (max-width: 768px)': {
                display: 'block'
              }
            }}
          >
            Filtrar
          </Box>
          <FaIcons.FaSearch style={{ margin: 0 }} />
        </Button>
      </FormControl>
    </Box>
  )
}

export default AdminAbmProductosFiltros
