import { memo, useCallback, useContext, useState } from 'react'
import { IProductoTienda } from '../../../../types/responses-productos'
import { Box, Button, Card, CardContent, IconButton } from '@mui/material'
import DialogConfirmProductoAA1T from '../AA1T/DialogConfirmProductoAA1T'
import ProductoBodyWoo from './SingleProducto/ProductoBodyWoo'
import ProductoImageWoo from './SingleProducto/ProductoImageWoo'
import ProductoAtributosWoo from './SingleProducto/ProductoAtributosWoo'
import { IntegracionesContext } from '../../../../context/integraciones.context'
import { GoTrash } from 'react-icons/go'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

type Actions = 'agregar' | 'eliminar' | 'nada'

interface Props {
  producto: IProductoTienda
  action: Actions
}

const SingleProductoTienda = ({ producto, action }: Props) => {
  const { handleProductosAConfirmarAction } = useContext(IntegracionesContext)
  const [openConfirmProductDialog, setOpenConfirmProductDialog] = useState(false)
  const [productoAA1T, setProductoAA1T] = useState<IProductoTienda | null>(null)

  const setConfirmProductDialog = useCallback((producto: IProductoTienda) => {
    setOpenConfirmProductDialog(true)
    setProductoAA1T(producto)
  }, [])

  const handleCloseDialogConfirmProductAA1T = useCallback(() => {
    setOpenConfirmProductDialog(false)
    setProductoAA1T(null)
  }, [])
  const handleRemoveItem = useCallback(() => {
    handleProductosAConfirmarAction(producto)
  }, [producto])

  function ButtonAction({ action }: { action: Actions }) {
    let button = null
    if (action === 'nada') return null

    if (action === 'eliminar')
      button = (
        <IconButton
          onClick={handleRemoveItem}
          sx={{ borderRadius: '8px', border: '2px solid #5983EF', color: '#5983EF' }}
        >
          <GoTrash fontSize={24} />
        </IconButton>
      )

    if (action === 'agregar')
      button = (
        <ButtonRounded sx={{ px: '16px' }} onClick={() => setConfirmProductDialog(producto)}>
          Agregar
        </ButtonRounded>
      )

    return <Box>{button}</Box>
  }

  if (!producto.nombreProducto) return null
  return (
    <Card
      sx={{ display: 'flex', pl: '16px', minHeight: '150px', bgcolor: '#FDFDFE', boxShadow: '0px 0px 2px #0000005a' }}
    >
      <ProductoImageWoo producto={producto} />
      <CardContent sx={{ overflow: 'hidden', width: '100%' }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          {/* Nombre, categoria, precio */}
          <ProductoBodyWoo producto={producto} />

          {/* Acciones */}
          <ButtonAction action={action} />
        </Box>

        {/* Atributos */}
        <ProductoAtributosWoo producto={producto} />
      </CardContent>

      {productoAA1T && productoAA1T.nombreProducto && (
        <DialogConfirmProductoAA1T
          open={openConfirmProductDialog}
          producto={productoAA1T}
          handleClose={handleCloseDialogConfirmProductAA1T}
        />
      )}
    </Card>
  )
}

export default memo(SingleProductoTienda)
