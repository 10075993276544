import Navbar from '../../components/Navbar'
import { useCallback, useContext, useEffect, useState } from 'react'
import { IntegracionesContext } from './context/integraciones.context'
import IntegracionesMain from './components/IntegracionesMain/IntegracionesMain'
import IntegracionesLoading from './components/IntegracionesLoading'
import { useAppStore } from '../../store/app-store'
import Onboarding from './components/Onboarding/Onboarding'
import SeleccionTienda from './components/SeleccionTienda'
import { ITienda } from './types'
import './index.css'

const Integraciones = () => {
  const codLocal = useAppStore((state) => state.codLocal)
  const [loading, setLoading] = useState(false)

  const {
    tiendaSeleccionada,
    completoOnboarding,
    getProductsTienda,
    getNovedades,
    getTiendaStatus,
    setCompletoOnboarding,
    setTiendaSeleccionada
  } = useContext(IntegracionesContext)

  const startIntegraciones = useCallback(
    async (tienda: ITienda) => {
      if (!tienda || !codLocal) return
      try {
        const [estadoTiendaResponse] = await Promise.all([
          getTiendaStatus(codLocal),
          getNovedades(codLocal),
          getProductsTienda(codLocal)
        ])

        setCompletoOnboarding(!!estadoTiendaResponse?.data?.data)
        if (estadoTiendaResponse?.status === 200) setLoading(false)
      } catch (error) {
        throw new Error('Error al inicializar las integraciones', error)
      }
    },
    [codLocal]
  )

  useEffect(() => {
    const loadTiendaSeleccionada = async () => {
      setLoading(true)

      const tiendaData = localStorage.getItem('tiendaSeleccionada')
      const tienda = tiendaData ? JSON.parse(tiendaData) : null

      setTiendaSeleccionada(tienda)
      await startIntegraciones(tienda)
      setLoading(false)
    }

    loadTiendaSeleccionada()
  }, [startIntegraciones])

  return (
    <>
      <Navbar title="Integraciones" />
      {loading ? (
        <IntegracionesLoading />
      ) : !tiendaSeleccionada ? (
        <SeleccionTienda />
      ) : !completoOnboarding ? (
        <Onboarding />
      ) : (
        <IntegracionesMain />
      )}
    </>
  )
}

export default Integraciones
