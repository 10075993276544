import { onMessage } from "firebase/messaging";
import { generarToken, messaging, requestPermission } from "../../firebase";
import SnackBarUtils from "../../utils/SnackBarUtils";
import { autoRegistrarToken } from "../../services/firebase.service";
// import AceptaNotificaciones from "../../components/Notificaciones/AceptaNotificaciones";
import { NotificationType } from "../../utils/notificationType";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import PreDialogNotificaciones from "../../components/Notificaciones/PreDialogNotificaciones";
import { RolType } from "../Navbar/Sidebar/SidebarItems";
import { TenantsContext } from "../../context/tenants.context";

const Notificaciones = (props: any) => {
  const { userLogged } = props.logged;
  const userAgent = navigator.userAgent;
  const [aceptoNotificaciones, setAceptoNotificaciones] = useState(false);
  const [preDialogNotificaciones, setPreDialogNotificaciones] = useState(false);
  const { tenant } = useContext(TenantsContext);

  useEffect(() => {
    (async () => {
      if (/iPad|iPhone|iPod/.test(userAgent)) return setAceptoNotificaciones(false);

      setPreDialogNotificaciones(Notification.permission === "default");

      if (!userLogged.isLogged || !userLogged.roles.includes(RolType.USER)) {
        setAceptoNotificaciones(true);
        setPreDialogNotificaciones(false);
        return true;
      }

      setPreDialogNotificaciones(Notification.permission === "default");

      if (aceptoNotificaciones && Notification.permission === "default") {
        const approve = await requestPermission();
        if (!approve) return setAceptoNotificaciones(false);
      } else {
        return;
      }

      setAceptoNotificaciones(true);

      const { token, imei } = await generarToken(userLogged, tenant);

      if (!token) return;
      await autoRegistrarToken(userLogged.codLocal, imei, tenant);

      onMessage(messaging, (payload) => {
        if (payload.data?.notificationType === NotificationType.APROBADO) {
          SnackBarUtils.success(payload.data?.body || "Producto aprobado");
        } else if (payload.data?.notificationType === NotificationType.RECHAZADO) {
          SnackBarUtils.error(payload.data?.body || "Producto rechazado");
        }
      });
    })();
  }, [userLogged, preDialogNotificaciones, aceptoNotificaciones]);

  const onClose = () => {
    setPreDialogNotificaciones(false);
    setAceptoNotificaciones(true);
  };

  // if (!aceptoNotificaciones) return <AceptaNotificaciones />;

  return (
    <>
      <PreDialogNotificaciones
        open={preDialogNotificaciones && userLogged.isLogged && userLogged.roles.includes("vendedor")}
        onClose={onClose}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
  };
};

export default connect(mapStateToProps)(Notificaciones);
