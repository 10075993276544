import { useContext } from 'react'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import Contactos from './components/Contactos/Contactos'
import Conversacion from './components/Conversacion/Conversacion'
import { WhatsappContext } from './context/whatsapp.context'
import Navbar from '../../components/Navbar'
import Footer from '../Home/components/Footer'

const AdminWspBotPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { contactoSeleccionado } = useContext(WhatsappContext)

  return (
    <>
      <Navbar title="Whatsapp" />

      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={3}
            hidden={isMobile && contactoSeleccionado.id !== 0}
          >
            <Contactos />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            hidden={isMobile && contactoSeleccionado.id === 0}
          >
            <Conversacion />
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default AdminWspBotPage
