import { FC, Fragment, useContext } from 'react'
import { IntegracionesContext } from '../../../context/integraciones.context'
import SingleNovedad from './Novedad/SingleNovedad'
import { Box, Divider, Typography } from '@mui/material'

interface Props {
  reject: (id: number) => void
}

const ListadoNovedades: FC<Props> = ({ reject }) => {
  const { novedades } = useContext(IntegracionesContext)

  if (!novedades) return <Typography variant="h6">No hay novedades</Typography>

  return (
    <Box display="flex" flexDirection="column" gap={2} bgcolor="#FDFDFE" borderRadius="8px">
      {novedades.map((novedad) => (
        <Fragment key={novedad.productoInterno.idproducto}>
          <SingleNovedad novedad={novedad} reject={reject} />
          <Divider color="black" />
        </Fragment>
      ))}
    </Box>
  )
}

export default ListadoNovedades
