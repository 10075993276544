import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material'
import { memo, useContext, useState, useEffect } from 'react'
import { OfrecerPremiumContext } from './ofrecerPremium.context'
import { connect } from 'react-redux'
import { Close } from '@mui/icons-material'
import { useLocal } from '../../pages/AbmLocalPage/hooks/local.hook'
import { styled } from '@mui/material/styles'
import { TenantsContext } from '../tenants.context'
import { IAnuncio, useAnuncios } from '../../pages/Home/hooks/anuncios.hook'
import { Config } from '@redux-devtools/extension'

const Cabecera = (props: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'start',
        height: '130px',
        width: '100%',
        bgcolor: props.header_bgcolor,
        backgroundImage: `url(${props.header_img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center'
      }}
    >
      <IconButton
        onClick={props.onClose}
        sx={{
          color: (theme) => theme.palette.grey[100]
        }}
      >
        <Close />
      </IconButton>
    </Box>
  )
}

interface PageProps {
  page: number
}

const Page1 = styled('img')<PageProps>(({ page }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  transform:
    page === 1
      ? window.innerWidth < 600
        ? 'translateX(50%)'
        : 'translateX(46%)'
      : 'translateX(-200%)',
  transition: 'transform 0.3s ease-in-out'
}))

const Page2 = styled('img')<PageProps>(({ page }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  transform:
    page === 1
      ? 'translateX(100%)'
      : window.innerWidth < 600
      ? 'translateX(-50%)'
      : 'translateX(-56%)',
  transition: 'transform 0.3s ease-in-out',
  alignSelf: 'flex-end'
}))

interface PreOfrecerPremiumDialogProps {
  text?: string
  header_img?: string
  header_bgcolor?: string
}

const PreOfrecerPremiumDialog = ({
  text,
  header_img,
  header_bgcolor
}: PreOfrecerPremiumDialogProps) => {
  const { openPre, onClosePre, onOpen } = useContext(OfrecerPremiumContext)

  return (
    <Dialog open={openPre} onClose={onClosePre} fullWidth>
      <Cabecera
        onClose={onClosePre}
        header_img={header_img}
        header_bgcolor={header_bgcolor}
      />
      <DialogContent>
        <Typography variant="h6" fontWeight={'bold'}>
          {text || ''}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOpen} color="success">
          ¡Quiero mejorar mi exposición!
        </Button>
        <Button onClick={onClosePre}>Lo tendré en cuenta</Button>
      </DialogActions>
    </Dialog>
  )
}

const OfrecerPremiumDialog = (props: any) => {
  const { open, onClose, openPre } = useContext(OfrecerPremiumContext)
  const { codLocal } = props.logged.userLogged;
  const { local, loading } = useLocal(codLocal)
  const [page, setPage] = useState(1)
  const [ofrecerPremium, setOfrecerPremium] = useState<boolean>(false)
  const { tenant } = useContext(TenantsContext)
  const { anuncios } = useAnuncios(codLocal)
  const idBannerConfig = tenant.config?.find(
    (c: Config) => c.name === 'anuncio_premiumVendedor'
  )?.value
  const bannerPremium = anuncios?.find(
    (a: IAnuncio) => a.idAnuncio === parseInt(idBannerConfig)
  )

  const handleClick = () => {
    if (page === 1) setPage(2)
    else {
      const wsp = local?.config.find(
        (c) => c.name === 'whatsappContratos'
      )?.value
      window.open(
        `https://wa.me/${wsp}?text=Hola, solicito más información sobre las cuentas Premium`,
        '_blank'
      )
    }
  }

  const handleClose = () => {
    setPage(1)
    onClose()
  }

  useEffect(() => {
    const show = local?.config?.find(
      (c: any) => c.name === 'premium_ofrecerPremium'
    )?.value
    setOfrecerPremium(show === 'true' ? true : false)
  }, [local])

  if (loading || !local || !ofrecerPremium || !bannerPremium?.detalleImagenList) return null

  if (openPre)
    return (
      <PreOfrecerPremiumDialog
        header_img={
          local.config.find(
            (c: any) => c.name === 'premium_bannerFullScreen_header_img'
          )?.value
        }
        header_bgcolor={
          local.config.find(
            (c: any) => c.name === 'premium_bannerFullScreen_header_bgcolor'
          )?.value
        }
        text={
          local.config.find(
            (c: any) => c.name === 'premium_ABMproductos_postText'
          )?.value
        }
      />
    )

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent sx={{ padding: 0 }}>
        <Cabecera
          header_img={
            local.config.find(
              (c: any) => c.name === 'premium_bannerFullScreen_header_img'
            )?.value
          }
          header_bgcolor={
            local.config.find(
              (c: any) => c.name === 'premium_bannerFullScreen_header_bgcolor'
            )?.value
          }
          onClose={handleClose}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 350px)',
            overflowX: 'hidden',
            padding: 3
          }}
        >
          <Page1
            src={bannerPremium?.detalleImagenList[0]?.url || ""}
            page={page}
            alt="Premium Avellaneda a Un Toque"
          />
          <Page2
            src={bannerPremium?.detalleImagenList[1]?.url || ""}
            page={page}
            alt="Premium Avellaneda a Un Toque"
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClick}
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
        >
          {page === 1 ? 'Siguiente' : 'Contactanos'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

export default connect(mapStateToProps)(memo(OfrecerPremiumDialog))
