import React, { useState } from 'react'
import * as GrIcons from 'react-icons/gr'
import { Button, styled } from '@mui/material'
import { format } from 'date-fns'
import { deleteCtaCte } from '../services/cta.cte.services'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import '../../../styles/contractsRenovations.scss'
import EditarMovimiento from './editarMovimiento'
import { MedioPago } from '../../../dto/ContractDTO'
import ConfirmDialog from '../../../pages/Products/components/ConfirmDialog'

const ContentTable = styled('div')({
  width: '97%'
})

// const Line = styled.div`
//   width: 97%;
//   border: 1px solid;
//   display: flex;
//   gap: 6px;
//   margin-top: 8px;
//   border-radius: 5px;
// `;

// const Celda = styled.div`
//   /* width: 97%; */
//   /* border: 1px solid; */
// `;

// interface UpIconParams {
//   className?: string;
//   color?: string;
// }

interface Props {
  getUrl?: string
  handleClose?: any
  headers?: string[]
  show?: boolean
  title?: string
  idLocal: string
  result: any[]
  updateCtaCte: (idLocal) => void
  mediosDePago: MedioPago[]
}

const ModalTable: React.FC<Props> = (props: Props) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [editMovimiento, setEditMovimiento] = useState<any>(null)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  const showHideClassName = props.show ? 'modal display-block' : 'modal display-none'

  const closeRenovations = () => {
    // setActions([]);
    props.handleClose()
  }

  const handleDelete = async (idProductoContrato: number, idLocal: number) => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Deseas eliminar este movimiento?',
        body: '',
        leftButton: 'Cancelar',
        rightButton: 'Eliminar',
        colorButtonConfirm: 'error'
      },
      action: async () => {
        await deleteCtaCte(idProductoContrato)
          .then((res) => {
            SnackBarUtils.success(res.data.message)
            props.updateCtaCte(idLocal)
          })
          .catch((e) => console.log(e))
          .finally(() => {
            clearConfirmDialog()
          })
      },
      close: () => clearConfirmDialog()
    })
  }

  // const executeAction = (url: string) => {
  //   const idContrato = url
  //     .substring(url.indexOf("contratos"), url.length)
  //     .split("/")[1];
  //   axiosInstanceMain
  //     .get(url)
  //     .then((result) => {
  //       window.alert(result.data);
  //       if (result.status === 200) {
  //         props.setNoShowContracts([
  //           ...props.noShowContracts,
  //           Number.parseInt(idContrato),
  //         ]);
  //       }
  //       closeRenovations();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  return (
    <div
      className={showHideClassName}
      // onClick={closeRenovations}
    >
      <section className="modal-main" style={{ marginTop: '40px', height: '700px', overflow: 'auto' }}>
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">
            {/* {(props.contract?.cantidad > 1
              ? props.contract?.productoNombre + "x" + props.contract?.cantidad
              : props.contract?.productoNombre) +
              ` Local ID ${props.contract?.idContratoClienteLocal}`} */}
            {props.title !== undefined && props.title}
          </h3>
          <button className="modal-close" onClick={closeRenovations} aria-label="Cerrar">
            <GrIcons.GrClose />
          </button>
        </header>
        <div>
          {/* {isMobile ? (
            <div className="btn-toolbar" role="toolbar">
              {result.map((a: any) => {
                return (
                  <Line>
                    {props.headers.map((h: any, index: number) => {
                      return (
                        <Celda>
                          <p>
                            {h}: {a[props.attributesToShow[index]]}
                          </p>
                        </Celda>
                      );
                    })}
                  </Line>
                );
              })}
            </div>
          ) : ( */}
          <ContentTable>
            <table className="table table-striped table-bordered premise-table">
              <thead className="table-active">
                <tr>
                  {props.headers &&
                    props.headers.map((h: any, index) => {
                      return (
                        <th key={index}>
                          <button
                            type="button"
                            // onClick={() => requestSort("idContrato")}
                            // className={getClassNamesFor("idContrato")}
                          >
                            {h}
                          </button>
                        </th>
                      )
                    })}
                </tr>
              </thead>
              <tbody>
                {props.result.map((item: any, index) => {
                  const fecha = new Date(item.fechaFormateada)
                  const formatDate = format(fecha, 'dd/MM/yyyy HH:mm:ss')
                  return (
                    <tr key={item.idContratoCuentaCorriente}>
                      <th>{item.idContrato}</th>
                      <th>{formatDate}</th>
                      <th>{item.productoNombre}</th>
                      <th>{item.movimientoTipo + ' ' + item.movimiento}</th>
                      <th>{item.medioDePago}</th>
                      <th>{item.comisionMedioDePago}</th>
                      <th>{item.observaciones != null ? item.observaciones : '-'}</th>
                      <th style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setEditMovimiento(item)
                            setEdit(true)
                          }}
                        >
                          Editar
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handleDelete(item.idContratoCuentaCorriente, item.idClienteLocal)
                          }}
                        >
                          Borrar
                        </Button>
                      </th>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </ContentTable>
          {/* )} */}
        </div>
      </section>
      {editMovimiento && (
        <EditarMovimiento
          open={edit}
          setOpen={setEdit}
          idMovimiento={editMovimiento.idContratoCuentaCorriente}
          item={editMovimiento}
          setItem={setEditMovimiento}
          mediosDePago={props.mediosDePago}
          updateCtaCte={props.updateCtaCte}
        />
      )}
      <ConfirmDialog {...confirmDialog} />
    </div>
  )
}

export default ModalTable
