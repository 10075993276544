import React, { useEffect, useState } from "react";
import "../../styles/contractsRenovations.scss";
import { axiosInstanceContratos, axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import * as GrIcons from "react-icons/gr";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { IHistorialContrato } from "../../dto/ContractDTO";
import CardsHistorial from "../ContractsList/historial/cardsHistorial";

const ContentTable = styled("div")({
  width: "97%",
});

interface Props {
  getUrl?: string;
  handleClose?: any;
  headers?: string[];
  show?: string;
  title?: string;
  attributesToShow?: string[];
}

const ModalTableHistorial: React.FC<any> = (props: Props) => {
  const [result, setResult] = useState<IHistorialContrato[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const showHideClassName = props.show ? "modal display-block" : "modal display-none";

  useEffect(() => {
    if (props && props.getUrl && !props.getUrl.includes("undefined")) {
      axiosInstanceContratos
        .get(props.getUrl)
        .then((result) => {
          setResult(result.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props]);

  const closeRenovations = () => {
    props.handleClose();
  };

  return (
    <div
      className={showHideClassName}
      // onClick={closeRenovations}
    >
      <section className="modal-main" style={{ height: "98%", overflow: "auto" }}>
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">
            {/* {(props.contract?.cantidad > 1
              ? props.contract?.productoNombre + "x" + props.contract?.cantidad
              : props.contract?.productoNombre) +
              ` Local ID ${props.contract?.idContratoClienteLocal}`} */}
            {props.title !== undefined && props.title}
          </h3>
          <button className="modal-close" onClick={closeRenovations} aria-label="Cerrar">
            <GrIcons.GrClose />
          </button>
        </header>
        <div>
          {isMobile ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CardsHistorial historial={result} listado={false} />
            </div>
          ) : (
            <ContentTable>
              <table className="table table-striped table-bordered premise-table">
                <thead className="table-active">
                  <tr>
                    {props.headers &&
                      props.headers.map((h: any, index) => {
                        return (
                          <th key={index}>
                            <button
                              type="button"
                              // onClick={() => requestSort("idContrato")}
                              // className={getClassNamesFor("idContrato")}
                            >
                              {h}
                            </button>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {[...result].reverse().map((item: IHistorialContrato, index) => {
                    const fecha = new Date(item.fecha);
                    const fechaInicio = new Date(item.fechaInicio);
                    const fechaFin = new Date(item.fechaFin);
                    return (
                      <tr key={index}>
                        <th>{fecha.toLocaleString()}</th>
                        <th>{item.estadoNombre}</th>
                        <th>{fechaInicio.toLocaleDateString()}</th>
                        <th>{fechaFin.toLocaleDateString()}</th>
                        <th>{item.observaciones}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ContentTable>
          )}
        </div>
      </section>
    </div>
  );
};

export default ModalTableHistorial;
