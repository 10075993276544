import { Box, TextField } from '@mui/material'
import { PasosProps } from './RecuperarPassword'
import { createRef, useRef, useState } from 'react';

const Paso2 = ({isSubmitting, isValid, setValue, handleConfirm}: PasosProps) => {
  const [codigo, setCodigo] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef()
  ]);

  const handleInputChange = (event: any, index: number) => {
    const value = event.target.value
    if (isNaN(value)) return

    // Si hay valor y el index es menor a 5, establecer el foco en el siguiente input
    if (value.length === 1 && index < (codigo.length - 1)) {
      const current = inputRefs.current[index + 1].current as any
      current.focus()
    }

    const newCodigo = [...codigo];
    newCodigo[index] = value;
    setCodigo(newCodigo);
    setValue(newCodigo.join(""));

  };

  const handlePaste = (event: any) => {
    const pasteData = event.clipboardData.getData("text/plain");
    if ((pasteData.length === codigo.length) && /^[0-9]+$/.test(pasteData)) {
      const newCodigo = pasteData.split("");
      setCodigo(newCodigo);
      setValue(newCodigo.join(""));
      // Establecer el foco en el ultimo input y enviar el codigo
      const current = inputRefs.current[codigo.length - 1].current as any
      current.focus();
      handleConfirm()
      event.preventDefault();
    }
  };

  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px', width: '100%', marginY: '20px'}}>
        {
          codigo.map((item, index) => {
            return (
              <TextField
                key={index}
                inputRef={inputRefs.current[index]}
                value={item}
                onChange={e => handleInputChange(e, index)}
                onKeyPress={e => e.key === "Enter" && handleConfirm()}
                onPaste={handlePaste}
                autoFocus={index === 0}
                onFocus={(e) => e.target.select()}
                disabled={isSubmitting}
                sx={{width: 50}}
                inputProps={{maxLength: 1, style: {textAlign: 'center', fontSize: '25px'}}}
                color={isValid ? "primary" : "error"}
              />
            )
          })
        }
      </Box>
    </>
  )
}

export default Paso2
