import React from "react";
import { useTheme, useMediaQuery } from "@mui/material";

const ComboBox: React.FC<any> = (props: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <label className={props.styles} htmlFor={props.htmlFor}>
        {props.label}
      </label>
      <select
        className={props.styles}
        value={props.value}
        onChange={props.onChange}
        style={{ ...(isMobile && { maxWidth: "90%" }) }}
      >
        {props.options.map((o: any, index: any) => {
          return (
            <option key={index} value={o.value}>
              {o.name}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default ComboBox;
