import { Box, Grid, TextField } from "@mui/material";
import { postLocalType } from "../../types";
import { FieldErrors, UseFormRegister, UseFormSetError, UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { Asterisco, MAX_LENGTH_DESC_WSP } from ".";
import { validarWsp } from "../../services/validar.service";
import AlertDialog from "../../../../components/Common/Dialog";
import SectionTitle from "../../common/SectionTitle";
import RecuperarPassword from "../../../Login/RecuperarPassword/RecuperarPassword";
import { IPais } from "../../types";
import { useDataPais } from "../../hooks/dataPais.hook";
import { TenantsContext } from "../../../../context/tenants.context";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

interface FormMedioDeContactosProps {
  register: UseFormRegister<postLocalType>;
  errors: FieldErrors<postLocalType>;
  local?: LocalVendedorDTO;
  whatsapps: { tipo: number; valor: string; descripcion: string }[];
  setWhatsapps: (whatsapps: { tipo: number; valor: string; descripcion: string }[]) => void;
  setValue: UseFormSetValue<postLocalType>;
  isSubmitting: boolean;
  props: any;
  setError: UseFormSetError<postLocalType>;
  clearErrors: UseFormClearErrors<postLocalType>;
  refWhatsapp: any;
  pais: IPais | undefined;
}

const FormMedioDeContactos = ({
  isSubmitting,
  register,
  errors,
  local,
  whatsapps,
  setWhatsapps,
  setValue,
  props,
  refWhatsapp,
  pais,
}: FormMedioDeContactosProps) => {
  const [maxLineasWhatsapp, setMaxLineasWhatsapp] = useState<number>(0);
  const [wspEnUso, setWspEnUso] = useState(false);
  const [invalidWsp, setInvalidWsp] = useState(false);
  const [invalidTel, setInvalidTel] = useState(false);
  const [invalidMail, setInvalidMail] = useState<boolean>(false);
  const [askedWsp, setAskedWsp] = useState<string[]>([]);
  const [recuperandoPassword, setRecuperandoPassword] = useState(false);
  const { tenant } = useContext(TenantsContext);

  const handleWspBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.toString().length !== pais?.longitudWhatsapp) return setInvalidWsp(true);
    if (!value.match(/^(?!15|0|54)\d{10}$/)) return setInvalidWsp(true);
    /* if (!value.match(pais?.codigoWhatsapp.toString()))
      return setInvalidWsp(true);
    if (!value.match(pais?.codigoTelefonico.toString()))
      return setInvalidTel(true);*/
    setInvalidWsp(false);
    if (askedWsp.some((w) => w === value)) return;
    const res = await validarWsp(value, tenant);
    askedWsp.push(value);
    setAskedWsp(askedWsp);
    setWspEnUso(res.data.data === true);
  };

  const handleTelBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.toString().length !== pais?.longitudWhatsapp) return setInvalidTel(true);
    if (!value.match(/^(?!15|0|54)\d{10}$/)) return setInvalidTel(true);
    /* if (!value.match(pais?.codigoWhatsapp.toString()))
      return setInvalidWsp(true);
    if (!value.match(pais?.codigoTelefonico.toString()))
      return setInvalidTel(true);*/
    setInvalidTel(false);
  };

  const handleRecuperarPassword = () => setRecuperandoPassword(true);
  const cancelarRecuperarPassword = () => setWspEnUso(false);

  const handleMail = (mail: string) => {
    if (mail.includes("@gamil")) {
      // En caso de encontrar "@gamil", reemplazar por "@gmail".
      setValue("mail", mail.replace(/@gamil/g, "@gmail"));
    }
    if (mail.includes(".@")) {
      setInvalidMail(true);
    }
  };

  useEffect(() => {
    if (local) {
      const mlw = local.config?.find((c) => c.name === "maxLineasWhatsapp")?.value as string;
      if (!mlw) setMaxLineasWhatsapp(0);
      else setMaxLineasWhatsapp(parseInt(mlw));
    }
  }, [local]);

  return (
    <Box sx={{ pb: 2 }} ref={refWhatsapp}>
      <RecuperarPassword open={recuperandoPassword} setOpen={setRecuperandoPassword} props={props} />
      <SectionTitle title="Medios de contacto" />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <AlertDialog
          agreeText="Recuperar contraseña"
          disagreeText="Continuar registrando este local"
          open={wspEnUso}
          title="Whatsapp en uso"
          content="Se registró otro local con el mismo whatsapp. ¿Cómo seguimos?"
          onConfirm={handleRecuperarPassword}
          onCancel={cancelarRecuperarPassword}
          onClose={() => {}}
          agreeColor="success"
        />

        {/* Whatsapps */}
        {local ? (
          whatsapps.map((wsp, i) => {
            return i < maxLineasWhatsapp ? (
              <Grid
                item
                md={12}
                xs={12}
                key={i}
                sx={{
                  mt: "18px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Grid container spacing={2} key={i}>
                  <Grid item md={maxLineasWhatsapp > 1 ? 6 : 12} xs={12}>
                    <TextField
                      value={wsp.valor}
                      onChange={(e) => {
                        const newWhatsapps = [...whatsapps];
                        newWhatsapps[i] = {
                          ...newWhatsapps[i],
                          valor: e.target.value,
                        };
                        setWhatsapps(newWhatsapps);
                      }}
                      name="whatsapp"
                      label="Whatsapp (con cód. de área, sin 0 ni 15)"
                      placeholder="1123456789"
                      type="tel"
                      disabled={isSubmitting}
                      fullWidth
                      helperText={invalidWsp && "Whatsapp inválido"}
                      FormHelperTextProps={{ error: true }}
                      color={invalidWsp ? "error" : "primary"}
                    />
                  </Grid>
                  {maxLineasWhatsapp > 1 && (
                    <Grid item md={6} xs={12}>
                      <TextField
                        value={wsp.descripcion || ""}
                        onChange={(e) => {
                          const newWhatsapps = [...whatsapps];
                          newWhatsapps[i] = {
                            ...newWhatsapps[i],
                            descripcion: e.target.value,
                          };
                          setWhatsapps(newWhatsapps);
                        }}
                        name="descripcion"
                        label="Descripcion del whatsapp"
                        type="text"
                        placeholder="Personal, trabajo, etc"
                        fullWidth
                        disabled={isSubmitting}
                        inputProps={{ maxLength: MAX_LENGTH_DESC_WSP }}
                        FormHelperTextProps={{ error: true }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : null;
          })
        ) : (
          <Grid item md={12} xs={12}>
            <TextField
              {...register("whatsapp")}
              name="whatsapp"
              label={
                <>
                  Whatsapp (con cód. de área, sin 0 ni 15) <Asterisco />
                </>
              }
              placeholder="Ej: 1144445555"
              type="tel"
              fullWidth
              disabled={isSubmitting}
              onBlur={handleWspBlur}
              helperText={(invalidWsp || errors.whatsapp) && (errors.whatsapp?.message || "Número inválido")}
              FormHelperTextProps={{ error: true }}
              color={errors.whatsapp ? "error" : "secondary"}
            />
          </Grid>
        )}

        {/* Medios de contacto obligatorios */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sx={{ "& p": { position: "absolute", top: "100%" } }}>
              <TextField
                label="Teléfono para llamadas"
                type="tel"
                fullWidth
                placeholder="Ej: 1144445555"
                {...register("telefono")}
                disabled={isSubmitting}
                onBlur={handleTelBlur}
                InputLabelProps={local && { shrink: !!local }}
                helperText={
                  (invalidTel || errors.telefono) && (errors.telefono?.message || "Número de teléfono inválido")
                }
                FormHelperTextProps={{ error: true }}
                color={errors.telefono ? "error" : "secondary"}
              />
            </Grid>
            <Grid item md={6} xs={12} sx={{ "& p": { position: "absolute", top: "100%" } }}>
              <TextField
                label="Email de contacto"
                type="email"
                fullWidth
                placeholder="mail@mail.com"
                {...register("mail")}
                disabled={isSubmitting}
                InputLabelProps={local && { shrink: !!local }}
                helperText={
                  (invalidMail || errors.mail) &&
                  (errors.mail?.message || "El e-mail no puede tener un . antes de la @")
                }
                FormHelperTextProps={{ error: true }}
                color={errors.mail ? "error" : "secondary"}
                onBlur={(e: any) => handleMail(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormMedioDeContactos;
