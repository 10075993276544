import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react'
import { IntegracionesContext } from '../../../../context/integraciones.context'
import SnackBarUtils from '../../../../../../utils/SnackBarUtils'
import ProductoAA1TForm from './ProductoAA1TForm'
import { Close } from '@mui/icons-material'
import { IProductoTienda } from '../../../../types/responses-productos'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  producto: IProductoTienda
  open: boolean
  handleClose: () => void
}

export interface FormData {
  codRubro: string
  precio: number
  idUnidadDeMedida: any
  canRetailSell: boolean
  rubroName: string
}

const DialogConfirmProductoAA1T: FC<Props> = ({ open, producto, handleClose }) => {
  const { handleProductosAConfirmarAction } = useContext(IntegracionesContext)
  const [formComplete, setFormComplete] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    codRubro: '',
    precio: 0,
    idUnidadDeMedida: '',
    canRetailSell: true,
    rubroName: ''
  })

  const handleChangeFormData = useCallback(
    (key: keyof FormData, value: any) => {
      setFormData((prev) => ({ ...prev, [key]: value }))
    },
    [setFormData]
  )

  const handleConfirm = useCallback(() => {
    if (!formComplete) return
    const newProducto = { ...producto }

    newProducto.codRubro = formData.codRubro
    newProducto.rubroName = formData.rubroName
    newProducto.idUnidadDeMedida = formData.idUnidadDeMedida
    newProducto.precio = formData.precio
    handleProductosAConfirmarAction(newProducto)
    SnackBarUtils.success('Producto agregado a AA1T: ' + producto.nombreProducto)
  }, [formComplete, formData])

  useEffect(() => {
    setFormComplete(!!formData.codRubro && !!formData.precio)
  }, [formData])

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: '40px', p: '20px', bgcolor: '#F5F5F7' } }}
    >
      <DialogTitle sx={{ bgcolor: '#F5F5F7', color: 'black', fontWeight: 800, fontSize: 24 }}>
        Tu tienda Avellaneda a un toque
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 15,
          top: 12,
          color: '#1C1C1C'
        })}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <ProductoAA1TForm producto={producto} formData={formData} handleChangeFormData={handleChangeFormData} />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          px: '24px',
          // pb: '24px',
          gap: '24px'
        }}
      >
        <ButtonRounded variant="outlined" onClick={handleClose} fullWidth sx={{ maxWidth: '25%' }}>
          Cerrar
        </ButtonRounded>
        <ButtonRounded
          variant="contained"
          bgcolor="#5983EF"
          textColor="white"
          disabled={!formComplete}
          onClick={handleConfirm}
          fullWidth
          sx={{ boxShadow: 'none' }}
        >
          Agregar Publicación
        </ButtonRounded>
      </DialogActions>
    </Dialog>
  )
}

export default memo(DialogConfirmProductoAA1T)
