import React, { useState, useContext } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableFooter,
  Box
} from "@mui/material";
import { GaleriasContext, IGalerias } from "../../context/galerias.context";
import GaleriasPagination from "./paginadoTablaGalerias";
import EditGaleria from "../../widgets/editGaleria";
import Loading from "../../../../components/Common/Loading";
import { GaleriasProps } from "../mobile/cardsGalerias";

const GaleriasDesktop: React.FC<GaleriasProps> = ({
  setEditGaleria,
  setSelectedGaleria,
}) => {
  const { galerias, /* searching */ page, rowsPerPage, loading } =
    useContext(GaleriasContext);
  const keyNames: Record<string, string> = {
    nombre: "Nombre",
    direccion: "Dirección",
  };
  const [hoverRow, setHoverRow] = useState<any>(0);
  if (loading) return <Loading />;
  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          margin: "30px",
          border: "1px solid grey",
          maxWidth: "95%",
        }}
      >
        <Table
          sx={{
            tableLayout: "initial",
          }}
        >
          <TableHead
            aria-label="Galerias"
            sx={{
              fontWeight: "bold",
              backgroundColor: "#e0e0e0",
              color: "white",
            }}
          >
            <TableRow>
              {Object.keys(keyNames).map((key) => (
                <TableCell
                  key={key}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "black",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  {keyNames[key]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {(rowsPerPage > 0
            ? galerias.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : galerias
          ).map((galeria: IGalerias, i: number) => (
            <TableRow
              key={galeria.codGaleria}
              onClick={() => {
                setSelectedGaleria(galeria.codGaleria);
                setEditGaleria(true);
              }}
              onMouseEnter={() => setHoverRow(galeria.codGaleria)}
              onMouseLeave={() => setHoverRow(null)}
              style={{
                cursor: "pointer",
                backgroundColor:
                  hoverRow === galeria.codGaleria ? "lightgray" : "white",
              }}
            >
              <TableCell style={{ width: "50%", textAlign: "center" }}>
                {galeria.nombre}
              </TableCell>
              <TableCell style={{ width: "50%", textAlign: "center" }}>
                {galeria.direccion}
              </TableCell>
            </TableRow>
          ))}
          <TableFooter>
            <TableRow>
              <GaleriasPagination component={"div"} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default GaleriasDesktop;
