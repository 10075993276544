import { MenuItem } from '@mui/material'
import { IProducto } from '../types/producto'

interface ProductoActionsProps {
  producto: IProducto
  changingEstado: boolean
  isDefinitivelyDeleting?: boolean
  isDefinitivelyExpiring?: boolean
}

const ProductoActions = ({producto, changingEstado, isDefinitivelyDeleting, isDefinitivelyExpiring}: ProductoActionsProps) => {
  return (
    <>
      { !producto.aprobado && <MenuItem disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.aprobar}>Aprobar</MenuItem> }
      { !producto.rechazado && <MenuItem disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.rechazar}>Rechazar</MenuItem> }
      { !producto.pausado && <MenuItem disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.pausar}>Pausar</MenuItem> }
      { producto.pausado && <MenuItem disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.reanudar}>Reanudar</MenuItem> }
      { !producto.vencido && <MenuItem disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.vencer}>Vencer</MenuItem> }
      <MenuItem disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.editar}>Editar</MenuItem>
      <MenuItem sx={{color: 'red'}} disabled={isDefinitivelyDeleting || isDefinitivelyExpiring || changingEstado} onClick={producto.borrar}>Borrar</MenuItem>
    </>
  )
}

export default ProductoActions
