import { useContext, useEffect, useState } from 'react'
import { getLocal } from '../services/locales.service'
import { TenantsContext } from '../../../context/tenants.context'
import { LocalVendedorDTO } from '../../../dto/LocalDTO'

export const useLocal = (codLocal: string) => {
  const [local, setLocal] = useState<LocalVendedorDTO>()
  const [loading, setLoading] = useState<boolean>(true)
  const { tenant } = useContext(TenantsContext)

  const fetchData = async (codLocal: string) => {
    const res = await getLocal(codLocal, tenant)
    setLocal(res.data.data)
    return res.data.data
  }

  useEffect(() => {
    if (!codLocal) return setLoading(false)
    setLoading(true)
    fetchData(codLocal).finally(() => setLoading(false))
  }, [codLocal])

  return { local, fetchData, loading }
}
