import { Box, Button } from '@mui/material'
import { useResponsive } from '../../../../hooks/useResponsive.hook'
import { Pasos, PasosAtributos } from '../ImportarCatalogo'
import { tieneValoresParaAsignar } from '../utils/helpers'
import { GrayButtonSx } from '../../../../styles/GrayButtonSx'
import { RubroYValores } from '../types/atributos'
import { AtributoValorASincronizar } from '../../context/integraciones.context'
import { useCallback, useEffect } from 'react'
import { SaveValores } from '../../types'
import { Atributos } from '../types/importacion'
import ButtonRounded from '../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  paso: string
  pasoAtributos: string
  setPaso: (paso: string) => void
  callback: () => Promise<void>
  loading: boolean
  textButton: string
  loadingTextButton: string
  atributos: any
  RubroYValores?: RubroYValores[]
  sincronizar?: () => Promise<void>
  handleBack?: VoidFunction
}

const BotonesAtributos = ({
  setPaso,
  callback,
  loading,
  paso,
  pasoAtributos,
  atributos,
  loadingTextButton,
  textButton,
  RubroYValores,
  sincronizar,
  handleBack
}: Props) => {
  const { isMobile } = useResponsive()

  const finish = useCallback(async () => {
    if (sincronizar) await sincronizar()
    await callback()
  }, [sincronizar, atributos, callback])

  async function avanzarAtributo(pasoAtributos: string, atributos: any) {
    switch (pasoAtributos) {
      case PasosAtributos.Colores:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          setPaso(PasosAtributos.Talles)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          setPaso(PasosAtributos.Composiciones)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          setPaso(PasosAtributos.Medidas)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          setPaso(PasosAtributos.Tamanos)
        } else {
          await finish()
        }
        break
      case PasosAtributos.Talles:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          setPaso(PasosAtributos.Composiciones)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          setPaso(PasosAtributos.Medidas)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          setPaso(PasosAtributos.Tamanos)
        } else {
          await finish()
        }
        break
      case PasosAtributos.Composiciones:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          setPaso(PasosAtributos.Medidas)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          setPaso(PasosAtributos.Tamanos)
        } else {
          await finish()
        }
        break
      case PasosAtributos.Medidas:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          setPaso(PasosAtributos.Tamanos)
        } else {
          await finish()
        }
        break
      case PasosAtributos.Tamanos:
        await finish()
        break
      default:
        await finish()
        break
    }
  }

  async function retrocederAtributo(pasoAtributos: string, atributos: any) {
    switch (pasoAtributos) {
      case PasosAtributos.Tamanos:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          setPaso(PasosAtributos.Medidas)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          setPaso(PasosAtributos.Composiciones)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          setPaso(PasosAtributos.Talles)
        } else {
          setPaso(PasosAtributos.Colores)
        }
        break
      case PasosAtributos.Medidas:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          setPaso(PasosAtributos.Composiciones)
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          setPaso(PasosAtributos.Talles)
        } else {
          setPaso(PasosAtributos.Colores)
        }
        break
      case PasosAtributos.Composiciones:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          setPaso(PasosAtributos.Talles)
        } else {
          setPaso(PasosAtributos.Colores)
        }
        break
      case PasosAtributos.Talles:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Colores, RubroYValores)) {
          setPaso(PasosAtributos.Colores)
        } else {
          handleBack()
        }
        break
      case PasosAtributos.Colores:
        await callback()
        break
      default:
        await callback()
        break
    }
  }

  function getButtonSettings(pasoAtributos: string, atributos: any) {
    let settings = {
      color: 'success', // Color por defecto si no hay siguiente paso
      text: loading ? loadingTextButton : textButton // Texto por defecto
    }

    switch (pasoAtributos) {
      case PasosAtributos.Colores:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a talles' }
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a composiciones' }
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a medidas' }
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a tamaños' }
        }
        break
      case PasosAtributos.Talles:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a composiciones' }
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a medidas' }
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a tamaños' }
        }
        break
      case PasosAtributos.Composiciones:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a medidas' }
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a tamaños' }
        }
        break
      case PasosAtributos.Medidas:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Tamanos, RubroYValores)) {
          settings = { color: 'primary', text: 'Ir a tamaños' }
        }
        break
      default:
        break
    }

    return settings
  }

  function getBackButtonSettings(pasoAtributos: string, atributos: any) {
    const settings = {
      color: 'inherit',
      text: 'Volver'
    }

    switch (pasoAtributos) {
      case PasosAtributos.Tamanos:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Medidas, RubroYValores)) {
          settings.text = 'Volver a medidas'
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          settings.text = 'Volver a composiciones'
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          settings.text = 'Volver a talles'
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Colores, RubroYValores)) {
          settings.text = 'Volver a colores'
        }
        break
      case PasosAtributos.Medidas:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Composiciones, RubroYValores)) {
          settings.text = 'Volver a composiciones'
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          settings.text = 'Volver a talles'
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Colores, RubroYValores)) {
          settings.text = 'Volver a colores'
        }
        break

      case PasosAtributos.Composiciones:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Talles, RubroYValores)) {
          settings.text = 'Volver a talles'
        } else if (tieneValoresParaAsignar(atributos, PasosAtributos.Colores, RubroYValores)) {
          settings.text = 'Volver a colores'
        }
        break

      case PasosAtributos.Talles:
        if (tieneValoresParaAsignar(atributos, PasosAtributos.Colores, RubroYValores)) {
          settings.text = 'Volver a colores'
        }
        break
      default:
        settings.text = 'Volver'
        break
    }

    return settings
  }

  useEffect(() => {
    // validar el paso Colores
    const tieneComienzo = tieneValoresParaAsignar(atributos, PasosAtributos.Colores, RubroYValores)
    if (!tieneComienzo) {
      avanzarAtributo(PasosAtributos.Colores, atributos)
    }
  }, [])

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      gap="16px"
      width="100%"
      hidden={paso !== Pasos.AsignarAtributos}
    >
      {/* Back Atributo */}
      <ButtonRounded
        hidden={pasoAtributos === PasosAtributos.Colores}
        disabled={loading}
        color={getBackButtonSettings(pasoAtributos, atributos).color as any}
        onClick={() => retrocederAtributo(pasoAtributos, atributos)}
        style={{ width: '300px' }}
      >
        {getBackButtonSettings(pasoAtributos, atributos).text}
      </ButtonRounded>

      <ButtonRounded
        bgcolor="#5983EF"
        textColor="white"
        color={getButtonSettings(pasoAtributos, atributos).color as any}
        onClick={async () => avanzarAtributo(pasoAtributos, atributos)}
        disabled={loading}
        fullWidth
      >
        {getButtonSettings(pasoAtributos, atributos).text}
      </ButtonRounded>
    </Box>
  )
}

export default BotonesAtributos
