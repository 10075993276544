import { ITenant } from "../../pages/AbmLocalPage/types";
import { axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import { INotificaciones } from "./types/Notificaciones";

export const getNotificaciones = async (tenant: ITenant) =>
  await axiosInstanceMain.get(
    `admin/${tenant.path}/notificaciones?pagina=1&pageSize=10000`
  );

export const getTiposOrigenNotificaciones = async (tenant: ITenant) =>
  await axiosInstanceMain.get(
    `admin/${tenant.path}/notificaciones/tipos-origen`
  );

export const getTiposNotificaciones = async (tenant: ITenant) =>
  await axiosInstanceMain.get(
    `admin/${tenant.path}/notificaciones/tipos`
  );

export const leerNotificaciones = async (tenant: ITenant, id: number) =>
  await axiosInstanceMain.get(`admin/${tenant.path}/notificaciones/${id}`);

export const insertarNotificacion = async (
  tenant: ITenant,
  notificacion: INotificaciones
) =>
  await axiosInstanceMain.post(
    `admin/${tenant.path}/notificaciones`,
    notificacion
  );

export const editarNotificación = async (
  tenant: ITenant,
  id: number,
  notificacion: INotificaciones
) =>
  await axiosInstanceMain.put(
    `admin/${tenant.path}/notificaciones/${id}`,
    notificacion
  );
