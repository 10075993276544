import { Box, FormControl, FormControlLabel, Link, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { memo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStoreImportacion } from './store/store-importacion'
import { readExcel } from '../utils/helpers'
import { useResponsive } from '../../../hooks/useResponsive.hook'
import ButtonRounded from '../../../components/Common/utils/button/ButtonRounded'
import { FiUploadCloud } from 'react-icons/fi'

const FILE_CSV_SIZING = parseInt(process.env.FILE_CSV_SIZING as string) || 5

export const CasoRepiteID = {
  ReemplazaExistente: 'Reemplazar el existente',
  NoImportar: 'No importarlos'
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

interface Props {
  setCasoRepiteIDs: (caso: string) => void
}

const SubirArchivo = ({ setCasoRepiteIDs }: Props) => {
  const { file, setFile, setIsValid, setDataExcel } = useStoreImportacion((state) => state)
  const [separadorCSV, setSeparadorCSV] = useState(';')
  const { isMobile } = useResponsive()

  const downloadExampleFile = () => {
    const link = document.createElement('a')
    link.href = '/assets/ejemplo_catalogo.csv'
    link.download = 'ejemplo_catalogo.csv'
    link.click()
  }

  const handleFileChange = useCallback(
    async (e: any) => {
      const file = e.target.files[0]

      if (!file) return
      if (file.size > FILE_CSV_SIZING * 1024 * 1024) return // Mayor a X (FILE_CSV_SIZING) MB
      if (!file.name.match(/\.(csv|xls|xlsx)$/)) return

      setDataExcel(await readExcel(file))

      setFile(file)
    },
    [setFile, setDataExcel]
  )

  useEffect(() => {
    setIsValid(file && !!separadorCSV)
  }, [file, separadorCSV, setIsValid])

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography color="#727272" fontSize={{ xs: 14, md: 16 }} fontWeight={'semi'}>
        Ahorrá tiempo agregando o modificando tus productos de forma masiva, a través de un archivo de Excel o CSV. Te
        recomendamos que descargues la plantilla de ejemplo con el formato ideal haciendo{' '}
        <Link href="#" onClick={downloadExampleFile}>
          click acá
        </Link>
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="12px"
        p="12px"
        bgcolor="white"
        borderRadius="16px"
      >
        <Typography fontSize={{ xs: 14, md: 16 }} fontWeight={'semi'}>
          Subí el archivo Excel o CSV desde tu equipo {`(máximo ${FILE_CSV_SIZING} MB)`}
        </Typography>
        {/* Form seleccion de archivo */}
        <ButtonRounded component="label" startIcon={<FiUploadCloud />} sx={{ p: '10px 40px' }}>
          Subir archivo
          <VisuallyHiddenInput
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleFileChange}
          />
        </ButtonRounded>
        {file && (
          <Typography
            variant="body1"
            fontWeight={'bold'}
            maxWidth={isMobile ? '250px' : '300px'}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            title={file.name}
          >
            {file.name}
          </Typography>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        marginTop={isMobile ? '10px' : '0'}
        alignItems="center"
        justifyContent="center"
        gap={isMobile ? '5px' : '20px'}
      >
        <Typography variant="body1" fontWeight={'bold'}>
          ¿Qué hacer con los productos que coincidan por ID o SKU?
        </Typography>

        <FormControl>
          <RadioGroup
            defaultValue={CasoRepiteID.ReemplazaExistente}
            row
            onChange={(e) => setCasoRepiteIDs(e.target.value)}
          >
            <FormControlLabel
              value={CasoRepiteID.ReemplazaExistente}
              label={CasoRepiteID.ReemplazaExistente}
              control={<Radio />}
            />
            <FormControlLabel value={CasoRepiteID.NoImportar} label={CasoRepiteID.NoImportar} control={<Radio />} />
          </RadioGroup>
        </FormControl>
      </Box>

      {file && file.name.match(/\.(csv)$/i) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px'
          }}
        >
          <Typography variant="body1" fontWeight={'bold'}>
            Separador de CSV
          </Typography>
          <TextField
            variant="outlined"
            value={separadorCSV}
            sx={{
              width: '70px',
              fontSize: '18px'
            }}
            onChange={(e) => setSeparadorCSV(e.target.value)}
          />
        </Box>
      )}
    </Box>
  )
}

export default memo(SubirArchivo)
