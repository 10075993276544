import { hash } from "./../../../utils/hashFunction";
import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant } from "../../AbmLocalPage/types";

export const EnviarCodigo = async (
  value: string,
  noSms = false,
  tenant: ITenant
) => {
  let url = `/${tenant.path}/auth/recuperar/enviar-dado-contacto`;
  if (noSms) url += "?no-usar-sms=true";
  return await axiosInstanceMain.post(url, value, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const ValidarCodigo = async (token: string, tenant: ITenant) => {
  return await axiosInstanceMain.get(
    `/${tenant.path}/auth/recuperar/validar/${token}`
  );
};

export const CambiarPassword = async (
  value: string,
  token: string,
  tenant: ITenant
) => {
  return await axiosInstanceMain.post(
    `/${tenant.path}/auth/recuperar/cambiar/${token}`,
    value,
    { headers: { "Content-Type": "text/plain" } }
  );
};

export const ObtenerUsuario = async (idUsuario: number, tenant: ITenant) => {
  const clave = await hash(idUsuario.toString() + "lugares2017");
  return await axiosInstanceMain.get(
    `/${tenant.path}/auth/usuario/${idUsuario}?clave=${clave}`
  );
};
