import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import DesplegableRubros from '../../AdminProducts/components/DesplegableRubros'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Equivalencia, RubroEquivalencia } from './types/importacion'
import { useStoreImportacion } from './store/store-importacion'
import NoImportarCheckbox from './components/NoImportarCheckbox'
import { areValidEquivalencias } from './utils/helpers'
import DescripcionPaso from './components/DescripcionPaso'
import { Rubro } from '../types/responses-productos'
import { useResponsive } from '../../../hooks/useResponsive.hook'
import { Pasos } from './ImportarCatalogo'
import { GetRubrosEquivalencias } from '../services/impo-expo.service'
import { useAppStore } from '../../../store/app-store'
import Loading from '../../../components/Common/Loading'
import useRubros from '../../Products/hooks/rubros.hook'

interface Props {
  categorias: string[]
  equivalencias: Equivalencia[]
  setEquivalencias: (equivalencias: Equivalencia[]) => void
  rubrosEquivalencias: RubroEquivalencia[]
  setRubrosEquivalencias: (equivalencias: RubroEquivalencia[]) => void
  handleNextImportacion: (paso: Pasos) => void
  paso: Pasos
}

interface AsignarCategoriasProps extends Partial<Props> {
  rubros: Rubro[]
  handleChange: ({ rubro, categoria }: { rubro: Rubro; categoria: string }) => void
}

// eslint-disable-next-line react/display-name
const AsignarCategoriasMobile = memo(
  ({ categorias, equivalencias, handleChange, rubros, setEquivalencias }: AsignarCategoriasProps) => {
    const loading = useStoreImportacion((s) => s.loading)

    if (!categorias || !equivalencias || !handleChange || !rubros || !setEquivalencias || loading) return <></>

    return (
      <Grid mt={1} container spacing={2}>
        {categorias?.map((categoria, i) => {
          const cat = equivalencias.find((eq) => Object.keys(eq)[0] === categoria)?.[categoria]

          return (
            <Grid key={i} item sm={6} xs={12}>
              <Card key={i}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                  <Typography fontSize="18px">{categoria}</Typography>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <DesplegableRubros
                      onChange={(rubro) => handleChange({ rubro, categoria })}
                      fullWidth
                      disabled={cat?.no_importar}
                      rubros={rubros}
                      defaultValue={cat?.value}
                    />

                    <NoImportarCheckbox
                      checked={cat?.no_importar || false}
                      element={categoria}
                      equivalencias={equivalencias}
                      setEquivalencias={setEquivalencias}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    )
  }
)

// eslint-disable-next-line react/display-name
const AsignarCategoriasDesktop = memo(
  ({ categorias, equivalencias, handleChange, rubros, setEquivalencias }: AsignarCategoriasProps) => {
    const loading = useStoreImportacion((s) => s.loading)

    if (!categorias || !equivalencias || !handleChange || !rubros || !setEquivalencias || loading) return <></>

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>Categoría en tu archivo Excel o CSV</TableCell>
              <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>Categoría en Avellaneda a un Toque</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categorias?.map((categoria, i) => {
              const cat = equivalencias.find((eq) => Object.keys(eq)[0] === categoria)?.[categoria]

              return (
                <TableRow key={i}>
                  <TableCell width="600px">
                    <Typography fontSize={{ xs: 12, md: 16 }} color="#8E8E8E" fontWeight="bold">
                      {categoria}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" gap="16px">
                      <DesplegableRubros
                        onChange={(rubro) => handleChange({ rubro, categoria })}
                        fullWidth
                        disabled={cat?.no_importar}
                        rubros={rubros}
                        sx={{ width: '400px' }}
                        defaultValue={cat?.value}
                        placeHolder="Seleccione un rubro"
                      />

                      <NoImportarCheckbox
                        checked={cat?.no_importar || false}
                        element={categoria}
                        equivalencias={equivalencias}
                        setEquivalencias={setEquivalencias}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)

const AsignarCategorias = ({
  categorias,
  equivalencias,
  setEquivalencias,
  rubrosEquivalencias,
  setRubrosEquivalencias,
  paso
}: Props) => {
  const codLocal = useAppStore((store) => store.codLocal)
  const { rubrosFlatted } = useRubros()
  const { setIsValid, setLoading, loading } = useStoreImportacion()
  const { isMobile } = useResponsive()
  const [eqs, setEqs] = useState<Equivalencia[]>([])
  const descripcion = useMemo(() => {
    return isMobile
      ? ''
      : `A la izquierda se muestran las categorías que se están importando del archivo Excel o CSV. A la derecha, los
      rubros de Avellaneda a un Toque. `
  }, [isMobile])

  const handleChange = useCallback(
    ({ rubro: { codRubro }, categoria }: { rubro: Rubro; categoria: string }): Promise<void> => {
      return new Promise((resolve) => {
        const index = equivalencias.findIndex((eq) => Object.keys(eq)[0] === categoria)
        const newEquivalencias = [...equivalencias]
        newEquivalencias[index] = {
          [categoria]: {
            value: codRubro,
            no_importar: false
          }
        }

        const indexRubro = rubrosEquivalencias.findIndex((eq) => eq.codRubro === codRubro)
        const newRubrosEquivalencias = [...rubrosEquivalencias]
        if (indexRubro !== -1) {
          newRubrosEquivalencias[indexRubro] = {
            codRubro,
            nombreRubroExterno: categoria
          }
        } else {
          newRubrosEquivalencias.push({
            codRubro,
            nombreRubroExterno: categoria
          })
        }

        setRubrosEquivalencias(newRubrosEquivalencias)
        setEquivalencias(newEquivalencias)
        setEqs(newEquivalencias)

        resolve()
      })
    },
    [equivalencias, setEquivalencias, rubrosEquivalencias, setRubrosEquivalencias]
  )

  const getCategoriasEquivalencias = useCallback(async (): Promise<RubroEquivalencia[]> => {
    const { data } = await GetRubrosEquivalencias(codLocal)
    return data.data as RubroEquivalencia[]
  }, [codLocal])

  useEffect(() => {
    const fetchEquivalencias = async () => {
      setLoading(true)
      const catEquivalenciasBackend = await getCategoriasEquivalencias()

      const equivalenciasMapped = catEquivalenciasBackend.map((eq) => ({
        [eq.nombreRubroExterno]: {
          value: eq.codRubro,
          no_importar: false
        }
      }))

      const newEquivalencias = categorias.map((categoria) => {
        const foundEquivalencia = equivalenciasMapped.find((eq) => Object.keys(eq)[0] === categoria)

        if (foundEquivalencia) {
          return foundEquivalencia
        }

        return {
          [categoria]: {
            value: 0,
            no_importar: false
          }
        }
      })

      setEquivalencias(newEquivalencias)
    }

    fetchEquivalencias().finally(() => setLoading(false))
  }, [categorias])

  useEffect(() => {
    setIsValid(areValidEquivalencias(equivalencias))
  }, [equivalencias, setIsValid, paso])

  useEffect(() => {
    if (!equivalencias || eqs.length > 0) return
    setEqs(equivalencias)
  }, [equivalencias, eqs])

  if (loading) return <Loading />

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <DescripcionPaso>
        {descripcion} Seleccioná en cada categoría de tu archivo Excel o CSV el rubro de Avellaneda a un Toque. Si no
        querés importar una categoría, seleccioná la opción "No importar".
      </DescripcionPaso>

      {isMobile ? (
        <AsignarCategoriasMobile
          categorias={categorias}
          equivalencias={eqs}
          handleChange={handleChange}
          rubros={rubrosFlatted}
          setEquivalencias={setEqs}
        />
      ) : (
        <AsignarCategoriasDesktop
          categorias={categorias}
          equivalencias={eqs}
          handleChange={handleChange}
          rubros={rubrosFlatted}
          setEquivalencias={setEqs}
        />
      )}
    </Box>
  )
}

export default AsignarCategorias
