import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Direccion, galeriasType, postLocalType } from '../../types'
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { validarDireccion } from '../../services/validar.service'
import Mapa from '../Mapa'
import SnackBarUtils from '../../../../utils/SnackBarUtils'
import Loading from '../../../../components/Common/Loading'
import AlertDialog from '../../../../components/Common/Dialog'
import SectionTitle from '../../common/SectionTitle'
import { Asterisco, MAX_NUM_LOCAL } from '.'
import { TenantsContext } from '../../../../context/tenants.context'
import { LocalVendedorDTO } from '../../../../dto/LocalDTO'

interface FormDireccionProps {
  register: UseFormRegister<postLocalType>
  setValue: UseFormSetValue<postLocalType>
  getValues: UseFormGetValues<postLocalType>
  local?: LocalVendedorDTO
  errors: FieldErrors<postLocalType>
  setDirectionRadio: (dir: string) => void
  directionRadio: string
  selectGaleria: string
  setSelectGaleria: (id: string) => void
  handleChangeGaleria: (value: string) => void
  locales: any[]
  esVirtualYVendedor: boolean
  center: string
  setCenter: (center: string) => void
  userLogged: any
  isSubmitting: boolean
  galerias: galeriasType[]
  setError: UseFormSetError<postLocalType>
  setGaleriaPropuesta: (value: boolean) => void
  clearErrors: UseFormClearErrors<postLocalType>
  proponeGaleria: boolean
  setProponeGaleria: (value: boolean) => void
  refDireccion: any
  setLong: (value: number) => void
  setLat: (value: number) => void
}

const FormDireccion = ({
  proponeGaleria,
  setProponeGaleria,
  setGaleriaPropuesta,
  clearErrors,
  setError,
  getValues,
  galerias,
  isSubmitting,
  userLogged,
  center,
  setCenter,
  esVirtualYVendedor,
  register,
  setValue,
  local,
  locales,
  errors,
  setDirectionRadio,
  directionRadio,
  handleChangeGaleria,
  selectGaleria,
  setSelectGaleria,
  refDireccion,
  setLong,
  setLat
}: FormDireccionProps) => {
  const [loadingDir, setLoadingDir] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [validandoDireccion, setValidandoDireccion] = useState(false)
  const [errorDirGaleria, setErrorDirGaleria] = useState(false)
  const [esGaleria, setEsGaleria] = useState(false)
  const [newDirGaleria, setNewDirGaleria] = useState('')
  const [dirLocal, setDirLocal] = useState(local?.direccion || '')
  const [lastDirConsultada, setLastDirConsultada] = useState('')
  const [multiplesResultados, setMultiplesResultados] = useState<Direccion[]>([])
  const [galeria, setGaleria] = useState<galeriasType>(null)
  const { tenant } = useContext(TenantsContext)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const changeDirectionRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirectionRadio(e.target.value)
  }

  const handleDirLocalChange = (e: any) => {
    setValue(`dirLocal`, e.target.value)
    setDirLocal(e.target.value)
  }

  const validarDirLocal = async (value: string, e?: any, esMultiple = true, dir?: Direccion) => {
    if (e) e.preventDefault()
    if (value === '') return
    if (lastDirConsultada === value) return
    setLoadingDir(true)
    setShowMap(true)
    setValidandoDireccion(true)
    setEsGaleria(false)

    return await validarDireccion(value, local?.codLocal, tenant)
      .then((res) => {
        setCenter('')
        const direccion = res.data.data as Direccion

        if (esMultiple && direccion.multiplesResultados && direccion.multiplesResultados.length > 0) {
          return setMultiplesResultados(direccion.multiplesResultados)
        }

        if (dir) {
          direccion.latitud = dir.latitud
          direccion.longitud = dir.longitud
          direccion.estaFueraDeZona = dir.estaFueraDeZona
        }

        if (direccion.latitud === 0) {
          setError('dirLocal', {
            message: 'La dirección no es válida',
            type: 'manual'
          })
          return SnackBarUtils.error('La dirección no es válida')
        }
        if (direccion.estaFueraDeZona) {
          if (!userLogged.isLogged || !userLogged.roles.includes('admin'))
            setError('dirLocal', {
              message: 'La dirección se encuentra fuera del radio permitido',
              type: 'manual'
            })
          return SnackBarUtils.error('La dirección se encuentra fuera del radio permitido')
        }
        if (direccion && direccion.codGaleria) {
          SnackBarUtils.info('La dirección que ingresaste corresponde a una galería')
          setDirectionRadio('galeria')
          setSelectGaleria(direccion.codGaleria)
          setValue(`dirGaleria`, direccion.codGaleria)
          return 2
        }
        setLong(direccion.longitud)
        setLat(direccion.latitud)
        setLastDirConsultada(value)
        setCenter(`${direccion.latitud},${direccion.longitud}`)
        clearErrors('dirLocal')
        setMultiplesResultados([])
        return 1
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoadingDir(false)
        setValidandoDireccion(false)
      })
  }

  const handleDirGaleriaChange = async (e: any, value: any) => {
    if (!value) return setErrorDirGaleria(true)
    handleChangeGaleria(value.codGaleria)
    setValue(`dirGaleria`, value.codGaleria)
    clearErrors('dirGaleria')
  }

  const handleNewDirGaleriaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setNewDirGaleria(e.target.value)
    setValue('dirGaleria', value)
  }

  const handleProponeGaleria = async () => {
    const nombreGaleria = getValues('nombreGaleria')
    // const dirGaleria = getValues("dirGaleria")
    const numGaleriaLocal = getValues('numGaleriaLocal')
    if (!nombreGaleria || !newDirGaleria || !numGaleriaLocal)
      return SnackBarUtils.error('Debes completar los campos para proponer una nueva galería!')
    const v = await validarDirLocal(newDirGaleria)
    if (v !== 1 && v !== 2) return
    setProponeGaleria(false)
    setValue('numLocal', getValues('numGaleriaLocal'))
    if (v === 1) {
      setShowMap(true)
      setGaleriaPropuesta(true)
      setDirectionRadio('calle')
      setValue('dirLocal', getValues('dirGaleria'))
      SnackBarUtils.info(
        'La galería se propuso correctamente, crea el local para que sea revisado por los administradores'
      )
    }
  }

  /* useEffect(() => {
    setCenter('-')
  }, [setCenter]) */

  useEffect(() => {
    setValue(`dirGaleria`, selectGaleria)
    setGaleria(galerias.find((g: galeriasType) => g.codGaleria === selectGaleria))
  }, [selectGaleria])

  useEffect(() => {
    setShowMap(directionRadio === 'calle' && center !== '')
  }, [directionRadio, center])

  return (
    <Box ref={refDireccion}>
      <SectionTitle title="Dirección" />
      <Grid spacing={2} container>
        <AlertDialog
          open={validandoDireccion}
          title="Validando dirección"
          content="Se está comprobando si la dirección ingresada es válida"
          agreeText=""
          disagreeText=""
          onCancel={() => {}}
          onConfirm={() => {}}
        />

        <Grid item md={3} xs={12}>
          <FormControl>
            <FormLabel id="direction" />
            <RadioGroup
              aria-labelledby="direction"
              onChange={changeDirectionRadio}
              sx={{ '@media (max-width: 600px)': { flexDirection: 'row' } }}
            >
              <FormControlLabel
                value="galeria"
                control={<Radio />}
                label="Galeria"
                disabled={isSubmitting || esVirtualYVendedor || proponeGaleria}
                checked={directionRadio === 'galeria'}
              />
              <FormControlLabel
                value="calle"
                control={<Radio />}
                label="Calle"
                disabled={isSubmitting || esVirtualYVendedor || proponeGaleria}
                checked={directionRadio === 'calle'}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {directionRadio && directionRadio === 'calle' ? (
          // CALLE
          <Grid
            item
            md={9}
            xs={12}
            alignSelf="center"
            mb={errors.dirLocal && isMobile ? 3 : 0}
            sx={{ '& p': { position: 'absolute', top: '100%' } }}
          >
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <TextField
                {...register('dirLocal')}
                onChange={handleDirLocalChange}
                onBlur={() => validarDirLocal(dirLocal)}
                label={
                  <>
                    Dirección de tu local <Asterisco />
                  </>
                }
                fullWidth
                onKeyPress={(e) => e.key === 'Enter' && validarDirLocal(dirLocal, e)}
                placeholder="Av. Avellaneda 2300"
                disabled={isSubmitting || esVirtualYVendedor || validandoDireccion || proponeGaleria}
                InputLabelProps={local && { shrink: !!local }}
                helperText={errors.dirLocal && errors.dirLocal.message}
                FormHelperTextProps={{ error: true }}
                color={errors.dirLocal ? 'error' : 'secondary'}
              />
              <Button
                disabled={
                  dirLocal === '' ||
                  isSubmitting ||
                  validandoDireccion ||
                  esVirtualYVendedor ||
                  lastDirConsultada === dirLocal
                }
                onClick={() => validarDirLocal(dirLocal)}
                color="primary"
                variant="contained"
              >
                Validar
              </Button>
            </Box>
          </Grid>
        ) : (
          // GALERIA
          <Grid item xs={12} md={9} alignSelf={'center'}>
            <Grid container spacing={2}>
              <Grid
                item
                md={9}
                sm={8}
                xs={12}
                sx={{
                  '& p': {
                    position: 'absolute',
                    top: '100%'
                  }
                }}
              >
                <Autocomplete
                  options={galerias}
                  getOptionLabel={(option) => {
                    if (!option || typeof option !== 'object') return ''
                    return option.nombre && option.direccion ? `${option.nombre} - ${option.direccion}` : ''
                  }}
                  isOptionEqualToValue={(option, value) => option?.codGaleria === value?.codGaleria}
                  disabled={esVirtualYVendedor || isSubmitting || proponeGaleria}
                  onChange={(e, value) => {
                    setGaleria(value)
                    handleDirGaleriaChange(e, value)
                  }}
                  value={galeria || null}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <>
                          Galería <Asterisco />
                        </>
                      }
                      fullWidth
                      placeholder="Seleccionar"
                      disabled={isSubmitting || proponeGaleria || esVirtualYVendedor}
                      helperText={
                        !proponeGaleria &&
                        (errorDirGaleria || (errors.dirGaleria && String(errors?.dirGaleria?.message)))
                      }
                      FormHelperTextProps={{ error: true }}
                      color={errorDirGaleria || errors.dirGaleria ? 'error' : 'primary'}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                md={3}
                sm={4}
                xs={12}
                mt={isMobile ? 2 : 0}
                sx={{ '& p': { position: 'absolute', top: '100%' } }}
              >
                <TextField
                  {...register('numLocal')}
                  fullWidth
                  label={
                    <>
                      Nº local <Asterisco />
                    </>
                  }
                  placeholder="10"
                  InputLabelProps={{ shrink: true }}
                  disabled={isSubmitting || esVirtualYVendedor || proponeGaleria}
                  helperText={!proponeGaleria && errors.numLocal && errors.numLocal.message}
                  FormHelperTextProps={{ error: true }}
                  color={errors.numLocal ? 'error' : 'secondary'}
                  inputProps={{ maxLength: MAX_NUM_LOCAL }}
                >
                  {locales &&
                    locales.map((local) => (
                      <MenuItem key={local.id} value={local.id}>
                        {local.numero + ' - ' + local.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isSubmitting || proponeGaleria || esVirtualYVendedor}
                  sx={{
                    mt: 3,
                    bgcolor: '#818181',
                    ':hover': { bgcolor: '#9b9a9a' }
                  }}
                  onClick={() => {
                    setProponeGaleria(true)
                    setEsGaleria(false)
                    setCenter('')
                  }}
                >
                  No encuentro la galería
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {proponeGaleria && !esGaleria && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
              Proponer Galería
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  {...register('nombreGaleria')}
                  label={
                    <>
                      Nombre de la galería <Asterisco />
                    </>
                  }
                  fullWidth
                  disabled={isSubmitting || esVirtualYVendedor}
                  InputLabelProps={local && { shrink: !!local }}
                  helperText={errors.nombreGaleria && String(errors?.nombreGaleria?.message)}
                  FormHelperTextProps={{ error: true }}
                  color={errors.nombreGaleria ? 'error' : 'secondary'}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  {...register('dirGaleria')}
                  value={newDirGaleria}
                  onChange={handleNewDirGaleriaChange}
                  label={
                    <>
                      Dirección de la galería <Asterisco />
                    </>
                  }
                  fullWidth
                  placeholder="Av. Avellaneda 2300"
                  disabled={isSubmitting || esVirtualYVendedor}
                  InputLabelProps={{ shrink: true }}
                  helperText={errors.dirGaleria && String(errors?.dirGaleria?.message)}
                  FormHelperTextProps={{ error: true }}
                  color={newDirGaleria.length === 0 && errors.dirGaleria ? 'error' : 'primary'}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  {...register('numGaleriaLocal')}
                  fullWidth
                  label={
                    <>
                      Nº Local <Asterisco />
                    </>
                  }
                  placeholder="10"
                  disabled={isSubmitting || esVirtualYVendedor}
                  helperText={errors.numGaleriaLocal && String(errors?.numGaleriaLocal?.message)}
                  FormHelperTextProps={{ error: true }}
                  color={errors.numGaleriaLocal ? 'error' : 'secondary'}
                  inputProps={{ maxLength: MAX_NUM_LOCAL }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: '12px', width: '100%' }}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting || esVirtualYVendedor}
                    color="error"
                    onClick={() => setProponeGaleria(false)}
                  >
                    Cancelar
                  </Button>

                  <Button
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting || esVirtualYVendedor}
                    color="primary"
                    onClick={handleProponeGaleria}
                  >
                    Confirmar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <AlertDialog
        open={multiplesResultados.length > 0}
        title="Elegí la dirección correcta"
        disagreeText="Cancelar"
        onClose={() => {
          setMultiplesResultados([])
          SnackBarUtils.error('No se completó la validación de la dirección')
        }}
      >
        <Grid container spacing={2}>
          {multiplesResultados.map((dir, index) => (
            <Grid item xs={12} key={index}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={async () => {
                  setValue('dirLocal', dir.direccionNormalizada)
                  await validarDirLocal(dir.direccionNormalizada, null, false, dir)
                }}
              >
                {dir.direccionNormalizada}
              </Button>
            </Grid>
          ))}
        </Grid>
      </AlertDialog>

      {loadingDir ? (
        <Loading />
      ) : (
        center !== '-' &&
        center !== '' &&
        showMap && (
          <Grid item xs={12} marginTop={2}>
            <Mapa center={center} />
          </Grid>
        )
      )}
    </Box>
  )
}

export default FormDireccion
