import { FaqDTO, HorarioDTO, LocalVendedorDTO } from "../../../dto/LocalDTO";

export type galleryType = {
  id: number;
  name: string;
  address: string;
};

export type localesType = {
  id: number;
  name: string;
  numero: number;
};

export const mockData: {
  galerias: galleryType[];
  galeriaLocales: {
    [key: string]: localesType[];
  };
} = {
  galerias: [
    {
      id: 1,
      name: "Galeria 1",
      address: "Calle falsa 123",
    },
    {
      id: 2,
      name: "Galeria 2",
      address: "Calle falsa 456",
    },
    {
      id: 3,
      name: "Galeria 3",
      address: "Calle falsa 789",
    }
  ],
  galeriaLocales: {
    1: [
      {
        id: 1,
        name: "Local 1",
        numero: 1,
      },
      {
        id: 2,
        name: "Local 2",
        numero: 2,
      }
    ],
    2: [
      {
        id: 3,
        name: "Local 3",
        numero: 3,
      },
      {
        id: 4,
        name: "Local 4",
        numero: 4,
      }
    ],
    3: [
      {
        id: 5,
        name: "Local 5",
        numero: 5,
      }
    ],
  },
};

export type faqType = {
  idLocal?: number;
  pregunta?: string;
  respuesta?: string;
};

export type medioContactoType = {
  descripcion?: string;
  tipo: number;
  valor?: string;
};

export type postLocalType = {
  [key: string]: any;
  idLocal?: number;
  codLocal?:string;
  idVendedor?: number;
  aceptaTyC?: boolean;
  //caracteristicasList: number[];
  caracteristicasId?: any[];
  clave?: string;
  compraMinimaParaEnvio?: any;
  desc?: string;
  galeriaPropuestaDireccion?: string;
  dirLocal?: string;
  faq?: faqType[];
  horarioList?: HorarioDTO[];
  idGaleria?: number;
  imei?: string;
  latLocal?: number;
  longLocal?: number;
  mail?: string;
  medioDeContactoList?: medioContactoType[];
  modeloDisp?: string;
  galeriaPropuestaNombre?: string;
  nombreLocal?: string;
  galeriaPropuestaNumero?: string;
  numLocal?: string;
  galeriaPropuesta?: boolean;
  push_id?: string;
  pwd?: string;
  contrasenia?: string;
  rubrosId?: boolean[];
  telefono?: string;
  usuario?: string;
  versionAndroid?: string;
  versionApp?: string;
  whatsapp?: string;
  captcha?: any;
};

export type postLocalResponseType = {
  errorCode: number;
  errorMessage: string;
  message: string;
};

export type caracteristicasType = {
  abreviatura: string;
  caracteristicaLocalId: number;
  enBusquedaDefault: boolean;
  nombre: string;
  oculto: boolean;
  orden: number;
};

export type caracteristicasResponseType = {
  data: caracteristicasType[];
  errorCode: number;
  errorMessage: string;
  message: string;
};

export type galeriasType = {
  direccion: string;
  fechaBaja: string;
  codGaleria: string;
  idGaleria: number;
  id: number;
  latitud: number;
  longitud: number;
  nombre: string;
};

export type galeriasResponseType = {
  errorCode: number;
  errorMessage: string;
  data: galeriasType[];
  message: string;
};

export type faqSugestionType = {
  errorCode: number;
  errorMessage: string;
  faq: faqType[];
  message: string;
};

export interface MedioDeContactoList {
  tipo: number;
  prefijo: string | null;
  valor: string;
  descripcion?: string;
}

export type DiaType = "Lunes" | "Martes" | "Miércoles" | "Jueves" | "Viernes" | "Sábado" | "Domingo";

export interface Config {
  name: string;
  value: string;
}

export interface CaracteristicasId {
  id: number;
  nombre: string;
  activo: boolean;
}

export interface Direccion {
  direccionNormalizada: string;
  estaFueraDeZona: boolean;
  codGaleria: string;
  latitud: number;
  longitud: number;
  multiplesResultados: Direccion[];
}

export interface ITenant {
  idTenant: number;
  idPais: number;
  nombre: string;
  path: string;
  latitud: number;
  longitud: number;
  radioMetros: number;
  logo: any;
  dominio: string;
  pais: IPais;
  config: Config[];
}

export interface IPais {
  id: number;
  nombre: string;
  codigoPais: number;
  codigoTelefonico: number;
  codigoWhatsapp: number;
  longitudWhatsapp: number;
}
