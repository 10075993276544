import { Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useState } from "react"

const DisableRetailPrice = ({ open, openStatus, setCanRetailSell }: any) => {

    const handleDisableRetail = async () => {
        openStatus(false)
    }

    const handleKeepRetail = async () => {
        setCanRetailSell(false)
        openStatus(false)
    }

    return (
      <Dialog open={open} sx={{ textAlign: 'center' }}>
        <DialogTitle>
          Remover precio minorista
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            El perfil del local dice que venden por menor.<br />
            ¿Por qué motivo no le querés cargar un precio minorista a este producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '20px' }}>
          <Button sx={{ width: '100%' }} variant='contained' onClick={handleKeepRetail}>Vendo minorista pero este producto<br />solamente lo vendo por mayor</Button>
          <Button sx={{ width: '100%' }} variant='contained' onClick={handleDisableRetail}>
            No soy minorista, corregir mi perfil
          </Button>
          <Button onClick={() => openStatus(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default DisableRetailPrice
