import { Checkbox, styled } from '@mui/material'

export const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    borderRadius: '4px',
    width: '24px',
    height: '24px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #C4C4C4',
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center'
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    borderColor: '#C4C4C4',
    backgroundColor: '#FFFFFF',
    color: '#5983EF'
  },
  '&:hover .MuiSvgIcon-root': {
    borderColor: '#5983EF'
  }
})

export function SquareIcon() {
  return (
    <span
      style={{
        width: '24px',
        height: '24px',
        display: 'inline-block',
        borderRadius: '4px',
        border: '1px solid #C4C4C4',
        backgroundColor: '#FFFFFF'
      }}
    ></span>
  )
}
