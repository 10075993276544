/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Columna, CsvEquivalencia, CsvTemplate, ISetEquivalencias } from './types/importacion'
import Loading from '../../../components/Common/Loading'
import { useStoreImportacion } from './store/store-importacion'
import NoImportarCheckbox from './components/NoImportarCheckbox'
import { areValidEquivalencias, getDefaultValue } from './utils/helpers'
import { ComponenteEquivalencias, ComponenteEquivalenciasAtributos } from '../ColumnasEquivalencias'
import DescripcionPaso from './components/DescripcionPaso'
import { useResponsive } from '../../../hooks/useResponsive.hook'
import useCsvTemplate from './hooks/useCsvTemplate'
import { Atributo, SaveAtributos } from '../types'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { SetAtributosEquivalencias } from '../services/impo-expo.service'
import { useAppStore } from '../../../store/app-store'
import { GrayButtonSx } from '../../../styles/GrayButtonSx'

const ID_COLUMN = 'id'

interface Props extends ISetEquivalencias {
  columnas: Columna[]
  csvEquivalencias?: CsvEquivalencia[]
  setCsvEquivalencias?: (csvEquivalencias: CsvEquivalencia[]) => void
  setAtributosSincronizar?: (atributos: Columna[]) => void
  atributosTemplate?: Atributo[]
  callback?: (some?: any) => Promise<void>
}

interface AsignarColumnasProps extends Partial<Props> {
  csvTemplate: CsvTemplate[]
  soloAtributos?: boolean
  eqs?: SaveAtributos[]
  setEqs?: (eqs: SaveAtributos[]) => void
}

// eslint-disable-next-line react/display-name
const AsignarColumnasMobile = memo(
  ({
    csvTemplate,
    columnas,
    equivalencias,
    setEquivalencias,
    csvEquivalencias,
    setCsvEquivalencias,
    soloAtributos
  }: AsignarColumnasProps) => {
    if (!columnas || !equivalencias || !setEquivalencias || !csvTemplate) return <></>

    let csv = csvTemplate

    if (soloAtributos) {
      // Filtra csvTemplate para incluir solo las columnas especificadas
      csv = csvTemplate.filter((colCsv) =>
        ['Talles', 'Colores', 'Composición', 'Medida', 'Tamaño'].includes(colCsv.columna)
      )
    }

    return (
      <Grid mt={1} container spacing={2} display="flex" justifyContent={'center'}>
        {csv.map((colCsv, i) => {
          if (colCsv.columna === ID_COLUMN) return null
          const col = equivalencias.find((eq) => Object.keys(eq)[0] === colCsv.columna)?.[colCsv.columna]

          return (
            <Grid item xs={12} sm={6} key={i}>
              <Card key={i}>
                <CardContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'left',
                    flexDirection: 'column',
                    gap: '12px'
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography fontSize="18px">
                      {colCsv.descripcion} {!colCsv.obligatorio && '(Opcional)'}
                    </Typography>
                    {colCsv.ejemplo && (
                      <Typography variant="subtitle2" color="GrayText">
                        Ejemplo: {colCsv.ejemplo}
                      </Typography>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <ComponenteEquivalencias
                      columnas={columnas}
                      csv={colCsv}
                      equivalencias={equivalencias}
                      setEquivalencias={setEquivalencias}
                      disabled={col?.no_importar}
                      csvEquivalencias={csvEquivalencias}
                      setCsvEquivalencias={setCsvEquivalencias}
                    />

                    {!colCsv.obligatorio && (
                      <NoImportarCheckbox
                        checked={col?.no_importar || false}
                        element={colCsv.columna}
                        equivalencias={equivalencias}
                        setEquivalencias={setEquivalencias}
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    )
  }
)

// eslint-disable-next-line react/display-name
const AsignarColumnasDesktop = memo(
  ({
    csvTemplate,
    columnas,
    equivalencias,
    setEquivalencias,
    csvEquivalencias,
    setCsvEquivalencias,
    soloAtributos,
    eqs,
    setEqs,
    atributosTemplate
  }: AsignarColumnasProps) => {
    if (!columnas || !equivalencias || !setEquivalencias || !csvTemplate) return <></>

    return (
      <TableContainer sx={{ maxHeight: '400px', overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                {soloAtributos ? 'Atributo en WooCommerce' : 'Columna en Avellaneda a un Toque'}
              </TableCell>
              <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                {soloAtributos ? 'Atributo en Avellaneda a un Toque' : 'Columna en tu archivo Excel o CSV'}
              </TableCell>
            </TableRow>
          </TableHead>
          {soloAtributos ? (
            <TableBody>
              {columnas.map((col, i) => {
                return (
                  <TableRow key={col.nombre + i}>
                    <TableCell width="600px">
                      <Typography>{col.nombre}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" gap="16px">
                        <ComponenteEquivalenciasAtributos
                          atributosTemplate={atributosTemplate}
                          columna={col}
                          equivalencias={eqs}
                          setEquivalencias={setEqs}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          ) : (
            <TableBody>
              {csvTemplate.map((colCsv, i) => {
                if (colCsv.columna === ID_COLUMN) return null

                const col = equivalencias.find((eq) => Object.keys(eq)[0] === colCsv.columna)?.[colCsv.columna]

                return (
                  <TableRow key={colCsv.columna + i}>
                    <TableCell width="600px">
                      <Typography fontSize={{ xs: 12, md: 16 }} fontWeight="bold" color="#8E8E8E">
                        {colCsv.descripcion}
                      </Typography>
                      {colCsv.ejemplo && (
                        <Typography fontSize={{ xs: 12, md: 16 }} fontWeight="bold" color="#8E8E8E">
                          Ejemplo: {colCsv.ejemplo}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" gap="16px">
                        <ComponenteEquivalencias
                          columnas={columnas}
                          csv={colCsv}
                          equivalencias={equivalencias}
                          setEquivalencias={setEquivalencias}
                          disabled={col?.no_importar}
                          csvEquivalencias={csvEquivalencias}
                          setCsvEquivalencias={setCsvEquivalencias}
                          conEjemplo={false}
                        />

                        <Box>
                          {!colCsv.obligatorio && (
                            <NoImportarCheckbox
                              checked={col?.no_importar || false}
                              element={colCsv.columna}
                              equivalencias={equivalencias}
                              setEquivalencias={setEquivalencias}
                            />
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    )
  }
)

const Descripcion = memo(() => {
  const { isMobile } = useResponsive()

  const descripcion = useMemo(() => {
    return isMobile
      ? 'Asigná por cada columna de Avellaneda a un Toque la columna de tu archivo Excel o CSV que hace referencia'
      : `Seleccioná en cada columna de Avellaneda a un Toque la columna de tu Archivo Excel o CSV que corresponda.`
  }, [isMobile])
  return (
    <DescripcionPaso>
      {descripcion}. Si la columna está marcada como OPCIONAL, podés tildar la cajita de No Importar en caso de no
      querer importarla.
    </DescripcionPaso>
  )
})

const AsignarColumnas = ({
  columnas,
  equivalencias,
  setEquivalencias,
  csvEquivalencias,
  setCsvEquivalencias
}: Props) => {
  const { setIsValid } = useStoreImportacion((state) => state)
  const { csvTemplate } = useCsvTemplate()
  const { isMobile } = useResponsive()

  useEffect(() => {
    if (!csvTemplate) return

    const newEquivalencias = csvTemplate.map((colCsv) => ({
      [colCsv.columna]: {
        value: getDefaultValue(colCsv, columnas)?.nombre || '',
        no_importar: colCsv.columna === ID_COLUMN
      }
    }))

    setEquivalencias(newEquivalencias)
  }, [csvTemplate, columnas, setEquivalencias])

  useEffect(() => {
    setIsValid(areValidEquivalencias(equivalencias))
  }, [equivalencias, setIsValid])

  if (!csvTemplate || !csvTemplate?.length) return <Loading center />

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Descripcion />

      {/* Asginar Columnas */}
      {isMobile ? (
        <AsignarColumnasMobile
          csvTemplate={csvTemplate}
          columnas={columnas}
          equivalencias={equivalencias}
          setEquivalencias={setEquivalencias}
          csvEquivalencias={csvEquivalencias}
          setCsvEquivalencias={setCsvEquivalencias}
        />
      ) : (
        <AsignarColumnasDesktop
          csvTemplate={csvTemplate}
          columnas={columnas}
          equivalencias={equivalencias}
          setEquivalencias={setEquivalencias}
          csvEquivalencias={csvEquivalencias}
          setCsvEquivalencias={setCsvEquivalencias}
        />
      )}
    </Box>
  )
}

export const AsignarColumnasImportacion = ({
  columnas,
  equivalencias,
  setEquivalencias,
  setAtributosSincronizar,
  atributosTemplate,
  callback
}: Props) => {
  const codLocal = useAppStore((state) => state.codLocal)
  const { setIsValid } = useStoreImportacion((state) => state)
  const { csvTemplate } = useCsvTemplate()
  const { isMobile } = useResponsive()
  const [eqs, setEqs] = useState<SaveAtributos[]>([])
  const [loading, setLoading] = useState(false)

  const sincronizar = useCallback(async () => {
    if (eqs.length !== columnas.length) return SnackBarUtils.error('Asigná todas las columna para sincronizar')

    setLoading(true)
    try {
      await SetAtributosEquivalencias(codLocal, eqs)
      if (callback) await Promise.resolve(callback())
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [eqs, codLocal, columnas, callback])

  useEffect(() => {
    if (!csvTemplate) return

    const newEquivalencias = csvTemplate.map((colCsv) => ({
      [colCsv.columna]: {
        value: getDefaultValue(colCsv, columnas)?.nombre || '',
        no_importar: colCsv.columna === ID_COLUMN
      }
    }))

    setEquivalencias(newEquivalencias)
  }, [csvTemplate, columnas, setEquivalencias])

  useEffect(() => {
    setIsValid(areValidEquivalencias(equivalencias))
  }, [equivalencias, setIsValid])

  if (!csvTemplate || !csvTemplate?.length) return <Loading center />

  return (
    <Box>
      <Descripcion />

      <Box display="flex" height={{ md: '300px' }} overflow="auto">
        {isMobile ? (
          <AsignarColumnasMobile
            csvTemplate={csvTemplate}
            columnas={columnas}
            equivalencias={equivalencias}
            setEquivalencias={setEquivalencias}
            soloAtributos={true}
            eqs={eqs}
            setEqs={setEqs}
          />
        ) : (
          <AsignarColumnasDesktop
            csvTemplate={csvTemplate}
            columnas={columnas}
            equivalencias={equivalencias}
            setEquivalencias={setEquivalencias}
            soloAtributos={true}
            eqs={eqs}
            setEqs={setEqs}
            atributosTemplate={atributosTemplate}
          />
        )}
      </Box>

      <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
        <Button variant="contained" onClick={sincronizar} disabled={loading || eqs.length !== columnas.length}>
          {loading ? 'Sincronizando' : 'Sincronizar'}
        </Button>
        <Button
          variant="contained"
          sx={(theme) => {
            return { ...GrayButtonSx(theme) }
          }}
          disabled={loading}
          onClick={() => {
            setEquivalencias([])
            if (setAtributosSincronizar) setAtributosSincronizar([])
          }}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  )
}

export default AsignarColumnas
