import { Button, Input } from "@mui/material";
import { isMobile } from "react-device-detect";
import { FaEdit, FaSave } from "react-icons/fa";
import { IConfig } from "../../pages/AdminAbmConfigPage";
import { esPar } from "../../utils/utils";
import { styled } from "@mui/material";

const Nombre = styled("span")(({ theme }) => ({
  fontSize: "1rem",
  alignSelf: "center",
  [theme.breakpoints.down(1024)]: {
    fontSize: "1rem",
    width: "90%",
    overflowWrap: "anywhere",
  },
}));

const Valor = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1rem",
  alignSelf: "center",
  [theme.breakpoints.down(1024)]: {
    fontSize: "0.8rem",
    width: "90%",
    overflowWrap: "anywhere",
  },
}));

const ContainerProps = styled("div")(({ theme }) => ({
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down(768)]: {
    flexDirection: "column",
  },
}));

interface AdminAbmConfigurationProps {
  config: IConfig;
  updateValue: (name: string, newValue: string) => void;
  handleEdit: (name: string) => void;
  handleInputChange: (name: string, newValue: string) => void;
  value: number;
}

const AdminAbmConfiguration = ({
  config,
  handleEdit,
  handleInputChange,
  updateValue,
  value,
}: AdminAbmConfigurationProps) => {
  const handleButtonClick = () => {
    if (config.isEditing) {
      updateValue(config.name, config.newValue);
    } else {
      handleEdit(config.name);
    }
  };

  return (
    <div key={value} style={esPar(value) ? { background: "#f8f8f8" } : { background: "#e2e2e2" }} className="my-1">
      <div className="d-flex justify-content-between px-3 py-2">
        <ContainerProps>
          <Nombre className={`${config.isEditing && isMobile ? "font-weight-bolder" : ""} m-0`}>
            {config.name}
            {!isMobile && ":"}
          </Nombre>
          {/* Valor o Edición de valor */}
          {config.isEditing ? (
            <Input
              autoFocus
              fullWidth
              size="small"
              className="mx-3"
              defaultValue={config.value}
              onChange={({ target: { value } }) => {
                handleInputChange(config.name, value);
              }}
              onKeyPress={(e) => {
                e.key === "Enter" && updateValue(config.name, config.newValue);
              }}
            />
          ) : (
            <Valor className={`mx-3`}>{config.value}</Valor>
          )}
        </ContainerProps>

        {/* Botón */}
        <Button
          variant="contained"
          color={config.isEditing ? "success" : "primary"}
          onClick={handleButtonClick}
          className={"align-self-center"}
        >
          {isMobile ? config.isEditing ? <FaSave /> : <FaEdit /> : config.isEditing ? "Guardar" : "Editar"}
        </Button>
      </div>
    </div>
  );
};

export default AdminAbmConfiguration;
