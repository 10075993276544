import { Box, Collapse, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ExpandMore, OpenInNew, Refresh } from '@mui/icons-material'
import { memo, useCallback, useContext, useState } from 'react'
import { IntegracionesContext } from '../../../context/integraciones.context'
import ModalChangeEstado from './ModalChangeEstado'
import { formatDateTime } from '../../../../../utils/utils'
import { ResetWebHook } from '../../../services/estado.service'
import SnackBarUtils from '../../../../../utils/SnackBarUtils'
import { useAppStore } from '../../../../../store/app-store'
import { TabsContent } from '@radix-ui/react-tabs'
import { MenuTabs } from '../../../types'
import ButtonRounded from '../../../../../components/Common/utils/button/ButtonRounded'

const IntegracionesEstado = () => {
  const { tiendaEstado, getTiendaStatus } = useContext(IntegracionesContext)
  const [showConfigAvanzada, setShowConfigAvanzada] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [loadingChangingStatus, setLoadingChangingStatus] = useState(false)
  const [loadingResetWebbok, setLoadingResetWebbok] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codLocal = useAppStore((state) => state.codLocal)

  const resetWebHook = useCallback(async () => {
    setLoadingResetWebbok(true)
    try {
      const { data } = await ResetWebHook(codLocal)
      SnackBarUtils.success(data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingResetWebbok(false)
    }
  }, [codLocal])

  const getStatus = useCallback(async () => {
    setLoadingChangingStatus(true)
    try {
      await getTiendaStatus(codLocal)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingChangingStatus(false)
    }
  }, [getTiendaStatus, codLocal])

  if (!tiendaEstado) return null

  return (
    <TabsContent value={MenuTabs.Estado} className="w-100">
      <Box display="flex" flexDirection="column" gap="24px">
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          gap="20px"
          width="100%"
        >
          <Typography fontSize={24} fontWeight="bold">
            El estado de la conexión con tu tienda es:{' '}
            <span
              style={{
                color: tiendaEstado.woocommerce_status === 'OnLine' ? '#72c023' : '#ff6969'
              }}
            >
              {tiendaEstado.woocommerce_status + ` (últ chequeo ${formatDateTime(tiendaEstado.date)})`}
            </span>
          </Typography>
          <IconButton
            aria-label="refresh"
            color="inherit"
            onClick={getStatus}
            disabled={loadingChangingStatus}
            sx={{
              bgcolor: 'white',
              // rotate infinite animation
              animation: loadingChangingStatus ? 'rotate 1s linear infinite' : 'none',
              '@keyframes rotate': {
                '100%': {
                  transform: 'rotate(360deg)'
                }
              }
            }}
          >
            <Refresh />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          gap="20px"
        >
          <Typography display="flex" gap="8px" fontSize={16} fontWeight={600} color="#8E8E8E" width="100%">
            La dirección de tu tienda Woocommerce es:{' '}
            <Link
              sx={{ textDecoration: 'none', cursor: 'pointer', color: '#5983EF' }}
              display="flex"
              gap="8px"
              href={tiendaEstado.woocommerce_url}
              target="_blank"
            >
              {tiendaEstado.woocommerce_url} <OpenInNew fontSize="small" />
            </Link>
          </Typography>
          <Box width="100%" maxWidth="200px">
            <ButtonRounded fullWidth variant="outlined" onClick={() => setOpenModal(true)}>
              Cambiar
            </ButtonRounded>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="32px"
          bgcolor="white"
          p="16px"
          borderRadius="16px"
        >
          <Box
            padding="10px 0px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowConfigAvanzada(!showConfigAvanzada)}
          >
            <Typography fontSize={16} fontWeight="bold">
              Configuración avanzada
            </Typography>
            <Box
              sx={{
                transform: showConfigAvanzada ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.25s ease-in-out',
                transformOrigin: 'center'
              }}
            >
              <ExpandMore sx={{ fontSize: 50 }} />
            </Box>
          </Box>

          {/* {showConfigAvanzada && (
            <Box
              display="flex"
              alignSelf="flex-start"
              justifyContent="space-between"
              alignItems="center"
              gap="20px"
              borderTop="1px solid #B4B4B4"
              width="100%"
              pt="16px"
            >
              <Typography fontSize={14} color="#8E8E8E">
                Clave del cliente terminada en <span style={{ color: '#5983EF' }}>ABC1234 {'(7 char)'}</span>
              </Typography>
              <Box width="100%" maxWidth="250px">
                <ButtonRounded fullWidth variant="outlined" disabled={loadingResetWebbok} onClick={resetWebHook}>
                  {loadingResetWebbok ? 'Reseteando...' : 'Resetear webhook'}
                </ButtonRounded>
              </Box>
            </Box>
          )} */}
          <Collapse in={showConfigAvanzada} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <Box
              display="flex"
              alignSelf="flex-start"
              justifyContent="space-between"
              alignItems="center"
              gap="20px"
              borderTop="1px solid #B4B4B4"
              width="100%"
              pt="16px"
            >
              <Typography fontSize={14} color="#8E8E8E">
                Clave del cliente terminada en <span style={{ color: '#5983EF' }}>ABC1234 {'(7 char)'}</span>
              </Typography>
              <Box width="100%" maxWidth="250px">
                <ButtonRounded fullWidth variant="outlined" disabled={loadingResetWebbok} onClick={resetWebHook}>
                  {loadingResetWebbok ? 'Reseteando...' : 'Resetear webhook'}
                </ButtonRounded>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
      <ModalChangeEstado open={openModal} onClose={() => setOpenModal(false)} onConfirm={() => {}} />
    </TabsContent>
  )
}

export default memo(IntegracionesEstado)
