import { createContext, useState, useEffect, useCallback, useContext } from "react";
import GaleriaDTO from "../../../dto/GaleriaDTO";
import { getGalerias } from "../services/galerias.services";
import { INITIAL_ROWS_PER_PAGE } from "../components/desktop/paginadoTablaGalerias";
import { RolType } from "../../../components/Navbar/Sidebar/SidebarItems";
import { TenantsContext } from "../../../context/tenants.context";

export interface PaginatorGalerias {
  handleChangePage: (e: any, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const defaultPaginator: PaginatorGalerias = {
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
};

export interface IGalerias {
  idGaleria?: any;
  codGaleria?: string;
  nombre: string;
  direccion: string;
  latitud: number;
  longitud: number;
}

export interface newGaleria {
  nombre: string;
  direccion: string;
  /* latitud: number;
  longitud: number; */
}

interface GaleriasContextProps {
  galerias: IGalerias[];
  loading: boolean;
  paginator: PaginatorGalerias;
  page: number;
  rowsPerPage: number;
  searching: boolean;
  setSearching: (searching: boolean) => void;
  setLoading: (loading: boolean) => void;
  setFiltro: (filtro: string) => void;
  /*actions: Actions;
   */
  fetchData: () => Promise<void>;
}

// create context
export const GaleriasContext = createContext<GaleriasContextProps>({
  galerias: [],
  loading: false,
  paginator: defaultPaginator,
  page: 0,
  rowsPerPage: INITIAL_ROWS_PER_PAGE,
  searching: false,
  setLoading: () => {},
  setSearching: () => {},
  setFiltro: () => {},
  /* actions: defaultActions,
   */
  fetchData: async () => {},
});

// create provider
export const GaleriasProvider = ({ children }: any, props: any) => {
  const [galerias, setGalerias] = useState<IGalerias[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [filtro, setFiltro] = useState<string>("");
  //const [actions] = useState<Actions>(defaultActions); */
  const [paginator] = useState<PaginatorGalerias>(defaultPaginator);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);
  const { tenant } = useContext(TenantsContext);

  const fetchData = useCallback(async () => {
    const response = await getGalerias(tenant, filtro).then((res) => res.data);
    setGalerias(response.data);
  }, [filtro]);

  // Paginator
  paginator.handleChangePage = (e: any, newPage: number) => setPage(newPage);

  paginator.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!searching) return;
    setLoading(true);
    Promise.all([fetchData()])
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }, [filtro, searching, fetchData]);

  return (
    <GaleriasContext.Provider
      value={{
        galerias,
        loading,
        setLoading,
        paginator,
        page,
        rowsPerPage,
        setFiltro,
        //actions,
        searching,
        setSearching,
        fetchData,
      }}
    >
      {children}
    </GaleriasContext.Provider>
  );
};
