import React, { useEffect, useState, useContext } from 'react'
import '../styles/adminLocal.scss'
import AdminLocalMainInfo from '../components/AdminLocal/AdminLocalMainInfo'
import AdminLocalContracts from '../components/AdminLocal/AdminLocalContracts'
import AdminLocalState from '../components/AdminLocal/AdminLocalState'
import AdminLocalContact from '../components/AdminLocal/AdminLocalContact'
import Calificaciones from '../components/AdminLocal/AdminLocalCalificaciones'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { axiosInstanceMain } from '../utils/axiosGlobalUtils'
import ComboBox from '../components/Common/ComboBox'
import { useNavigate, useParams } from 'react-router-dom'
import SnackBarUtils from '../utils/SnackBarUtils'
import {
  Container,
  Divider,
  Paper,
  useMediaQuery,
  useTheme,
  styled,
  FormControlLabel,
  Switch,
  alpha
} from '@mui/material'
import { getLocal as getLocalVendedor } from './AbmLocalPage/services/locales.service'
import FormLocales from './AbmLocalPage/components/Form'
import { connect } from 'react-redux'
import Navbar from '../components/Navbar'
import { RolType } from '../components/Navbar/Sidebar/SidebarItems'
import AdminLocalHistorias from '../components/AdminLocal/AdminLocalHistorias'
import { format } from 'date-fns'
import Footer from './Home/components/Footer'
import useLocal from './Products/hooks/locals.hook'
import DialogShareLink from './DialogCopySharelink'
import { TenantsContext } from '../context/tenants.context'
import { LocalAdminDTO } from '../dto/LocalDTO'
import { green } from '@mui/material/colors'

const StyledSwitch = styled(Switch)(({ theme }) => ({
  marginTop: '8px',
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.grey[900],
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.success.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.success.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[900],
    '&:hover': {
      backgroundColor: alpha(green[900], theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[900]
  }, //
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: theme.palette.common.white
  }
}))

const ContentObservaciones = styled('div')({ margin: '0 1rem' })

const ActionsGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '0rem 1rem',
  gap: '10px',
  flexWrap: 'wrap'
})

const Info = styled('div')({
  fontSize: 'calc(15px + 1vmin)',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif'
})

const ContentSwitch = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px'
})

const ContentTables = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
  padding: '0 10px',
  '@media (max-width: 425px)': {
    flexWrap: 'wrap'
  }
})

const AdminLocalPage: React.FC = (props: any) => {
  const [premise, setPremise] = useState<LocalAdminDTO>()
  const [fechaAlta, setFechaAlta] = useState<Date | null>()
  const [stickerDate, setStickerDate] = useState<Date | null>()
  const [important, setImportant] = useState<boolean>(false)
  const [virtual, setVirtual] = useState<boolean>(false)
  const navigate = useNavigate()
  const [autoApproval, setAutoApproval] = useState<number>(0) // 0 no definido - 1 Si - 2 No
  const [observations, setObservations] = useState<string>()
  const [soloLectura, setSoloLectura] = useState<boolean>(true)
  const [localAEditar, setLocalAEditar] = useState<any>(null)
  const [openDialogShareLink, setOpenDialogShareLink] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { token } = props.logged?.userLogged
  const { tenant } = useContext(TenantsContext)

  const { code } = useParams<{ code: any }>()
  const { shareLink, shareLinkMinorista } = useLocal(code, token)

  useEffect(() => {
    if (code) getLocal(code)
  }, [code])

  const getLocal = async (codLocal: any) => {
    axiosInstanceMain
      .get(`/admin/${tenant.path}/locales/${codLocal}`)
      .then((result) => {
        setPremise(result.data.data)
      })
      .catch((error) => {
        console.log(error)
        navigate('/abm-locales')
      })
  }

  const handleCopyShareLink = (link: string) => {
    navigator.clipboard.writeText(link)
    SnackBarUtils.success('Enlace copiado con éxito')
  }

  useEffect(() => {
    if (premise) {
      setFechaAlta(premise.fechaAltaFormateada ? new Date(`${premise.fechaAltaFormateada}T00:00`) : null)
      setStickerDate(
        premise.fechaPusoStickerFormateada ? new Date(`${premise.fechaPusoStickerFormateada}T00:00`) : null
      )
      setImportant(premise.importante || false)
      setVirtual(premise.virtual || false)
      premise && premise.aprobarProductosAuto === null
        ? setAutoApproval(0)
        : premise.aprobarProductosAuto === true
        ? setAutoApproval(1)
        : setAutoApproval(2)
      setObservations(
        premise.observaciones
          ? premise.observaciones?.replaceAll('"', '').replaceAll('\\n', String.fromCharCode(13, 10))
          : premise.observaciones
      )
    }
  }, [premise, code])

  const changeStickerDate = (value: Date) => {
    setStickerDate(value)
    axiosInstanceMain
      .patch(`/admin/${tenant.path}/locales/${premise?.codLocal}/fechapusosticker`, format(value, 'yyyy-MM-dd'))
      .then(() => {
        setStickerDate(value)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changeImportant = (e: any) => {
    const value = !important
    axiosInstanceMain
      .patch(`/admin/${tenant.path}/locales/${premise?.codLocal}/importante`, value)
      .then(() => {
        setImportant(value)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const changeVirtual = (e: any) => {
    const value = !virtual
    axiosInstanceMain
      .patch(`/admin/${tenant.path}/locales/${premise?.codLocal}/virtual`, value)
      .then(() => {
        setVirtual(value)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changeAutoApproval = (e: any) => {
    const selectedValue = e.target.value
    const value = selectedValue === '0' ? null : selectedValue === '1' ? true : false

    axiosInstanceMain
      .patch(`/admin/${tenant.path}/locales/${premise?.codLocal}/aprobar-productos-auto`, value)
      .then(() => {
        setAutoApproval(selectedValue)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changeObservations = (e: any) => {
    axiosInstanceMain
      .patch(`/admin/${tenant.path}/locales/${premise?.codLocal}/observaciones`, e.target.value)
      .then(() => {
        setObservations(e.target.value)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getLocalAEditar = async () => {
    try {
      if (!premise) return null
      const res = await getLocalVendedor(premise.codLocal, tenant)
      setLocalAEditar(res.data.data)
      return res.data.data
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Navbar
        title={
          isMobile
            ? `${premise?.nombre} | ${premise?.idLocal} | ${premise?.codLocal}`
            : `Admin Local: ${premise?.nombre} | ${premise?.idLocal} | ${premise?.codLocal}`
        }
        searchBar={true}
      />
      {!soloLectura && localAEditar ? (
        <Container maxWidth="md">
          {isMobile ? (
            <FormLocales
              setSoloLectura={setSoloLectura}
              local={localAEditar}
              props={props}
              getLocal={getLocal}
              setLoading={() => {}}
            />
          ) : (
            <Paper variant="outlined" sx={{ px: { md: '3em', xs: '0.5em' } }}>
              <FormLocales
                setSoloLectura={setSoloLectura}
                local={localAEditar}
                props={props}
                getLocal={getLocal}
                setLoading={() => {}}
              />
            </Paper>
          )}
        </Container>
      ) : (
        <>
          <AdminLocalMainInfo premise={premise} setSoloLectura={setSoloLectura} getLocalAEditar={getLocalAEditar} />
          <ContentTables>
            <AdminLocalContact premise={premise} />
            {!isMobile && <Divider orientation="vertical" style={{ margin: '0px 20px', height: '220px' }} />}
            <Calificaciones codLocal={premise?.codLocal} isAdmin={true} />
          </ContentTables>
          <AdminLocalState premise={premise} setPremise={setPremise} />
          <br />
          <ActionsGroup>
            <Info>Fecha Alta: {fechaAlta && format(fechaAlta, 'dd/MM/yyyy')}</Info>
            <ContentSwitch>
              <Info>Puso Sticker</Info>
              <DatePicker
                className="premise-info-child"
                // selected={
                //   typeof stickerDate?.getMonth === "function" ? stickerDate : null
                // }
                //selected={stickerDate}
                onChange={(dt: Date) => changeStickerDate(dt)}
                value={stickerDate ? format(stickerDate, 'dd/MM/yyyy') : ''}
              />
            </ContentSwitch>
            <ContentSwitch>
              <Info>Importante</Info>
              <FormControlLabel
                label=""
                //labelPlacement="start"
                control={<StyledSwitch onChange={(e) => changeImportant(e)} checked={important} />}
              />
            </ContentSwitch>
            <ContentSwitch>
              <Info>Virtual</Info>
              <FormControlLabel
                label=""
                control={<StyledSwitch onChange={(e) => changeVirtual(e)} checked={virtual} />}
              />
            </ContentSwitch>
            {shareLink && (
              <button
                className="btn btn-primary"
                onClick={() => (shareLinkMinorista ? setOpenDialogShareLink(true) : handleCopyShareLink(shareLink))}
              >
                Copiar link catálogo
              </button>
            )}
            <div className="premise-info-child">
              <ComboBox
                label="Aprobación automática"
                styles="premise-info-child"
                htmlFor="automaticApproval"
                value={autoApproval}
                onChange={changeAutoApproval}
                options={[
                  { value: 1, name: 'Sí' },
                  { value: 2, name: 'No' },
                  { value: 0, name: 'No Definido' }
                ]}
              />
            </div>
          </ActionsGroup>
          <label className="premise-info" style={{ ...(isMobile && { marginBottom: '30px' }) }}>
            Contratos
          </label>
          <AdminLocalContracts premise={premise} updateLocal={getLocal} props={props} />
          <label className="premise-info">Historias</label>
          {premise && <AdminLocalHistorias premise={premise} />}
          <ContentObservaciones>
            {/* <div className="form-group premise-info"> */}
            <Info>Observaciones</Info>
            <div>
              <textarea
                className="form-control"
                rows={3}
                onBlur={changeObservations}
                value={observations}
                onChange={(e) => {
                  setObservations(e.target.value)
                }}
              >
                {/* {observations} */}
              </textarea>
            </div>
            {/* </div> */}
          </ContentObservaciones>
          <DialogShareLink
            open={openDialogShareLink}
            setOpen={setOpenDialogShareLink}
            shareLink={shareLink}
            shareLinkMinorista={shareLinkMinorista}
            handleCopyShareLink={handleCopyShareLink}
          />
        </>
      )}
      <br />
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

export default connect(mapStateToProps)(AdminLocalPage)
