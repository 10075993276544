import { Dialog, DialogContent, IconButton } from '@mui/material'
import ImportarCatalogo, { Pasos } from './ImportarCatalogo'
import { useStoreImportacion } from './store/store-importacion'
import { memo, useCallback } from 'react'

interface Props {
  getProducts: () => Promise<any>
}

const Message = '¿Estás seguro que querés cancelar la importación? Se perderán todos los datos cargados.'

const DialogImportarCatalogo = ({ getProducts }: Props) => {
  const { open, setOpen, loading } = useStoreImportacion((state) => state)

  const handleClose = useCallback(
    (paso?: string, reset?: () => void) => {
      if (loading || !paso) return

      const confirmMessage = paso !== Pasos.ResultadoFinal ? Message : null

      if (!confirmMessage || window.confirm(confirmMessage)) setOpen(false)

      reset && reset()
    },
    [loading, setOpen]
  )

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      PaperProps={{
        sx: { borderRadius: '16px', p: '8px', bgcolor: '#F5F5F7' }
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <ImportarCatalogo getProducts={getProducts} closeButton={handleClose} />
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogImportarCatalogo)
