import { ITenant } from '../../../pages/AbmLocalPage/types'
import {
  axiosInstanceContratos,
  axiosInstanceMain
} from '../../../utils/axiosGlobalUtils'

export const getLocalesRelacionados = async (
  tenant: ITenant,
  codLocal: string
) =>
  await axiosInstanceMain.get(
    `/admin/${tenant.path}/locales/${codLocal}/relacionados`
  )

// Admin Local Contratos
export const getPagos = async (
  idContrato: number,
  page: number,
  pageSize: number
) =>
  await axiosInstanceContratos.get(
    `contratos/${idContrato}/pagos?page=${page}&size=${pageSize}`
  )

export const getLinks = async (
  idContrato: number,
  page: number,
  pageSize: number
) =>
  await axiosInstanceContratos.get(
    `contratos/${idContrato}/links-de-pago?page=${page}&size=${pageSize}`
  )
