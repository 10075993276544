import React, { useState, useCallback, memo, useEffect } from "react";

// components
import { Box, Typography, Paper, useTheme, useMediaQuery, Button, Divider } from "@mui/material";

// redux
import { connect } from "react-redux";
import setRedirectUrl from "../../redux/actions/redirection/setRedirectUrl";
import { useNavigate } from "react-router-dom";

interface CardProps {
  descripcion: React.ReactNode;
  logo: React.ReactElement;
  divider: boolean;
  botonVer?: string;
  botonPath?: string;
}

const CardEstadistica: React.FC<CardProps> = ({ descripcion, logo, divider, botonVer, botonPath }: CardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "20px",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {logo}
        </div>
        <Typography
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {descripcion}
        </Typography>
        {botonVer && (
          <Button
            size="small"
            sx={{
              color: "white",
              backgroundColor: "#e38e38",
              ...(isMobile && { marginLeft: "auto", marginRight: "-10px" }),
              "&:hover": {
                backgroundColor: "#d87a1b",
              },
            }}
            onClick={() => botonPath && navigate(`/${botonPath}`)}
          >
            <Typography
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontWeight: "bold",
                padding: "5px 10px 2px 10px",
                fontSize: isMobile ? "14px" : "16px",
              }}
            >
              {botonVer}
            </Typography>
          </Button>
        )}
      </Box>
      {divider && <Divider sx={{ width: "100%" }} />}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(CardEstadistica));
