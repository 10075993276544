import { ICtaCte } from '../../../dto/ContractDTO'
import { axiosInstanceContratos } from '../../../utils/axiosGlobalUtils'

export const getCtaCte = async (idLocal: number) =>
  await axiosInstanceContratos.get(`/contratos/local/${idLocal}/ctacte`)

export const editCtaCte = async (idProductoContrato: number, movimiento: ICtaCte) =>
  await axiosInstanceContratos.put(`/contratos/cuentaCorriente/${idProductoContrato}`, movimiento)

export const deleteCtaCte = async (idProductoContrato: number) =>
  await axiosInstanceContratos.delete(`/contratos/cuentaCorriente/${idProductoContrato}`)

export const getTiposMovimiento = async () => await axiosInstanceContratos.get('/contratos/cuentaCorriente/tipos')
