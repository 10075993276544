import { Autocomplete, MenuItem, TextField } from '@mui/material'
import { Rubro } from '../../Integraciones/types/responses-productos'
import useRubros from '../../Products/hooks/rubros.hook'

interface DesplegableRubrosPadresProps {
  onChange: (param?: any) => void
  prevValue?: any
}

export const flattenRubrosHierarchy = (rubros: Rubro[]): Rubro[] => {
  const flattened: Rubro[] = []
  for (const rubro of rubros) {
    flattened.push(rubro)
    if (rubro.rubros?.length > 0) {
      flattened.push(...flattenRubrosHierarchy(rubro.rubros))
    }
  }
  return flattened
}

const ninguno: Rubro = {
  activo: true,
  listable: true,
  idEspacioTemplate: 0,
  codRubro: "",
  nombre: 'Ninguno',
  orden: 0,
  codRubroPadre: null,
  nombreAnalytics: 'Ninguno',
  rubros: [],
  precioMinimo: 0,
  unidadDeMedidaList: [],
  productoAtributoList: [],
  monedaList: [],
  slug: '',
  urlFoto: '',
  urlIcono: '',
  urlImagen: ''
}

const DesplegableRubrosPadres = ({
  onChange,
  prevValue
}: DesplegableRubrosPadresProps) => {
  const { rubros } = useRubros()
  const rubrosFlatted = [ninguno, ...flattenRubrosHierarchy(rubros)]

  return (
    <Autocomplete
      options={rubrosFlatted}
      getOptionLabel={(rubro: Rubro) => rubro.nombreAnalytics || ''}
      value={prevValue === 0 ? ninguno : prevValue}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyPress={(e) => e.key === 'Enter'}
          variant="outlined"
          placeholder="Seleccione"
          InputLabelProps={{ disableAnimation: true }}
        />
      )}
      renderOption={(props, rubro) => (
        <MenuItem {...props} value={rubro.codRubro}>
          {rubro.nombreAnalytics}
        </MenuItem>
      )}
    />
  )
}

export default DesplegableRubrosPadres
