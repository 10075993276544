import { Box, Divider, Grid, Typography } from "@mui/material";
import SectionTitle from "../../common/SectionTitle";
import { Close } from "@mui/icons-material";
import { getFecha } from "../../../../utils/utils";
import { Fragment } from "react";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

const SoloLecturaHorarios = ({ local }: { local: LocalVendedorDTO }) => {
  return (
    <Grid container spacing={2}>
      <SectionTitle title="Horarios" />

      {/* <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', width: '100%'}}>
        <Chip label="Abierto" color="success" />
        <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <Check color="success" /> Abierto
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <Close color="error" /> Cerrado
        </Box>
        <Chip label="Cerrado" color="error" />
      </Box> */}

      <Grid item xs={12}>
        {local.horarioList?.length === 0 && (
          <Typography variant="body1">No se cargaron horarios</Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: "row",
            gap: "8px",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          {local.horarioList?.map((h, i) => (
            <Fragment key={i}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  "@media (max-width: 768px)": {
                    flexDirection: "row",
                    gap: "8px",
                  },
                }}
              >
                <Typography
                  component="b"
                  sx={{ display: "flex", fontWeight: "bold" }}
                >
                  {getFecha(h)}
                  <Box
                    component="span"
                    sx={{
                      display: "none",
                      "@media (max-width: 768px)": { display: "block" },
                    }}
                  >
                    :
                  </Box>
                </Typography>
                <Box>
                  {h.cerrado ? <Close color="error" /> : h.horario || "-"}
                </Box>
              </Box>
              {i < local.horarioList?.length - 1 && (
                <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              )}
            </Fragment>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SoloLecturaHorarios;
