export const type = 'setRedirectUrl';

const setRedirectUrl = (data: string | null) => {
    return {
        type,
        payload: data
    };
};

export default setRedirectUrl;
