import { Box, Button, Typography } from "@mui/material";
import Buscador from "../../../../components/Common/Buscador";
import { FaRedo } from "react-icons/fa";
import { memo, useContext } from "react";
import { WhatsappContext } from "../../context/whatsapp.context";

const ContactosHeader = () => {
  const {
    contactos,
    contactosArchivados,
    viendoContactosArchivados,
    setContactosFiltrados,
    setIsSearching,
    getContactos,
  } = useContext(WhatsappContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        padding: "10px",
        borderBottom: "1px solid #555555",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          "@media (max-width: 1024px)": { flexDirection: "column" },
        }}
      >
        <Typography variant="h5">Contactos</Typography>

        <Box width="100%">
          <Buscador
            onlySearchField
            elements={
              viendoContactosArchivados ? contactosArchivados : contactos
            }
            setElementsSearched={setContactosFiltrados}
            searchBy={["number"]}
            variant="filled"
            setIsSearching={setIsSearching}
            textFieldStyles={{ backgroundColor: "#ffffff" }}
          />
        </Box>
      </Box>

      <Button onClick={() => getContactos(null)} variant="contained" color="info">
        <FaRedo />
      </Button>
    </Box>
  );
};

export default memo(ContactosHeader);
