import { useState, ChangeEvent, useContext, memo, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import {
  Autocomplete,
  Button,
  MenuItem,
  FormControl,
  TextField,
  InputLabel
} from "@mui/material";
import { Box } from "@mui/system";
import { isMobile } from "react-device-detect";
import { IProductoContrato } from "../../pages/AdminProducts/types/producto";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IFiltrosHistorial } from "../../dto/ContractDTO";

const Todos: IProductoContrato = {
  idContratoProducto: null,
  idContratoProductoTipo: 0,
  nombre: "Todos",
  nombreRequest: "",
  duracion: "",
  idCuentaTipo: 0,
  color: "",
  precio: 0,
  flagNotificar: false,
};

const FiltrosGalerias = ({
  filtros,
  setFiltros,
  productos,
  loading,
}: {
  filtros: any;
  setFiltros: (f: IFiltrosHistorial) => void;
  productos: IProductoContrato[];
  loading: boolean;
}) => {
  const [producto, setProducto] = useState<IProductoContrato | null>(Todos);
  const [fechaInicio, setFechaInicio] = useState<any>();
  const [fechaFin, setFechaFin] = useState<any>();

  const listaProductos = [Todos, ...productos]

  const handleFormSubmit = () => {
    const fechaDesde = fechaInicio?.format("YYYY-MM-DD");
    const fechaHasta = fechaFin?.format("YYYY-MM-DD");
    setFiltros({
      idContratoProducto: producto?.idContratoProducto,
      fechaDesde,
      fechaHasta,
      size: filtros.size,
      page: filtros.page,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          gap: "10px",
          "@media (max-width: 768px)": {
            flexDirection: "column",
            gap: "14px",
          },
        }}
      >
        <FormControl
          sx={{
            width: "40%",
            "@media (max-width: 768px)": { width: "100%" },
          }}
        >
          <Autocomplete
            options={listaProductos}
            getOptionLabel={(producto: IProductoContrato) =>
              producto.nombre || ""
            }
            onChange={(event, value) => value && setProducto(value)}
            renderInput={(params) => (
              <TextField {...params} label="Producto" variant="outlined" />
            )}
            value={producto}
            renderOption={(props, producto) => (
              <MenuItem
                {...props}
                key={producto.idContratoProducto || "todos"}
                value={producto.idContratoProducto}
              >
                {producto.nombre}
              </MenuItem>
            )}
          />
        </FormControl>

        <FormControl
          sx={{
            "@media (max-width: 768px)": { width: "100%" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={loading}
              label="Fecha Desde"
              format="DD/MM/YYYY"
              value={fechaInicio}
              onChange={(date: any) => {
                date ? setFechaInicio(date) : setFechaInicio(null);
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  onKeyPress: (e) => e.key === "Enter" && handleFormSubmit(),
                  value: fechaInicio,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          sx={{
            "@media (max-width: 768px)": { width: "100%" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={loading}
              label="Fecha Hasta"
              format="DD/MM/YYYY"
              value={fechaFin}
              onChange={(date: any) =>
                date ? setFechaFin(date) : setFechaFin(null)
              }
              slotProps={{
                textField: {
                  variant: "outlined",
                  onKeyPress: (e) => e.key === "Enter" && handleFormSubmit(),
                  value: fechaFin,
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <Button
            disabled={loading}
            variant="contained"
            size="large"
            onClick={handleFormSubmit}
            //fullWidth
            sx={{
              height: "50px",
              "@media (max-width: 768px)": {
                display: "flex",
                gap: "10px",
              },
            }}
          >
            <Box
              sx={{
                display: "none",
                "@media (max-width: 768px)": {
                  display: "block",
                },
              }}
            >
              Filtrar
            </Box>
            <FaIcons.FaSearch style={{ margin: 0 }} />
          </Button>
        </FormControl>
      </Box>
    </>
  );
};

export default memo(FiltrosGalerias);
