import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const IntegracionesLoading = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="88vh" width="100%" gap="10px">
      <Typography variant="h6">Cargando Integraciones...</Typography>
    </Box>
  )
}

export default IntegracionesLoading
