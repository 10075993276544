import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import SectionTitle from "../../common/SectionTitle";
import { Mail, Phone, WhatsApp } from "@mui/icons-material";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

const SoloLecturaMediosDeContacto = ({ local }: { local: LocalVendedorDTO }) => {
  const telefono = local.medioDeContactoList.find((c) => c.tipo === 2);
  const email = local.medioDeContactoList.find((c) => c.tipo === 3)?.valor;
  const whatsapps = local.medioDeContactoList.filter((c) => c.tipo === 1);

  return (
    <Grid container spacing={2}>
      <SectionTitle title="Medios de contacto" />
      {!whatsapps.some((w) => w.valor !== "") && !telefono && !email && (
        <Grid item xs={12}>
          <Typography variant="body1">No se han ingresado medios de contacto</Typography>
        </Grid>
      )}

      {whatsapps.length > 0 && whatsapps.some((w) => w.valor !== "") && (
        <Grid item xs={12}>
          <Box>
            <WhatsApp color="success" fontSize="small" /> Whatsapp:{" "}
            <Typography component={"b"} variant="body1" fontWeight={"bold"}>
              {whatsapps.map((m, i) => (
                <Fragment key={i}>
                  {m.valor && `+${m.prefijo} ${m.valor}`}
                  {m.descripcion && ` (${m.descripcion})`}
                  {whatsapps.length > 1 && i < whatsapps.length - 1 && ", "}
                </Fragment>
              ))}
            </Typography>
          </Box>
        </Grid>
      )}

      {telefono && (
        <Grid item xs={12}>
          <Typography variant="body1">
            <Phone color="inherit" fontSize="small" /> Teléfono: <b>{`+${telefono.prefijo} ${telefono.valor}`}</b>
          </Typography>
        </Grid>
      )}

      {email && (
        <Grid item xs={12}>
          <Typography variant="body1">
            <Mail color="primary" fontSize="small" /> Email: <b>{email}</b>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SoloLecturaMediosDeContacto;
