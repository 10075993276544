import { StarBorder, Tour } from '@mui/icons-material'
import { Box, Chip } from '@mui/material'

const Estadisticas = ({ product }: { product: any }) => {
  return (
    <Box display="flex" flexDirection="row" gap="12px">
      <Chip icon={<Tour />} label={`Visitas: ${product.cantVisitas}`} />
      <Chip
        icon={<StarBorder />}
        label={`Favoritos: ${product.cantFavoritos}`}
      />
    </Box>
  )
}

export default Estadisticas
