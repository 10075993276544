import React, { useCallback, useEffect, useState } from 'react'
import {
  Typography,
  Button,
  Box,
  IconButton,
  DialogActions,
  Autocomplete,
  MenuItem,
  TextField,
  Dialog,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  useTheme,
  useMediaQuery
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IProductoContrato } from '../../pages/AdminProducts/types/producto'
import { crearContrato } from '../../pages/ContratosVendedor/components/contratos.services'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { IContrato, MedioPago } from '../../dto/ContractDTO'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Loading from '../Common/Loading'
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone'

interface CrearContratoProps {
  open: boolean
  idLocal: number
  productos: IProductoContrato[]
  openDialog: (open: boolean) => void
  updateContractsData: (idLocal: number) => void
  mediosDePago: MedioPago[]
}

const CrearContrato: React.FC<CrearContratoProps> = ({
  open,
  idLocal,
  productos,
  openDialog,
  updateContractsData,
  mediosDePago
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState<boolean>(false)
  const [medioPagoSeleccionado, setMedioPagoSeleccionado] = useState<MedioPago | null>(null)
  const [activacion, setActivacion] = useState<boolean>(false)
  const [observaciones, setObservaciones] = useState<string | null>()
  const [linkCopiado, setLinkCopiado] = useState<boolean>(false)
  const [mensajeEndpoint, setMensajeEndpoint] = useState<string>()
  const [linkDePago, setLinkDePago] = useState<string>()

  const schema = yup.object({
    producto: yup.string().required('Elija un producto'),
    medioDePago: yup.string().test('is-required', 'Elija un medio de pago', function (value) {
      return !activacion || (activacion && !!value)
    })
  })

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      producto: '',
      medioDePago: ''
    }
  })

  const handleCrearContrato = useCallback(
    async (data: any) => {
      let cerrar = true
      const newContract: IContrato = {
        producto: data.producto,
        medioDePago: data.medioDePago,
        observaciones,
        activacion,
        idLocal: idLocal
      }
      try {
        setLoading(true)
        await crearContrato(newContract).then((data) => {
          idLocal && updateContractsData(idLocal)
          if (activacion) {
            SnackBarUtils.success(data.data.message)
          } else {
            setMensajeEndpoint(data.data.message)
            setLinkDePago(data.data.data.link)
            cerrar = false
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
        cerrar && handleClose()
      }
    },
    [idLocal, activacion, medioPagoSeleccionado, observaciones]
  )

  useEffect(() => {
    if (linkCopiado) {
      SnackBarUtils.success('El link fue copiado al portapapeles')
    }
  }, [linkCopiado])

  const handleClose = () => {
    openDialog(false)
    reset()
    setActivacion(false)
    setMedioPagoSeleccionado(null)
    setObservaciones(null)
    setMensajeEndpoint(null)
    setLinkDePago(null)
    setLinkCopiado(false)
  }

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        PaperProps={{
          style: {
            overflowX: 'hidden'
          }
        }}
      >
        {mensajeEndpoint && linkDePago ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '20px',
              gap: '20px',
              padding: '8px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            >
              {mensajeEndpoint}
            </Typography>
            <Typography
              sx={{
                fontSize: '20px',
                textAlign: 'center'
              }}
            >
              <strong>Link de Pago: </strong>{' '}
              <a
                href={linkDePago}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                style={{
                  textDecoration: 'underline',
                  width: '100%'
                  //textAlign: 'center'
                }}
              >
                {linkDePago}{' '}
              </a>
              <ContentCopyTwoToneIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setLinkCopiado(true)
                    navigator.clipboard.writeText(linkDePago)
                  }}
                  color={linkCopiado ? 'primary' : 'action'}
                  titleAccess="Copiar link"
                />
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 40px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                Nuevo Contrato
              </Typography>
            </Box>
            <Box>
              <Autocomplete
                options={productos}
                disabled={loading}
                getOptionLabel={(producto: IProductoContrato) => producto.nombre || ''}
                onChange={(event, value) => {
                  if (value) {
                    setValue('producto', value.nombreRequest)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Producto"
                    variant="outlined"
                    //{...register("producto")}
                    error={!!errors.producto}
                    helperText={errors?.producto && String(errors?.producto?.message)}
                  />
                )}
                renderOption={(props, producto) => (
                  <MenuItem {...props} key={producto.idContratoProducto} value={producto.nombreRequest}>
                    {producto.nombre}
                  </MenuItem>
                )}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  marginTop: isMobile ? '10px' : '20px',
                  ...(!isMobile && {
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  })
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    disabled={loading}
                    checked={activacion}
                    onChange={(e) => {
                      setMedioPagoSeleccionado(null)
                      setActivacion(e.target.checked)
                    }}
                  />
                  <Typography
                    style={{
                      marginTop: '0.2rem'
                    }}
                  >
                    Activar automáticamente
                  </Typography>
                </Box>
                {activacion && (
                  <FormControl
                    error={!!errors.medioDePago}
                    sx={{
                      width: isMobile ? '100%' : '50%',
                      ...(isMobile && { marginTop: '10px' })
                    }}
                  >
                    <InputLabel id="select-medio-pago-label">Medio de Pago</InputLabel>
                    <Select
                      disabled={loading}
                      labelId="select-medio-pago-label"
                      label="Medio de Pago"
                      {...register('medioDePago')}
                      defaultValue=""
                      error={activacion && !!errors.medioDePago}
                    >
                      {mediosDePago.map((medio: MedioPago) => (
                        <MenuItem key={medio.idContratoMedioDePago} value={medio.nombreRequest}>
                          {medio.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors?.medioDePago && String(errors?.medioDePago?.message)}</FormHelperText>
                  </FormControl>
                )}
              </Box>
            </Box>
            <TextField
              disabled={loading}
              label="Observaciones"
              multiline
              rows={4}
              fullWidth
              value={observaciones}
              onChange={(e) => setObservaciones(e.target.value)}
              sx={{ marginTop: isMobile ? '10px' : '20px' }}
            />
            <Button
              disabled={loading}
              variant="contained"
              onClick={handleSubmit(handleCrearContrato)}
              color="success"
              sx={{ marginTop: '20px', width: '100%' }}
            >
              Crear
            </Button>
          </Box>
        )}
        <DialogActions>
          <IconButton
            disabled={loading}
            edge="end"
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              paddingRight: '16px'
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CrearContrato
