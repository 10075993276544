import { Send } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { WhatsappContext } from "../../context/whatsapp.context";

const ConversacionSendMessage = () => {
  const { sendMessage } = useContext(WhatsappContext);
  const [message, setMessage] = useState("");

  const handleSendMessage = async () => {
    if (message.trim().length === 0) return;

    const res = await sendMessage(message);
    console.log("Res SendMessage", res);
  };

  return (
    <Box
      sx={{
        bgcolor: "#ededed",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "8px",
        p: 2,
      }}
    >
      <TextField
        fullWidth
        autoFocus
        autoComplete="off"
        variant="outlined"
        label="Escribe un mensaje"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
      />
      <Button
        onClick={handleSendMessage}
        variant="contained"
        color="inherit"
        sx={{
          color: "#cddfdb",
          bgcolor: "#035d4d",
          ":hover": { bgcolor: "#02382e" },
        }}
      >
        <Send />
      </Button>
    </Box>
  );
};

export default ConversacionSendMessage;
