export const type = 'logOut';

const logOut = () => {
    return {
        type,
        payload: ''
    };
};

export default logOut;
