import { useContext, useEffect, useState } from "react";
import { getFaqs } from "../services/faq.service";
import { faqType } from "../types";
import { TenantsContext } from "../../../context/tenants.context";

export const useFaq = () => {
  const [faqs, setFaqs] = useState<faqType[]>([]);
  const { tenant } = useContext(TenantsContext);
  useEffect(() => {
    getFaqs(tenant).then((res) => {
      setFaqs(res.data.faq);
    });
  }, []);

  return { faqs };
};
