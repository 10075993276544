import React, { createContext, useCallback, useEffect, useState } from 'react'
import { MenuTabs, ITienda } from '../types'
import { GetEstadoTienda } from '../services/estado.service'
import { IEstadoTiendaResponse, ITiendaEstado } from '../types/responses'
import { IProductoTienda } from '../types/responses-productos'
import { GetProductosAA1T, GetProductosTienda } from '../services/productos.service'
import { GetNovedades } from '../services/novedades.service'
import { Novedad } from '../types/responses-novedades'
import { useAppStore } from '../../../store/app-store'

export interface AtributoValorASincronizar {
  atributo: string
  valor: string
}

interface IntegracionesContextProps {
  completoOnboarding: boolean
  setCompletoOnboarding: React.Dispatch<React.SetStateAction<boolean>>
  menuTab: MenuTabs
  setMenuTab: React.Dispatch<React.SetStateAction<MenuTabs>>
  cantNovedades: number
  tiendaSeleccionada: ITienda | null
  setTiendaSeleccionada: React.Dispatch<React.SetStateAction<ITienda | null>>
  irASeleccionarTienda: () => void
  tiendaEstado: ITiendaEstado | null
  getTiendaStatus: (codLocal: string) => Promise<IEstadoTiendaResponse | null>
  cantProductosAA1T: number
  setCantProductosAA1T: React.Dispatch<React.SetStateAction<number>>
  getProductsAA1T: (codLocal: string) => Promise<void>
  productosTienda: IProductoTienda[] | null
  getProductsTienda: (
    codLocal: string,
    idCategoria?: number | string,
    searchText?: string,
    sku?: string
  ) => Promise<void>
  loadingProductosAA1T: boolean
  loadingProductosTienda: boolean
  productosTiendaFiltered: IProductoTienda[]
  setProductosTiendaFiltered: React.Dispatch<React.SetStateAction<IProductoTienda[]>>
  productosAConfirmar: IProductoTienda[]
  setProductosAConfirmar: React.Dispatch<React.SetStateAction<IProductoTienda[]>>
  handleProductosAConfirmarAction: (producto: IProductoTienda) => void
  novedades: Novedad[]
  getNovedades: (codLocal: string, idCategoria?: number | string, searchText?: string) => Promise<void>
  resetProductos: () => void
  atributosValoresASincronizar: AtributoValorASincronizar[]
  setAtributosValoresASincronizar: React.Dispatch<React.SetStateAction<AtributoValorASincronizar[]>>
}

export const IntegracionesContext: React.Context<IntegracionesContextProps> = createContext<IntegracionesContextProps>({
  menuTab: MenuTabs.Estado,
  completoOnboarding: false,
  cantNovedades: 0,
  tiendaSeleccionada: null,
  tiendaEstado: null,
  cantProductosAA1T: 0,
  productosTienda: null,
  loadingProductosAA1T: false,
  loadingProductosTienda: false,
  productosTiendaFiltered: [],
  productosAConfirmar: [],
  novedades: [],
  atributosValoresASincronizar: [],
  setCompletoOnboarding: () => {},
  setMenuTab: () => {},
  setTiendaSeleccionada: () => {},
  irASeleccionarTienda: () => {},
  getTiendaStatus: () => Promise.resolve(null),
  setCantProductosAA1T: () => {},
  getProductsAA1T: () => Promise.resolve(),
  getProductsTienda: () => Promise.resolve(),
  setProductosTiendaFiltered: () => {},
  setProductosAConfirmar: () => {},
  handleProductosAConfirmarAction: () => {},
  getNovedades: () => Promise.resolve(),
  resetProductos: () => {},
  setAtributosValoresASincronizar: () => {}
})

export const IntegracionesProvider = ({ children }: { children: React.ReactNode }) => {
  const [completoOnboarding, setCompletoOnboarding] = useState(true)
  const [menuTab, setMenuTab] = useState<MenuTabs>(MenuTabs.Productos)
  const [cantNovedades, setCantNovedades] = useState(0)
  const [tiendaSeleccionada, setTiendaSeleccionada] = useState<ITienda | null>(null)
  const [tiendaEstado, setTiendaEstado] = useState<ITiendaEstado | null>(null)
  const [cantProductosAA1T, setCantProductosAA1T] = useState(0)
  const [productosAConfirmar, setProductosAConfirmar] = useState<IProductoTienda[]>([])
  const [loadingProductosAA1T, setLoadingProductosAA1T] = useState(false)
  const [productosTienda, setProductosTienda] = useState<IProductoTienda[] | null>(null)
  const [productosTiendaFiltered, setProductosTiendaFiltered] = useState<IProductoTienda[]>([])
  const [loadingProductosTienda, setLoadingProductosTienda] = useState(false)
  const [novedades, setNovedades] = useState<Novedad[]>([])
  const [atributosValoresASincronizar, setAtributosValoresASincronizar] = useState<AtributoValorASincronizar[]>([])

  const irASeleccionarTienda = useCallback(() => {
    // This method clear the state "TiendaSeleccionada"
    setTiendaSeleccionada(null)
    localStorage.removeItem('tiendaSeleccionada')
  }, [])

  const getTiendaStatus = useCallback(async (codLocal: string) => {
    let res = null
    try {
      res = await GetEstadoTienda(codLocal)

      if (res.data.data) setTiendaEstado(res.data.data)
    } catch (error) {
      console.log('Error al obtener estado de la tienda', error)
    }
    return res
  }, [])

  const handleProductosAConfirmarAction = useCallback(
    (producto: IProductoTienda) => {
      const index = productosAConfirmar.findIndex((p) => p.externalId === producto.externalId)

      if (index !== -1) {
        // Ya está para confirmar en AA1T, sacar de esta lista y agregar a ProductosTienda
        const newProductos = [...productosAConfirmar]
        newProductos.splice(index, 1)
        setProductosAConfirmar(newProductos)
        setProductosTienda([...productosTienda, producto])
        setCantProductosAA1T(cantProductosAA1T - 1)
      } else {
        setProductosTienda(productosTienda?.filter((p) => p.externalId !== producto.externalId) || [])
        setProductosAConfirmar([...productosAConfirmar, producto])
        setCantProductosAA1T(cantProductosAA1T + 1)
      }
    },
    [setProductosAConfirmar, productosAConfirmar, productosTienda, cantProductosAA1T]
  )

  const getProductsAA1T = useCallback(async (codLocal: string) => {
    setLoadingProductosAA1T(true)
    try {
      const res = await GetProductosAA1T(codLocal)

      if (res.status === 200) setCantProductosAA1T(res.data.ofertas.length || 0)
    } catch (error) {
      console.log('Error al obtener Productos AA1T', error)
    } finally {
      setLoadingProductosAA1T(false)
    }
  }, [])

  const getProductsTienda = useCallback(
    async (codLocal: string, idCategoria: string, searchText: string, sku: string) => {
      if (!tiendaSeleccionada) return
      setLoadingProductosTienda(true)
      try {
        const res = await GetProductosTienda(tiendaSeleccionada.name, codLocal, idCategoria, searchText, sku)

        if (res.status === 200) setProductosTienda(res.data.data)
      } catch (error) {
        throw new Error('Error al obtener Productos Woo')
      } finally {
        setLoadingProductosTienda(false)
      }
    },
    [tiendaSeleccionada]
  )

  const getNovedades = useCallback(async (codLocal: string, idCategoria?: number | string, searchText?: string) => {
    try {
      const res = await GetNovedades(codLocal, idCategoria, searchText)
      setNovedades(res.data.data)

      setCantNovedades(res.data?.data?.length || 0)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const resetProductos = useCallback(() => {
    setProductosAConfirmar([])
    setProductosTienda(null)
    setCantProductosAA1T(0)
  }, [])

  return (
    <IntegracionesContext.Provider
      value={{
        completoOnboarding,
        setCompletoOnboarding,
        menuTab,
        setMenuTab,
        cantNovedades,
        tiendaSeleccionada,
        setTiendaSeleccionada,
        irASeleccionarTienda,
        tiendaEstado,
        getTiendaStatus,
        cantProductosAA1T,
        setCantProductosAA1T,
        handleProductosAConfirmarAction,
        getProductsAA1T,
        productosTienda,
        getProductsTienda,
        loadingProductosAA1T,
        loadingProductosTienda,
        productosAConfirmar,
        setProductosAConfirmar,
        productosTiendaFiltered,
        setProductosTiendaFiltered,
        novedades,
        getNovedades,
        resetProductos,
        atributosValoresASincronizar,
        setAtributosValoresASincronizar
      }}
    >
      {children}
    </IntegracionesContext.Provider>
  )
}
