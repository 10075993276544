/* import * as dotenv from "dotenv";

if (process.env.NODE_ENV !== "production") {
  dotenv.config();
  const path = `${__dirname}/../../.env`;
  dotenv.config({ path });
  //console.log('Development Mode');
} */

export const INTEGRACIONES_URL =
  process.env.REACT_APP_MAIN_URL + '/integraciones'
export const COMUNICACIONES_URL = process.env.REACT_APP_COMUNICACIONES_URL
export const MAIN_URL = process.env.REACT_APP_MAIN_URL
export const CONTRATOS_URL = process.env.REACT_APP_CONTRATOS_URL
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const RECAPTCHA_SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const RECAPTCHA_API_KEY = process.env
  .REACT_APP_RECAPTCHA_API_KEY as string
