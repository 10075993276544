import React from "react";
import { Typography, Box } from "@mui/material";

interface ProdAgotadosProps {
  isMobile: boolean;
}

const InfoProductosAgotados: React.FC<ProdAgotadosProps> = ({ isMobile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingTop: "20px",
          ...(!isMobile && {
            paddingLeft: "50px",
            paddingRight: "50px",
          }),
        }}
      >
        Solamente se pueden publicar productos que estén disponibles, y no
        agotados.
      </Typography>
    </Box>
  );
};

export default InfoProductosAgotados;
