import { Grid, Typography } from "@mui/material";
import SectionTitle from "../../common/SectionTitle";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

const SoloLecturaDescripcion = ({ local }: { local: LocalVendedorDTO }) => {
  return (
    <Grid container spacing={2}>
      <SectionTitle title="Descripción" />
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {local.descripcion || "No se agregó ninguna descripción"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SoloLecturaDescripcion;
