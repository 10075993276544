import Loading from '../../../../components/Common/Loading'
import { Box } from '@mui/material'
import TableDataPagination from '../Table/TableFooter'
import NoResults from '../../../../components/Common/NoResults'
import Producto from '../Producto'
import NoSearch from '../../../../components/Common/NoSearch'
import { useContext } from 'react'
import { ProductosAdminContext } from '../../context/productos.context'
import { useEffect, memo } from 'react'
import useRubros from '../../../Products/hooks/rubros.hook'

interface CardProductosProps {
  productos: any[]
  page: number
  pageSize: number
  searching: boolean
  loadingProductos: boolean
}

const CardProductos = ({ productos, page, pageSize, searching, loadingProductos }: CardProductosProps) => {
  const { rubrosFlatted: rubros, loading: loadingRubros } = useRubros()
  //const { items } = useSortableData(productos ? productos : []);

  useEffect(() => {
    // Scroll hacia arriba cuando cambia la página
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [page])

  if (loadingProductos) return <Loading />
  if (productos.length <= 0) {
    if (!searching) return <NoSearch />
    return <NoResults />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <Box>
        <Box
          display="grid"
          sx={{
            gap: '20px',
            gridTemplateColumns: 'repeat(12, 1fr)',
            '@media (max-width: 750px)': {
              gridTemplateColumns: 'repeat(1, 1fr)'
            }
          }}
        >
          {(pageSize > 0 ? productos.slice(page * pageSize, page * pageSize + pageSize) : productos).map(
            (producto, i) => (
              <Box
                sx={{
                  gridColumn: 'span 6',
                  '@media (max-width: 750px)': {
                    gridColumn: 'span 12'
                  }
                }}
                key={i}
              >
                <Producto key={i} producto={producto} rubros={rubros} loadingRubros={loadingRubros} />
              </Box>
            )
          )}
        </Box>
      </Box>

      <Box sx={{ alignSelf: 'end', margin: '20px 0px' }}>
        <TableDataPagination />
      </Box>
    </Box>
  )
}

export default memo(CardProductos)
