import { Box, ToggleButton, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { ProductStatusValues } from '../../Products/types/product'
import DesplegableRubros from '../../AdminProducts/components/DesplegableRubros'
import { INTEGRACIONES_URL } from '../../../utils/config'
import CloseButton from '../../../components/CloseButton'
import { useAppStore } from '../../../store/app-store'
import ButtonRounded from '../../../components/Common/utils/button/ButtonRounded'
import { StyledToggleButtonGroup } from '../../../components/Common/utils/button/ButtonToggle'
import useRubros from '../../Products/hooks/rubros.hook'
import { Rubro } from '../types/responses-productos'
import { connect } from 'react-redux'

const ExportarCatalogo = ({ logged, closeButton }: any) => {
  const codLocal = useAppStore((state) => state.codLocal)
  const user = logged?.userLogged
  const [loading, setLoading] = useState(false)
  const [rubro, setRubro] = useState('0')
  const [estado, setEstado] = useState(ProductStatusValues.Todos)
  const { rubrosFlatted } = useRubros()

  const handleChangeRubro = (rubro: Rubro) => {
    setRubro(rubro.codRubro || '0')
  }

  const exportar = useCallback(async () => {
    setLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))

    // Llamar a exportar al backend
    const link = document.createElement('a')
    let url = `${INTEGRACIONES_URL}/${codLocal}/exportar?authorization=${user.token}`
    if (rubro !== '0') url += `&codRubro=${rubro}`
    if (estado !== ProductStatusValues.Todos) url += `&estado=${estado}`

    link.href = url

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    await new Promise((resolve) => setTimeout(resolve, 1000))
    setLoading(false)
  }, [estado, codLocal, rubro])

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={{ xs: 20, md: 24 }} fontWeight={'bold'} color="#1C1C1C">
          Exportar Catálogo
        </Typography>
        {closeButton && <CloseButton onClick={closeButton} />}
      </Box>
      <Typography color="#727272" fontSize={{ xs: 14, md: 16 }} fontWeight={'semi'}>
        Esta herramienta te permite descargar un archivo CSV con tus productos. Podés usarla para hacer cambios en
        Excel, y luego volver a importarlos
      </Typography>

      {/* Rubro */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        gap={{ xs: '5px', md: '20px' }}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Rubro
        </Typography>
        <DesplegableRubros
          onChange={handleChangeRubro}
          disabled={loading}
          rubros={rubrosFlatted}
          fullWidth
          placeHolder="Todos los rubros"
        />
      </Box>

      {/* Estado */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        gap={{ xs: '5px', md: '14px' }}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Estado
        </Typography>
        <StyledToggleButtonGroup
          value={estado}
          color="primary"
          exclusive
          onChange={(e, value) => setEstado(value)}
          disabled={loading}
          fullWidth
          sx={{ borderRadius: '12px' }}
        >
          <ToggleButton disabled={loading} value={ProductStatusValues.Todos} sx={{ fontWeight: 'bold' }}>
            Todos
          </ToggleButton>
          <ToggleButton disabled={loading} value={ProductStatusValues.SoloActivos} sx={{ fontWeight: 'bold' }}>
            Solo activos
          </ToggleButton>
          <ToggleButton
            disabled={loading}
            value={ProductStatusValues.SoloVencidos}
            color="error"
            sx={{ fontWeight: 'bold' }}
          >
            Solo vencidos
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Box>

      <ButtonRounded bgcolor="#5983EF" textColor="white" fullWidth onClick={exportar} disabled={loading} sx={{ mt: 2 }}>
        {loading ? 'Exportando' : 'Exportar'}
      </ButtonRounded>
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

export default connect(mapStateToProps)(ExportarCatalogo)
