import { IConfig } from "../../pages/AdminAbmConfigPage";
import SnackBarUtils from "../../utils/SnackBarUtils";
import { axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import NoResults from "../Common/NoResults";

import AdminAbmConfiguration from "./AdminAbmConfiguration";

interface AdminAbmConfigurationsProps {
  configs: IConfig[];
  setConfigs: (configs: IConfig[]) => void;
  configsSearched: IConfig[];
  isSearching: boolean;
}

const AdminAbmConfigurations = ({configs, setConfigs, configsSearched, isSearching}: AdminAbmConfigurationsProps) => {
  const handleEdit = (name: string) => {
    configs.find(config => config.name === name)!.isEditing = true
    setConfigs([...configs])
  }

  const handleInputChange = (name: string, newValue: string) => {
    configs.find(config => config.name === name)!.newValue = newValue
    setConfigs([...configs])
  }

  const updateValue = async (name: string, newValue: string) => {
    const config = configs.find(config => config.name === name)
    if(config) {
      config.isEditing = false
      if(!newValue || newValue === config.value) return setConfigs([...configs])
      config.value = newValue
      await axiosInstanceMain.post(`/admin/config`, {name: config.name, value: config.value})
      .then(result => {
        console.log(result);
        setConfigs([...configs])
        SnackBarUtils.success("Se actualizó el valor de la configuración.")
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  if(isSearching && configsSearched.length <= 0) return <NoResults />

  return (
    <>
      {
        configsSearched.length > 0 ? configsSearched.map((config, i) => {
          return (
            <AdminAbmConfiguration
              config={config}
              handleEdit={handleEdit}
              handleInputChange={handleInputChange}
              updateValue={updateValue}
              key={i}
              value={i}
            />
          )
        }) : configs.map((config, i) => {
          return (
            <AdminAbmConfiguration
              config={config}
              handleEdit={handleEdit}
              handleInputChange={handleInputChange}
              updateValue={updateValue}
              key={i}
              value={i}
            />
          )
        })
      }
    </>
  )
}

export default AdminAbmConfigurations
