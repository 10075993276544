import { useCallback, useEffect, useState } from 'react'
import { useStoreImportacion } from './store/store-importacion'
import { Box, Typography } from '@mui/material'
import DescripcionPaso from './components/DescripcionPaso'
import { ProductoAtributoList } from '../types/responses'
import { AtributosEquivalencias, SaveValores, ValoresList } from '../types'
import { AsignarAtributosProps, RubroYValores } from './types/atributos'
import { firstLetterUpperCase } from '../../../utils/utils'
import LinearProgressWithLabel from '../../../components/LinearProgressWithLabel'
import Loading from '../../../components/Common/Loading'
import { attributes, eliminarDuplicados } from './utils/helpers'
import AsignarAtributoImpo from './AsignarAtributoImpo'
import useRubros from '../../Products/hooks/rubros.hook'

interface Props extends AsignarAtributosProps {
  setValoresASincronizar: React.Dispatch<React.SetStateAction<SaveValores[]>>
  atributosValores: AtributosEquivalencias[]
}

const AsignarAtributos = ({
  paso,
  atributos,
  equivalencias,
  valoresList,
  setValoresList,
  setEquivalencias,
  handleNext,
  setValoresASincronizar,
  atributosValores
}: Props) => {
  const { loading, percentage } = useStoreImportacion((state) => state)
  const { rubrosFlatted, loading: loadingRubros } = useRubros()
  const [RubroYValores, setRubroYValores] = useState<RubroYValores[]>([])
  const [carga, setCarga] = useState(false)

  const handleChange = useCallback(
    (attrName: string, value: ValoresList, attrsRubro: ProductoAtributoList, codRubro: string) => {
      setValoresASincronizar((prev) => {
        const found = prev.find(
          (p) => p.idIntegracionAtributo === value.id && p.nombreAtributoValorExterno === attrName
        )
        if (found) {
          found.nombreAtributoValorExterno = attrName
          found.idProductoAtributo = attrsRubro.idProductoAtributo
          found.idIntegracionAtributo = 0
        } else {
          prev.push({
            idProductoAtributoValorNormalizado: value.id,
            nombreAtributoValorExterno: attrName,
            idIntegracionAtributo: 0,
            idProductoAtributo: attrsRubro.idProductoAtributo
          })
        }

        return prev
      })
      const newEquivalencias = {
        ...equivalencias,
        [attrName]: {
          id: value.id,
          value: value.nombre,
          no_importar: false,
          codRubro: codRubro
        }
      }

      setEquivalencias(newEquivalencias)

      const newValue = {
        id: value.id,
        nombre: value.nombre,
        atributo: attrsRubro.nombrePlural.toLowerCase(),
        equivalencia: attrName
      }

      let newValues = {}

      if (valoresList[codRubro]) {
        const found = valoresList[codRubro].find((v: ValoresList) => v.equivalencia === attrName)
        if (found) {
          newValues = {
            ...valoresList,
            [codRubro]: valoresList[codRubro].map((v: ValoresList) => (v.equivalencia === attrName ? newValue : v))
          }
        } else {
          newValues = {
            ...valoresList,
            [codRubro]: [...valoresList[codRubro], newValue]
          }
        }
      } else {
        newValues = {
          ...valoresList,
          [codRubro]: [newValue]
        }
      }

      setValoresList(newValues)
    },
    [setEquivalencias, setValoresList, valoresList, equivalencias]
  )

  useEffect(() => {
    if (!rubrosFlatted.length || carga) return

    atributos.forEach((a) => {
      for (const key in attributes) {
        const { singular, plural } = attributes[key]
        const rubro = rubrosFlatted.find((r) => Object.keys(a)[0] === r.codRubro?.toString())
        const attribute = rubro?.productoAtributoList?.find(
          (a) => a.nombreSingular.toLowerCase() === singular || a.nombrePlural.toLowerCase() === plural
        )
        if (attribute) {
          const attrs = Object.values(a)[0][key]
          const valores = attribute.valoresList.filter((v) => attrs.includes(v.nombre))

          if (
            !RubroYValores.some((r) => {
              return (
                r.rubro.codRubro === rubro.codRubro &&
                JSON.stringify(r.atributosDefault.all) === JSON.stringify(attribute) &&
                JSON.stringify(r.atributosParaAsignar) === JSON.stringify(attrs)
              )
            })
          ) {
            if (!rubro.codRubro || !rubro.nombreAnalytics) return
            RubroYValores.push({
              rubro: {
                codRubro: rubro.codRubro,
                nombre: rubro.nombreAnalytics
              },
              atributosDefault: {
                key,
                all: attribute,
                valores: valores
              },
              atributosParaAsignar: attrs
            })
          }
        }
      }
    })

    const valoresListInitial: any = { ...valoresList }

    RubroYValores.forEach((r) => {
      const { atributosDefault, atributosParaAsignar, rubro } = r
      const { key } = atributosDefault
      const { codRubro } = rubro

      atributosParaAsignar.forEach((a) => {
        const values = atributosDefault.all.valoresList

        const found = values.find((v) => v.nombre === a)
        if (found) {
          if (!valoresListInitial[codRubro]) valoresListInitial[codRubro] = []
          valoresListInitial[codRubro].push({
            id: found.id,
            nombre: found.nombre,
            atributo: key,
            equivalencia: a
          })
        }
      })
    })

    setRubroYValores(RubroYValores)
    setValoresList(eliminarDuplicados(valoresListInitial))

    if (!carga) setCarga(true)
  }, [rubrosFlatted])

  if (loading || loadingRubros)
    return percentage !== null ? <LinearProgressWithLabel value={percentage} /> : <Loading />

  if (RubroYValores.length === 0) return <Typography variant="h6">No hay atributos para asignar</Typography>

  return (
    <Box display="flex" flexDirection="column" gap="16px" width="100%">
      {/* Descripción */}
      <DescripcionPaso props={{ my: 2 }}>
        Seleccioná cuáles son los valores de atributos de tu catálogo para que se correspondan con los que usamos en
        Avellaneda a un Toque
      </DescripcionPaso>

      {/* Screen Atributos por paso */}
      <Box maxHeight="400px" overflow="auto">
        <Typography variant="h6" fontWeight={'bold'}>
          {firstLetterUpperCase(paso)}
        </Typography>
        {RubroYValores.map((r, i) => (
          <AsignarAtributoImpo
            key={i}
            rubro={r}
            paso={paso}
            handleChange={handleChange}
            handleNext={handleNext}
            equivalencias={equivalencias}
            setEquivalencias={setEquivalencias}
            atributosValores={atributosValores}
          />
        ))}
      </Box>
    </Box>
  )
}

export default AsignarAtributos
