import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { connect } from 'react-redux'
import { LoginService } from './services/login.service'
import logoAA1THome from '../../media/logo_home.png'
import setLogged from '../../redux/actions/logged/setLogged'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material'
import Form from './components/Form'
import RecuperarPassword from './RecuperarPassword/RecuperarPassword'
import { RolType } from '../../components/Navbar/Sidebar/SidebarItems'
import Footer from '../Home/components/Footer'
import { TenantsContext } from '../../context/tenants.context'
import logOut from '../../redux/actions/logged/logOut'
import { confirmarTenantLocal, decodeCode } from '../Products/services/locals.services'
import { useAppStore } from '../../store/app-store'

const LoginPage = (props: any) => {
  const { userLogged } = props.logged
  const setCodLocal = useAppStore((state) => state.setCodLocal)
  const setIdLocal = useAppStore((state) => state.setIdLocal)
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [recuperarPass, setRecuperarPass] = useState(false)
  const { tenant } = useContext(TenantsContext)

  const confirmDataLocal = (dataLogin: any) => {
    setLoading(true)
    confirmarTenantLocal(dataLogin.codLocal, dataLogin.accessToken, tenant)
      .then(({ data }) => {
        if (data.data) {
          setCodLocal(dataLogin.codLocal)
          props.setLogged(dataLogin)
        } else {
          SnackBarUtils.error(data.message)
          navigate('/login')
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false)
      })
  }

  const getIdByCode = (dataLogin: any) => {
    setLoading(true)
    decodeCode(dataLogin.codLocal, dataLogin.accessToken)
      .then(({ data }) => {
        if (data.data) {
          setIdLocal(data.data)
        } else {
          SnackBarUtils.error(data.message)
          navigate('/login')
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleLogin = async (user: string, pwd: string) => {
    if (!user || !pwd) return SnackBarUtils.error('Completa los datos para ingresar!')
    setLoading(true)
    try {
      const loginUser = { user, pwd, captcha: token }
      const res = await LoginService(loginUser, tenant)
      if (res.data.roles.includes('vendedor')) {
        confirmDataLocal(res.data)
        getIdByCode(res.data)
      } else {
        props.setLogged(res.data)
      }
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Usuario o contraseña incorrectos')
    } finally {
      setLoading(false)
    }
  }

  const goToRegister = () => navigate('/abm-local')

  useEffect(() => {
    if (userLogged.isLogged) {
      if (
        userLogged.user.roles &&
        (userLogged.user.roles.includes(RolType.ADMIN) || userLogged.user.roles.includes(RolType.MOD))
      ) {
        navigate('/abm-locales')
      } else if (userLogged.user.roles.includes(RolType.USER)) {
        navigate('/home')
      } else {
        navigate('/login')
      }
    }
  }, [userLogged, location])

  return (
    <>
      <Container
        sx={
          isMobile
            ? { display: 'flex' }
            : {
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
                marginBottom: '4px'
              }
        }
      >
        {isMobile ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <img src={logoAA1THome} width={isMobile ? '100%' : 600} alt="Avellaneda A 1 Toque" />

            <Form
              handleLogin={handleLogin}
              goToRegister={goToRegister}
              loading={loading}
              setToken={setToken}
              setRecuperarPass={setRecuperarPass}
            />
            <RecuperarPassword open={recuperarPass} setOpen={setRecuperarPass} props={props} />
          </Box>
        ) : (
          <Paper variant="outlined" sx={{ p: 3, alignSelf: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <img src={logoAA1THome} width={isMobile ? '100%' : 600} alt="Avellaneda A 1 Toque" />

              <Form
                handleLogin={handleLogin}
                goToRegister={goToRegister}
                loading={loading}
                setToken={setToken}
                setRecuperarPass={setRecuperarPass}
              />
              <RecuperarPassword open={recuperarPass} setOpen={setRecuperarPass} props={props} />
            </Box>
          </Paper>
        )}
      </Container>
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

const mapDispatchToProps = {
  setLogged,
  logOut
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
