import { styled, ToggleButtonGroup, toggleButtonGroupClasses } from '@mui/material'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: '1px solid #868686'
  },
  [`& .${toggleButtonGroupClasses.firstButton}`]: {
    borderRadius: '8px 0px 0px 8px'
  },
  [`& .${toggleButtonGroupClasses.middleButton}`]: {
    border: 'none',
    borderTop: '1px solid #868686',
    borderBottom: '1px solid #868686'
  },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    borderRadius: '0px 8px 8px 0px'
  },
  [`& .${toggleButtonGroupClasses.selected}`]: {
    bgcolor: '#E3EAFC'
  }
}))
