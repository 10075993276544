import { memo, useCallback, useContext, useState } from 'react'
import { IProductoTienda, Rubro } from '../../../../types/responses-productos'
import { Box, FormControl, InputAdornment, Typography } from '@mui/material'
import { findRubro, findUnidadDeMedida } from '../../../../../AdminProducts/services/rubros.service'
import useRubros from '../../../../../Products/hooks/rubros.hook'
import { UnidadDeMedidaList } from '../../../../types/responses'
import DesplegableRubros from '../../../../../AdminProducts/components/DesplegableRubros'
import { TenantsContext } from '../../../../../../context/tenants.context'
import { FormData } from './DialogConfirmProductoAA1T'
import SingleProductoWoo from '../WooCommerce/SingleProductoWoo'
import { useAppStore } from '../../../../../../store/app-store'
import useLocal from '../../../../../Products/hooks/locals.hook'
import { CustomInput, CustomSelect } from '../../../../../../components/Common/input/CustomInput'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  producto: IProductoTienda
  formData: FormData
  handleChangeFormData: (key: keyof FormData, value: any) => void
}

const ProductoAA1TForm = ({ producto, formData, handleChangeFormData }: Props) => {
  const { rubros, rubrosFlatted } = useRubros()
  const [unitsPerRubro, setUnitsPerRubro] = useState<any>([])
  const { tenant } = useContext(TenantsContext)
  const codLocal = useAppStore((state) => state.codLocal)
  const { esMinorista } = useLocal(codLocal, '0')

  const handleChangeRubro = useCallback(
    async (rubro: Rubro) => {
      const codRubro = rubro.codRubro

      handleChangeFormData('codRubro', codRubro)
      handleChangeFormData('rubroName', rubro.nombreAnalytics)

      setUnitsPerRubro(rubro?.unidadDeMedidaList)
      if (rubro?.unidadDeMedidaList?.length > 1) {
        handleChangeFormData('idUnidadDeMedida', rubro?.unidadDeMedidaList[0].nombreSingular.toLowerCase())
      } else {
        setUnitsPerRubro('')
        handleChangeFormData('idUnidadDeMedida', '')
      }
    },

    [rubrosFlatted, tenant]
  )

  const handleUnidadSeleccionadaChange = useCallback(
    async (e: any) => {
      const unidadDeMedidaID = parseInt(e.target.value)
      const rubroFound = await findRubro(formData.codRubro, rubrosFlatted)
      const unidadDeMedida = await findUnidadDeMedida(unidadDeMedidaID, rubroFound.codRubro as number, rubrosFlatted)

      if (unidadDeMedida) {
        handleChangeFormData('idUnidadDeMedida', unidadDeMedida.nombreSingular.toLowerCase())
      }
    },
    [formData, rubrosFlatted, tenant]
  )

  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {/* Producto Woo */}
      <SingleProductoWoo producto={producto} action="nada" />

      <Box display="flex" flexDirection="column" gap="20px">
        {/* Rubros */}
        <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
          <Typography component={'label'} color="#5983EF" fontSize={16} fontWeight={800}>
            Rubro
          </Typography>
          <DesplegableRubros rubros={rubros} onChange={handleChangeRubro} placeHolder="Escribe el rubro" />
        </FormControl>

        {/* Unidad de medida */}
        {unitsPerRubro?.length > 1 && (
          <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
            <Typography component={'label'} color="#5983EF" fontSize={16} fontWeight={800}>
              Unidad de medida
            </Typography>
            <CustomSelect
              native
              onChange={handleUnidadSeleccionadaChange}
              sx={{ bgcolor: '#FDFDFE', borderRadius: '8px' }}
            >
              <option disabled value="" label="Seleccione" />
              {unitsPerRubro.map((unidadDeMedida: UnidadDeMedidaList) => (
                <option
                  key={unidadDeMedida.idUnidadDeMedida}
                  value={unidadDeMedida.idUnidadDeMedida}
                  label={unidadDeMedida.nombreSingular + '/' + unidadDeMedida.nombrePlural}
                >
                  {unidadDeMedida.nombreSingular + '/' + unidadDeMedida.nombrePlural}
                </option>
              ))}
            </CustomSelect>
          </FormControl>
        )}

        {/* Precio */}
        <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%" gap="24px">
          <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
            <Typography component={'label'} color="#5983EF" fontSize={16} fontWeight={800}>
              Compra mínima por mayor
            </Typography>
            <CustomInput
              onFocus={(e) => e.target.select()}
              fullWidth
              value={formData.precio}
              onChange={(e) => handleChangeFormData('precio', parseInt(e.target.value))}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            />
          </FormControl>
          {esMinorista && (
            <Box display="flex" width="100%" flexDirection="column" gap="12px">
              <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'column', gap: '13px' }}>
                <Typography component={'label'} color="#5983EF" fontSize={16} fontWeight={800}>
                  Precio minorista (
                  {unitsPerRubro?.length > 1
                    ? 'por ' + formData.idUnidadDeMedida
                    : unitsPerRubro?.length > 0
                    ? 'por ' + unitsPerRubro[0].nombreSingular.toLowerCase()
                    : 'por unidad'}
                  )
                </Typography>
                <CustomInput
                  onFocus={(e) => e.target.select()}
                  fullWidth
                  disabled={!formData.canRetailSell}
                  onChange={(e) => {
                    console.log('change', e.target.value)
                  }}
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
              </FormControl>
              <ButtonRounded
                onClick={() => handleChangeFormData('canRetailSell', !formData.canRetailSell)}
                sx={{ px: 0 }}
              >
                {formData.canRetailSell ? 'No vender por menor' : 'Vender por menor'}
              </ButtonRounded>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default memo(ProductoAA1TForm)
