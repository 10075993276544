import React, { useContext } from "react";
import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { FiltrosHistorias } from "../context/historias.context";
import { ITenant } from "../../AbmLocalPage/types";

export const getHistorias = async (
  { idHistoria, codLocal, publicadosAhora, publicadosInstagram, creadoDesde, creadoHasta }: FiltrosHistorias,
  tenant: ITenant
) => {

  return await axiosInstanceMain.get(`/admin/${tenant.path}/historias`, {
    params: {
      ...(idHistoria && { idHistoria: idHistoria }),
      ...(codLocal && { codLocal: codLocal }),
      ...{ "publicados-ahora": publicadosAhora },
      ...(publicadosInstagram !== null && {
        "publicados-instagram": publicadosInstagram,
      }),
      ...(creadoDesde && { "creado-desde": creadoDesde }),
      ...(creadoHasta && { "creado-hasta": creadoHasta }),
    },
  });
};

export const compartirHistoria = async (idHistoria: any, tenant: ITenant) => {
  await axiosInstanceMain.post(`/admin/${tenant.path}/historias/${idHistoria}/compartir`);
};

export const eliminarHistoria = async (idHistoria: any, tenant: ITenant) => {
  await axiosInstanceMain.delete(`/admin/${tenant.path}/historias/${idHistoria}`);
};
