import { Autocomplete, Select, styled as styledMUI, TextField } from '@mui/material'

const styled = (component: any) =>
  styledMUI(component)({
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      backgroundColor: '#FDFDFE',
      outline: 'none',
      border: 'none',
      boxShadow: 'none'

      // Move Icon to the right
      // '& .MuiInputAdornment-root': {
      //   paddingLeft: '18px'
      // }
    },
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        color: '#c7c7c7',
        fontWeight: 500,
        fontSize: '16px'
      }
    },
    '& .Mui-disabled': {
      backgroundColor: '#efefef'
    }
  })

export const CustomInput = styled(TextField)
export const CustomAutocomplete = styled(Autocomplete)
export const CustomSelect = styled(Select)
