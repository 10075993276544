import React, { useContext, useState, useEffect, useCallback } from "react";
import { axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import { getCalificaciones } from "./services/calificaciones.services";
import { TenantsContext } from "../../context/tenants.context";

export interface ICalificacionXAtr {
  idCalificacionAtributo: number;
  nombre: string;
  puntaje: number;
  votos: number;
}

export interface ICalificacionesList {
  idCalificacion: number;
  idProducto: number;
  idUsuario: number;
  idRespuestaPosible: number;
  idAtributo: number;
  compradorNombre: string;
  titulo: string;
  comentarios: string;
  fecha: number;
  positiva: boolean;
  preguntasYrespuestasRespondidas: any;
  respuestaLocal: any;
  respuestaLocalFecha: any;
  puedeResponder: boolean;
  compradorWhatsapp: string;
  respuestaLocalPublica: boolean;
  afectaPuntaje: boolean;
}

export interface ICalificaciones {
  calificacionXatributoList: ICalificacionXAtr[];
  calificacionesList: ICalificacionesList[];
}

export const useCalificaciones = (codLocal: string) => {
  const { tenant } = useContext(TenantsContext);
  const [calificacionesList, setCalificacionesList] = useState<ICalificacionesList[]>([]);
  const [calificacionXatributoList, setCalificacionXatributoList] = useState<ICalificacionXAtr[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async (codLocal: string) => {
    setLoading(true);
    try {
      const result = await getCalificaciones(codLocal, tenant);
      const calificaciones = result.data.data.calificacionesList.map((c: any, index: any) => ({
        ...c,
        atributoNombre: result.data.data.calificacionXatributoList.find(
          (x: any) => x.idCalificacionAtributo === c.idAtributo
        ).nombre,
      }));
      setCalificacionesList(calificaciones);
      setCalificacionXatributoList(result.data.data.calificacionXatributoList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!codLocal) return;
    setLoading(true);
    fetchData(codLocal);
  }, [codLocal]);

  return { calificacionXatributoList, calificacionesList, fetchData, loading };
};
