import React, { useState, useCallback, memo, useEffect } from "react";

// components
import { Box, Typography, Toolbar, Button, Paper, useTheme } from "@mui/material";

// redux
import { connect } from "react-redux";
import setRedirectUrl from "../../../redux/actions/redirection/setRedirectUrl";
import PaperElement from "./PaperElement";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";
import { ShoppingCartOutlined, StarBorderOutlined } from "@mui/icons-material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { IMetricas } from "../../AdminLocal/types";
import { StatusHistorias } from "../../AdminLocal/types";

const Elementos: React.FC<{
  isMobile: boolean;
  metricas: IMetricas;
  estado: StatusHistorias;
}> = ({ isMobile, metricas, estado }) => {
  const createMarkup = (html: string) => ({ __html: html });

  const descripcionStyle = {
    fontFamily: "Plus Jakarta Sans",
    fontSize: isMobile ? "14px" : "16px",
    fontWeight: 500,
    lineHeight: isMobile ? "17.64px" : "20px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const resaltarNros = (inputString: string) => {
    return inputString.replace(/\b\d+\b/g, (match) => `<span style="color: #5983EF">${match}</span>`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
          ...(isMobile && {
            margin: "10px 10px",
          }),
          width: isMobile ? "95%" : "100%",
          gap: isMobile ? "2px" : "10px",
        }}
      >
        <PaperElement
          titulo="Publicaciones"
          descripcion={
            <Typography variant="body2" sx={descripcionStyle}>
              Activas: <span style={{ color: "#5983EF" }}>{metricas.cantOfertas}</span> Vencidas:{" "}
              <span style={{ color: "#5983EF" }}>{metricas.cantOfertasVencidas}</span> {isMobile && <br />}
              Disponibles para publicar: <span style={{ color: "#5983EF" }}>{metricas.cantOfertasDisp}</span>
            </Typography>
          }
          logo={<TaskOutlinedIcon fontSize="large" />}
          botonNext={true}
          redirectUrl="/abm-products"
        />
        <PaperElement
          titulo="Historias"
          descripcion={
            estado?.cardTexto && (
              <Typography
                variant="body2"
                sx={descripcionStyle}
                dangerouslySetInnerHTML={createMarkup(resaltarNros(estado?.cardTexto))}
              />
            )
          }
          logo={<ControlPointDuplicateOutlinedIcon fontSize="large" />}
          botonTexto={`${estado?.botonTexto}`}
          botonNext={true}
          redirectUrl={null}
        />
        <PaperElement
          titulo="Pedidos"
          descripcion={
            <Typography variant="body2" sx={descripcionStyle}>
              Recibiste <span style={{ color: "#5983EF" }}>{metricas.cantPedidos}</span> pedidos
            </Typography>
          }
          logo={<ShoppingCartOutlined fontSize="large" />}
          botonNext={true}
          redirectUrl="/pedidos"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            width: isMobile ? "100%" : "60%",
            justifyItems: "center",
            ...(!isMobile && { gap: "20px" }),
          }}
        >
          <PaperElement
            titulo="Seguidores"
            descripcion={
              <Typography variant="body2" sx={descripcionStyle}>
                Tenés <span style={{ color: "#5983EF" }}>{metricas.cantSeguidores}</span> seguidores
              </Typography>
            }
            logo={<PeopleAltOutlinedIcon fontSize="large" />}
            botonNext={false}
            redirectUrl={null}
          />
          <PaperElement
            titulo="Calificaciones"
            descripcion={
              <Typography variant="body2" sx={descripcionStyle}>
                {"Tu reputación es importante!"}
              </Typography>
            }
            logo={<StarBorderOutlined fontSize="large" />}
            botonNext={true}
            redirectUrl={"/calificaciones"}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Elementos));
