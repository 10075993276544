import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate, useLocation } from 'react-router'
import { useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { obtenerValoresNumericos } from '../../utils/utils'

const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '6px 12px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(1)
  }
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    minWidth: '100%'
  }
}))

interface SearchNavbarProps {
  setIsSearching: (value: boolean) => void
  isSearching: boolean
}

const regex = /^[0-9\b]+$/

const SearchNavbar = ({ setIsSearching, isSearching }: SearchNavbarProps) => {
  const [local, setLocal] = useState<any>('')
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const goLocal = () => {
    if (!local) return

    if (location.pathname === 'admin-local') {
      if (local) navigate(`admin-local/${local}`)
    } else {
      if (local) navigate(`/admin-local/${local}`)
    }
    setLocal('')
  }

  const handleChange = (e: any) => {
    const { value } = e.target
    if (value === '' || regex.test(value)) {
      setLocal(value)
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    const text: string = e.clipboardData.getData('text/plain')
    setLocal(text.trim())
  }

  return (
    <Search
      onPaste={handlePaste}
      onClick={() => setIsSearching(true)}
      onBlur={() => setIsSearching(false)}
      sx={
        isMobile && !isSearching ? { width: 'auto' } : isMobile && isSearching ? { width: '100%' } : { width: '300px' }
      }
    >
      <SearchIcon fontSize="medium" />
      {(!isMobile || isSearching) && (
        <StyledInputBase
          autoFocus={isSearching}
          placeholder="Buscar local por ID o código"
          value={local}
          onChange={handleChange}
          onBlur={() => setIsSearching(false)}
          onKeyDown={(e: { key: string }) => e.key === 'Enter' && goLocal()}
        />
      )}
    </Search>
  )
}

export default SearchNavbar
