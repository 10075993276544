import { Box, IconButton, Typography } from '@mui/material'
import TiendaAA1T from './AA1T/TiendaAA1T'
import ProductosWoo from './WooCommerce/ProductosWoo'
import { MenuTabs } from '../../../types'
import { TabsContent } from '@radix-ui/react-tabs'
import { useMetricas } from '../../../../Home/hooks/metricas.hook'
import { useAppStore } from '../../../../../store/app-store'
import { Error, Refresh } from '@mui/icons-material'
import SearchFiltersTienda from './WooCommerce/SearchFiltersTienda/SearchFiltersTienda'
import { useContext } from 'react'
import { IntegracionesContext } from '../../../context/integraciones.context'
// import IconButtonRotate from '../../../../../components/Common/button/IconButtonRotate'

const IntegracionesProducts = () => {
  const { getProductsTienda, loadingProductosTienda } = useContext(IntegracionesContext)
  const codLocal = useAppStore((store) => store.codLocal)
  const { metricas, loading: loadingMetricas } = useMetricas(codLocal)

  return (
    <TabsContent value={MenuTabs.Productos} className="w-100">
      <Box display="flex" flexDirection="column" gap="21px" width="100%">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Box display="flex" alignItems="center" gap="12px">
              <Typography fontWeight="bold" fontSize={24}>
                Tu tienda en Woocommerce
              </Typography>
              {/* <IconButtonRotate
                onClick={() => getProductsTienda(codLocal)}
                disabled={loadingProductosTienda}
                sx={{
                  bgcolor: '#5983EF',
                  color: 'white',
                  '&:hover': {
                    bgcolor: '#5983EF',
                    color: 'white'
                  }
                }}
              /> */}
            </Box>
            <Typography fontSize={16} color="#D5A000">
              {loadingMetricas ? (
                'Buscando productos...'
              ) : (
                <Box component={'span'} display="flex" gap="4px" alignItems="center">
                  <Error fontSize="small" />
                  Podés importar {metricas?.cantOfertasDisp} publicaciones en Avellaneda a un toque
                </Box>
              )}
            </Typography>
          </Box>

          <TiendaAA1T cantOfertasDisp={metricas?.cantOfertasDisp} />
        </Box>

        <SearchFiltersTienda />
        <ProductosWoo />
      </Box>
    </TabsContent>
  )
}

export default IntegracionesProducts
