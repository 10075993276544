import { Box } from '@mui/material'
import { Novedad } from '../../../../types/responses-novedades'
import { memo } from 'react'
import NovedadHeader from './NovedadHeader'
import NovedadBody from './NovedadBody'

interface Props {
  novedad: Novedad
  reject: (id: number) => void
}

const SingleNovedad = ({ novedad, reject }: Props) => {
  const { productoInterno: productoAA1T, productoExterno: productoTienda } = novedad

  // const ActionInfo = ({ productoAA1T, productoTienda }: { productoAA1T: ProductoInterno; productoTienda: ProductoExterno }) => {
  //   const ActionNovedad = () => {
  //     const filter = s => s[productoAA1T.idproducto] === novedad[productoAA1T.idproducto]
  //     return (
  //       <>
  //         {selected.find((s) => filter(s)) ? (
  //           <Button
  //             color="warning"
  //             // onClick remove from selected
  //             onClick={() => setSelected(selected.filter((s) => filter(s)))}
  //           >
  //             <Block />
  //           </Button>
  //         ) : (
  //           <Button
  //             color="success"
  //             onClick={() => setSelected([...selected, { ...DefaultNovedad, [productoAA1T.idproducto]: novedad[productoAA1T.idproducto] }])}
  //           >
  //             <Check />
  //           </Button>
  //         )}

  //         <Button color="error" onClick={() => reject(novedad.externalId)}>
  //           <Close />
  //         </Button>
  //       </>
  //     )
  //   }

  //   return (
  //     <>
  //       {novedad[aa1t_attr] !== novedad[int_attr] && (
  //         <Box
  //           sx={{
  //             display: 'flex',
  //             alignItems: 'center',
  //             gap: '8px'
  //           }}
  //         >
  //           <Typography>{novedad[int_attr]}</Typography>
  //           <Typography
  //             sx={{
  //               textDecoration: 'line-through',
  //               color: 'red'
  //             }}
  //           >
  //             {novedad[aa1t_attr]}
  //           </Typography>
  //           <ActionNovedad />
  //         </Box>
  //       )}
  //     </>
  //   )
  // }

  return (
    <Box p={{ xs: '0px', md: '24px' }}>
      <NovedadHeader producto={productoAA1T} />
      <NovedadBody productoTienda={productoTienda} productoAA1T={productoAA1T} />
    </Box>
  )
}

export default memo(SingleNovedad)
