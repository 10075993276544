import { Box } from '@mui/system'
import { FC, memo } from 'react'
import { IProductoTienda } from '../../../../../types/responses-productos'
import { CardMedia } from '@mui/material'

interface Props {
  producto: IProductoTienda
}

const ProductoImageWoo: FC<Props> = ({ producto: { descripcion, imagenes } }) => {
  return (
    <CardMedia
      component="img"
      // image={`https://picsum.photos/id/${Math.floor(Math.random() * 50) + 1}/200/300`}
      image={imagenes[0]?.src}
      alt={descripcion}
      sx={{
        width: '93.36px',
        height: '117.48px',
        objectFit: 'cover',
        aspectRatio: '16 / 9',
        borderRadius: '8px',
        alignSelf: 'center'
      }}
    />
  )
}

export default memo(ProductoImageWoo)
