import { useState, ChangeEvent, useContext } from "react";
import * as FaIcons from "react-icons/fa";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem
} from "@mui/material";
import { Box } from "@mui/system";
import Loading from "../../../components/Common/Loading";
import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HistoriasAdminContext } from "../context/historias.context";
import { TenantsContext } from "../../../context/tenants.context";
import { Dayjs } from "dayjs";

const AdminHistoriasFiltros = () => {
  const { setFiltros, setSearching, loading: loadingHistorias } = useContext(HistoriasAdminContext);
  const [autoCompletado, setAutoCompletado] = useState<string[]>([]);
  // Form
  const [idHistoria, setIdHistoria] = useState<string>("");
  const [codLocal, setCodLocal] = useState<string>("");
  const [publicadosAhora, setPublicadosAhora] = useState<boolean>(true);
  const [publicadosInstagram, setPublicadosInstagram] = useState<boolean | null>(null);
  const [creadoDesde, setCreadoDesde] = useState<Dayjs | null>(null);
  const [creadoHasta, setCreadoHasta] = useState<Dayjs | null>(null);
  const estados: any[] = [
    { key: 1, value: "Publicadas" },
    { key: 2, value: "Todas" }
  ];
  const instagram: any[] = [
    { key: 1, value: "Publicadas" },
    { key: 2, value: "No Publicadas" },
    { key: 3, value: "Todas" }
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { tenant } = useContext(TenantsContext);

  const handleAutoCompletado = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length < 3) return;

    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/autocompletado`, { valor: value })
      .then((result) => {
        setAutoCompletado(result.data.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleFormSubmit = () => {
    setSearching(true);
    const fechaDesde = creadoDesde?.format("YYYY-MM-DD");
    const fechaHasta = creadoHasta?.format("YYYY-MM-DD");
    setFiltros({
      idHistoria,
      codLocal,
      publicadosAhora,
      publicadosInstagram,
      creadoDesde: fechaDesde,
      creadoHasta: fechaHasta,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        padding: "10px",
        backgroundColor: "#f5f5f5",
        borderRadius: "5px",
        marginBottom: "10px",
        gap: "8px",
        marginTop: "8px",

        // display column en mobile
        "@media (max-width: 768px)": {
          flexDirection: "column",
          gap: "12px",
        },
      }}
    >
      <FormControl sx={{ width: "250px", "@media (max-width: 768px)": { width: "100%" } }}>
        <Autocomplete
          options={autoCompletado}
          disabled={loadingHistorias}
          getOptionLabel={(option: any) => option.nombre}
          onChange={(e, value: any) => setCodLocal(value?.data || "")}
          value={autoCompletado.find((option: any) => option.data === codLocal) || null}
          renderInput={(p) => (
            <TextField
              {...p}
              onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
              label="Local"
              variant="outlined"
              onChange={handleAutoCompletado}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ width: "200px", "@media (max-width: 768px)": { width: "100%" } }}>
        <TextField
          disabled={loadingHistorias}
          label="Id Historia"
          variant="outlined"
          value={idHistoria}
          onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
          onChange={(e) => setIdHistoria(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ width: "200px", "@media (max-width: 768px)": { width: "100%" } }}>
        <Autocomplete
          options={estados}
          getOptionLabel={(estado: any) => estado.value || ""}
          onChange={() => {
            setPublicadosAhora(!publicadosAhora);
          }}
          onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Estado" InputLabelProps={{ disableAnimation: true }} />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={
            publicadosAhora ? estados.find((e) => e.value === "Publicadas") : estados.find((e) => e.value === "Todas")
          }
        />
      </FormControl>
      <FormControl sx={{ width: "200px", "@media (max-width: 768px)": { width: "100%" } }}>
        <Autocomplete
          options={instagram}
          getOptionLabel={(instagram: any) => instagram.value || ""}
          onChange={(e, value) => {
            value?.value !== "Todas" && setPublicadosInstagram(value?.value === "Publicadas" ? true : false);
          }}
          onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Instagram" InputLabelProps={{ disableAnimation: true }} />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          )}
          value={
            publicadosInstagram !== null
              ? publicadosInstagram
                ? instagram.find((e) => e.value === "Publicadas")
                : instagram.find((e) => e.value === "No Publicadas")
              : instagram.find((e) => e.value === "Todas")
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </FormControl>
      <FormControl
        sx={{
          width: "200px",
          "@media (max-width: 768px)": { width: "100%" },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={loadingHistorias}
            label={"Creado Desde"}
            format="DD/MM/YYYY"
            value={creadoDesde}
            onChange={(date: any) => (date ? setCreadoDesde(date) : setCreadoDesde(null))}
            slotProps={{
              textField: {
                variant: "outlined",
                onKeyPress: (e) => e.key === "Enter" && handleFormSubmit(),
                value: creadoDesde,
              },
            }}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        sx={{
          width: "200px",
          "@media (max-width: 768px)": { width: "100%" },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={loadingHistorias}
            label={"Creado Hasta"}
            format="DD/MM/YYYY"
            value={creadoHasta}
            onChange={(date: any) => (date ? setCreadoHasta(date) : setCreadoHasta(null))}
            slotProps={{
              textField: {
                variant: "outlined",
                onKeyPress: (e) => e.key === "Enter" && handleFormSubmit(),
                value: creadoHasta,
              },
            }}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl
        sx={{
          paddingRight: "10px",
          "@media (max-width: 768px)": {
            width: "100%",
          },
        }}
      >
        <Button
          disabled={loadingHistorias}
          variant="contained"
          size="large"
          onClick={handleFormSubmit}
          //fullWidth
          sx={{
            height: "50px",
            "@media (max-width: 768px)": {
              display: "flex",
              gap: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "none",
              "@media (max-width: 768px)": {
                display: "block",
              },
            }}
          >
            Filtrar
          </Box>
          <FaIcons.FaSearch style={{ margin: 0 }} />
        </Button>
      </FormControl>
    </Box>
  );
};

export default AdminHistoriasFiltros;
