import React, { useState } from 'react'
import '../../styles/contractsRenovations.scss'
import * as GrIcons from 'react-icons/gr'
import { Box, Card, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ILocalesRelacionados } from '../../dto/LocalDTO'
import TablaLocalesRelacionados from './LocalesRelacionados/tablaLocalesRelacionados'
import { useNavigate } from 'react-router'
import { MaximizeIcon } from '../../icons/maximize-icon/maximize-icon.component'
import Loading from '../Common/Loading'
import TableDataPagination from '../../pages/Products/components/TableDataPagination'
import { defaultPaginator, Paginator } from '../../pages/AdminProducts/context/productos.context'
import { Button } from '../Common/utils/button/button.component'

interface Props {
  open: boolean
  setOpen: (op: boolean) => void
  locales: ILocalesRelacionados[]
  loading: boolean
}

const ModalLocalesRelacionados: React.FC<Props> = ({ open, setOpen, locales, loading }: Props) => {
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [paginator] = useState<Paginator>(defaultPaginator)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()

  paginator.handleChangePage = (e: any, newPage: number) => setPage(newPage)

  paginator.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const redirectToLocalDetail = (codLocal: string) => {
    navigate('/admin-local/' + codLocal)
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            display: 'flex',
            minWidth: '90%',
            padding: '20px 10px',
            ...(isMobile && {
              maxHeight: '100%'
            })
          }
        }}
      >
        <header className="contract-actions-header">
          <h2
            className="contract-actions-title"
            style={{
              marginTop: isMobile ? '4px' : '8px',
              fontSize: isMobile ? '24px' : '28px',
              fontWeight: 'bold'
            }}
          >
            {`Locales Relacionados (${locales.length})`}
          </h2>
          <button className="modal-close" onClick={handleClose} aria-label="Cerrar">
            <GrIcons.GrClose />
          </button>
        </header>
        {loading ? (
          <Loading />
        ) : isMobile ? (
          <>
            {(pageSize > 0 ? locales.slice(page * pageSize, page * pageSize + pageSize) : locales).map(
              (item: ILocalesRelacionados, index: number) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '4px',
                    padding: '10px',
                    overflow: 'visible'
                  }}
                >
                  <Typography
                    style={{ marginBottom: '8px' }}
                    variant="h5"
                  >{`${item.nombre} (CODE ${item.codLocal})`}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      marginBottom: '10px'
                    }}
                  >
                    <Typography variant="body1">
                      <strong>Tipo de relación:</strong> {item.tipoRelacion}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Dirección:</strong> {item.direccion}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Tipo de cuenta:</strong> {item.tipoCuenta}
                    </Typography>
                    {item.virtual === 'Si' && (
                      <Typography variant="body1">
                        <strong>Virtual</strong>
                      </Typography>
                    )}
                    <Typography variant="body1">
                      <strong>Cant. productos activos:</strong> {item.cantidadProductos}
                    </Typography>
                  </Box>
                  <Button variant="secondary" size="small" onClick={() => redirectToLocalDetail(item.codLocal)}>
                    <MaximizeIcon />
                  </Button>
                </Card>
              )
            )}
            <TableDataPagination
              total={locales.length || 0}
              page={page || 0}
              paginator={paginator}
              items={locales || []}
              rowsPerPage={pageSize || 10}
              component={'p'}
              label={`Locales por página`}
            />
          </>
        ) : (
          <TablaLocalesRelacionados
            locales={locales}
            headers={[
              'Cod. Local',
              'Nombre',
              'Tipo de Relación',
              'Dirección',
              'Tipo de Cuenta',
              'Virtual',
              'Cant. Productos Activos',
              'Administrar'
            ]}
            redirectToLocalDetail={redirectToLocalDetail}
            page={page}
            pageSize={pageSize}
            paginator={paginator}
          />
        )}
      </Dialog>
    </>
  )
}

export default ModalLocalesRelacionados
