import { axiosInstanceComunicaciones } from "../../../utils/axiosGlobalUtils";

export const getConversacionService = async (idContact: number) =>
  await axiosInstanceComunicaciones.get(
    `/comunicaciones/admin/whatsapp/messages?idContact=${idContact}`
  );
export const getContactosService = async () =>
  await axiosInstanceComunicaciones.get(
    `/comunicaciones/admin/whatsapp/contacts`
  );

export const archiveContact = async (id: number) =>
  await axiosInstanceComunicaciones.post(
    `/comunicaciones/admin/whatsapp/archivar/${id}`
  );
export const unArchiveContact = async (id: number) =>
  await axiosInstanceComunicaciones.post(
    `/comunicaciones/admin/whatsapp/desarchivar/${id}`
  );

export const markAsUnread = async (id: number) =>
  await axiosInstanceComunicaciones.post(
    `/comunicaciones/admin/whatsapp/marcar-no-leido/${id}`
  );

export const sendMessageService = async (idContact: number, message: string) =>
  await axiosInstanceComunicaciones.post(
    `/comunicaciones/admin/whatsapp/${idContact}/enviarMensaje`,
    {
      // date: "2023-05-20T03:05:23.655Z",
      // status: "ENVIADO",
      message,
      // outbound: true,
      // hasAttachment: true,
      // read: true,
    }
  );
