import { Box, Chip } from "@mui/material";

interface ChipEstadosProps {
  product: any;
  isMobile?: boolean;
}

const ChipEstados = ({ product, isMobile }: ChipEstadosProps) => {
  const pendienteAprobacion = !product.aprobado && !product.rechazado;
  const vencido = product.fechaVencimiento < new Date();
  if (product.aprobado && !product.pausado && !product.vencido) return <></>;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexDirection: "column"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "12px",
        }}
      >
        {product.pausado && (
          <Chip variant="outlined" label={"Pausado"} color={"default"} />
        )}
        {product.rechazado && (
          <Chip variant="outlined" label={"Rechazado"} color={"error"} />
        )}
        {vencido && (
          <Chip variant="outlined" label={"Vencido"} color={"error"} />
        )}
      </Box>
      {pendienteAprobacion && (
        <Chip
          variant="outlined"
          label={"Pendiente de aprobación"}
          color={"info"}
        />
      )}
    </Box>
  );
};

export default ChipEstados;
