import { createContext, useState, useEffect, useCallback, useContext } from 'react'
import { getProductos } from '../../AdminProducts/services/productos.service'
import { INITIAL_ROWS_PER_PAGE } from '../components/TableDataPagination'
import { borrar } from '../../AdminProducts/services/productos.service'
import { handleNewRubro } from '../../AdminProducts/services/rubros.service'
import { getAllProducts } from '../../Products/services/products.services'
import { TenantsContext } from '../../../context/tenants.context'

export interface Paginator {
  handleChangePage: (e: any, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export interface Productos {
  key: string
  productos: any[]
  totalRecord: number
  page: number
  pageSize: number
}

export interface Estado {
  key: number
  value: string
}

export const TABS: Estado[] = [
  { key: 0, value: 'activo' },
  { key: 1, value: 'vencido' },
  { key: 2, value: 'inactivo' }
]

const defaultProductos: Productos[] = [
  {
    key: 'activos',
    productos: [],
    totalRecord: 0,
    page: 0,
    pageSize: INITIAL_ROWS_PER_PAGE
  },
  {
    key: 'vencidos',
    productos: [],
    totalRecord: 0,
    page: 0,
    pageSize: INITIAL_ROWS_PER_PAGE
  },
  {
    key: 'activos',
    productos: [],
    totalRecord: 0,
    page: 0,
    pageSize: INITIAL_ROWS_PER_PAGE
  }
]

export const defaultPaginator: Paginator = {
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {}
}

interface ProductosVendedorContextProps {
  productos: any[]
  tabsProductos: Productos[]
  setTabsProductos: (p: Productos[]) => void
  productosSeleccionados: any[]
  setProductosSeleccionados: (p: any[]) => void
  puedePausarVarios: boolean
  puedeReanudarVarios: boolean
  accionesMasivas: boolean
  setAccionesMasivas: (accionesMasivas: boolean) => void
  confirmDialog: any
  setConfirmDialog: (confirm: any) => void
  loading: boolean
  paginator: Paginator
  page: number
  setPage: (page: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  codLocal: string
  setCodLocal: (code: string) => void
  rubroFilter: string
  setRubroFilter: (r: string) => void
  searchFilter: string
  setSearchFilter: (search: string) => void
  ordenamiento: string
  setOrdenamiento: (or: string) => void
  estado: Estado
  setEstado: (estado: Estado) => void
  handleLeft: () => void
  getProductosVendedor: (key: number) => Promise<void>
}

// create context
export const ProductosVendedorContext = createContext<ProductosVendedorContextProps>({
  productos: [],
  tabsProductos: defaultProductos,
  setTabsProductos: () => {},
  productosSeleccionados: [],
  setProductosSeleccionados: () => {},
  puedePausarVarios: false,
  puedeReanudarVarios: false,
  accionesMasivas: false,
  setAccionesMasivas: () => {},
  confirmDialog: '',
  setConfirmDialog: () => {},
  loading: false,
  paginator: defaultPaginator,
  page: 0,
  setPage: () => {},
  pageSize: INITIAL_ROWS_PER_PAGE,
  setPageSize: () => {},
  codLocal: '',
  setCodLocal: () => {},
  rubroFilter: '',
  setRubroFilter: () => {},
  searchFilter: '',
  setSearchFilter: () => {},
  ordenamiento: '4',
  setOrdenamiento: () => {},
  estado: TABS[0],
  setEstado: () => {},
  handleLeft: () => {},
  getProductosVendedor: async (key) => {}
})

// create provider
export const ProductosVendedorProvider = ({ children }: any) => {
  const { tenant } = useContext(TenantsContext)
  const [tabsProductos, setTabsProductos] = useState<Productos[]>(defaultProductos)
  const [productos, setProductos] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [paginator] = useState<Paginator>(defaultPaginator)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const [codLocal, setCodLocal] = useState<string>('')
  const [ordenamiento, setOrdenamiento] = useState<string>('4')
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [rubroFilter, setRubroFilter] = useState<string>('0')
  const [estado, setEstado] = useState<Estado>(TABS[0])
  const [productosSeleccionados, setProductosSeleccionados] = useState<any[]>([])
  const [puedePausarVarios, setPuedePausarVarios] = useState<boolean>(false)
  const [puedeReanudarVarios, setPuedeReanudarVarios] = useState<boolean>(false)
  const [accionesMasivas, setAccionesMasivas] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })

  const getProductosVendedor = async (key: number) => {
    if (!codLocal) return
    setLoading(true)
    const tabs = [...tabsProductos]
    try {
      const response = await getAllProducts(
        tenant,
        codLocal,
        ordenamiento,
        TABS[key].value,
        page,
        pageSize,
        searchFilter,
        rubroFilter
      )
      setProductos(response.data)
      tabs[key].productos = response.data
      tabs[key].totalRecord = response.totalRecord
      setTabsProductos(tabs)
    } catch (error) {
      console.error('Error fetching products:', error)
    } finally {
      setLoading(false)
    }
  }

  // Paginator
  paginator.handleChangePage = (e: any, newPage: number) => setPage(newPage)

  paginator.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10))
  }

  useEffect(() => {
    const fetchDataTabs = async (key: number) => {
      await getProductosVendedor(key)
    }

    TABS.map((tab: Estado) => {
      fetchDataTabs(tab.key)
    })
  }, [codLocal, ordenamiento, searchFilter, rubroFilter])

  useEffect(() => {
    setProductos(tabsProductos[estado.key].productos)
  }, [estado])

  useEffect(() => {
    setProductos(tabsProductos[estado.key].productos)
    const tabs = [...tabsProductos]
    tabs[estado.key].page = page
    tabs[estado.key].pageSize = pageSize
    setTabsProductos(tabs)
  }, [page, pageSize])

  useEffect(() => {
    const fetchData = async () => {
      await getProductosVendedor(estado.key)
    }
    fetchData()
  }, [page, pageSize])

  const handleLeft = useCallback(() => {
    setProductosSeleccionados([])
    setAccionesMasivas(false)
    setPuedePausarVarios(false)
    setPuedeReanudarVarios(false)
  }, [])

  useEffect(() => {
    if (productosSeleccionados.length <= 0) return setAccionesMasivas(false)

    setAccionesMasivas(true)

    const haySoloPausados = productosSeleccionados.every((p: any) => p.pausado === true)

    const haySoloUnPausados = productosSeleccionados.every((p: any) => p.pausado === false)

    setPuedePausarVarios(!haySoloPausados || haySoloUnPausados)
    setPuedeReanudarVarios(!haySoloUnPausados || haySoloPausados)
  }, [productosSeleccionados])

  return (
    <ProductosVendedorContext.Provider
      value={{
        productos,
        tabsProductos,
        setTabsProductos,
        productosSeleccionados,
        setProductosSeleccionados,
        puedePausarVarios,
        puedeReanudarVarios,
        accionesMasivas,
        setAccionesMasivas,
        confirmDialog,
        setConfirmDialog,
        loading,
        paginator,
        page,
        setPage,
        pageSize,
        setPageSize,
        codLocal,
        setCodLocal,
        rubroFilter,
        setRubroFilter,
        searchFilter,
        setSearchFilter,
        ordenamiento,
        setOrdenamiento,
        estado,
        setEstado,
        handleLeft,
        getProductosVendedor
      }}
    >
      {children}
    </ProductosVendedorContext.Provider>
  )
}
