import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import { useContext, useState } from "react";
import { uploadFoto } from "../../services/locales.service";
import SnackBarUtils from "../../../../utils/SnackBarUtils";
import AlertDialog from "../../../../components/Common/Dialog";
import Loading from "../../../../components/Common/Loading";
import LogoDefault from "../../../../media/default_logo.svg";
import { Close } from "@mui/icons-material";
import store from "../../../../redux/store";
import { TenantsContext } from "../../../../context/tenants.context";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

interface SoloLecturaLogoProps {
  local: LocalVendedorDTO;
  open: boolean;
  setOpen: (open: boolean) => void;
  getLocal: (codLocal: string) => Promise<any>;
}

const SoloLecturaLogo = ({
  local,
  open,
  setOpen,
  getLocal,
}: SoloLecturaLogoProps) => {
  const [foto, setFoto] = useState<File | null>(null);
  const [eliminando, setEliminando] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { tenant } = useContext(TenantsContext);
  const theme = useTheme();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let base64: any = null;

    // parse image to base 64
    const reader = new FileReader();
    reader.readAsDataURL(foto as Blob);
    reader.onload = async () => {
      base64 = reader.result;
      base64 = base64.split(",")[1];

      setLoading(true);
      try {
        await uploadFoto(local.codLocal, base64, tenant);
        const localRes = await getLocal(local.codLocal);
        store.dispatch({
          type: "setUserLogo",
          payload: { logo: localRes.logo },
        });
        SnackBarUtils.success("Se actualizó el logo correctamente");
      } catch (error) {
        console.log(error);
      } finally {
        handleClose();
        setLoading(false);
      }
    };
  };

  const handleClose = () => {
    setOpen(false);
    setFoto(null);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await uploadFoto(local.codLocal, null, tenant);
      await getLocal(local.codLocal);
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
      setEliminando(false);
      setLoading(false);
    }
  };

  const handleLogoChange = (e: any) => {
    const image = e.target.files[0];

    // validate if is jpg/jpeg, png or gif
    if (
      !image.type.includes("jpeg") &&
      !image.type.includes("jpg") &&
      !image.type.includes("png") &&
      !image.type.includes("gif")
    )
      return setError("Solo se permiten imágenes");

    setError(null);
    setFoto(image);
  };

  return (
    <>
      <AlertDialog
        open={eliminando}
        content="¿Está seguro que desea eliminar el logo actual?"
        title="Eliminar Logo"
        onCancel={() => setEliminando(false)}
        onConfirm={handleDelete}
        disagreeText="Cancelar"
        agreeText="Eliminar"
        agreeColor="error"
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "8px",
          }}
        >
          {local.logo ? "Cambiá" : "Cargá"} el logo de tu local
          <IconButton
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              p: "0",
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "30px",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                {/* previsualizar foto */}
                {foto && (
                  <img
                    src={URL.createObjectURL(foto)}
                    alt="foto"
                    width="100px"
                    height="auto"
                  />
                )}
                <Button
                  disabled={loading}
                  component="label"
                  variant="contained"
                  color="primary"
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Elegir archivo
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={handleLogoChange}
                  />
                </Button>
                {local.logo && !foto && (
                  <Button
                    disabled={loading}
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={() => setEliminando(true)}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Eliminar logo
                  </Button>
                )}
              </Box>

              {foto && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "4px",
                    marginLeft: "auto",
                    "@media (max-width: 600px)": {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Button
                    fullWidth
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                  >
                    Subir logo
                  </Button>
                </Box>
              )}
              {error && (
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {loading ? (
        <Loading />
      ) : (
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {local.logo ? (
              <img
                src={local.logo}
                alt={local.nombre}
                width="100px"
                height="auto"
              />
            ) : (
              <Box
                sx={{
                  // fontSize: "100px",
                  bgcolor: "#4b57ff",
                  color: "white",
                  borderRadius: "50%",
                  p: 3,
                }}
              >
                <img
                  src={LogoDefault}
                  alt={local.nombre}
                  width="75px"
                  height="auto"
                />
              </Box>
              // <Store
              //   sx={{
              //     fontSize: "100px",
              //     bgcolor: "#4b57ff",
              //     color: "white",
              //     borderRadius: "4px",
              //     p: 1,
              //   }}
              // />
            )}
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpen(true)}
              sx={{
                backgroundColor: theme.palette.grey[700],
                color: theme.palette.common.white,
                ":hover": {
                  backgroundColor: theme.palette.grey[900],
                },
              }}
            >
              {local.logo ? "Cambiar" : "Agregar"} Logo
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default SoloLecturaLogo;
