import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toggle/style.css'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'
import { LocalesProvider } from './hooks/locales.hook'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils'
import { ProductosAdminProvider } from './pages/AdminProducts/context/productos.context'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { RECAPTCHA_API_KEY } from './utils/config'
import { WhatsappProvider } from './pages/WhatsappBot/context/whatsapp.context'
import { OfrecerPremiumProvider } from './context/OfrecerPremium/ofrecerPremium.context'
import OfrecerPremiumDialog from './context/OfrecerPremium/OfrecerPremiumDialog'
import { PedidosProvider } from './pages/Pedidos/context/pedidos.context'
import { GaleriasProvider } from './pages/Galerias/context/galerias.context'
import { TenantsProvider } from './context/tenants.context'
import { HistoriasProvider } from './pages/AdminHistorias/context/historias.context'
import { DataContratosProvider } from './components/AdminLocal/context/data.contratos.context'
import { ProductosVendedorProvider } from './pages/Products/context/productos.vendedor.context'
import { IntegracionesProvider } from './pages/Integraciones/context/integraciones.context'

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_API_KEY} language="es" useRecaptchaNet={true}>
      <Provider store={store}>
        <TenantsProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <OfrecerPremiumProvider>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <SnackbarUtilsConfigurator />
                <HistoriasProvider>
                  <LocalesProvider>
                    <DataContratosProvider>
                      <WhatsappProvider>
                        <ProductosAdminProvider>
                          <ProductosVendedorProvider>
                            <GaleriasProvider>
                              <PedidosProvider>
                                <IntegracionesProvider>
                                  <App />
                                </IntegracionesProvider>
                              </PedidosProvider>
                            </GaleriasProvider>
                          </ProductosVendedorProvider>
                        </ProductosAdminProvider>
                      </WhatsappProvider>
                    </DataContratosProvider>
                  </LocalesProvider>
                </HistoriasProvider>
              </SnackbarProvider>
            </OfrecerPremiumProvider>
          </LocalizationProvider>
        </TenantsProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
