import { memo, useContext, useEffect, useState } from 'react'

// Material UI
import {
  InputLabel,
  Select,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  MenuItem
} from '@mui/material'
import { Close, Info } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

// form validation
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import Loading from '../../../components/Common/Loading'
import { TenantsContext } from '../../../context/tenants.context'
import { INotificaciones, TipoNotificacion } from '../types/Notificaciones'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { editarNotificación, insertarNotificacion } from '../notificaciones.services'
import dayjs from 'dayjs'
import ConfirmDialog from '../../../pages/Products/components/ConfirmDialog'

const MAX_NOMBRE_LENGTH = 100
const MAX_TITULO_LENGTH = 45
const MAX_MJS_LENGTH = 500

interface Props {
  open: boolean
  setOpen: (op: boolean) => void
  tiposDeNotificacion: TipoNotificacion[]
  tiposOrigenNotificacion: TipoNotificacion[]
  notificacion: INotificaciones
  setNotificacion: (notificacion: INotificaciones) => void
  isEditing: boolean
  setIsEditing: (editing: boolean) => void
  updateData: () => void
}

const CrearNotificacion = ({
  open,
  setOpen,
  tiposDeNotificacion,
  tiposOrigenNotificacion,
  notificacion,
  setNotificacion,
  isEditing,
  setIsEditing,
  updateData
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [duplicado, setDuplicado] = useState<boolean>(false)
  const [nombre, setNombre] = useState<string>('')
  const [titulo, setTitulo] = useState<string>('')
  const [mensaje, setMensaje] = useState<string>('')
  const [tipoSeleccionado, setTipoSeleccionado] = useState<TipoNotificacion>(tiposDeNotificacion[0] || null)
  const [tipoOrigenSeleccionado, setTipoOrigenSeleccionado] = useState<TipoNotificacion>()
  const [fechaInicio, setFechaInicio] = useState<any>(dayjs().startOf('day'))
  const [fechaFin, setFechaFin] = useState<any>(null)
  const [errorTipo, setErrorTipo] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { tenant } = useContext(TenantsContext)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  const schema: yup.AnyObjectSchema = yup.object({
    nombre: yup.string().required('El nombre es obligatorio'),
    titulo: yup.string().nullable(),
    mensaje: yup.string().required('El mensaje es obligatorio'),
    fechaInicio: yup.string().nullable(),
    fechaFin: yup
      .string()
      .nullable()
      .test('is-greater', 'La fecha de fin debe ser posterior a la fecha de inicio', function (value) {
        const { fechaInicio } = this.parent
        return !fechaInicio || !value || new Date(value) >= new Date(fechaInicio)
      })
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const publicarNotificacion = async (idNotificacion: number, newNotificacion: any) => {
    try {
      setLoading(true)
      isEditing
        ? await editarNotificación(tenant, idNotificacion, newNotificacion).then(() => {
            SnackBarUtils.success('Notificación actualizada con éxito')
            updateData()
          })
        : await insertarNotificacion(tenant, newNotificacion).then(() => {
            SnackBarUtils.success('Notificación creada con éxito')
            updateData()
          })
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Error al crear la notificación')
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const onSubmit = (data: any) => {
    const fechaInicioFormateada = new Date(fechaInicio)
    const fechaFinFormateada = new Date(fechaFin)

    if (!tipoOrigenSeleccionado) {
      setErrorTipo(true)
      return SnackBarUtils.error('Debe indicar un destinatario de notificación')
    }

    const newNotificacion: any = {
      nombre: nombre,
      titulo: titulo,
      mensaje: mensaje,
      idNotificacionTipo: tipoSeleccionado?.id || 0,
      fechaInicio: fechaInicio ? fechaInicioFormateada : null,
      fechaFin: fechaFin ? fechaFinFormateada : null,
      idNotificacionPushOrigenTipo: tipoOrigenSeleccionado.id
    }

    if (!data.titulo) {
      setConfirmDialog({
        open: true,
        text: {
          title: 'Vas a publicar esta notificación sin título',
          body: '¿Deseas continuar?',
          leftButton: 'Cancelar',
          rightButton: 'Publicar',
          colorButtonConfirm: 'error'
        },
        action: async () => {
          publicarNotificacion(notificacion?.idNotificacionPush, newNotificacion)
          clearConfirmDialog()
        },
        close: () => clearConfirmDialog()
      })
    } else {
      publicarNotificacion(notificacion?.idNotificacionPush, newNotificacion)
    }
  }

  const handleClose = () => {
    reset()
    setTipoOrigenSeleccionado(null)
    setTipoSeleccionado(tiposDeNotificacion[0])
    setErrorTipo(null)
    setFechaFin(null)
    setFechaInicio(dayjs().startOf('day'))
    setNotificacion(null)
    setIsEditing(false)
    setNombre('')
    setTitulo('')
    setMensaje('')
    setDuplicado(false)
    setOpen(false)
  }

  useEffect(() => {
    if (notificacion) {
      !isEditing && setDuplicado(true)
      setValue('nombre', notificacion.nombre)
      setValue('mensaje', notificacion.mensaje)
      setNombre(notificacion.nombre)
      setTitulo(notificacion.titulo)
      setMensaje(notificacion.mensaje)
      const tipoOrigen: TipoNotificacion = tiposOrigenNotificacion.find(
        (tipo: TipoNotificacion) => tipo.id === notificacion.idNotificacionPushOrigenTipo
      )
      const tipo: TipoNotificacion = tiposDeNotificacion.find(
        (tipo: TipoNotificacion) => tipo.id === notificacion.idNotificacionTipo
      )
      const fechaInicioDayjs = isEditing ? dayjs(notificacion.fechaInicio) : dayjs().startOf('day')
      const fechaFinDayjs = dayjs(notificacion.fechaFin)
      tipo && setTipoSeleccionado(tipo)
      tipoOrigen && setTipoOrigenSeleccionado(tipoOrigen)
      fechaInicioDayjs && setFechaInicio(fechaInicioDayjs)
      fechaFinDayjs && setFechaFin(fechaFinDayjs)
    } else if (tiposDeNotificacion.length > 0) {
      setTipoSeleccionado(tiposDeNotificacion[0])
    }
  }, [notificacion, tiposDeNotificacion])

  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(0,0,0,0.75)',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5">Publicando Notificación</Typography>
        <Loading color="inherit" />
      </Box>
    )
  }

  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {isEditing ? 'Editar notificación' : 'Agregar una nueva notificación'}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack sx={{ width: '100%' }} spacing={1}>
              <InputLabel>Destinatario de notificación</InputLabel>
              <Autocomplete
                disabled={isEditing}
                options={tiposOrigenNotificacion}
                getOptionLabel={(tipo: any) => tipo.nombre || ''}
                onChange={(event, value) => {
                  setErrorTipo(false)
                  if (value?.id === 2 || value?.id === 3) {
                    SnackBarUtils.error(
                      'Este tipo de notificaciones se debe crear directamente desde la base de datos.'
                    )
                    setTipoOrigenSeleccionado(null)
                  } else {
                    value ? setTipoOrigenSeleccionado(value) : setTipoOrigenSeleccionado(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={!!errorTipo}
                    helperText={errorTipo && 'El destinatario de notificación es obligatorio'}
                  />
                )}
                value={tipoOrigenSeleccionado || null}
                renderOption={(props, tipo) => (
                  <MenuItem {...props} key={tipo.id} value={tipo.id}>
                    {tipo.nombre}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }} spacing={1}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Nombre
                <Typography variant="subtitle2">
                  {nombre?.length || 0}/{MAX_NOMBRE_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                {...register('nombre')}
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                error={!!errors.nombre}
                helperText={errors?.nombre && String(errors.nombre.message)}
                variant="outlined"
                disabled={loading}
                sx={{ width: '100%' }}
                inputProps={{ maxLength: MAX_NOMBRE_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }} spacing={1}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Título
                <Typography variant="subtitle2">
                  {titulo?.length || 0}/{MAX_TITULO_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                {...register('titulo')}
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                variant="outlined"
                error={!!errors.titulo}
                helperText={errors?.titulo && String(errors.titulo.message)}
                disabled={loading}
                sx={{ width: '100%' }}
                inputProps={{ maxLength: MAX_TITULO_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }} spacing={1}>
              <InputLabel>Tipo de notificacion</InputLabel>
              <Autocomplete
                disabled={loading}
                options={tiposDeNotificacion}
                getOptionLabel={(tipo: TipoNotificacion) => tipo.nombre || ''}
                onChange={(event, value) => setTipoSeleccionado(value || null)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                value={tipoSeleccionado}
                renderOption={(props, tipo) => (
                  <MenuItem {...props} key={tipo.id} value={tipo.id}>
                    {tipo.nombre}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack direction={isMobile || duplicado ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
              <Stack
                sx={{ width: '100%' }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disabled={loading}
                    label="Fecha Inicio"
                    format="DD/MM/YYYY HH:mm"
                    value={fechaInicio}
                    onChange={(date: any) => {
                      date ? setFechaInicio(date) : setFechaInicio(null)
                      setValue('fechaInicio', date, { shouldValidate: true })
                    }}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        value: fechaInicio,
                        error: !!errors.fechaInicio,
                        helperText: errors?.fechaInicio && String(errors.fechaInicio.message),
                        InputProps: {
                          ...(fechaInicio &&
                            isHovered && {
                              endAdornment: (
                                <>
                                  <IconButton onClick={() => setFechaInicio(null)} edge="end">
                                    <Close />
                                  </IconButton>
                                </>
                              )
                            })
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              {!duplicado && (
                <Stack
                  sx={{ width: '100%' }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      disabled={loading}
                      label="Fecha Fin"
                      format="DD/MM/YYYY HH:mm"
                      value={fechaFin}
                      onChange={(date: any) => {
                        date ? setFechaFin(date) : setFechaFin(null)
                        setValue('fechaFin', date, { shouldValidate: true })
                      }}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          value: fechaFin,
                          error: !!errors.fechaFin,
                          helperText: errors?.fechaFin && String(errors.fechaFin.message),
                          InputProps: {
                            ...(fechaFin &&
                              isHovered && {
                                endAdornment: (
                                  <>
                                    <IconButton onClick={() => setFechaFin(null)} edge="end">
                                      <Close />
                                    </IconButton>
                                  </>
                                )
                              })
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              )}
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }} spacing={1}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Mensaje
                <Typography variant="subtitle2">
                  {mensaje?.length || 0}/{MAX_MJS_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                {...register('mensaje')}
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                inputProps={{ maxLength: MAX_MJS_LENGTH }}
                error={!!errors.mensaje}
                helperText={errors?.mensaje && String(errors.mensaje.message)}
                variant="outlined"
                disabled={loading}
                sx={{ width: '100%' }}
                rows={5}
                multiline
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              diplay: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-end',
              justifyContent: 'space-between',
              width: '100%',
              padding: '22px',
              paddingTop: 0,
              gap: '8px',
              position: 'relative'
            }}
          >
            <Button color="inherit" onClick={handleClose}>
              Volver
            </Button>

            <Stack direction="row" spacing={2}>
              <Button variant="contained" type="submit" disabled={loading}>
                {loading ? 'Grabando' : 'Grabar'}
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmDialog {...confirmDialog} />
    </>
  )
}

export default memo(CrearNotificacion)
