import { Trigger, List } from '@radix-ui/react-tabs'
import { memo, useCallback, useContext } from 'react'
import { IntegracionesContext } from '../../context/integraciones.context'
import { MenuTabs } from '../../types'
import { OpenInNew } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useResponsive } from '../../../../hooks/useResponsive.hook'

const MenuTabsList = () => {
  const { isMobile } = useResponsive()
  const { cantNovedades } = useContext(IntegracionesContext)

  const openNewTab = useCallback(() => {
    window.open('/abm-products', '_blank')
  }, [])

  return (
    <List className="tab-list">
      <Trigger className="tab" value={MenuTabs.Estado}>
        {MenuTabs.Estado}
      </Trigger>
      <Trigger className="tab" value={MenuTabs.Productos}>
        {isMobile ? 'IMPORTAR' : MenuTabs.Productos}
      </Trigger>
      <Trigger
        onClick={openNewTab}
        className="tab"
        value=""
        onMouseDown={(e) => e.preventDefault()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px'
        }}
      >
        <OpenInNew fontSize="small" /> {isMobile ? 'PUBLICACIONES' : MenuTabs.Publicaciones}
      </Trigger>
      <Trigger className="tab" value={MenuTabs.Novedades}>
        <Box display="flex" alignItems="center" gap="5px" width="100%" justifyContent="center">
          {MenuTabs.Novedades}
          <Typography
            fontSize={12}
            fontWeight={800}
            bgcolor="#5983EF"
            color="white"
            width="16px"
            height="16px"
            borderRadius="40px"
          >
            {cantNovedades}
          </Typography>
        </Box>
      </Trigger>
    </List>
  )
}

export default memo(MenuTabsList)
