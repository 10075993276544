import { useEffect, useState, memo, useContext } from 'react'

// Material UI
import {
  InputLabel,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  IconButton,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from '@mui/material'

import { Close } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

// form validation
import * as yup from 'yup'
import { set, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { updateRubro } from '../services/rubros.operaciones'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import Loading from '../../../components/Common/Loading'
import DesplegableRubrosPadres, { flattenRubrosHierarchy } from '../components/desplegableRubrosPadres'
import { useDataABMRubros } from '../services/datos.seleccion'
import { HuePicker } from 'react-color'
import KeywordsSelect from './keywordsWidget'
import { TenantsContext } from '../../../context/tenants.context'
import { Rubro } from '../../Integraciones/types/responses-productos'
import { getAllRubros } from '../../AdminProducts/services/rubros.service'

const MAX_NOMBRE_LENGTH = 85
const MAX_URL_LENGTH = 150
const MAX_COLOR_LENGTH = 6
const MAX_SLUG_LENGTH = 45

const EditRubro = (props: any) => {
  const { open, setOpen, codRubro } = props
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { atributos, monedas, unidadesDeMedidaDisponibles } = useDataABMRubros()
  const [editRubro, setEditRubro] = useState<Rubro>({} as Rubro)
  const [loadingEditRubro, setLoadingEditRubro] = useState<boolean>(false)
  const [maxOrden, setMaxOrder] = useState<number>(0)
  const [unidadesSeleccionadas, setUnidadesSeleccionadas] = useState<any[]>([])
  const [atributosSeleccionados, setAtributosSeleccionados] = useState<any[]>([])
  const [monedasSeleccionadas, setMonedasSeleccionadas] = useState<any[]>([])
  const [rubroPadre, setRubroPadre] = useState<any>(null)
  const [showHue, setShowHue] = useState<boolean>(false)
  //const [isDesplegableSelected, setIsDesplegableSelected] = useState<boolean>(false);

  const [selectedRubro, setSelectedRubro] = useState<any>(null)
  const { tenant } = useContext(TenantsContext)

  const findRubroByCode = async (codRubro: any) => {
    try {
      const rubros = (await getAllRubros(tenant)).data.data
      const flatRubros = flattenRubrosHierarchy(rubros)
      const foundRubro = flatRubros.find((rubro: any) => rubro.codRubro === codRubro)
      return foundRubro
    } catch (e) {
      console.error('Error:', e)
    }
  }

  useEffect(() => {
    if (codRubro && open) {
      getEditRubro()
    }
  }, [codRubro, open])

  useEffect(() => {
    const fetchRubroPadre = async () => {
      try {
        setLoadingEditRubro(true)
        const fetchedRubroPadre = await findRubroByCode(editRubro.codRubroPadre)
        setRubroPadre(fetchedRubroPadre === undefined ? 0 : fetchedRubroPadre)
      } catch (error) {
        console.error('Error fetching rubro padre:', error)
      } finally {
        setLoadingEditRubro(false)
      }
    }

    fetchRubroPadre()
  }, [editRubro?.codRubroPadre])

  const getEditRubro = async () => {
    setLoadingEditRubro(true)
    try {
      if (codRubro) {
        const rubro = await findRubroByCode(codRubro)
        if (rubro) {
          setEditRubro(rubro)
          setAtributosSeleccionados(rubro.productoAtributoList || [])
          setMonedasSeleccionadas(rubro.monedaList || [])
          setUnidadesSeleccionadas(rubro.unidadDeMedidaList || [])
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingEditRubro(false)
    }
  }

  const handleUnidadSeleccionadaChange = (unidadId: number) => {
    if (unidadesSeleccionadas.some((unidad: any) => unidad.idUnidadDeMedida === unidadId)) {
      setUnidadesSeleccionadas(unidadesSeleccionadas.filter((unidad: any) => unidad.idUnidadDeMedida !== unidadId))
    } else {
      setUnidadesSeleccionadas([...unidadesSeleccionadas, { idUnidadDeMedida: unidadId }])
    }
  }

  const handleAtributoSeleccionado = (atributoId: number) => {
    if (atributosSeleccionados.some((atributo: any) => atributo.idProductoAtributo === atributoId)) {
      setAtributosSeleccionados(
        atributosSeleccionados.filter((atributo: any) => atributo.idProductoAtributo !== atributoId)
      )
    } else {
      setAtributosSeleccionados([...atributosSeleccionados, { idProductoAtributo: atributoId }])
    }
  }

  const handleMonedaSeleccionada = (monedaSelect: any) => {
    if (monedasSeleccionadas.some((moneda: any) => moneda.isoCode === monedaSelect.isoCode)) {
      setMonedasSeleccionadas(monedasSeleccionadas.filter((moneda: any) => moneda.isoCode !== monedaSelect.isoCode))
    } else {
      setMonedasSeleccionadas([...monedasSeleccionadas, monedaSelect])
    }
  }

  const handleMaxOrden = async (rubroPadreCode: string) => {
    try {
      const rubros = (await getAllRubros(tenant)).data.data
      const flatRubros = flattenRubrosHierarchy(rubros)
      if (rubroPadreCode) {
        const orden = rubros.length + 1
        return orden
      } else {
        const rubrosHijos = flatRubros.filter((rubro: any) => rubro.codRubroPadre === rubroPadreCode)
        const orden = rubrosHijos.length + 1
        return orden
      }
    } catch (e) {
      console.error('Error:', e)
    }
  }

  useEffect(() => {
    const fetchMaxOrder = async () => {
      const orden = await handleMaxOrden(editRubro.codRubroPadre)
      setMaxOrder(orden)
    }
    fetchMaxOrder()
  }, [editRubro.codRubroPadre])

  const schema: yup.AnyObjectSchema = yup.object({
    rubroName: yup.string().required('El Nombre es obligatorio'),
    minPrice: yup
      .number()
      .test('minPrice', 'El precio debe ser positivo y mayor a cero', function (value) {
        if (!value) {
          return true // No hay error si el campo está vacío
        }
        return yup.number().positive().isValidSync(value)
      })
      .transform((_, value) => {
        if (value.includes('.')) {
          return null
        }
        return +value.replace(/,/, '.')
      }),
    orden: yup
      .number()
      .required('Es obligatorio declarar el orden del rubro')
      .min(1, 'El orden debe ser mayor o igual a 1')
      .max(maxOrden, `El orden debe ser menor o igual a ${maxOrden}`)
      .typeError('Es obligatorio declarar el orden del rubro'),
    colorRubro: yup.string().max(MAX_COLOR_LENGTH),
    nombreCompleto: yup.string().max(MAX_URL_LENGTH),
    slug: yup.string().max(MAX_SLUG_LENGTH),
    urlImg: yup.string().max(MAX_URL_LENGTH),
    urlFoto: yup.string().max(MAX_URL_LENGTH),
    urlIcono: yup.string().max(MAX_URL_LENGTH),
    urlPortada: yup.string().max(MAX_URL_LENGTH)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const onSubmit = async (data: any) => {
    const rubro: Rubro = {
      activo: true,
      listable: editRubro.listable,
      codRubroPadre: editRubro.codRubroPadre,
      monedaList: monedasSeleccionadas,
      nombre: editRubro.nombre,
      nombreCompleto: editRubro.nombreCompleto || '',
      orden: editRubro.orden - 1,
      precioMinimo: editRubro.precioMinimo,
      productoAtributoList: atributosSeleccionados,
      unidadDeMedidaList: unidadesSeleccionadas,
      color: editRubro.color || '',
      slug: editRubro.slug || '',
      keywords: editRubro.keywords || null,
      urlFoto: editRubro.urlFoto || '',
      urlImagen: editRubro.urlImagen || '',
      urlIcono: editRubro.urlIcono || '',
      urlPortada: editRubro.urlPortada || '',
      idEspacioTemplate: editRubro.idEspacioTemplate || 0,
      codRubro: editRubro.codRubro || null,
      nombreAnalytics: editRubro.nombreAnalytics || '',
      rubros: editRubro.rubros || []
    }

    if (rubro.monedaList.length === 0) {
      return SnackBarUtils.error('Debes elegir por lo menos un valor de moneda')
    }

    if (rubro.unidadDeMedidaList.length === 0) {
      return SnackBarUtils.error('Debes elegir por lo menos una unidad de medida')
    }

    try {
      setLoading(true)
      await updateRubro(codRubro, rubro, tenant)
      props.updateRubros()
      props.handleNodeSelect(codRubro)
      SnackBarUtils.success('Rubro actualizado con éxito')
    } catch (e) {
      console.log('Error: ', e)
      SnackBarUtils.error('Ha ocurrido un error al actualizar el rubro')
    } finally {
      handleClose()
      setLoading(false)
    }
  }

  const handleClose = async () => {
    setOpen(false)
    setUnidadesSeleccionadas([])
    setAtributosSeleccionados([])
    setMonedasSeleccionadas([])
    setShowHue(false)
    reset()
  }
  if (loadingEditRubro || !editRubro) return <></>

  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,
          backgroundColor: 'rgba(0,0,0,0.75)',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5">Actualizando Rubro</Typography>
        <Loading color="inherit" />
      </Box>
    )
  }

  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Actualizar rubro
            {open ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Título del rubro
                  <Typography variant="subtitle2">
                    {editRubro.nombre?.length || 0}/{MAX_NOMBRE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  disabled={loading}
                  sx={{ width: '100%' }}
                  error={!!errors.rubroName}
                  helperText={errors?.rubroName && String(errors?.rubroName?.message)}
                  variant="outlined"
                  {...register('rubroName')}
                  value={editRubro.nombre}
                  onChange={(e) => {
                    setEditRubro({
                      ...editRubro,
                      nombre: e.target.value
                    })
                    trigger('rubroName')
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Nombre completo
                  <Typography variant="subtitle2">
                    {editRubro.nombreCompleto?.length || 0}/{MAX_NOMBRE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  disabled={loading}
                  sx={{ width: '100%' }}
                  error={!!errors.nombreCompleto}
                  helperText={errors?.nombreCompleto && String(errors?.nombreCompleto?.message)}
                  variant="outlined"
                  {...register('nombreCompleto')}
                  value={editRubro.nombreCompleto}
                  onChange={(e) => {
                    setEditRubro({
                      ...editRubro,
                      nombreCompleto: e.target.value
                    })
                    trigger('nombreCompleto')
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Rubro padre</InputLabel>

                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DesplegableRubrosPadres
                    onChange={(e: any) => {
                      setEditRubro({
                        ...editRubro,
                        codRubroPadre: e.target.value || null
                      })
                    }}
                    prevValue={rubroPadre}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Precio mínimo</InputLabel>
                <TextField
                  prefix={'$'}
                  variant="outlined"
                  error={!!errors.minPrice}
                  helperText={errors?.minPrice && String(errors?.minPrice?.message)}
                  disabled={loading}
                  {...register('minPrice')}
                  value={editRubro.precioMinimo}
                  type="number"
                  onChange={(e) => {
                    setEditRubro({
                      ...editRubro,
                      precioMinimo: parseFloat(e.target.value)
                    })
                    trigger('minPrice')
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Orden</InputLabel>
                <TextField
                  variant="outlined"
                  error={!!errors.orden}
                  helperText={errors?.orden && String(errors?.orden?.message)}
                  disabled={loading}
                  type="number"
                  {...register('orden')}
                  placeholder="Seleccione"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{ min: 1, max: maxOrden }}
                  value={editRubro.orden}
                  onChange={(e) => {
                    setEditRubro({
                      ...editRubro,
                      orden: parseInt(e.target.value)
                    })
                    trigger('orden')
                  }}
                />
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        setEditRubro({
                          ...editRubro,
                          listable: e.target.checked
                        })
                      }
                      checked={editRubro.listable}
                      disabled={loading}
                    />
                  }
                  style={{ marginTop: '40px' }}
                  label={'Listable'}
                  disabled={loading}
                />
              </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0', flexGrow: 1 }} spacing="20px">
              <Stack sx={{ width: '100%' }}>
                <InputLabel>Keywords</InputLabel>
                <KeywordsSelect editRubro={editRubro} setEditRubro={setEditRubro} />
              </Stack>
            </Stack>
            <InputLabel
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '35%',
                marginTop: '20px'
              }}
            >
              Color
              <Typography variant="subtitle2">
                {editRubro.color?.length || 0}/{MAX_COLOR_LENGTH}
              </Typography>
            </InputLabel>
            <Stack sx={{ width: '100%', marginBottom: '20px' }} direction={isMobile ? 'column' : 'row'} gap="10px">
              <Stack direction="row" gap="10px" sx={{ width: '40%', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  multiline
                  {...register('colorRubro')}
                  value={editRubro.color || ''}
                  onChange={(e) => setEditRubro({ ...editRubro, color: e.target.value })}
                  inputProps={{
                    maxLength: MAX_COLOR_LENGTH
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">#</InputAdornment>
                  }}
                  style={{ width: '100%' }}
                />
                <div
                  onClick={() => setShowHue(true)}
                  style={{
                    display: 'block',
                    cursor: 'pointer',
                    width: '18px',
                    height: '18px',
                    border: `1px solid black`,
                    backgroundColor: `#${editRubro.color}` || '',
                    aspectRatio: '1/1'
                  }}
                ></div>
              </Stack>

              <Stack sx={{ width: '60%', justifyContent: 'center' }}>
                {showHue && (
                  <HuePicker
                    color={`#${editRubro.color}`}
                    onChange={(selectedColor) =>
                      setEditRubro({
                        ...editRubro,
                        color: selectedColor.hex.substring(1)
                      })
                    }
                    height="18px"
                    width="100%"
                  />
                )}
              </Stack>
            </Stack>

            <Stack sx={{ width: '100%' }}>
              <InputLabel>Unidad de medida</InputLabel>
              {unidadesDeMedidaDisponibles.map((units: any, i: number) => (
                <FormControlLabel
                  key={units.idUnidadDeMedida + i}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={unidadesSeleccionadas.some(
                        (unidad: any) => unidad.idUnidadDeMedida === units.idUnidadDeMedida
                      )}
                      onChange={() => handleUnidadSeleccionadaChange(units.idUnidadDeMedida)}
                    />
                  }
                  label={units.nombreSingular + '/' + units.nombrePlural}
                  sx={{ marginBottom: '1px' }}
                />
              ))}
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <InputLabel>Atributos</InputLabel>
              {atributos.map((units: any, i: number) => (
                <FormControlLabel
                  key={units.idProductoAtributo + i}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={atributosSeleccionados?.some(
                        (atributo: any) => atributo.idProductoAtributo === units.idProductoAtributo
                      )}
                      onChange={() => handleAtributoSeleccionado(units.idProductoAtributo)}
                    />
                  }
                  label={units.nombreSingular + '/' + units.nombrePlural}
                  sx={{ marginBottom: '1px' }}
                />
              ))}
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <InputLabel>Monedas</InputLabel>
              {monedas.map((units: any) => (
                <FormControlLabel
                  key={units.isoCode}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={monedasSeleccionadas.some((moneda: any) => moneda.isoCode === units.isoCode)}
                      onChange={() => handleMonedaSeleccionada(units)}
                    />
                  }
                  label={`${units.nombre} (${units.isoCode}, ${units.simbolo})`}
                  sx={{ marginBottom: '1px' }}
                />
              ))}
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                Slug
                <Typography variant="subtitle2">
                  {editRubro.slug?.length || 0}/{MAX_SLUG_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                disabled={loading}
                sx={{ width: '100%' }}
                error={!!errors.slug}
                helperText={errors?.slug && String(errors?.slug?.message)}
                variant="outlined"
                {...register('slug')}
                value={editRubro.slug || ''}
                onChange={(e) =>
                  setEditRubro({
                    ...editRubro,
                    slug: e.target.value
                  })
                }
                inputProps={{ maxLength: MAX_SLUG_LENGTH }}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url imagen
                <Typography variant="subtitle2">
                  {editRubro.urlImagen?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlImg')}
                value={editRubro.urlImagen || ''}
                onChange={(e) =>
                  setEditRubro({
                    ...editRubro,
                    urlImagen: e.target.value
                  })
                }
                disabled={true}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url Foto
                <Typography variant="subtitle2">
                  {editRubro.urlFoto?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlFoto')}
                value={editRubro.urlFoto || ''}
                onChange={(e) =>
                  setEditRubro({
                    ...editRubro,
                    urlFoto: e.target.value
                  })
                }
                disabled={true}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url icono
                <Typography variant="subtitle2">
                  {editRubro.urlIcono?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlIcono')}
                value={editRubro.urlIcono || ''}
                onChange={(e) =>
                  setEditRubro({
                    ...editRubro,
                    urlIcono: e.target.value
                  })
                }
                disabled={true}
              />
            </Stack>
            <Stack sx={{ width: '100%', margin: '20px 0' }}>
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                url Portada
                <Typography variant="subtitle2">
                  {editRubro.urlPortada?.length || 0}/{MAX_URL_LENGTH}
                </Typography>
              </InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                {...register('urlPortada')}
                value={editRubro.urlPortada || ''}
                onChange={(e) =>
                  setEditRubro({
                    ...editRubro,
                    urlPortada: e.target.value
                  })
                }
                disabled={true}
              />
            </Stack>
            <DialogActions
              sx={{
                diplay: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-end',
                width: '100%',
                padding: '22px',
                paddingTop: 0,
                gap: '8px',
                position: 'relative'
              }}
            >
              {isMobile && (
                <Button color="inherit" onClick={handleClose}>
                  Volver
                </Button>
              )}
              <Stack direction="row" spacing={2}>
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? 'Grabando' : 'Grabar'}
                </Button>
              </Stack>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  )
}

export default memo(EditRubro)
