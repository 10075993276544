import { Box, Typography } from '@mui/material'

const Precio = ({product}: {product: any}) => {
  // if(!product.precioMinorista) return null

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant="body1" fontWeight={"bold"}>
        ${new Intl.NumberFormat('es-AR').format(product.precio)}
      </Typography>
      {
        product.precioMinorista > 0 && <Typography variant="body1" fontSize={'12px'}>
          Por menor: ${new Intl.NumberFormat('es-AR').format(product.precioMinorista)}
        </Typography>
      }
    </Box>
  )
}

export default Precio
