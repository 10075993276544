import { Typography } from '@mui/material'
import { memo } from 'react'

interface Props {
  children: any
  props?: any
}

const DescripcionPaso = ({ children, props }: Props) => {
  return (
    <Typography fontWeight={{ xs: 12, md: 16 }} sx={{ fontWeight: 'medium' }} {...props}>
      {children}
    </Typography>
  )
}

export default memo(DescripcionPaso)
