import React, { useCallback, useContext, useEffect, useState } from "react";
import { axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import { useNavigate } from "react-router";

import SnackBarUtils from "../../utils/SnackBarUtils";
import AlertDialog from "../Common/Dialog";
import { Dialog, DialogActions, DialogContent, DialogTitle, useTheme, useMediaQuery } from "@mui/material";
import CustomDataGrid from "./CustomDataGrid";
import HistorialCards from "./HistorialCards";
import { notificarSobreEstafador } from "../../pages/AbmLocalPage/services/locales.service";
import ConfirmDialog from "../../pages/Products/components/ConfirmDialog";
import { useLocal } from "../../pages/AbmLocalPage/hooks/local.hook";
import { styled } from "@mui/material";
import { TenantsContext } from "../../context/tenants.context";

export interface HistorialDTO {
  aprobado: boolean;
  cuentaTipo: string;
  direccion: string;
  fecha: string;
  fechaBaja: any;
  idLocal: number;
  idLocalHistorial: number;
  localTipo: string;
  nombre: string;
  rechazado: boolean;
}

const ActionsGroup = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: "0px 15px",
  gap: "10px",
  flexWrap: "wrap",
});

const Info = styled("div")(({ theme }) => ({
  fontSize: "calc(15px + 1vmin)",
  fontFamily: theme.typography.fontFamily,
}));

const AdminLocalState: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [historialOpen, setHistorialOpen] = useState(false);
  const [historial, setHistorial] = useState<HistorialDTO[]>([]);
  const [loadingHistorial, setLoadingHistorial] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: "",
      body: "",
      leftButton: "Cancelar",
      rightButton: "Confirmar",
      colorButtonConfirm: "primary",
    },
    action: async () => {},
  });
  const theme = useTheme();
  const { tenant } = useContext(TenantsContext);
  const { local } = useLocal(props.premise?.codLocal);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleNotificacionEstafador = useCallback(() => {
    const rangoDias = local?.config.find((c: any) => c.name === "notificacion_local_sospechoso_dias")?.value;
    if (props.premise?.codLocal)
      setConfirmDialog({
        open: true,
        text: {
          title: "¿Deseás notificar sobre un posible estafador?",
          body: `Se le enviará un mensaje a todo comprador que haya contactado al local en lo últimos ${
            rangoDias ? rangoDias : ""
          } días. `,
          leftButton: "Cancelar",
          rightButton: "Confirmar",
          colorButtonConfirm: "error",
        },
        action: async () => {
          await notificarSobreEstafador(props.premise?.codLocal, tenant)
            .then((response) => SnackBarUtils.success(response.data.data))
            .catch(() => console.log());
          clearConfirmDialog();
        },
        close: () => clearConfirmDialog(),
      });
  }, [local, props.premise]);

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false,
    }));
  };

  const getLocal = (id: number) => {
    axiosInstanceMain
      .get(`admin/${tenant.path}/locales/` + id)
      .then((result) => {
        const data = result.data.data;
        props.setPremise(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    props.setPremise(props.premise);
  }, [props.premise]);

  const changeLocalStatus = async () => {
    await getLocal(props.premise?.codLocal);
    SnackBarUtils.success("El local ha sido actualizado.");
  };

  const approve = () => {
    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/${props.premise?.codLocal}/aprobar`)
      .then((result) => {
        changeLocalStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const reject = () => {
    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/${props.premise?.codLocal}/rechazar`)
      .then((result) => {
        changeLocalStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const pause = () => {
    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/${props.premise?.codLocal}/pausar`)
      .then((result) => {
        changeLocalStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const unpause = () => {
    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/${props.premise?.codLocal}/despausar`)
      .then((result) => {
        changeLocalStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deletePremise = () => {
    axiosInstanceMain
      .delete(`/admin/${tenant.path}/locales/${props.premise?.codLocal}`)
      .then((result) => {
        SnackBarUtils.success("El local ha sido eliminado.");
        navigate("/abm-locales");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHistorial = () => {
    setLoadingHistorial(true);
    axiosInstanceMain
      .get(`/admin/${tenant.path}/locales/${props.premise?.codLocal}/historial`)
      .then((result) => {
        setHistorial(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingHistorial(false);
      });
  };

  const handleHistorialClick = () => {
    setHistorialOpen(true);
    getHistorial();
  };

  return (
    <>
      <ActionsGroup>
        <Info className="premise-info-child">Estado:</Info>
        <Info className="premise-info-child">{props.premise?.statusString}</Info>
        <button
          className={
            props.premise?.statusString === "Aprobado"
              ? "btn btn-light admin-local-btn premise-info-child"
              : "btn btn-success admin-local-btn premise-info-child"
          }
          onClick={() => (props.premise?.statusString === "Aprobado" ? pause() : approve())}
        >
          {props.premise?.statusString === "Aprobado" ? "Pausar" : "Aprobar"}
        </button>
        <button
          style={
            props.premise?.statusString !== "Pendiente de aprobación" && props.premise?.statusString !== "Rechazado"
              ? { display: "none" }
              : {}
          }
          onClick={unpause}
          className={"btn btn-success admin-local-btn premise-info-child"}
        >
          Despausar
        </button>
        <button
          style={props.premise?.statusString === "Rechazado" ? { display: "none" } : {}}
          onClick={reject}
          className="btn btn-danger admin-local-btn premise-info-child"
        >
          Rechazar
        </button>
        <button onClick={() => setDialogOpen(true)} className="btn btn-danger admin-local-btn premise-info-child">
          Eliminar
        </button>
        <button onClick={handleHistorialClick} className="btn btn-secondary admin-local-btn premise-info-child">
          Historial
        </button>
        <button
          style={{ ...(!isMobile && { marginLeft: "80px" }) }}
          className="btn btn-primary admin-local-btn premise-info-child"
          onClick={() => handleNotificacionEstafador()}
        >
          {isMobile ? "Notif. sobre un estafador" : "Notif. compradores sobre un estafador"}
        </button>
      </ActionsGroup>

      <AlertDialog
        title="¿Deseas eliminar este local?"
        content="Esta acción no se puede deshacer."
        onConfirm={deletePremise}
        agreeText="Eliminar"
        agreeColor="error"
        disagreeText="Cancelar"
        onCancel={() => setDialogOpen(false)}
        open={dialogOpen}
      />

      <Dialog open={historialOpen} maxWidth="xl" fullWidth>
        <DialogTitle>Historial</DialogTitle>
        <DialogContent>
          {isMobile ? (
            <HistorialCards data={historial} loadingHistorial={loadingHistorial} />
          ) : (
            <CustomDataGrid data={historial} loadingHistorial={loadingHistorial} />
          )}
        </DialogContent>
        <DialogActions>
          <button onClick={() => setHistorialOpen(false)} className="btn btn-secondary">
            Cerrar
          </button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog {...confirmDialog} />
    </>
  );
};

export default AdminLocalState;
