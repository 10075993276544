import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { AsignarAtributoProps } from './types/atributos'
import { useCallback, useMemo, useState } from 'react'
import { useStoreImportacion } from './store/store-importacion'
import { cleanString } from '../../../utils/utils'
import { CustomCheckbox, SquareIcon } from '../../../components/Common/utils/CustomCheckbox'
import { CustomInput, CustomSelect } from '../../../components/Common/utils/input/CustomInput'
import { Check } from '@mui/icons-material'

const AsignarAtributoImpo = ({
  rubro,
  paso,
  handleChange,
  handleNext,
  equivalencias,
  atributosValores
}: AsignarAtributoProps) => {
  const loading = useStoreImportacion((state) => state.loading)
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string
  }>({})
  const atributosAllValues = rubro.atributosDefault.all
  const atributo = rubro.atributosDefault.key
  const atributosAllValuesOrdenado = useMemo(() => {
    if (!atributosAllValues) return []
    return atributosAllValues.valoresList.sort((a, b) => a.nombre.localeCompare(b.nombre))
  }, [atributosAllValues])

  const attrs = useMemo(() => rubro.atributosParaAsignar, [rubro.atributosParaAsignar])

  const handleAttrChange = useCallback(
    (attrName: string, value: string) => {
      if (!atributosAllValues) return
      const nombre = value.split('-')[1]
      const id = parseInt(value.split('-')[0])

      setSelectedValues((prevValues) => ({
        ...prevValues,
        [attrName]: id + '-' + nombre
      }))
      handleChange(attrName, { id, nombre, equivalencia: attrName }, atributosAllValues, rubro.rubro.codRubro)
    },
    [atributosAllValues, handleChange, rubro.rubro.codRubro]
  )

  if (!handleNext || cleanString(atributo) !== cleanString(paso)) return null
  if (attrs.every((a) => atributosAllValues.valoresList.map((v) => v.nombre).includes(a))) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>{rubro.rubro.nombre}</TableCell>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold' }}>Atributo en Avellaneda a un Toque</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attrs.map((a: string, i: number) => {
            if (
              !atributosAllValues ||
              atributosAllValues.valoresList.find((v) => v.nombre.toLowerCase() === a.toLowerCase())?.nombre
            )
              return null

            const foundEquivalencia = atributosValores.find((av) => a === av.nombreAtributoValorExterno)

            return (
              <TableRow key={i}>
                <TableCell width={'300px'}>
                  <Typography fontSize={{ xs: 12, md: 16 }} color="#8E8E8E" fontWeight="bold">
                    {a}
                  </Typography>
                </TableCell>
                <TableCell width={'300px'}>
                  <CustomInput
                    select
                    SelectProps={{ native: true }}
                    value={
                      selectedValues[a] ||
                      (equivalencias &&
                        equivalencias[a as any] &&
                        equivalencias[a as any].id + '-' + equivalencias[a as any].value) ||
                      (foundEquivalencia
                        ? `${foundEquivalencia.idProductoAtributoValorNormalizado}-${foundEquivalencia.nombreValorAa1t}`
                        : '') ||
                      ''
                    }
                    onChange={(e) => handleAttrChange(a, e.target.value)}
                    disabled={loading}
                    sx={{ width: '200px' }}
                  >
                    <option disabled value="">
                      <em>Seleccione</em>
                    </option>
                    {atributosAllValuesOrdenado.map((v, i) => (
                      <option key={v.id} value={v.id + '-' + v.nombre}>
                        {v.nombre}
                      </option>
                    ))}
                  </CustomInput>
                </TableCell>
                <TableCell width={'300px'}>
                  {atributosAllValues.puedeCargarValorLibre && (
                    <FormControlLabel
                      disabled={loading}
                      control={
                        <CustomCheckbox
                          sx={{ alignSelf: 'center' }}
                          icon={<SquareIcon />}
                          checkedIcon={<Check />}
                          checked={false}
                        />
                      }
                      label="No está en la lista"
                      sx={{ width: '200px', alignSelf: 'center' }}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AsignarAtributoImpo
