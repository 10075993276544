import React from 'react'
import { Table, TableHead, TableBody, TableCell, TableRow, TableFooter } from '@mui/material'
import { ILocalesRelacionados } from '../../../dto/LocalDTO'
import { MaximizeIcon } from '../../../icons/maximize-icon/maximize-icon.component'
import TableDataPagination from '../../../pages/Products/components/TableDataPagination'
import { Paginator } from '../../../pages/AdminProducts/context/productos.context'
import { Button } from '../../Common/utils/button/button.component'

interface TablaProps {
  locales: ILocalesRelacionados[]
  headers: string[]
  redirectToLocalDetail: (code: string) => void
  page: number
  pageSize: number
  paginator: Paginator
}

const TablaLocalesRelacionados: React.FC<TablaProps> = ({
  locales,
  headers,
  redirectToLocalDetail,
  page,
  pageSize,
  paginator
}: TablaProps) => {
  return (
    <Table style={{ border: '1px solid #e0e0e0' }}>
      <TableHead
        aria-label="Locales"
        sx={{
          fontWeight: 'bold',
          backgroundColor: '#e0e0e0',
          color: 'white'
        }}
      >
        <TableRow>
          {headers.map((h: string, i: number) => (
            <TableCell
              key={i}
              sx={{
                fontWeight: 'bold',
                fontSize: '18px',
                color: 'black',
                textAlign: 'center'
              }}
            >
              {h}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(pageSize > 0 ? locales.slice(page * pageSize, page * pageSize + pageSize) : locales).map(
          (local: ILocalesRelacionados, index: number) => {
            return (
              <TableRow key={index} style={{ cursor: 'pointer' }}>
                <TableCell style={{ textAlign: 'center' }}>{local.codLocal}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.nombre}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.tipoRelacion}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.direccion}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.tipoCuenta}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.virtual}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>{local.cantidadProductos}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Button variant="secondary" size="small" onClick={() => redirectToLocalDetail(local.codLocal)}>
                    <MaximizeIcon />
                  </Button>
                </TableCell>
              </TableRow>
            )
          }
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableDataPagination
            total={locales.length || 0}
            page={page || 0}
            paginator={paginator}
            items={locales || []}
            rowsPerPage={pageSize || 10}
            component={'td'}
            label={`Locales por página`}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default TablaLocalesRelacionados
