import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalChangeEstado = ({ open, onClose, onConfirm }: Props) => {
  const [paso, setPaso] = useState(0);

  const handleClose = () => {
    onClose();
    setPaso(0);
  };

  const handleConfirm = () => {
    if (paso === 0) return setPaso(1);

    onConfirm();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="body1">
          {paso === 0
            ? "¿Es una instalación nueva, o solamente le cambiaste el dominio a tu tienda existente?"
            : "En caso de continuar, se eliminarán todos los productos que hayas traído de Woocommerce. Los que hayas cargado directamente en Avellaneda a un toque se mantendrán"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={paso === 0 ? "primary" : "error"}>
          {paso === 0 ? "Cambié de dominio" : "Cancelar"}
        </Button>
        <Button onClick={handleConfirm}>{paso === 0 ? "Nueva instalación" : "Aceptar"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalChangeEstado;
