import { Box, IconButton, SxProps, Typography } from "@mui/material";
import { IContacto, WhatsappContext } from "../../context/whatsapp.context";
import { MouseEvent, useContext, useState } from "react";
import { Archive, MarkChatUnread, Unarchive } from "@mui/icons-material";
import {
  checkSiPasoUnDia,
  mostrarFecha,
  mostrarFechaCompleta,
  mostrarHora
} from "../../../../utils/DateFunctions";

export const ContactoStyles: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 2,
  margin: 0,
  cursor: "pointer",
  borderBottom: "1px solid #e0e0e0",
  "&:hover": { backgroundColor: "#4d4d4d" },
};

const Contacto = ({ contacto }: { contacto: IContacto }) => {
  const {
    archivarContacto,
    desarchivarContacto,
    marcarNoLeido,
    setContactoSeleccionado,
    contactoSeleccionado,
  } = useContext(WhatsappContext);
  const [mostrarAcciones, setMostrarAcciones] = useState(false);

  const handleArchiveClick = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    return contacto.archived
      ? await desarchivarContacto(contacto)
      : await archivarContacto(contacto);
  };

  const handleUnReadClick = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    return await marcarNoLeido(contacto);
  };

  return (
    <Box
      key={contacto.id}
      onClick={() => setContactoSeleccionado(contacto)}
      onMouseEnter={() => setMostrarAcciones(true)}
      onMouseLeave={() => setMostrarAcciones(false)}
      sx={{
        ...ContactoStyles,
        backgroundColor:
          contacto.id === contactoSeleccionado.id ? "#4d4d4d" : "transparent",
      }}
    >
      {/* NUMERO DEL CONTACTO */}
      <Box component="span">{contacto.number}</Box>

      {/* FECHA ÚLTIMO MENSAJE */}
      {contacto.lastMessageDate && !mostrarAcciones && (
        <Typography
          variant="body1"
          color={
            contacto.id === contactoSeleccionado.id
              ? "white"
              : contacto.read
              ? "#bbbbbb"
              : "#00a884"
          }
        >
          {/* si paso mas de un dia del ultimo mensaje, mostrar la fecha, sino la hora */}
          {checkSiPasoUnDia(contacto.lastMessageDate)
            ? mostrarFecha(contacto.lastMessageDate)
            : mostrarHora(contacto.lastMessageDate)}
        </Typography>
      )}

      {/* ACCIONES */}
      <Box hidden={!mostrarAcciones} sx={{ display: "flex", gap: "4px", p: 0 }}>
        <IconButton
          color="inherit"
          sx={{ p: 0 }}
          onClick={(e) => handleArchiveClick(e)}
        >
          {contacto.archived ? (
            <Unarchive fontSize="small" />
          ) : (
            <Archive fontSize="small" />
          )}
        </IconButton>

        <IconButton
          color="inherit"
          sx={{ p: 0 }}
          onClick={(e) => handleUnReadClick(e)}
        >
          {contacto.read && <MarkChatUnread fontSize="small" />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default Contacto;
