import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme
} from "@mui/material";

interface TyCDialogProps {
  open: boolean;
  handleClose: any;
}

export default function TyCDialog({ open, handleClose }: TyCDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Términos y condiciones
        </DialogTitle>
        <DialogContent sx={{ p: 0, m: 0 }}>
          <iframe
            src="https://imgstatic.avellanedaaltoque.com/terminos-y-condiciones.html"
            width={isMobile ? "100%" : 500}
            height={450}
            style={{
              border: "none",
              overflow: "hidden",
              margin: 0,
              padding: "0px 20px",
            }}
            title="Términos y condiciones"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="info">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
