import React from "react";
import { IconButton, Dialog, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ExpandImageDialogProps {
  open: boolean;
  setExpandImage: (expandImage: boolean) => void;
  imageUrl: any;
  setShowImage?: (showImage: boolean) => void;
  isMobile: boolean;
}

export const ExpandImageDialog = ({
  open,
  setExpandImage,
  imageUrl,
  setShowImage,
  isMobile,
}: ExpandImageDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setExpandImage(false);
        setShowImage && setShowImage(false);
      }}
      aria-labelledby="imagen-dialog-title"
      aria-describedby="imagen-dialog-description"
      PaperProps={{
        style: {
          display: "flex",
          backgroundColor: "transparent",
          boxShadow: "none",
          maxWidth: "90%",
          maxHeight: "90%",
        },
      }}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            setExpandImage(false);
            setShowImage && setShowImage(false);
          }}
          sx={{
            position: "absolute",
            right: 3,
            top: 3,
            color: "black",
            backgroundColor: "white",
            fontSize: "10px"
          }}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={imageUrl}
          alt="imagen"
          style={{
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "90vh",
          }}
        />
      </Box>
    </Dialog>
  );
};

export default ExpandImageDialog;
