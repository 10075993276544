import { axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import { hash } from '../../../utils/hashFunction'
import { ITenant } from '../../AbmLocalPage/types'

/* export const getLocalInfo = async (code: number, token: string, tenant: ITenant) =>
  axiosInstanceMain.post(`/${tenant.path}/locales/${code}?vendedor`, {
    clave: "8842a618d3902314c703a354076db8b0",
    token: token,
  }); */

export const getMetricas = async (codLocal: string, tenant: ITenant, detallado?: boolean) => {
  let url = `${tenant.path}/metricas/local/${codLocal}`

  if (detallado !== undefined) url += `?detallado=${detallado}`

  return await axiosInstanceMain.get(url)
}

export const confirmarTenantLocal = async (codLocal: string, token: string, tenant: ITenant) => {
  return await axiosInstanceMain.get(`${tenant.path}/locales/${codLocal}/pertenece-al-tenant`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/plain'
    }
  })
}

export const decodeCode = async (codLocal: string, accessToken: string) =>
  await axiosInstanceMain.post('admin/decode-cod', codLocal, {
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${accessToken}`
    }
  })
