import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LocalVendedorDTO } from "../../../dto/LocalDTO";

interface UserInfoProps {
  local: LocalVendedorDTO;
  user: any;
}

const UserInfo = ({ local, user }: UserInfoProps) => {
  const [userFoto, setUserFoto] = useState<string | null>(null);

  useEffect(() => {
    if (user.user?.logo) {
      setUserFoto(user.user.logo);
    } else if (local.logo) {
      setUserFoto(local.logo);
    } else {
      setUserFoto(null);
    }
  }, [user, local]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        gap: "15px",
        mb: 3,
        p: 0.5,
      }}
    >
      {userFoto && (
        <Box>
          <img src={userFoto} alt={local.nombre} width={70} height="auto" />
        </Box>
      )}

      <Box>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {local.nombre}
        </Typography>
      </Box>

      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography variant="body2">{local.direccion}</Typography>
        </Box>
      </Box>

      <Box>
        <Typography variant="body1" fontWeight={"bold"}>
          {local.cuentaTipoNombre?.replace(/<\/?[^>]+(>|$)/g, "")}
        </Typography>
      </Box>

      {local.virtual && (
        <Typography variant="body1">Solo venta online</Typography>
      )}

      <Typography variant="body1">
        Estado: <b>{local.statusString}</b>
      </Typography>
    </Box>
  );
};

export default UserInfo;
