import React, { useContext, useState, useEffect } from 'react'
import { Select, InputLabel, MenuItem, Box } from '@mui/material'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import Navbar from '../../components/Navbar'
import { useTheme, useMediaQuery } from '@mui/material'
import Loading from '../../components/Common/Loading'
import { getAllReportes, getInfoReporte } from './services/reportes.services'
import { DataReporte, IReporte } from './types/reporte'
import TablaReporte from './componentes/desktop/tablaReportes'
import CardReporte from './componentes/mobile/cardReportes'
import Footer from '../Home/components/Footer'
import { TenantsContext } from '../../context/tenants.context'

const Reportes: React.FC = () => {
  const [reportes, setReportes] = useState<IReporte[]>([])
  const [reporte, setReporte] = useState<string>('')
  const [infoReporte, setInfoReporte] = useState<DataReporte[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { tenant } = useContext(TenantsContext)

  useEffect(() => {
    try {
      setLoading(true)
      getAllReportes(tenant).then((res) => setReportes(res.data.data))
    } catch {
      console.log()
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (reporte === '') return
    setLoading(true)
    try {
      getInfoReporte(reporte, tenant).then((res) =>
        setInfoReporte(res.data.data)
      )
    } catch {
      console.log()
    } finally {
      setLoading(false)
    }
  }, [reporte])

  return (
    <>
      <Navbar title="Reportes" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '20px',
          marginLeft: isMobile ? '10px' : '20px',
          marginRight: isMobile ? '10px' : '0px',
          gap: '20px'
        }}
      >
        <Select
          native
          labelId="select-label"
          id="select"
          value={reporte}
          onChange={(e) => setReporte(e.target.value)}
          style={{ width: isMobile ? '100%' : '60%' }}
          placeholder="Seleccione"
        >
          <option disabled value="" label="Seleccione">
            Seleccione
          </option>
          {reportes.map((reporte: IReporte) => (
            <option key={reporte.queryReporte} value={reporte.queryReporte}>
              {reporte.nombreReporte}
            </option>
          ))}
        </Select>
        {loading && <Loading style={{ margin: 0, marginTop: '10px' }} />}
      </Box>
      {infoReporte &&
        (isMobile ? (
          <CardReporte loading={loading} reporte={infoReporte} />
        ) : (
          <TablaReporte loading={loading} reporte={infoReporte} />
        ))}
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(Reportes)
