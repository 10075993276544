import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  styled
} from "@mui/material";
import TableData from "./TableData";
import TableDataPagination from "./TableFooter";
import { TablaAccionesMasivas } from "../../../Products/components/Desktop/Tabla";
import { useContext } from "react";
import { ProductosAdminContext } from "../../context/productos.context";
import { ProductsAdminProps } from "../..";
import { useSortableData } from "../../../../utils/UseSorteableDataHook";

export const TableTitle = styled("h5")({
  margin: 0,
  fontWeight: "bold",
});

export const TABLE_HEAD_ITEMS = [
  { key: ".", label: "" },
  { key: "-", label: "" },
  { key: "nombreLocal", label: "Local" },
  { key: "nombre", label: "Nombre" },
  { key: "nombreRubro", label: "Rubro" },
  { key: "precioFormateado", label: "Precio" },
  { key: "fechaPublicacion", label: "Fechas" },
  { key: "flags", label: "Flags" },
  { key: "*", label: "" }
];

const TableProductos = ({
  rubros,
  handlePauseProducts,
  handleUnPauseProducts,
  handleDeleteProducts,
  handleExpireProducts,
  handleChangeRubro,
}: ProductsAdminProps) => {
  const {
    productos,
    accionesMasivas,
    puedePausarVarios,
    puedeReanudarVarios,
    puedeVencerVarios,
    productosSeleccionados,
    handleLeft,
  } = useContext(ProductosAdminContext);
  const { items, requestSort, sortConfig } = useSortableData(productos ? productos : []);
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig["key"] === name ? sortConfig["direction"] : undefined;
  };

  return (
    <TableContainer component={Paper}>
      {accionesMasivas && (
        <TablaAccionesMasivas
          isAdmin={true}
          rubros={rubros}
          handleDeleteProducts={handleDeleteProducts}
          handlePauseProducts={handlePauseProducts}
          handleUnPauseProducts={handleUnPauseProducts}
          handleExpireProducts={handleExpireProducts}
          handleChangeRubro={handleChangeRubro}
          handleLeft={handleLeft}
          puedePausarVarios={puedePausarVarios}
          puedeReanudarVarios={puedeReanudarVarios}
          puedeVencerVarios={puedeVencerVarios}
          productosSeleccionados={productosSeleccionados}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEAD_ITEMS.map((item, i) => (
              <TableCell key={item.key}>
                <TableTitle>
                  <button
                    style={{
                      backgroundColor: "white",
                      ...(item.label !== "" && { cursor: "pointer" }),
                    }}
                    type="button"
                    onClick={() => item.label !== "" && requestSort(item.key)}
                    className={item.label !== "" ? getClassNamesFor(item.key) : ""}
                  >
                    {item.label}
                  </button>
                </TableTitle>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableData items={items} />
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableDataPagination component={"td"} />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TableProductos;
