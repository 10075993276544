/* eslint-disable react/prop-types */
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { localesType, mockData, postLocalType } from '../../types'
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form'
import FormUserData from './FormUserData'
import { useContext, useEffect, useRef, useState } from 'react'
import { validarNombre, validarUsuario } from '../../services/validar.service'
import SnackBarUtils from '../../../../utils/SnackBarUtils'
import { postLocal, updateLocal } from '../../services/locales.service'
import { hash } from '../../../../utils/hashFunction'
import { useCaracteristicas } from '../../hooks/caracteristicas.hook'
import FormDireccion from './FormDireccion'
import FormMedioDeContactos from './FormMedioDeContactos'
import FormCaracteristicas from './FormCaracteristicas'
import FormDescripcion from './FormDescripcion'
import FormFaq from './FormFaq'
import FormActions from './FormActions'
import FormTyC from './FormTyC'
import { LoginService } from '../../../Login/services/login.service'
import { connect } from 'react-redux'
import { useFaq } from '../../hooks/faq.hook'
import { getFaqs } from '../../services/faq.service'
import FormHorarios from './FormHorarios'
import { useGalerias } from '../../hooks/galerias.hook'
import Loading from '../../../../components/Common/Loading'
import AlertDialog from '../../../../components/Common/Dialog'
import FormRecaptcha from './FormRecaptcha'
import { RolType } from '../../../../components/Navbar/Sidebar/SidebarItems'
import { Close } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { useDataPais } from '../../hooks/dataPais.hook'
import { TenantsContext } from '../../../../context/tenants.context'
import { LocalVendedorDTO } from '../../../../dto/LocalDTO'

export const MAX_LENGTH_USUARIO = 30
export const MAX_LENGTH_DESC = 500
export const MAX_LENGTH_NOMBRE_LOCAL = 50
export const MAX_LENGTH_DESC_WSP = 10
export const MAX_LENGTH_FAQ_PREGUNTA = 100
export const MIN_ENVIOS = 50
export const MAX_NUM_LOCAL = 20
export const MAX_NOMBRE_GALERIA_LENGTH = 75
export const MAX_DIR_GALERIA_LENGTH = 150

interface FormLocalesProps {
  local?: LocalVendedorDTO
  getLocal: (codLocal: string) => Promise<void>
  setSoloLectura: (value: boolean) => void
  props: any
  setLoading: (value: boolean) => void
}

export const Asterisco = () => <span style={{ color: 'red' }}>*</span>
export const telRegex = /^(?!15|0|54)\d{10}$/

const FormLocales = ({ local, getLocal, setSoloLectura, props }: FormLocalesProps) => {
  const {
    logged: { userLogged }
  } = props
  const { galerias, loading: loadingGalerias } = useGalerias()
  const [selectGaleria, setSelectGaleria] = useState<any | null>(null)
  const [directionRadio, setDirectionRadio] = useState<string>('')
  const [caracteristicasStringChecked, setCaracteristicasStringChecked] = useState<string[]>([])
  const [enableDelivery, setEnableDelivery] = useState<boolean>(false)
  const [locales] = useState<localesType[]>(mockData.galeriaLocales[1])
  const { caracteristicas } = useCaracteristicas()
  const { faqs } = useFaq()
  const [whatsapps, setWhatsapps] = useState<any[]>([])
  const [usuarioEnUso, setUsuarioEnUso] = useState<boolean>(false)
  const [nombreLocalEnUso, setNombreLocalEnUso] = useState<boolean>(false)
  const [esVirtualYVendedor, setEsVirtualYVendedor] = useState<boolean>(false)
  const [center, setCenter] = useState<string>('')
  const [galeriaPropuesta, setGaleriaPropuesta] = useState(false)
  const [tokenCaptcha, setTokenCaptcha] = useState('')
  const [proponeGaleria, setProponeGaleria] = useState(false)
  const refCaracteristicas = useRef(null)
  const refWhatsapp = useRef(null)
  const refDireccion = useRef(null)
  const isAdmin = userLogged?.roles?.includes(RolType.ADMIN)
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [long, setLong] = useState(0)
  const [lat, setLat] = useState(0)
  const { infoPais } = useDataPais()
  const { tenant } = useContext(TenantsContext)

  const checkUsuario = async (value: string) => {
    try {
      if (!value) return true
      const res = await validarUsuario(value, tenant, local?.idVendedor.toString())
      if (!res) return true
      setUsuarioEnUso(res.data.errorCode === -11)
      if (res.data.errorCode === -11) {
        setError('usuario', {
          type: 'manual',
          message: 'El usuario ya está en uso'
        })
      } else {
        clearErrors('usuario')
      }
      return res.data.errorCode !== -11
    } catch (e) {
      console.log(e)
      return false
    }
  }

  const schema: yup.AnyObjectSchema = yup.object({
    nombreLocal: yup
      .string()
      .required('El nombre del local es requerido')
      .max(MAX_LENGTH_NOMBRE_LOCAL, `El nombre del local no puede superar los ${MAX_LENGTH_NOMBRE_LOCAL} caracteres`),
    desc: yup.string().max(MAX_LENGTH_DESC, `La descripción no puede superar los ${MAX_LENGTH_DESC} caracteres`),
    usuario: yup
      .string()
      .required('El usuario es requerido')
      .max(MAX_LENGTH_USUARIO, `El usuario no puede superar los ${MAX_LENGTH_USUARIO} caracteres`)
      .test('is-unique', 'El usuario ya está en uso', async (value: any): Promise<boolean> => {
        if (value !== local?.usuario) {
          return await checkUsuario(value)
        }
        return true
      }),
    contrasenia: local ? yup.string().nullable() : yup.string().required('La contraseña es requerida'),
    mail: yup.string().email('El mail no es válido').typeError('El mail no es válido').nullable(),
    telefono: yup
      .string()
      .matches(new RegExp(`^(?!15|0|${infoPais?.codigoTelefonico})\\d{10}$`), {
        message: 'Número inválido',
        excludeEmptyString: true
      })
      .typeError('El teléfono es requerido'),
    whatsapp: local
      ? yup.string().nullable()
      : yup
          .string()
          .matches(new RegExp(`^(?!15|0|${infoPais?.codigoWhatsapp})\\d{10}$`), {
            message: 'Número inválido',
            excludeEmptyString: true
          })
          .required('El número de whatsapp es requerido'),
    aceptaTyC:
      local && !local.aceptaTyC && !isAdmin
        ? yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones')
        : !local
        ? yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones')
        : yup.boolean().nullable(),
    dirLocal:
      directionRadio === 'calle'
        ? yup.string().required('La calle es requerida').typeError('La calle es requerida').nullable()
        : yup.string().nullable(),
    dirGaleria:
      directionRadio === 'galeria' || proponeGaleria
        ? yup
            .string()
            .required('La galería es requerida')
            .typeError('La galería es requerida')
            .max(MAX_DIR_GALERIA_LENGTH, `No puede superar los ${MAX_DIR_GALERIA_LENGTH} caracteres`)
        : yup.string().nullable(),
    numLocal:
      directionRadio === 'galeria' && !proponeGaleria
        ? yup.string().required('Requerido').max(MAX_NUM_LOCAL, `No puede superar los ${MAX_NUM_LOCAL} caracteres`)
        : yup.string().nullable(),
    numGaleriaLocal: proponeGaleria
      ? yup.string().required('Requerido').max(MAX_NUM_LOCAL, `No puede superar los ${MAX_NUM_LOCAL} caracteres`)
      : yup.string(),
    nombreGaleria:
      proponeGaleria && !galeriaPropuesta
        ? yup
            .string()
            .required('Requerido')
            .max(MAX_NOMBRE_GALERIA_LENGTH, `No puede superar los ${MAX_NOMBRE_GALERIA_LENGTH} caracteres`)
        : yup.string().nullable(),
    compraMinimaParaEnvio: enableDelivery
      ? yup
          .string()
          .typeError('La compra mínima para envío es requerida')
          .required('La compra mínima para envío es requerida')
          .max(MIN_ENVIOS, `El máximo debe ser ${MIN_ENVIOS} caracteres`)
      : yup.string().nullable(),
    faq: yup.array().of(
      yup.object().shape({
        pregunta: yup
          .string()
          .required('La pregunta es requerida')
          .max(MAX_LENGTH_FAQ_PREGUNTA, `La pregunta no puede superar los ${MAX_LENGTH_FAQ_PREGUNTA} caracteres`),
        respuesta: yup
          .string()
          .required('La respuesta es requerida')
          .max(MAX_LENGTH_DESC, `La respuesta no puede superar los ${MAX_LENGTH_DESC} caracteres`)
          .nullable()
      })
    )
  })

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
    setError,
    setFocus,
    clearErrors
  } = useForm<any>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: async () => {
      if (!local) {
        return {
          faq: (await getFaqs(tenant)).data.faq,
          caracteristicasId: []
        }
      }
      return {
        caracteristicasId: [],
        nombreLocal: local.nombre,
        galeriaPropuestaDireccion: local.direccion,
        numLocal: local.ubicacionEnGaleria && local.ubicacionEnGaleria[0]?.valor,
        dirLocal: local.direccion,
        compraMinimaParaEnvio: local.compraMinimaParaEnvio,
        faq: local.faq,
        desc: local.descripcion,
        usuario: local.usuario,
        horarioList: local.horarioList,
        telefono: local.medioDeContactoList?.find((m) => m.tipo === 2)?.valor,
        mail: local.medioDeContactoList?.find((m) => m.tipo === 3)?.valor
      }
    }
  })

  useEffect(() => {
    if (!local) {
      reset({
        faq: faqs
      })
    }
  }, [faqs, reset, local])

  const handleChangeGaleria = (code: any) => {
    setSelectGaleria(code)
  }

  const handleCaracteristicas = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCaracteristicasStringChecked([...caracteristicasStringChecked, e.target.name])
    } else {
      setCaracteristicasStringChecked([
        ...caracteristicasStringChecked.filter((c) => {
          return c !== e.target.name
        })
      ])
    }
    if (e.target.name === 'Hace envíos') {
      setEnableDelivery(!enableDelivery)
    }
  }

  const checkNombreLocal = async (value: string) => {
    try {
      if (!value) return
      const res = await validarNombre(value, tenant, local?.codLocal)
      if (!res) return
      setNombreLocalEnUso(res.data.errorCode === -11)
    } catch (e) {
      console.log(e)
    }
  }

  const getMedioDeContactos = () => {
    const telefono = getValues('telefono')
    const mail = getValues('mail')

    const medioDeContactoList = [
      {
        tipo: 2,
        valor: telefono,
        descripcion: ''
      },
      {
        tipo: 3,
        valor: mail,
        descripcion: ''
      }
    ]

    let medioDeContactoListWhatsapp = []

    if (local) {
      medioDeContactoListWhatsapp = whatsapps.map((w: any) => {
        return {
          tipo: 1,
          valor: w.valor,
          descripcion: w?.descripcion || ''
        }
      })
    } else {
      medioDeContactoListWhatsapp = [
        {
          tipo: 1,
          valor: getValues('whatsapp'),
          descripcion: ''
        }
      ]
    }

    return [...medioDeContactoList, ...medioDeContactoListWhatsapp]
  }

  const getCaracteristicasId = () => {
    return caracteristicas
      .map((c) => {
        if (caracteristicasStringChecked.includes(c.nombre)) return c.caracteristicaLocalId
        return 0
      })
      .filter((a) => a !== 0)
  }

  const onInvalid: SubmitErrorHandler<postLocalType> = (errors) => {
    console.log(errors)
  }

  const handleLoginService = async (user: string, pwd: string) => {
    try {
      const resLogin = await LoginService(
        {
          user,
          pwd,
          captcha: tokenCaptcha
        },
        tenant
      )
      return resLogin
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit: SubmitHandler<postLocalType> = async (data) => {
    if (!local && !tokenCaptcha) return SnackBarUtils.error('Captcha inválido')
    if (nombreLocalEnUso || usuarioEnUso) return
    if (!caracteristicasStringChecked.includes('Minorista') && !caracteristicasStringChecked.includes('Mayorista')) {
      const cu = refCaracteristicas.current as any
      cu && cu.scrollIntoView()
      setError('caracteristicasId', {
        type: 'manual',
        message: 'Indicá si tu local vende mayorista, minorista, o los dos'
      })
      return SnackBarUtils.error('Indicá si tu local vende mayorista, minorista, o los dos')
    }
    clearErrors('caracteristicasId')
    // if(checkHorarios(data.horarios as unknown as Horario[]))
    //   return SnackBarUtils.error("Completá todos los horarios en los que el local está abierto")
    if (proponeGaleria && !galeriaPropuesta) {
      const cu = refDireccion.current as any
      cu && cu.scrollIntoView()
      return SnackBarUtils.error('Confirma los datos de la galería propuesta')
    }
    if (center === '' && directionRadio === 'calle') {
      const cu = refDireccion.current as any
      cu && cu.scrollIntoView()
      return SnackBarUtils.error('La dirección se encuentra fuera del radio permitido')
    }

    if (local) {
      const found = whatsapps.find((w) => w.valor !== '' && !w.valor.match(/^(?!15|0|54)\d{10}$/))
      if (found) {
        const cu = refWhatsapp.current as any
        cu && cu.scrollIntoView()
        return SnackBarUtils.error('Número de whatsapp no válido')
      }
    }

    const newLocal: postLocalType = {
      aceptaTyC: true,
      caracteristicasId: getCaracteristicasId(),
      clave: await hash(data.nombreLocal + 'lugares2017'),
      compraMinimaParaEnvio: enableDelivery ? data.compraMinimaParaEnvio : undefined,
      desc: data.desc,
      dirLocal: directionRadio === 'calle' ? data.dirLocal : undefined,
      codGaleria: directionRadio === 'galeria' ? selectGaleria : undefined,
      medioDeContactoList: getMedioDeContactos() as any,
      galeriaPropuestaNombre: galeriaPropuesta ? data.nombreGaleria : undefined,
      nombreLocal: data.nombreLocal,
      latLocal: lat,
      longLocal: long,
      galeriaPropuestaNumero: galeriaPropuesta ? data.numGaleriaLocal : undefined,
      numLocal: directionRadio === 'galeria' && !proponeGaleria ? data.numLocal : undefined,
      galeriaPropuestaDireccion: galeriaPropuesta ? data.dirLocal : undefined,
      galeriaPropuesta: galeriaPropuesta,
      usuario: data.usuario,
      faq: data.faq,
      horarioList: data.horarios,
      contrasenia: data.contrasenia,
      pwd: data.contrasenia,
      captcha: tokenCaptcha
    }

    try {
      if (!local) {
        const res = await postLocal(newLocal, tenant)
        if (res.data.errorCode !== 0) return SnackBarUtils.error(res.data.errorMessage)
        const resLogin = await handleLoginService(data.usuario, data.contrasenia)
        if (resLogin.status !== 200) return SnackBarUtils.error(resLogin.data.errorMessage)
        props.setLogged(resLogin.data)
        navigate('/abm-products')
        return SnackBarUtils.success('Local creado con éxito')
      } else {
        const res = await updateLocal(local.codLocal, newLocal, tenant)

        if (res.data.errorCode !== 0) return SnackBarUtils.error(res.data.errorMessage)
        setSoloLectura(true)
        await getLocal(local.codLocal)
        return SnackBarUtils.success('Local actualizado con éxito')
      }
    } catch (e) {
      console.log('ERROR: ', e)
      SnackBarUtils.error('Error al guardar el local')
    }
  }

  useEffect(() => {
    if (local) {
      reset({
        ...local,
        caracteristicasId: local.caracteristicasList?.filter((c: any) => c.activo).map((c) => c.id)
      })

      setEsVirtualYVendedor(userLogged?.roles.includes('vendedor') && local.virtual)

      const caracteristicas = local.caracteristicasList
        ?.filter((c: any) => c.activo)
        .map((c) => c.nombre.split(' - '))
        .flat(1)
      setCaracteristicasStringChecked(caracteristicas)
      local.enGaleria ? setDirectionRadio('galeria') : setDirectionRadio('calle')
      setSelectGaleria(local.codGaleria)
      setCenter(`${local.latitud},${local.longitud}`)

      setValue('mail', local.medioDeContactoList?.find((m: any) => m.tipo === 3)?.valor)
      setValue('telefono', local.medioDeContactoList?.find((m: any) => m.tipo === 2)?.valor)

      const wsps = local.medioDeContactoList?.filter((m: any) => m.tipo === 1)
      if (wsps?.length > 0) {
        const wspsComplete = wsps.concat(Array(3 - wsps.length).fill({ tipo: 1, valor: '', descripcion: '' }))
        setWhatsapps(wspsComplete)
      } else {
        setWhatsapps([
          { tipo: 1, valor: '', descripcion: '' },
          { tipo: 1, valor: '', descripcion: '' },
          { tipo: 1, valor: '', descripcion: '' }
        ])
      }

      const haceEnvios = local.caracteristicasList?.find((c) => c.nombre === 'Hace envíos')
      if (haceEnvios !== undefined) {
        setEnableDelivery(haceEnvios.activo)
      }
    }
  }, [local])

  useEffect(() => {}, [])

  useEffect(() => {
    const firstError = (Object.keys(errors) as Array<keyof typeof errors>).reduce<keyof typeof errors | null>(
      (field, a) => {
        const fieldKey = field as keyof typeof errors
        return errors[parseInt(fieldKey?.toString())] ? fieldKey : a
      },
      null
    )

    if (firstError && firstError !== 'root') {
      setFocus(firstError.toString())
    }
  }, [errors, setFocus, setError])

  if (loadingGalerias)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loading />
      </Box>
    )

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit, onInvalid)}
      sx={{ ...(isMobile && { marginBottom: '80px' }) }}
    >
      <AlertDialog
        open={isSubmitting}
        title="Guardando datos"
        content={local ? 'Se está actualizando el local' : 'Se está creando el local'}
        agreeText=""
        disagreeText=""
        onCancel={() => {}}
        onConfirm={() => {}}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Typography variant="body1" sx={{ mt: 2 }}>
          Los campos con <Asterisco /> son obligatorios
        </Typography>
        {isMobile && (
          <IconButton
            onClick={() => (local ? setSoloLectura(true) : navigate('/login'))}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>

      {/* Informacion de del usuario */}
      <FormUserData
        local={local}
        register={register}
        errors={errors}
        usuarioEnUso={usuarioEnUso}
        checkUsuario={checkUsuario}
        isSubmitting={isSubmitting}
        checkNombreLocal={checkNombreLocal}
        setValue={setValue}
        getValues={getValues}
        nombreLocalEnUso={nombreLocalEnUso}
      />

      {/* Direccion */}
      <FormDireccion
        galerias={galerias}
        local={local}
        setValue={setValue}
        getValues={getValues}
        register={register}
        errors={errors}
        directionRadio={directionRadio}
        setDirectionRadio={setDirectionRadio}
        selectGaleria={selectGaleria}
        setSelectGaleria={setSelectGaleria}
        handleChangeGaleria={handleChangeGaleria}
        locales={locales}
        esVirtualYVendedor={esVirtualYVendedor}
        center={center}
        setCenter={setCenter}
        userLogged={userLogged}
        isSubmitting={isSubmitting}
        setError={setError}
        clearErrors={clearErrors}
        setGaleriaPropuesta={setGaleriaPropuesta}
        proponeGaleria={proponeGaleria}
        setProponeGaleria={setProponeGaleria}
        refDireccion={refDireccion}
        setLat={setLat}
        setLong={setLong}
      />

      {/* Medios de contacto */}
      <FormMedioDeContactos
        props={props}
        register={register}
        setError={setError}
        errors={errors}
        clearErrors={clearErrors}
        local={local}
        setValue={setValue}
        setWhatsapps={setWhatsapps}
        whatsapps={whatsapps}
        isSubmitting={isSubmitting}
        refWhatsapp={refWhatsapp}
        pais={infoPais}
      />

      {/* Horarios */}
      <FormHorarios
        errors={errors}
        local={local}
        register={register}
        getValues={getValues}
        setValue={setValue}
        isSubmitting={isSubmitting}
      />

      {/* Caracteristicas */}
      <FormCaracteristicas
        register={register}
        clearErrors={clearErrors}
        caracteristicas={caracteristicas}
        caracteristicasStringChecked={caracteristicasStringChecked}
        enableDelivery={enableDelivery}
        errors={errors}
        handleCaracteristicas={handleCaracteristicas}
        isSubmitting={isSubmitting}
        refCaracteristicas={refCaracteristicas}
      />

      {/* FAQ */}
      <FormFaq
        control={control}
        register={register}
        setValue={setValue}
        getValues={getValues}
        setError={setError}
        errors={errors}
        isSubmitting={isSubmitting}
      />

      {/* Descripcion */}
      <FormDescripcion
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        register={register}
        isSubmitting={isSubmitting}
      />

      {/* Recaptcha */}
      <FormRecaptcha name="Register_backoffice" setToken={setTokenCaptcha} />

      {/* TYC */}
      {(!userLogged.isLogged || (userLogged.isLogged && userLogged.roles.includes('vendedor'))) && (
        <FormTyC register={register} errors={errors} local={local} isSubmitting={isSubmitting} />
      )}

      {/* Grabar o eliminar */}
      <FormActions setSoloLectura={setSoloLectura} isSubmitting={isSubmitting} local={local} />
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

export default connect(mapStateToProps)(FormLocales)
