import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TableFooter
} from '@mui/material'
import { ProductListProps } from '../..'
import { Delete, Pause, PlayArrow, OpenInNew, Check, Close } from '@mui/icons-material'

import { pauseProduct, unPauseProduct, deleteProduct } from '../../services/products.services'
import { approveProduct, deniedProduct } from '../../services/admin.services'
import { memo, useContext } from 'react'
import TableDataPagination from '../TableDataPagination'
import NoResults from '../../../../components/Common/NoResults'
import TablaProducto from './TablaProducto'
import AccionesMasivasButtons from '../AccionesMasivasButtons'
import Loading from '../../../../components/Common/Loading'
import { ProductosVendedorContext } from '../../context/productos.vendedor.context'
import { ITenant } from '../../../AbmLocalPage/types'
import { Rubro } from '../../../Integraciones/types/responses-productos'

const TABLE_HEADS = ['', 'Imagen', 'Código/SKU', 'Nombre', 'Rubro', 'Precio', 'Estado', 'Estadisticas', 'Acciones']

export const BUTTONS: any = {
  open: {
    text: 'Detalle',
    icon: <OpenInNew />
  },
  approve: {
    text: 'Aprobar',
    icon: <Check />,
    action: (code: string, tenant: ITenant) => approveProduct(code, tenant)
  },
  denied: {
    text: 'Rechazar',
    icon: <Close />,
    action: (code: string, tenant: ITenant) => deniedProduct(code, tenant)
  },
  pause: {
    text: 'Pausar',
    icon: <Pause />,
    action: (code: string, tenant: ITenant) => pauseProduct(code, tenant)
  },
  resume: {
    text: 'Reanudar',
    icon: <PlayArrow />,
    action: (code: string, tenant: ITenant) => unPauseProduct(code, tenant)
  },
  delete: {
    text: 'Borrar',
    icon: <Delete />,
    action: (code: string, tenant: ITenant) => deleteProduct(code, tenant)
  }
}

interface TablaProps extends ProductListProps {
  loading: boolean
  rowsPerPage: number
  page: number
  paginator: any
  isMobile: boolean
  usaSku: boolean
}

interface TablaAccionesMasivasProps {
  isAdmin: boolean
  rubros: Rubro[]
  handleDeleteProducts: () => void
  handlePauseProducts: () => void
  handleUnPauseProducts: () => void
  handleExpireProducts?: () => void
  handleChangeRubro: (codRubro: string, tenant: ITenant) => void
  handleLeft: () => void
  puedePausarVarios: boolean
  puedeReanudarVarios: boolean
  puedeVencerVarios: boolean
  productosSeleccionados: number[]
}

export const TablaAccionesMasivas = ({
  isAdmin,
  rubros,
  handleDeleteProducts,
  handlePauseProducts,
  handleUnPauseProducts,
  handleExpireProducts,
  handleChangeRubro,
  handleLeft,
  productosSeleccionados,
  puedePausarVarios,
  puedeReanudarVarios,
  puedeVencerVarios
}: TablaAccionesMasivasProps) => {
  return (
    <Box
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#d1d1d1'
      }}
    >
      <Typography variant="h6">
        {productosSeleccionados.length}{' '}
        {productosSeleccionados.length === 1 ? 'producto seleccionado' : 'productos seleccionados'}
      </Typography>
      <AccionesMasivasButtons
        isAdmin={isAdmin}
        rubros={rubros}
        handleDeleteProducts={handleDeleteProducts}
        handlePauseProducts={handlePauseProducts}
        handleUnPauseProducts={handleUnPauseProducts}
        handleExpireProducts={handleExpireProducts}
        handleChangeRubro={handleChangeRubro}
        handleLeft={handleLeft}
        puedePausarVarios={puedePausarVarios}
        puedeReanudarVarios={puedeReanudarVarios}
        puedeVencerVarios={puedeVencerVarios}
      />
    </Box>
  )
}

const Tabla = ({
  page,
  paginator,
  rowsPerPage,
  products,
  total,
  handleDeleteProduct,
  handleEditProduct,
  handlePauseProduct,
  handleResumeProduct,
  handleDeleteProducts,
  handlePauseProducts,
  handleUnPauseProducts,
  handleChangeRubro,
  isAdmin,
  loading,
  usaSku,
  rubros
}: TablaProps) => {
  const { accionesMasivas, productosSeleccionados, puedePausarVarios, puedeReanudarVarios, handleLeft } =
    useContext(ProductosVendedorContext)
  if (loading) return <Loading />
  return (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
      {accionesMasivas && rubros && (
        <TablaAccionesMasivas
          isAdmin={isAdmin}
          rubros={rubros}
          handleDeleteProducts={handleDeleteProducts}
          handlePauseProducts={handlePauseProducts}
          handleUnPauseProducts={handleUnPauseProducts}
          handleChangeRubro={handleChangeRubro}
          handleLeft={handleLeft}
          puedePausarVarios={puedePausarVarios}
          puedeReanudarVarios={puedeReanudarVarios}
          puedeVencerVarios={false}
          productosSeleccionados={productosSeleccionados}
        />
      )}
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            {TABLE_HEADS.map((head, i) => {
              if (head === 'Código/SKU' && !usaSku) {
                return null
              }

              return (
                <TableCell key={i} sx={{ fontSize: '17px' }} align="center">
                  {head}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.length === 0 && (
            <TableRow>
              <TableCell colSpan={TABLE_HEADS.length}>
                <NoResults />
              </TableCell>
            </TableRow>
          )}

          {products.map((product: any, i: any) => (
            <TablaProducto
              key={i}
              product={product}
              isAdmin={isAdmin}
              handleResumeProduct={handleResumeProduct}
              handlePauseProduct={handlePauseProduct}
              handleDeleteProduct={handleDeleteProduct}
              handleEditProduct={handleEditProduct}
              handleDeleteProducts={handleDeleteProducts}
              handlePauseProducts={handlePauseProducts}
              handleUnPauseProducts={handleUnPauseProducts}
              handleChangeRubro={handleChangeRubro}
              loading={loading}
              usaSku={usaSku}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableDataPagination
              total={total || 0}
              page={page}
              paginator={paginator}
              items={products}
              rowsPerPage={rowsPerPage}
              component={'td'}
              label={'Productos por página'}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {accionesMasivas && rubros && (
        <Box sx={{marginBottom: '5%'}}>
          <TablaAccionesMasivas
            isAdmin={isAdmin}
            rubros={rubros}
            handleDeleteProducts={handleDeleteProducts}
            handlePauseProducts={handlePauseProducts}
            handleUnPauseProducts={handleUnPauseProducts}
            handleChangeRubro={handleChangeRubro}
            handleLeft={handleLeft}
            puedePausarVarios={puedePausarVarios}
            puedeReanudarVarios={puedeReanudarVarios}
            puedeVencerVarios={false}
            productosSeleccionados={productosSeleccionados}
          />
        </Box>
      )}
    </TableContainer>
  )
}

export default memo(Tabla)
