import React, { useState, useCallback, memo, useContext } from 'react'
import { Card, Typography, Box, CardMedia, Button } from '@mui/material'
import EditRubro from '../../widgets/EditarRubro'
import { deleteRubro } from '../../services/rubros.operaciones'
import ConfirmDialog from '../../../Products/components/ConfirmDialog'
import { TenantsContext } from '../../../../context/tenants.context'
import { Rubro } from '../../../Integraciones/types/responses-productos'

interface CardRubrosProps {
  rubros: any[]
  rubro: Rubro
  isMobile: boolean
  updateRubros: () => void
  handleNodeSelect: (nodeId: string) => void
}

const CardRubros: React.FC<CardRubrosProps> = ({ rubros, isMobile, rubro, updateRubros, handleNodeSelect }) => {
  const isRootNode = !rubro.codRubroPadre
  const [modalUpdatedRubro, setModalUpdatedRubro] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })
  const { tenant } = useContext(TenantsContext)

  const handleDeleteRubro = useCallback((codRubro: any) => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Deseás eliminar este rubro?',
        body: 'Esta accion no se puede deshacer',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar',
        colorButtonConfirm: 'error'
      },
      action: async () => {
        await deleteRubro(codRubro, tenant)
        updateRubros()
        clearConfirmDialog()
      },
      close: () => clearConfirmDialog()
    })
  }, [])

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  if (isRootNode && !rubro.urlPortada) {
    return null
  }

  return (
    <Card
      key={JSON.stringify(rubros)}
      variant="outlined"
      sx={{
        px: { md: '1em', xs: '0.5em' },
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        border: '0.5px solid',
        backgroundColor: 'lightgrey',
        marginTop: '2px',
        alignItems: 'center',
        width: '100%',
        gap: isMobile ? '2px' : '6px',
        ...(isMobile && {
          padding: '5px 5px 10px 5px'
        })
      }}
    >
      {rubro.urlPortada && (
        <CardMedia
          component="img"
          sx={{
            width: '80%',
            height: 'auto',
            objectFit: 'contain'
          }}
          image={rubro.urlPortada}
          alt=""
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          alignItems: 'center'
        }}
      >
        {rubro.urlImagen && (
          <CardMedia
            component="img"
            sx={{
              width: isMobile ? '140px' : '150px',
              height: isMobile ? '190px' : '200px',
              objectFit: 'contain'
            }}
            image={rubro.urlImagen}
            alt=""
          />
        )}

        {rubro.urlFoto && (
          <CardMedia
            component="img"
            sx={{
              width: isMobile ? '140px' : '150px',
              height: isMobile ? '190px' : '200px',
              objectFit: 'contain'
            }}
            image={rubro.urlFoto}
            alt=""
          />
        )}
      </Box>
      <Box
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
          fontSize: 16,
          fontFamily: 'Monospace',
          textAlign: 'left',
          marginTop: '16px',
          marginLeft: '10px'
        }}
      >
        <div key={rubro.codRubro}>
          {rubro.color && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >
              <>
                {`Color: `}
                <span
                  style={{
                    display: 'inline-block',
                    width: '16px',
                    height: '16px',
                    border: `1px solid black`,
                    backgroundColor: `#${rubro.color}` || '#000000',
                    marginLeft: '3px'
                  }}
                ></span>
              </>
            </Typography>
          )}
          {rubro.nombreCompleto && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >
              {`Nombre Completo: ${rubro.nombreCompleto}`}
            </Typography>
          )}
          {rubro.monedaList?.length > 0 && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Moneda/s: ${rubro.monedaList
              .map((moneda: any) => `${moneda.nombre} (${moneda.simbolo})`)
              .join(', ')}`}</Typography>
          )}
          {rubro.productoAtributoList?.length > 0 && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Atributos: ${rubro.productoAtributoList
              .map((element: any) => `${element.nombreSingular}/${element.nombrePlural}`)
              .join(', ')}`}</Typography>
          )}
          {rubro.unidadDeMedidaList?.length > 0 && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Unidad/es de Medida: ${rubro.unidadDeMedidaList
              .map((element: any) => `${element.nombreSingular}/${element.nombrePlural}`)
              .join(', ')}`}</Typography>
          )}
          {rubro.precioMinimo > 0 && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Precio Mínimo: ${rubro.precioMinimo}`}</Typography>
          )}
          {rubro.slug && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Slug: ${rubro.slug}`}</Typography>
          )}
          {rubro.keywords && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Keywords: ${rubro.keywords}`}</Typography>
          )}
          {rubro.orden && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                paddingBottom: '16px'
              }}
            >{`Orden: ${rubro.orden}`}</Typography>
          )}
        </div>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          flexWrap: 'wrap',
          gap: '16px',
          ...(!isMobile && {
            alignItems: 'flex-end',
            ml: 'auto' // Empuja los botones hacia la derecha
          })
        }}
      >
        {/* Botón "Editar" */}
        <Button
          size={isMobile ? 'small' : 'medium'}
          variant="contained"
          color="primary"
          onClick={() => setModalUpdatedRubro(true)}
        >
          Editar
        </Button>

        {/* Botón "Borrar" */}
        <Button
          size={isMobile ? 'small' : 'medium'}
          variant="contained"
          color="error"
          onClick={() => handleDeleteRubro(rubro.codRubro)}
        >
          Borrar
        </Button>
      </Box>
      <EditRubro
        open={modalUpdatedRubro}
        setOpen={setModalUpdatedRubro}
        codRubro={rubro.codRubro}
        updateRubros={updateRubros}
        handleNodeSelect={handleNodeSelect}
      />
      <ConfirmDialog {...confirmDialog} />
    </Card>
  )
}

export default memo(CardRubros)
