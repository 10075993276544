import React, { memo, useContext, useState } from 'react'
import '../../styles/contractsRenovations.scss'
import * as GrIcons from 'react-icons/gr'
import { PagosDTO } from '../../hooks/admin.local.contracts.hook'
import { Button, Dialog } from '@mui/material'
import TableDataPagination, { INITIAL_ROWS_PER_PAGE } from '../../pages/Products/components/TableDataPagination'
import PopupHistorialPagos from './PopupHistorialPagos'
import { DataContratosContext } from './context/data.contratos.context'
import { CheckCircleOutline } from '@mui/icons-material'
import { format } from 'date-fns'

export interface PropsDataContratos {
  show: boolean
  handleClose: any
  headers?: string[]
  title: string
}

const ModalTablePagos: React.FC<PropsDataContratos> = ({ show, handleClose, headers, title }: PropsDataContratos) => {
  const [openHistorial, setOpenHistorial] = useState<boolean>(false)

  const showHideClassName = show ? 'modal display-block' : 'modal display-none'
  const { paginatorPagos, setPagePagos, setPageSizePagos, pagePagos, pageSizePagos, selectedPagos } =
    useContext(DataContratosContext)

  paginatorPagos.handleChangePage = (e: any, newPage: number) => {
    setPagePagos(newPage)
  }

  paginatorPagos.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSizePagos(parseInt(event.target.value, 10))
    setPagePagos(0)
  }

  const closeRenovations = () => {
    handleClose()
  }

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main"
        style={{
          marginTop: '20px',
          overflowY: 'auto',
          maxHeight: '80vh',
          width: '90%'
        }}
      >
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">{title !== undefined && title}</h3>
          <button className="modal-close" onClick={closeRenovations} aria-label="Cerrar">
            <GrIcons.GrClose />
          </button>
        </header>
        <div style={{ paddingRight: '7.5vh' }}>
          <table className="table table-striped table-bordered premise-table">
            <thead className="table-active">
              <tr>
                {headers &&
                  headers.map((h: any, index) => {
                    return (
                      <th key={index}>
                        <button type="button">{h}</button>
                      </th>
                    )
                  })}
              </tr>
            </thead>
            {selectedPagos.items.length > 0 && (
              <tbody>
                {selectedPagos.items.map((item: PagosDTO, index) => {
                  const fecha = new Date(item.fechaPago)
                  return (
                    <>
                      <tr key={item.idContratoPago}>
                        <th>{`Pago: ${item.contratoPagoEstado}`}</th>
                        <th>
                          {`Pagado: ${item.monto}`}
                          {item.montoDevuelto > 0 && `\nDevuelto: ${item.montoDevuelto}`}
                          {item.saldoAplicado > 0 && `\nSaldo Aplicado: ${item.saldoAplicado}`}
                        </th>
                        <th>{item.pagoNroOperacion}</th>
                        <th>{format(fecha, 'dd/MM/yyyy HH:mm')}</th>
                        <th>
                          {item.mensajeResultado}
                          {item.nuevoContrato && <CheckCircleOutline style={{ marginLeft: '8px', color: 'green' }} />}
                        </th>
                        <th className="columWithButton">
                          <Button variant="outlined" size="small" onClick={() => setOpenHistorial(true)}>
                            Historial de cambios
                          </Button>
                        </th>
                        <Dialog open={openHistorial}>
                          <PopupHistorialPagos
                            idContrato={item.idContrato}
                            open={openHistorial}
                            setOpen={setOpenHistorial}
                            elements={item.historialCambios}
                          />
                        </Dialog>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            )}
            <tfoot>
              <tr>
                <TableDataPagination
                  total={selectedPagos.totalItems || 0}
                  page={pagePagos || 0}
                  paginator={paginatorPagos}
                  items={selectedPagos.items || []}
                  rowsPerPage={pageSizePagos || INITIAL_ROWS_PER_PAGE}
                  component={'td'}
                  label={`Pagos por página`}
                />
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>
  )
}

export default memo(ModalTablePagos)
