import { Box } from "@mui/material";
import MobileProduct from "./MobileProduct";
import { memo, useContext } from "react";
import { ProductListProps } from "../..";
import TableDataPagination from "../TableDataPagination";
import NoResults from "../../../../components/Common/NoResults";
import Loading from "../../../../components/Common/Loading";

interface MobileProductsListProps extends ProductListProps {
  loading: boolean;
  page: number;
  rowsPerPage: number;
  paginator: any;
}

const MobileProductsList = ({
  loading,
  total,
  page,
  paginator,
  rowsPerPage,
  products,
  handleDeleteProduct,
  handleEditProduct,
  handlePauseProduct,
  handleResumeProduct,
  handleDeleteProducts,
  handlePauseProducts,
  handleUnPauseProducts,
  handleChangeRubro,
  isAdmin,
}: MobileProductsListProps) => {
  if (products.length === 0) return <NoResults />;

  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: 5,
        marginBottom: 10,
      }}
    >
      <Box
        display="grid"
        sx={{
          gap: "20px",
          gridTemplateColumns: "repeat(12, 1fr)",
          "@media (max-width: 750px)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {products.map((product: any, i: number) => (
          <Box
            sx={{
              gridColumn: "span 6",
              "@media (max-width: 750px)": {
                gridColumn: "span 12",
              },
            }}
            key={i}
          >
            <MobileProduct
              key={i}
              product={product}
              isAdmin={isAdmin}
              handleResumeProduct={handleResumeProduct}
              handlePauseProduct={handlePauseProduct}
              handleDeleteProduct={handleDeleteProduct}
              handleEditProduct={handleEditProduct}
              handleUnPauseProducts={handleUnPauseProducts}
              handleDeleteProducts={handleDeleteProducts}
              handlePauseProducts={handlePauseProducts}
              handleChangeRubro={handleChangeRubro}
            />
          </Box>
        ))}
      </Box>
      <TableDataPagination
        total={total || 0}
        page={page}
        paginator={paginator}
        items={products}
        rowsPerPage={rowsPerPage}
        component={"div"}
        label="Productos por página"
      />
    </Box>
  );
};

export default memo(MobileProductsList);
