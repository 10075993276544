import { Box, Button, Container, Link, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { EstadoLinkType } from '../../types'
import { IntegracionesContext } from '../../context/integraciones.context'
import TiendaSeleccionada from '../TiendaSeleccionada'
import { Check } from '@mui/icons-material'
import { GetLinkAutorizacion } from '../../services/onboarding.service'
import { useAppStore } from '../../../../store/app-store'

const Onboarding = () => {
  const { tiendaSeleccionada } = useContext(IntegracionesContext)
  const [link, setLink] = useState('')
  const [linkEstado, setLinkEstado] = useState<EstadoLinkType | null>(null)
  const [linkGenerado, setLinkGenerado] = useState('')
  const [error, setError] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const idLocal = useAppStore((state) => state.idLocal)

  const handleVerificar = useCallback(async () => {
    if (!tiendaSeleccionada) return null
    setLinkEstado('validando')
    try {
      const linkAutorizacion = await GetLinkAutorizacion(tiendaSeleccionada.name, idLocal, link)

      setLinkGenerado(linkAutorizacion.data.data)

      setError('')
      setLinkEstado('validado')
    } catch (error) {
      setLinkEstado('error')
    }
  }, [link, idLocal, tiendaSeleccionada])

  if (!tiendaSeleccionada) return null

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap="20px"
        p="20px"
        mt="20px"
        component={Paper}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row-reverse'}
          alignItems="center"
          justifyContent="space-between"
          gap="20px"
          width="100%"
        >
          <TiendaSeleccionada />
          <Typography variant="h5">Tu {tiendaSeleccionada.name}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '20px',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '20px',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '10px',
                width: '100%'
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                {'1) Ingresá la dirección de tu página web:'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  width: '100%'
                }}
              >
                <TextField
                  type="url"
                  placeholder="https://www.mitienda.com.ar"
                  variant="outlined"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  fullWidth
                  disabled={linkEstado === 'validando'}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleVerificar}
                  disabled={linkEstado === 'validando'}
                >
                  {linkEstado === 'validando' ? 'Verificando' : 'Verificar'}
                </Button>
                {linkEstado === 'validado' && <Check sx={{ color: '#72c023' }} />}
              </Box>
              {(error || linkEstado === 'error') && (
                <Typography variant="body1" color="#b80000">
                  {error || 'Error al verificar el link'}
                </Typography>
              )}
            </Box>

            {linkEstado === 'validado' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <Typography variant="body1" fontWeight={600}>
                  {`2) Ingresá a esta dirección web para permitirle a Avellaneda a un Toque que acceda a ${tiendaSeleccionada.name}:`}
                </Typography>
                {linkGenerado && (
                  <Link href={linkGenerado} sx={{ cursor: 'pointer', width: 'fit-content' }}>
                    {linkGenerado}
                  </Link>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Onboarding
