import MenuItem from '@mui/material/MenuItem'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { axiosInstanceMain } from '../../utils/axiosGlobalUtils'
import ButtonDesplegable from './ButtonDesplegable'

export default function ButtonCache() {
  const borrarCache = async () => {
    SnackBarUtils.info('Borrando cache, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/clear-cache')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success('Cache borrada')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const borrarCacheConfig = async () => {
    SnackBarUtils.info('Borrando cache, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/clear-cache-config')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success('Cache de config borrada')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const generarCacheLocales = async () => {
    SnackBarUtils.info('Generando cache de locales, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/build-cache-locales')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success(result.data?.data || 'Cache de locales generada')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const generarCacheProductos = async () => {
    SnackBarUtils.info('Generando cache de productos, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/build-cache-productos')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success(
          result.data?.data || 'Cache de productos generada'
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const borrarCacheGalerias = async () => {
    SnackBarUtils.info('Borrando cache, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/clear-cache-galerias')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success('Cache de galerías borrada')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const borrarCacheAnuncios = async () => {
    SnackBarUtils.info('Borrando cache, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/clear-cache-anuncios')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success('Cache de anuncios borrada')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const borrarCacheRubros = async () => {
    SnackBarUtils.info('Borrando cache, puede tardar un rato...')
    await axiosInstanceMain
      .post('/admin/cache/clear-cache-rubros')
      .then((result) => {
        console.log(result)
        SnackBarUtils.success('Cache de rubros borrada')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <ButtonDesplegable label={'Cache'} color="secondary">
      <MenuItem onClick={borrarCache}>Borrar caché</MenuItem>
      <MenuItem onClick={borrarCacheConfig}>Borrar caché config</MenuItem>
      <MenuItem onClick={borrarCacheGalerias}>Borrar caché galerías</MenuItem>
      <MenuItem onClick={borrarCacheAnuncios}>Borrar caché anuncios</MenuItem>
      <MenuItem onClick={borrarCacheRubros}>Borrar caché rubros</MenuItem>
      <MenuItem onClick={generarCacheLocales}>Generar caché locales</MenuItem>
      <MenuItem onClick={generarCacheProductos}>
        Generar caché productos
      </MenuItem>
    </ButtonDesplegable>
  )
}
