import React, { useEffect, useState } from 'react'
import { axiosInstanceContratos } from '../../utils/axiosGlobalUtils'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import { ContractDTO } from '../../dto/ContractDTO'
import { useSnackbar } from 'notistack'
import { Button, useTheme, useMediaQuery, IconButton, styled } from '@mui/material'
import SnackBarUtils from '../../utils/SnackBarUtils'

const ContentEraseButton = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
  [theme.breakpoints.down(425)]: {
    width: '30%'
  }
}))

const ObservacionesColumn = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  width: '100%'
})

const LineObservaciones = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between'
})

const CustomEraseButton = styled('button')({
  backgroundColor: 'white',
  border: '1px solid black',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35%'
})

const Text = styled('p')({
  marginBottom: '0.15rem',
  fontSize: '4vw'
})

const Observaciones: React.FC<any> = (props: ContractDTO) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [item, setItem] = useState<ContractDTO>()
  const [observaciones, setObservaciones] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (props && props.props) {
      setItem(props.props)
      if (props.props.observaciones && props.props.observaciones !== '') {
        setObservaciones(props.props.observaciones)
      }
    }
  }, [props])

  const changeObservations = (idContrato: number, observations: string) => {
    axiosInstanceContratos
      .post(`/contratos/${idContrato}/observaciones`, {
        observaciones: observations
      })
      .catch((error) => {
        console.log(error)
        SnackBarUtils.error(error.response.data)
      })
  }

  const eraseObservations = (idContrato: number) => {
    enqueueSnackbar('¿Deseas eliminar este local?', {
      variant: 'warning',
      persist: true,
      action: (key) => (
        <>
          <Button
            variant="text"
            color="error"
            onClick={() => {
              axiosInstanceContratos
                .delete(`/contratos/${idContrato}/observaciones`)
                .then((result) => {
                  console.log(result)
                  setObservaciones('')
                  closeSnackbar(key)
                })
                .catch((error) => {
                  console.log(error)
                })
            }}
          >
            Eliminar
          </Button>
          <Button variant="text" color="primary">
            <span onClick={() => closeSnackbar(key)}>Cancelar</span>
          </Button>
        </>
      )
    })
    // let confirmarErase = window.confirm(
    //   "¿Desea borrar las observaciones del contrato Id " + idContrato + "?"
    // );
    // if (confirmarErase) {
    //   setObservaciones("");
    //   axiosInstanceMain
    //     .post(`/contratos/${idContrato}/observaciones`, " ", {
    //       headers: { "Content-Type": "text/plain;charset=UTF-8" },
    //     })
    //     .then(() => {})
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  }

  return (
    <>
      {!isMobile ? (
        <tr>
          <th>Observaciones</th>
          <th colSpan={7}>
            <div style={{ position: 'relative' }}>
              <textarea
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
                className="form-control"
                style={{ color: '#53292a', paddingRight: '30px' }}
                rows={3}
                onBlur={(e) => {
                  if (item) changeObservations(item.idContrato, e.target.value)
                }}
              ></textarea>
              <IconButton
                size="small"
                color="inherit"
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px'
                }}
                onClick={() => {
                  setObservaciones((prevObservaciones) => {
                    if (item) {
                      changeObservations(item.idContrato, prevObservaciones)
                    }
                    return prevObservaciones
                  })
                }}
              >
                <CheckIcon />
              </IconButton>
            </div>
          </th>
        </tr>
      ) : (
        <ObservacionesColumn>
          <LineObservaciones>
            <Text>Observaciones:</Text>
          </LineObservaciones>
          <div style={{ position: 'relative', width: '100%' }}>
            <textarea
              value={observaciones}
              onChange={(e) => setObservaciones(e.target.value)}
              className="form-control"
              style={{ color: '#212529', paddingRight: '30px' }}
              rows={1}
              // onKeyUp={(e) => {
              //   if (e.key == "Enter") {
              //     e.currentTarget.blur();
              //   }
              // }}
            ></textarea>
            <IconButton
              size="small"
              color="inherit"
              type="submit"
              style={{
                position: 'absolute',
                top: '2px',
                right: '2px'
              }}
              onClick={() => {
                setObservaciones((prevObservaciones) => {
                  if (item) {
                    changeObservations(item.idContrato, prevObservaciones)
                  }
                  return prevObservaciones
                })
              }}
            >
              <CheckIcon />
            </IconButton>
          </div>
        </ObservacionesColumn>
      )}
    </>
  )
}

export default Observaciones
