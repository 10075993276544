import './Tabs.css'
import { Root } from '@radix-ui/react-tabs'
import { useState } from 'react'
import { MenuTabs } from '../../types'
import IntegracionesEstado from './Estado/IntegracionesEstado'
import IntegracionesProducts from './Prouctos/IntegracionesProducts'
import IntegracionesNovedades from './Novedades/IntegracionesNovedades'
import { Box } from '@mui/material'
import MenuTabsList from './MenuTabsList'

const IntegracionesMain = () => {
  const [value, setValue] = useState(MenuTabs.Productos)

  const handleChange = (newValue: MenuTabs) => setValue(newValue)

  return (
    <div className="tab-container">
      <Root value={value} onValueChange={handleChange}>
        <MenuTabsList />
        <Box
          paddingY="23px"
          paddingX="28px"
          borderRadius={`
            ${
              value === MenuTabs.Estado
                ? '0 20px 20px 20px'
                : value === MenuTabs.Productos
                ? '20px'
                : value === MenuTabs.Novedades
                ? '20px 0 20px 20px'
                : '0 0 0 0'
            }
          `}
          bgcolor="white"
          border="1px solid #D9D9D9"
          marginTop={'-1px'}
          zIndex={5}
        >
          <Box
            display="flex"
            width="100%"
            border="1px solid #d9d9d9"
            bgcolor="#F5F5F7"
            paddingY="23px"
            paddingX="33px"
            borderRadius="16px"
          >
            <IntegracionesEstado />
            <IntegracionesProducts />
            <IntegracionesNovedades />
          </Box>
        </Box>
      </Root>
    </div>
  )
}

export default IntegracionesMain
