import React, { useEffect, useState } from "react";
import "../../styles/contractsRenovations.scss";
import { axiosInstanceContratos } from "../../utils/axiosGlobalUtils";
import * as GrIcons from "react-icons/gr";
import { styled } from "@mui/material";

const ContentTable = styled("div")({
  width: "97%",
});

// const Line = styled.div`
//   width: 97%;
//   border: 1px solid;
//   display: flex;
//   gap: 6px;
//   margin-top: 8px;
//   border-radius: 5px;
// `;

// const Celda = styled.div`
//   /* width: 97%; */
//   /* border: 1px solid; */
// `;

// interface UpIconParams {
//   className?: string;
//   color?: string;
// }

interface Props {
  getUrl?: string;
  handleClose?: any;
  headers?: string[];
  show?: string;
  title?: string;
  codLocal: string;
  result: any[];
}

const ModalTable: React.FC<any> = (props: Props) => {
  const [result, setResult] = useState<any[]>([]);

  const showHideClassName = props.show ? "modal display-block" : "modal display-none";

  const closeRenovations = () => {
    // setActions([]);
    props.handleClose();
  };

  // const executeAction = (url: string) => {
  //   const idContrato = url
  //     .substring(url.indexOf("contratos"), url.length)
  //     .split("/")[1];
  //   axiosInstanceMain
  //     .get(url)
  //     .then((result) => {
  //       window.alert(result.data);
  //       if (result.status === 200) {
  //         props.setNoShowContracts([
  //           ...props.noShowContracts,
  //           Number.parseInt(idContrato),
  //         ]);
  //       }
  //       closeRenovations();
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  return (
    <div
      className={showHideClassName}
      // onClick={closeRenovations}
    >
      <section className="modal-main" style={{ marginTop: "40px", height: "700px", overflow: "auto" }}>
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">
            {/* {(props.contract?.cantidad > 1
              ? props.contract?.productoNombre + "x" + props.contract?.cantidad
              : props.contract?.productoNombre) +
              ` Local ID ${props.contract?.idContratoClienteLocal}`} */}
            {props.title !== undefined && props.title}
          </h3>
          <button className="modal-close" onClick={closeRenovations} aria-label="Cerrar">
            <GrIcons.GrClose />
          </button>
        </header>
        <div>
          {/* {isMobile ? (
            <div className="btn-toolbar" role="toolbar">
              {result.map((a: any) => {
                return (
                  <Line>
                    {props.headers.map((h: any, index: number) => {
                      return (
                        <Celda>
                          <p>
                            {h}: {a[props.attributesToShow[index]]}
                          </p>
                        </Celda>
                      );
                    })}
                  </Line>
                );
              })}
            </div>
          ) : ( */}
          <ContentTable>
            <table className="table table-striped table-bordered premise-table">
              <thead className="table-active">
                <tr>
                  {props.headers &&
                    props.headers.map((h: any, index) => {
                      return (
                        <th key={index}>
                          <button
                            type="button"
                            // onClick={() => requestSort("idContrato")}
                            // className={getClassNamesFor("idContrato")}
                          >
                            {h}
                          </button>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {props.result?.map((item: any, index) => {
                  const fecha = new Date(item.fecha);
                  return (
                    <tr key={item.idContratoCuentaCorriente}>
                      <th>{item.idContrato}</th>
                      <th>{fecha.toLocaleDateString()}</th>
                      <th>{item.productoNombre}</th>
                      <th>{item.movimientoTipo + " " + item.movimiento}</th>
                      <th>{item.medioDePago}</th>
                      <th>{item.comisionMedioDePago}</th>
                      <th>{item.observaciones != null ? item.observaciones : "-"}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ContentTable>
          {/* )} */}
        </div>
      </section>
    </div>
  );
};

export default ModalTable;
