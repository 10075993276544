import { useContext, useEffect } from "react";
import { useState } from "react";

import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { TenantsContext } from "../../../context/tenants.context";

export const useDataABMRubros = () => {
  const [unidadesDeMedidaDisponibles, setUnidadesDeMedidaDisponibles] = useState<any[]>([]);
  const [atributos, setAtributos] = useState<any[]>([]);
  const [monedas, setMonedas] = useState<any[]>([]);
  const { tenant } = useContext(TenantsContext);

  const getData = async (endpoint: string) => {
    const response = await axiosInstanceMain.get(endpoint);
    const datos = response.data.data;
    return datos;
  };

  useEffect(() => {
    (async () => {
      try {
        const unidadesDeMedidaDisponibles = await getData(`admin/${tenant.path}/productos/unidades-de-medida`);
        const atributos = await getData(`admin/${tenant.path}/productos/atributos`);
        const monedas = await getData(`admin/${tenant.path}/monedas`);
        setUnidadesDeMedidaDisponibles(unidadesDeMedidaDisponibles);
        setAtributos(atributos);
        setMonedas(monedas);
      } catch (e) {
        console.error("Error: ", e);
      }
    })();
  }, []);

  return {
    unidadesDeMedidaDisponibles,
    atributos,
    monedas,
  };
};
