import { useEffect, useContext } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import ConversacionHeader from './ConversacionHeader'
import { WhatsappContext } from '../../context/whatsapp.context'
import ConversacionList from './ConversacionList'
import ConversacionSendMessage from './ConversacionSendMessage'

const Conversacion = () => {
  const { getConversacion, contactoSeleccionado, loadingConversacion } =
    useContext(WhatsappContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (contactoSeleccionado.id) getConversacion()
  }, [contactoSeleccionado])

  return (
    <Box>
      <ConversacionHeader />
      <ConversacionList />
      <Box
        sx={
          isMobile
            ? {
                position: 'absolute',
                bottom: 0,
                width: '100%'
              }
            : {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }
        }
      >
        {contactoSeleccionado.id !== 0 && !loadingConversacion && (
          <ConversacionSendMessage />
        )}
      </Box>
    </Box>
  )
}

export default Conversacion
