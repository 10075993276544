import React, { useContext, useEffect, useState } from 'react'
import '../../styles/sortableData.scss'
import { useSortableData } from '../../utils/UseSorteableDataHook'
import PremiseContactDTO from '../../dto/PremiseContactDTO'
import { axiosInstanceMain } from '../../utils/axiosGlobalUtils'
import { useNavigate } from 'react-router'
import { WhatsappContext, initialStateContacto } from '../../pages/WhatsappBot/context/whatsapp.context'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { TenantsContext } from '../../context/tenants.context'

const ContentTable = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  width: isMobile ? '100%' : '97%'
}))

const ContentCell = styled('div')({
  marginBottom: 7
})

const AdminLocalContact: React.FC<any> = (props: any) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  //const { goToContact } = useContext(WhatsappContext);
  const navigate = useNavigate()
  const [contacts, setContacts] = useState<PremiseContactDTO[]>(props.premise?.medioDeContactoList)
  const [contactsTypes, setContactsTypes] = useState<any[]>()
  const { items, requestSort, sortConfig } = useSortableData(contacts ? contacts : [])
  const { tenant } = useContext(TenantsContext)
  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return
    }
    return sortConfig['key'] === name ? sortConfig['direction'] : undefined
  }

  useEffect(() => {
    axiosInstanceMain
      .get(`/admin/${tenant.path}/medios-de-contacto-tipo`)
      .then((result) => {
        setContacts(props.premise?.medioDeContactoList)
        setContactsTypes(result.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [props.premise])

  const handleRevalidar = (whpp: string) => {
    const msj =
      'Hola! Me comunico desde el equipo de la aplicación Avellaneda Un Toque.\n\nHemos recibido reportes de compradores avisando que han visitado la dirección cargada en la app y no estaban, ¿Han dejado su local?\n\nAguardamos su respuesta.'
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whpp}&text=${encodeURIComponent(msj)}`
    window.open(whatsappUrl, '_blank')
  }

  return (
    <>
      <ContentTable isMobile={isMobile}>
        <table className="table table-striped table-bordered">
          <thead className="table-active">
            <tr>
              <th
                style={{
                  ...(isMobile && { fontSize: '14px' })
                }}
              >
                <button type="button" onClick={() => requestSort('type')} className={getClassNamesFor('type')}>
                  Tipo
                </button>
              </th>
              <th
                style={{
                  ...(isMobile && { fontSize: '14px' })
                }}
              >
                Valor
              </th>
              <th
                style={{
                  ...(isMobile && { fontSize: '14px' })
                }}
              >
                Descripcion
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: PremiseContactDTO, index) => {
              return (
                <tr key={index}>
                  <th style={{ ...(isMobile && { fontSize: '14px' }) }}>
                    <ContentCell>{contactsTypes?.find((t) => t.id === item.tipo).nombre}</ContentCell>
                  </th>
                  <th style={{ ...(isMobile && { fontSize: '14px' }) }}>
                    {item.tipo === 1 ? (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px'
                        }}
                      >
                        <a href={`https://wa.me/${item.prefijo}${item.valor}`}>
                          {item.valor && `+${item.prefijo} ${item.valor}`}
                        </a>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            //goToContact(item.valor);
                            navigate(`/admin-wsp-bot?contact=${item.prefijo}${item.valor}`)
                          }}
                          style={{ fontSize: isMobile ? 'small' : 'medium' }}
                        >
                          Bot
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            handleRevalidar(`${item.prefijo}${item.valor}`)
                          }}
                          style={{ fontSize: isMobile ? 'small' : 'medium' }}
                        >
                          Revalidar
                        </button>
                      </span>
                    ) : item.tipo === 3 ? (
                      <a href={`mailto: ${item.valor}`}>{item.valor}</a>
                    ) : (
                      `+${item.prefijo} ${item.valor}`
                    )}
                  </th>
                  <th style={{ ...(isMobile && { fontSize: '14px' }) }}>{item.descripcion}</th>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ContentTable>
    </>
  )
}

export default AdminLocalContact
