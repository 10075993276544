import React, { memo, useState, useEffect, useRef } from "react";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import setRedirectUrl from "../../../redux/actions/redirection/setRedirectUrl";
import { connect } from "react-redux";

const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [footerPosition, setFooterPosition] = useState<"fixed" | "relative">("relative");

  useEffect(() => {
    const updateFooterPosition = () => {
      const windowHeight = window.outerHeight;
      const contentHeight = document.documentElement.scrollHeight;
      setFooterPosition(contentHeight < windowHeight ? "fixed" : "relative");
    };

    updateFooterPosition();

    window.addEventListener("resize", updateFooterPosition);

    const observer = new MutationObserver(updateFooterPosition);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => {
      window.removeEventListener("resize", updateFooterPosition);
      observer.disconnect();
    };
  }, []);

  if (isMobile) return null;

  return (
    <div
      style={{
        position: footerPosition,
        bottom: 0,
        width: "100%",
        background: "#2869A5",
        paddingTop: "10px",
        paddingBottom: "10px",
        height: "40px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Plus Jakarta Sans",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "18px",
          letterSpacing: "0.15px",
          textAlign: "center",
          color: "#FFFFFF",
        }}
      >
        Todos los derechos reservados Avellaneda a un Toque 2024
      </Typography>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {
  setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Footer));
