import React, { useCallback } from 'react'
import { getColor } from '../MobileProduct'
import { Box, Typography } from '@mui/material'
import ExpandMore from '../../../../../components/Common/ExpandMore'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Precio from '../../Elements/Precio'

interface CardHeaderProps {
  product: any
  setExpanded: (expanded: boolean) => void
  expanded: boolean
}

const CardHeader = ({product, expanded, setExpanded}: CardHeaderProps) => {
  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded, setExpanded])

  return (
    <Box
      sx={{
        backgroundColor: getColor(product),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        padding: '12px 16px',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {product.nombre}
      </Typography>
      <Box sx={{display: 'flex', alignSelf: 'flex-end', alignItems: 'center'}}>
        <Precio product={product} />
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <ExpandMoreOutlined />
        </ExpandMore>
      </Box>
    </Box>
  )
}

export default CardHeader
