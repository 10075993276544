export const msjAyerAntesPremium =
  'Hola! Me comunico de la aplicación *Avellaneda a un Toque* 🙋\n\n{{cuando}} venció tu suscripción *{{nombre producto}} ${{precio}}* 😀\n\n✅Le dejo el link de Mercado Pago para abonar la renovación:\n\n{{link}}\n\n✅También puede abonar por transferencia:\n\nCBU: 0000003100070729251825\n\nAlias: av.a.un.toque\n\nMercados a un Toque SRL\n\n ---------------------------------------\n\n*Por favor envíe el comprobante de pago luego de haber abonado.*\n\n⚠️En caso de no querer continuar con los beneficios de la Suscripción Premium es necesario que nos avise para que pueda continuar con una Cuenta Básica, *¡El sistema no seguirá mostrando sus publicaciones hasta que responda nuestro mensaje!* 👇🏻\n\n⚠️ Por favor responder. ¡Muchas gracias!'

export const msjHoyMañanaPremium =
  'Hola! Me comunico de la aplicación *Avellaneda a un Toque* 🙋\n\n{{cuando}} vence tu suscripción *{{nombre producto}} ${{precio}}* 😀\n\n✅Le dejo el link de Mercado Pago para abonar la renovación:\n\n{{link}}\n\n✅También puede abonar por transferencia:\n\nCBU: 0000003100070729251825\n\nAlias: av.a.un.toque\n\nMercados a un Toque SRL\n\n ---------------------------------------\n\n*Por favor envíe el comprobante de pago luego de haber abonado.*\n\n⚠️En caso de no querer continuar con los beneficios de la Suscripción Premium es necesario que nos avise para que pueda continuar con una Cuenta Básica, *¡El sistema no seguirá mostrando sus publicaciones hasta que responda nuestro mensaje!* 👇🏻\n\n⚠️ Por favor responder. ¡Muchas gracias!'

export const msjMañana =
  'Hola! Me comunico de la aplicación *Avellaneda a un Toque*🙋\n\n{{cuando}} vence tu suscripción *{{nombre producto}} ${{precio}}* 😀\n\n✅Le dejo el link de Mercado Pago para abonar la renovación:\n\n{{link}}\n\n✅También puede abonar por transferencia:\n\nCBU: 0000003100070729251825\n\nAlias: av.a.un.toque\n\nMercados a un Toque SRL\n\n---------------------------------------\n\n*Por favor envíe el comprobante de pago luego de haber abonado.*\n\n⚠️ Por favor responder. ¡Muchas gracias!'

export const msjAyerAntes =
  'Hola! Me comunico de la aplicación *Avellaneda a un Toque*🙋\n\n{{cuando}} venció tu suscripción *{{nombre producto}} ${{precio}}* 😀\n\n✅Le dejo el link de Mercado Pago para abonar la renovación:\n\n{{link}}\n\n✅También puede abonar por transferencia:\n\nCBU: 0000003100070729251825\n\nAlias: av.a.un.toque\n\nMercados a un Toque SRL\n\n---------------------------------------\n\n*Por favor envíe el comprobante de pago luego de haber abonado.*\n\n⚠️ Por favor responder. ¡Muchas gracias!'

export const msjPrecioActualizado =
  'Hola! Me comunico de la aplicación *Avellaneda a un Toque*🙋\n\n{{cuando}} vence tu suscripción *{{nombre producto}}* 😀\n\nEn los últimos 6 meses hemos venido manteniendo los precios para ayudar a que la actividad crezca en esta situación complicada, y para seguir ofreciendo un servicio de excelencia y atraer cada vez más compradores, necesitamos hacer una actualización. Por ese motivo el precio actualizado del servicio que tenés contratado será de *${{precio}}*.\n\n✅Le dejo el link de Mercado Pago para abonar la renovación:\n\n{{link}}\n✅También puede abonar por transferencia:\n\nCBU: 0000003100070729251825\nAlias: av.a.un.toque\nMercados a un Toque SRL\n\nPor favor envíe el comprobante de pago luego de haber abonado.\n\n⚠️ Por favor responder. ¡Muchas gracias!'

export const msjPrecioActualizadoPremium =
  'Hola! Me comunico de la aplicación *Avellaneda a un Toque*🙋\n\n{{cuando}} vence tu suscripción *{{nombre producto}}* 😀\n\nEn los últimos 6 meses hemos venido manteniendo los precios para ayudar a que la actividad crezca en esta situación complicada, y para seguir ofreciendo un servicio de excelencia y atraer cada vez más compradores, necesitamos hacer una actualización. Por ese motivo el precio actualizado del servicio que tenés contratado será de *${{precio}}*.\n\n✅Le dejo el link de Mercado Pago para abonar la renovación:\n\n{{link}}\n✅También puede abonar por transferencia:\n\nCBU: 0000003100070729251825\nAlias: av.a.un.toque\nMercados a un Toque SRL\n\nPor favor envíe el comprobante de pago luego de haber abonado.\n\n⚠️En caso de no querer continuar con los beneficios de la Suscripción Premium es necesario que nos avise para que pueda continuar con una Cuenta Básica, *¡El sistema no seguirá mostrando sus publicaciones hasta que responda nuestro mensaje!* 👇🏻\n\n⚠️ Por favor responder. ¡Muchas gracias!'
