import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import Navbar from '../../components/Navbar'
import { useTheme, useMediaQuery } from '@mui/material'
import { GaleriasContext } from './context/galerias.context'
import GaleriasDesktop from './components/desktop/tablaGalerias'
import FiltrosGalerias from './services/filtrosGalerias'
import EditGaleria from './widgets/editGaleria'
import SnackBarUtils from '../../utils/SnackBarUtils'
import ConfirmDialog from '../Products/components/ConfirmDialog'
import { deleteGaleria } from './services/galerias.services'
import GaleriasMobile from './components/mobile/cardsGalerias'
import Footer from '../Home/components/Footer'
import { styled } from '@mui/material/styles'
import { TenantsContext } from '../../context/tenants.context'

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

const AbmGalerias: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { fetchData, setLoading } = useContext(GaleriasContext)
  const [editGaleria, setEditGaleria] = useState<boolean>(false)
  const [eliminaGaleria, setEliminaGaleria] = useState<boolean>(false)
  const [selectedGaleria, setSelectedGaleria] = useState<string | null>(null)
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    text: {
      title: '',
      body: '',
      leftButton: 'Cancelar',
      rightButton: 'Confirmar',
      colorButtonConfirm: 'primary'
    },
    action: async () => {}
  })
  const { tenant } = useContext(TenantsContext)

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
    setEditGaleria(false)
    fetchData()
    setEliminaGaleria(false)
  }
  const handleEliminarGaleria = () => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Desea eliminar esta Galería? Esta acción no se puede deshacer',
        body: '',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar',
        colorButtonConfirm: 'primary'
      },
      action: async () => {
        try {
          setLoading(true)
          eliminaGaleria && (await deleteGaleria(selectedGaleria, tenant))
          SnackBarUtils.success('Galería eliminada correctamente')
          clearConfirmDialog()
        } catch {
          console.log()
        }
        setLoading(false)
      },
      close: () => {
        clearConfirmDialog()
      }
    })
  }

  useEffect(() => {
    if (eliminaGaleria) {
      handleEliminarGaleria()
    }
  }, [eliminaGaleria])

  return (
    <>
      <Navbar title="Galerías" />
      <Header>
        <FiltrosGalerias />
      </Header>
      {isMobile ? (
        <GaleriasMobile
          setEditGaleria={setEditGaleria}
          setSelectedGaleria={setSelectedGaleria}
        />
      ) : (
        <GaleriasDesktop
          setEditGaleria={setEditGaleria}
          setSelectedGaleria={setSelectedGaleria}
        />
      )}
      <Footer />

      <EditGaleria
        open={editGaleria}
        setOpen={setEditGaleria}
        editGaleriaCode={selectedGaleria}
        setEliminaGaleria={setEliminaGaleria}
      />
      <ConfirmDialog {...confirmDialog} />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(AbmGalerias)
