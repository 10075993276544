import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import StickersPagination from "../paginado/paginadoStickers";

interface ListadoStickersProps {
  stickers: any[];
}

export const INITIAL_ROWS_PER_PAGE = 10;

const ListadoStickers: React.FC<ListadoStickersProps> = ({ stickers }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleStickers = stickers.slice(startIndex, endIndex);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Orden</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>NOMBRE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleStickers.map((sticker: any, i: number) => (
              <TableRow key={sticker.id}>
                <TableCell>{i+1}</TableCell>
                <TableCell>{sticker.id}</TableCell>
                <TableCell>{sticker.nombre}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StickersPagination
        component={"div"}
        stickers={stickers}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default ListadoStickers;
