import { SxProps, Theme } from "@mui/material";

export const GrayButtonSx = (theme: Theme): SxProps => {
  return {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
  };
};
