import React from 'react'
import { Card, Button, Box, Container } from '@mui/material'

type Props = {
  contratosList: any[]
  contactoLocal: any
}

const DisplayContractsMobile: React.FC<Props> = ({ contratosList, contactoLocal }) => {
  const openWhatsAppConversation = (action: string, nombreProducto: string) => {
    const text = `Hola, quiero ${action.toLowerCase()} mi suscripción ${nombreProducto}.`
    const url = `https://api.whatsapp.com/send?phone=${contactoLocal}&text=${encodeURIComponent(text)}`
    window.open(url, '_blank')
  }
  return (
    <>
      <Container>
        {contratosList.map((contrato: any) => (
          <Card
            key={contrato.idContrato}
            variant="outlined"
            sx={{
              px: { md: '3em', xs: '1em' },
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              margin: '20px 22px 22px',
              alignItems: 'flex-start'
            }}
            elevation={4}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '10px'
              }}
            >
              <Box
                sx={{
                  flexDirection: 'column',
                  flexGrow: 1,
                  fontSize: 16,
                  textAlign: 'left'
                }}
              >
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{contrato.productoNombre}</div>
              </Box>

              <div
                style={{
                  backgroundColor: `#${contrato.color}`,
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '3px 8px',
                  cursor: 'pointer',
                  fontSize: '16px'
                }}
              >
                {contrato.estadoNombre}
              </div>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '10px'
              }}
            >
              <Box
                sx={{
                  flexDirection: 'column',
                  flexGrow: 1,
                  fontSize: 16,
                  textAlign: 'left',
                  whiteSpace: 'nowrap'
                }}
              >
                <div style={{ fontSize: '16px' }}>Desde: {contrato.fechaInicioFormateada} </div>
                <div style={{ fontSize: '16px' }}>Hasta: {contrato.fechaFinFormateada} </div>
              </Box>
            </Box>
            {contrato.botonCTA && contactoLocal && (
              <Button
                variant="contained"
                color="success"
                fullWidth
                style={{
                  border: '1px solid grey',
                  color: 'white',
                  marginTop: '20px'
                }}
                onClick={() => openWhatsAppConversation(contrato.botonCTA, contrato.productoNombre)}
              >
                {contrato.botonCTA}
              </Button>
            )}
          </Card>
        ))}
      </Container>
    </>
  )
}

export default DisplayContractsMobile
