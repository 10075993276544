import { Box, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Columna, CsvEquivalencia, CsvTemplate, ISetEquivalencias } from './Importacion/types/importacion'
import { firstLetterUpperCase } from '../../utils/utils'
import { getDefaultValue } from './Importacion/utils/helpers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useResponsive } from '../../hooks/useResponsive.hook'
import { Atributo, SaveAtributos } from './types'
import { CustomInput } from '../../components/Common/utils/input/CustomInput'

interface PropsEquivalencias extends ISetEquivalencias {
  csv: CsvTemplate
  columnas: Columna[]
  disabled?: boolean
  csvEquivalencias?: CsvEquivalencia[]
  setCsvEquivalencias?: (csvEquivalencias: CsvEquivalencia[]) => void
  conEjemplo?: boolean
}

export const ComponenteEquivalencias = ({
  disabled,
  csv,
  columnas,
  equivalencias,
  setEquivalencias,
  csvEquivalencias,
  setCsvEquivalencias,
  conEjemplo = true
}: PropsEquivalencias) => {
  const [ejemplo, setEjemplo] = useState('')
  const { isMobile } = useResponsive()

  const isValid = useMemo(() => {
    const eq = equivalencias?.find((eq) => Object.keys(eq)[0] === csv.columna)
    if (eq) {
      return Object.values(eq)[0].value !== '' && !Object.values(eq)[0].no_importar
    }

    return true
  }, [equivalencias, csv])

  const handleChange = (e: any) => {
    const { value } = e.target

    const index = equivalencias.findIndex((eq) => Object.keys(eq)[0] === csv.columna)
    const newEquivalencias = [...equivalencias]
    newEquivalencias[index] = {
      [csv.columna]: {
        value,
        no_importar: false
      }
    }

    if (csvEquivalencias) {
      const csvEquivalenciasExists = csvEquivalencias.findIndex((eq) => eq.idTemplate === csv.id)

      if (csvEquivalenciasExists !== -1) {
        csvEquivalencias[csvEquivalenciasExists].nombreColumna = value
      } else {
        csvEquivalencias.push({ idTemplate: csv.id, nombreColumna: value })
      }

      if (setCsvEquivalencias) setCsvEquivalencias(csvEquivalencias)
    }
    setEquivalencias(newEquivalencias)
    setEjemplo(columnas.find((col) => col.nombre === value)?.valor || '')
  }

  useEffect(() => {
    setEjemplo(columnas.find((col) => col.nombre === getDefaultValue(csv, columnas)?.nombre)?.valor || '')
  }, [csv, columnas])

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <CustomInput
        select
        sx={{ width: isMobile ? '100%' : '400px' }}
        error={!isValid}
        variant="outlined"
        SelectProps={{ native: true }}
        onChange={handleChange}
        disabled={disabled}
        defaultValue={getDefaultValue(csv, columnas)?.nombre || ''}
      >
        <option value="">Seleccione</option>
        {columnas &&
          columnas.map((col, i) => (
            <option key={i} value={col.nombre}>
              {firstLetterUpperCase(col.nombre)}
            </option>
          ))}
      </CustomInput>
      {ejemplo && conEjemplo && (
        <Typography variant="subtitle2" color="GrayText">
          Ejemplo: {ejemplo}
        </Typography>
      )}
    </Box>
  )
}

interface PropsEquivalenciasAtributos {
  atributosTemplate?: Atributo[]
  columna: Columna
  equivalencias?: SaveAtributos[]
  setEquivalencias?: (equivalencias: SaveAtributos[]) => void
}

export const ComponenteEquivalenciasAtributos = ({
  atributosTemplate,
  columna,
  equivalencias,
  setEquivalencias
}: PropsEquivalenciasAtributos) => {
  const { isMobile } = useResponsive()
  const handleChange = useCallback(
    (e: any) => {
      if (!equivalencias || !setEquivalencias) return null

      const value = parseInt(e.target.value)
      const attr = equivalencias.find((eq) => eq.nombreAtributoExterno === columna.nombre)

      if (!attr) {
        setEquivalencias([...equivalencias, { idProductoAtributo: value, nombreAtributoExterno: columna.nombre }])
      } else {
        attr.idProductoAtributo = value
        setEquivalencias([...equivalencias])
      }
    },
    [equivalencias, setEquivalencias, columna]
  )

  return (
    <Select
      defaultValue={0}
      onChange={handleChange}
      variant="outlined"
      sx={isMobile ? { width: '100%' } : { width: '400px' }}
    >
      <MenuItem value={0} disabled>
        Seleccione
      </MenuItem>
      {atributosTemplate &&
        atributosTemplate
          .filter((attr) => attr.idProductoAtributo >= 1 && attr.idProductoAtributo <= 5)
          .map((attr, i) => (
            <MenuItem key={i} value={attr.idProductoAtributo}>
              {firstLetterUpperCase(attr.nombreAa1tPlural)}
            </MenuItem>
          ))}
    </Select>
  )
}
