import { IContrato } from '../../../dto/ContractDTO'
import { axiosInstanceContratos } from '../../../utils/axiosGlobalUtils'
import { hash } from '../../../utils/hashFunction'

export const crearContrato = async (contract: IContrato) =>
  await axiosInstanceContratos.post('/contratos', contract)

export const getContratosList = async (idLocal: any): Promise<any[]> => {
  const clave = await hash(idLocal.toString() + 'lugares2017')
  const contratosData = (
    await axiosInstanceContratos.get(
      `/contratos/local/${idLocal}?clave=` + clave
    )
  ).data

  return contratosData
}
