import { useCallback, useMemo, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchNavbar from "./Search";
import { connect } from "react-redux";
import logOut from "../../redux/actions/logged/logOut";
import Sidebar from "./Sidebar/";
import { themePalette } from "./themePalette";
import AlertDialog from "../Common/Dialog";
import { useNavigate } from "react-router";
import { useMediaQuery, useTheme } from "@mui/material";
import PedidosArchivados from "../../pages/Pedidos/components/pedidosArchivados";
import ToolbarHome from "../../pages/Home/components/Toolbar";
import { RolType } from "./Sidebar/SidebarItems";

const Navbar = (props: any) => {
  const { userLogged: user } = props.logged;
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isAdmin = props.logged.userLogged.roles.includes(RolType.ADMIN);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);
  const handleLogout = useCallback(() => {
    props.logOut();
    setIsLoggingOut(false);
    navigate("/login");
  }, [props, setIsLoggingOut]);

  const esAdmin = useMemo(
    () => user?.roles?.includes("admin") || user?.roles?.includes("operador"),
    [user]
  );

  if (!user) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <AppBar
        position="static"
        sx={{
          p: 1,
          bgcolor: themePalette.bgcolor,
        }}
      >
        <Toolbar>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleDrawerOpen}
            sx={{
              ":hover": {
                bgcolor: themePalette.defaultHover,
                color: themePalette.color,
              },
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>

          {((isMobile && !isSearching) || !isMobile || !isSearching) && (
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                ml: "20px",
                color: themePalette.color,
                marginTop: "7px",
              }}
            >
              {props.title || "MUI"}
            </Typography>
          )}

          {props.pedidosArchivados && !esAdmin && <PedidosArchivados />}

          {props.searchBar && esAdmin && (
            <SearchNavbar
              setIsSearching={setIsSearching}
              isSearching={isSearching}
            />
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Sidebar
        handleDrawerClose={handleDrawerClose}
        open={open}
        rol={user.roles && user.roles[0]}
        setIsLoggingOut={setIsLoggingOut}
      />

      <AlertDialog
        open={isLoggingOut}
        title="Cerrar Sesión"
        content="¿Estás seguro que querés cerrar sesión?"
        agreeColor="error"
        agreeText="Cerrar Sesión"
        disagreeText="Cancelar"
        onConfirm={handleLogout}
        onCancel={() => setIsLoggingOut(false)}
      />
      {!isAdmin && isMobile && <ToolbarHome isMobile={isMobile} />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
  };
};

const mapDispatchToProps = {
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
