import React, { useContext, useEffect, useState } from 'react'
import DictionaryItem from '../../dto/DictionaryDTO'
import { axiosInstanceMain } from '../../utils/axiosGlobalUtils'
import * as GrIcons from 'react-icons/gr'
import ModalCambioClave from './ModalCambioClave'
import { copyToClipboard } from '../../utils/utils'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import AlertDialog from '../Common/Dialog'
import Loading from '../Common/Loading'
import { TenantsContext } from '../../context/tenants.context'
import { styled } from '@mui/material'
import { getLocalesRelacionados } from './services/admin.local.services'
import ModalLocalesRelacionados from './ModalLocalesRelacionados'
import { ILocalesRelacionados } from '../../dto/LocalDTO'

const Identity = styled('div')(({ theme }) => ({
  fontSize: 'calc(15px + 1vmin)',
  margin: '0 1rem',
  fontFamily: theme.typography.fontFamily
}))

const SecondLine = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0px 1rem',
  [theme.breakpoints.down(425)]: {
    flexWrap: 'wrap'
  }
}))

const Info = styled('div')(({ theme }) => ({
  fontSize: 'calc(15px + 1vmin)',
  fontFamily: theme.typography.fontFamily
}))

const ThirdLine = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '1rem 1rem',
  gap: '10px',
  flexWrap: 'wrap'
})

const ContentThirdLine = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down(425)]: {
    flexWrap: 'wrap'
  }
}))

const AdminLocalMainInfo: React.FC<any> = (props: any) => {
  const [userInfo, setUserInfo] = useState<any>()
  const [accountTypes, setAccountTypes] = useState<DictionaryItem[]>([])
  const [showCambioClave, setShowCambioClave] = useState<boolean>(false)
  const [localesRelacionados, setLocalesRelacionados] = useState<ILocalesRelacionados[]>()
  const [showLocalesRelacionados, setShowLocalesRelacionados] = useState<boolean>(false)
  const [newPasswordAleatoria, setNewPasswordAleatoria] = useState<string>('')
  const [closingSessions, setClosingSessions] = useState<boolean>(false)
  const [tieneComillas, setTieneComillas] = useState<boolean>(false)
  const [tipoCuenta, setTipoCuenta] = useState(0)
  const [vendedorTipoCuenta, setVendedorTipoCuenta] = useState('')
  const [highest, setHighest] = useState<DictionaryItem>()
  const [lowest, setLowest] = useState<DictionaryItem>()
  const [loadingTipoCuenta, setLoadingTipoCuenta] = useState<boolean>(false)
  const [loadingRelacionados, setLoadingRelacionados] = useState<boolean>(false)
  const { tenant } = useContext(TenantsContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!tipoCuenta) return
    axiosInstanceMain
      .get(`/admin/${tenant.path}/cuentatipo`)
      .then((result) => {
        setAccountTypes(result.data.data)
        setVendedorTipoCuenta(result.data.data.find((x: any) => x.id === tipoCuenta).nombre)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [tipoCuenta])

  useEffect(() => {
    setHighest(accountTypes.find((account: DictionaryItem) => account.nombre?.toLowerCase().includes('oro plus')))
    setLowest(accountTypes.find((account: DictionaryItem) => account.nombre?.toLowerCase().includes('vendedor básico')))
  }, [accountTypes])

  useEffect(() => {
    if (props.premise) {
      setTipoCuenta(props.premise.idCuentaTipo)

      axiosInstanceMain
        .get(`/auth/usuario/${props.premise?.idVendedor}`)
        .then((result) => {
          let user = result.data?.user as string

          user = user.includes('"') ? (user.split('"').length > 3 ? JSON.parse(user) : user.replace(/\\"/g, '')) : user

          setTieneComillas(user.includes('"') ? true : false)

          setUserInfo({
            ...result.data,
            user
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [props.premise])

  const closeSessions = () => {
    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/${props.premise.codLocal}/cerrar-sesion`)
      .then((res) => {
        console.log(res)
        SnackBarUtils.success('Sesiones cerradas correctamente')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleInputChange = (event: any) => {
    const value = event.target.value

    setUserInfo({
      ...userInfo,
      [event.target.name]: value
    })
  }

  const copy = async () => {
    await copyToClipboard(userInfo?.user.toString())
  }

  const savePremiseInfo = (event: any) => {
    event.preventDefault()
  }

  const closeModalCambioClave = () => {
    setShowCambioClave(false)
  }

  const changeUser = (event: any) => {
    const username = userInfo?.user
    if (username.includes('"')) return SnackBarUtils.error('El nombre de usuario no puede contener comillas')

    axiosInstanceMain
      .patch(`/auth/usuario/${userInfo?.idUsuario}/cambiarUsuario`, username, {
        headers: { 'Content-Type': 'text/plain' }
      })
      .then((result) => {
        setUserInfo({ user: result.data?.user, ...userInfo })
        SnackBarUtils.success('Nombre de usuario cambiado correctamente')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changePassword = (event: any) => {
    event.preventDefault()
    const banco1 = 'ABCDEFGHIJKLMNPQRSTUVWXYZ'
    const banco2 = '123456789'
    let aleatoria = ''
    for (let i = 0; i < 3; i++) {
      aleatoria += banco1.charAt(Math.floor(Math.random() * banco1.length))
    }
    for (let i = 0; i < 3; i++) {
      aleatoria += banco2.charAt(Math.floor(Math.random() * banco2.length))
    }

    const requestHandler = (request: any) => {
      request.headers['Content-Type'] = 'text/plain'
      return request
    }

    axiosInstanceMain.interceptors.request.use((request) => requestHandler(request))

    axiosInstanceMain
      .patch(`/auth/usuario/${userInfo?.idUsuario}/cambiarClave`, aleatoria)
      .then((result) => {
        if (result && result) {
          setNewPasswordAleatoria(aleatoria)
          setShowCambioClave(true)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const upgrade = async () => {
    if (highest && tipoCuenta >= highest.id) return
    cambioTipoCuenta(tipoCuenta + 1)
  }

  const downgrade = () => {
    if (lowest && tipoCuenta <= lowest.id) return
    cambioTipoCuenta(tipoCuenta - 1)
  }

  const cambioTipoCuenta = async (idCuentaTipo: number) => {
    try {
      setLoadingTipoCuenta(true)
      await axiosInstanceMain
        .post(`/admin/${tenant.path}/locales/${props.premise.codLocal}/tipo-cuenta/${idCuentaTipo}`)
        .then((data) => data.data && setTipoCuenta(idCuentaTipo))
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTipoCuenta(false)
    }
  }

  const fetchLocalesRelacionados = async () => {
    if (props.premise?.codLocal) {
      setLoadingRelacionados(true)
      getLocalesRelacionados(tenant, props.premise.codLocal)
        .then((data) => setLocalesRelacionados(data.data.data))
        .catch((error) => {
          console.log(error)
        })
        .finally(() => setLoadingRelacionados(false))
    }
  }

  const handleLocalesRelacionados = () => {
    if (!localesRelacionados) fetchLocalesRelacionados()
    setShowLocalesRelacionados(true)
  }

  if (!userInfo || !tipoCuenta || !vendedorTipoCuenta || !highest || !lowest) return <Loading />

  return (
    <>
      <ModalCambioClave
        show={showCambioClave}
        newPassword={newPasswordAleatoria}
        user={userInfo?.user}
        title={'Cambio de clave'}
        handleClose={closeModalCambioClave}
      ></ModalCambioClave>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
          py: 0.5,
          fontSize: '22px'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <span>{props.premise?.nombre}</span>
          <span>{`${props.premise?.idLocal} ${props.premise?.codLocal} | ${props.premise?.direccion}`}</span>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '10px' : '20px'
          }}
        >
          <button className="btn btn-primary" onClick={handleLocalesRelacionados}>
            {isMobile ? 'Relacionados' : 'Ver locales relacionados'}
          </button>
          <button
            className="btn btn-success"
            onClick={async () => {
              await props.getLocalAEditar()
              props.setSoloLectura(false)
            }}
          >
            Editar local
          </button>
        </div>
      </Box>
      <SecondLine>
        <Info>{props.premise?.resumenPublicaciones}</Info>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            mt: 3
          }}
        >
          <span className="premise-info-child">Tipo de Cuenta:</span>
          {loadingTipoCuenta ? <Loading /> : <label className="premise-info-child">{vendedorTipoCuenta}</label>}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {tipoCuenta !== highest.id && (
              <button className="btn btn-success" onClick={upgrade}>
                Upgrade
              </button>
            )}
            {tipoCuenta !== lowest.id && (
              <button className="btn btn-danger" onClick={downgrade}>
                Downgrade
              </button>
            )}
          </Box>
        </Box>
      </SecondLine>
      <form className="" onSubmit={savePremiseInfo}>
        <ContentThirdLine>
          <div>
            <ThirdLine>
              <Info>Usuario:</Info>
              <input
                className="col-xs-6 premise-info-child"
                name="user"
                type="text"
                value={userInfo.user}
                required
                onInput={handleInputChange}
              />
              <button title="Copiar al portapapeles" className="btn btn-link admin-local-btn premise-info-child">
                <GrIcons.GrClipboard onClick={copy} />
              </button>
              <button onClick={changeUser} className="btn btn-primary admin-local-btn premise-info-child" type="submit">
                Cambiar usuario
              </button>
              <button onClick={changePassword} className="btn btn-primary admin-local-btn premise-info-child">
                Cambiar clave aleatoria
              </button>
              {/* <Button color="error" variant="contained" onClick={() => setClosingSessions(true)}>
                Cerrar sesiones
              </Button> */}
              <button
                className="btn btn-danger admin-local-btn premise-info-child"
                onClick={() => setClosingSessions(true)}
              >
                Cerrar sesiones
              </button>
            </ThirdLine>
          </div>
        </ContentThirdLine>
      </form>

      <AlertDialog
        open={closingSessions}
        onClose={() => setClosingSessions(false)}
        onCancel={() => setClosingSessions(false)}
        onConfirm={closeSessions}
        title="Cerrar sesiones"
        content="¿Está seguro que desea cerrar todas las sesiones de este usuario?"
        agreeText="Confirmar"
        agreeColor="error"
        disagreeText="Cancelar"
      />
      <ModalLocalesRelacionados
        open={showLocalesRelacionados}
        setOpen={setShowLocalesRelacionados}
        locales={localesRelacionados || []}
        loading={loadingRelacionados}
      />
    </>
  )
}

export default AdminLocalMainInfo
