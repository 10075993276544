import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from "@mui/material";
import { format } from "date-fns";
import { IHistorias } from "../../types/historias.types";
import { useState } from "react";
import DialogCompartir from "./DialogCompartir";
import SnackBarUtils from "../../../../utils/SnackBarUtils";
import axios from "axios";

interface PropsCardHistoria {
  historia: IHistorias;
  eliminar: (idHistoria: any) => Promise<void>;
  compartir: (idHistoria: any) => Promise<void>;
  copyShareLink: (link: string) => void;
}

const CardHistoria = ({
  historia,
  eliminar,
  compartir,
  copyShareLink,
}: PropsCardHistoria) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  const handleCompartirClick = async () => {
    const imagen = await fetch(historia.imageUrl, {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "blob" },
    });

    //SnackBarUtils.info("pasó fetch")
    const blob = await imagen.blob();
    //SnackBarUtils.info("pasó blob")
    const shareFile = new File(
      [new Blob([blob], { type: "image/jpeg" })],
      "image.jpeg",
      { type: "image/jpeg" }
    );
    console.log(shareFile.type);
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Compartir imagen",
          url: historia.imageUrl,
          files: [shareFile],
        });
      } catch (error) {
        console.error("Error al compartir:", error);
      }
    }
    compartir(historia.idHistoria);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
      }}
    >
      <CardActionArea>
        <CardMedia
          component={"img"}
          loading="lazy"
          sx={{ height: 200, objectFit: "scale-down" }}
          image={historia.imageUrl}
          title={historia.imageUrl}
        />
      </CardActionArea>
      <CardHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      />
      <CardContent>
        <Typography variant="h6" fontWeight={"bold"}>
          {`${historia.nombreLocal} (${historia.codLocal})`}
        </Typography>
        <Typography variant="body2">{`ID Historia: ${historia.idHistoria}`}</Typography>
        <Typography variant="body2" style={{ whiteSpace: "break-spaces" }}>
          {historia.publicadaAhora ? "Publicada" : "No publicada"}
        </Typography>
        <Typography variant="body2">
          {historia.fechaDesde && `Creada: ${formatDate(historia.fechaDesde)}`}
        </Typography>
      </CardContent>
      <Box
        sx={{
          padding: "5px 10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => handleCompartirClick()}
        >
          Compartir
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => eliminar(historia.idHistoria)}
        >
          Eliminar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => copyShareLink(historia.shareLink)}
        >
          Copiar link
        </Button>
      </Box>
      {/* Compartir en caso de que navigator.share no funcione */}
      {/* <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>Compartir</DialogTitle>
        <DialogCompartir urlImagen={historia.imageUrl} />
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
    </Card>
  );
};

export default CardHistoria;
