import { useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Loading from '../../../../components/Common/Loading'
import NoResults from '../../../../components/Common/NoResults'
import { IContacto, WhatsappContext } from '../../context/whatsapp.context'
import Contacto, { ContactoStyles } from './Contacto'
import { Archive } from '@mui/icons-material'

const ContactosList = () => {
  const {
    loadingContactos,
    isSearching,
    contactosFiltrados,
    contactos,
    contactosArchivados,
    setViendoContactosArchivados,
    viendoContactosArchivados,
    getContactos
  } = useContext(WhatsappContext)
  const searchParams = new URLSearchParams(window.location.search)
  const contact = searchParams.get('contact')

  useEffect(() => {
    getContactos(contact)
  }, [])

  if (loadingContactos) return <Loading />

  return (
    <>
      {isSearching &&
      (viendoContactosArchivados
        ? contactosFiltrados.filter((c) => c.archived)
        : contactosFiltrados
      ).length === 0 ? (
        <div className="px-3">
          <NoResults />
        </div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '70%',
            overflowY: 'auto'
          }}
        >
          {contactosArchivados.length > 0 && !viendoContactosArchivados ? (
            <Box
              onClick={() => setViendoContactosArchivados(true)}
              sx={ContactoStyles}
            >
              <Box component="span">
                Archivados <Archive />
              </Box>
              <Typography variant="body1" color="#bbbbbb">
                {contactosArchivados.length}
              </Typography>
            </Box>
          ) : (
            contactosArchivados.length > 0 && (
              <Box
                onClick={() => setViendoContactosArchivados(false)}
                sx={ContactoStyles}
              >
                <Box component="span">Volver</Box>
              </Box>
            )
          )}

          {(contactosFiltrados.length > 0
            ? viendoContactosArchivados
              ? contactosFiltrados.filter((c) => c.archived)
              : contactosFiltrados
            : viendoContactosArchivados
            ? contactosArchivados
            : contactos
          ).map((c: IContacto, i) => {
            return c.archived && !viendoContactosArchivados ? null : (
              <Contacto key={i} contacto={c} />
            )
          })}
        </Box>
      )}
    </>
  )
}

export default ContactosList
