import { Box } from "@mui/material";
import { mostrarFecha } from "../../../../utils/DateFunctions";

interface ConversacionFechaProps {
  fecha: string;
}

const ConversacionFecha = ({ fecha }: ConversacionFechaProps) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        justifySelf: "center",
        padding: "5px 10px",
        margin: "5px 0px",
        borderRadius: "5px",
        backgroundColor: "#313131",
        color: "#aaa",
        fontSize: "12px",
      }}
    >
      {mostrarFecha(fecha) === fecha ? "Hoy" : fecha}
    </Box>
  );
};

export default ConversacionFecha;
