import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";
// import { DeleteOutline } from "@mui/icons-material"

interface FormActionsProps {
  local?: LocalVendedorDTO;
  isSubmitting: boolean;
  setSoloLectura: (value: boolean) => void;
}

const FormActions = ({
  local,
  isSubmitting,
  setSoloLectura,
}: FormActionsProps) => {
  const navigate = useNavigate();

  const volver = () => (local ? setSoloLectura(true) : navigate("/login"));

  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 1,
        mb: 2,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      {/* { local && <Grid item md={3} xs={12}>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteOutline />}
            fullWidth
            onClick={() => console.log("ELIMINANDO")}
          >
            Eliminar
          </Button>
        </Grid>
      } */}
      <Grid item md={12} xs={12}>
        <Button
          variant="contained"
          color="success"
          fullWidth
          type="submit"
          disabled={isSubmitting}
        >
          {local
            ? isSubmitting
              ? "Modificando"
              : "Modificar"
            : isSubmitting
            ? "Creando el local"
            : "Continuar"}
        </Button>
      </Grid>
      <Grid item md={12} xs={12}>
        <Button
          color="inherit"
          fullWidth
          type="button"
          disabled={isSubmitting}
          onClick={volver}
        >
          Volver
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormActions;
