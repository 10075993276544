import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { PasosProps } from './RecuperarPassword'

interface Paso3Props extends PasosProps {
  repeatValue: string
  setRepeatValue: (value: string) => void
}

const Paso3 = ({isSubmitting, setValue, setRepeatValue, value, repeatValue, handleConfirm}: Paso3Props) => {
  const [visibility, setVisibility] = useState(false)
  const [helpText, setHelpText] = useState("")

  const handleChange = (e: any, setValue: any) => {
    setValue(e.target.value)
    // setHelpText(value === repeatValue ? "" : "Las contraseñas no coinciden")
  }

  useEffect(() => {
    setHelpText(value === repeatValue ? "" : "Las contraseñas no coinciden")
  }, [value, repeatValue])

  return (
    <>
      <Typography marginBottom={2} variant="body1" color={"GrayText"}>
        Ingresa la nueva contraseña
      </Typography>

      <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-password"
          label="Contraseña"
          type={visibility ? "text" : "password"}
          disabled={isSubmitting}
          value={value}
          autoFocus
          fullWidth
          onChange={e => handleChange(e, setValue)}
          onKeyPress={e => e.key === "Enter" && handleConfirm()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setVisibility(!visibility)}
                onMouseDown={() => setVisibility(!visibility)}
                edge="end"
              >
                { visibility ? <Visibility /> : <VisibilityOff />  }
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl fullWidth sx={{mt: 2}}>
        <InputLabel htmlFor="outlined-adornment-repassword">Repita la contraseña</InputLabel>
        <OutlinedInput
          autoComplete="off"
          id="outlined-adornment-repassword"
          label="Repita la contraseña"
          type={visibility ? "text" : "password"}
          disabled={isSubmitting}
          value={repeatValue}
          fullWidth
          onChange={e => handleChange(e, setRepeatValue)}
          onKeyPress={e => e.key === "Enter" && handleConfirm()}
       />
        <Typography variant="body2" color={"error"}>
          {helpText}
        </Typography>
      </FormControl>
    </>
  )
}

export default Paso3
