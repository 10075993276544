import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useCallback, useState } from "react";
import FormRecaptcha from "../../AbmLocalPage/components/Form/FormRecaptcha";

interface FormProps {
  handleLogin: (user: string, pwd: string) => Promise<void>;
  goToRegister: () => void;
  loading: boolean;
  setToken: (token: string) => void;
  setRecuperarPass: (value: boolean) => void;
}

const Form = ({
  handleLogin,
  goToRegister,
  loading,
  setToken,
  setRecuperarPass,
}: FormProps) => {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleLoginFormSubmit = useCallback(async () => {
    await handleLogin(user, pwd);
  }, [handleLogin, pwd, user]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <FormControl fullWidth>
        <TextField
          fullWidth
          label="Usuario"
          name="usuario"
          value={user}
          onChange={(e) => setUser(e.target.value)}
          disabled={loading}
          onKeyDown={(e) => e.key === "Enter" && handleLoginFormSubmit()}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">
          Contraseña
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          fullWidth
          label="Contraseña"
          type={showPassword ? "text" : "password"}
          name="password"
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          disabled={loading}
          onKeyDown={(e) => e.key === "Enter" && handleLoginFormSubmit()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography
          variant={isMobile ? "caption" : "body2"}
          color="#007bff"
          sx={{ alignSelf: "flex-end", p: 1, cursor: "pointer" }}
          onClick={() => setRecuperarPass(true)}
        >
          ¿Olvidaste tu usuario o contraseña?
        </Typography>
      </FormControl>

      <FormControl fullWidth>
        <Button
          fullWidth
          variant="contained"
          disabled={loading}
          onClick={handleLoginFormSubmit}
        >
          {loading ? "Ingresando" : "Iniciar Sesión"}
        </Button>
      </FormControl>

      <FormControl fullWidth>
        <Button
          fullWidth
          variant="contained"
          sx={{ bgcolor: "#696969", ":hover": { bgcolor: "#5c5c5c" } }}
          disabled={loading}
          onClick={goToRegister}
        >
          Crear mi cuenta
        </Button>
      </FormControl>

      <Typography>
        Si no tenés local en Flores{" "}
        <a href="https://www.avellanedaauntoque.com">
          <b>ingresa acá</b>
        </a>
      </Typography>

      <FormRecaptcha name="Login_backoffice" setToken={setToken} />
    </Box>
  );
};

export default Form;
