import { FormControlLabel } from '@mui/material'
import { Equivalencia } from '../types/importacion'
import { memo, useCallback } from 'react'
import { useResponsive } from '../../../../hooks/useResponsive.hook'
import { CustomCheckbox, SquareIcon } from '../../../../components/Common/utils/CustomCheckbox'
import { Check } from '@mui/icons-material'

interface Props {
  element: string
  equivalencias: Equivalencia[]
  setEquivalencias: (equivalencias: Equivalencia[]) => void
  checked: boolean
  alignSelf?: string
}

const NoImportarCheckbox = ({ checked, element, equivalencias, setEquivalencias, alignSelf = 'center' }: Props) => {
  const { isMobile } = useResponsive()

  const handleChangeCheckbox = useCallback(
    (element: string) => {
      const index = equivalencias.findIndex((eq) => Object.keys(eq)[0] === element)
      const newEquivalencias = [...equivalencias]
      newEquivalencias[index] = {
        [element]: {
          value: equivalencias[index][element].value,
          no_importar: !equivalencias[index][element].no_importar
        }
      }

      setEquivalencias(newEquivalencias)
    },
    [equivalencias, setEquivalencias]
  )

  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          checked={checked}
          icon={<SquareIcon />}
          checkedIcon={<Check />}
          onChange={() => handleChangeCheckbox(element)}
        />
      }
      label="No importar"
      slotProps={{
        typography: { fontSize: { xs: 12, md: 14 }, fontWeight: 600, mt: '0.5px' }
      }}
      sx={isMobile ? { m: 0, mt: 2 } : { width: '200px', alignSelf }}
    />
  )
}

export default memo(NoImportarCheckbox)
