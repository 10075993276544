import React, { useState } from "react";
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableFooter
} from "@mui/material";
import { format } from "date-fns";
import DetalleDesktop from "./detalleDesktop";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PaginatorPedidos } from "../../context/pedidos.context";
import TablePedidosPagination from "./pedidosTablaPaginado";

interface PedidosProps {
  pedidos: any;
  isAdmin: boolean;
  paginator: PaginatorPedidos;
  handleArchivarPedidos: (idPedido: number, archivar: boolean) => void;
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, "dd/MM/yyyy HH:mm");
};

const RenderPedidosDesktop: React.FC<PedidosProps> = ({
  pedidos,
  isAdmin,
  paginator,
  handleArchivarPedidos,
}) => {
  const [selectedPedido, setSelectedPedido] = useState<any | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleVerDetalleClick = (pedido: any) => {
    setSelectedPedido(pedido);
    setModalOpen(true);
  };

  const keyNames: Record<string, string> = {
    codigoPedido: "Pedido",
    fechaEnvio: "Fecha de Envío",
    nombreComprador: "Nombre del comprador",
    whatsappComprador: "Teléfono",
    formaRetiro: "Forma de retiro",
    total: "Total",
  };

  return (
    <>
      <Table style={{ tableLayout: "initial" }}>
        <TableHead
          aria-label="Pedidos"
          sx={{
            fontWeight: "bold",
            backgroundColor: "#e0e0e0",
            color: "white",
          }}
        >
          <TableRow>
            {isAdmin && (
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
              >
                Local
              </TableCell>
            )}
            {Object.keys(keyNames).map((key) => (
              <TableCell
                key={key}
                sx={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
              >
                {keyNames[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {pedidos.map((pedido: any, index: number) => (
            <TableRow
              key={index}
              onClick={() => handleVerDetalleClick(pedido)}
              style={{ cursor: "pointer" }}
            >
              {isAdmin && <TableCell>{pedido.localNombre}</TableCell>}
              <TableCell>{pedido.codigo}</TableCell>
              <TableCell>{formatDate(pedido.fechaEnvio)}</TableCell>
              <TableCell
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  maxWidth: "200px",
                }}
              >
                {pedido.nombre} {pedido.apellido}
              </TableCell>
              <TableCell>
                {pedido.compradorWhatsapp
                  ? `(...${pedido.compradorWhatsapp.slice(-4)})`
                  : "--"}
              </TableCell>
              <TableCell>{pedido.pedidoTipoRetiro}</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {pedido.totales.map(
                  (total: any) =>
                    `${total.moneda.simbolo} ${new Intl.NumberFormat(
                      "es-AR"
                    ).format(parseFloat(total.total))}\n`
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePedidosPagination
              paginator={paginator}
              pedidos={pedidos}
              component={"td"}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="md"
        PaperProps={{
          style: {
            maxHeight: "90vh",
          },
        }}
      >
        <DialogContent
          style={{ flexGrow: 1, marginTop: "2px", maxWidth: "800px" }}
        >
          <DetalleDesktop
            pedidos={[selectedPedido]}
            isAdmin={isAdmin}
            handleArchivarPedidos={handleArchivarPedidos}
          />
        </DialogContent>
        <DialogActions>
          <IconButton
            edge="end"
            onClick={() => setModalOpen(false)}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              margin: "6px",
              paddingTop: "16px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RenderPedidosDesktop;
