import { Atributo, AtributosEquivalencias, SaveAtributos, SaveValores } from '../types/index'
import { axiosInstanceIntegraciones } from '../../../utils/axiosGlobalUtils'
import { IProductoTienda } from '../types/responses-productos'
import { IBaseResponseGlobal } from '../types/responses'

export const GetAtributosEquivalencias = async (codLocal: string) =>
  (await axiosInstanceIntegraciones.get<IBaseResponseGlobal<Atributo[]>>(`/${codLocal}/atributos`)).data

export const GetAtributosEquivalenciasValores = async (atributo: number, codLocal: string) =>
  (
    await axiosInstanceIntegraciones.get<IBaseResponseGlobal<AtributosEquivalencias[]>>(
      `/${codLocal}/atributos/${atributo}/valores`
    )
  ).data

export const GetAtributos = async (codLocal: string) => await axiosInstanceIntegraciones.get(`/${codLocal}/atributos`)

export const SaveAtributosEquivalencia = async (atributos: SaveAtributos[], codLocal: string) =>
  await axiosInstanceIntegraciones.post(`/${codLocal}/atributos`, atributos)

export const SaveValoresAtributos = async (valores: SaveValores[], codLocal: string) =>
  await axiosInstanceIntegraciones.post(`/${codLocal}/atributos/valores`, valores)

export const ValidarAtributos = async (codLocal: string, productos: IProductoTienda[]) =>
  await axiosInstanceIntegraciones.post(`/${codLocal}/atributos/validar`, productos)

export const ValidarAtributosEquivalencias = async (codLocal: string, atributos: any[]) =>
  await axiosInstanceIntegraciones.post(`/woocommerce/${codLocal}/atributos`, atributos)

// export const SincronizarAtributos = async (codLocal: string, atributos: AtributosSincronizar[]) =>
//   await axiosInstanceIntegraciones.post(`/${codLocal}/atributos`, atributos);
