import React, { ReactElement } from "react";
import { MaximizeIconWrapper } from "./style/maximize-icon.style";

interface UpIconParams {
  className?: string;
  color?: string;
}

export const MaximizeIcon = ({
  className,
  color,
}: UpIconParams): ReactElement => {
  return (
    <MaximizeIconWrapper className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 16 16 12 12 8"></polyline>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </MaximizeIconWrapper>
  );
};
