import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material'
import CardProducto from '../cardProducto'
import DialogNoContacto from '../DialogNoContacto'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { format } from 'date-fns'

interface DetallePedidosProps {
  pedidos: any
  isAdmin: boolean
  handleArchivarPedidos: (idPedido: number, archivar: boolean) => void
}

const DetallesMobile: React.FC<DetallePedidosProps> = ({
  pedidos,
  isAdmin,
  handleArchivarPedidos
}) => {
  const openWhatsAppConversation = (
    nombreLocal: string,
    codigoPedido: string,
    telefono: number
  ) => {
    const text = `Hola, soy ${nombreLocal}, te contacto por el pedido ${codigoPedido} que hiciste por Avellaneda a un toque`
    const url = `https://api.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(
      text
    )}`
    window.open(url, '_blank')
  }

  const [modalOpen, setModalOpen] = useState(false)
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return format(date, 'dd/MM/yyyy HH:mm')
  }

  return (
    <Container>
      {pedidos.map((pedido: any, index: number) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bold',
              flexGrow: 1,
              marginBottom: '6px',
              marginTop: '6px'
            }}
          >
            {`Detalles del Pedido ${pedido.codigo}`}
          </Typography>
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flexGrow: 1
            }}
          >
            {isAdmin && (
              <Typography
                variant="body1"
                sx={{ marginTop: '6px', marginBottom: '6px' }}
              >
                Local: {pedido.localNombre}
              </Typography>
            )}
            {isAdmin && <Divider sx={{ width: '100%' }} />}
            <Typography
              variant="body1"
              style={{ marginTop: '6px', fontWeight: 'bold' }}
            >
              Enviado: {formatDate(pedido.fechaEnvio)}
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: '6px', fontWeight: 'bold' }}
            >
              Total:{' '}
              {pedido.totales
                .map(
                  (total: any, index: number) =>
                    `${total.moneda.simbolo} ${new Intl.NumberFormat(
                      'es-AR'
                    ).format(parseFloat(total.total))}`
                )
                .join(' - ')}
            </Typography>
            <Typography variant="body1">
              {pedido.nombre &&
                pedido.apellido &&
                `${pedido.nombre} ${pedido.apellido}`}
            </Typography>
            <Typography variant="body1">
              {pedido.compradorWhatsapp &&
                `(Tel termina en ${pedido.compradorWhatsapp.slice(-4)})`}
            </Typography>
            <Typography variant="body1">
              Retira: {pedido.pedidoTipoRetiro}
            </Typography>
            <Typography variant="body1">
              {pedido.pais && `País: ${pedido.pais}`}
            </Typography>
            <Typography variant="body1">
              {pedido.provincia && `Provincia: ${pedido.provincia}`}
            </Typography>
            <Typography variant="body1">
              {pedido.codigoPostal && `Código Postal: ${pedido.codigoPostal}`}
            </Typography>
            <Typography variant="body1">
              {pedido.localidad && `Localidad: ${pedido.localidad}`}
            </Typography>
            <Typography variant="body1" justifyContent="flex-start">
              {pedido.direccion &&
                `Dirección: ${pedido.direccion}${
                  pedido.pedidoTipoDireccion
                    ? ` (${pedido.pedidoTipoDireccion})`
                    : ''
                }`}
            </Typography>
            <Typography variant="body1" justifyContent="flex-start">
              {pedido.observaciones && `Observaciones: ${pedido.observaciones}`}
            </Typography>
          </Box>
          <Box
            sx={{
              maxHeight: 260,
              overflow: 'auto',
              width: '100%',
              flexGrow: 1
            }}
          >
            {pedido.productoList.map((producto: any, i: number) => (
              <CardProducto
                key={producto.codProducto}
                producto={producto}
                isMobile={isMobile}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', // Cambiamos "column" a "row"
              gap: '10px',
              width: '100%',
              marginTop: '20px',
              marginBottom: '10px'
            }}
          >
            <Button
              variant="contained"
              color="success"
              fullWidth
              style={{
                alignItems: 'center',
                background: `linear-gradient(45deg, #55CF60, #27B53F)`,
                color: '#fff',
                padding: '10px 20px',
                fontSize: '14px',
                cursor: 'pointer',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
              }}
              onClick={() =>
                pedido.compradorWhatsapp
                  ? openWhatsAppConversation(
                      pedido.localNombre,
                      pedido.codigo,
                      pedido.compradorWhatsapp
                    )
                  : setModalOpen(true)
              }
            >
              <WhatsAppIcon
                sx={{
                  marginRight: '8px',
                  marginBottom: '4px',
                  fontSize: '20px'
                }}
              />
              Contactar al comprador
            </Button>
            {!isAdmin && (
              <Button
                onClick={() => {
                  pedido.vendedorArchivado
                    ? handleArchivarPedidos(pedido.idPedido, false)
                    : handleArchivarPedidos(pedido.idPedido, true)
                }}
                variant="contained"
                color="primary"
                sx={{
                  alignItems: 'center',
                  padding: '10px 20px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
                }}
              >
                {pedido.vendedorArchivado ? (
                  <UnarchiveIcon
                    sx={{
                      marginRight: '8px',
                      marginBottom: '4px',
                      fontSize: '20px'
                    }}
                  />
                ) : (
                  <ArchiveIcon
                    sx={{
                      marginRight: '8px',
                      marginBottom: '4px'
                      //fontSize: "20px",
                    }}
                    fontSize="medium"
                  />
                )}
                {pedido.vendedorArchivado ? 'Desarchivar' : 'Archivar'}
              </Button>
            )}
          </Box>

          <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
            <DialogContent>
              <DialogNoContacto codigoPedido={pedido.codigo} />
            </DialogContent>
            <DialogActions>
              <IconButton
                edge="end"
                //color="error"
                onClick={() => setModalOpen(false)}
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  marginRight: '2px'
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Dialog>
        </div>
      ))}
    </Container>
  )
}

export default DetallesMobile
