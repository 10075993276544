import { Alert } from "@mui/material"

const NoSearch = () => {
  return (
    <Alert
      severity="info"
      className="my-3 font-weight-bold"
    >
      Realice una búsqueda para ver los productos
    </Alert>
  )
}

export default NoSearch
