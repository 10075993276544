/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from 'react'
import Select from 'react-select'

const KeywordsSelect: React.FC<any> = (props: any) => {
  const [keywordChips, setKeywordChips] = useState<
    { value: string; label: string }[]
  >([])
  const { setKeywords, editRubro, setEditRubro } = props

  useEffect(() => {
    if (editRubro?.keywords) {
      const kwrds = editRubro.keywords.split(', ')
      const kwrdsObject: any[] = []
      kwrds.map((k: string) => {
        const item = { value: k, label: k }
        kwrdsObject.push(item)
      })
      setKeywordChips([...kwrdsObject])
    }
  }, [editRubro])

  return (
    <>
      <Select
        options={keywordChips.map((valor) => ({
          value: valor.value,
          label: valor.label
        }))}
        isMulti
        isClearable={false}
        className="basic-multi-select"
        classNamePrefix="select"
        value={keywordChips}
        onChange={(e, context) => {
          if (context.action === 'remove-value') {
            let kwrds = [...keywordChips]
            kwrds = kwrds.filter(
              (item: any) => item.value !== context.removedValue.value
            )
            setKeywordChips(kwrds)
          }
        }}
        components={{
          NoOptionsMessage: (props) => (
            <span
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                const valorLibre = {
                  value: props.selectProps.inputValue,
                  label: props.selectProps.inputValue
                }
                setKeywordChips([...keywordChips, valorLibre])
              }}
            >
              Cargar valor
            </span>
          )
        }}
        onBlur={() => {
          const rubroKeywords: string[] = []
          keywordChips.map((kwrd: { value: string; label: string }) => {
            rubroKeywords.push(kwrd.label)
          })
          if (editRubro && setEditRubro) {
            setEditRubro({
              ...editRubro,
              keywords: rubroKeywords.join(', ')
            })
          } else {
            setKeywords(rubroKeywords.join(', '))
          }
        }}
      />
    </>
  )
}

export default memo(KeywordsSelect)
