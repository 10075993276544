import { Box, useMediaQuery, useTheme } from "@mui/material";
import Mensaje from "./Mensaje";
import { useContext } from "react";
import { WhatsappContext } from "../../context/whatsapp.context";
import Loading from "../../../../components/Common/Loading";
import ConversacionFecha from "./ConversacionFecha";

const ConversacionList = () => {
  const { conversacion, contactoSeleccionado, loadingConversacion } =
    useContext(WhatsappContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (loadingConversacion) return <Loading />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        padding: isMobile ? "20px 0px 100px 0px" : "20px",
        height: !isMobile ? "74vh" : "",
      }}
      component={"div"}
      ref={(ref: any) => ref && ref.scrollTo(0, ref.scrollHeight)}
    >
      {Object.entries(conversacion).length <= 0 &&
      contactoSeleccionado.id !== 0 ? (
        <h4 style={{ textAlign: "center" }}>No hay mensajes</h4>
      ) : (
        Object.entries(conversacion).map((mensaje: any, i: number) => (
          <Box key={i} sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ConversacionFecha fecha={mensaje[0]} />
            </Box>
            {mensaje[1].map((m: any, j: number) => (
              <Mensaje key={j} message={m} />
            ))}
          </Box>
        ))
      )}
    </Box>
  );
};

export default ConversacionList;
