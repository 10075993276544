import { createContext, useState, useEffect, useCallback, useContext } from "react";
import { IHistorias } from "../types/historias.types";
import { eliminarHistoria, getHistorias, compartirHistoria } from "../services/historias.services";
import SnackBarUtils from "../../../utils/SnackBarUtils";
import { TenantsContext } from "../../../context/tenants.context";

export interface FiltrosHistorias {
  idHistoria: string;
  codLocal: string;
  publicadosAhora: boolean;
  publicadosInstagram: boolean | null;
  creadoDesde: string | null;
  creadoHasta: string | null;
}

export interface Paginator {
  handleChangePage: (e: any, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const defaultFiltros: FiltrosHistorias = {
  idHistoria: "",
  codLocal: "",
  publicadosAhora: true,
  publicadosInstagram: null,
  creadoDesde: "",
  creadoHasta: "",
};

const defaultPaginator: Paginator = {
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
};

interface HistoriasAdminContextProps {
  historias: IHistorias[];
  loading: boolean;
  paginator: Paginator;
  searching: boolean;
  cantLocales: number;
  setSearching: (searching: boolean) => void;
  setFiltros: (filtros: FiltrosHistorias) => void;
  page: number;
  rowsPerPage: number;
  CompartirHistoria: (idHistoria: any) => Promise<void>;
  EliminarHistoria: (idHistoria: any) => Promise<void>;
}

const INITIAL_ROWS_PER_PAGE = 10;

// create context
export const HistoriasAdminContext = createContext<HistoriasAdminContextProps>({
  historias: [],
  loading: false,
  paginator: defaultPaginator,
  searching: false,
  cantLocales: 0,
  setSearching: () => {},
  setFiltros: () => {},
  page: 0,
  rowsPerPage: INITIAL_ROWS_PER_PAGE,
  CompartirHistoria: async () => {},
  EliminarHistoria: async () => {},
});

// create provider
export const HistoriasProvider = ({ children }: any) => {
  const [historias, setHistorias] = useState<IHistorias[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [filtros, setFiltros] = useState<FiltrosHistorias>(defaultFiltros);
  const [paginator] = useState<Paginator>(defaultPaginator);
  const [page, setPage] = useState<number>(0);
  const [cantLocales, setCantLocales] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);
  const { tenant } = useContext(TenantsContext);

  const getCantLocales = (listado: IHistorias[]) => {
    const locales: string[] = [];
    listado.map((h: IHistorias) => {
      if (!locales.includes(h.codLocal)) {
        locales.push(h.codLocal);
      }
    });
    setCantLocales(locales.length);
  };

  const fetchData = useCallback(async () => {
    const data = await getHistorias({ ...filtros }, tenant).then((res) => res.data);
    setHistorias(data.data);
    getCantLocales(data.data);
  }, [filtros]);

  // Paginator
  paginator.handleChangePage = (e: any, newPage: number) => setPage(newPage);

  paginator.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
    if (!searching) return;
    setLoading(true);
    Promise.all([fetchData()])
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filtros, searching, fetchData]);

  const CompartirHistoria = async (idHistoria: any) => {
    try {
      setLoading(true);
      await compartirHistoria(idHistoria, tenant);
      SnackBarUtils.success("Historia compartida con éxito");
    } catch {
      console.log();
      SnackBarUtils.error("Error al compartir la historia");
    } finally {
      setLoading(false);
    }
  };

  const EliminarHistoria = async (idHistoria: any) => {
    try {
      setLoading(true);
      await eliminarHistoria(idHistoria, tenant);
      SnackBarUtils.success("Historia eliminada con éxito");
      fetchData();
    } catch {
      console.log();
      SnackBarUtils.error("Error al eliminar la historia");
    } finally {
      setLoading(false);
    }
  };

  return (
    <HistoriasAdminContext.Provider
      value={{
        historias,
        loading,
        setFiltros,
        paginator,
        page,
        rowsPerPage,
        searching,
        cantLocales,
        setSearching,
        CompartirHistoria,
        EliminarHistoria,
      }}
    >
      {children}
    </HistoriasAdminContext.Provider>
  );
};
