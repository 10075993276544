import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useContext } from 'react'
import { IntegracionesContext } from '../context/integraciones.context'
import { Tiendas } from '../utils/Tiendas'
import { ITienda } from '../types'

const SeleccionTienda = () => {
  const { setTiendaSeleccionada } = useContext(IntegracionesContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleSeleccionTienda = (tienda: ITienda) => {
    setTiendaSeleccionada(tienda)
    localStorage.setItem('tiendaSeleccionada', JSON.stringify(tienda))
  }

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mt="30px"
        bgcolor="white"
        padding="30px"
        borderRadius="16px"
        border="1px solid #d9d9d9"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          border="1px solid #d9d9d9"
          bgcolor="#F5F5F7"
          padding="20px"
          borderRadius="16px"
          gap="40px"
        >
          <Typography textAlign="center" fontSize={{ xs: 22, sm: 26, md: 30 }} fontWeight="bold" color="#212121">
            Seleccioná la tienda para integrar
          </Typography>
          <Box
            display="flex"
            gap="40px"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            width="100%"
          >
            {Tiendas.map((tienda) => (
              <Box
                key={tienda.name}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="20px"
                border="1px solid #D9D9D9"
                bgcolor="white"
                padding="24px 40px"
                width="250px"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0px 6px 8px 0px rgba(0,0,0,0.1)',
                    outline: '1.5px solid #919191'
                  }
                }}
                onClick={() => handleSeleccionTienda(tienda)}
              >
                <img style={{ objectFit: 'cover' }} width={'100%'} src={tienda.logo} alt={`tienda ${tienda.name}`} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default SeleccionTienda
