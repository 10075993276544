import { ChevronLeft, ChevronRight, Logout } from '@mui/icons-material'
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router'
import { RolType, SidebarItems } from './SidebarItems'
import { connect } from 'react-redux'
import { themePalette } from '../themePalette'
import { useLocal as useLocalABM } from '../../../pages/AbmLocalPage/hooks/local.hook'
import Loading from '../../Common/Loading'
import UserInfo from './UserInfo'
import { useCallback, useContext, useEffect, useState } from 'react'
import { OfrecerPremiumContext } from '../../../context/OfrecerPremium/ofrecerPremium.context'
import useLocal from '../../../pages/Products/hooks/locals.hook'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

const Sidebar = (props: any) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { onOpen } = useContext(OfrecerPremiumContext)
  const location = useLocation()
  const { handleDrawerClose, open, rol, setIsLoggingOut, logged } = props
  const { userLogged: user } = logged
  const { local, loading } = useLocalABM(user.codLocal)
  const { showIntegraciones } = useLocal(user.idLocal, user.token)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const drawerWidth = isMobile ? 250 : 290
  const [showBotonPremium, setShowBotonPremium] = useState<boolean>(false)

  const handleCloseSidebar = useCallback(() => {
    if (isMobile) handleDrawerClose()
  }, [isMobile, handleDrawerClose])

  useEffect(() => {
    const show = local?.config?.find((c: any) => c.name === 'premium_ofrecerPremium')?.value
    setShowBotonPremium(show === 'true' ? true : false)
  }, [local])

  if (loading) return <Loading />

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          bgcolor: themePalette.bgcolorsidebar,
          color: themePalette.colorsidebar
        }
      }}
      variant={isMobile ? 'temporary' : 'persistent'}
      onClose={handleCloseSidebar}
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton
          sx={{
            color: themePalette.colorsidebar,
            bgcolor: themePalette.defaultHoverSidebar,
            ':hover': { bgcolor: themePalette.alternativeHover }
          }}
          onClick={handleDrawerClose}
        >
          {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      {/* User Info */}
      {local && <UserInfo local={local} user={user} />}
      <Divider sx={{ bgcolor: themePalette.defaultHoverSidebar }} />
      {/* Links */}

      {local && showBotonPremium && (
        <ListItem
          disablePadding
          onClick={onOpen}
          sx={{
            background: 'linear-gradient(90deg, #ffdc40 0%, #caa70b 100%)',
            ':hover': {
              background: 'linear-gradient(90deg, #ffdc40 0%, #e2c64a 100%)'
            },
            color: '#000000'
            // ":hover > div > div > svg": {
            //   color: "#fffab2",
            // },
          }}
        >
          <ListItemButton>
            {/* <ListItemIcon>
              <Star />
            </ListItemIcon> */}
            <ListItemText
              primary={local.config.find((c: any) => c.name === 'premium_sidebarBotonUpgradeText')?.value}
            />
          </ListItemButton>
        </ListItem>
      )}
      <List>
        {SidebarItems.map((item, i) => {
          if (!item.rol.includes(rol)) return null
          if (i === 0 && isMobile) return null
          if (item.title === 'Integraciones' && !showIntegraciones) return null
          return (
            <ListItem
              key={i}
              disablePadding
              onClick={() => navigate(item.path)}
              sx={{
                bgcolor: location.pathname === item.path ? '#dddddd' : 'transparent',
                ':hover': { bgcolor: themePalette.defaultHoverSidebar }
              }}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: themePalette.colorsidebar }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          )
        })}

        <ListItem
          onClick={() => setIsLoggingOut(true)}
          disablePadding
          sx={{ ':hover': { bgcolor: themePalette.defaultHoverSidebar } }}
        >
          <ListItemButton>
            <ListItemIcon sx={{ color: themePalette.logout }}>
              <Logout />
            </ListItemIcon>
            <ListItemText sx={{ color: themePalette.logout }} primary={'Cerrar Sesión'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged
  }
}

export default connect(mapStateToProps)(Sidebar)
