import React from 'react'
import {
  IHistorialContratos,
  IHistorialResponse
} from '../../../dto/ContractDTO'
import TableDataPagination from '../../../pages/Products/components/TableDataPagination'
import { Paginator } from '../../../pages/Products/context/productos.vendedor.context'

export interface PropsListadoHistorial {
  historial: IHistorialResponse
  headers: string[]
  paginator: Paginator
}

const TablaHistorial: React.FC<PropsListadoHistorial> = ({
  historial,
  headers,
  paginator
}: PropsListadoHistorial) => {
  return (
    <table className="table table-striped table-bordered">
      <thead className="table-active">
        <tr>
          {headers &&
            headers.map((h: any, index) => {
              return <th key={index}>{h}</th>
            })}
        </tr>
      </thead>
      <tbody>
        {historial.results.map((item: IHistorialContratos, index) => {
          const fecha = item.fecha ? new Date(item.fecha) : null
          const fechaInicio = item.fechaInicio
            ? new Date(item.fechaInicio)
            : null
          const fechaFin = item.fechaFin ? new Date(item.fechaFin) : null
          return (
            <tr key={index}>
              <th>{fecha?.toLocaleString() || '-'}</th>
              <th>{item.idContrato}</th>
              <th>{item.idLocal}</th>
              <th>{item.nombreCliente}</th>
              <th>{item.productoNombre}</th>
              <th>{item.estadoNombre}</th>
              <th>{fechaInicio?.toLocaleString() || '-'}</th>
              <th>{fechaFin?.toLocaleString() || '-'}</th>
              <th>{item.observaciones}</th>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <TableDataPagination
          total={historial.totalElements || 0}
          page={historial.pageNumber || 0}
          paginator={paginator}
          items={historial.results || []}
          rowsPerPage={historial.pageSize || 100}
          component={'td'}
          label={`Productos por página`}
        />
      </tfoot>
    </table>
  )
}

export default TablaHistorial
