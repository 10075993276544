import { memo, useContext, useEffect, useState } from 'react'

// Material UI
import {
  InputLabel,
  Select,
  Stack,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Link,
  useTheme,
  IconButton,
  Box,
  Tooltip,
  Typography,
  InputAdornment
} from '@mui/material'
import { ContentCopy, Close, Info } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'

// date format
import { DatePicker, DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

// component for d&d images
import Dropzone from '../components/Dropzone'

import DisableRetailPrice from './DisableRetailPrice'

// form validation
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Image } from '../types/product'
import { updateProduct, getProduct, pauseProduct, unPauseProduct } from '../services/products.services'
import { getStatusLabel } from '../utils/status.product'
import { AiFillInfoCircle } from 'react-icons/ai'
import { copyToClipboard } from '../../../utils/utils'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { findRubro, findUnidadDeMedida } from '../../AdminProducts/services/rubros.service'
import DialogPausarProducto from '../components/DialogPausarProducto'
import { skuEnUso } from '../services/products.services'
import { TenantsContext } from '../../../context/tenants.context'
import { ProductosVendedorContext, Estado, TABS } from '../context/productos.vendedor.context'
import { IPalabrasProhibidas } from '../hooks/palabras.prohibidas.hook'
import { ErrorPP, defaultErrorTitulo } from './CreateProduct'
import { Rubro } from '../../Integraciones/types/responses-productos'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const MAX_NOMBRE_LENGTH = 85
const MAX_DESC_LENGTH = 1000
const MAX_SKU_LENGTH = 45
const MAX_MIN_WHOLESALE_LENGTH = 75

export interface NewProductProps {
  getProducts: (key: number) => Promise<void>
  open: boolean
  setOpen: (open: boolean) => void
  admin: boolean
  precioDto: any
  retailer: any
  codLocal: any
  maxPhotos: number
  editProductCode?: string
  esMinorista: boolean
  onClose?: () => void
  onConfirm?: () => Promise<void>
  handlePauseProduct?: (productCode: any) => void
  usaSku?: boolean
  ofrecerPremium: boolean
  palabrasProhibidas: IPalabrasProhibidas[]
  rubros: Rubro[]
  cantOfertasDisp?: number
  cantProducts?: number
}

const NewProduct = ({
  getProducts,
  open,
  setOpen,
  admin,
  precioDto,
  retailer,
  codLocal,
  maxPhotos,
  editProductCode,
  esMinorista,
  onClose,
  onConfirm,
  handlePauseProduct,
  usaSku,
  ofrecerPremium,
  palabrasProhibidas,
  rubros
}: NewProductProps) => {
  const isEdit = () => (editProduct ? true : false)
  const { estado } = useContext(ProductosVendedorContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)

  // PRODUCT SAVE
  const [value, setValue] = useState<any>(moment('2014-08-18T21:11:54'))
  const [images, setImages] = useState<any[]>([])
  const [editProduct, setEditProduct] = useState<any>(null)
  const [fechaPublicacion, setFechaPublicacion] = useState<Dayjs | null>(null)
  const [fechaVencimiento, setFechaVencimiento] = useState<Dayjs | null>(null)
  const [canRetailSell, setCanRetailSell] = useState(true)
  const [disableRetailPrice, setDisableRetailPrice] = useState(false)
  const [unitsPerRubro, setUnitsPerRubro] = useState<any>([])
  const [monedasPorRubro, setMonedasPorRubro] = useState<any>([])
  const [monedaSeleccionada, setMonedaSeleccionada] = useState<any>()
  const [precioMinimo, setPrecioMinimo] = useState<number>(0)
  const [codigoSKU, setCodigoSKU] = useState<string>('')
  const [showTooltip, setShowTooltip] = useState(false)
  const [unidadDeMedida, setUnidadDeMedida] = useState<any>('')
  const [loadingEstado, setLoadingEstado] = useState(false)
  const [loadingEditProduct, setLoadingEditProduct] = useState(false)
  const [openPausarProducto, setOpenPausarProducto] = useState(false)
  const [quierePausarProducto, setQuierePausarProducto] = useState(false)
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [tituloAgotado, setTituloAgotado] = useState<boolean>(false)
  const [codigoSkuEnUso, setCodigoSkuEnUso] = useState<boolean>(false)
  const [tituloProhibido, setTituloProhibido] = useState<ErrorPP>(defaultErrorTitulo)
  const { tenant } = useContext(TenantsContext)

  const handleUnidadSeleccionadaChange = async (e: any) => {
    const rubro = admin ? editProduct.codRubro : editProduct.codRubro
    const unDeMedida = findUnidadDeMedida(parseInt(e.target.value), rubro, rubros)

    setEditProduct({
      ...editProduct,
      idUnidadDeMedida: parseInt(e.target.value)
    })

    if (unDeMedida) {
      setUnidadDeMedida(unDeMedida.nombreSingular.toLowerCase())
    }
  }

  const getEditProduct = async () => {
    setLoadingEditProduct(true)
    try {
      if (editProductCode) {
        const res = await getProduct(editProductCode, tenant)
        setEditProduct(res.data.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingEditProduct(false)
    }
  }

  const handlePalabrasProhibidas = (input: string) => {
    const titulo = input
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    palabrasProhibidas.map((group: IPalabrasProhibidas) => {
      const accion = group.accion
      const msj = group.mensaje
      group.palabraList.map((p: string) => {
        const palabra = p
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
        if (titulo.includes(` ${palabra} `) || titulo.startsWith(`${palabra} `) || titulo.endsWith(` ${palabra}`)) {
          const error: ErrorPP = {
            mensaje: msj.replace('{palabra}', p),
            error: accion === 'bloquear',
            warning: accion === 'advertir'
          }
          setTituloProhibido(error)
        }
      })
    })
  }
  const handleTitulo = (titulo: string) => {
    const agotado = titulo.toLowerCase().includes('agotad')
    setTituloAgotado(agotado)
  }

  useEffect(() => {
    if (editProductCode && open) {
      setLoading(true)
      getProduct(editProductCode, tenant)
        .then((res) => {
          setEditProduct(res.data.data)
          setImages(res.data.data.fotosProducto || [])
          setMonedaSeleccionada(res.data.data.moneda?.isoCode)
        })
        .finally(() => setLoading(false))
    }
  }, [editProductCode, open])

  const schema: yup.AnyObjectSchema = yup.object({
    productName: yup
      .string()
      .test('isNotProhibited', tituloProhibido.mensaje, function () {
        return !tituloProhibido.error
      })
      .max(MAX_NOMBRE_LENGTH, `El título no puede tener más de ${MAX_NOMBRE_LENGTH} caracteres`)
      .required('El título es obligatorio'),
    skuCode: yup.string().max(MAX_SKU_LENGTH, `El código SKU no puede tener más de ${MAX_SKU_LENGTH} caracteres`),
    price: yup
      .number()
      .typeError('el monto debe ser un número')
      .transform((_, value) => {
        if (value === '') return null
        if (typeof value === 'string') {
          // Elimina los puntos solo si son separadores de miles
          value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
          value = parseFloat(value)
        }
        return +value
      })
      .positive('El precio debe ser positivo y mayor a cero')
      .required('El precio es un atributo necesario')
      .min(precioMinimo, 'El precio debe ser el verdadero o no se aprobará la publicación'),
    previusPrice:
      precioDto &&
      yup
        .number()
        .typeError('el monto debe ser un número')
        .nullable()
        .transform((_, value) => {
          if (value === '') return null
          if (typeof value === 'string') {
            value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
            value = parseFloat(value)
          }
          return +value
        })
        //maximo 6 digitos
        .max(999999, 'El precio anterior no puede tener más de 6 digitos')
        .min(0, 'El precio debe ser positivo o igual a cero'),
    description: yup
      .string()
      .max(MAX_DESC_LENGTH, `La descripción no puede tener más de ${MAX_DESC_LENGTH} caracteres`),
    minWholesale: yup
      .string()
      .required('Debes indicar la cantidad minima por mayor')
      .max(MAX_MIN_WHOLESALE_LENGTH, `Este campo no puede tener más de ${MAX_MIN_WHOLESALE_LENGTH} caracteres`),
    retailPrice:
      retailer &&
      canRetailSell &&
      yup
        .number()
        .typeError('el monto debe ser un número')
        .nullable()
        .transform((_, value) => {
          if (value === '') return null
          if (typeof value === 'string') {
            value = value.replace(/\.(?=\d{3}([.,]|$))/g, '').replace(',', '.')
            value = parseFloat(value)
          }
          return +value
        })
        .min(0, 'El precio debe ser positivo o igual a cero')
        //maximo 6 digitos
        .max(999999, 'El precio minorista no puede tener más de 6 digitos')
        .typeError('El precio minorista debe ser un número'),
    waist: yup
      .string()
      .max(MAX_MIN_WHOLESALE_LENGTH, `Este campo no puede tener más de ${MAX_MIN_WHOLESALE_LENGTH} caracteres`)
    // .required("El precio minorista es obligatorio"),
  })

  const handleChangeRubro = async (e: any) => {
    const rubro = admin ? parseInt(e.target.value) : e.target.value
    const rubroEncontrado: any = findRubro(rubro, rubros)
    setUnitsPerRubro(rubroEncontrado.unidadDeMedidaList)
    setMonedasPorRubro(rubroEncontrado.monedaList)
    setMonedaSeleccionada(rubroEncontrado.monedaList[0].isoCode)
    setPrecioMinimo(rubroEncontrado?.precioMinimo)

    setEditProduct({
      ...editProduct,
      codRubro: rubro || null,
      idUnidadDeMedida: rubroEncontrado.unidadDeMedidaList[0].idUnidadDeMedida
    })
  }

  useEffect(() => {
    ;(async () => {
      if (editProduct) {
        setCanRetailSell(editProduct.precioMinorista > 0)
        const unDeMedida = findUnidadDeMedida(editProduct.idUnidadDeMedida, editProduct.codRubro, rubros)
        const rubroFound = findRubro(editProduct.codRubro, rubros)
        if (rubroFound) {
          setMonedasPorRubro(rubroFound.monedaList)
          setUnitsPerRubro(rubroFound.unidadDeMedidaList)
          setUnidadDeMedida(unDeMedida.nombreSingular.toLowerCase())
        }
        if (editProduct.fechaPublicacion) {
          setFechaPublicacion(dayjs(editProduct.fechaPublicacion))
        }
        if (editProduct.fechaVencimiento) {
          setFechaVencimiento(dayjs(editProduct.fechaVencimiento))
        }
      }
    })()
  }, [editProduct, rubros, unitsPerRubro])

  useEffect(() => {
    if (quierePausarProducto) {
      setOpenPausarProducto(false)
      handlePauseProduct && handlePauseProduct(editProductCode)
      handleClose()
    }
  }, [quierePausarProducto])

  const validarSku = async () => {
    const codigoEnUso = await skuEnUso(codLocal, codigoSKU, tenant)
    if (codigoEnUso?.data) {
      setCodigoSkuEnUso(true)
      SnackBarUtils.warning('El código/SKU está en uso')
    } else {
      setCodigoSkuEnUso(false)
    }
  }

  const handleChange = (newValue: any) => setValue(newValue)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  })

  const onSubmit = async (data: any) => {
    const photos: Image[] = []

    images?.forEach((img: any, i: number) => {
      const CHUNK_SIZE = 65536 // Tamaño de cada fragmento
      const uint8Array = new Uint8Array(img.bin)
      const chunks = []
      for (let i = 0; i < uint8Array.length; i += CHUNK_SIZE) {
        chunks.push(uint8Array.subarray(i, i + CHUNK_SIZE))
      }
      const str = chunks.map((chunk) => String.fromCharCode(...chunk)).join('')
      const b64 = btoa(str)

      photos.push({
        desc: encodeURIComponent(img.desc),
        idfotoProducto: img.idfotoProducto,
        imagenBase64: b64
      })
    })

    const updatedProduct = {
      nombre: editProduct.nombre,
      listaImagenes: photos,
      codLocal: codLocal,
      codRubro: editProduct.codRubro,
      sku: editProduct.sku,
      precio: editProduct.precio,
      precioSinDto: editProduct.precioSinDto ?? 0,
      aclaracionPrecio: editProduct.informacionPrecio,
      precioMinorista: canRetailSell ? editProduct.precioMinorista : 0,
      detalle: editProduct.descripcion,
      talles: editProduct.talles,
      idUnidadDeMedida: parseInt(editProduct.idUnidadDeMedida),
      isoCodeMoneda: monedaSeleccionada,
      imei: '0',
      versionAndroid: '0',
      modeloDisp: '',
      versionApp: '0'
    }

    if (images.length > maxPhotos) {
      return SnackBarUtils.error(`Solo podés subir ${maxPhotos} fotos`)
    } else if (images.length === 0) {
      return SnackBarUtils.error(`Debes subir al menos una foto`)
    }

    try {
      !codigoSkuEnUso && setLoading(true)
      const response = await updateProduct(updatedProduct, editProductCode, tenant)
      if (response.data.errorCode !== 0) {
        return SnackBarUtils.error(response.data.message)
      } else {
        codigoSkuEnUso && setLoading(true)
        SnackBarUtils.success('Producto actualizado con éxito')
      }
      await getEditProduct()
      await getProducts(estado.key)
    } catch (e) {
      console.log(e)
      SnackBarUtils.error('Error al actualizar el producto')
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const handleClose = async () => {
    if (onClose) onClose()

    setOpen(false)
    setUnidadDeMedida('')
    // setUnidadSeleccionada("");
    setUnitsPerRubro([])
    setEditProduct(null)
    setTituloProhibido(defaultErrorTitulo)
    reset()
  }

  const handleTooltipClose = () => setShowTooltip(false)
  const handleTooltipOpen = () => setShowTooltip(true)

  if (rubros.length === 0) return <></>

  if (loading || loadingEditProduct || (unitsPerRubro?.length > 1 && !editProduct)) return <></>

  return (
    <>
      <Dialog maxWidth={isMobile ? 'lg' : 'sm'} fullWidth fullScreen={isMobile} open={open} onClose={handleClose}>
        {editProduct && (
          <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'hidden' }}>
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              Edición de producto
              {open ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500]
                  }}
                >
                  <Close />
                </IconButton>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
                <Stack sx={{ width: '100%' }} onBlur={() => handlePalabrasProhibidas(editProduct.nombre)}>
                  <InputLabel
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    Título del producto
                    <Typography variant="subtitle2">
                      {editProduct.nombre?.length || 0}/{MAX_NOMBRE_LENGTH}
                    </Typography>
                  </InputLabel>
                  <TextField
                    sx={{ width: '100%' }}
                    value={editProduct.nombre}
                    error={!!errors.productName}
                    helperText={
                      (errors?.productName && String(errors.productName.message)) ||
                      (!tituloProhibido.error && tituloProhibido.warning && (
                        <span style={{ color: 'blue' }}>{tituloProhibido.mensaje}</span>
                      ))
                    }
                    variant="outlined"
                    {...register('productName')}
                    disabled={loading}
                    onChange={(e) => {
                      setCanSubmit(false)
                      setEditProduct({
                        ...editProduct,
                        nombre: e.target.value
                      })
                      handleTitulo(e.target.value)
                    }}
                    inputProps={{ maxLength: MAX_NOMBRE_LENGTH }}
                  />
                </Stack>
                {admin && isEdit() && (
                  <Stack sx={{ width: '60%' }}>
                    <InputLabel>CODE</InputLabel>
                    <TextField variant="outlined" value={editProduct.codProducto} disabled />
                  </Stack>
                )}
                {admin && isEdit() && (
                  <Stack sx={{ width: '100%' }}>
                    <InputLabel>Estado</InputLabel>
                    <TextField value={getStatusLabel(editProduct)} variant="outlined" disabled />
                  </Stack>
                )}
              </Stack>
              {usaSku && (
                <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
                  <Stack sx={{ width: '100%' }}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      Código del Producto (SKU)
                      <Typography variant="subtitle2">
                        {editProduct.sku?.length || 0}/{MAX_SKU_LENGTH}
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ width: '100%' }}
                      value={editProduct.sku || ''}
                      error={!!errors.skuCode}
                      helperText={errors?.skuCode && String(errors?.skuCode?.message)}
                      variant="outlined"
                      {...register('skuCode')}
                      disabled={loading}
                      onChange={(e) => {
                        setEditProduct({
                          ...editProduct,
                          sku: e.target.value
                        })
                        setCodigoSKU(e.target.value)
                      }}
                      onBlur={validarSku}
                      inputProps={{ maxLength: MAX_SKU_LENGTH }}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack direction={isMobile ? 'column' : 'row'} sx={{ margin: '20px 0' }} spacing="20px">
                <Stack sx={{ width: '100%' }}>
                  <InputLabel>Rubro</InputLabel>
                  <Select native defaultValue={editProduct.codRubro} disabled={loading} onChange={handleChangeRubro}>
                    <option aria-label="None" disabled value="" label="Seleccione" />
                    {rubros &&
                      rubros?.map((group: any, i: number) => (
                        <optgroup key={i} label={group.nombre.toUpperCase()}>
                          {group.rubros.map((subrubro: any, h: number) => {
                            return subrubro.rubros?.length > 0 ? (
                              subrubro.rubros.map((subrubro2: any, j: number) => (
                                <option key={j} value={admin ? subrubro2.codRubro : subrubro2.codRubro}>
                                  {subrubro.nombre + ' - ' + subrubro2.nombre}
                                </option>
                              ))
                            ) : (
                              <option key={h} value={admin ? subrubro.codRubro : subrubro.codRubro}>
                                {subrubro.nombre}
                              </option>
                            )
                          })}
                        </optgroup>
                      ))}
                  </Select>
                </Stack>
                {unitsPerRubro?.length > 1 && (
                  <Stack sx={{ width: '100%' }}>
                    <InputLabel>Unidad de medida</InputLabel>
                    <Select
                      native
                      defaultValue={editProduct.idUnidadDeMedida}
                      disabled={loading}
                      onChange={handleUnidadSeleccionadaChange}
                    >
                      <option disabled value="" label="Seleccione">
                        Seleccione
                      </option>
                      {unitsPerRubro.map((units: any, i: number) => (
                        <option
                          key={units.idUnidadDeMedida + i}
                          value={units.idUnidadDeMedida}
                          label={units.nombreSingular + '/' + units.nombrePlural}
                        >
                          {units.nombreSingular + '/' + units.nombrePlural}
                        </option>
                      ))}
                    </Select>
                  </Stack>
                )}
                {monedasPorRubro?.length > 1 && (
                  <Stack sx={{ width: '100%' }}>
                    <InputLabel>Moneda</InputLabel>
                    <Select
                      native
                      disabled={loading}
                      onChange={(e: any) => {
                        setMonedaSeleccionada(e.target.value)
                      }}
                      value={monedaSeleccionada}
                    >
                      <option disabled value="" label="Seleccione">
                        Seleccione
                      </option>
                      {monedasPorRubro.map((units: any) => (
                        <option key={units.isoCode} value={units.isoCode} label={`${units.nombre} (${units.simbolo})`}>
                          {`${units.nombre} (${units.simbolo})`}
                        </option>
                      ))}
                    </Select>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction={isMobile ? 'column' : precioDto ? 'column' : 'row'}
                spacing="20px"
                sx={{ width: '100%', margin: '20px 0' }}
              >
                <Stack direction={'row'} spacing="20px">
                  <Stack>
                    <InputLabel>Precio mayorista (por {unidadDeMedida})</InputLabel>
                    <TextField
                      prefix={'$'}
                      variant="outlined"
                      error={!!errors.price}
                      helperText={errors?.price && String(errors?.price?.message)}
                      value={editProduct.precio}
                      disabled={loading}
                      {...register('price')}
                      onChange={(e) => {
                        setEditProduct({
                          ...editProduct,
                          precio: e.target.value
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {monedasPorRubro.length > 0 &&
                              monedasPorRubro.find((moneda: any) => moneda.isoCode === monedaSeleccionada)?.simbolo}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Stack>

                  {precioDto && (
                    <Stack sx={{ width: isMobile ? '100%' : '50%' }}>
                      <InputLabel
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '12px'
                        }}
                      >
                        Precio anterior
                        <AiFillInfoCircle
                          style={{
                            fontSize: '20px',
                            color: '#8882f3',
                            cursor: 'pointer'
                          }}
                        />
                      </InputLabel>
                      <TextField
                        prefix={'$'}
                        variant="outlined"
                        error={!!errors.previusPrice}
                        helperText={errors?.previusPrice && String(errors?.previusPrice?.message)}
                        value={editProduct.precioSinDto !== 0 ? editProduct.precioSinDto : ''}
                        disabled={loading}
                        {...register('previusPrice')}
                        onChange={(e) => {
                          setEditProduct({
                            ...editProduct,
                            precioSinDto: e.target.value
                          })
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {monedasPorRubro.length > 0 &&
                                monedasPorRubro.find((moneda: any) => moneda.isoCode === monedaSeleccionada)?.simbolo}
                            </InputAdornment>
                          )
                        }}
                      />
                    </Stack>
                  )}
                </Stack>

                <Stack sx={{ width: isMobile ? '100%' : precioDto ? '100%' : '50%' }}>
                  <InputLabel
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    Compra mínima por mayor
                    <Typography variant="subtitle2">
                      {editProduct.informacionPrecio?.length || 0}/{MAX_MIN_WHOLESALE_LENGTH}
                    </Typography>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Mínimo 3 unidades"
                    error={!!errors.minWholesale}
                    disabled={loading}
                    value={editProduct.informacionPrecio}
                    helperText={errors?.minWholeSale && String(errors?.minWholeSale?.message)}
                    {...register('minWholesale')}
                    onChange={(e) => {
                      setEditProduct({
                        ...editProduct,
                        informacionPrecio: e.target.value
                      })
                    }}
                    inputProps={{ maxLength: MAX_MIN_WHOLESALE_LENGTH }}
                  />
                </Stack>
              </Stack>
              {esMinorista && (
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    gap: '8px',
                    width: '100%',
                    margin: '20px 0'
                  }}
                >
                  <Stack sx={{ width: '100%' }}>
                    <InputLabel>
                      Precio minorista (
                      {unitsPerRubro?.length > 1
                        ? 'por ' + unidadDeMedida
                        : unitsPerRubro?.length > 0
                        ? 'por ' + unitsPerRubro[0].nombreSingular.toLowerCase()
                        : 'por unidad'}
                      )
                      <Tooltip
                        open={showTooltip}
                        onClose={handleTooltipClose}
                        onOpen={handleTooltipOpen}
                        disableFocusListener
                        title={`Si el comprador indicó que compra solo por menor, le mostraremos este precio. Si no vendés por menor, tocá ${
                          !retailer ? 'en la X' : 'en el botón'
                        } a la derecha del cuadro de texto`}
                      >
                        <IconButton onClick={handleTooltipOpen}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      error={!!errors.retailPrice}
                      helperText={errors?.retailPrice && String(errors?.retailPrice?.message)}
                      disabled={!canRetailSell || loading}
                      sx={
                        !canRetailSell
                          ? {
                              backgroundColor: '#8d8d8d'
                            }
                          : {}
                      }
                      value={!canRetailSell ? '' : editProduct.precioMinorista}
                      {...register('retailPrice')}
                      onChange={(e) => {
                        setEditProduct({
                          ...editProduct,
                          precioMinorista: e.target.value
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {monedasPorRubro.length > 0 &&
                              monedasPorRubro.find((moneda: any) => moneda.isoCode === monedaSeleccionada)?.simbolo}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Stack>

                  <Button
                    variant="contained"
                    onClick={() => {
                      if (canRetailSell === false) {
                        setCanRetailSell(true)
                      } else {
                        setDisableRetailPrice(true)
                      }
                    }}
                    sx={{ alignSelf: 'flex-end', width: '75%' }}
                  >
                    {canRetailSell ? 'No vender por menor' : 'Vender por menor'}
                  </Button>
                </Stack>
              )}
              <Stack sx={{ margin: '20px 0' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Talles
                  <Typography variant="subtitle2">
                    {editProduct.talles?.length || 0}/{MAX_MIN_WHOLESALE_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  variant="outlined"
                  error={!!errors.waist}
                  placeholder="Del 1 al 6, Talle único, etc"
                  helperText={errors?.waist && String(errors?.waist?.message)}
                  disabled={loading}
                  value={editProduct.talles}
                  {...register('waist')}
                  onChange={(e) => {
                    setEditProduct({
                      ...editProduct,
                      talles: e.target.value
                    })
                  }}
                  inputProps={{ maxLength: MAX_MIN_WHOLESALE_LENGTH }}
                />
              </Stack>
              <Stack sx={{ width: '100%', margin: isMobile ? '0' : '10px 0' }}>
                <InputLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  Descripción del producto
                  <Typography variant="subtitle2">
                    {editProduct.descripcion?.length || 0}/{MAX_DESC_LENGTH}
                  </Typography>
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  multiline
                  placeholder="No olvides ninguna información! Ej colores, material, etc"
                  rows={3}
                  disabled={loading}
                  error={!!errors.description}
                  helperText={errors?.description && String(errors?.description?.message)}
                  value={editProduct.descripcion}
                  {...register('description')}
                  onChange={(e) => {
                    setEditProduct({
                      ...editProduct,
                      descripcion: e.target.value
                    })
                  }}
                  inputProps={{ maxLength: MAX_DESC_LENGTH }}
                />
              </Stack>

              {isEdit() && (
                <Stack spacing={3} sx={{ margin: '10px 0' }} direction={'column'}>
                  {admin && (
                    <>
                      <Stack>
                        <InputLabel>Fecha publicación</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label=""
                            format="DD/MM/YYYY"
                            value={fechaPublicacion}
                            onChange={handleChange}
                            slotProps={{
                              textField: {
                                variant: 'outlined'
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                      <Stack>
                        <InputLabel>Fecha vencimiento</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label=""
                            format="DD/MM/YYYY"
                            value={fechaVencimiento}
                            onChange={handleChange}
                            slotProps={{
                              textField: {
                                variant: 'outlined'
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </>
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      gap: '12px'
                    }}
                  >
                    {admin && editProduct.shareLinkMinorista && (
                      <InputLabel sx={{ display: 'flex', gap: '5px' }}>
                        Link minorista:
                        <Link
                          component="button"
                          variant="body1"
                          onClick={() => {
                            console.info("I'm a button.")
                          }}
                        >
                          {editProduct.shareLinkMinorista}
                          <ContentCopy
                            onClick={() => copyToClipboard(editProduct.shareLinkMinorista)}
                            sx={{ marginLeft: '5px', cursor: 'ponter' }}
                          />
                        </Link>
                      </InputLabel>
                    )}
                  </Box>
                </Stack>
              )}
              <Stack sx={{ mt: 3 }}>
                <InputLabel>
                  Fotos{' '}
                  {images.length > 0 && (
                    <span
                      style={{
                        color: images.length >= maxPhotos ? 'red' : undefined
                      }}
                    >
                      ({images.length} de {maxPhotos})
                    </span>
                  )}
                </InputLabel>
                {/* <Camera /> */}
                <Dropzone maxPhotos={maxPhotos} images={images} setImages={setImages} ofrecerPremium={ofrecerPremium} />
              </Stack>
            </DialogContent>

            <DialogActions
              sx={{
                diplay: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-end',
                justifyContent: 'space-between',
                width: '100%',
                padding: '22px',
                paddingTop: 0,
                gap: '8px',
                position: 'relative'
              }}
            >
              <Button color="inherit" onClick={handleClose}>
                Volver
              </Button>
              <Stack direction="row" spacing={1}>
                {/* <Button
                  variant="outlined"
                  onClick={handlePausarOReanudar}
                  color={editProduct.pausado ? "success" : "inherit"}
                  disabled={loading || loadingEstado}
                >
                  {editProduct.pausado
                    ? loadingEstado
                      ? "Reanudando"
                      : "Reanudar"
                    : loadingEstado
                    ? "Pausando"
                    : "Pausar"}
                </Button> */}

                {canSubmit ? (
                  <Button variant="contained" type="submit" disabled={loading}>
                    {editProduct.vencido
                      ? loading
                        ? 'Reanudando'
                        : 'Reanudar'
                      : loading
                      ? 'Modificando'
                      : 'Modificar'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    //type="submit"
                    disabled={loading}
                    onClick={() => (tituloAgotado ? setOpenPausarProducto(true) : setCanSubmit(true))}
                  >
                    {editProduct.vencido
                      ? loading
                        ? 'Reanudando'
                        : 'Reanudar'
                      : loading
                      ? 'Modificando'
                      : 'Modificar'}
                  </Button>
                )}
              </Stack>
            </DialogActions>
          </form>
        )}
      </Dialog>
      <DisableRetailPrice
        open={disableRetailPrice}
        openStatus={setDisableRetailPrice}
        setCanRetailSell={setCanRetailSell}
      />
      <Dialog open={openPausarProducto}>
        <DialogContent>
          <DialogPausarProducto
            quierePausarProducto={setQuierePausarProducto}
            openDialog={setOpenPausarProducto}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isMobile={isMobile}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default memo(NewProduct)
