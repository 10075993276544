import React, { useState, useCallback, memo, useEffect, useContext } from 'react'

// components
import { Box, Button, Divider, useTheme, useMediaQuery, Typography } from '@mui/material'

// redux
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import Navbar from '../../components/Navbar'
import ToolbarHome from './components/Toolbar'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import Elementos from './components/Elementos'
import Anuncios from './components/Anuncios'
import { useAnuncios } from './hooks/anuncios.hook'
import { useLocal as findLocal } from '../AbmLocalPage/hooks/local.hook'
import useLocal from '../Products/hooks/locals.hook'
import { StatusHistorias } from '../AdminLocal/types'
import { GetStatus } from '../AdminLocal/services/historias.service'
import CreateProduct from '../Products/widgets/CreateProduct'
import { useNavigate } from 'react-router'
import Footer from './components/Footer'
import { useMetricas } from './hooks/metricas.hook'
import SnackBarUtils from '../../utils/SnackBarUtils'
import usePalabrasProhibidas from '../Products/hooks/palabras.prohibidas.hook'
import { ProductosVendedorContext } from '../Products/context/productos.vendedor.context'
import { TenantsContext } from '../../context/tenants.context'
import { RolType } from '../../components/Navbar/Sidebar/SidebarItems'
import useRubrosLocal from '../Products/hooks/rubros.hook'

const Home: React.FC = (props: any) => {
  //console.log(props.logged.userLogged)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { idLocal, codLocal, token } = props.logged.userLogged
  const { local } = findLocal(codLocal)
  const { retail, maxPhotos, maxPublicaciones, esMinorista, precioDto, ofrecerPremium, usaSku } = useLocal(
    codLocal,
    token
  )
  const { rubros } = useRubrosLocal()
  const { anuncios } = useAnuncios(codLocal)
  const { metricas } = useMetricas(codLocal)
  const { palabrasProhibidas } = usePalabrasProhibidas()
  const navigate = useNavigate()
  const [newProduct, setNewProduct] = useState<boolean>(false)
  const [estado, setEstado] = useState<StatusHistorias | null>(null)
  const { productos, getProductosVendedor, setCodLocal } = useContext(ProductosVendedorContext)
  const { tenant } = useContext(TenantsContext)

  useEffect(() => {
    codLocal && setCodLocal(codLocal)
    ;(async () => {
      try {
        await GetStatus(codLocal, tenant).then((res) => {
          setEstado(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }, [codLocal])

  if (!metricas) return <></>

  return (
    <>
      <Navbar title={`Hola ${local?.nombre || ''}!`} />
      {anuncios && <Anuncios anuncios={anuncios} isMobile={isMobile} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F5F5F5',
          marginBottom: isMobile ? '70px' : '20px',
          ...(isMobile && { borderRadius: '20px' })
        }}
      >
        <ToolbarHome isMobile={isMobile} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: isMobile ? '100%' : '60%',
            margin: '0 auto',
            gap: isMobile ? '10px' : '20px',
            marginTop: isMobile ? '50px' : '20px',
            marginBottom: isMobile ? '0px' : '20px',
            ...(isMobile && { paddingRight: '10px', paddingLeft: '10px' })
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{
              gap: isMobile ? '10px' : '20px',
              padding: isMobile ? '6px 10px' : '20px 50px',
              width: '50%',
              borderRadius: '15px',
              flexDirection: isMobile ? 'column' : 'row',
              border: '2px solid',
              backgroundColor: '#5983EF',
              '&:hover': {
                backgroundColor: '#1346C7'
              },
              boxShadow: '0px 4px 4px 0px #00000040'
            }}
            onClick={() => {
              if (metricas?.cantOfertasDisp <= 0 && !ofrecerPremium) {
                SnackBarUtils.info(`Alcanzaste el límite de ${maxPublicaciones} publicaciones`)
              } else {
                setNewProduct(true)
              }
            }}
          >
            <AddBoxOutlinedIcon fontSize="large" />
            <Typography
              style={{
                fontWeight: 800,
                textAlign: isMobile ? 'center' : 'start',
                fontSize: isMobile ? '16px' : '20px',
                fontFamily: 'Plus Jakarta Sans',
                lineHeight: isMobile ? '20.16px' : '25.2px'
              }}
            >
              Publicar nuevo producto
            </Typography>
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{
              gap: isMobile ? '10px' : '20px',
              padding: isMobile ? '6px 10px' : '20px 50px',
              width: '50%',
              borderRadius: '15px',
              flexDirection: isMobile ? 'column' : 'row',
              color: '#5983EF',
              border: '2px solid',
              backgroundColor: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#E3EAFC'
              },
              boxShadow: '0px 4px 4px 0px #00000040'
            }}
            onClick={() => navigate('/abm-products')}
          >
            <RemoveRedEyeOutlinedIcon fontSize="large" />
            <Typography
              style={{
                fontWeight: 800,
                textAlign: isMobile ? 'center' : 'start',
                fontSize: isMobile ? '16px' : '20px',
                fontFamily: 'Plus Jakarta Sans',
                lineHeight: isMobile ? '20.16px' : '25.2px'
              }}
            >
              Ver mis publicaciones
            </Typography>
          </Button>
        </Box>
        {!isMobile && <Divider sx={{ width: '70%', margin: '0 auto' }} />}
        {metricas && estado && <Elementos isMobile={isMobile} metricas={metricas} estado={estado} />}
      </Box>
      {metricas && estado && <Footer />}
      <CreateProduct
        admin={false}
        open={newProduct}
        setOpen={setNewProduct}
        palabrasProhibidas={palabrasProhibidas}
        precioDto={precioDto}
        retailer={retail}
        //wholesaler={wholesaler}
        rubros={rubros}
        maxPhotos={maxPhotos}
        codLocal={codLocal}
        getProducts={getProductosVendedor}
        esMinorista={esMinorista}
        cantOfertasDisp={metricas?.cantOfertasDisp}
        cantProducts={productos.length}
        usaSku={usaSku}
        ofrecerPremium={ofrecerPremium}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Home))
