import { Box, Typography } from '@mui/material'
import { formatDate } from '../../../../utils/utils'

const Fechas = ({product}: {product: any}) => {
  return (
    <Box sx={{marginBottom: "-20px"}}>
      {
        product.fechaVencimiento < new Date() ?
          <Typography variant="body2" sx={{ fontWeight: 'bold' }} component="h2">
            Vencido el {formatDate(product.fechaVencimiento)}
          </Typography>
          :
          <Typography variant="body2" sx={{ fontWeight: 'bold' }} component="h2">
            Vence el {formatDate(product.fechaVencimiento)}
          </Typography>
      }
      <Typography variant="body2" sx={{ fontWeight: 'bold' }} component="h2">
        Publicado el {formatDate(product.fechaPublicacion)}
      </Typography>
    </Box>
  )
}

export default Fechas
