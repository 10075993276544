import React, { useEffect, useRef, useState } from 'react'
import '../../styles/contractsRenovations.scss'
import { axiosInstanceContratos } from '../../utils/axiosGlobalUtils'
import * as GrIcons from 'react-icons/gr'
import SnackBarUtils from '../../utils/SnackBarUtils'
import { CircularProgress } from '@mui/material'

const AdminLocalContractsRenovations: React.FC<any> = (props: any) => {
  const [loadingAccion, setLoadingAccion] = useState<number | null>(null)
  const forzarRenovables = props.forzarRenovables

  const showHideClassName = props.show
    ? 'modal display-block'
    : 'modal display-none'

  // const [actions, setActions] = useState<any[]>([]);

  // useEffect(() => {
  //   if (props.contract) {
  //     setActions(props.contract.accionesAdminList);
  //   }
  // }, [props.contract]);

  const closeRenovations = () => {
    // setActions([]);
    setLoadingAccion(null)
    props.handleClose()
  }

  const executeAction = (
    url: string,
    reqMethod: string,
    id: number,
    payload: any
  ) => {
    const callback = (result: any) => {
      SnackBarUtils.info(`Resultado de la acción: ${result.data.message}`)
      props.updateContractList && props.updateContractList(id)
      props.getLocal && props.getLocal(props.idLocal, forzarRenovables)
      props.updateLocal && props.updateLocal(props.idLocal)
    }
    if (reqMethod === 'GET')
      axiosInstanceContratos
        .get(url)
        .then(callback)
        .catch((e) => {
          SnackBarUtils.error(e.response.data)
          console.log(e)
        })
        .finally(closeRenovations)
    else if (reqMethod === 'POST')
      axiosInstanceContratos
        .post(url, payload)
        .then(callback)
        .catch((e) => {
          SnackBarUtils.error(e.response.data)
          console.log(e)
        })
        .finally(closeRenovations)
    else if (reqMethod === 'PUT')
      axiosInstanceContratos
        .put(url)
        .then(callback)
        .catch((e) => {
          SnackBarUtils.error(e.response.data)
          console.log(e)
        })
        .finally(closeRenovations)
    else if (reqMethod === 'DELETE')
      axiosInstanceContratos
        .delete(url)
        .then(callback)
        .catch((e) => {
          SnackBarUtils.error(e.response.data)
          console.log(e)
        })
        .finally(closeRenovations)
  }

  return (
    <div className={showHideClassName} /*  onClick={closeRenovations} */>
      <section className="modal-main">
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">
            {(props.contract?.cantidad > 1
              ? props.contract?.productoNombre + 'x' + props.contract?.cantidad
              : props.contract?.productoNombre) +
              ` Local ID ${props.contract?.idContratoClienteLocal}`}
          </h3>
          <button
            className="modal-close"
            onClick={closeRenovations}
            aria-label="Cerrar"
          >
            <GrIcons.GrClose />
          </button>
        </header>
        {props.children}
        <div>
          <div
            className="btn-toolbar"
            role="toolbar"
            style={{ display: 'flex', gap: '10px' }}
          >
            {props.contract &&
              props.contract.accionesAdminList &&
              props.contract.accionesAdminList.map((a: any, index: number) => {
                return (
                  <div key={a.boton} className="btn-group mr-2" role="group">
                    <button
                      //disabled={loadingAccion === index}
                      className="btn contract-btn btn-lg btn-block"
                      onClick={() => {
                        setLoadingAccion(index)
                        executeAction(
                          a.url,
                          a.requestMethod,
                          props.contract.idContrato,
                          a.payload
                        )
                      }}
                      style={{
                        backgroundColor: `${a.color}`,
                        borderColor: 'black ', //`${a.color}`,
                        pointerEvents: loadingAccion === index ? 'none' : 'auto'
                      }}
                    >
                      {loadingAccion === index ? (
                        <CircularProgress
                          style={{ width: '20px', height: '20px' }}
                        />
                      ) : (
                        a.boton
                      )}
                    </button>
                  </div>
                )
              })}
          </div>
        </div>
      </section>
    </div>
  )
}

export default AdminLocalContractsRenovations
