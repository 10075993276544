import { Box, Button, Card, CardActions, CardContent, FormControl, Grid, TextField, Typography } from "@mui/material"
import { postLocalType } from "../../types"
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, useFieldArray, FieldErrors, UseFormSetError } from "react-hook-form"
import { Fragment, useState } from "react"
import AlertDialog from "../../../../components/Common/Dialog"
import SectionTitle from "../../common/SectionTitle"
import { Asterisco } from "."

interface FormFaqProps {
  control: Control<postLocalType>
  register: UseFormRegister<postLocalType>
  setValue: UseFormSetValue<postLocalType>
  getValues: UseFormGetValues<postLocalType>
  setError: UseFormSetError<postLocalType>
  errors: FieldErrors<postLocalType>
  isSubmitting: boolean
}

const MAX_LENGTH_PREGUNTA = 100
const MAX_LENGTH_RESPUESTA = 500

const FormFaq = ({isSubmitting, control, register, setValue, getValues, errors, setError}: FormFaqProps) => {
  const [contadoresPregunta, setContadoresPregunta] = useState<number[]>([])
  const [contadoresRespuesta, setContadoresRespuesta] = useState<number[]>([])
  const [open, setOpen] = useState(false)
  const [deleting, setDeleting] = useState(0)
  const {fields, append, remove} = useFieldArray({control, name: "faq"})

  // const setFaqRecomendada = (i: number, pregunta?: string) => {
  //   setValue(`faq.${i}.pregunta`, pregunta)
  //   contadoresPregunta[i] = pregunta?.length || 0
  //   setContadoresPregunta([...contadoresPregunta])
  // }

  const deleteFAQ = () => {
    setContadoresPregunta(contadoresPregunta.filter((_, index) => index !== deleting))
    setContadoresRespuesta(contadoresRespuesta.filter((_, index) => index !== deleting))
    remove(deleting)
  }

  return (
    <Box>
      <SectionTitle title="Preguntas frecuentes" />
      <Grid container spacing={2} sx={{mb: 1}}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <AlertDialog
                open={open}
                onConfirm={() => {
                  deleteFAQ()
                  setOpen(false)
                }}
                agreeText="Eliminar"
                disagreeText="Cancelar"
                content="¿Estás seguro que querés eliminar esta pregunta frecuente?"
                onCancel={() => setOpen(false)}
                title="Eliminar pregunta frecuente"
                agreeColor="error"
              />
              {
                fields.length > 0 ? fields.map((faq, i: number) => {
                  return (
                    <Fragment key={faq.id}>
                      <Card sx={{mb: 2}}>
                        <CardContent>
                          <Typography variant="h6" sx={{mb: 3}}>Pregunta {i + 1}</Typography>
                          <FormControl fullWidth>
                            <TextField
                              label={(<>Pregunta <Asterisco /></>)}
                              fullWidth
                              sx={{ mb: 1 }}
                              disabled={isSubmitting}
                              inputProps={{ maxLength: MAX_LENGTH_PREGUNTA }}
                              InputLabelProps={{shrink: true}}
                              helperText={
                                <Box component={"span"} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                  <Typography color={"#d53839"} variant="caption">{errors.faq && errors.faq[i]?.pregunta?.message}</Typography>
                                  {contadoresPregunta[i] || getValues(`faq.${i}.pregunta`)?.length || 0}/{MAX_LENGTH_PREGUNTA}
                                </Box>
                              }
                              {...register(`faq.${i}.pregunta`)}
                              onChange={(e) => {
                                setValue(`faq.${i}.pregunta`, e.target.value)
                                contadoresPregunta[i] = e.target.value.length
                                setContadoresPregunta([...contadoresPregunta])
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              label={(<>Respuesta <Asterisco /></>)}
                              fullWidth
                              multiline
                              disabled={isSubmitting}
                              rows={4}
                              inputProps={{ maxLength: MAX_LENGTH_RESPUESTA }}
                              helperText={
                                <Box component={"span"} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                  <Typography color={"#d53839"} variant="caption">{errors.faq && errors.faq[i]?.respuesta?.message}</Typography>
                                  {contadoresRespuesta[i] || getValues(`faq.${i}.respuesta`)?.length || 0}/{MAX_LENGTH_RESPUESTA}
                                </Box>
                              }
                              InputLabelProps={{shrink: true}}
                              {...register(`faq.${i}.respuesta`)}
                              onChange={(e) => {
                                setValue(`faq.${i}.respuesta`, e.target.value)
                                contadoresRespuesta[i] = e.target.value.length
                                setContadoresRespuesta([...contadoresRespuesta])
                              }}
                            />
                          </FormControl>
                        </CardContent>
                        <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                          {/* <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "5px",
                              '@media (max-width: 600px)': {
                                flexDirection: 'column',
                              }
                            }}
                          >
                            {
                              faqs && faqs.map((faqSugerida, j) => (
                                <Chip
                                  key={j}
                                  label={faqSugerida.pregunta}
                                  sx={{ mt: "0.5em" }}
                                  onClick={() => setFaqRecomendada(i, faqSugerida.pregunta)}
                                  variant="outlined"
                                />
                              ))
                            }
                          </Box> */}
                          <Box sx={{alignSelf: 'flex-end'}}>
                            <Button disabled={isSubmitting} onClick={() => {setOpen(true); setDeleting(i)}} color="error" variant="contained">
                              Eliminar
                            </Button>
                          </Box>
                        </CardActions>
                      </Card>
                      {
                        i >= fields.length - 1 && <Button variant="contained" sx={{bgcolor: '#818181', ':hover': {bgcolor: '#9b9a9a'}}} fullWidth disabled={isSubmitting} onClick={() => append({ pregunta: "", respuesta: "" })}>
                          {/* <AddCircleOutline /> */}
                          Agregar otra pregunta
                        </Button>
                      }
                    </Fragment>
                  )})
                : <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                  <Typography variant="body1">No cargaste ninguna pregunta frecuente</Typography>
                  <Button variant="contained" sx={{bgcolor: '#818181', ':hover': {bgcolor: '#9b9a9a'}}} fullWidth disabled={isSubmitting} onClick={() => append({ pregunta: "", respuesta: "" })}>
                    {/* <AddCircleOutline /> */}
                    Agregar una pregunta
                  </Button>
                </Box>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FormFaq
