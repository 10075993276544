import { ITenant } from "../pages/AbmLocalPage/types";
import { axiosInstanceMain } from "../utils/axiosGlobalUtils";
import { hash } from "../utils/hashFunction";

export const registrarToken = async (
  user: any,
  token: string,
  imei: any,
  tenant: ITenant
) => {
  const clave = await hash(imei + "lugares2017");
  const data = {
    rol: user.roles[0],
    push_id: token,
    dispositivoDesc: "backoffice",
    imei,
    clave,
  };

  const res = await axiosInstanceMain.post(
    `/${tenant.path}/dispositivo/registrar`,
    data
  );

  return res;
};

export const autoRegistrarToken = async (
  codLocal: any,
  imei: any,
  tenant: ITenant
) => {
  const res = await axiosInstanceMain.post(
    `/${tenant.path}/auth/registrar-autologin-local/${codLocal}?deviceId=${imei}`
  );

  return res;
};

export {};
