import React from "react";
import Papa from "papaparse";
import { Button } from "@mui/material";
import { IStickers } from "../types/sticker";

interface ExportStickersProps {
  stickers: any[];
}

const ExportStickers: React.FC<ExportStickersProps> = ({ stickers }) => {
  const exportToCSV = () => {
    // Prepara los datos para exportar como CSV
    const stickersWithOrder: IStickers[] = stickers.map(
      (sticker, index: number) => ({
        orden: index + 1,
        ...sticker,
      })
    );

    // Prepara los datos para exportar como CSV
    const csvData = Papa.unparse(stickersWithOrder, {
      header: true,
    });

    // Crea un objeto y url Blob
    const blob = new Blob([csvData], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "stickers.csv";
    a.style.display = "none";

    // Agrega el enlace al DOM y hacer clic en él
    document.body.appendChild(a);
    a.click();

    // Limpiar después de la descarga
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <>
      <Button variant="contained" sx={{ margin: "10px" }} onClick={exportToCSV}>
        Exportar a CSV
      </Button>
    </>
  );
};

export default ExportStickers;
