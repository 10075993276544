import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant } from "../../AbmLocalPage/types";
import { Rubro } from "../types/rubro";

export const createRubro = async (rubro: Rubro, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/rubros`, rubro);
export const deleteRubro = async (codRubro: string, tenant: ITenant) =>
  await axiosInstanceMain.delete(`/admin/${tenant.path}/rubros/${codRubro}`);
export const updateRubro = async (codRubro: string, rubro: any, tenant: ITenant) =>
  await axiosInstanceMain.put(`/admin/${tenant.path}/rubros/${codRubro}`, rubro);
