import React, { useState, useEffect, memo } from 'react'
import CardRubros from './cardRubros'
import { flattenRubrosHierarchy } from '../desplegableRubrosPadres'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { RichTreeView, SimpleTreeView, TreeItem, TreeItem2 } from '@mui/x-tree-view'
import { Rubro } from '../../../Integraciones/types/responses-productos'

interface MultiSelectTreeViewProps {
  searchedRubro?: string | null // Prop para recibir el rubro buscado
  rubros: any[]
  updateRubros: () => void
  expandedNodes: string[]
  setExpandedNodes: (nodes: string[]) => void
}

const MultiSelectTreeView: React.FC<MultiSelectTreeViewProps> = ({
  searchedRubro,
  rubros,
  updateRubros,
  expandedNodes,
  setExpandedNodes
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [matched, setMatched] = useState<string[]>([])

  const handleNodeSelect = (nodeCode: any) => {
    const rubro = flattenRubrosHierarchy(rubros).find((r) => r.codRubro === nodeCode)

    let expanded = [...expandedNodes]

    if (expandedNodes.includes(nodeCode)) {
      expanded = expanded.filter((code) => code !== nodeCode)
      rubro?.rubros?.map((r: Rubro) => {
        if (expanded.includes(r.codRubro)) {
          expanded = expanded.filter((code) => code !== r.codRubro)
        }
      })
    } else {
      expanded.push(nodeCode)
    }

    setExpandedNodes([...expanded])
  }

  useEffect(() => {
    if (searchedRubro && rubros.length > 0) {
      const found = findMatchingRubros(rubros, searchedRubro)
      setMatched(found)
    } else {
      setMatched([])
    }
  }, [searchedRubro, rubros])

  const findMatchingRubros = (nodes: Rubro[], rubroToFind: string): string[] => {
    const matched: string[] = []
    for (const node of nodes) {
      if (node.nombre.toLowerCase().includes(rubroToFind.toLowerCase())) {
        matched.push(node.codRubro)
      }
      if (node.rubros) {
        const foundInChildren = findMatchingRubros(node.rubros, rubroToFind)
        matched.push(...foundInChildren)
      }
    }
    return matched
  }

  const renderTreeItems = (nodes: Rubro[]) =>
    nodes.map((node: Rubro) => {
      const isNodeMatched = matched.includes(node.codRubro)
      return (
        <TreeItem
          key={node.codRubro}
          itemId={node.codRubro}
          label={
            <div
              //className={`${nodeClasses.nombre}`}
              style={{
                backgroundColor: isNodeMatched ? 'yellow' : 'transparent',
                ...(isNodeMatched && { fontStyle: 'italic' })
              }}
            >
              {node.nombre}
            </div>
          }
          onClick={() => handleNodeSelect(node.codRubro)}
        >
          {expandedNodes.includes(node.codRubro) && (
            <CardRubros
              rubros={rubros}
              isMobile={isMobile}
              rubro={node}
              updateRubros={updateRubros}
              handleNodeSelect={handleNodeSelect}
            />
          )}
          {node.rubros && renderTreeItems(node.rubros)}
        </TreeItem>
      )
    })

  return (
    <>
      {rubros.length > 0 && (
        <div style={{ marginLeft: '-22px' }}>
          <SimpleTreeView
            key={JSON.stringify(rubros)}
            //getItemId={(item) => item.codRubro}
            //getItemLabel={(item) => item.nombre}
            //defaultCollapseIcon={<ExpandMore />}
            //defaultExpandIcon={<ChevronRightIcon />}
            //expanded={expandedNodes}
            expandedItems={expandedNodes}
            //items={rubros}
            // eslint-disable-next-line react/no-children-prop
            children={renderTreeItems(rubros)}
            style={{ height: 1000, flexGrow: 1, maxWidth: 1000 }}
          />
        </div>
      )}
    </>
  )
}

export default memo(MultiSelectTreeView)
