import React, { useContext, useEffect, useState } from 'react'
import GaleriaDTO from '../../dto/GaleriaDTO'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { StaticGoogleMap, Marker } from 'react-static-google-map'
import DictionaryDTO from '../../dto/DictionaryDTO'
import { MedioDeContactoDTO } from '../../dto/LocalDTO'
import { hash } from '../../utils/hashFunction'
import { GOOGLE_API_KEY } from '../../utils/config'
import Footer from '../../pages/Home/components/Footer'
import SnackBarUtils from '../../utils/SnackBarUtils'
import AlertDialog from '../Common/Dialog'
import { axiosInstanceMain } from '../../utils/axiosGlobalUtils'
import Navbar from '../Navbar'
import { useTheme, useMediaQuery } from '@mui/material'
import { TenantsContext } from '../../context/tenants.context'
import { ITenant, MedioDeContactoList, postLocalType } from '../../pages/AbmLocalPage/types'
import { getGalerias } from '../../pages/Galerias/services/galerias.services'
import { styled } from '@mui/material'
import { useAppStore } from '../../store/app-store'

const ThirdLine = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '1rem',
  gap: theme.spacing(1),
  flexWrap: 'wrap'
}))
const Line = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between'
  }
}))

const LineCaracteristicas = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  margin: '0rem 1rem',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    width: '70%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

const ContainerMap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const ContainerCaracteristicas = styled('div')({
  display: 'flex',
  flexWrap: 'wrap'
})

const ContentCalleOGaleria = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '70%',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

const ContentSelectGaleria = styled('div')(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

const ContentButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0rem 1rem'
})

const ContentHaceEnvios = styled('div')({
  height: '25px'
})

const Info = styled('div')(({ theme }) => ({
  fontSize: 'calc(15px + 1vmin)',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('sm')]: {
    minWidth: '115px'
  }
}))

const InfoTitle = styled('div')(({ theme }) => ({
  fontSize: 'calc(15px + 1vmin)',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 600,
  width: '100%'
}))

const AbmLocal: React.FC = () => {
  const navigate = useNavigate()
  const codLocal = useAppStore((state) => state.codLocal)
  const [premise, setPremise] = useState<postLocalType | undefined>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [title, setTitle] = useState<string>('')
  const [editPage, setEditPage] = useState<boolean>(false)
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<boolean>(false)
  const [galerias, setGalerias] = useState<GaleriaDTO[]>()
  const [codGaleriaSelected, setCodGaleriaSelected] = useState<string>(null)
  const [caracteristicas, setCaracteristicas] = useState<DictionaryDTO[]>()
  const [caracteristicasSelectedNewLocal, setCaracteristicasSelectedNewLoca] = useState<number[]>([])
  const [showDireccionGaleria, setShowDireccionGaleria] = useState<boolean>(false)
  const [showDireccionCalle, setShowDireccionCalle] = useState<boolean>(false)
  const [location, setLocation] = useState<string>('buenos+aires')
  const [error, setError] = useState<boolean>(false)
  const [mensajeError, setMensajeError] = useState<string>('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { tenant } = useContext(TenantsContext)

  useEffect(() => {
    getListadoGalerias()
    getCaracteristicas()
  }, [])

  useEffect(() => {
    if (codLocal) {
      getLocal(codLocal)
    } else {
      setEditPage(false)
      setTitle('Nuevo local')
    }
  }, [codLocal])

  useEffect(() => {
    if (galerias && premise) {
      setGaleriaOCalleyNumero(premise.codGaleria)
    }
  }, [galerias, premise])

  const changeDireccionOption = () => {
    setShowDireccionCalle(false)
    setShowDireccionGaleria(true)
  }

  const changeDireccionCalle = () => {
    setShowDireccionCalle(true)
    setShowDireccionGaleria(false)
  }

  const setGaleriaOCalleyNumero = (codGaleria: string) => {
    if (codGaleria) {
      if (!showDireccionCalle) setShowDireccionGaleria(true) // solo la seteo en true si no estoy mostrando componente de calle y numero.
      changeGaleria(codGaleria)
    }
  }

  const getLocal = (code: string) => {
    axiosInstanceMain
      .get(`admin/${tenant.path}/locales/` + code)
      .then((result) => {
        const data = result.data.data
        setPremise(data)
        setTitle('Perfil: ' + data.nombreLocal + ' CODE: ' + data.codLocal)
        setEditPage(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getListadoGalerias = () => {
    getGalerias(tenant)
      .then((result) => {
        const galeriasResult: GaleriaDTO[] = []
        if (result.data) {
          result.data.data.map((g: GaleriaDTO) => {
            const galeriaResult: GaleriaDTO = {
              value: g.id,
              label: g.nombre
            }
            galeriasResult.push(galeriaResult)
          })
        }
        setGalerias(galeriasResult)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCaracteristicas = async () => {
    axiosInstanceMain
      .get(`${tenant.path}/locales/caracteristicas?filtro=vendedor-configura-perfil`)
      .then((result) => {
        setCaracteristicas(result.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteLocal = () => {
    axiosInstanceMain
      .delete(`/${tenant.path}/locales/${premise?.codLocal}`)
      .then((result) => {
        SnackBarUtils.success('El local ha sido eliminado.')
        navigate('/')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const goBack = () => {
    navigate('/')
  }

  const changeGaleria = (codGaleria: string) => {
    const galeria = galerias?.find((g: GaleriaDTO) => {
      return g.value === codGaleria
    })
    if (galeria) {
      const query = galeria.label.replace('- ', '').replace(' ', '+')

      setLocation(query)
      setCodGaleriaSelected(codGaleria)
    }
  }

  const changeCalleYNumero = (calleYNumero: string) => {
    if (calleYNumero) {
      const query = calleYNumero.replace(' ', '+')
      setLocation(query)
    }
  }

  const savePremiseInfo = async (event: any) => {
    setSaveSuccess(false)
    setSaveError(false)
    event.preventDefault()
    const datos = event.target
    const clave = await hash(datos.nombre.value + 'lugares2017')

    if (editPage) {
      const premiseEdited = { ...premise }
      if (premiseEdited.codGaleria && datos.calleCheck.value) {
        premiseEdited.codGaleria = null
        premiseEdited.galeriaPropuestaNombre = ''
        premiseEdited.dirLocal = datos.calleYnumero.value
      }
      /* if (premiseEdited.idGaleria && !(premiseEdited.idGaleria > 0) && datos.galeriaCheck.value) {
        premiseEdited.idGaleria = 0
        premiseEdited.galeriaPropuestaNombre = ''
        premiseEdited.dirLocal = datos.calleYnumero.value
      } */
      const local: postLocalType = {
        caracteristicasList:
          premiseEdited.caracteristicasList?.filter((c: any) => c.activo).map((c: any) => c.id) || [],
        clave: clave,
        compraMinimaParaEnvio: premiseEdited.caracteristicasList?.some((c: any) => c.id === 3 && c.activo)
          ? premiseEdited.compraMinimaParaEnvio
          : 0,
        desc: premiseEdited.desc,
        galeriaPropuestaDireccion: premiseEdited.dirLocal,
        dirLocal: premiseEdited.dirLocal,
        faq: [],
        horarioList: premiseEdited.horarios,
        codGaleria: premiseEdited.codGaleria,
        codLocal: premiseEdited.codLocal || null,
        idVendedor: premiseEdited.idVendedor ? premiseEdited.idVendedor : 0,
        imei: '',
        latLocal: premiseEdited.latLocal,
        longLocal: premiseEdited.longLocal,
        mail: premiseEdited.mail,
        medioDeContactoList: premiseEdited.medioDeContactoList as MedioDeContactoList[],
        modeloDisp: '',
        galeriaPropuestaNombre: premiseEdited.galeriaPropuestaNombre,
        nombreLocal: premiseEdited.nombreLocal,
        galeriaPropuestaNumero: premiseEdited.numLocal,
        numLocal: premiseEdited.numLocal,
        galeriaPropuesta: premiseEdited.codGaleria !== null,
        push_id: '',
        pwd: premiseEdited.contrasenia,
        rubrosId: [],
        telefono: premiseEdited.telefono,
        usuario: premiseEdited.usuario,
        versionAndroid: '',
        versionApp: '',
        whatsapp: premiseEdited.whatsapp
      }
      axiosInstanceMain
        .put(`${tenant.path}/locales/` + premiseEdited.codLocal, local)
        .then((result) => {
          if (result && result.status === 200 && result.statusText === 'OK') {
            setSaveSuccess(true)
            SnackBarUtils.success('Local guardado con exito')
          } else setSaveError(true)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      const mediosDeContacto: MedioDeContactoDTO[] = [
        {
          descripcion: datos.whatsappDescripcion.value,
          tipo: 1,
          valor: datos.whatsapp.value
        },
        { descripcion: '', tipo: 2, valor: datos.telefono.value }, // telefono
        { descripcion: '', tipo: 3, valor: datos.email.value } //mail
      ]
      const local: postLocalType = {
        caracteristicasId: caracteristicasSelectedNewLocal,
        clave: clave,
        compraMinimaParaEnvio: caracteristicasSelectedNewLocal.includes(3)
          ? datos.caracteristicasHaceEnviosCantidad.value
          : '',
        desc: datos.descripcion.value,
        galeriaPropuestaDireccion: '',
        dirLocal: datos.calleCheck.checked ? datos.calleYnumero.value : undefined,
        faq: [],
        horarioList: [],
        codGaleria: datos.galeriaCheck.checked && codGaleriaSelected,
        imei: '',
        latLocal: 0,
        longLocal: 0,
        mail: datos.email.value,
        medioDeContactoList: mediosDeContacto,
        modeloDisp: '',
        galeriaPropuestaNombre:
          galerias && datos.galeriaCheck.checked && codGaleriaSelected
            ? galerias.filter((f) => f.value === codGaleriaSelected)[0].label
            : '',
        nombreLocal: datos.nombre.value,
        galeriaPropuestaNumero: premise.numLocal, // ???
        numLocal: datos.numeroLocal ? datos.numeroLocal.value : '',
        galeriaPropuesta: datos.galeriaCheck.checked && codGaleriaSelected !== null,
        push_id: '',
        pwd: datos.contrasenia.value,
        rubrosId: [],
        telefono: datos.telefono.value,
        usuario: datos.usuario.value,
        versionAndroid: '',
        versionApp: '',
        whatsapp: datos.whatsapp.value
      }

      axiosInstanceMain
        .post(`${tenant.path}/locales`, local)
        .then((result) => {
          if (result && result.status === 200 && result.statusText === 'OK') {
            setSaveSuccess(true)
            SnackBarUtils.success('Local guardado con exito')
          } else setSaveError(true)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const customStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      borderWidth: '2px',
      borderColor: 'grey',
      borderStyle: 'inset',
      '&:hover': {},
      '&:focus': {
        borderWidth: '2px',
        borderColor: 'grey',
        borderStyle: 'inset'
      }
    })
  }

  const validarNombre = async (value: any) => {
    if (value !== '') {
      const clave = await hash(value + 'lugares2017')
      const url = editPage
        ? `${tenant.path}/locales/nombre-en-uso?codLocal=` + premise?.codLocal + `&clave=` + clave
        : `${tenant.path}/locales/nombre-en-uso?clave=` + clave

      axiosInstanceMain
        .post(url, value)
        .then((result) => {
          if (result.data.errorCode === -11) {
            setError(true)
            setMensajeError(result.data.message)
          } else {
            setError(false)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const validarUsuario = async (value: any) => {
    if (value !== '') {
      const clave = await hash(value + 'lugares2017')
      const url = editPage
        ? `${tenant.path}/locales/user-en-uso?idVendedor=` + premise?.idVendedor + `&clave=` + clave
        : `${tenant.path}/locales/user-en-uso?clave=` + clave

      axiosInstanceMain
        .post(url, value)
        .then((result) => {
          if (result.data.errorCode === -11) {
            setError(true)
            setMensajeError(result.data.message)
          } else {
            setError(false)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <Navbar title={{ title }} />
      <form className="" onSubmit={savePremiseInfo}>
        <ThirdLine>
          <Line>
            <Info>Nombre:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="nombre"
              type="text"
              value={premise?.nombreLocal}
              required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                if (newPremise) {
                  setPremise({
                    ...newPremise,
                    nombreLocal: event.target.value
                  })
                }
              }}
              onBlur={(e) => {
                validarNombre(e.target.value)
              }}
            />
          </Line>
          <Line>
            <Info>Usuario:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="usuario"
              type="text"
              value={premise?.usuario}
              required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                if (newPremise) {
                  setPremise({
                    ...newPremise,
                    usuario: event.target.value
                  })
                }
              }}
              onBlur={(e) => {
                validarUsuario(e.target.value)
              }}
            />
          </Line>
          <Line>
            <Info>Contraseña:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="contrasenia"
              type="text"
              value={premise?.contrasenia}
              required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                if (newPremise) {
                  setPremise({
                    ...newPremise,
                    contrasenia: event.target.value
                  })
                }
              }}
            />
            {error && <Info>{mensajeError}</Info>}
          </Line>
        </ThirdLine>

        <ThirdLine>
          <InfoTitle>Direccion:</InfoTitle>
          <input
            onClick={(e) => {
              changeDireccionOption()
            }}
            className="col-xs-6 premise-info-child"
            id="galeriaCheck"
            type="radio"
            value={''}
            checked={showDireccionGaleria}
            // required
          />
          <Info>Galeria</Info>
          <input
            onClick={(e) => {
              changeDireccionCalle()
            }}
            className="col-xs-6 premise-info-child"
            id="calleCheck"
            type="radio"
            value={''}
            checked={showDireccionCalle}
            // required
          />
          <Info>Calle</Info>
          <ContentCalleOGaleria>
            {showDireccionGaleria && (
              <>
                <ContentSelectGaleria>
                  <Select
                    id="galeria"
                    styles={customStyles}
                    options={galerias}
                    placeholder="Galeria"
                    defaultValue={galerias ? galerias.filter((f) => f.value === premise?.codGaleria)[0] : null}
                    onChange={(e) => {
                      const newPremise: postLocalType | undefined = premise
                      if (newPremise) {
                        setPremise({
                          ...newPremise,
                          codGaleria: e?.value
                        })
                      }
                      changeGaleria(e?.value)
                    }}
                  />
                </ContentSelectGaleria>
                <input
                  className="col-xs-6 premise-info-child"
                  id="numeroLocal"
                  type="text"
                  value={premise?.numLocal}
                  onChange={(event) => {
                    const newPremise: postLocalType | undefined = premise
                    if (newPremise) {
                      setPremise({
                        ...newPremise,
                        numLocal: event.target.value
                      })
                    }
                  }}
                  // required
                />
              </>
            )}
            {showDireccionCalle && (
              <input
                className="col-xs-6 premise-info-child"
                id="calleYnumero"
                type="text"
                value={premise?.dirLocal}
                placeholder={'Calle y número'}
                onBlur={(e) => {
                  changeCalleYNumero(e.target.value)
                }}
                onChange={(e) => {
                  setShowDireccionGaleria(false)
                  const newPremise: postLocalType | undefined = premise
                  if (newPremise) {
                    setPremise({
                      ...newPremise,
                      dirLocal: e?.target.value
                    })
                  }
                }}
                // required
              />
            )}
          </ContentCalleOGaleria>
        </ThirdLine>
        <ContainerMap>
          <StaticGoogleMap size="900x400" className="img-fluid" apiKey={GOOGLE_API_KEY}>
            <Marker location={location} color="blue" label="P" />
          </StaticGoogleMap>
        </ContainerMap>
        <ThirdLine>
          <InfoTitle>Medios de contacto:</InfoTitle>
          <Line>
            <Info>WhatsApp:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="whatsapp"
              type="text"
              value={
                premise?.medioDeContactoList?.filter((m) => {
                  return m.tipo === 1
                })[0]?.valor
              }
              // required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                const newMedioDeContactoList = premise?.medioDeContactoList

                if (newPremise && newMedioDeContactoList) {
                  newMedioDeContactoList.filter((m) => {
                    return m.tipo === 1
                  })[0].valor = event.target.value
                  setPremise({
                    ...newPremise,
                    medioDeContactoList: newMedioDeContactoList
                  })
                }
              }}
            />
          </Line>
          <Line>
            <Info>Descripción:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="whatsappDescripcion"
              type="text"
              value={
                premise?.medioDeContactoList?.filter((m) => {
                  return m.tipo === 1
                })[0].descripcion
              }
              // required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                const newMedioDeContactoList = premise?.medioDeContactoList

                if (newPremise && newMedioDeContactoList) {
                  newMedioDeContactoList.filter((m) => {
                    return m.tipo === 1
                  })[0].descripcion = event.target.value
                  setPremise({
                    ...newPremise,
                    medioDeContactoList: newMedioDeContactoList
                  })
                }
              }}
            />
          </Line>
          <Line>
            <Info>Teléfono:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="telefono"
              type="text"
              value={
                premise?.medioDeContactoList?.filter((m) => {
                  return m.tipo === 2
                })[0]?.valor
              }
              // required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                const newMedioDeContactoList = premise?.medioDeContactoList

                if (newPremise && newMedioDeContactoList) {
                  newMedioDeContactoList.filter((m) => {
                    return m.tipo === 2
                  })[0].valor = event.target.value
                  setPremise({
                    ...newPremise,
                    medioDeContactoList: newMedioDeContactoList
                  })
                }
              }}
            />
          </Line>
          <Line>
            <Info>e-mail:</Info>
            <input
              className="col-xs-6 premise-info-child"
              id="email"
              type="text"
              value={premise?.emailConDescripcionListString}
              // required
              onChange={(event) => {
                const newPremise: postLocalType | undefined = premise
                if (newPremise) {
                  setPremise({
                    ...newPremise,
                    emailConDescripcionListString: event.target.value
                  })
                }
              }}
            />
          </Line>
        </ThirdLine>
        <ThirdLine>
          <InfoTitle>Caracteristicas:</InfoTitle>
        </ThirdLine>
        {!editPage ? (
          caracteristicas !== null && caracteristicas ? (
            <ContainerCaracteristicas>
              {caracteristicas.map((caracteristica: any, i: number) => {
                return (
                  <LineCaracteristicas key={i}>
                    <Info>{caracteristica.nombre}</Info>
                    <input
                      className="col-xs-6 premise-info-child"
                      style={{ marginTop: '10px' }}
                      type="checkbox"
                      id="caracteristicas"
                      name="caracteristicas"
                      value={caracteristica.nombre}
                      onChange={() => {
                        if (caracteristicasSelectedNewLocal) {
                          if (!caracteristicasSelectedNewLocal.includes(caracteristica.caracteristicaLocalId)) {
                            setCaracteristicasSelectedNewLoca([
                              ...caracteristicasSelectedNewLocal,
                              caracteristica.caracteristicaLocalId
                            ])
                          } else {
                            setCaracteristicasSelectedNewLoca([
                              ...caracteristicasSelectedNewLocal.filter((f) => {
                                return f != caracteristica.caracteristicaLocalId
                              })
                            ])
                          }
                        }
                      }}
                    />
                    {Number(caracteristica.caracteristicaLocalId) === 3 &&
                    caracteristicasSelectedNewLocal.includes(3) ? (
                      <ContentHaceEnvios>
                        <input
                          className="col-xs-6 premise-info-child"
                          style={{ height: '25px' }}
                          id="caracteristicasHaceEnviosCantidad"
                          type="text"
                          required
                          placeholder="Detalles de envío"
                        />
                      </ContentHaceEnvios>
                    ) : null}
                  </LineCaracteristicas>
                )
              })}
            </ContainerCaracteristicas>
          ) : null
        ) : (
          <ContainerCaracteristicas>
            {premise?.caracteristicasList?.map((caracteristica: any, i: number) => {
              return (
                <LineCaracteristicas key={i}>
                  <Info>{caracteristica.nombre}</Info>
                  <input
                    className="col-xs-6 premise-info-child"
                    style={{ marginTop: '10px' }}
                    id="caracteristicas"
                    name="caracteristicas"
                    type="checkbox"
                    checked={caracteristica.activo}
                    value={caracteristica.nombre}
                    onClick={() => {
                      const newPremise: postLocalType | undefined = premise
                      const newCaracteristicasList = premise.caracteristicasList
                      if (newPremise && newCaracteristicasList) {
                        const position = newCaracteristicasList.findIndex((c: any) => {
                          return c.nombre === caracteristica.nombre
                        })
                        newCaracteristicasList[position].activo = !newCaracteristicasList[position].activo
                        setPremise({
                          ...newPremise,
                          caracteristicasList: newCaracteristicasList
                        })
                      }
                    }}
                  />
                  {caracteristica.id === 3 && caracteristica.activo === true ? (
                    <ContentHaceEnvios>
                      <input
                        className="col-xs-6 premise-info-child"
                        style={{ height: '25px', marginTop: '5px' }}
                        id="caracteristicasCantidad"
                        type="text"
                        value={premise?.compraMinimaParaEnvio}
                        onChange={(event) => {
                          const newPremise: postLocalType | undefined = premise
                          if (newPremise) {
                            setPremise({
                              ...newPremise,
                              compraMinimaParaEnvio: event.target.value
                            })
                          }
                        }}
                        required
                      />
                    </ContentHaceEnvios>
                  ) : null}
                </LineCaracteristicas>
              )
            })}
          </ContainerCaracteristicas>
        )}
        <ThirdLine>
          <InfoTitle>Descripción:</InfoTitle>
          <textarea
            id="descripcion"
            value={premise?.descripcion}
            className="form-control"
            style={{ color: '#53292a' }}
            rows={3}
            onChange={(event) => {
              const newPremise: postLocalType | undefined = premise
              if (newPremise) {
                setPremise({
                  ...newPremise,
                  descripcion: event.target.value
                })
              }
            }}
          ></textarea>
        </ThirdLine>
        <ContentButtons>
          {editPage ? (
            <div onClick={() => deleteLocal()} className="btn btn-primary admin-local-btn premise-info-child">
              Eliminar cuenta
            </div>
          ) : (
            <button onClick={() => goBack()} className="btn btn-primary admin-local-btn premise-info-child">
              Cancelar
            </button>
          )}

          <button
            className="btn btn-primary admin-local-btn premise-info-child"
            type="submit"
            // onClick={save}
            disabled={error}
          >
            Guardar
          </button>
        </ContentButtons>
        {saveSuccess && <Info>Local guardado con éxito!</Info>}
        {saveError && <Info>Ha ocurrido un error al guardar el local</Info>}
      </form>

      <AlertDialog
        title="¿Deseas eliminar este local?"
        content="Esta acción no se puede deshacer."
        onConfirm={deleteLocal}
        agreeText="Eliminar"
        agreeColor="error"
        disagreeText="Cancelar"
        onCancel={() => setDialogOpen(false)}
        open={dialogOpen}
      />
      <Footer />
    </>
  )
}

export default AbmLocal
