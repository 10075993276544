import React, { useState } from "react";
import * as GrIcons from "react-icons/gr";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import {
  IFiltrosHistorial,
  IHistorialResponse
} from "../../../dto/ContractDTO";
import { useProductosContrato } from "../hooks/productos.contrato.hook";
import FiltrosHistorial from "../FiltrosHistorial";
import {
  defaultPaginator,
  Paginator
} from "../../../pages/Products/context/productos.vendedor.context";
import TablaHistorial from "./tablaHistorial";
import CardsHistorial from "./cardsHistorial";
import TableDataPagination from "../../../pages/Products/components/TableDataPagination";

interface Props {
  historial: IHistorialResponse;
  headers: string[];
  filtros: IFiltrosHistorial;
  setFiltros: (filtros: any) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

const HistorialContratos: React.FC<Props> = ({
  historial,
  headers,
  filtros,
  setFiltros,
  loading,
  setLoading,
  show,
  setShow,
}: Props) => {
  const [paginatorHistorial] = useState<Paginator>(defaultPaginator);
  const { productos } = useProductosContrato();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const closeRenovations = () => {
    setShow(false);
  };
  paginatorHistorial.handleChangePage = (e: any, newPage: number) => {
    setFiltros({ ...filtros, page: newPage });
  };

  paginatorHistorial.handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFiltros({ ...filtros, size: parseInt(event.target.value, 10), page: 0 });
  };

  if (!historial?.results) return;

  return (
    <Dialog
      open={show}
      onClose={() => setShow(false)}
      // onClick={closeRenovations}
    >
      <section
        className="modal-main"
        style={{
          height: "98%",
          overflow: "auto",
          ...(isMobile && { overflowX: "hidden", overflowY: "auto" }),
        }}
      >
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">Historial de Contratos</h3>
          <button
            className="modal-close"
            onClick={closeRenovations}
            aria-label="Cerrar"
          >
            <GrIcons.GrClose />
          </button>
        </header>
        <FiltrosHistorial
          filtros={filtros}
          setFiltros={setFiltros}
          productos={productos}
          loading={loading}
        />
        {isMobile ? (
          <div style={{ margin: "12px" }}>
            <CardsHistorial historial={historial?.results} listado={true} />
            <TableDataPagination
              total={historial.totalElements || 0}
              page={historial.pageNumber || 0}
              paginator={paginatorHistorial}
              items={historial.results || []}
              rowsPerPage={historial.pageSize || 100}
              component={"p"}
              label={`Productos por página`}
            />
          </div>
        ) : (
          <TablaHistorial
            historial={historial}
            headers={headers}
            paginator={paginatorHistorial}
          />
        )}
      </section>
    </Dialog>
  );
};

export default HistorialContratos;
