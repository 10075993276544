import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { ITenant } from "../../AbmLocalPage/types";

export const getCalificaciones = async (codLocal: string, tenant: ITenant) =>
  await axiosInstanceMain.get(`/${tenant.path}/calificaciones/local/${codLocal}/vendedor`);

export const responderCalificaciones = async (
  idCalificacion: number,
  respuesta: string,
  esPublica: boolean,
  tenant: ITenant
) =>
  await axiosInstanceMain.post(`/${tenant.path}/calificaciones/${idCalificacion}/responder`, {
    respuesta: respuesta,
    esPublica: esPublica,
  });
