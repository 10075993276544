import React from "react";
import { Typography, Button, Box } from "@mui/material";

interface DialogPausarProductoProps {
  quierePausarProducto: (pausa: boolean) => void;
  openDialog: (open: boolean) => void;
  handleSubmit: (submit: any) => () => void;
  onSubmit: (data: any) => void;
  isMobile: boolean;
}

const DialogPausarProducto: React.FC<DialogPausarProductoProps> = ({
  quierePausarProducto,
  openDialog,
  handleSubmit,
  onSubmit,
  isMobile,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          paddingLeft: isMobile ? "4px" : "50px",
          paddingRight: isMobile ? "4px" : "50px",
        }}
      >
        ¿Deseás pausar la publicación hasta que se encuentre disponible
        nuevamente?
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => {
              quierePausarProducto(true);
            }}
          >
            {isMobile ? "Sí, pausar" : "Sí, pausar la publicación"}
          </Button>
          <Button
            variant="contained"
            color={"error"}
            type="submit"
            onClick={() => {
              openDialog(false);
              handleSubmit(onSubmit)();
            }}
          >
            {isMobile ? "No, modificar" : "No, modificarla sin pausar"}
          </Button>
        </Box>
      </Typography>
    </Box>
  );
};

export default DialogPausarProducto;
