import { createContext, useState, useEffect, useCallback } from 'react'
import { INITIAL_ROWS_PER_PAGE } from '../../../pages/Products/components/TableDataPagination'
import {
  Paginator,
  defaultPaginator
} from '../../../pages/Products/context/productos.vendedor.context'
import { getLinks, getPagos } from '../services/admin.local.services'
import { useLocalContracts } from '../../../hooks/admin.local.contracts.hook'
import { ContractDTO } from '../../../dto/ContractDTO'

export interface LinksDTO {
  idContratoPagoLink: number
  idContrato: number
  platform: string
  idExterno: string
  link: string
  source: string
  fechaAlta: string
  fechaVencimiento: string
}

export interface PagosDTO {
  idContratoPago: number
  idContrato: number
  contratoPagoEstado: string
  contratoPagoEstadoLogica: string
  monto: number
  montoDevuelto: number
  saldoAplicado: any
  pagoNroOperacion: string
  fechaPago: string
  fechaAlta: string
  fechaBaja: string
  idContratoPagoLink: number
  mensajeResultado: string
  nuevoContrato: boolean
  historialCambios: any[]
}

export interface LinksLocal {
  page: number
  pageSize: number
  totalPages: number
  totalItems: number
  idContrato: number
  items: LinksDTO[]
}

export interface PagosLocal {
  page: number
  pageSize: number
  totalPages: number
  totalItems: number
  idContrato: number
  items: PagosDTO[]
}

export const defaultData: any = {
  page: 0,
  pageSize: INITIAL_ROWS_PER_PAGE,
  totalPages: 0,
  totalItems: 0,
  items: []
}

interface ProductosVendedorContextProps {
  linksContrato: LinksLocal[]
  setLinksContrato: (links: LinksLocal[]) => void
  selectedLinks: LinksLocal
  setIdContrato: (id: number) => void
  pagosContrato: PagosLocal[]
  setPagosContrato: (pagos: PagosLocal[]) => void
  selectedPagos: PagosLocal
  loading: boolean
  paginatorLinks: Paginator
  paginatorPagos: Paginator
  pageLinks: number
  setPageLinks: (p: number) => void
  pageSizeLinks: number
  setPageSizeLinks: (size: number) => void
  pagePagos: number
  setPagePagos: (p: number) => void
  pageSizePagos: number
  setPageSizePagos: (size: number) => void
  idLocal: string | undefined
  setIdLocal: (id: string) => void
  fetchDataContratos: (contratos: ContractDTO[]) => void
}

// create context
export const DataContratosContext =
  createContext<ProductosVendedorContextProps>({
    linksContrato: [],
    setLinksContrato: () => {},
    selectedLinks: defaultData,
    setIdContrato: () => {},
    pagosContrato: [],
    setPagosContrato: () => {},
    selectedPagos: defaultData,
    loading: false,
    paginatorLinks: defaultPaginator,
    paginatorPagos: defaultPaginator,
    pageLinks: 0,
    setPageLinks: () => {},
    pageSizeLinks: INITIAL_ROWS_PER_PAGE,
    setPageSizeLinks: () => {},
    pagePagos: 0,
    setPagePagos: () => {},
    pageSizePagos: INITIAL_ROWS_PER_PAGE,
    setPageSizePagos: () => {},
    idLocal: '0',
    setIdLocal: () => {},
    fetchDataContratos: () => {}
  })

// create provider
export const DataContratosProvider = ({ children }: any) => {
  const [linksContrato, setLinksContrato] = useState<LinksLocal[]>([])
  const [idContrato, setIdContrato] = useState<number>(0)
  const [selectedLinks, setSelectedLinks] = useState<LinksLocal>(defaultData)
  const [pagosContrato, setPagosContrato] = useState<PagosLocal[]>([])
  const [selectedPagos, setSelectedPagos] = useState<PagosLocal>(defaultData)
  const [paginatorLinks] = useState<Paginator>(defaultPaginator)
  const [paginatorPagos] = useState<Paginator>(defaultPaginator)
  const [pageLinks, setPageLinks] = useState<number>(0)
  const [pageSizeLinks, setPageSizeLinks] = useState<number>(
    INITIAL_ROWS_PER_PAGE
  )
  const [pagePagos, setPagePagos] = useState<number>(0)
  const [pageSizePagos, setPageSizePagos] = useState<number>(
    INITIAL_ROWS_PER_PAGE
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [idLocal, setIdLocal] = useState<string>()
  const { contracts } = useLocalContracts(idLocal)

  paginatorLinks.handleChangePage = (e: any, newPage: number) => {
    setPageLinks(newPage)
  }

  paginatorLinks.handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSizeLinks(parseInt(event.target.value, 10))
    setPageLinks(0)
  }

  paginatorPagos.handleChangePage = (e: any, newPage: number) => {
    setPagePagos(newPage)
  }

  paginatorPagos.handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSizePagos(parseInt(event.target.value, 10))
    setPagePagos(0)
  }

  const fetchLinks = async (idContrato: number) => {
    setLoading(true)
    try {
      const response = await getLinks(idContrato, pageLinks, pageSizeLinks)
      const data = response.data.data
      if (data.totalElements > 0) {
        const links: LinksLocal[] = [...linksContrato]
        const newLinks: LinksLocal = {
          page: data.page,
          pageSize: data.pageSize,
          totalPages: data.totalPages,
          totalItems: data.totalElements,
          idContrato: data.results[0]?.idContrato || 0,
          items: data.results
        }
        const listadoExistente = linksContrato.findIndex(
          (link: LinksLocal) => link.idContrato === idContrato
        )
        if (listadoExistente !== -1) {
          links[listadoExistente] = newLinks
          setSelectedLinks(newLinks)
          setLinksContrato(links)
        } else {
          setLinksContrato((prevLinks) => [...prevLinks, newLinks])
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPagos = async (idContrato: number) => {
    setLoading(true)
    try {
      const response = await getPagos(idContrato, pagePagos, pageSizePagos)
      const data = response.data.data
      if (data.totalElements > 0) {
        const pagos: PagosLocal[] = [...pagosContrato]
        const newPagos: PagosLocal = {
          page: data.page,
          pageSize: data.pageSize,
          totalPages: data.totalPages,
          totalItems: data.totalElements,
          idContrato: data.results[0]?.idContrato || 0,
          items: data.results
        }
        const listadoExistente = pagosContrato.findIndex(
          (pago: PagosLocal) => pago.idContrato === idContrato
        )
        if (listadoExistente !== -1) {
          pagos[listadoExistente] = newPagos
          setSelectedPagos(newPagos)
          setPagosContrato(pagos)
        } else {
          setPagosContrato((prevPagos) => [...prevPagos, newPagos])
        }
      }
    } catch (error) {
      console.error('Error fetching data: ', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    selectedLinks.idContrato && fetchLinks(selectedLinks.idContrato)
  }, [pageLinks, pageSizeLinks])

  useEffect(() => {
    selectedPagos.idContrato && fetchPagos(selectedPagos.idContrato)
  }, [pagePagos, pageSizePagos])

  useEffect(() => {
    if (idContrato === 0) {
      setSelectedLinks(defaultData)
      setSelectedPagos(defaultData)
    }
    const link = linksContrato.find(
      (l: LinksLocal) => l.idContrato === idContrato
    )
    link && setSelectedLinks(link)
    const pago = pagosContrato.find(
      (l: PagosLocal) => l.idContrato === idContrato
    )
    pago && setSelectedPagos(pago)
  }, [idContrato])

  const fetchDataContratos = (contratos: ContractDTO[]) => {
    contratos.map((c: ContractDTO) => {
      fetchPagos(c.idContrato)
      fetchLinks(c.idContrato)
    })
  }

  /*   useEffect(() => {
    if (contracts.length > 0) {
      fetchDataContratos(contracts);
    }
  }, [contracts, idLocal]); */

  return (
    <DataContratosContext.Provider
      value={{
        linksContrato,
        setLinksContrato,
        selectedLinks,
        setIdContrato,
        pagosContrato,
        setPagosContrato,
        selectedPagos,
        loading,
        paginatorLinks,
        paginatorPagos,
        pageLinks,
        setPageLinks,
        pageSizeLinks,
        setPageSizeLinks,
        pagePagos,
        setPagePagos,
        pageSizePagos,
        setPageSizePagos,
        idLocal,
        setIdLocal,
        fetchDataContratos
      }}
    >
      {children}
    </DataContratosContext.Provider>
  )
}
