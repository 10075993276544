import { Check, CircleOutlined } from '@mui/icons-material'
import { Box, CardActionArea, CardMedia } from '@mui/material'
import { useCallback, useContext } from 'react'
import { ITenant } from '../../../../AbmLocalPage/types'
import { TenantsContext } from '../../../../../context/tenants.context'

interface CardActionsAreaProps {
  product: any
  handleEditProduct: (code: string, tenant: ITenant) => Promise<void>
  accionesMasivas: boolean
  productosSeleccionados: any[]
  setProductosSeleccionados: (productos: any[]) => void
  estaSeleccionado: boolean
  setAccionesMasivas?: (accionesMasivas: boolean) => void
}

const CardActionsArea = ({
  product,
  handleEditProduct,
  accionesMasivas,
  productosSeleccionados,
  setProductosSeleccionados,
  estaSeleccionado
}: CardActionsAreaProps) => {
  const { tenant } = useContext(TenantsContext)
  const handleActionAreaClick = useCallback(() => {
    handleEditProduct(product.codProducto, tenant)
  }, [product.codProducto, handleEditProduct])

  const handleCardActionAreaClick = () => {
    if (!accionesMasivas) return handleActionAreaClick()

    if (estaSeleccionado) {
      setProductosSeleccionados(productosSeleccionados.filter((p: any) => p.codProducto !== product.codProducto))
    } else {
      setProductosSeleccionados([...productosSeleccionados, product])
    }
  }

  return (
    <CardActionArea onClick={handleCardActionAreaClick}>
      {accionesMasivas && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            padding: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '0 0 0 10px'
          }}
        >
          <Box>
            {productosSeleccionados.some((p: any) => p.codProducto === product.codProducto) ? (
              <Check color="primary" />
            ) : (
              <CircleOutlined color="inherit" />
            )}
          </Box>
        </Box>
      )}
      <CardMedia
        component={'img'}
        loading="lazy"
        sx={{
          height: 200,
          objectFit: 'scale-down',
          //not allow to select image
          userSelect: 'none',
          pointerEvents: 'none'
        }}
        image={product.fotoUrlThumb}
        title={product.nombre}
      />
    </CardActionArea>
  )
}

export default CardActionsArea
