import { PlayArrow, Delete, Pause, Close } from '@mui/icons-material'
import CategoryIcon from '@mui/icons-material/Category'
import {
  Box,
  Button,
  Dialog,
  Fab,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useState } from 'react'
import DialogCambiarRubros from '../../AdminProducts/components/DialogCambiarRubros'
import { ITenant } from '../../AbmLocalPage/types'
import { Rubro } from '../../Integraciones/types/responses-productos'

interface AccionesMasivasButtonsProps {
  isAdmin: boolean
  rubros: Rubro[]
  puedePausarVarios: boolean
  puedeReanudarVarios: boolean
  puedeVencerVarios: boolean
  handlePauseProducts: () => void
  handleUnPauseProducts: () => void
  handleDeleteProducts: () => void
  handleExpireProducts?: () => void
  handleChangeRubro?: (codRubro: string, tenant: ITenant) => void
  handleLeft: () => void
}

const AccionesMasivasButtons = ({
  isAdmin,
  rubros,
  puedePausarVarios,
  puedeReanudarVarios,
  puedeVencerVarios,
  handleDeleteProducts,
  handlePauseProducts,
  handleUnPauseProducts,
  handleExpireProducts,
  handleChangeRubro,
  handleLeft
}: AccionesMasivasButtonsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [openChangeRubro, setOpenChangeRubro] = useState<boolean>(false)
  if (!isMobile)
    return (
      <>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            onClick={handleLeft}
            sx={{ bgcolor: '#8b8b8b', ':hover': { bgcolor: '#7e7e7e' } }}
          >
            Cerrar
          </Button>
          {puedePausarVarios && (
            <Button
              variant="contained"
              color="primary"
              onClick={handlePauseProducts}
            >
              Pausar
            </Button>
          )}

          {puedeReanudarVarios && (
            <Button
              variant="contained"
              color="success"
              onClick={handleUnPauseProducts}
            >
              Reanudar
            </Button>
          )}
          {puedeVencerVarios && handleExpireProducts && (
            <Button
              variant="contained"
              onClick={handleExpireProducts}
              sx={{ bgcolor: '#8b8b8b', ':hover': { bgcolor: '#7e7e7e' } }}
            >
              Vencer
            </Button>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteProducts}
          >
            Borrar
          </Button>
          {isAdmin && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenChangeRubro(true)}
            >
              Cambiar rubro
            </Button>
          )}
        </Box>
        <Dialog open={openChangeRubro}>
          <DialogCambiarRubros
            rubros={rubros}
            openDialog={setOpenChangeRubro}
            handleChangeRubro={handleChangeRubro}
          />
        </Dialog>
      </>
    )

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}
    >
      {puedePausarVarios && (
        <Fab color="primary" onClick={handlePauseProducts}>
          <Pause />
        </Fab>
      )}
      {puedeReanudarVarios && (
        <Fab color="success" onClick={handleUnPauseProducts}>
          <PlayArrow />
        </Fab>
      )}
      <Fab color="error" onClick={handleDeleteProducts}>
        <Delete />
      </Fab>
      <Fab color="primary" onClick={() => setOpenChangeRubro(true)}>
        <CategoryIcon />
      </Fab>
      <Fab
        sx={{
          color: 'white',
          bgcolor: '#8b8b8b',
          ':hover': { bgcolor: '#7e7e7e' }
        }}
        onClick={handleLeft}
      >
        <Close />
      </Fab>
    </Box>
  )
}

export default AccionesMasivasButtons
