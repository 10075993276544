import { useState, ChangeEvent, useContext, memo, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import { Autocomplete, Button, MenuItem, FormControl, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import Loading from "../../components/Common/Loading";
import { PedidosContext } from "./context/pedidos.context";
import { axiosInstanceMain } from "../../utils/axiosGlobalUtils";
import { TenantsContext } from "../../context/tenants.context";
import { Dayjs } from "dayjs";

const PedidosFiltros = (props: any) => {
  const isAdmin = props.isAdmin;
  const tipo = isAdmin ? "admin" : "local";

  const {
    setFiltros,
    setSearching,
    tiposDeRetiro,
    loading: loadingPedidos,
  } = useContext(PedidosContext);

  const [autoCompletado, setAutoCompletado] = useState<string[]>([]);
  // Form
  const [codLocal, setCodLocal] = useState<string>(props.userLocal);
  const [envioDesde, setEnvioDesde] = useState<Dayjs | null>(null);
  const [envioHasta, setEnvioHasta] = useState<Dayjs | null>(null);
  const [codigoPedido, setCodigoPedido] = useState<string>("");
  const { tenant } = useContext(TenantsContext);

  const [idTipoRetiro, setIdTipoRetiro] = useState<number>(0);

  const [selectedLocal, setSelectedLocal] = useState<string>("");

  const handleAutoCompletado = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length < 3) return;

    axiosInstanceMain
      .post(`/admin/${tenant.path}/locales/autocompletado`, { valor: value })
      .then((result) => {
        setAutoCompletado(result.data.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleTipoRetiro = (tipoRetiro: string) => {
    const selectedTipo = tiposDeRetiro.find((tipo: any) => tipo.nombre === tipoRetiro);
    setIdTipoRetiro(selectedTipo ? selectedTipo.id : "");
  };

  useEffect(() => {
    handleFormSubmit();
  }, [isAdmin]);

  const handleFormSubmit = () => {
    setSearching(true);
    const fechaDesde = envioDesde?.format("YYYY-MM-DD");
    const fechaHasta = envioHasta?.format("YYYY-MM-DD");
    setFiltros({
      tipo,
      codLocal,
      envioDesde: fechaDesde,
      envioHasta: fechaHasta,
      codigoPedido,
      idTipoRetiro,
      pagina: 0,
      archivados: false,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        width: "100%",
        padding: "10px",
        backgroundColor: "#f5f5f5",
        borderRadius: "5px",
        marginBottom: "10px",
        gap: "10px",
        marginTop: "10px",

        // display column en mobile
        "@media (max-width: 768px)": {
          flexDirection: "column",
          gap: "14px",
        },
      }}
    >
      {isAdmin && (
        <FormControl
          sx={{
            width: "300px",
            "@media (max-width: 768px)": { width: "100%" },
          }}
        >
          <Autocomplete
            options={autoCompletado}
            disabled={loadingPedidos}
            getOptionLabel={(option: any) => option.nombre}
            onChange={(e, value: any) => {
              setCodLocal(value?.data || "");
              setSelectedLocal(value?.nombre || "");
            }}
            value={autoCompletado.find((option: any) => option.nombre === selectedLocal) || null}
            renderInput={(p) => (
              <TextField
                {...p}
                onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
                label="Local"
                variant="outlined"
                onChange={handleAutoCompletado}
              />
            )}
          />
        </FormControl>
      )}
      <FormControl sx={{ width: "300px", "@media (max-width: 768px)": { width: "100%" } }}>
        <TextField
          label="Código de pedido"
          disabled={loadingPedidos}
          onChange={(e) => {
            setCodigoPedido(e.target.value);
          }}
          onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
        />
      </FormControl>

      <FormControl
        sx={{
          width: "300px",
          "@media (max-width: 768px)": { width: "100%" },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={loadingPedidos}
            label="Envío Desde"
            format="DD/MM/YYYY"
            value={envioDesde}
            onChange={(date: any) => (date ? setEnvioDesde(date) : setEnvioDesde(null))}
            slotProps={{
              textField: {
                variant: "outlined",
                onKeyPress: (e) => e.key === "Enter" && handleFormSubmit(),
                value: envioDesde,
              },
            }}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        sx={{
          width: "300px",
          "@media (max-width: 768px)": { width: "100%" },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={loadingPedidos}
            label="Envío Hasta"
            format="DD/MM/YYYY"
            value={envioHasta}
            onChange={(date: any) => (date ? setEnvioHasta(date) : setEnvioHasta(null))}
            slotProps={{
              textField: {
                variant: "outlined",
                onKeyPress: (e) => e.key === "Enter" && handleFormSubmit(),
                value: envioHasta,
              },
            }}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl sx={{ width: "300px", "@media (max-width: 768px)": { width: "100%" } }}>
        <Autocomplete
          options={tiposDeRetiro}
          getOptionLabel={(tipo: any) => tipo.nombre || ""}
          onChange={(e, value) => handleTipoRetiro(value?.nombre || "")}
          value={tiposDeRetiro.find((tipo: any) => tipo.id === idTipoRetiro) || null}
          onKeyPress={(e) => e.key === "Enter" && handleFormSubmit()}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Tipo de Retiro"
              InputLabelProps={{ disableAnimation: true }}
            />
          )}
          renderOption={(props, tipo) => (
            <MenuItem {...props} value={tipo.id}>
              {tipo.nombre}
            </MenuItem>
          )}
        />
      </FormControl>

      <FormControl
        sx={{
          "@media (max-width: 768px)": {
            width: "100%",
          },
        }}
      >
        <Button
          disabled={loadingPedidos}
          variant="contained"
          size="large"
          onClick={handleFormSubmit}
          fullWidth
          sx={{
            "@media (max-width: 768px)": {
              display: "flex",
              gap: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "none",
              "@media (max-width: 768px)": {
                display: "block",
              },
            }}
          >
            Filtrar
          </Box>
          <Box
            sx={{
              padding: "6px",
              display: "block",
              "@media (max-width: 768px)": {
                display: "flex",
              },
            }}
          >
            <FaIcons.FaSearch />
          </Box>
        </Button>
      </FormControl>
    </Box>
  );
};

export default memo(PedidosFiltros);
