import { Box, Drawer, Typography } from '@mui/material'
import { useLocal } from '../../../../../AbmLocalPage/hooks/local.hook'
import Loading from '../../../../../../components/Common/Loading'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { IntegracionesContext } from '../../../../context/integraciones.context'
import AlertDialog from '../../../../../../components/Common/Dialog'
import { useAppStore } from '../../../../../../store/app-store'
import ProductosAA1TListado from './ProductosAA1TListado'
import SnackBarUtils from '../../../../../../utils/SnackBarUtils'
import { SaveProductosAA1T } from '../../../../services/productos.service'
import { Atributos, Columna, Equivalencia } from '../../../../Importacion/types/importacion'
import { Atributo, AtributosEquivalencias, SaveValores, ValoresList } from '../../../../types'
import { RubroYValores } from '../../../../Importacion/types/atributos'
import { Pasos, PasosAtributos } from '../../../../Importacion/ImportarCatalogo'
import { TenantsContext } from '../../../../../../context/tenants.context'
import usePasos from '../../../../Importacion/hooks/usePasos'
import { attributes, ObtenerAtributosValoresEquivalencias } from '../../../../Importacion/utils/helpers'
import { IProductoTienda, Rubro } from '../../../../types/responses-productos'
import {
  GetAtributosEquivalencias,
  GetAtributosEquivalenciasValores,
  SaveValoresAtributos
} from '../../../../services/atributos.service'
import AsignarAtributo from '../../../../Importacion/AsignarAtributo'
import BotonesAtributos from '../../../../Importacion/components/BotonesAtributos'
import { ProductoAtributoList } from '../../../../types/responses'
import { AsignarColumnasImportacion } from '../../../../Importacion/AsignarColumnas'
import { findRubro, getAllRubros } from '../../../../../AdminProducts/services/rubros.service'
import useAtributos from '../../../../hooks/useAtributos'
import ButtonOpenCarrito from './ButtonOpenCarrito'
import CarritoPublicaciones from './CarritoPublicaciones'
import useRubros, { flatRubros } from '../../../../../Products/hooks/rubros.hook'

interface Props {
  cantOfertasDisp: number
}

const TiendaAA1T = ({ cantOfertasDisp }: Props) => {
  const { tiendaSeleccionada, productosAConfirmar, setProductosAConfirmar, cantProductosAA1T, setCantProductosAA1T } =
    useContext(IntegracionesContext)
  const codLocal = useAppStore((state) => state.codLocal)
  const [loading, setLoading] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const { loading: loadingLocal } = useLocal(codLocal)
  const [atributos, setAtributos] = useState<Atributos[]>([])
  const [atributosSincronizar, setAtributosSincronizar] = useState<Columna[]>([])
  const [atributosTemplate, setAtributosTemplate] = useState<Atributo[]>([])
  const [atributosValores, setAtributosValores] = useState<AtributosEquivalencias[]>([])
  const [atributosEquivalencias] = useState<any[]>([])
  const [atributosToSend, setAtributosToSend] = useState<SaveValores[]>([])
  const [columnasEquivalencias, setColumnasEquivalencias] = useState<Equivalencia[]>([])
  const [RubroYValores, setRubroYValores] = useState<RubroYValores[]>([])
  const [atributosValoresASincronizar, setAtributosValoresASincronizar] = useState<any[]>([])
  const {
    paso: pasoAtributos,
    setPaso: setPasoAtributos,
    handleNext: handleNextAttr
  } = usePasos({
    pasos: Object.values(PasosAtributos),
    pasoInicial: PasosAtributos.Colores
  })
  const { tenant } = useContext(TenantsContext)
  const { rubros, rubrosFlatted } = useRubros()
  const { getAtributos } = useAtributos()

  const _setRubroYValores = useCallback(
    ({ atributos, rubros }: { atributos: Atributos[]; rubros: Rubro[] }) => {
      atributos.forEach((a) => {
        for (const key in attributes) {
          const { singular, plural } = attributes[key]
          const rubro = (rubros || rubrosFlatted).find((r) => Object.keys(a)[0] === r?.codRubro?.toString())

          if (!rubro || !rubro.productoAtributoList)
            throw new Error('Error al sincronizar atributos, intentalo de vuelta')

          const attribute = rubro?.productoAtributoList.find(
            (a) =>
              a.nombreSingular.toLowerCase() === singular.toLowerCase() ||
              a.nombrePlural.toLowerCase() === plural.toLowerCase()
          )
          if (attribute) {
            const attrs = Object.values(a)[0][key]
            const valores = attribute.valoresList.filter((v) => attrs.includes(v.nombre))

            if (
              !RubroYValores.some((r) => {
                return (
                  r.rubro.codRubro === rubro.codRubro &&
                  JSON.stringify(r.atributosDefault.all) === JSON.stringify(attribute) &&
                  JSON.stringify(r.atributosParaAsignar) === JSON.stringify(attrs)
                )
              })
            ) {
              if (!rubro.codRubro || !rubro.nombreAnalytics) return

              const existeValor = RubroYValores.some((r) => attrs.every((a) => r.atributosParaAsignar.includes(a)))

              if (!existeValor)
                RubroYValores.push({
                  rubro: {
                    codRubro: rubro.codRubro,
                    nombre: rubro.nombreAnalytics
                  },
                  atributosDefault: {
                    key,
                    all: attribute,
                    valores: valores
                  },
                  atributosParaAsignar: attrs
                })
            }
          }
        }
      })

      const r = RubroYValores.filter((r) => r.atributosParaAsignar.length > 0)

      setRubroYValores(r)
    },
    [rubrosFlatted]
  )

  const handleChange = useCallback((attrName: string, value: ValoresList, attrsRubro: ProductoAtributoList) => {
    setAtributosToSend((prev) => {
      const found = prev.find((p) => p.idIntegracionAtributo === value.id && p.nombreAtributoValorExterno === attrName)
      if (found) {
        found.nombreAtributoValorExterno = attrName
        found.idProductoAtributo = attrsRubro.idProductoAtributo
        found.idIntegracionAtributo = 0
      } else {
        prev.push({
          idProductoAtributoValorNormalizado: value.id,
          nombreAtributoValorExterno: attrName,
          idIntegracionAtributo: 0,
          idProductoAtributo: attrsRubro.idProductoAtributo
        })
      }

      return prev
    })
  }, [])

  const callbackAsignarColumnas = useCallback(async (productos?: IProductoTienda[]) => {
    if (productos) await handleConfirmProducts({ productos })
    setAtributosSincronizar([])
    setColumnasEquivalencias([])
    SnackBarUtils.success('Columnas asignadas correctamente')
  }, [])

  const _setAtributos = async ({
    rubro,
    rubros,
    productos
  }: {
    rubro: Rubro
    rubros: Rubro[]
    productos?: IProductoTienda[]
  }): Promise<{ final_attrs: any; atributosSincronizar: any } | undefined> => {
    const { data: dataAttrs } = await GetAtributosEquivalencias(codLocal)
    setAtributosTemplate(dataAttrs || null)

    let newAtributosGlobal: Atributo[] = []
    const atributosSincronizar: Columna[] = []
    const final_attrs: any[] = []
    const colores: string[] = []
    const talles: string[] = []
    const composicion: string[] = []
    const medida: string[] = []
    const tamano: string[] = []

    const prods = productos && productos.length > 0 ? productos : productosAConfirmar

    if (prods && prods.length > 0) {
      newAtributosGlobal = await getAtributos()
    }

    prods.forEach((producto) => {
      producto.atributos.forEach((atributo) => {
        const match = dataAttrs.find(
          (tem) =>
            tem.nombreAa1tPlural.toLowerCase() === atributo.name.toLowerCase() ||
            tem.nombreAa1tSingular.toLowerCase() === atributo.name.toLowerCase() ||
            tem.nombreAtributoExterno?.toLowerCase() === atributo.name.toLowerCase()
        )

        if (!match) atributosSincronizar.push({ nombre: atributo.name, valor: '' })
        else {
          switch (match.nombreAa1tPlural.toLowerCase()) {
            case 'colores':
              colores.push(...atributo.options)
              break
            case 'talles':
              talles.push(...atributo.options)
              break
            case 'medidas':
              medida.push(...atributo.options)
              break
            case 'composiciones':
              composicion.push(...atributo.options)
              break
            case 'tamaños':
              tamano.push(...atributo.options)
              break
            default:
              break
          }

          const newAttr = { [rubro.codRubro]: { colores, talles, composicion, medida, tamano } }

          setAtributos([...new Set([...atributos, newAttr])])
          if (!final_attrs.find((a) => a[rubro.codRubro])) final_attrs.push(newAttr)
        }
      })

      if (atributosSincronizar.length === 0) {
        _setRubroYValores({ atributos: final_attrs, rubros: flatRubros(rubros) })
        _setAtributosValores(final_attrs, newAtributosGlobal)
      } else {
        const noRepeated = atributosSincronizar.filter(
          (a, i, self) => i === self.findIndex((t) => t.nombre === a.nombre)
        )
        setAtributosSincronizar(noRepeated)
      }
    })

    return { atributosSincronizar, final_attrs }
  }

  const _setAtributosValores = useCallback(
    async (atributos: Atributos[], atributosGlobal: Atributo[]) => {
      let SetAttrsValoresASincronizar: any[] = []
      if (atributos.length > 0 || atributosValores.length > 0 || RubroYValores.length > 0) {
        const attrs = atributos.map((a) => Object.values(a)[0])

        const attrsValoresASincronizar: any = []

        const [coloresTienda, tallesTienda, medidaTienda, tamanoTienda, composicionTienda] = await Promise.all([
          ObtenerAtributosValoresEquivalencias(codLocal, 'color', atributosGlobal),
          ObtenerAtributosValoresEquivalencias(codLocal, 'talle', atributosGlobal),
          ObtenerAtributosValoresEquivalencias(codLocal, 'medida', atributosGlobal),
          ObtenerAtributosValoresEquivalencias(codLocal, 'tamano', atributosGlobal),
          ObtenerAtributosValoresEquivalencias(codLocal, 'composicion', atributosGlobal)
        ])

        attrs.forEach((a) => {
          a.colores.forEach((c) => {
            const found =
              atributosValores.find((v) => v.nombreAtributoValorExterno.toLowerCase() === c.toLowerCase()) ||
              RubroYValores.find(
                (r) =>
                  r.atributosDefault.key === 'colores' &&
                  r.atributosDefault.all.valoresList.find((x) => x.nombre.toLowerCase() === c.toLowerCase())
              ) ||
              RubroYValores.every((r) => r.atributosDefault.key !== 'colores') ||
              (coloresTienda && coloresTienda.find((v) => v.nombreAtributoValorExterno === c))
            if (!found) {
              attrsValoresASincronizar.push(c)
            }
          })

          a.talles.forEach((t) => {
            const found =
              atributosValores.find((v) => v.nombreAtributoValorExterno.toLowerCase() === t.toLowerCase()) ||
              RubroYValores.find((r) => r.atributosDefault.key === 'talles' && r.atributosParaAsignar.includes(t)) ||
              RubroYValores.every((r) => r.atributosDefault.key !== 'talles') ||
              (tallesTienda && tallesTienda.find((v) => v.nombreAtributoValorExterno === t))
            if (!found) {
              attrsValoresASincronizar.push(t)
            }
          })

          a.composicion.forEach((c) => {
            const found =
              atributosValores.find((v) => v.nombreAtributoValorExterno.toLowerCase() === c.toLowerCase()) ||
              RubroYValores.find(
                (r) => r.atributosDefault.key === 'composicion' && r.atributosParaAsignar.includes(c)
              ) ||
              RubroYValores.every((r) => r.atributosDefault.key !== 'composicion') ||
              (composicionTienda && composicionTienda.find((v) => v.nombreAtributoValorExterno === c))
            if (!found) {
              attrsValoresASincronizar.push(c)
            }
          })

          a.medida.forEach((m) => {
            const found =
              atributosValores.find((v) => v.nombreAtributoValorExterno.toLowerCase() === m.toLowerCase()) ||
              RubroYValores.find((r) => r.atributosDefault.key === 'medida' && r.atributosParaAsignar.includes(m)) ||
              RubroYValores.every((r) => r.atributosDefault.key !== 'medida') ||
              (medidaTienda && medidaTienda.find((v) => v.nombreAtributoValorExterno === m))
            if (!found) {
              attrsValoresASincronizar.push(m)
            }
          })

          a.tamano.forEach((t) => {
            const found =
              atributosValores.find((v) => v.nombreAtributoValorExterno.toLowerCase() === t.toLowerCase()) ||
              RubroYValores.find((r) => r.atributosDefault.key === 'tamano' && r.atributosParaAsignar.includes(t)) ||
              RubroYValores.every((r) => r.atributosDefault.key !== 'tamano') ||
              (tamanoTienda && tamanoTienda.find((v) => v.nombreAtributoValorExterno === t))
            if (!found) {
              attrsValoresASincronizar.push(t)
            }
          })
        })

        SetAttrsValoresASincronizar = [...new Set(attrsValoresASincronizar)]
        setAtributosValoresASincronizar(SetAttrsValoresASincronizar)
      }

      return SetAttrsValoresASincronizar
    },
    [atributosValores, RubroYValores, codLocal]
  )

  const sincronizarValoresAtributos = useCallback(
    async (productos?: IProductoTienda[]) => {
      setAtributosTemplate([])
      setAtributosValores([])
      setLoading(true)
      try {
        await SaveValoresAtributos(atributosToSend, codLocal)
        const { valores } = await _setTemplate()
        await handleConfirmProducts({ productos, valores })
        SnackBarUtils.success('Valores sincronizados correctamente')
      } catch (error) {
        SnackBarUtils.error('Error al sincronizar valores: ' + error.message || error || '')
      } finally {
        setLoading(false)
        setAtributosValoresASincronizar([])
        setAtributosValores([])
        setAtributosToSend([])
        setPasoAtributos(PasosAtributos.Colores)
      }
    },
    [atributosToSend, codLocal, setPasoAtributos]
  )

  const checkCanSave = useCallback(
    ({ atributosSincronizar, valoresSincronizar }: { atributosSincronizar: any; valoresSincronizar: any }) =>
      atributosSincronizar.length === 0 && valoresSincronizar.length === 0,
    []
  )

  const handleConfirmProducts = useCallback(
    async ({ productos, valores }: { productos?: IProductoTienda[]; valores?: any[] }) => {
      setLoading(true)

      let atributosSincronizar: any[] = []
      let valoresSincronizar: any[] = []

      try {
        const newAtributosGlobal = await getAtributos()
        const productosProcesados = []

        for (const p of productos || productosAConfirmar) {
          if (!p.codRubro) {
            SnackBarUtils.error('El producto no tiene rubro')
            continue
          }

          const rubroEncontrado = findRubro(p.codRubro, rubros)

          // Validar que los atributos ya estén sincronizados, sino, salta Dialog
          const data = await _setAtributos({
            rubro: rubroEncontrado,
            rubros,
            productos
          })

          // Validar que los valores de los atributos ya estén sincronizados, sino, salta Dialog
          if (data) {
            atributosSincronizar = data.atributosSincronizar
            if (atributosSincronizar.length === 0) {
              const dataAtributosValoresASincronizar = await _setAtributosValores(data.final_attrs, newAtributosGlobal)

              if (dataAtributosValoresASincronizar) {
                valoresSincronizar = dataAtributosValoresASincronizar
              }
            }
          }

          // Setear los valores de los atributos sincronizados
          p.atributos = p.atributos.map((pa) => ({
            ...pa,
            options: pa.options.map((o) => {
              const foundOption = (valores || atributosValores).find((av) => av.nombreAtributoValorExterno === o)
              return foundOption ? foundOption.nombreValorAa1t : o
            })
          }))

          p.aclaracionPrecio = p.precio

          productosProcesados.push(p)
        }

        if (!checkCanSave({ atributosSincronizar, valoresSincronizar })) return

        await SaveProductosAA1T(tiendaSeleccionada.name, codLocal, productosProcesados, tenant)
        // SnackBarUtils.success('Productos publicados con éxito!')
        setOpenDrawer(false)
        setProductosAConfirmar([])
        setCantProductosAA1T(0)
        resetTemplates()
      } catch (error) {
        SnackBarUtils.error('Ocurrió un error al publicar los productos: ' + error)
        resetTemplates()
      } finally {
        setLoading(false)
      }
    },
    [productosAConfirmar, tiendaSeleccionada, codLocal]
  )

  const _setTemplateValores = useCallback(async (atributos?: Atributo[]) => {
    const promesas = (atributos || atributosTemplate)
      .filter((a) => a.idIntegracionAtributo !== 0) // Filtrar los atributos válidos
      .map((a) => GetAtributosEquivalenciasValores(a.idIntegracionAtributo, codLocal).then(({ data }) => data))

    // Resolver todas las promesas en paralelo
    const resultados = await Promise.all(promesas)
    // Combinar los resultados en un solo array
    const resAttrsValores = resultados.flat()
    return resAttrsValores
  }, [])

  const _setTemplate = useCallback(async () => {
    if (atributosTemplate.length > 0 || atributosValores.length > 0) return

    setLoading(true)
    try {
      let valores = null
      const { data: atributos } = await GetAtributosEquivalencias(codLocal)
      setAtributosTemplate(atributos || null)

      if (atributos) {
        valores = await _setTemplateValores(atributos)
        setAtributosValores(valores)
      }

      return { atributos, valores }
    } catch (error) {
      SnackBarUtils.error('Error al obtener el template de atributos y valores')
    } finally {
      setLoading(false)
    }
  }, [codLocal, atributosTemplate, atributosValores])

  const resetTemplates = useCallback(() => {
    setAtributosTemplate([])
    setAtributosValores([])
    setColumnasEquivalencias([])
    setAtributosValoresASincronizar([])
    setAtributos([])
    setRubroYValores([])
    setAtributosSincronizar([])
    setPasoAtributos(PasosAtributos.Colores)
  }, [])

  useEffect(() => {
    _setTemplate()
  }, [_setTemplate, atributosSincronizar])

  if (loadingLocal) return null

  return (
    <>
      {cantProductosAA1T > 0 && (
        <>
          {/* Button que abre el carrito */}
          <ButtonOpenCarrito setOpenDrawer={setOpenDrawer} cantProductosAA1T={cantProductosAA1T} />

          {/* Drawer Carrito */}
          <CarritoPublicaciones
            cantOfertasDisp={cantOfertasDisp}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            cantProductosAA1T={cantProductosAA1T}
            handleConfirmProducts={handleConfirmProducts}
            loading={loading}
            productosAConfirmar={productosAConfirmar}
          />
        </>
      )}

      {/* Dialog Sincronizar Valores */}
      <AlertDialog
        fullWidth
        maxWidth="md"
        open={atributosValoresASincronizar.length > 0}
        title="Sincronizar valores atributos"
        hasActions={false}
        onClose={resetTemplates}
      >
        <>
          <Box sx={{ overflowY: 'auto', maxHeight: '500px' }}>
            {RubroYValores.map((r, i) => (
              <AsignarAtributo
                key={i}
                rubro={r}
                paso={pasoAtributos}
                handleChange={handleChange}
                handleNext={handleNextAttr}
                equivalencias={atributosEquivalencias}
                atributosValores={atributosValores}
              />
            ))}
          </Box>
          <BotonesAtributos
            setPaso={setPasoAtributos}
            loading={loading}
            paso={Pasos.AsignarAtributos}
            pasoAtributos={pasoAtributos}
            atributos={atributos}
            callback={async () => {
              await sincronizarValoresAtributos(productosAConfirmar)
              setAtributosValoresASincronizar([])
              setPasoAtributos(PasosAtributos.Colores)
            }}
            RubroYValores={RubroYValores}
            loadingTextButton="Sincronizando"
            textButton="Sincronizar"
          />
        </>
      </AlertDialog>

      {/* Dialog Sincronizar Atributos */}
      <AlertDialog
        fullWidth
        maxWidth="md"
        open={atributosSincronizar.length > 0}
        title="Sincronizar atributos"
        hasActions={false}
        onClose={resetTemplates}
      >
        <AsignarColumnasImportacion
          columnas={atributosSincronizar}
          equivalencias={columnasEquivalencias}
          setEquivalencias={setColumnasEquivalencias}
          setAtributosSincronizar={setAtributosSincronizar}
          atributosTemplate={atributosTemplate}
          callback={() => callbackAsignarColumnas(productosAConfirmar)}
        />
      </AlertDialog>
    </>
  )
}

export default memo(TiendaAA1T)
