import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import * as yup from 'yup'
import { useEffect, useState, useContext } from 'react'
import {
  CambiarPassword,
  EnviarCodigo,
  ObtenerUsuario,
  ValidarCodigo
} from '../services/recuperar.service'
import Paso1 from './Paso1'
import Paso2 from './Paso2'
import Paso3 from './Paso3'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { LoginService } from '../services/login.service'
import { TenantsContext } from '../../../context/tenants.context'

const validationEmail = yup
  .string()
  .email('El mail no es válido')
  .typeError('El mail es requerido')
const validationTelefono = yup.string().matches(/^(?!15|0|54)\d{10}$/, {
  message: 'Número inválido',
  excludeEmptyString: true
})
const TIEMPO_ESPERA = 60

interface RecuperarPasswordProps {
  open: boolean
  setOpen: (open: boolean) => void
  defaultValue?: string
  props: any
}

enum Pasos {
  EMAIL = 1,
  CODIGO = 2,
  PASSWORD = 3
}

export interface PasosProps {
  defaultValue?: string
  value: string
  setValue: (value: string) => void
  isValid: boolean
  setIsValid: (isValid: boolean) => void
  isSubmitting: boolean
  handleConfirm: () => void
}

const RecuperarPassword = ({
  open,
  setOpen,
  defaultValue,
  props
}: RecuperarPasswordProps) => {
  const [isValid, setIsValid] = useState(true)
  const [value, setValue] = useState(defaultValue || '')
  const [repeatValue, setRepeatValue] = useState('')
  const [lastValue, setLastValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState('')
  const [msgError, setMsgError] = useState('')
  const [paso, setPaso] = useState(Pasos.EMAIL)
  const [tiempoDeEspera, setTiempoDeEspera] = useState(TIEMPO_ESPERA)
  const [errorCode, setErrorCode] = useState(0)
  const [token, setToken] = useState('')
  const [usuarioId, setUsuarioId] = useState(0)
  const [noSms, setNoSms] = useState(false)
  const { tenant } = useContext(TenantsContext)

  const handleConfirm = async () => {
    if (!value) return
    try {
      if (paso === Pasos.EMAIL) {
        setIsSubmitting(true)
        if (
          !validationEmail.isValidSync(value) &&
          !validationTelefono.isValidSync(value)
        ) {
          setIsSubmitting(false)
          return setIsValid(false)
        }

        setIsValid(true)
        const res = await EnviarCodigo(value, noSms, tenant)
        setErrorCode(res.data.errorCode)

        setLastValue(value)
        setValue('')
        setMsgSuccess(
          res.data.message + ' (Puede tardar unos minutos en llegar)'
        )
        setMsgError('')
        setPaso(Pasos.CODIGO)
      }
      if (paso === Pasos.CODIGO) {
        //validar codigo
        if (value.split('').some((c) => c === ' ')) return
        const res = await ValidarCodigo(value, tenant)
        setToken(value)
        setUsuarioId(res.data.data)
        setValue('')
        setMsgSuccess('')
        setMsgError('')
        setPaso(Pasos.PASSWORD)
      }
      if (paso === Pasos.PASSWORD) {
        if (value !== repeatValue)
          return setMsgError('Las contraseñas no coinciden')

        const res = await CambiarPassword(value, token, tenant)
        SnackBarUtils.success(res.data.message)
        setPaso(1)
        setOpen(false)
        setMsgSuccess('')
        setMsgError('')
        const userRes = await ObtenerUsuario(usuarioId, tenant)
        const user = userRes.data.user
        const resLogin = await LoginService({ user, pwd: value, captcha: '' }, tenant)
        // eslint-disable-next-line react/prop-types
        props.setLogged(resLogin.data)
      }
    } catch (error: any) {
      console.log(error)
      setMsgError(error.response.data.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCancel = () => {
    switch (paso) {
      case Pasos.EMAIL:
        setOpen(false)
        break
      case Pasos.CODIGO:
        setPaso(Pasos.EMAIL)
        setValue(lastValue)
        break
      case Pasos.PASSWORD:
        setPaso(Pasos.EMAIL)
        setOpen(false)
        break
      default:
        break
    }
  }

  const handleSendMail = async () => {
    try {
      setIsSubmitting(true)
      setNoSms(true)
      const res = await EnviarCodigo(lastValue, true, tenant)
      setMsgSuccess(res.data.message)
      setMsgError('')
      setErrorCode(0)
    } catch (error: any) {
      console.log(error)
      setMsgError(error.response.data.message)
      setMsgSuccess('')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleNoRecibi = async () => {
    try {
      setIsSubmitting(true)
      const res = await EnviarCodigo(lastValue, noSms, tenant)
      setTiempoDeEspera(TIEMPO_ESPERA)
      setMsgSuccess(res.data.message)
      setMsgError('')
    } catch (error: any) {
      console.log(error)
      setMsgError(error.response.data.message)
      setMsgSuccess('')
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (paso === Pasos.CODIGO && tiempoDeEspera > 0) {
      const interval = setInterval(() => {
        setTiempoDeEspera(tiempoDeEspera - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [tiempoDeEspera, paso])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>Recuperar contraseña</DialogTitle>
      <DialogContent>
        {msgSuccess && (
          <div
            dangerouslySetInnerHTML={{ __html: msgSuccess }}
            style={{ maxWidth: '100%' }}
          />
        )}
        {paso === Pasos.EMAIL ? (
          <Paso1
            isSubmitting={isSubmitting}
            isValid={isValid}
            setIsValid={setIsValid}
            setValue={setValue}
            value={value}
            defaultValue={defaultValue}
            handleConfirm={handleConfirm}
          />
        ) : paso === Pasos.CODIGO ? (
          <Paso2
            isSubmitting={isSubmitting}
            isValid={isValid}
            setIsValid={setIsValid}
            setValue={setValue}
            value={value}
            handleConfirm={handleConfirm}
          />
        ) : (
          <Paso3
            isSubmitting={isSubmitting}
            setValue={setValue}
            setRepeatValue={setRepeatValue}
            repeatValue={repeatValue}
            isValid={isValid}
            setIsValid={setIsValid}
            value={value}
            handleConfirm={handleConfirm}
          />
        )}
        {msgError && (
          <div
            dangerouslySetInnerHTML={{ __html: msgError }}
            style={{ maxWidth: '100%', color: '#d53839' }}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection:
            paso === Pasos.EMAIL || paso === Pasos.PASSWORD ? 'row' : 'column',
          alignItems: 'flex-end',
          padding: '12px 24px'
        }}
      >
        <Button disabled={isSubmitting} onClick={handleCancel} color="inherit">
          {paso === Pasos.EMAIL || paso === Pasos.PASSWORD
            ? 'Cancelar'
            : 'Volver'}
        </Button>

        {paso === Pasos.CODIGO && (
          <>
            <Button
              disabled={isSubmitting || tiempoDeEspera > 0}
              color="primary"
              onClick={handleNoRecibi}
            >
              No recibí el código{' '}
              {tiempoDeEspera > 0 && `(Aguarda ${tiempoDeEspera} segundos)`}
            </Button>

            {errorCode === 1 && (
              <Button
                disabled={isSubmitting || tiempoDeEspera > 0}
                color="primary"
                onClick={handleSendMail}
              >
                Enviar por mail
              </Button>
            )}
          </>
        )}

        <Button
          disabled={isSubmitting || !isValid || value === ''}
          onClick={handleConfirm}
          color="success"
        >
          {isSubmitting ? 'Buscando' : 'Continuar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RecuperarPassword
