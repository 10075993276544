import { Box, Button, CardActions as CardActionsMUI } from '@mui/material'
import { useCallback, useContext } from 'react'
import { BUTTONS } from '../../Desktop/Tabla'
import { ITenant } from '../../../../AbmLocalPage/types'
import { TenantsContext } from '../../../../../context/tenants.context'

interface CardActionsProps {
  product: any
  isAdmin: boolean
  handleResumeProduct: (code: string, tenant: ITenant) => void
  handlePauseProduct: (code: string, tenant: ITenant) => void
  handleDeleteProduct: (code: string, tenant: ITenant) => void
}

const CardActions = ({
  handleDeleteProduct,
  handlePauseProduct,
  handleResumeProduct,
  isAdmin,
  product
}: CardActionsProps) => {
  const { tenant } = useContext(TenantsContext)
  const handleDeleteClick = useCallback(() => {
    handleDeleteProduct(product.codProducto, tenant)
  }, [product.codProducto, handleDeleteProduct])

  return (
    <CardActionsMUI
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '12px'
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        {product.pausado ? (
          <Button
            variant="text"
            startIcon={BUTTONS.resume.icon}
            onClick={() => handleResumeProduct(product.codProducto, tenant)}
          >
            {BUTTONS.resume.text}
          </Button>
        ) : (
          <Button
            variant="text"
            startIcon={BUTTONS.pause.icon}
            onClick={() => handlePauseProduct(product.codProducto, tenant)}
          >
            {BUTTONS.pause.text}
          </Button>
        )}

        {isAdmin && (
          <Button variant="text" startIcon={BUTTONS.approve.icon} onClick={() => BUTTONS.approve.action}>
            {BUTTONS.approve.text}
          </Button>
        )}

        <Button variant="text" startIcon={BUTTONS.delete.icon} onClick={handleDeleteClick} color="error">
          {BUTTONS.delete.text}
        </Button>
      </Box>
    </CardActionsMUI>
  )
}

export default CardActions
