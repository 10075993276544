import React, { useState, useCallback, memo, useEffect, useRef } from 'react'

// components
import { Box, Button, Grid, IconButton } from '@mui/material'

// redux
import { connect } from 'react-redux'
import setRedirectUrl from '../../../redux/actions/redirection/setRedirectUrl'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { ArrowBackIosOutlined } from '@mui/icons-material'
import { IAnuncio } from '../hooks/anuncios.hook'
import DialogDetalleAnuncio from './DialogDetalleAnuncio'

const Anuncios: React.FC<{ isMobile: boolean; anuncios: IAnuncio[] }> = ({
  isMobile,
  anuncios
}) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(0)
  const [imageHeight, setImageHeight] = useState<number>(0)
  const [openDetalleAnuncio, setOpenDetalleAnuncio] = useState<boolean>(false)
  const [anuncioSeleccionado, setAnuncioSeleccionado] = useState<IAnuncio>()
  const slideshow = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const intervaloSlideshow = useRef<NodeJS.Timeout | null>(null)

  const handleClickDetalle = (anuncio: IAnuncio) => {
    setAnuncioSeleccionado(anuncio)
    if (anuncio.detalleImagenList) {
      setOpenDetalleAnuncio(true)
    } else {
      anuncio.detalleLink && window.open(anuncio.detalleLink)
    }
  }

  const handlePreviousBanner = () => {
    if (slideshow.current && slideshow.current.children.length > 0) {
      setIndex((prevIndex) =>
        prevIndex === 0 ? anuncios.length - 1 : prevIndex - 1
      )
      const ultimoElemento = slideshow.current.children[
        slideshow.current.children.length - 1
      ] as HTMLElement
      slideshow.current.insertBefore(
        ultimoElemento,
        slideshow.current.firstChild
      )
      slideshow.current.style.transition = 'none'
      const tamañoSlide = (slideshow.current.children[0] as HTMLElement)
        .offsetWidth
      slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`

      setTimeout(() => {
        slideshow.current!.style.transition = `1s ease-out all`
        slideshow.current!.style.transform = `translateX(0)`
      }, 30)
    }
  }
  const handleNextBanner = useCallback(() => {
    if (slideshow.current && slideshow.current.children.length > 0) {
      setIndex((prevIndex) => (prevIndex + 1) % anuncios.length)
      const primerElemento = slideshow.current.children[0] as HTMLElement
      slideshow.current.style.transition = `1s ease-out all`
      const tamañoSlide = primerElemento.offsetWidth
      slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`

      const transicion = () => {
        slideshow.current!.style.transition = 'none'
        slideshow.current!.style.transform = `translateX(0)`
        slideshow.current!.appendChild(primerElemento)
        slideshow.current!.removeEventListener('transitionend', transicion)
      }

      slideshow.current.addEventListener('transitionend', transicion)
    }
  }, [])

  useEffect(() => {
    if (
      anuncios.length > 1 &&
      intervaloSlideshow.current === null &&
      slideshow.current
    ) {
      intervaloSlideshow.current = setInterval(() => {
        handleNextBanner()
      }, 4000)

      slideshow.current.addEventListener('mouseenter', () => {
        if (intervaloSlideshow.current) {
          clearInterval(intervaloSlideshow.current)
          intervaloSlideshow.current = null
        }
      })

      slideshow.current.addEventListener('mouseleave', () => {
        if (!intervaloSlideshow.current) {
          intervaloSlideshow.current = setInterval(() => {
            handleNextBanner()
          }, 4000)
        }
      })
    }

    return () => {
      if (intervaloSlideshow.current) {
        clearInterval(intervaloSlideshow.current)
        intervaloSlideshow.current = null
      }
    }
  }, [handleNextBanner])

  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.offsetHeight)
    }
  }, [index, isMobile, anuncios])

  return (
    <>
      <Box
        sx={{
          height: isMobile ? 'auto' : '250px',
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
          ...(isMobile && {
            flexDirection: 'column'
          })
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {anuncios.length > 0 && (
          <>
            <div
              ref={slideshow}
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                width: anuncios.length === 1 ? '100%' : 'auto'
              }}
            >
              {anuncios.map((anuncio, i) => (
                <div
                  key={anuncio.idAnuncio}
                  style={{
                    minWidth: '100%',
                    overflow: 'hidden',
                    transition: '.3s ease all',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => handleClickDetalle(anuncio)}
                >
                  <img
                    ref={i === index ? imageRef : null}
                    key={anuncio.idAnuncio}
                    src={
                      anuncio.urlImagenWide
                        ? isMobile
                          ? anuncio.urlImagen
                          : anuncio.urlImagenWide
                        : anuncio.urlImagen
                    }
                    alt={`Ad ${i + 1}`}
                    loading="lazy"
                    aria-hidden={index !== i}
                    style={{
                      objectFit: 'cover',
                      height: isMobile ? '100%' : '250px',
                      width: '100%',
                      cursor:
                        anuncio.detalleImagenList || anuncio.detalleLink
                          ? 'pointer'
                          : 'default'
                    }}
                  />
                </div>
              ))}
              {anuncioSeleccionado?.detalleImagenList && (
                <DialogDetalleAnuncio
                  open={openDetalleAnuncio}
                  setOpen={setOpenDetalleAnuncio}
                  anuncio={anuncioSeleccionado}
                  detalle={anuncioSeleccionado.detalleImagenList}
                  isMobile={isMobile}
                />
              )}
            </div>
            {anuncios.length > 1 && (
              <>
                {!isMobile && hovered && (
                  <Grid
                    container
                    style={{
                      position: 'fixed',
                      top: `${imageHeight - 50}px`,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <>
                      <IconButton
                        style={{
                          borderRadius: '0 50% 50% 0',
                          backgroundColor: 'white',
                          cursor: 'pointer',
                          padding: '25px 30px'
                        }}
                        onClick={handlePreviousBanner}
                      >
                        <ArrowBackIosOutlined
                          fontSize="small"
                          style={{ color: 'blue' }}
                        />
                      </IconButton>

                      <IconButton
                        style={{
                          borderRadius: '50% 0 0 50%',
                          backgroundColor: 'white',
                          cursor: 'pointer',
                          padding: '25px 30px'
                        }}
                        onClick={handleNextBanner}
                      >
                        <ArrowForwardIosOutlinedIcon
                          fontSize="small"
                          style={{ color: 'blue' }}
                        />
                      </IconButton>
                    </>
                  </Grid>
                )}

                <Grid
                  container
                  justifyContent="center"
                  style={{
                    position: 'absolute',
                    top: isMobile
                      ? `${imageHeight - 16}px`
                      : `${imageHeight - 14}px`,
                    width: '100%',
                    margin: '0 5px',
                    gap: '4px',
                    zIndex: 1100
                  }}
                >
                  {anuncios.map((_, i) => (
                    <div
                      key={i}
                      style={{
                        width: '7px',
                        height: '7px',
                        borderRadius: '50%',
                        backgroundColor:
                          i === index ? 'white' : 'rgba(0,0,0,.25)',
                        border: '1px solid',
                        borderColor: i === index ? 'rgba(0,0,0,.25)' : 'white'
                      }}
                    />
                  ))}
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(Anuncios))
