import axios from "axios";
import { MAIN_URL, COMUNICACIONES_URL, CONTRATOS_URL, INTEGRACIONES_URL } from "../utils/config";
import store from "../redux/store";
import SnackBarUtils from "./SnackBarUtils";
import { consultarConNuevoToken } from "./utils";
import { TenantsContext } from "../context/tenants.context";
import { useContext } from "react";

export const headers = {
  "Cache-Control": "no-cache",
  Accept: "application/vnd.aa1t.main-v2+json",
  "Content-Type": "application/json",
};

export const axiosInstanceIntegraciones = axios.create({ baseURL: INTEGRACIONES_URL, headers });
export const axiosInstanceComunicaciones = axios.create({ baseURL: COMUNICACIONES_URL, headers });
export const axiosInstanceMain = axios.create({ baseURL: MAIN_URL, headers });
export const axiosInstanceContratos = axios.create({ baseURL: CONTRATOS_URL, headers });

const requestHandler = (request: any) => {
  // Modify request here...
  // este acceso al store sólo es posible si se exporta el store, cosa que está mal si se usa server-side-rendering
  if (store.getState().logged["userLogged"]["token"]) {
    request.headers["Authorization"] = "Bearer " + store.getState().logged["userLogged"]["token"]; // .logged.userLogged.token;
  }
  return request;
};

export const errorHandler = async (error: any) => {
  const config = error.config;
  if (error.response) {
    if (error.response.status === 401) {
      if (store.getState().logged["userLogged"]["refreshToken"]) return await consultarConNuevoToken(config);
      else return await Promise.reject({ ...error });
    }

    const msg = error.response.data.message || error.response.data.errorMessage;
    if (msg && !msg.includes("<") && !msg.includes(">"))
      SnackBarUtils.error(error.response.data.message || "Ha ocurrido un error");
  }

  return await Promise.reject({ ...error });
};

const successHandler = (response: any) => response;

axiosInstanceMain.interceptors.request.use((request) => requestHandler(request));
axiosInstanceMain.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

axiosInstanceComunicaciones.interceptors.request.use((request) => requestHandler(request));
axiosInstanceComunicaciones.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

axiosInstanceContratos.interceptors.request.use((request) => requestHandler(request));
axiosInstanceContratos.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

axiosInstanceIntegraciones.interceptors.request.use((request) => requestHandler(request));
axiosInstanceIntegraciones.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
