import { Checkbox, Divider, Grid, Typography } from "@mui/material";
import { postLocalType } from "../../types";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import TyCDialog from "../../widgets/TyCDialog";
import { useState } from "react";
import { Asterisco } from ".";
import { LocalVendedorDTO } from "../../../../dto/LocalDTO";

interface FormTyCProps {
  register: UseFormRegister<postLocalType>;
  local?: LocalVendedorDTO;
  errors: FieldErrors<postLocalType>;
  isSubmitting: boolean;
}

const FormTyC = ({ isSubmitting, register, local, errors }: FormTyCProps) => {
  const [modalOpenAceptarTyC, setModalOpenAceptarTyC] = useState(false);

  return (
    <>
      <TyCDialog
        open={modalOpenAceptarTyC}
        handleClose={() => setModalOpenAceptarTyC(false)}
      />
      {((local && !local.aceptaTyC) || !local) && (
        <>
          <Divider sx={{ mt: "1.5em" }} />
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            {/* checkbox para aceptar los tyc */}
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Checkbox {...register("aceptaTyC")} disabled={isSubmitting} />
              <span>
                He leído y acepto los
                <span
                  onClick={() => setModalOpenAceptarTyC(true)}
                  style={{
                    margin: "0px 4px",
                    color: "#156da0",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  términos y condiciones
                </span>
                de uso <Asterisco />
              </span>
            </Grid>
            {errors.aceptaTyC && (
              <Typography sx={{ pl: "67px" }} variant="caption" color="#d53839">
                {errors.aceptaTyC.message}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default FormTyC;
