import React from "react";
import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { StickersFechaMasivas } from "../types/sticker";
import { ITenant } from "../../AbmLocalPage/types";

export const getListadoStickers = async (tenant: ITenant) => {
  try {
    const stickers = await axiosInstanceMain.get(`/admin/${tenant.path}/locales/stickers-a-pegar`);
    return stickers.data;
  } catch {
    console.log();
  }
};

export const actualizaFechaStickers = async (fechasMasivas: StickersFechaMasivas | undefined, tenant: ITenant) => {
  return await axiosInstanceMain.post(`/admin/${tenant.path}/locales/fecha-puso-sticker-masivo`, fechasMasivas);
};
