import { CircularProgress, CircularProgressProps } from "@mui/material";

interface Props extends CircularProgressProps {
  center?: boolean;
}

const Loading = ({ center, ...props }: Props) => (
  <CircularProgress
    sx={{
      margin: "20px",
      alignSelf: center ? "center" : "flex-start",
      justifySelf: center ? "center" : "flex-start",
    }}
    {...props}
  />
);

export default Loading;
