import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DialogShareLinkProps {
  open: boolean;
  setOpen: (op: boolean) => void;
  shareLink: string;
  shareLinkMinorista: string;
  handleCopyShareLink: (link: string) => void;
}

const DialogShareLink = ({
  open,
  setOpen,
  shareLink,
  shareLinkMinorista,
  handleCopyShareLink,
}: DialogShareLinkProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
      <Box
        style={{
          display: "flex",
          margin: "6px 20px",
        }}
      >
        <IconButton
          edge="end"
          onClick={() => setOpen(false)}
          size="small"
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            marginRight: "1px",
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            padding: "6px",
            margin: "6px",
            display: "flex",
            gap: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleCopyShareLink(shareLink);
              setOpen(false);
            }}
            sx={{
              backgroundColor: "#fd2104",
              "&:hover": {
                backgroundColor: "#c30000",
              },
            }}
          >
            Mayorista
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleCopyShareLink(shareLinkMinorista);
              setOpen(false);
            }}
            sx={{
              backgroundColor: "#e3780c",
              "&:hover": {
                backgroundColor: "#b25c00",
              },
            }}
          >
            Minorista
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DialogShareLink;
