import React, { useCallback, useEffect, useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  IconButton,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { DeleteOutline, Add } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { v4 as uuidv4 } from "uuid";
import { OfrecerPremiumContext } from "../../../context/OfrecerPremium/ofrecerPremium.context";

// Cropper
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./editor.css";

// d&d
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SnackBarUtils from "../../../utils/SnackBarUtils";
import ExpandImageDialog from "./ExpandImage";

const DROPPER_ERROR_LABELS = {
  maxPhotos: "too-many-files",
  invalidFile: "file-invalid-type",
};

const Dropzone = ({ images, setImages, maxPhotos, ofrecerPremium }: any) => {
  const { onOpen } = useContext(OfrecerPremiumContext);
  const [expandImage, setExpandImage] = useState<boolean>(false);
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [imgSelected, setImgSelected] = useState<string | null>(null);

  const [toEdit, setToEdit] = useState<any>();
  const [crop, setCrop] = useState<Crop>();
  const [output, setOutput] = useState<any>(null);
  const [dropError, setDropError] = useState<string>();
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      setIsDragging(false);
      images.length < maxPhotos || !images
        ? acceptedFiles?.forEach(async (file: any) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = async () => {
              // if (file.size > 5242880)
              //   throw SnackBarUtils.error("El tamaño máximo de la imagen es de 5MB");
              // Do whatever you want with the file contents
              const binaryStr: any = reader.result;
              const blob: any = new Blob([binaryStr]);
              const url = URL.createObjectURL(blob);

              setImages((images: any) => [
                ...images,
                {
                  id: uuidv4(),
                  desc: file.name,
                  url,
                  blob,
                  bin: binaryStr,
                  raw: file,
                }
              ]);
            };
            reader.readAsArrayBuffer(file);
          })
        : setDropError(`La cantidad máxima de fotos es: ${maxPhotos}`);
    },
    [images]
  );

  const handleToEdit = (id: string) => {
    const img = images.find((a: any) => a.id === id);
    setToEdit(img);
  };

  const handleSave = (id: string) => {
    const update = images.find((a: any) => a.id === id);

    setToEdit(undefined);
    console.log("ENCONTRADO PARA GUARDAR", update);
    console.log("CORTE GUARDADO", crop);
  };

  const handleDelete = (id: string | number) => {
    if (typeof id === "number") setImages(images.filter((img: any) => img.idfotoProducto !== id));
    else setImages(images.filter((img: any) => img.id !== id));
  };

  const cropImageNow = (id: number) => {
    const { url } = images.find((a: any) => a.id === id);
    const image = new Image(url);
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop!.width;
    canvas.height = crop!.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop!.width * pixelRatio;
    canvas.height = crop!.height * pixelRatio;
    ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx!.imageSmoothingQuality = "high";

    ctx?.drawImage(
      image,
      crop!.x * scaleX,
      crop!.y * scaleY,
      crop!.width * scaleX,
      crop!.height * scaleY,
      0,
      0,
      crop!.width,
      crop!.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  const { getRootProps, open, getInputProps, fileRejections } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: maxPhotos - (images.length || 0),
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
  });

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    if (fileRejections.length === 0) return setDropError(undefined);
    fileRejections.map((files: any) =>
      files.errors.map((err: any) => {
        return err.code === DROPPER_ERROR_LABELS.maxPhotos
          ? setDropError(`La cantidad máxima de fotos es: ${maxPhotos}`)
          : err.code === DROPPER_ERROR_LABELS.invalidFile
          ? setDropError("Solamente podés subir archivos JPG, JPEG o PNG")
          : setDropError("Error desconocido");
      })
    );
  }, [fileRejections]);

  return (
    <>
      {isMobile && (
        <Button
          onClick={() => (images.length < maxPhotos || !images ? open() : onOpen())}
          disabled={images.length >= maxPhotos && !ofrecerPremium}
          variant="text"
          size="large"
          fullWidth
          sx={{ display: "flex", alignItems: "center", gap: "2px" }}
        >
          {images.length < maxPhotos || !images ? (
            <>
              Agregar Fotos
              <Add
                color={images.length >= maxPhotos ? "disabled" : "primary"}
                fontSize="medium"
                sx={{ marginBottom: "6px" }}
              />
            </>
          ) : (
            "Alcanzaste el límite de fotos." + (ofrecerPremium ? " Toca para ampliar" : "")
          )}
        </Button>
      )}
      <Box
        sx={{
          border: "2px dashed #d9d9d9",
          backgroundColor: "#fafafa",
          borderRadius: "4px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          ...(isDragging && {
            borderColor: "#007bff",
            backgroundColor: "#eee",
            backgroundImage: "repeating-linear-gradient(45deg, transparent, transparent 5px, #ccc 5px, #ccc 10px)",
            border: "2px dashed #007bff",
          }),
        }}
        {...getRootProps()}
        onDragOver={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
      >
        {!isMobile && (
          <Button
            onClick={() => (images.length < maxPhotos || !images ? open() : onOpen())}
            disabled={images.length >= maxPhotos && !ofrecerPremium}
            variant="text"
            size="large"
            fullWidth
            sx={{ display: "flex", alignItems: "center", gap: "2px" }}
          >
            {images.length < maxPhotos || !images
              ? "Arrastrá las imágenes o hacé click aquí"
              : "ALCANZASTE EL MÁXIMO DE FOTOS." + (ofrecerPremium ? " Hacé click para ampliar el límite" : "")}
          </Button>
        )}
        <Box
          sx={{
            textAlign: "center",
            cursor: "pointer",
            overflow: "auto",
          }}
        >
          <input {...getInputProps()} />
          <DragDropContext
            onDragEnd={(result: any) => {
              setIsDragging(false);
              const { source, destination } = result;
              if (!destination) return;
              if (source.index === destination.index && source.droppableId === destination.droppableId) return;
              setImages((imgs: any) => reorder(imgs, source.index, destination.index));
            }}
          >
            <Droppable droppableId="droppableImagesId" direction="horizontal">
              {(drop: any) => (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    width: "max-content",
                    height: "150px",
                    scrollDirection: "horizontal",
                  }}
                  {...drop.droppableProps}
                  ref={drop.innerRef}
                >
                  {images.map((item: any, i: number) => {
                    return (
                      <Draggable
                        isDragDisabled={images >= maxPhotos ? true : false}
                        key={item.idfotoProducto ? item.idfotoProducto * i : item.id}
                        draggableId={(item.idfotoProducto ? item.idfotoProducto * i : item.id).toString()}
                        index={i}
                      >
                        {(drag: any) => (
                          <Box
                            {...drag.draggableProps}
                            ref={drag.innerRef}
                            {...drag.dragHandleProps}
                            sx={{
                              width: "150px",
                              height: "150px",
                              backgroundImage: `url(${item.url_thumb || item.url})`,
                              backgroundSize: "cover",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                              ...(isMobile
                                ? {
                                    ...(showIcons && {
                                      backgroundColor: "rgba(248, 249, 250, 1)",
                                      "& > .iconButton": {
                                        opacity: "0.6",
                                        color: "white",
                                        backgroundColor: "blue",
                                        cursor: "pointer",
                                        "&:hover": { opacity: 1 },
                                      },
                                      "& > .foreground": {
                                        opacity: "0.8",
                                      },
                                    }),
                                  }
                                : {
                                    "&:hover": {
                                      backgroundColor: "rgba(248, 249, 250, 1)",
                                      "& > .iconButton": {
                                        opacity: "0.6",
                                        color: "white",
                                        backgroundColor: "blue",
                                        cursor: "pointer",
                                        "&:hover": { opacity: 1 },
                                      },
                                      "& > .foreground": {
                                        opacity: "0.8",
                                      },
                                    },
                                  }),
                            }}
                            onClick={() => {
                              setImgSelected(item.url);
                              setShowIcons(!showIcons);
                            }}
                          >
                            <Box
                              className="foreground"
                              sx={{
                                width: "150px",
                                height: "150px",
                                opacity: 0,
                                backgroundColor: "white",
                                position: "absolute",
                                display: isMobile ? (item.url === imgSelected ? "flex" : "none") : undefined,
                              }}
                            />

                            <IconButton
                              disabled={isMobile && !showIcons}
                              className="iconButton"
                              aria-label="expand"
                              size="medium"
                              sx={{
                                opacity: 0,
                                marginRight: "10px",
                                display: isMobile ? (item.url === imgSelected ? "flex" : "none") : undefined,
                              }}
                              onClick={() => {
                                setExpandImage(true);
                              }}
                            >
                              <VisibilityIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              disabled={isMobile && !showIcons}
                              className="iconButton"
                              aria-label="delete"
                              size="medium"
                              sx={{
                                opacity: 0,
                                display: isMobile ? (item.url === imgSelected ? "flex" : "none") : undefined,
                              }}
                              onClick={() => handleDelete(item.idfotoProducto || item.id)}
                            >
                              <DeleteOutline fontSize="inherit" />
                            </IconButton>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {drop.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
      {dropError !== undefined && (
        <Dialog open={dropError !== undefined} onClose={() => setDropError(undefined)} maxWidth="lg">
          <DialogTitle>Errores en la subida de imagenes</DialogTitle>
          <DialogContent>{dropError}</DialogContent>
          <DialogActions>
            <Button onClick={() => setDropError(undefined)}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      )}
      <ExpandImageDialog open={expandImage} setExpandImage={setExpandImage} imageUrl={imgSelected} isMobile={false} />
    </>
  );
};

export default Dropzone;
