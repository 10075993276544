import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useState } from "react";

interface PreDialogNotificacionesProps {
  open: boolean;
  onClose: () => void;
}

const PreDialogNotificaciones = ({ open, onClose }: PreDialogNotificacionesProps) => {
  const [hizoClick, setHizoClick] = useState(false);

  const handleClose = () => {
    setHizoClick(true);
    onClose();
  };

  return (
    <Dialog open={open && !hizoClick} onClose={() => {}}>
      <DialogContent sx={{ textAlign: "center" }}>
        <Typography variant="h5" fontWeight={"bold"}>
          Permiso para recibir notificaciones
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          Para asegurar el correcto funcionamiento de la app, necesitamos que aceptes el permiso para recibir
          notificaciones. Son obligatorios para que puedas gestionar tu cuenta.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose} fullWidth>
          <Typography variant="body1">OK</Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PreDialogNotificaciones;
