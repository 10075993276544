import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableFooter
} from "@mui/material";
import { format } from "date-fns";
import { DataReporte } from "../../types/reporte";
import ReportesPagination from "./paginadoReportes";
import Loading from "../../../../components/Common/Loading";

interface ReporteProps {
  reporte: DataReporte[];
  loading: boolean;
}

const INITIAL_ROWS_PER_PAGE = 10;

const TablaReporte: React.FC<ReporteProps> = ({ reporte, loading }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedReportes = reporte.slice(startIndex, endIndex);

  const keyNames: Record<string, string> = {
    codLocal: "Cod. Local",
    nombre: "Nombre",
    direccion: "Dirección",
    whatsapp: "Whatsapp",
    metadata: "Metadata",
  };

  const formatValue = (data: any) => {
    if (typeof data === "string" && data.length >= 10) {
      const dateObject = new Date(data);

      if (!isNaN(dateObject.getDate())) {
        return format(dateObject, "dd/MM/yyyy HH:mm");
      }
    }

    return data;
  };
  if (loading) return <Loading />;

  return (
    <>
      <Table
        style={{
          tableLayout: "fixed",
          marginTop: "20px",
          width: "97%",
          marginLeft: "20px",
          marginBottom: "20px",
          border: "1px solid #ddd",
        }}
      >
        <TableHead
          aria-label="Reportes"
          sx={{
            fontWeight: "bold",
            backgroundColor: "#e0e0e0",
            color: "white",
          }}
        >
          <TableRow>
            {Object.keys(keyNames).map((key) => (
              <TableCell
                key={key}
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "black",
                }}
              >
                {keyNames[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {slicedReportes.map((r: DataReporte, index: number) => (
            <TableRow key={index}>
              <TableCell>{r.codLocal}</TableCell>
              <TableCell>{r.nombre}</TableCell>
              <TableCell>{r.direccion}</TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "70px",
                  }}
                >
                  {r.whatsapp.map((whpp, i: number) => (
                    <React.Fragment key={i}>
                      <a
                        href={whpp}
                        target="_blank"
                        className="btn btn-success wsp-btn"
                        role="button"
                        rel="noreferrer"
                        style={{
                          marginBottom: i < r.whatsapp.length - 1 ? "5px" : "0",
                        }}
                      >
                        WSP
                      </a>
                    </React.Fragment>
                  ))}
                </div>
              </TableCell>

              <TableCell style={{ whiteSpace: "pre-line" }}>
                {r.metadata.length > 0 &&
                  r.metadata.map(
                    (data: any) =>
                      data.name && `${data.name}: ${formatValue(data.value)}\n`
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              colSpan={Object.keys(keyNames).length}
              sx={{ justifyContent: "flex-end", width: "100%" }}
            >
              <ReportesPagination
                component={"div"}
                reportes={reporte}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default TablaReporte;
