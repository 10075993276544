import { ICambioProducto } from '../../../dto/ContractDTO'
import { axiosInstanceContratos } from '../../../utils/axiosGlobalUtils'

export const getProductosContrato = async () =>
  axiosInstanceContratos.get(`/contratos/productos?size=100&page=0`)

export const cambiarProductoContrato = async (
  idContrato: number,
  nuevoProducto: ICambioProducto
) => {
  return await axiosInstanceContratos.post(
    `/contratos/${idContrato}/cambiar-producto`,
    nuevoProducto
  )
}
