import React from "react";
import { Box, Paper, Typography } from "@mui/material";

interface Props {
  message: string;
}

const NoContractsLabel: React.FC<Props> = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20vh",
        marginBottom: "20vh",
      }}
    >
      <Paper sx={{ p: 2, textAlign: "center", backgroundColor: "#f0f0f0" }}>
        <Typography variant="h5" sx={{ color: "black" }}>
          {message}
        </Typography>
      </Paper>
    </Box>
  );
};

export default NoContractsLabel;
