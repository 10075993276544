import {
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  Stack,
  Typography,
  TextField,
  InputLabel
} from '@mui/material'
import { Direccion } from '../../AbmLocalPage/types'
import { IGalerias, newGaleria } from '../context/galerias.context'
import {
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue
} from 'react-hook-form'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { validarDireccion } from '../../AbmLocalPage/services/validar.service'
import Mapa from '../../AbmLocalPage/components/Mapa'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import Loading from '../../../components/Common/Loading'
import AlertDialog from '../../../components/Common/Dialog'
import SectionTitle from '../../AbmLocalPage/common/SectionTitle'
import { TenantsContext } from '../../../context/tenants.context'

interface ValidarDireccionProps {
  loading: boolean
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  errors: FieldErrors<FieldValues>
  center: string
  setCenter: (center: string) => void
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  setLongitud: (value: number) => void
  setLatitud: (value: number) => void
  editGaleria?: IGalerias
  setEditGaleria?: (galeria: IGalerias) => void
  setDireccionValidada: (validada: boolean) => void
}
const MAX_DIR_LENGTH = 150

const ValidarDireccion = ({
  loading,
  register,
  setValue,
  getValues,
  errors,
  center,
  setCenter,
  setError,
  clearErrors,
  setLongitud,
  setLatitud,
  editGaleria,
  setEditGaleria,
  setDireccionValidada
}: ValidarDireccionProps) => {
  const [loadingDir, setLoadingDir] = useState<boolean>(false)
  const [showMap, setShowMap] = useState<boolean>(editGaleria ? true : false)
  const [validandoDireccion, setValidandoDireccion] = useState<boolean>(false)

  const [esGaleria, setEsGaleria] = useState<boolean>(false)
  const [dirGaleria, setDirGaleria] = useState<string>('')
  const [multiplesResultados, setMultiplesResultados] = useState<Direccion[]>(
    []
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { tenant } = useContext(TenantsContext)
  /* const handleDirGaleriaChange = (e: any) => {
    setValue(`direcGaleria`, e.target.value);
    setDirGaleria(e.target.value);
  }; */
  const validarDirGaleria = async (
    value: string,
    e?: any,
    esMultiple = true,
    dir?: Direccion
  ) => {
    if (e) e.preventDefault()
    if (value === '') return
    setLoadingDir(true)
    setShowMap(true)
    setValidandoDireccion(true)
    setEsGaleria(false)
    return await validarDireccion(value, null, tenant)
      .then((res) => {
        setCenter('')
        const direccion = res.data.data as Direccion
        if (
          esMultiple &&
          direccion.multiplesResultados &&
          direccion.multiplesResultados.length > 0
        ) {
          return setMultiplesResultados(direccion.multiplesResultados)
        }

        if (dir) {
          direccion.latitud = dir.latitud
          direccion.longitud = dir.longitud
          direccion.estaFueraDeZona = dir.estaFueraDeZona
        }

        if (direccion.latitud === 0) {
          setError('direcGaleria', {
            message: 'La dirección no es válida',
            type: 'manual'
          })
          return SnackBarUtils.error('La dirección no es válida')
        }
        if (direccion.estaFueraDeZona) {
          setError('direcGaleria', {
            message: 'La dirección se encuentra fuera del radio permitido',
            type: 'manual'
          })
          return SnackBarUtils.error(
            'La dirección se encuentra fuera del radio permitido'
          )
        }
        if (direccion && direccion.codGaleria) {
          setError('direcGaleria', {
            message: 'La dirección corresponde a una galería existente',
            type: 'manual'
          })
          return SnackBarUtils.error(
            `La dirección que ingresaste corresponde a la galería de código ${direccion.codGaleria}`
          )
        }
        setDireccionValidada(true)
        setLongitud(direccion.longitud)
        setLatitud(direccion.latitud)
        setCenter(`${direccion.latitud},${direccion.longitud}`)
        clearErrors('direcGaleria')
        setMultiplesResultados([])
        return 1
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoadingDir(false)
        setValidandoDireccion(false)
      })
  }
  useEffect(() => {
    setCenter('-')
  }, [setCenter])

  useEffect(() => {
    setShowMap(center !== '')
  }, [center])
  useEffect(() => {
    if (editGaleria) {
      setLongitud(editGaleria.longitud)
      setLatitud(editGaleria.latitud)
      setCenter(`${editGaleria.latitud},${editGaleria.longitud}`)
    }
  }, [editGaleria])
  return (
    <>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        sx={{ margin: '20px 0', width: '100%' }}
        spacing={isMobile ? 2 : 3}
      >
        <Stack sx={{ flex: 1 }}>
          <InputLabel
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            Dirección
            <Typography variant="subtitle2">
              {(editGaleria
                ? editGaleria.direccion.length
                : dirGaleria.length) || 0}
              /{MAX_DIR_LENGTH}
            </Typography>
          </InputLabel>
          <TextField
            // disabled={loading}
            sx={{ width: '100%' }}
            error={!!errors.direcGaleria}
            helperText={
              errors?.direcGaleria && String(errors?.direcGaleria?.message)
            }
            variant="outlined"
            {...register('direcGaleria')}
            onChange={
              editGaleria && setEditGaleria
                ? (e: any) => {
                    setEditGaleria({
                      ...editGaleria,
                      direccion: e.target.value
                    })
                    setDireccionValidada(false)
                  }
                : (e: any) => {
                    setDirGaleria(e.target.value)
                    setDireccionValidada(false)
                    clearErrors('direcGaleria')
                  }
            }
            inputProps={{
              maxLength: MAX_DIR_LENGTH
            }}
            value={editGaleria?.direccion}
          />
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <Button
            variant="outlined"
            onClick={() =>
              validarDirGaleria(getValues('direcGaleria'), null /* , false */)
            }
            style={isMobile ? {} : { height: '56px', marginTop: '23px' }}
          >
            Validar dirección
          </Button>
        </Stack>
      </Stack>
      <AlertDialog
        open={multiplesResultados.length > 0}
        title="Elegí la dirección correcta"
        disagreeText="Cancelar"
        onClose={() => {
          setMultiplesResultados([])
          SnackBarUtils.error('No se completó la validación de la dirección')
        }}
      >
        <Grid container spacing={2}>
          {multiplesResultados.map((dir, index) => (
            <Grid item xs={12} key={index}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={() => {
                  setValue('direcGaleria', dir.direccionNormalizada)
                  setMultiplesResultados([])
                  validarDirGaleria(dir.direccionNormalizada, null, false, dir)
                }}
              >
                {dir.direccionNormalizada}
              </Button>
            </Grid>
          ))}
        </Grid>
      </AlertDialog>
      {loadingDir ? (
        <Loading />
      ) : (
        center !== '-' &&
        center !== '' &&
        showMap && (
          <Grid item xs={12} marginTop={2}>
            <Mapa center={center} />
          </Grid>
        )
      )}
    </>
  )
}

export default ValidarDireccion
