import * as React from "react";
import { Navigate } from "react-router-dom";

export interface ProtectedRouteProps {
  element: JSX.Element;
  isAuthenticated: boolean;
  redirectURL: string;
  includeUrlParams?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuthenticated,
  redirectURL,
  element,
}) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectURL} />;
  }

  return element;
};

export default ProtectedRoute;
