import { Dialog, DialogContent, IconButton } from '@mui/material'
import ExportarCatalogo from './ExportarCatalogo'
import { useStoreExportacion } from '../Importacion/store/store-exportacion'
import { memo } from 'react'
import { Close } from '@mui/icons-material'

const DialogExportarCatalogo = () => {
  const { open, setOpen } = useStoreExportacion((state) => state)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '16px', p: '8px', bgcolor: '#F5F5F7' }
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <ExportarCatalogo closeButton={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}

export default memo(DialogExportarCatalogo)
