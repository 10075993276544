import React, { useState, useCallback, memo, useEffect } from "react";

// components
import { Box, Typography, Paper, Card, useTheme, useMediaQuery, Button } from "@mui/material";

// redux
import { connect } from "react-redux";
import setRedirectUrl from "../../../redux/actions/redirection/setRedirectUrl";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate } from "react-router";
import SnackBarUtils from "../../../utils/SnackBarUtils";

interface PaperProps {
  titulo: string;
  descripcion: React.ReactNode;
  logo: React.ReactElement;
  botonTexto?: string;
  botonNext: boolean;
  redirectUrl: string | null;
}

const PaperElement: React.FC<PaperProps> = ({
  titulo,
  descripcion,
  logo,
  botonTexto,
  botonNext,
  redirectUrl,
}: PaperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const styledLogo = React.cloneElement(logo as React.ReactElement, {
    sx: {
      color: isDisabled ? "#A1A1A1" : isHovered ? "#5983EF" : "#666",
    },
  });

  useEffect(() => {
    if (!redirectUrl && botonNext) setIsDisabled(true);
  }, [redirectUrl]);

  return (
    <>
      <Card
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          botonNext && (redirectUrl ? navigate(redirectUrl) : SnackBarUtils.info("Próximamente!"));
        }}
        sx={{
          width: isMobile ? "100%" : "60%",
          margin: "10px auto",
          padding: isMobile ? "10px" : "20px", //fijate en mobile se ve raro
          display: "flex",
          justifyContent: "flex-start",
          cursor: botonNext ? "pointer" : "auto",
          border: "2px solid transparent",
          ...(!botonTexto &&
            (isDisabled
              ? { color: "#A1A1A1" }
              : {
                  ...(isHovered &&
                    botonNext && {
                      border: "2px solid",
                      borderImage: "linear-gradient(180deg, #FF78CE 3.35%, #5983EF 93.36%) 1",
                      pointerEvents: isDisabled ? "none" : "auto",
                    }),
                })),
          boxShadow: "0px 4px 4px 0px #00000040",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {styledLogo}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            ...(isDisabled && { color: "#A1A1A1" }),
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontFamily: "Plus Jakarta Sans",
              fontSize: isMobile ? "14px" : "20px",
              fontWeight: isMobile ? 700 : 800,
              lineHeight: isMobile ? "17.64px" : "25.2px",
              textAlign: "left",
            }}
          >
            {titulo.toUpperCase()}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontFamily: "Plus Jakarta Sans",
              fontSize: isMobile ? "12px" : "16px",
              lineHeight: isMobile ? "15.12px" : "20.16px",
              textAlign: "left",
            }}
          >
            {descripcion}
          </Typography>
        </Box>
        {botonNext && (
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{
              flexDirection: "column",
              ml: "auto",
              alignSelf: "center",
              color: isDisabled ? "#A1A1A1" : theme.palette.primary.main,
            }}
          />
        )}
      </Card>
      {botonTexto && (
        <Button
          variant="contained"
          disableElevation={isDisabled}
          sx={{
            display: "flex",
            justifyContent: "initial",
            width: "60%",
            fontWeight: "bold",
            marginTop: "-20px",
            padding: "2px 0px",
            background: isDisabled ? "#D9D9D9" : "linear-gradient(90deg, #5983EF 0%, rgba(165, 190, 255, 0.2) 100%)",

            "&:hover": {
              background: "linear-gradient(180deg, #FF78CE 3.35%, #5983EF 93.36%)",
            },
            ...(isMobile && {
              width: "100%",
              background: isDisabled ? "#D9D9D9" : "#5983EF",
            }),
            pointerEvents: isDisabled ? "none" : "auto",
          }}
        >
          <Typography
            style={{
              padding: "2px 8%",
              paddingLeft: "8%",
              fontFamily: "Plus Jakarta Sans",
              fontSize: isMobile ? "12px" : "20px",
              fontWeight: isMobile ? 500 : 800,
              lineHeight: isMobile ? "15.12px" : "25.2px",
            }}
          >
            {botonTexto}
          </Typography>
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection,
  };
};

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PaperElement));
