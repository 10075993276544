import { FormControlLabel, Checkbox, Box, Grid, TextField } from '@mui/material'
import { Config, DiaType, postLocalType } from '../../types'
import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormGetValues
} from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import SectionTitle from '../../common/SectionTitle'
import { getFecha } from '../../../../utils/utils'
import { HorarioDTO, LocalVendedorDTO } from '../../../../dto/LocalDTO'
import { TenantsContext } from '../../../../context/tenants.context'

interface FormHorariosProps {
  local?: LocalVendedorDTO
  register: UseFormRegister<postLocalType>
  errors: FieldErrors<postLocalType>
  setValue: UseFormSetValue<postLocalType>
  getValues: UseFormGetValues<postLocalType>
  isSubmitting: boolean
}

const diasMap: Record<string, DiaType> = {
  lu: 'Lunes',
  ma: 'Martes',
  mi: 'Miércoles',
  ju: 'Jueves',
  vi: 'Viernes',
  sa: 'Sábado',
  do: 'Domingo'
}

const FormHorarios = ({
  isSubmitting,
  local,
  setValue,
  errors
}: FormHorariosProps) => {
  const [horarios, setHorarios] = useState<HorarioDTO[]>(
    (local && local.horarioList?.length > 0 && local.horarioList) || []
  )
  const { tenant } = useContext(TenantsContext)

  useEffect(() => {
    const configHorarios = tenant.config?.find(
      (c: Config) => c.name === 'registracion_diasqueabre'
    )?.value
    const diasQueAbre: string[] = configHorarios?.split(',') || []

    const initialHorarios: HorarioDTO[] = Object.keys(diasMap).map(
      (key, index) => ({
        idDia: index + 1,
        dia: diasMap[key],
        horario: null,
        cerrado: !diasQueAbre.includes(key)
      })
    )
    horarios.length === 0 && setHorarios(initialHorarios)
  }, [tenant])

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    horario: HorarioDTO
  ) => {
    horario.cerrado = e.target.checked
    setHorarios([...horarios])
  }

  const handleHorarioChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    horario: HorarioDTO
  ) => {
    horario.horario = e.target.value
    setHorarios([...horarios])
  }

  useEffect(() => {
    setValue('horarios', horarios)
  }, [horarios])

  return (
    <Box sx={{ pb: 2 }}>
      <SectionTitle title="Horarios" />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ mb: 1 }}>
            {horarios.map((horario, index) => (
              <Grid key={index} item md={3} xs={12}>
                <Box
                  sx={{
                    '@media (max-width: 768px)': {
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      width: '100%',
                      pr: 1
                    }
                  }}
                >
                  <TextField
                    label={horario.dia || getFecha(horario)}
                    placeholder={horario.cerrado ? 'Cerrado' : ''}
                    fullWidth
                    value={horario.horario}
                    InputLabelProps={{ shrink: !!local || !!horario.horario }}
                    disabled={isSubmitting || horario.cerrado}
                    onChange={(e) => handleHorarioChange(e, horario)}
                  />

                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => handleCheckboxChange(e, horario)}
                          checked={horario.cerrado}
                          disabled={isSubmitting}
                        />
                      }
                      label={'Cerrado'}
                      disabled={isSubmitting}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FormHorarios
