import {
  CardContent as CardContentMUI,
  Divider,
  Typography
} from "@mui/material";
import Rubro from "../../Elements/Rubro";
import Estadisticas from "../../Elements/Estadisticas";
import ChipEstados from "../../Elements/ChipEstados";
import Fechas from "../../Elements/Fechas";
import "./CardStyles.css";

interface CardContentProps {
  product: any;
}

const CardContent = ({ product }: CardContentProps) => {
  return (
    <CardContentMUI
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
      }}
    >
      {/* RUBRO */}
      <Rubro product={product} />

      {product.sku && (
        <Typography variant="body2" sx={{ fontWeight: "bold" }} component="h2">
          Código: {product.sku}
        </Typography>
      )}

      <Divider sx={{ width: "100%" }} />

      {/* ESTADISTICAS */}
      <Estadisticas product={product} />

      <Divider sx={{ width: "100%" }} />

      {/* Chip estados */}
      <ChipEstados product={product} />
      {/* Código SKU */}

      {/* Fechas */}
      <Fechas product={product} />
    </CardContentMUI>
  );
};

export default CardContent;
