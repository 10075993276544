import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { Rubro } from '../../Integraciones/types/responses-productos'

interface DesplegableRubrosProductoProps {
  onChange: (codRubro: string, nombreAnalytics: string) => Promise<void>
  setIsSelected: (value: boolean) => void
  nombreRubro: string
  isSelected: boolean
  codActual: string
  rubros: Rubro[]
  loading: boolean
}

const DesplegableRubrosProducto = ({
  onChange,
  nombreRubro,
  codActual,
  isSelected,
  setIsSelected,
  rubros,
  loading
}: DesplegableRubrosProductoProps) => {
  if (loading || rubros.length === 0) return null

  const handleChange = async (e: any, newValue: Rubro | null) => {
    await setTimeout(() => {}, 200)
      if (!newValue) return
      onChange(newValue.codRubro, newValue.nombreAnalytics)
      setIsSelected(false)
  }

  if (!isSelected)
    return (
      <Box
        onClick={() => setIsSelected(true)}
        title="Click para cambiar el rubro"
        sx={{
          cursor: 'pointer',
          padding: '15px',
          borderRadius: '4px',
          border: '1px solid #a5a5a5',
          fontSize: '15px',
          width: '100%',
          ':hover': {
            borderColor: '#000000'
          }
        }}
      >
        {nombreRubro}
      </Box>
    )

  return (
    <Autocomplete
      onChange={handleChange}
      onBlur={() => setIsSelected(false)}
      disableClearable
      options={rubros}
      fullWidth
      onKeyPress={(e: any) => e.code === 'Enter' && handleChange}
      defaultValue={rubros.find((rubro: Rubro) => rubro.codRubro === codActual)}
      getOptionLabel={(rubro: Rubro) => rubro.nombreAnalytics || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          variant="outlined"
          // achicar tamaño del texto
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: '15px'
            }
          }}
        />
      )}
      renderOption={(props, rubro) => {
        // if(rubro.codRubro === codActual) return null
        return (
          <MenuItem {...props} value={rubro.codRubro}>
            {rubro.nombreAnalytics}
          </MenuItem>
        )
      }}
    />
  )
}

export default DesplegableRubrosProducto
