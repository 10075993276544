import { FC, useState } from 'react'
import { ProductoExterno, ProductoInterno } from '../../../../types/responses-novedades'
import { Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { firstLetterUpperCase } from '../../../../../../utils/utils'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  productoTienda: ProductoExterno
  productoAA1T: ProductoInterno
}

type CamposAChequear = 'nombre' | 'descripcion' | 'precio'

const NewData = styled('span')({
  color: '#5983EF',
  fontWeight: 'bold'
})

const OldData = styled('span')({
  fontWeight: 'bold'
})

const Actions = () => {
  const [control, setControl] = useState(null)
  const handleChange = (e) => {
    setControl(e.target.value)
  }

  return (
    <FormControl>
      <RadioGroup row onChange={handleChange} value={control}>
        <FormControlLabel value={1} label="Aplicar" control={<Radio />} />
        <FormControlLabel value={2} label="Descartar" control={<Radio />} />
        <FormControlLabel value={0} label="Omitir" control={<Radio />} />
      </RadioGroup>
    </FormControl>
  )
}

const Novedad = ({
  productoAA1T,
  productoTienda,
  attr
}: {
  productoAA1T: ProductoInterno
  productoTienda: ProductoExterno
  attr: CamposAChequear
}) => {
  return (
    productoAA1T[attr] !== productoTienda[attr] && (
      <>
        <Box display="flex" justifyContent="space-between" width="100%" paddingY="12px">
          <Box display="flex" alignItems="center" gap={1}>
            {firstLetterUpperCase(attr)}:{' '}
            <OldData>
              {attr === 'precio' && '$'}
              {productoAA1T[attr]}
            </OldData>{' '}
            <ArrowForward />{' '}
            <NewData>
              {attr === 'precio' && '$'}
              {productoTienda[attr]}
            </NewData>
          </Box>
          <Box>
            <Actions />
          </Box>
        </Box>
        <Divider color="#a2a2a2" />
      </>
    )
  )
}

const NovedadBody: FC<Props> = ({ productoAA1T, productoTienda }) => {
  console.log({ productoAA1T, productoTienda })

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Novedad productoAA1T={productoAA1T} productoTienda={productoTienda} attr="nombre" />
      <Novedad productoAA1T={productoAA1T} productoTienda={productoTienda} attr="descripcion" />
      <Novedad productoAA1T={productoAA1T} productoTienda={productoTienda} attr="precio" />
      <ButtonRounded bgcolor="#5983EF" textColor="white" sx={{ mt: 2, width: 'fit-content', alignSelf: 'flex-end' }}>
        Confirmar
      </ButtonRounded>
    </Box>
  )
}

export default NovedadBody
