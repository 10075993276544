import { memo, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { changePassword } from "../../Login/services/password.service";
import SnackBarUtils from "../../../utils/SnackBarUtils";
import { TenantsContext } from "../../../context/tenants.context";

interface ModifyPasswordModalProps {
  modificandoPassword: boolean;
  setModificandoPassword: (value: boolean) => void;
  isSubmitting: boolean;
  idUsuario: number;
}

const ModifyPasswordModal = ({
  idUsuario,
  isSubmitting,
  modificandoPassword,
  setModificandoPassword,
}: ModifyPasswordModalProps) => {
  const [visibility, setVisibility] = useState(false);
  const [helpText, setHelpText] = useState("");
  const [passwordVieja, setPasswordVieja] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const { tenant } = useContext(TenantsContext);

  const handleConfirm = async () => {
    if (password1 !== password2)
      return setHelpText("Las contraseñas no coinciden");

    try {
      const res = await changePassword(
        {
          idUsuario,
          contraseniaVieja: passwordVieja,
          contraseniaNueva: password1,
        },
        tenant
      );
      if (res.status !== 200) return;

      setModificandoPassword(false);
      setPasswordVieja("");
      setPassword1("");
      setPassword2("");
      SnackBarUtils.success("Contraseña cambiada con éxito");
    } catch (error: any) {
      console.log(error);
      setHelpText(error.response.data.errorMessage);
    }
  };

  const handleCancel = () => {
    setModificandoPassword(false);
    setPasswordVieja("");
    setPassword1("");
    setPassword2("");
  };

  useEffect(() => {
    setHelpText(password1 === password2 ? "" : "Las contraseñas no coinciden");
  }, [password1, password2]);

  return (
    <Dialog
      open={modificandoPassword}
      onClose={() => setModificandoPassword(false)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Modificar contraseña</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel htmlFor="outlined-adornment-password">
            Contraseña vieja
          </InputLabel>
          <OutlinedInput
            autoComplete="off"
            id="outlined-adornment-password"
            label="Contraseña vieja"
            type={visibility ? "text" : "password"}
            value={passwordVieja}
            autoFocus
            fullWidth
            onChange={(e) => setPasswordVieja(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleConfirm()}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel htmlFor="outlined-adornment-password">
            Nueva Contraseña
          </InputLabel>
          <OutlinedInput
            autoComplete="off"
            id="outlined-adornment-password"
            label="Nueva Contraseña"
            type={visibility ? "text" : "password"}
            value={password1}
            fullWidth
            onChange={(e) => setPassword1(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleConfirm()}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVisibility(!visibility)}
                  onMouseDown={() => setVisibility(!visibility)}
                  edge="end"
                >
                  {visibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel htmlFor="outlined-adornment-repassword">
            Repita la nueva contraseña
          </InputLabel>
          <OutlinedInput
            autoComplete="off"
            id="outlined-adornment-repassword"
            label="Repita la nueva contraseña"
            type={visibility ? "text" : "password"}
            value={password2}
            fullWidth
            onChange={(e) => setPassword2(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleConfirm()}
          />
          <Typography variant="body2" sx={{ mt: 1, ml: 1 }} color={"error"}>
            {helpText}
          </Typography>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleCancel} color="error">
          Cancelar
        </Button>
        <Button disabled={isSubmitting} onClick={handleConfirm} color="success">
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ModifyPasswordModal);
