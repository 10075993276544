import AdminAbmProductosFiltros from './components/Filtros'
import TableProductos from './components/Table/TableProductos'
import CardProductos from './components/Card/CardProductos'
import Navbar from '../../components/Navbar'
import { useContext, useEffect, useState } from 'react'
import { ProductosAdminContext } from './context/productos.context'
import EditProduct from '../Products/widgets/EditProduct'
import useLocal from '../Products/hooks/locals.hook'
import Footer from '../Home/components/Footer'
import { styled } from '@mui/material/styles'
import ConfirmDialog from '../Products/components/ConfirmDialog'
import AccionesMasivasButtons from '../Products/components/AccionesMasivasButtons'
import { RolType } from '../../components/Navbar/Sidebar/SidebarItems'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import useAccionesMasivas from '../Products/hooks/acciones.masivas.hook'
import {
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import { Ordenamiento, ordenamientos } from '../Products/utils/ordenamientos'
import { useSortableData } from '../../utils/UseSorteableDataHook'
import usePalabrasProhibidas from '../Products/hooks/palabras.prohibidas.hook'
import { Rubro } from '../Integraciones/types/responses-productos'
import useRubros from '../Products/hooks/rubros.hook'

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
})

export interface ProductsAdminProps {
  rubros: Rubro[]
  handleUnPauseProducts: () => void
  handlePauseProducts: () => void
  handleDeleteProducts: () => void
  handleExpireProducts: () => void
  handleChangeRubro: (codRubro: string) => void
}

const AdminAbmProductosPage = (props: any) => {
  const {
    productos,
    page,
    pageSize,
    searching,
    loading: loadingProductos,
    editProductCode,
    codLocalEditing,
    clearEditing,
    fetchData,
    handleLeft,
    accionesMasivas,
    confirmDialog,
    productosSeleccionados,
    setProductosSeleccionados,
    puedePausarVarios,
    puedeReanudarVarios,
    puedeVencerVarios,
    setConfirmDialog
  } = useContext(ProductosAdminContext)
  const isAdmin = props.logged.userLogged.roles.includes(RolType.ADMIN)
  const { rubros, rubrosFlatted } = useRubros()
  const {
    loadingProductosMasivos,
    handleDeleteProducts,
    handlePauseProducts,
    handleUnPauseProducts,
    handleExpireProducts,
    handleChangeRubro
  } = useAccionesMasivas({
    isAdmin,
    productosSeleccionados,
    setProductosSeleccionados,
    setConfirmDialog
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { retail, premium, maxPhotos, esMinorista, precioDto, ofrecerPremium } =
    useLocal(codLocalEditing, '0')
  const [ordenamiento, setOrdenamiento] = useState<Ordenamiento>(
    ordenamientos[0]
  )
  const { palabrasProhibidas } = usePalabrasProhibidas()
  const { items, requestSort } = useSortableData(productos ? productos : [])

  useEffect(() => {
    const direction = ordenamiento.ascendente ? 'ascending' : 'descending'
    requestSort(ordenamiento.keyName, direction)
  }, [ordenamiento])

  return (
    <>
      <Navbar title="Admin ABM Productos" />
      <div className="p-4">
        <Header>
          <AdminAbmProductosFiltros rubros={rubrosFlatted} />
        </Header>
        {isMobile && (
          <FormControl style={{ flexGrow: '1', marginBottom: '10px' }}>
            <InputLabel id="demo-simple-select-label">Orden</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              native
              label="Orden"
              //value={ordenamiento}
              onChange={(e: any) => {
                const option = ordenamientos.find(
                  (item: Ordenamiento) =>
                    item.id === parseInt(e.target.value, 10)
                )
                option && setOrdenamiento(option)
              }}
            >
              {ordenamientos
                .filter((ordenamiento) => ![1, 2, 9].includes(ordenamiento.id))
                .map((ordenamiento: Ordenamiento) => (
                  <option key={ordenamiento.id} value={ordenamiento.id}>
                    {ordenamiento.criterio}
                  </option>
                ))}
            </Select>
          </FormControl>
        )}
        {isMobile ? (
          <CardProductos
            productos={items}
            page={page}
            pageSize={pageSize}
            searching={searching}
            loadingProductos={loadingProductos}
          />
        ) : (
          <TableProductos
            rubros={rubrosFlatted}
            handlePauseProducts={handlePauseProducts}
            handleUnPauseProducts={handleUnPauseProducts}
            handleDeleteProducts={handleDeleteProducts}
            handleExpireProducts={handleExpireProducts}
            handleChangeRubro={handleChangeRubro}
          />
        )}

        <EditProduct
          admin={true}
          editProductCode={editProductCode}
          open={editProductCode !== ""}
          esMinorista={esMinorista}
          codLocal={codLocalEditing}
          maxPhotos={maxPhotos}
          precioDto={precioDto}
          retailer={retail}
          setOpen={() => {}}
          getProducts={async () => {}}
          onClose={clearEditing}
          onConfirm={fetchData}
          ofrecerPremium={ofrecerPremium}
          palabrasProhibidas={palabrasProhibidas}
          rubros={rubros}
        />
        {accionesMasivas && isMobile && (
          <AccionesMasivasButtons
            isAdmin={isAdmin}
            rubros={rubrosFlatted}
            handleDeleteProducts={handleDeleteProducts}
            handlePauseProducts={handlePauseProducts}
            handleUnPauseProducts={handleUnPauseProducts}
            handleExpireProducts={handleExpireProducts}
            handleChangeRubro={handleChangeRubro}
            handleLeft={handleLeft}
            puedePausarVarios={puedePausarVarios}
            puedeReanudarVarios={puedeReanudarVarios}
            puedeVencerVarios={puedeVencerVarios}
          />
        )}
        <ConfirmDialog {...confirmDialog} disabled={loadingProductosMasivos} />
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAbmProductosPage);
