import Loading from "../../../../components/Common/Loading";
import { Grid, Box, Typography } from "@mui/material";
import TablaHistoriasPagination from "../desktop/HistoriasPagination";
import NoResults from "../../../../components/Common/NoResults";
import NoSearch from "../../../../components/Common/NoSearch";
import { useContext } from "react";
import { HistoriasAdminContext } from "../../context/historias.context";
import { IHistorias } from "../../types/historias.types";
import CardHistoria from "./CardHistoria";

const CardHistorias = ({ copyShareLink }: { copyShareLink: (link: string) => void }) => {
  const {
    historias,
    cantLocales,
    page,
    rowsPerPage,
    loading: loadingHistorias,
    EliminarHistoria,
    CompartirHistoria,
  } = useContext(HistoriasAdminContext);

  if (loadingHistorias) return <Loading />;

  return (
    <>
      {historias.length > 0 && cantLocales > 0 && (
        <Typography
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >{`${historias.length} ${historias.length > 1 ? "historias" : "historia"} de ${cantLocales} ${
          cantLocales > 1 ? "locales" : "local"
        }`}</Typography>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        {historias
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((historia: IHistorias, i: number) => (
            <Grid item key={i} md={6} sm={12} sx={{ marginBottom: "20px" }}>
              <CardHistoria
                historia={historia}
                compartir={CompartirHistoria}
                eliminar={EliminarHistoria}
                copyShareLink={copyShareLink}
              />
            </Grid>
          ))}

        <Grid item xs={12} sx={{ alignSelf: "end", margin: "20px 0px" }}>
          <TablaHistoriasPagination />
        </Grid>
      </Grid>
    </>
  );
};

export default CardHistorias;
