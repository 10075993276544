import { memo, useContext } from 'react'
import { IntegracionesContext } from '../../../../context/integraciones.context'
import { Grid } from '@mui/material'
import SingleProductoWoo from '../WooCommerce/SingleProductoWoo'

const ProductosAA1TListado = () => {
  const { productosAConfirmar } = useContext(IntegracionesContext)

  if (!productosAConfirmar.length) return null

  return (
    <Grid container spacing="16px">
      {productosAConfirmar.map((producto) => (
        <Grid item key={producto.externalId} xs={12}>
          <SingleProductoWoo key={producto.externalId} producto={producto} action="eliminar" />
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(ProductosAA1TListado)
