import React from "react";
import { SortConfig } from "../pages/Products/utils/ordenamientos";

export const useSortableData = (items: any, config = null) => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig | null>(config);

  const getPrecioFromFormattedString = (formattedString: string) => {
    const formatedPrice = formattedString.replace(/,/g, "");
    const startIndex = formatedPrice.indexOf(":") + 1;
    const endIndex = formatedPrice.indexOf("<br>");
    const precioString = formatedPrice.substring(startIndex, endIndex).trim();
    return parseFloat(precioString);
  };

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      if (sortConfig.key === "precioFormateado") {
        sortableItems.sort((a, b) => {
          const precioA = getPrecioFromFormattedString(a[sortConfig.key]);
          const precioB = getPrecioFromFormattedString(b[sortConfig.key]);
          if (precioA < precioB) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          if (precioA > precioB) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          return 0;
        });
      } else {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          return 0;
        });
      }
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string, direction?: string) => {
    if (direction) {
      setSortConfig({ key, direction });
    } else {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }
  };

  return { items: sortedItems, requestSort, sortConfig, setSortConfig };
};
