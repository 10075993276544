import { Tour, StarBorder } from "@mui/icons-material";
import { TableRow, TableCell, Typography, Chip, Button, Box, Checkbox } from "@mui/material";
import { isMobile } from "react-device-detect";
import { formatDate } from "../../../../utils/utils";
import ChipEstados from "../Elements/ChipEstados";
import { BUTTONS } from "./Tabla";
import { ProductListProps } from "../..";
import { useContext, useMemo } from "react";
import { ProductosVendedorContext } from "../../context/productos.vendedor.context";
import { TenantsContext } from "../../../../context/tenants.context";

interface TableProductProps extends ProductListProps {
  product: any;
  loading: boolean;
  usaSku: boolean;
}

const TablaProducto = ({
  product,
  isAdmin,
  handleDeleteProduct,
  handleEditProduct,
  handlePauseProduct,
  handleResumeProduct,
  loading,
  usaSku,
}: TableProductProps) => {
  const { productosSeleccionados, setProductosSeleccionados } = useContext(ProductosVendedorContext);
  const { tenant } = useContext(TenantsContext);
  const estaSeleccionado = useMemo(() => {
    return productosSeleccionados.some((p: any) => p.codProducto === product.codProducto);
  }, [productosSeleccionados, product]);

  const handleCheckboxChange = () => {
    if (estaSeleccionado) {
      setProductosSeleccionados(productosSeleccionados.filter((p: any) => p.codProducto !== product.codProducto));
    } else {
      setProductosSeleccionados([...productosSeleccionados, product]);
    }
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell align="center">
        <Checkbox
          checked={estaSeleccionado}
          onChange={handleCheckboxChange}
          sx={{
            p: 0,
            m: 0,
            width: "fit-content",
            alignSelf: "center",
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Box
          style={{
            maxWidth: "135px",
            maxHeight: "135px",
            overflow: "hidden",
            cursor: "pointer",
          }}
          onClick={() => handleEditProduct(product.codProducto, tenant)}
        >
          {product.fotoUrlThumb && <img src={product.fotoUrlThumb} alt="imagen" />}
        </Box>
      </TableCell>
      {usaSku && (
        <TableCell
          align="center"
          sx={{ fontSize: "16px", cursor: "pointer" }}
          onClick={() => handleEditProduct(product.codProducto, tenant)}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }} component="h2">
            {product.sku ? product.sku : ""}
          </Typography>
        </TableCell>
      )}

      <TableCell
        align="center"
        sx={{ fontSize: "16px", cursor: "pointer" }}
        onClick={() => handleEditProduct(product.codProducto, tenant)}
      >
        {product.nombre}
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {product.rubro?.urlIcono && (
            <img src={product.rubro.urlIcono} alt="imagen" style={{ width: "32px", height: "32px" }} />
          )}
          <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }} component="h2">
            {product.rubro.nombre}
            {product.rubro.idrubroPadre !== 0 && (
              <>
                <br />({product.rubroNombreAnalytics?.split(" - ").slice(0, -1).join(" - ")})
              </>
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "16px" }}>
        {product.moneda?.simbolo} {new Intl.NumberFormat("es-AR").format(product.precio)}
        {product.precioMinorista > 0 && (
          <span>
            <br />
            Menor: {product.moneda?.simbolo} {new Intl.NumberFormat("es-AR").format(product.precioMinorista)}
          </span>
        )}
      </TableCell>
      <TableCell align="center">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <ChipEstados product={product} isMobile={isMobile} />
          {/* {product.fechaVencimiento < new Date() && <Chip variant="outlined" label={"Vencido"} color={"error"} />} */}
          {product.fechaVencimiento < new Date() ? (
            <Typography variant="body2" sx={{ fontWeight: "bold" }} component="h2">
              Vencido el {formatDate(product.fechaVencimiento)}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontWeight: "bold" }} component="h2">
              Vence el {formatDate(product.fechaVencimiento)}
            </Typography>
          )}
          <Typography variant="body2" sx={{ fontWeight: "bold" }} component="h2">
            Publicado el {formatDate(product.fechaPublicacion)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Chip sx={{ marginBottom: "5px" }} icon={<Tour />} label={`Visitas: ${product.cantVisitas}`} />
        <br />
        <Chip icon={<StarBorder />} label={`Favoritos: ${product.cantFavoritos}`} />
      </TableCell>
      <TableCell align="center">
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {product.pausado ? (
            <Button
              variant="text"
              endIcon={BUTTONS.resume.icon}
              disabled={loading}
              onClick={() => handleResumeProduct(product.codProducto, tenant)}
            >
              {BUTTONS.resume.text}
            </Button>
          ) : (
            <Button
              variant="text"
              endIcon={BUTTONS.pause.icon}
              disabled={loading}
              onClick={() => handlePauseProduct(product.codProducto, tenant)}
            >
              {BUTTONS.pause.text}
            </Button>
          )}
          {isAdmin && (
            <Button
              variant="text"
              endIcon={BUTTONS.approve.icon}
              disabled={loading}
              onClick={() => BUTTONS.approve.action}
            >
              {BUTTONS.approve.text}
            </Button>
          )}

          <Button
            variant="text"
            endIcon={BUTTONS.delete.icon}
            color="error"
            disabled={loading}
            onClick={() => handleDeleteProduct(product.codProducto, tenant)}
          >
            {BUTTONS.delete.text}
          </Button>
          <Button
            variant="text"
            endIcon={BUTTONS.open.icon}
            disabled={loading}
            onClick={() => handleEditProduct(product.codProducto, tenant)}
          >
            {BUTTONS.open.text}
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TablaProducto;
