import { axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import { ITenant } from '../../AbmLocalPage/types'

interface ProductosSearchProps {
  codLocal?: string
  codRubro?: string
  nombreProducto?: string
  soloActivos?: boolean
  soloMalCatalogados: boolean
  publicadoDesde: string | null
  publicadoHasta: string | null
}

export const getProductos = async (
  {
    codLocal = null,
    codRubro = null,
    nombreProducto = '',
    soloActivos = true,
    soloMalCatalogados = false,
    publicadoDesde = null,
    publicadoHasta = null
  }: ProductosSearchProps,
  tenant: ITenant
) => {
  let url = `/admin/${tenant.path}/productos?`

  if (codLocal) url += `codLocal=${codLocal}&`
  if (codRubro) url += `codRubro=${codRubro}&`
  if (nombreProducto !== '') url += `nombre-producto=${nombreProducto}&`
  if (publicadoDesde) url += `fecha-publicacion-desde=${publicadoDesde}&`
  if (publicadoHasta) url += `fecha-publicacion-hasta=${publicadoHasta}&`
  if (soloMalCatalogados) url += `revisar=${soloMalCatalogados}&`

  url += `solo-activos=${soloActivos}`

  return await axiosInstanceMain.get(url)
}

export const aprobar = async (codProducto: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/${codProducto}/aprobar`)
export const rechazar = async (codProducto: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/${codProducto}/rechazar`)
export const pausar = async (codProducto: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/${codProducto}/pausar`)
export const reanudar = async (codProducto: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/${codProducto}/despausar`)
export const borrar = async (codProducto: string, tenant: ITenant) =>
  await axiosInstanceMain.delete(`/admin/${tenant.path}/productos/${codProducto}`)
export const vencer = async (codes: string[], tenant: ITenant) =>
  await axiosInstanceMain.post(`/admin/${tenant.path}/productos/vencer`, codes)

export const noRevisar = async (codProducto: string, tenant: ITenant) =>
  await axiosInstanceMain.put(`/admin/${tenant.path}/productos/${codProducto}/admin-no-revisar`)
