import { useState, useEffect, useCallback, useContext } from 'react'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { changeRubroProducts, deleteProducts, pauseProducts, unPauseProducts } from '../services/products.services'
import { ProductosVendedorContext } from '../context/productos.vendedor.context'
import { IProducto } from '../../AdminProducts/types/producto'
import { ProductosAdminContext } from '../../AdminProducts/context/productos.context'
import { TenantsContext } from '../../../context/tenants.context'
import { vencer } from '../../AdminProducts/services/productos.service'

export interface AccionesMasivasProps {
  isAdmin: boolean
  productosSeleccionados: any[]
  setProductosSeleccionados: (p: any[]) => void
  setConfirmDialog: (confirm: any) => void
}

const useAccionesMasivas = ({
  isAdmin,
  productosSeleccionados,
  setProductosSeleccionados,
  setConfirmDialog
}: AccionesMasivasProps) => {
  const { estado, getProductosVendedor } = useContext(ProductosVendedorContext)
  const { fetchData } = useContext(ProductosAdminContext)
  const [loadingProductosMasivos, setLoadingProductosMasivos] = useState<boolean>(false)
  const { tenant } = useContext(TenantsContext)

  const clearConfirmDialog = () => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }

  const fetchProducts = async () => {
    if (isAdmin) {
      return await fetchData()
    } else {
      return await getProductosVendedor(estado.key)
    }
  }

  const handleDeleteProducts = useCallback(() => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Deseas eliminar estas publicaciones?',
        body: 'Ten en cuenta que esto no se puede deshacer',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar',
        colorButtonConfirm: 'error'
      },
      disabled: loadingProductosMasivos,
      action: async () => {
        setLoadingProductosMasivos(true)
        try {
          const products: string = productosSeleccionados.map((p: any) => p.codProducto).join(',')
          if (products.length === 0) return SnackBarUtils.error('No hay productos seleccionados')
          await deleteProducts(products, tenant).then(() => {
            SnackBarUtils.success('Productos eliminados')
          })
          await fetchProducts()
        } catch (e) {
          console.log(e)
        } finally {
          setLoadingProductosMasivos(false)
          setProductosSeleccionados([])
          clearConfirmDialog()
        }
      },
      close: () => clearConfirmDialog()
    })
  }, [productosSeleccionados, getProductosVendedor, loadingProductosMasivos])

  const handleExpireProducts = useCallback(() => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Deseas vencer estas publicaciones?',
        //body: "Ten en cuenta que esto no se puede deshacer",
        leftButton: 'Cancelar',
        rightButton: 'Confirmar',
        colorButtonConfirm: 'error'
      },
      disabled: loadingProductosMasivos,
      action: async () => {
        setLoadingProductosMasivos(true)
        try {
          const codes: string[] = productosSeleccionados.map((p: any) => p.codProducto)
          if (codes.length === 0) return SnackBarUtils.error('No hay productos seleccionados')
          await vencer(codes, tenant).then()
          await fetchProducts()
          SnackBarUtils.success('Productos vencidos')
        } catch (e) {
          console.log(e)
        } finally {
          setLoadingProductosMasivos(false)
          setProductosSeleccionados([])
          clearConfirmDialog()
        }
      },
      close: () => clearConfirmDialog()
    })
  }, [productosSeleccionados, getProductosVendedor, loadingProductosMasivos])

  const handlePauseProducts = useCallback(() => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Deseas pausar estas publicaciones?',
        body: '',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar'
      },
      disabled: loadingProductosMasivos,
      action: async () => {
        setLoadingProductosMasivos(true)
        try {
          const products: string = productosSeleccionados.map((p: any) => p.codProducto).join(',')
          if (products.length === 0) return SnackBarUtils.error('No hay productos seleccionados')

          await pauseProducts(products, tenant).then(() => SnackBarUtils.success('Productos pausados'))
          await fetchProducts()
        } catch (e) {
          console.log(e)
        } finally {
          setLoadingProductosMasivos(false)
          setProductosSeleccionados([])
          clearConfirmDialog()
        }
      },
      close: () => clearConfirmDialog()
    })
  }, [productosSeleccionados, getProductosVendedor, loadingProductosMasivos])

  const handleUnPauseProducts = useCallback(() => {
    setConfirmDialog({
      open: true,
      text: {
        title: '¿Deseas reanudar estas publicaciones?',
        body: '',
        leftButton: 'Cancelar',
        rightButton: 'Confirmar'
      },
      disabled: loadingProductosMasivos,
      action: async () => {
        setLoadingProductosMasivos(true)
        try {
          const products: string = productosSeleccionados.map((p: any) => p.codProducto).join(',')
          if (products.length === 0) return SnackBarUtils.error('No hay productos seleccionados')

          await unPauseProducts(products, tenant).then(() => SnackBarUtils.success('Productos reanudados'))
          await fetchProducts()
        } catch (e) {
          console.log(e)
        } finally {
          setLoadingProductosMasivos(false)
          setProductosSeleccionados([])
          clearConfirmDialog()
        }
      },
      close: () => clearConfirmDialog()
    })
  }, [productosSeleccionados, getProductosVendedor, loadingProductosMasivos])

  const handleChangeRubro = useCallback(
    async (codRubro: string) => {
      setLoadingProductosMasivos(true)
      try {
        const codes: string[] = []
        productosSeleccionados.map((p: IProducto) => codes.push(p.codProducto))
        if (codes.length === 0) return SnackBarUtils.error('No hay productos seleccionados')

        await changeRubroProducts(codes, codRubro, tenant)
        await fetchData()
        SnackBarUtils.success('Rubros actualizados')
        setProductosSeleccionados([])
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingProductosMasivos(false)
      }
    },
    [productosSeleccionados, getProductosVendedor, loadingProductosMasivos]
  )

  return {
    loadingProductosMasivos,
    handleDeleteProducts,
    handlePauseProducts,
    handleExpireProducts,
    handleUnPauseProducts,
    handleChangeRubro
  }
}

export default useAccionesMasivas
