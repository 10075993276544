import React, { useState, useCallback, memo, useEffect, useContext } from 'react'
import './Products.css'

// components
import {
  Button,
  Container,
  Box,
  TextField,
  FormControl,
  Select,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  InputLabel
} from '@mui/material'

// redux
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'

// Hooks
import useLocal from './hooks/locals.hook'

import EditProduct from './widgets/EditProduct'
import CreateProduct from './widgets/CreateProduct'

import ConfirmDialog from './components/ConfirmDialog'
import MobileProductsList from './components/Mobile/MobileProductsList'
import Loading from '../../components/Common/Loading'
import Tabla, { BUTTONS } from './components/Desktop/Tabla'
import Navbar from '../../components/Navbar'
import SnackBarUtils from '../../utils/SnackBarUtils'
import AccionesMasivasButtons from './components/AccionesMasivasButtons'
import { Ordenamiento, ordenamientos } from './utils/ordenamientos'
import Footer from '../Home/components/Footer'
import { useMetricas } from '../Home/hooks/metricas.hook'
import usePalabrasProhibidas from './hooks/palabras.prohibidas.hook'
import { ProductosVendedorContext, Estado, TABS } from './context/productos.vendedor.context'
import useAccionesMasivas from './hooks/acciones.masivas.hook'
import { ITenant } from '../AbmLocalPage/types'
import { TenantsContext } from '../../context/tenants.context'
import { Rubro } from '../Integraciones/types/responses-productos'
import DialogImportarCatalogo from '../Integraciones/Importacion/DialogImportarCatalogo'
import DialogExportarCatalogo from '../Integraciones/Exportacion/DialogExportarCatalogo'
import { useStoreImportacion } from '../Integraciones/Importacion/store/store-importacion'
import { useStoreExportacion } from '../Integraciones/Importacion/store/store-exportacion'
import { useResponsive } from '../../hooks/useResponsive.hook'
import useRubrosLocal from './hooks/rubros.hook'
import { useAppStore } from '../../store/app-store'

const USER_ROLES: any = {
  seller: 'Vendedor',
  admin: 'Administrador'
}

export const getStatusColor = (p: any) => {
  if (p.aprobado && !p.pausado && !p.vencido) return 'success'
  if (p.aprobado && !p.pausado && p.vencido) return 'warning'
  return 'error'
}

export interface ProductListProps {
  products?: any
  rubros?: Rubro[]
  isAdmin: boolean
  total?: number
  handleResumeProduct: (code: string, tenant: ITenant) => void
  handleUnPauseProducts: () => void
  handlePauseProduct: (code: string, tenant: ITenant) => void
  handlePauseProducts: () => void
  handleDeleteProduct: (code: string, tenant: ITenant) => void
  handleDeleteProducts: () => void
  handleChangeRubro: (codRubro: string, tenant: ITenant) => void
  handleEditProduct: (code: string, tenant: ITenant) => Promise<void>
}

const INITIAL_ROWS_PER_PAGE = 10

// eslint-disable-next-line react/display-name
const ImportacionExportacion = memo(({ getProducts }: { getProducts: () => Promise<void> }) => {
  const setOpenImpo = useStoreImportacion((state) => state.setOpen)
  const setOpenExpo = useStoreExportacion((state) => state.setOpen)
  const { isMobile } = useResponsive()

  const importar = useCallback(() => {
    setOpenImpo(true)
    setOpenExpo(false)
  }, [setOpenImpo, setOpenExpo])

  const exportar = useCallback(() => {
    setOpenExpo(true)
    setOpenImpo(false)
  }, [setOpenImpo, setOpenExpo])

  return (
    <Box display="flex" gap="10px" marginRight="12px">
      {/* Importar / Exportar Dialogs */}
      <DialogImportarCatalogo getProducts={getProducts} />
      <DialogExportarCatalogo />

      {/* Botones */}
      <Button color="primary" variant="outlined" fullWidth={isMobile} onClick={importar}>
        Importar
      </Button>
      <Button color="primary" variant="outlined" fullWidth={isMobile} onClick={exportar}>
        Exportar
      </Button>
    </Box>
  )
})

const AbmProducts: React.FC = (props: any) => {
  const { codLocal, idLocal, roles, token } = props.logged.userLogged
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // PAGINADOR

  const {
    tabsProductos,
    setTabsProductos,
    productos,
    accionesMasivas,
    productosSeleccionados,
    setProductosSeleccionados,
    puedePausarVarios,
    puedeReanudarVarios,
    confirmDialog,
    setConfirmDialog,
    setCodLocal,
    getProductosVendedor,
    handleLeft,
    setSearchFilter,
    setRubroFilter,
    ordenamiento,
    setOrdenamiento,
    estado,
    setEstado,
    setPage,
    setPageSize,
    paginator,
    loading: loadingProductos
  } = useContext(ProductosVendedorContext)
  const isAdmin = roles.includes(USER_ROLES.admin)
  const {
    loadingProductosMasivos,
    handleDeleteProducts,
    handlePauseProducts,
    handleUnPauseProducts,
    handleChangeRubro
  } = useAccionesMasivas({
    isAdmin,
    productosSeleccionados,
    setProductosSeleccionados,
    setConfirmDialog
  })

  const [tmpSearchFilter, setTmpSearchFilter] = useState<string>('')
  const [tmpRubroFilter, setTmpRubroFilter] = useState<string>()

  const {
    retail,
    wholesaler,
    maxPhotos,
    maxPublicaciones,
    esMinorista,
    precioDto,
    ofrecerPremium,
    usaSku,
    showImpoExpo
  } = useLocal(codLocal, token)

  const { rubros, loading: loadingRubros } = useRubrosLocal()
  const [modalNewProduct, setModalNewProduct] = useState<boolean>(false)
  const [modalEditProduct, setModalEditProduct] = useState<boolean>(false)
  const [editProduct, setEditProduct] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [cantOfertasDisp, setCantOfertasDisp] = useState<number>(0)
  const { metricas } = useMetricas(codLocal)
  const { palabrasProhibidas, loading: loadingPalabrasProhibidas } = usePalabrasProhibidas()
  const { tenant } = useContext(TenantsContext)

  useEffect(() => {
    setCodLocal(codLocal)
  }, [codLocal])

  paginator.handleChangePage = (e: any, newPage: number) => {
    setPage(newPage)
    const tabs = [...tabsProductos]
    tabs[estado.key].page = newPage
    setTabsProductos(tabs)
  }

  paginator.handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    //setPage(0);
    const tabs = [...tabsProductos]
    tabs[estado.key].pageSize = parseInt(event.target.value, 10)
    setTabsProductos(tabs)
  }

  const handleActionClick = useCallback(
    async (action: string, code: string, msj: string) => {
      setLoading(true)
      await BUTTONS[action]
        .action(code, tenant)
        .then(() => {
          SnackBarUtils.success(`Producto ${code} ${msj}`)
          TABS.map((tab: Estado) => {
            getProductosVendedor(tab.key)
          })
        })
        .finally(() => setLoading(false))
      // setProducts((products: any) => products.filter((p: any) => p.id !== id))
    },
    [getProductosVendedor]
  )

  const handleEditProduct = useCallback(async (code: string) => {
    setEditProduct(code)
    setModalEditProduct(true)
  }, [])

  const executeSearch = async () => {
    setLoading(true)
    setSearchFilter(tmpSearchFilter)
    tmpRubroFilter && setRubroFilter(tmpRubroFilter)
    setPage(0)

    setLoading(false)
  }

  const clearConfirmDialog = useCallback(() => {
    setConfirmDialog((confirmDialog: any) => ({
      ...confirmDialog,
      open: false
    }))
  }, [setConfirmDialog])

  const handlePauseProduct = useCallback(
    (productCode: string) => {
      setConfirmDialog({
        open: true,
        text: {
          title: '¿Deseas pausar esta publicacion?',
          body: '',
          leftButton: 'Cancelar',
          rightButton: 'Confirmar',
          colorButtonConfirm: 'primary'
        },
        action: async () => {
          setLoading(true)
          await handleActionClick('pause', productCode, 'pausado')
          clearConfirmDialog()
          setLoading(false)
        },
        close: () => clearConfirmDialog()
      })
    },
    [handleActionClick, clearConfirmDialog]
  )

  const handleResumeProduct = useCallback(
    (productCode: string) => {
      setConfirmDialog({
        open: true,
        text: {
          title: '¿Deseas reanudar esta publicacion?',
          body: '',
          leftButton: 'Cancelar',
          rightButton: 'Confirmar',
          colorButtonConfirm: 'primary'
        },
        action: async () => {
          setLoading(true)
          await handleActionClick('resume', productCode, 'despausado')
          clearConfirmDialog()
          setLoading(false)
        },
        close: () => clearConfirmDialog()
      })
    },
    [handleActionClick, clearConfirmDialog]
  )

  const handleDeleteProduct = useCallback(
    (productCode: string) => {
      setConfirmDialog({
        open: true,
        text: {
          title: '¿Deseás eliminar esta publicación?',
          body: 'Tené en cuenta que esto no se puede deshacer',
          leftButton: 'Cancelar',
          rightButton: 'Confirmar',
          colorButtonConfirm: 'error'
        },
        action: async () => {
          await handleActionClick('delete', productCode, 'eliminado')
          clearConfirmDialog()
        },
        close: () => clearConfirmDialog()
      })
    },
    [handleActionClick]
  )

  useEffect(() => {
    metricas && setCantOfertasDisp(metricas.cantOfertasDisp)
  }, [metricas])

  if (loadingRubros) return <></>

  return (
    <>
      <Navbar title="Catálogo de productos" />

      <Container>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            style={{
              marginTop: '2vh',
              display: 'flex',
              justifyContent: 'center',
              gap: isMobile ? '14px' : '10px'
            }}
            sx={{ '@media (max-width:600px)': { flexDirection: 'column' } }}
          >
            <FormControl style={{ flexGrow: '1' }}>
              <InputLabel id="demo-simple-select-label">Orden</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                native
                label="Orden"
                value={ordenamiento}
                onChange={(e: any) => setOrdenamiento(e.target.value)}
              >
                {ordenamientos.map((ordenamiento: Ordenamiento) => (
                  <option key={ordenamiento.id} value={ordenamiento.id}>
                    {ordenamiento.criterio}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="outlined-basic"
              style={{ flexGrow: '2' }}
              label="Buscar por nombre, descripción o código/sku"
              onChange={(e: any) => setTmpSearchFilter(e.target.value)}
              onKeyPress={(e: any) => e.key === 'Enter' && executeSearch()}
              variant="outlined"
            />
            <FormControl style={{ flexGrow: '1' }}>
              <InputLabel id="demo-simple-select-label">Rubro</InputLabel>
              <Select
                native
                label="Rubro"
                defaultValue={'0'}
                onKeyPress={(e: any) => e.key === 'Enter' && executeSearch()}
                onChange={(e: any) => setTmpRubroFilter(e.target.value)}
              >
                <option value="0" label="Todos" />
                {rubros &&
                  rubros?.map((group: any, i: number) => (
                    <optgroup key={i} label={group.nombre.toUpperCase()}>
                      {group?.rubros?.map((subrubro: any, h: number) => {
                        return subrubro.rubros?.length > 0 ? (
                          subrubro.rubros.map((subrubro2: any, j: number) => (
                            <option key={j} value={subrubro2.codRubro}>
                              {subrubro.nombre + ' - ' + subrubro2.nombre}
                            </option>
                          ))
                        ) : (
                          <option key={h} value={subrubro.codRubro}>
                            {subrubro.nombre}
                          </option>
                        )
                      })}
                    </optgroup>
                  ))}
              </Select>
            </FormControl>

            <Button variant="contained" onClick={() => executeSearch()}>
              Buscar
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row-reverse',
              marginY: '20px',
              gap: '20px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: isMobile ? '100%' : '100%'
              }}
            >
              {showImpoExpo && (
                <ImportacionExportacion
                  getProducts={async () => {
                    await getProductosVendedor(0)
                    await getProductosVendedor(1)
                    await getProductosVendedor(2)
                  }}
                />
              )}
              <Button
                variant="contained"
                disabled={loading || loadingPalabrasProhibidas}
                fullWidth={isMobile}
                color="success"
                onClick={() => {
                  if (cantOfertasDisp <= 0 && !ofrecerPremium) {
                    SnackBarUtils.info(`Alcanzaste el límite de ${maxPublicaciones} publicaciones`)
                  } else {
                    setModalNewProduct(true)
                  }
                }}
              >
                Agregar nuevo producto
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                width: '100%'
              }}
            >
              <Tabs value={estado.key} scrollButtons="auto" variant="scrollable">
                <Tab
                  onClick={() => setEstado(TABS[0])}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        flexDirection: isMobile ? 'column' : 'row'
                      }}
                    >
                      <span>Activos</span>
                      <span>{`(${tabsProductos[0].totalRecord})`}</span>
                    </Box>
                  }
                />
                <Tab
                  onClick={() => setEstado(TABS[1])}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        flexDirection: isMobile ? 'column' : 'row'
                      }}
                    >
                      <span>Vencidos</span>
                      <span>{`(${tabsProductos[1].totalRecord})`}</span>
                    </Box>
                  }
                />
                <Tab
                  onClick={() => setEstado(TABS[2])}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        flexDirection: isMobile ? 'column' : 'row'
                      }}
                    >
                      <span>Inactivos</span>
                      <span>{`(${tabsProductos[2].totalRecord})`}</span>
                    </Box>
                  }
                />
              </Tabs>
            </Box>
          </Box>
        </Box>

        {loadingProductos ? (
          <Loading />
        ) : isMobile ? (
          <MobileProductsList
            loading={loading}
            products={tabsProductos[estado.key].productos}
            total={tabsProductos[estado.key].totalRecord}
            isAdmin={isAdmin}
            handleResumeProduct={handleResumeProduct}
            handlePauseProduct={handlePauseProduct}
            handleDeleteProduct={handleDeleteProduct}
            handleDeleteProducts={handleDeleteProducts}
            handlePauseProducts={handlePauseProducts}
            handleUnPauseProducts={handleUnPauseProducts}
            handleChangeRubro={handleChangeRubro}
            handleEditProduct={handleEditProduct}
            page={tabsProductos[estado.key].page}
            paginator={paginator}
            rowsPerPage={tabsProductos[estado.key].pageSize}
            rubros={rubros}
          />
        ) : (
          <Tabla
            products={tabsProductos[estado.key].productos}
            total={tabsProductos[estado.key].totalRecord}
            isAdmin={isAdmin}
            handleResumeProduct={handleResumeProduct}
            handlePauseProduct={handlePauseProduct}
            handleDeleteProduct={handleDeleteProduct}
            handleDeleteProducts={handleDeleteProducts}
            handlePauseProducts={handlePauseProducts}
            handleUnPauseProducts={handleUnPauseProducts}
            handleChangeRubro={handleChangeRubro}
            handleEditProduct={handleEditProduct}
            loading={loading}
            page={tabsProductos[estado.key].page}
            paginator={paginator}
            rowsPerPage={tabsProductos[estado.key].pageSize}
            isMobile={isMobile}
            usaSku={usaSku}
            rubros={rubros}
          />
        )}
      </Container>
      <Footer />

      <CreateProduct
        admin={isAdmin}
        open={modalNewProduct}
        setOpen={setModalNewProduct}
        precioDto={precioDto}
        retailer={retail}
        //wholesaler={wholesaler}
        rubros={rubros}
        maxPhotos={maxPhotos}
        codLocal={codLocal}
        getProducts={getProductosVendedor}
        esMinorista={esMinorista}
        cantOfertasDisp={cantOfertasDisp}
        cantProducts={productos.length}
        usaSku={usaSku}
        ofrecerPremium={ofrecerPremium}
        palabrasProhibidas={palabrasProhibidas}
      />

      {editProduct && (
        <EditProduct
          admin={false}
          open={modalEditProduct}
          setOpen={setModalEditProduct}
          precioDto={precioDto}
          retailer={retail}
          codLocal={codLocal}
          editProductCode={editProduct}
          getProducts={getProductosVendedor}
          maxPhotos={maxPhotos}
          esMinorista={esMinorista}
          handlePauseProduct={handlePauseProduct}
          usaSku={usaSku}
          ofrecerPremium={ofrecerPremium}
          palabrasProhibidas={palabrasProhibidas}
          rubros={rubros}
        />
      )}

      {accionesMasivas && isMobile && (
        <AccionesMasivasButtons
          isAdmin={isAdmin}
          rubros={rubros}
          handleDeleteProducts={handleDeleteProducts}
          handlePauseProducts={handlePauseProducts}
          handleUnPauseProducts={handleUnPauseProducts}
          handleLeft={handleLeft}
          puedePausarVarios={puedePausarVarios}
          puedeReanudarVarios={puedeReanudarVarios}
          puedeVencerVarios={false}
        />
      )}
      <ConfirmDialog {...confirmDialog} disabled={loadingProductosMasivos} />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  // resetToaster,
  setRedirectUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(AbmProducts))
