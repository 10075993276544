import React, { useContext, useState, useEffect } from 'react'
import { Button, Box } from '@mui/material'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import Navbar from '../../components/Navbar'
import { useTheme, useMediaQuery } from '@mui/material'
import FechaStickers from './components/setFechaActualizada'
import ListadoStickers from './components/listStickers'
import { getListadoStickers } from './services/stickers.services'
import ExportStickers from './components/exportStickers'
import Loading from '../../components/Common/Loading'
import Footer from '../Home/components/Footer'
import { TenantsContext } from '../../context/tenants.context'

const Stickers: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [stickers, setStickers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { tenant } = useContext(TenantsContext)

  const theme = useTheme()

  useEffect(() => {
    const fetchStickers = async () => {
      try {
        setLoading(true)
        const fetchedStickers = await getListadoStickers(tenant)
        setStickers(fetchedStickers.data)
      } catch (error) {
        console.error('Error al obtener los stickers:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchStickers()
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <Navbar title="Stickers" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '-54px'
        }}
      >
        <Button
          variant="contained"
          sx={{ margin: '10px' }}
          onClick={() => setOpen(true)}
        >
          Seteo Masivo de fecha de puesta de stickers
        </Button>
        <ExportStickers stickers={stickers} />
      </Box>
      <ListadoStickers stickers={stickers} />
      <FechaStickers open={open} setOpen={setOpen} />
      <Footer />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(Stickers)
