import { useCallback, useEffect, useState } from 'react'
import { CsvTemplate } from '../types/importacion'
import SnackBarUtils from '../../../../utils/SnackBarUtils'
import { GetCSVTemplate } from '../../services/impo-expo.service'
import { useAppStore } from '../../../../store/app-store'

const useCsvTemplate = () => {
  const codLocal = useAppStore((state) => state.codLocal)
  const [csvTemplate, setCsvTemplate] = useState<CsvTemplate[] | null>(null)
  const [loading, setLoading] = useState(false)

  const getCsvTemplate = useCallback(async () => {
    try {
      setLoading(true)
      const res = await GetCSVTemplate(codLocal)
      if (res.status === 200) {
        setCsvTemplate(res.data.data)
        return res.data.data
      }
    } catch (error) {
      SnackBarUtils.error('Error al obtener el template')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (csvTemplate) return
    ;(async () => {
      await getCsvTemplate()
    })()
  }, [setLoading, codLocal])

  return { csvTemplate, getCsvTemplate, loading }
}

export default useCsvTemplate
