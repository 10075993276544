import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import setRedirectUrl from '../../redux/actions/redirection/setRedirectUrl'
import setLogged from '../../redux/actions/logged/setLogged'
import Navbar from '../../components/Navbar'
import { useTheme, useMediaQuery } from '@mui/material'
import AdminHistoriasFiltros from './componentes/historias.filtros'
import TablaHistorias from './componentes/desktop/TablaHistorias'
import CardHistorias from './componentes/mobile/CardsHistorias'
import SnackBarUtils from '../../utils/SnackBarUtils'
import Footer from '../Home/components/Footer'
import { HistoriasAdminContext } from './context/historias.context'

const AdminHistorias: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [copied, setCopied] = useState(false)
  const { loading: loadingHistorias } = useContext(HistoriasAdminContext)
  const copyShareLink = (shareLink: string) => {
    navigator.clipboard.writeText(shareLink)
    setCopied(true)
  }

  useEffect(() => {
    if (copied) {
      SnackBarUtils.success('Link copiado al portapapeles')
    }
  }, [copied])

  return (
    <>
      <Navbar title="Historias" />
      <AdminHistoriasFiltros />
      {isMobile ? <CardHistorias copyShareLink={copyShareLink} /> : <TablaHistorias copyShareLink={copyShareLink} />}
      {!loadingHistorias && <Footer />}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    logged: state.logged,
    theme: state.theme,
    toaster: state.toaster,
    redirection: state.redirection
  }
}

const mapDispatchToProps = {
  setRedirectUrl,
  setLogged
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHistorias)
