import { ITenant } from "../../AbmLocalPage/types";
import { axiosInstanceMain } from "./../../../utils/axiosGlobalUtils";

export const EnviarMensajeContacto = async (
  codLocal: string,
  data: any,
  tenant: ITenant
) =>
  await axiosInstanceMain.post(`${tenant.path}/contactos/local/${codLocal}`, {
    descripcion: data,
    versionApp: "backoffice",
  });
