import { cleanString, eliminarTildes } from '../../../../utils/utils'
import { GetAtributosEquivalencias, GetAtributosEquivalenciasValores } from '../../services/atributos.service'
import { Atributo, AtributosEquivalencias, ValoresList } from '../../types'
import { TiendaAtributo } from '../../types/responses-productos'
import { CATEGORIA_NAME } from '../ImportarCatalogo'
import { Attribute, RubroYValores } from '../types/atributos'
import { Columna, CsvTemplate, Equivalencia, ImportarData } from '../types/importacion'

export type AtributoType = 'color' | 'talle' | 'medida' | 'tamano' | 'composicion'

export const areValidEquivalencias = (equivalencias: Equivalencia[]) => {
  return equivalencias.every((eq) => {
    const { value, no_importar } = Object.values(eq)[0]
    return no_importar || (value !== 0 && value && value.toString() !== '')
  })
}

export const recorrerDataExcelYReemplazar = async (
  dataExcel: any[],
  columnasEquivalencias: Equivalencia[],
  categoriasEquivalencias: Equivalencia[]
): Promise<ImportarData[]> => {
  const promises: Promise<ImportarData>[] = dataExcel.map(async (row) => {
    return new Promise<ImportarData>((resolve) => {
      const newRow: any = {}
      Object.keys(row).forEach(async (key) => {
        // Reemplazar columnas por equivalencias
        const eqCol = columnasEquivalencias.find((eq) => Object.values(eq)[0].value === key)
        if (eqCol === undefined) return
        const nombreColumna = Object.keys(eqCol)[0]
        newRow[nombreColumna] = row[key]

        const newKey = nombreColumna

        // Reemplazar categorias por equivalencias
        if (Object.keys(eqCol).find((eq) => eq === CATEGORIA_NAME) !== undefined) {
          const eqCat = categoriasEquivalencias.find((eq) => Object.keys(eq)[0] === newRow[newKey])
          if (eqCat === undefined) return
          const nombreColumna = Object.values(eqCat)[0]
          newRow[newKey] = nombreColumna.value
        }

        // Reemplazar vacíos por null
        if (newRow[newKey] === '') newRow[newKey] = null
      })

      resolve(newRow)
    })
  })

  return Promise.all(promises)
}

export const getDefaultValue = (csv: CsvTemplate, columnas: Columna[]) =>
  columnas.find(
    (col) =>
      cleanString(csv.columna) === cleanString(col.nombre) ||
      cleanString(col.nombre) === cleanString(csv.descripcion) ||
      csv.alias.find((a) => cleanString(a) === cleanString(col.nombre))
  )

export const eliminarDuplicados = (valoresList: any) => {
  // eliminar si hay mas de un valor con misma equivalencia
  for (const key in valoresList) {
    if (valoresList[key].length > 1) {
      const valores = valoresList[key]
      const newValues = valores.filter((v: ValoresList, i: number, self: ValoresList[]) => {
        return i === self.findIndex((t) => t.equivalencia === v.equivalencia)
      })

      valoresList[key] = newValues
    }
  }

  return valoresList
}

export const attributes: { [key: string]: Attribute } = {
  colores: { singular: 'color', plural: 'colores' },
  talles: { singular: 'talle', plural: 'talles' },
  composicion: { singular: 'composicion', plural: 'composiciones' },
  medida: { singular: 'medida', plural: 'medidas' },
  tamano: { singular: 'tamano', plural: 'tamanos' }
}

export const tieneValoresParaAsignar = (atributos: any, atributo: string, RubroYValores?: RubroYValores[]): boolean => {
  const atributoLower = atributo.toLowerCase()
  if (RubroYValores) {
    if (RubroYValores.every((r) => r.atributosDefault.key.toLowerCase() !== atributoLower)) return false
  }

  for (const key in atributos[0]) {
    if (atributos[0][key][atributoLower] && atributos[0][key][atributoLower].length > 0) {
      const rubro_valor = RubroYValores.find((r) => r.atributosDefault.key === atributoLower)

      if (rubro_valor) {
        return !rubro_valor.atributosParaAsignar.every((a) => {
          return rubro_valor.atributosDefault.all.valoresList.some((v) => v.nombre.toLowerCase() === a.toLowerCase())
        })
      }
    }
  }
  return false
}

export const ObtenerAtributosValoresEquivalencias = async (
  codLocal: string,
  atributoName: AtributoType,
  atributos: Atributo[]
) => {
  try {
    const valores: AtributosEquivalencias[] = []

    for (const atributo of atributos) {
      if (
        (eliminarTildes(atributo.nombreAa1tSingular.toLowerCase()) !== atributoName &&
          eliminarTildes(atributo.nombreAa1tPlural.toLowerCase()) !== atributoName) ||
        !atributo.idIntegracionAtributo
      )
        continue

      const { data: valoresResponse } = await GetAtributosEquivalenciasValores(atributo.idIntegracionAtributo, codLocal)
      if (valoresResponse.length > 0) valores.push(...valoresResponse)
    }

    return valores
  } catch (error) {
    console.error(error)
  }
}

export const getListAtributosValores = async (
  codLocal: string,
  atributo: AtributoType,
  atributos: TiendaAtributo[]
) => {
  try {
    const { data: atributosEquivalenciasValores } = await GetAtributosEquivalencias(codLocal)

    const atributoFound = atributosEquivalenciasValores.find((a) => a.nombreAa1tSingular.toLowerCase() === atributo)
    if (!atributoFound) return []

    return null
  } catch (error) {
    console.log(error)
  }
}
