import { axiosInstanceMain } from "../../../utils/axiosGlobalUtils";
import { hash } from "../../../utils/hashFunction";
import { ITenant } from "../types";

export const validarNombre = async (value: any, tenant: ITenant, codLocal?: string) => {
  if (value === "") return;

  const clave = await hash(value + "lugares2017");
  const url = codLocal
    ? `${tenant.path}/locales/nombre-en-uso?codLocal=${codLocal}&clave=${clave}`
    : `${tenant.path}/locales/nombre-en-uso?clave=${clave}`;
  return await axiosInstanceMain.post(url, value, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const validarUsuario = async (value: string, tenant: ITenant, idVendedor?: string) => {
  if (value === "") return;

  const clave = await hash(value + "lugares2017");
  const url = idVendedor
    ? `${tenant.path}/locales/user-en-uso?idVendedor=${idVendedor}&clave=${clave}`
    : `${tenant.path}/locales/user-en-uso?clave=${clave}`;
  return await axiosInstanceMain.post(url, value, {
    headers: { "Content-Type": "text/plain" },
  });
};

export const validarDireccion = async (direccion: string, codLocal = "", tenant: ITenant) => {
  const clave = await hash(direccion + "lugares2017");
  return await axiosInstanceMain.post(`${tenant.path}/locales/validar-direccion?clave=${clave}`, {
    direccion,
    codLocal,
  });
};

export const validarWsp = async (wsp: string, tenant: ITenant) => {
  const clave = await hash(wsp + "lugares2017");
  return await axiosInstanceMain.get(`${tenant.path}/locales/whatsapp-en-uso/${wsp}?clave=${clave}`);
};
