import React, { useEffect, useState } from "react";
import "../../styles/contractsRenovations.scss";
import * as GrIcons from "react-icons/gr";
import { LikeIcon } from "../../icons/like-icon/like-icon.component";
import { NolikeIcon } from "../../icons/nolike-icon/nolike-icon.component";
import ComboBox from "../../components/Common/ComboBox";
import { ICalificacionesList, ICalificacionXAtr } from "../../pages/Calificaciones/calificaciones.hook";
import { Button, useTheme, useMediaQuery, styled } from "@mui/material";
import DialogResponder from "../../pages/Calificaciones/popupResponder";

const ContentComent = styled("div")({
  border: "1px solid",
  backgroundColor: "#dbdbdb",
  borderRadius: "5px",
  marginBottom: "10px",
  padding: "5px",
  overflowWrap: "anywhere",
});

const LineOne = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
});

const LineTwo = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "30px",
  [theme.breakpoints.down(425)]: {
    gap: "2px",
  },
}));

const ContentFiltros = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  padding: "12px",
  gap: "10px",
  border: "2px solid",
  borderRadius: "7px",
  marginBottom: "15px",
  [theme.breakpoints.down(425)]: {
    padding: "2px",
    gap: "2px",
  },
}));

const Title = styled("p")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
  marginBottom: "5px",
  [theme.breakpoints.down(425)]: {
    width: "85%",
  },
}));

const Autor = styled("p")(({ theme }) => ({
  marginBottom: "5px",
  [theme.breakpoints.down(425)]: {
    width: "90%",
  },
}));

const Filtros = styled("label")({
  fontWeight: "bold",
  width: "90%",
});

const Header = styled("header")({
  height: "4vh",
});

interface Props {
  getUrl?: string;
  handleClose?: any;
  headers?: string[];
  show?: string;
  title?: string;
  isAdmin: boolean;
  codLocal: string;
  options: ICalificacionXAtr[];
  data?: ICalificacionesList[];
}

const ModalTable: React.FC<any> = (props: Props) => {
  const [comentarios, setComentarios] = useState<any[]>([]);
  const [atributos, setAtributos] = useState<any[]>([]);
  const [filtroAtributo, setFiltroAtributo] = useState<string>("0");
  const [filtroSentimiento, setFiltroSentimiento] = useState<string>("0");
  const [idResponde, setIdResponde] = useState<number>(0);
  const [openDialogResponder, setOpenDialogResponder] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const showHideClassName = props.show ? "modal display-block" : "modal display-none";

  useEffect(() => {
    if (props && props.data) {
      const atr: any[] = [];
      atr[0] = { name: "Todos", value: 0 };
      props.options.map((c: ICalificacionXAtr) => {
        if (!atr.some((a) => a.name === c.nombre))
          atr.push({
            name: c.nombre,
            value: c.idCalificacionAtributo,
          });
      });
      setAtributos(atr);
      setComentarios(props.data);
    }
  }, [props.data, props.options]);

  useEffect(() => {
    if (filtroAtributo && filtroSentimiento) {
      const allComents = props.data ? props.data : [];
      let coments = [...allComents];
      if (filtroSentimiento !== "0") {
        coments = [
          ...allComents.filter((c) => {
            if (filtroSentimiento === "1") return c.positiva === true;
            else if (filtroSentimiento === "2") return c.positiva === false;
            else if (filtroSentimiento === "") return c;
          })
        ];
      }
      if (filtroAtributo !== "0") {
        coments = [
          ...coments.filter((c) => {
            return c.idAtributo === Number.parseInt(filtroAtributo);
          })
        ];
      }
      setComentarios(coments);
    }
  }, [filtroAtributo, filtroSentimiento]);

  const closeRenovations = () => {
    props.handleClose();
  };

  const handleFiltroAtributoChange = async (event: any) => {
    await setFiltroAtributo(event.target.value);
  };

  const handleFiltroSentimientoChange = async (event: any) => {
    await setFiltroSentimiento(event.target.value);
  };

  return (
    <>
      <div
        className={props.isAdmin ? showHideClassName : ""}
        style={{ margin: isMobile ? "8px 7px 60px 7px" : "20px 10px 20px 0px" }}
      >
        <section
          className={props.isAdmin ? "modal-main" : "custom-modal-main"}
          style={{
            ...(props.isAdmin && { height: "700px", overflow: "auto" }),
          }}
        >
          {props.isAdmin && (
            <Header className="contract-actions-header">
              <h3 className="contract-actions-title">{props.title !== undefined && props.title}</h3>
              <button className="modal-close" onClick={closeRenovations} aria-label="Cerrar">
                <GrIcons.GrClose />
              </button>
            </Header>
          )}
          <div>
            <ContentFiltros>
              <Filtros className="premise-info-child">Filtros: </Filtros>
              <div>
                {" "}
                <ComboBox
                  name="atributos"
                  label="Atributo:"
                  styles="premise-info-child"
                  htmlFor="automaticApproval"
                  onChange={handleFiltroAtributoChange}
                  options={atributos}
                />
              </div>
              <div>
                <ComboBox
                  name="sentimiento"
                  label="Pos. y Neg:"
                  styles="premise-info-child"
                  htmlFor="automaticApproval"
                  onChange={handleFiltroSentimientoChange}
                  options={[
                    { value: 0, name: "Todos" },
                    { value: 1, name: "Positivos" },
                    { value: 2, name: "Negativos" }
                  ]}
                />
              </div>
            </ContentFiltros>
            {comentarios &&
              comentarios.map((item: ICalificacionesList, index) => {
                const fecha = new Date(item.fecha);
                const fechaRespuesta = new Date(item.respuestaLocalFecha);
                return (
                  <React.Fragment key={item.idCalificacion}>
                    <ContentComent>
                      <LineOne>
                        <Title>{item.titulo}</Title>
                        <div>{item.positiva ? <LikeIcon></LikeIcon> : <NolikeIcon></NolikeIcon>}</div>
                      </LineOne>
                      <LineTwo>
                        <Autor>
                          {"De: "}
                          {item.compradorNombre}
                        </Autor>
                        <div>
                          {"Calificado el: "}
                          {fecha.toLocaleString()}
                        </div>
                      </LineTwo>

                      <p>{item.comentarios}</p>
                      {item.respuestaLocal && item.respuestaLocalFecha && (
                        <>
                          <Title>{`Respondiste el ${fechaRespuesta.toLocaleDateString()} (${
                            item.respuestaLocalPublica ? "en" : "no"
                          } público)`}</Title>
                          <p>{item.respuestaLocal}</p>
                        </>
                      )}
                      {!props.isAdmin && item.puedeResponder && (
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            color: "white",
                            backgroundColor: "#919999",
                            "&:hover": {
                              backgroundColor: "#6e6e6e",
                            },
                          }}
                          onClick={() => {
                            setIdResponde(item.idCalificacion);
                            setOpenDialogResponder(true);
                          }}
                        >
                          {" "}
                          Contactar al comprador
                        </Button>
                      )}
                    </ContentComent>
                  </React.Fragment>
                );
              })}
          </div>
        </section>
      </div>
      <DialogResponder
        open={openDialogResponder}
        setOpen={setOpenDialogResponder}
        codLocal={props.codLocal}
        idCalificacion={idResponde}
      />
    </>
  );
};

export default ModalTable;
