import { FC, memo } from 'react'
import { Box, Typography } from '@mui/material'
import { FiShoppingCart } from 'react-icons/fi'
import ButtonRounded from '../../../../../../components/Common/utils/button/ButtonRounded'

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  cantProductosAA1T: number
}

const ButtonOpenCarrito: FC<Props> = ({ cantProductosAA1T, setOpenDrawer }) => {
  return (
    <ButtonRounded
      borderSize="1px"
      textColor="#212121"
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        gap: '16px',
        borderRadius: '40px',
        px: { xs: '20px', lg: '40px' },
        py: { xs: '16px', lg: '12px' },
        ':hover': {
          color: '#5983EF'
        }
      }}
      onClick={() => setOpenDrawer(true)}
    >
      <Box position="relative">
        {cantProductosAA1T > 0 && (
          <Typography
            fontSize={10}
            fontWeight={800}
            align="center"
            position="absolute"
            top={0}
            right={-5}
            bgcolor="#5983EF"
            color="white"
            width="16px"
            height="16px"
            borderRadius="40px"
          >
            {cantProductosAA1T}
          </Typography>
        )}
        <FiShoppingCart fontSize={24} />
      </Box>
      <Typography fontSize={20} textTransform="initial" fontWeight={700} display={{ xs: 'none', md: 'block' }}>
        Productos a importar
      </Typography>
    </ButtonRounded>
  )
}

export default memo(ButtonOpenCarrito)
