import React, { createContext, useCallback, useEffect, useState } from 'react'
import {
  archiveContact,
  getContactosService,
  getConversacionService,
  markAsUnread,
  sendMessageService,
  unArchiveContact
} from '../services/whatsapp.service'
import SnackBarUtils from '../../../utils/SnackBarUtils'
import { mostrarFecha } from '../../../utils/DateFunctions'

export interface IContacto {
  id: number
  number: number | string
  lastMessageDate: string
  archived: boolean
  read: boolean
}

export const initialStateContacto: IContacto = {
  id: 0,
  number: 0,
  lastMessageDate: '',
  archived: false,
  read: false
}

export type StatusMessageType =
  | 'RECIBIDO'
  | 'LEIDO'
  | 'ENVIADO'
  | 'ENTREGADO'
  | 'FALLO'
  | 'ERROR'

export interface IConversacion {
  date: Date
  hasAttachment: boolean
  message: string
  outbound: boolean
  status: StatusMessageType
  read?: boolean
}

interface WhatsappContextProps {
  contactos: IContacto[]
  contactosFiltrados: IContacto[]
  conversacion: IConversacion[]
  isSearching: boolean
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>
  setContactosFiltrados: React.Dispatch<React.SetStateAction<IContacto[]>>
  contactoSeleccionado: IContacto
  setContactoSeleccionado: React.Dispatch<React.SetStateAction<IContacto>>
  getContactos: (phone: string | null) => Promise<void>
  getConversacion: () => Promise<void>
  loadingContactos: boolean
  loadingConversacion: boolean
  viendoContactosArchivados: boolean
  setViendoContactosArchivados: React.Dispatch<React.SetStateAction<boolean>>
  contactosArchivados: IContacto[]
  archivarContacto: (contacto: IContacto) => Promise<void>
  desarchivarContacto: (contacto: IContacto) => Promise<void>
  marcarNoLeido: (contacto: IContacto) => Promise<void>
  sendMessage: (message: string) => Promise<any>
}

export const WhatsappContext = createContext<WhatsappContextProps>({
  contactos: [],
  contactosFiltrados: [],
  conversacion: [],
  isSearching: false,
  setIsSearching: () => {},
  setContactosFiltrados: () => {},
  contactoSeleccionado: initialStateContacto,
  setContactoSeleccionado: () => {},
  getContactos: () => Promise.resolve(),
  getConversacion: () => Promise.resolve(),
  loadingContactos: true,
  loadingConversacion: false,
  viendoContactosArchivados: false,
  setViendoContactosArchivados: () => {},
  contactosArchivados: [],
  archivarContacto: () => Promise.resolve(),
  desarchivarContacto: () => Promise.resolve(),
  marcarNoLeido: () => Promise.resolve(),
  sendMessage: () => Promise.resolve()
})

export const WhatsappProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [contactoSeleccionado, setContactoSeleccionado] =
    useState<IContacto>(initialStateContacto)
  const [contactos, setContactos] = useState<IContacto[]>([])
  const [contactosFiltrados, setContactosFiltrados] = useState<IContacto[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [conversacion, setConversacion] = useState<IConversacion[]>([])
  const [loadingContactos, setLoadingContactos] = useState(true)
  const [loadingConversacion, setLoadingConversacion] = useState(false)
  const [viendoContactosArchivados, setViendoContactosArchivados] =
    useState(false)
  const [contactosArchivados, setContactosArchivados] = useState<IContacto[]>(
    []
  )

  const getContactos = async (phone: string | null) => {
    setLoadingContactos(true)
    await getContactosService()
      .then((res) => {
        setContactos(res.data.data)
        setContactosArchivados(
          res.data.data.filter((contacto: IContacto) => contacto.archived)
        )
        if (phone) goToContact(phone, res.data.data)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingContactos(false))
  }

  const goToContact = (phone: string, contacts: IContacto[]) => {
    const contactoEncontrado = contacts.find(
      (c: IContacto) => c.number === phone
    )
    if (!contactoEncontrado) SnackBarUtils.error('No se encontró el contacto')
    else setContactoSeleccionado(contactoEncontrado || initialStateContacto)
  }

  const getConversacion = useCallback(async () => {
    setLoadingConversacion(true)
    try {
      const res = await getConversacionService(contactoSeleccionado.id)
      const mensajesPorFecha = {} as any
      res.data.data[0].messages.forEach((mensaje: IConversacion) => {
        if (mostrarFecha(mensaje.date) in mensajesPorFecha) {
          mensajesPorFecha[mostrarFecha(mensaje.date)].push(mensaje)
        } else {
          mensajesPorFecha[mostrarFecha(mensaje.date)] = [mensaje]
        }
      })
      setConversacion(mensajesPorFecha)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingConversacion(false)
    }
  }, [contactoSeleccionado.id])

  const archivarContacto = useCallback(async (contacto: IContacto) => {
    try {
      await archiveContact(contacto.id)
      setContactos((prev) =>
        prev.map((c: IContacto) => {
          if (contacto.id === c.id) c.archived = true
          return c
        })
      )
      setContactosArchivados((prev) => [...prev, contacto])
      setViendoContactosArchivados(false)
      SnackBarUtils.success('Contacto archivado')
    } catch (error) {
      console.log(error)
    }
  }, [])

  const desarchivarContacto = useCallback(
    async (contacto: IContacto) => {
      try {
        await unArchiveContact(contacto.id)
        setContactos((prev) =>
          prev.map((c: IContacto) => {
            if (contacto.id === c.id) c.archived = false
            return c
          })
        )
        setContactosArchivados((prev) =>
          prev.filter((c: IContacto) => c.id !== contacto.id)
        )
        setViendoContactosArchivados(contactos.some((c) => c.archived))
        SnackBarUtils.success('Contacto desarchivado')
      } catch (error) {
        console.log(error)
      }
    },
    [contactos]
  )

  const marcarNoLeido = useCallback(async (contacto: IContacto) => {
    try {
      await markAsUnread(contacto.id)
      setContactos((prev) =>
        prev.map((c: IContacto) => {
          if (contacto.id === c.id) c.read = false
          return c
        })
      )
      SnackBarUtils.success('Contacto marcado como no leido')
    } catch (error) {
      console.log(error)
    }
  }, [])

  const sendMessage = useCallback(
    async (message: string) => {
      try {
        const res = await sendMessageService(contactoSeleccionado.id, message)
        await getConversacion()
        return res
      } catch (error) {
        console.log(error)
      }
    },
    [contactoSeleccionado.id, getConversacion]
  )

  useEffect(() => {
    if (!isSearching && contactosFiltrados.length > 0) setContactosFiltrados([])
  }, [isSearching])

  return (
    <WhatsappContext.Provider
      value={{
        contactos,
        contactosFiltrados,
        conversacion,
        isSearching,
        setIsSearching,
        loadingContactos,
        loadingConversacion,
        contactoSeleccionado,
        contactosArchivados,
        viendoContactosArchivados,
        setViendoContactosArchivados,
        setContactoSeleccionado,
        setContactosFiltrados,
        getConversacion,
        getContactos,
        archivarContacto,
        desarchivarContacto,
        marcarNoLeido,
        sendMessage
      }}
    >
      {children}
    </WhatsappContext.Provider>
  )
}
