import { axiosInstanceMain } from '../../../utils/axiosGlobalUtils'
import { ITenant } from '../../AbmLocalPage/types'
import { Product } from '../types/product'

export const getAllProducts = async (
  tenant: ITenant,
  codLocal: string,
  ordenamiento: string,
  estado: string,
  page: number,
  pageSize: number,
  searchFilter: string | undefined,
  rubroFilter: string | undefined
) => {
  const queryParams = []

  queryParams.push(`page=${page + 1}&pagesize=${pageSize}`)
  if (searchFilter && searchFilter !== '') queryParams.push(`search=${searchFilter}`)
  if (rubroFilter && rubroFilter !== '0') queryParams.push(`codRubro=${rubroFilter}`)

  const queryString = queryParams.join('&')
  const url = `/${tenant.path}/productos/local/${codLocal}?ordenamiento=${ordenamiento}&estado=${estado}${
    queryString ? `&${queryString}` : ''
  }`

  const data = await axiosInstanceMain.get(url)
  const ofertas = data.data
  return ofertas
}

// TODO: apply trycatch
export const deleteProduct = async (productCode: string, tenant: ITenant) =>
  await axiosInstanceMain.delete(`/${tenant.path}/productos/${productCode}`)
export const deleteProducts = async (productsCodes: string, tenant: ITenant) =>
  await axiosInstanceMain.delete(`/${tenant.path}/productos?codes=${productsCodes}`)
export const updateProduct = async (product: any, code: string, tenant: ITenant) =>
  await axiosInstanceMain.put(`/${tenant.path}/productos/${code}`, product)
export const pauseProduct = async (productCode: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/${tenant.path}/productos/${productCode}/pausar`)
export const pauseProducts = async (productsCodes: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/${tenant.path}/productos/pausar?codes=${productsCodes}`)
export const unPauseProduct = async (productCode: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/${tenant.path}/productos/${productCode}/despausar`)
export const unPauseProducts = async (productsCodes: string, tenant: ITenant) =>
  await axiosInstanceMain.post(`/${tenant.path}/productos/despausar?codes=${productsCodes}`)
export const changeRubroProducts = async (productCodes: string[], codRubro: string, tenant: ITenant) =>
  await axiosInstanceMain.patch(`/admin/${tenant.path}/productos/rubro`, [
    {
      codRubro: codRubro,
      codProductoList: productCodes
    }
  ])
export const createProduct = async (product: Product, tenant: ITenant) =>
  await axiosInstanceMain.post(`/${tenant.path}/productos`, product)
export const getProduct = async (product: string, tenant: ITenant) =>
  await axiosInstanceMain.get(`/${tenant.path}/productos/${product}/vendedor`)
export const skuEnUso = async (code: any, sku: string, tenant: ITenant) => {
  const response = await axiosInstanceMain.post(`/${tenant.path}/productos/sku-en-uso?codLocal=${code}`, sku)
  return response.data
}
export const getPalabrasProhibidas = async (tenant: ITenant) =>
  await axiosInstanceMain.get(`/${tenant.path}/productos/palabras-prohibidas`)
