import { Search } from '@mui/icons-material'
import { Box, FormControl, InputAdornment, MenuItem } from '@mui/material'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { GetCategoriasTienda } from '../../../../../services/productos.service'
import { IntegracionesContext } from '../../../../../context/integraciones.context'
import { CategoryWoo } from '../../../../../types/responses'
import useRubros from '../../../../../../Products/hooks/rubros.hook'
import { useAppStore } from '../../../../../../../store/app-store'
import { CustomInput, CustomSelect } from '../../../../../../../components/Common/input/CustomInput'
import ButtonRounded from '../../../../../../../components/Common/utils/button/ButtonRounded'

const SearchFiltersTienda = () => {
  const { tiendaSeleccionada, getProductsTienda, loadingProductosTienda } = useContext(IntegracionesContext)
  const [form, setForm] = useState({
    name: '',
    category: '0',
    SKU: ''
  })
  const [categoriesWoo, setCategoriesWoo] = useState<CategoryWoo[]>([])
  const { rubros, loading: loadingRubros } = useRubros()
  const { codLocal } = useAppStore()

  const handleSearch = useCallback(async () => {
    if (loadingProductosTienda || loadingRubros) return
    await getProductsTienda(codLocal, form.category === '0' ? '' : form.category, form.name, form.SKU)
  }, [getProductsTienda, codLocal, form])

  const getCategorias = useCallback(
    async (isMounted) => {
      if (!tiendaSeleccionada || !isMounted) return null
      const data = await GetCategoriasTienda(tiendaSeleccionada.name, codLocal)
      if (isMounted) setCategoriesWoo(data.data.data)
    },
    [tiendaSeleccionada, codLocal]
  )

  const handleChange = useCallback(
    (value: string, filter: string) => setForm((prev) => ({ ...prev, [filter]: value })),
    [setForm]
  )

  const _setTienda = useCallback(
    async (isMounted) => {
      await getCategorias(isMounted)
    },
    [rubros, getCategorias]
  )

  useEffect(() => {
    let isMounted = true
    _setTienda(isMounted)
    return () => {
      isMounted = false
    }
  }, [_setTienda])

  return (
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" gap="8px">
      {/* Name Search */}
      <FormControl sx={{ my: 1, width: { xs: '100%', sm: 'auto' } }} variant="outlined">
        <CustomInput
          size="small"
          placeholder="Nombre"
          onChange={(e) => handleChange(e.target.value, 'name')}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          disabled={loadingProductosTienda || loadingRubros}
          sx={{ width: { xs: '100%', sm: '200px', md: '300px', lg: '400px' } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      {/* Category Select */}
      <FormControl sx={{ width: { xs: '100%', sm: 'auto' } }}>
        <CustomSelect
          defaultValue="0"
          onChange={(e) => handleChange(e.target.value, 'category')}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          sx={{ bgcolor: '#FDFDFE', borderRadius: '8px', width: { xs: '100%', sm: '200px', md: '300px', lg: '400px' } }}
          size="small"
          disabled={loadingProductosTienda || loadingRubros || categoriesWoo?.length === 0}
        >
          <MenuItem value="0">Todas las categorias</MenuItem>
          {categoriesWoo?.map((cat) => (
            <MenuItem key={cat.id} value={cat.id}>
              {cat.name}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>

      {/* CTA */}
      <ButtonRounded
        bgcolor="#5983EF"
        textColor="white"
        onClick={handleSearch}
        disabled={loadingProductosTienda || loadingRubros}
        sx={{ width: { xs: '100%', sm: 'auto' } }}
      >
        {loadingProductosTienda ? 'Buscando' : 'Buscar'}
      </ButtonRounded>
    </Box>
  )
}

export default memo(SearchFiltersTienda)
