import React, { useState } from "react";
import ModalCalificaciones from "./ModalCalificaciones";
import { ICalificacionXAtr, useCalificaciones } from "../../pages/Calificaciones/calificaciones.hook";
import { Box, Divider, Typography, Paper, useTheme, useMediaQuery, Rating } from "@mui/material";
import { styled } from "@mui/system";

const ContentStarts = styled("div")(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  alignItems: "flex-start",
  gap: "10px",
  [theme.breakpoints.down(399)]: {
    display: "block",
  },
}));

const ContentTable = styled("div")({
  width: "97%",
  marginRight: "10px",
  marginLeft: "10px",
});

const ContentFirstLine = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

const NoCalificaciones = styled("p")({
  marginLeft: "35px",
  fontWeight: "bold",
  marginTop: "15px",
});

const CalificacionesTitle = styled("p")({
  margin: "0px",
  fontWeight: "bold",
});

const AtributoName = styled("p")({
  marginBottom: "7px",
  maxWidth: "75%",
});

// TODO: user grid if we there are more attributes
// const Grilla = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//   grid-gap: 5px;
//   justify-items: flex-start;
//   align-items: flex-start;
// `;

// const GrillaElemento = styled.div`
//   background: grey;
//   text-align: center;
//   border: black 5px solid;
//   width: 100px;
//   height: 100px;
// `;

const Calificaciones: React.FC<any> = ({ codLocal, isAdmin }: { codLocal: any; isAdmin: boolean }) => {
  const [showModalCalificaciones, setShowModalCalificaciones] = useState<boolean>(false);
  const { calificacionesList, calificacionXatributoList } = useCalificaciones(codLocal);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const closeModalCalificaciones = () => {
    setShowModalCalificaciones(false);
  };

  return (
    <>
      {isAdmin && (
        <ModalCalificaciones
          show={showModalCalificaciones}
          handleClose={closeModalCalificaciones}
          headers={["Calificaciones"]}
          title={"Calificaciones"}
          options={calificacionXatributoList}
          data={calificacionesList}
          isAdmin={isAdmin}
        ></ModalCalificaciones>
      )}
      <ContentTable>
        <ContentFirstLine>
          {isAdmin && <CalificacionesTitle>Calificaciones</CalificacionesTitle>}
          {isAdmin && codLocal && (
            <button
              type="button"
              onClick={() => {
                setShowModalCalificaciones(true);
              }}
              disabled={
                calificacionXatributoList === null ||
                calificacionXatributoList === undefined ||
                (calificacionXatributoList && calificacionXatributoList.length === 0)
              }
            >
              Ver comentarios
            </button>
          )}
        </ContentFirstLine>
        {isAdmin ? (
          <div>
            {calificacionXatributoList ? (
              calificacionXatributoList.map((c: ICalificacionXAtr, index) => {
                return (
                  <React.Fragment key={c?.idCalificacionAtributo}>
                    <ContentStarts>
                      <AtributoName> {c?.nombre}:</AtributoName>
                      <span>({c?.puntaje.toFixed(1)})</span>
                      <Rating
                        name="rate1"
                        readOnly
                        size="large"
                        //starCount={5}
                        value={c?.puntaje}
                        //emptyStarColor={"#cccccc"}
                      />
                    </ContentStarts>
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <NoCalificaciones> Sin calificaciones</NoCalificaciones>
              </tr>
            )}
          </div>
        ) : (
          <Paper
            elevation={3}
            sx={{
              marginTop: isMobile ? "10px" : "20px",
              ...(isMobile && { margin: "10px 7px 10px 0px" }),
            }}
          >
            {calificacionXatributoList ? (
              calificacionXatributoList.map((c: ICalificacionXAtr, index) => {
                return (
                  <React.Fragment key={c?.idCalificacionAtributo}>
                    <Box padding={"10px"}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          variant="h5"
                          style={{
                            paddingLeft: "10px",
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontSize: isMobile ? "18px" : "24px",
                          }}
                        >
                          {c?.nombre}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            marginLeft: "auto",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              fontSize: isMobile ? "14px" : "26px",
                              fontWeight: "bold",
                            }}
                          >
                            ({c?.puntaje.toFixed(1)})
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Rating
                              name="rate1"
                              readOnly
                              size={isMobile ? "medium" : "large"}
                              //starCount={5}
                              value={c?.puntaje}
                              //emptyStarColor={"#cccccc"}
                            />
                          </div>
                        </Box>
                      </Box>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "-10px 20px 10px 0px",
                          fontSize: isMobile ? "14px" : "18px",
                        }}
                      >
                        {`(${c?.votos} opiniones)`}
                      </Typography>
                      {index !== calificacionXatributoList.length - 1 && <Divider sx={{ width: "100%" }} />}
                    </Box>
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <NoCalificaciones> Sin calificaciones</NoCalificaciones>
              </tr>
            )}
          </Paper>
        )}
      </ContentTable>
    </>
  );
};

export default Calificaciones;
