import * as GrIcons from "react-icons/gr";

interface PopupProps {
  idContrato: number;
  open: boolean;
  setOpen: (op: boolean) => void;
  elements: any[];
}

const PopupHistorialPagos: React.FC<PopupProps> = ({
  idContrato,
  open,
  setOpen,
  elements,
}: PopupProps) => {
  const showHideClassName = open ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section
        className="modal-main"
        style={{
          marginTop: "20px",
          overflowY: "auto",
          maxHeight: "80vh",
          width: "80%",
        }}
      >
        <header className="contract-actions-header">
          <h3 className="contract-actions-title">Historial de Pagos</h3>
          <button
            className="modal-close"
            onClick={() => setOpen(false)}
            aria-label="Cerrar"
          >
            <GrIcons.GrClose size="small" />
          </button>
        </header>
        <div style={{ paddingRight: "7.5vh" }}>
          <div>
            <table className="table table-striped table-bordered premise-table">
              <thead className="table-active">
                <tr>
                  <th>ID Contrato</th>
                  <th>Estado</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              {elements.length > 0 && (
                <tbody>
                  {elements.map((item: any, index) => {
                    const fecha = new Date(item.fecha);
                    return (
                      <tr key={idContrato}>
                        <th>{idContrato}</th>
                        <th>{item.estado}</th>
                        <th>{fecha.toLocaleDateString()}</th>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PopupHistorialPagos;
