import React, { useContext } from "react";
import { axiosInstanceMain } from "../../../../utils/axiosGlobalUtils";
import { Filtros } from "../../context/pedidos.context";
import { TenantsContext } from "../../../../context/tenants.context";
import { ITenant } from "../../../AbmLocalPage/types";

export const getPedidos = async (
  { tipo, codLocal, envioDesde, envioHasta, idTipoRetiro, codigoPedido, pagina, archivados }: Filtros,
  tenant: ITenant
) => {
  const queryParams = [];

  queryParams.push(`tipo=${tipo}`);
  if (codLocal) queryParams.push(`codLocal=${codLocal}`);
  if (idTipoRetiro) queryParams.push(`tipo-retiro=${idTipoRetiro}`);
  if (envioDesde) queryParams.push(`envio-desde=${envioDesde}`);
  if (envioHasta) queryParams.push(`envio-hasta=${envioHasta}`);
  if (codigoPedido) queryParams.push(`codigo-pedido=${codigoPedido}`);
  queryParams.push(`pagina=${pagina + 1}`);

  return await axiosInstanceMain.get(`/${tenant.path}/pedidos`, {
    params: {
      tipo,
      ...(codLocal && { codLocal }),
      ...(idTipoRetiro && { "tipo-retiro": idTipoRetiro }),
      ...(envioDesde && { "envio-desde": envioDesde }),
      ...(envioHasta && { "envio-hasta": envioHasta }),
      ...(codigoPedido && { "codigo-pedido": codigoPedido }),
      pagina: pagina + 1,
      archivados,
    },
  });
};

export const getTiposRetiro = async (tenant: ITenant): Promise<any[]> => {
  const datosFormulario = (await axiosInstanceMain.get(`/${tenant.path}/pedidos/tipo-retiro`)).data.data;
  return datosFormulario;
};
